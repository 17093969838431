<template>
    <sa-page-layout :showmolalloading="showmolalloading" :backVisible="true" :linkback="linkback">
        <template slot="toolbar-title">
            <paziente-anagrafica :linkPatient="linkPatient" :idPaziente="idPaziente"></paziente-anagrafica>
        </template>
        <template slot="toolbar-row">
            <paziente-dashboard-component :idPaziente="idPaziente"></paziente-dashboard-component>
        </template>

        <template slot="table-body">
            <b-row style="height: 100%">
                <iframe allow="camera;microphone" :src="linkQuestionario" width="100%" height="100%" frameborder="0"></iframe>
            </b-row>
        </template>
    </sa-page-layout>
</template>
<script>
import axios from "axios";
import PazienteAnagrafica from "./PazienteAnagrafica.vue";
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
import PazienteDashboardComponent from "./PazienteDashboardComponent.vue";
export default {
    props: {
        utente: {
            type: Object,
            default: function() {
                return null;
            },
        },
    },
    components: { PazienteAnagrafica, SaPageLayout, PazienteDashboardComponent },
    computed: {
        idPaziente() {
            return this.$route.query.idPaziente;
        },
        rows() {
            return this.items.length;
        },
        linkPatient: {
            get: function() {
                let returnValue = "";
                if (this.utente.gruppo) {
                    if (this.utente.gruppo.menuGruppo) {
                        if (this.utente.gruppo.menuGruppo.length > 1) {
                            return "/paziente/view/" + this.idPaziente;
                        } else {
                            if (this.utente.gruppo.menuGruppo.length == 1) {
                                if (this.utente.gruppo.menuGruppo[0].primaryKey === "pazienti") {
                                    return "/paziente/view/" + this.idPaziente;
                                } else {
                                    return "/paziente/homepaziente";
                                }
                            }
                        }
                    }
                }
                return returnValue;
            },
        },
    },
    data() {
        return {
            pathResource: "/pazientequestionari",
            linkback: "",
            linkQuestionario: "",
            perPage: 10,
            currentPage: 1,
            showmolalloading: false,
            id: "-1",
            fields: [],
            items: [],
            data: {},
        };
    },
    created: function() {},
    mounted: function() {
        let me = this;
        me.id = this.$route.params.id;
        me.data.idPaziente = this.$route.query.idPaziente;
        me.linkback = "/paziente/questionari?idPaziente=" + me.idPaziente;
        me.linkQuestionario = "https://scheduler.ak12srl.it/?idAnagrafica=" + me.data.idPaziente + "&username=decisa&idRichiedente=0&tipoScheduler=0&codiceStruttura=HYPHE1&callbackCreate=http://10.10.13.132:8080/hyperhealth/api/pazientequestionari&callbackResponse=http://10.10.13.132:8080/hyperhealth/api/questionari/response";
        me.loadData();
    },
    methods: {
        loadData() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "?idPaziente=" + me.data.idPaziente;
            me.showmolalloading = true;
            axios
                .get(link, { params: me.filtro })
                .then((response) => {
                    me.items = [];
                    me.items = response.data.data;
                    me.showmolalloading = false;
                })
                .catch(() => {
                    me.showmolalloading = false;
                });
        },
    },
};
</script>
