<template>
    <paziente-scheda :utente="utente" :messageDanger="collegamentoWt" :key="idPaziente" :idPaziente="idPaziente"></paziente-scheda>
</template>
<script>
import axios from "axios";
import PazienteScheda from "./PazienteSchedaComponent.vue";
export default {
    props: {
        utente: {
            required: true,
            type: Object,
            default: function() {
                return null;
            },
        },
    },
    components: { PazienteScheda },
    data() {
        return {
            messageDanger: false,
            collegamentoWt: false,
            idPaziente: null, //"0d3d6418-1105-4085-b622-b5b0bee0aa3d",
        };
    },
    created() {
        let me = this;
        console.log("Home Paziente");
        me.loadUserData();
    },
    mounted() {},
    methods: {
        loadUserData() {
            let me = this;

            let link = process.env.VUE_APP_PATH_API + "/users/profile";
            axios
                .get(link)
                .then((response) => {
                    let utente = response.data.data;
                    this.$user = utente;
                    me.idPaziente = utente.id;
                    me.nominativoUtente = utente.lastname + " " + utente.firstname;
                    me.ruolo = utente.profilo;
                    me.menu = response.data.data.gruppo.menuGruppo;
                    //me.loadPatientMenu();
                    me.loadPatientData();
                })
                .catch((e) => {
                    this.errors.push(e);
                });
        },
        /*loadPatientMenu() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + "/pazientemenu";
            axios
                .get(link, { params: { tipo: "left" } })
                .then((response) => {
                    me.menu = response.data.data;
                })
                .catch((e) => {
                    this.errors.push(e);
                });
        },*/
        loadPatientData() {
            let me = this;
            let linkPaziente = process.env.VUE_APP_PATH_API + "/pazientewt/" + me.idPaziente;
            axios
                .get(linkPaziente)
                .then((response) => {
                    me.collegamentoWt = !response.data.data.collegamentoWt;
                })
                .catch((e) => {
                    this.errors.push(e);
                });
        },
    },
};
</script>
