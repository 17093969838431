<template>
    <sa-page-layout :btnChatVisible="false" ref="pgaView" :pathResource="pathResource" :btnEditVisible="true" :btnAnnullaVisible="true" :saveVisible="false" :linkback="linkback" :linkedit="linkedit" :showmolalloading="showmolalloading">
        <template slot="toolbar-title">
            <paziente-anagrafica :linkPatient="linkPatient" :idPaziente="idPaziente"></paziente-anagrafica>
        </template>
        <template slot="table-body">
            <controlli-specialistici-view-component :idEvento="idEvento"></controlli-specialistici-view-component>
        </template>
    </sa-page-layout>
</template>
<script>
import ControlliSpecialisticiViewComponent from "./ControlliSpecialisticiViewComponent.vue";
import PazienteAnagrafica from "../../../components/PazienteAnagrafica.vue";
import SaPageLayout from "../../../../../template/sa/components/layout/contents/SaPageLayout.vue";
export default {
    props: {
        utente: {
            type: Object,
            default: function() {
                return null;
            },
        },
    },
    components: { ControlliSpecialisticiViewComponent, PazienteAnagrafica, SaPageLayout },

    data() {
        return {
            pathResource: "/diabetologiacontrollispecialistici",
            linkback: "/paziente/diabetologia/controllispecialistici/",
            linkedit: null,
            showmolalloading: false,
            id: "-1",
            profili: [],
            provenienzaOption: [{ value: null, text: null }],
        };
    },
    computed: {
        idPaziente() {
            return this.$route.query.idPaziente;
        },
        idEvento() {
            return this.$route.params.id;
        },
        dataEventoPlaceholder() {
            return this.$i18n.t("patient.events.lblEventDate");
        },
        linkPatient: {
            get: function() {
                let returnValue = "";
                if (this.utente.gruppo) {
                    if (this.utente.gruppo.menuGruppo) {
                        if (this.utente.gruppo.menuGruppo.length > 1) {
                            return "/paziente/view/" + this.idPaziente;
                        } else {
                            if (this.utente.gruppo.menuGruppo.length == 1) {
                                if (this.utente.gruppo.menuGruppo[0].primaryKey === "pazienti") {
                                    return "/paziente/view/" + this.idPaziente;
                                } else {
                                    return "/paziente/homepaziente";
                                }
                            }
                        }
                    }
                }
                return returnValue;
            },
        },
    },
    mounted: function() {
        let me = this;
        me.id = this.$route.params.id;
        me.linkedit = "/paziente/diabetologia/controllispecialistici/edit/" + me.id + "?idPaziente=" + me.idPaziente;
        me.linkback = "/paziente/diabetologia/controllispecialistici?idPaziente=" + me.idPaziente;
    },
};
</script>
