<template>
    <div class="modal-body">
        <b-row>
            <label for="codicePrestazione">{{ this.$i18n.t("patient.agenda.lblPerformanceCode") }}</label>
            <input name="codicePrestazione" class="form-control" v-model="setCodicePrestazione" />
        </b-row>

        <b-row>
            <label for="descrizionePrestazione">{{ this.$i18n.t("patient.agenda.lblDescriptionPerformance") }}</label>
            <b-form-textarea class="form-control" v-model="setDescrizione" rows="5" no-resize></b-form-textarea>
        </b-row>

        <b-row>
            <label for="costo">{{ this.$i18n.t("patient.agenda.lblCost") }}</label>
            <input type="text" name="costo" class="form-control" v-model="setCosto" />
        </b-row>

        <b-row>
            <label for="descrizionePrestazione">{{ this.$i18n.t("agenda.lblDuration") }}</label>
            <input type="number" name="durata" class="form-control" v-model="setDurata" />
        </b-row>
    </div>
</template>
<script>
export default {
    props: {
        jsonData: {
            type: Object,
            default: function() {
                return { codicePrestazione: null, descrizionePrestazione: null, costo: null, durata: null };
            },
        },
    },
    data() {
        return {};
    },
    computed: {
        setDurata: {
            get: function() {
                return this.jsonData.durata;
            },
            set: function(value) {
                this.jsonData.durata = value;
            },
        },
        setDescrizione: {
            get: function() {
                return this.jsonData.descrizionePrestazione;
            },
            set: function(value) {
                this.jsonData.descrizionePrestazione = value;
            },
        },
        setCosto: {
            get: function() {
                return this.jsonData.costo;
            },
            set: function(value) {
                this.jsonData.costo = value;
            },
        },
        setCodicePrestazione: {
            get: function() {
                return this.jsonData.codicePrestazione;
            },
            set: function(value) {
                this.jsonData.codicePrestazione = value;
            },
        },
    },
};
</script>
<style></style>
