<template>
    <div>
        <div class="sa-form-section">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <strong>Esame ORL</strong><br />
                            <b-form-textarea v-model="data.esameOrl" no-resize></b-form-textarea>
                            <b-input-group style="margin-top: 5px">
                                <b-form-select id="inline-form-custom-select-pref" class="mb-2 mr-sm-2 mb-sm-0" :options="esameOrlOptions" :value="null" value-field="descrizione" text-field="descrizione" @input="onInputEsameOrl($event)"></b-form-select>
                                <b-button variant="outline-secondary" style="margin-right: 5px;" size="sm" @click="onClickAggiungiDescrizione(data.esameOrl, linkEsameOrl)"><b-icon icon="plus"></b-icon></b-button>
                                <b-button variant="outline-secondary" size="sm" @click="onDeleteDescrizione(data.esameOrl, linkEsameOrl, esameOrlOptions)"><b-icon icon="trash"></b-icon></b-button>
                            </b-input-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <strong>Esame Audiometrico</strong><br />
                            <b-form-textarea v-model="data.esameAudiometrico" no-resize></b-form-textarea>
                            <b-input-group style="margin-top: 5px">
                                <b-form-select id="inline-form-custom-select-pref" class="mb-2 mr-sm-2 mb-sm-0" :options="esameAudiometricoOptions" :value="null" value-field="descrizione" text-field="descrizione" @input="onInputEsameAudiometrico($event)"></b-form-select>
                                <b-button variant="outline-secondary" style="margin-right: 5px;" size="sm" @click="onClickAggiungiDescrizione(data.esameAudiometrico, linkEsameAudiometrico)"><b-icon icon="plus"></b-icon></b-button>
                                <b-button variant="outline-secondary" size="sm" @click="onDeleteDescrizione(data.esameAudiometrico, linkEsameAudiometrico, esameAudiometricoOptions)"><b-icon icon="trash"></b-icon></b-button>
                            </b-input-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <strong>Esame Neurologico</strong><br />
                            <b-form-textarea v-model="data.esameNeurologico" no-resize></b-form-textarea>
                            <b-input-group style="margin-top: 5px">
                                <b-form-select id="inline-form-custom-select-pref" class="mb-2 mr-sm-2 mb-sm-0" :options="esameNeurologicoOptions" :value="null" value-field="descrizione" text-field="descrizione" @input="onInputEsameNeurologico($event)"></b-form-select>
                                <b-button variant="outline-secondary" style="margin-right: 5px;" size="sm" @click="onClickAggiungiDescrizione(data.esameNeurologico, linkEsameNeurologico)"><b-icon icon="plus"></b-icon></b-button>
                                <b-button variant="outline-secondary" size="sm" @click="onDeleteDescrizione(data.esameNeurologico, linkEsameNeurologico, esameNeurologicoOptions)"><b-icon icon="trash"></b-icon></b-button>
                            </b-input-group>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
        </div>
        <div class="sa-form-section">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <strong>Stato </strong><br />
                            {{ data.firma }}
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
        </div>
    </div>
</template>
<script>
import axios from "axios";
export default {
    props: {
        data: {
            Type: Object,
            default: function() {
                return {
                    esameOrl: "",
                    esameAudiometrico: "",
                    esameNeurologico: "",
                };
            },
        },
    },
    data() {
        return {
            linkEsameOrl: "/visiteesamispecialisticiintegrativiesameorl",
            linkEsameAudiometrico: "/visiteesamispecialisticiintegrativiesameaudiometrico",
            linkEsameNeurologico: "/visiteesamispecialisticiintegrativiesameneurologico",
            esameOrlOptions: [],
            esameAudiometricoOptions: [],
            esameNeurologicoOptions: [],
        };
    },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        me.loadDefaultData();
    },
    methods: {
        loadDefaultData() {
            let me = this;
            me.loadEsameOrl();
            me.loadEsameAudiometrico();
            me.loadEsameNeurologico();
        },
        loadEsameOrl() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkEsameOrl;
            axios.get(link).then((response) => {
                me.esameOrlOptions = response.data.data;
            });
        },
        onInputEsameOrl(value) {
            let me = this;
            me.data.esameOrl = value;
        },
        loadEsameAudiometrico() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkEsameAudiometrico;
            axios.get(link).then((response) => {
                me.esameAudiometricoOptions = response.data.data;
            });
        },
        onInputEsameAudiometrico(value) {
            let me = this;
            me.data.esameAudiometrico = value;
        },
        loadEsameNeurologico() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkEsameNeurologico;
            axios.get(link).then((response) => {
                me.esameNeurologicoOptions = response.data.data;
            });
        },
        onInputEsameNeurologico(value) {
            let me = this;
            me.data.esameNeurologico = value;
        },
        onClickAggiungiDescrizione(descrizione, path) {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + path;
            let jsonData = { descrizione: descrizione };
            axios
                .post(link, JSON.stringify(jsonData))
                .then((response) => {
                    this.$bvModal.msgBoxOk(response.data.messaggio, {
                        title: "",
                        size: "sm",
                        okVariant: "outline-danger",
                        headerClass: "sa-msg-header-danger",
                        footerClass: "p-2 border-top-0",
                        centered: true,
                    });
                    me.loadDefaultData();
                })
                .catch((e) => {
                    // console.log(e);
                    this.$bvModal
                        .msgBoxOk(e.response.data.messaggio, {
                            title: "ATTENZIONE",
                            size: "sm",
                            okVariant: "outline-danger",
                            headerClass: "sa-msg-header-danger",
                            footerClass: "p-2 border-top-0",
                            centered: true,
                        })
                        .then((value) => {
                            this.boxTwo = value;
                        });
                });
        },

        onDeleteDescrizione(descrizione, path, array) {
            let me = this;
            this.$bvModal
                .msgBoxConfirm("Sei sicuro di procedere alla cancellazione?", {
                    title: "Conferma Cancellazione",
                    size: "sm",
                    buttonSize: "sm",
                    okVariant: "secondary",
                    cancelVariant: "danger",
                    okTitle: "SI",
                    cancelTitle: "NO",
                    footerClass: "p-2",
                    hideHeaderClose: false,
                    centered: true,
                })
                .then((value) => {
                    if (value) {
                        console.log(value);
                        let link = process.env.VUE_APP_PATH_API + path;
                        array.forEach((element) => {
                            if (element.descrizione === descrizione) {
                                axios.delete(link + "/" + element.id).then((response) => {
                                    this.$bvModal.msgBoxOk(response.data.messaggio, {
                                        title: "",
                                        size: "sm",
                                        okVariant: "outline-danger",
                                        headerClass: "sa-msg-header-danger",
                                        footerClass: "p-2 border-top-0",
                                        centered: true,
                                    });
                                    me.loadDefaultData();
                                });
                            } else if (descrizione === null || descrizione === "") {
                                this.$bvModal.msgBoxOk("Nessun elemento da cancellare.", {
                                    title: "Attenzione!",
                                    Text: "Nessun valore da cancellare!",
                                    size: "sm",
                                    okVariant: "outline-danger",
                                    headerClass: "sa-msg-header-danger",
                                    footerClass: "p-2 border-top-0",
                                    centered: true,
                                });
                            }
                        });
                    }
                })
                .catch((e) => {
                    this.$bvModal
                        .msgBoxOk(e.response.data.messaggio, {
                            title: "ATTENZIONE",
                            size: "sm",
                            okVariant: "outline-danger",
                            headerClass: "sa-msg-header-danger",
                            footerClass: "p-2 border-top-0",
                            centered: true,
                        })
                        .then((value) => {
                            this.boxTwo = value;
                        });
                });
        },
    },
};
</script>
