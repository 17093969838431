<template>
  <div class="modal-body">
    <b-row>
      <b-col cols="6">
        <label for="titolo">{{ this.$i18n.t("agendas.lblTitle") }}</label>
        <input type="text" name="titolo" class="form-control" v-model="slotDisponibilita.titolo" />
      </b-col>

      <b-col cols="6">
        <label for="identificativoAppuntamento"> {{ this.$i18n.t("billing.lblFiscalCode") }}</label>
        <input type="text" name="identificativoAppuntamento" class="form-control" v-model="slotDisponibilita.appuntamento.identificativoAppuntamento" :disabled="true" />
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="6">
        <label for="codicePrestazione">{{ this.$i18n.t("patient.agenda.lblPerformanceCode") }}</label>
        <input name="codicePrestazione" class="form-control" v-model="slotDisponibilita.appuntamento.codicePrestazione" :disabled="true" />
      </b-col>

      <b-col cols="6">
        <label for="descrizionePrestazione">{{ this.$i18n.t("patient.agenda.lblDescriptionPerformance") }}</label>
        <input type="text" name="descrizionePrestazione" class="form-control" v-model="slotDisponibilita.appuntamento.descrizionePrestazione" :disabled="true" />
      </b-col>
    </b-row>
    <div style="height: 10px" />

    <label style="font-size: 13px;">{{ this.$i18n.t("patient.agenda.lblDateReference") }}</label>
    <b-row>
      <b-col>
        <b-button block v-b-toggle :disabled="!dataOdierna" class="no-text btn-outline-primary sa-base-color" variant="outline-light" @click="onPreviousDay"
          ><b-icon icon="arrow-left-circle" style="justify-content: end; align-self: end"
        /></b-button>
      </b-col>
      <b-col cols="8">
        <date-picker class="datepicker" format="DD-MM-YYYY" value-type="timestamp" :clearable="false" v-model="setDataAppuntamento" :disabled="true" type="timestamp" @update="updateData"></date-picker>
      </b-col>
      <b-col cols="2">
        <b-button block v-b-toggle class="no-text btn-outline-primary sa-base-color" variant="outline-info" @click="onNextDay"><b-icon icon="arrow-right-circle"/></b-button>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <label for="dataInizio">{{ this.$i18n.t("patient.agenda.lblStartTime") }}</label>
        <date-picker format="HH:mm" value-type="timestamp" v-model="oraInizioApp" style="color: black" type="time" placeholder="HH:mm" :disabled="true"></date-picker>
      </b-col>
      <b-col>
        <label for="indirizzo">{{ this.$i18n.t("patient.agenda.lblEndTime") }}</label>
        <date-picker format="HH:mm" value-type="timestamp" v-model="oraFineApp" type="time" placeholder="HH:mm" :disabled="true"></date-picker>
      </b-col>
    </b-row>
    <b-card title="Card Title" class="sc-card-max-height" no-body>
      <b-card-body class="text-center" style="overflow-x: auto">
        <div class="sa-calendar-day">
          <div class="sa-calendar-day-content">
            <b-row>
              <b-card class="sc-card-max-height" style="width: 100%; height= 100%" no-body>
                <div class="sa-calendar-day-grid-slot">
                  <table class="sa-calendar-day-grid-table-slot">
                    <tr v-for="(row, i) in griglia" :key="i">
                      <td v-for="(td, x) in row" :key="x">
                        {{ td.dalleOre }} - {{ td.alleOre }}
                        <div :class="classDisponibile(td)">
                          <div>
                            <a :id="id" href="oraScaduta" @click="onSetData(td)" onclick="return false;" :class="disabledClass(td)">
                              <span>{{ titoloSlot(td) }}</span>
                            </a>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </table>
                </div>
              </b-card>
            </b-row>
          </div>
        </div>
      </b-card-body>
    </b-card>
  </div>
</template>
<script>
import DatePicker from "vue2-datepicker";
import axios from "axios";
import moment from "moment";
import UtilityMixin from "../../utility/UtilityMixin";

export default {
  mixins: [UtilityMixin],
  components: {
    DatePicker,
  },
  data() {
    return {
      gridData: [],
      riferimento: "",
      periodo: "",
      subTitle: "",
      disponibilita: [],
      dataAppVar: this.dataAppuntamento,
      dataOdierna: false,
      oraScaduta: true,
      pathRest: "/agendadisponibilita",
      pathRestPrestazioni: "/agendaprestazioni",
    };
  },
  props: {
    index: String,
    slotDisponibilita: {
      type: Object,
      default: function() {
        return { appuntamento: { identificativoAppuntamento: null } };
      },
    },
    idAgenda: String,
    dataAppuntamento: Number,
    oraFine: Number,
    oraInizio: Number,
  },
  computed: {
    griglia: {
      get: function() {
        return this.gridData;
      },
      set: function(value) {
        this.gridData = value;
      },
    },
    id() {
      let me = this;
      return "popover-button_" + me.index;
    },
    setDataAppuntamento: {
      get: function() {
        let me = this;
        return me.dataAppVar;
      },
      set: function(value) {
        let me = this;
        me.dataAppVar = value;
        me.loadData();
      },
    },
    oraInizioApp: {
      get: function() {
        let me = this;
        let dalleOre = UtilityMixin.methods.parseFormatDateTime(me.slotDisponibilita.dataRiferimento + " " + me.slotDisponibilita.dalleOre, "YYYY-MM-DD HH:mm").getTime();
        return dalleOre;
      },
      set: function(value) {
        let me = this;
        let dalleOre = UtilityMixin.methods.parseFormatDateTime(value);
        me.slotDisponibilita.dalleOre = dalleOre;
      },
    },
    oraFineApp: {
      get: function() {
        let me = this;
        let alleOre = UtilityMixin.methods.parseFormatDateTime(me.slotDisponibilita.dataRiferimento + " " + me.slotDisponibilita.alleOre, "YYYY-MM-DD HH:mm").getTime();
        return alleOre;
      },
      set: function(value) {
        let me = this;
        let alleOre = UtilityMixin.methods.parseFormatDateTime(value);
        me.slotDisponibilita.alleOre = alleOre;
      },
    },
  },
  mounted: function() {
    let me = this;
    let dataSlot = new Date();
    let dataFormattata = moment(dataSlot).format("YYYY-MM-DD");
    let dataAppVarFormattata = moment(me.dataAppVar).format("YYYY-MM-DD");
    if (dataAppVarFormattata === dataFormattata) {
      me.dataOdierna = false;
    } else {
      me.dataOdierna = true;
    }
    me.loadData();
  },
  watch: {
    disponibilita: function() {
      let column = 7;
      let numeroRighe = Math.trunc(this.disponibilita[0].disponibilita.length / column);
      numeroRighe = this.disponibilita[0].disponibilita.length % column > 0 ? numeroRighe + 1 : numeroRighe;
      let griglia = [];
      let count = 0;
      let row = [];
      this.disponibilita[0].disponibilita.forEach((element) => {
        if (count < column) {
          row.push(element);
          count++;
        }

        let numeroElementi = griglia.length * column + row.length;
        if (count === column || (griglia.length === numeroRighe - 1 && this.disponibilita[0].disponibilita.length === numeroElementi)) {
          griglia.push(row);
          row = [];
          count = 0;
        }
      });
      this.gridData = griglia;
    },
  },
  methods: {
    disabledClass(td) {
      let me = this;
      let dataOraSlot = new Date();
      let dataOraFormattata = moment(dataOraSlot).format("YYYY-MM-DD HH:mm");
      if (me.slotDisponibilita.appuntamento.dataRiferimento + " " + td.dalleOre <= dataOraFormattata) {
        me.oraScaduta = true;
        return "disabled";
      } else {
        me.oraScaduta = false;
        return "";
      }
    },
    classDisponibile(td) {
      if (td.appuntamento === null) {
        return "sa-calendar-day-slot-prenotazione disponibile";
      } else {
        return "sa-calendar-day-slot-prenotazione nodisponibile disabled";
      }
    },
    titoloSlot(td) {
      if (td.titolo === "") {
        return this.$i18n.t("agenda.lblAvailable");
      } else {
        return td.titolo;
      }
    },
    updateData() {
      let me = this;
      me.$emit("update");
    },
    loadData(flag) {
      let me = this;
      if (me.slotDisponibilita.appuntamento.codicePrestazione) {
        let link = process.env.VUE_APP_PATH_API + me.pathRest + "/";
        axios
          .get(link, {
            params: {
              idAgenda: me.idAgenda,
              periodo: "G",
              riferimento: moment(me.dataAppVar).format("YYYY-MM-DD"),
              codicePrestazione: me.slotDisponibilita.appuntamento.codicePrestazione,
            },
          })
          .then((response) => {
            me.disponibilita = response.data.data;
            let value = 0;
            me.disponibilita.forEach((elemento) => {
              value += elemento.disponibilita.length;
            });
            me.numeroDisponibilita = value;
            let link = process.env.VUE_APP_PATH_API + me.pathRestPrestazioni;
            if (flag) {
              axios
                .get(link, {
                  params: {
                    idAgenda: me.idAgenda,
                  },
                })
                .then((response) => {
                  for (let i = 0; response.data.data.length; i++) {
                    if (me.slotDisponibilita.appuntamento.codicePrestazione === response.data.data[i].codicePrestazione) {
                      me.nomenclatore = [];
                      me.nomenclatore = response.data.data;
                      me.descrizionePrestazione = response.data.data[i].descrizionePrestazione;
                      me.costo = response.data.data[i].costo;
                      me.idAgenda = me.id;
                      me.tags.splice(0, 1);
                      me.tags.push(me.slotDisponibilita.appuntamento.codicePrestazione);
                    }
                    me.disabledClass();
                  }
                })
                .catch(() => {});
            }
          })
          .catch(function(error) {
            me.$bvToast.toast(error.response.data.messaggio, {
              title: "Agenda",
              variant: "danger",
              solid: true,
            });
          });
      } else {
        return;
      }
    },
    onSetData(target) {
      let me = this;
      me.slotDisponibilita.dalleOre = target.dalleOre;
      me.slotDisponibilita.alleOre = target.alleOre;

      let dataOraInizio = moment(me.slotDisponibilita.appuntamento.dataRiferimento + " " + target.dalleOre, "YYYY-MM-DD hh:mm");
      let dataOraFine = moment(me.slotDisponibilita.appuntamento.dataRiferimento + " " + target.alleOre, "YYYY-MM-DD hh:mm");
      me.slotDisponibilita.appuntamento.dataOraInizio = dataOraInizio.toDate().getTime();
      me.slotDisponibilita.appuntamento.dataOraFine = dataOraFine.toDate().getTime();
      me.$emit("update", me.slotDisponibilita.appuntamento);
    },

    onPreviousDay() {
      let me = this;
      let nuovaData = moment(me.dataAppVar).subtract(1, "days");
      me.dataAppVar = nuovaData.toDate().getTime();
      let dataSlot = new Date();
      let dataFormattata = moment(dataSlot).format("YYYY-MM-DD");
      let dataAppVarFormattata = moment(nuovaData).format("YYYY-MM-DD");
      if (dataAppVarFormattata === dataFormattata) {
        me.dataOdierna = false;
      }
      me.slotDisponibilita.appuntamento.dataRiferimento = moment(me.dataAppVar).format("YYYY-MM-DD");
      me.loadData();
      me.$emit("updatedataRiferimento", me.slotDisponibilita.appuntamento);
    },
    onNextDay() {
      let me = this;
      let nuovaData = moment(me.dataAppVar).add(1, "days");
      me.dataAppVar = nuovaData.toDate().getTime();
      me.slotDisponibilita.appuntamento.dataRiferimento = moment(me.dataAppVar).format("YYYY-MM-DD");
      me.loadData();
      me.$emit("updatedataRiferimento", me.slotDisponibilita.appuntamento);
      me.dataOdierna = true;
    },
  },
};
</script>
<style>
.sa-calendar-day {
  border-top: 1px solid #d0d0d0;
  display: block;
  outline: none;
  height: 100% !important;
}
.sa-calendar-day-slot {
  height: 3rem;
}
.sa-calendar-day-slot-hour {
  width: 5rem;
  float: inline-start;
  height: 100%;
  border-bottom: 1px solid #d0d0d0;
}
.sa-calendar-day-slot-hour-start {
  display: inline-block;
  font-size: 0.8rem;
}
.sa-calendar-day-slot-hour-end {
  display: inline-block;
  font-size: 0.7rem;
  width: 100%;
  text-align: right;
  padding-right: 0.2rem;
}
.sa-calendar-day-slot-text {
  border-left: 1px solid #d0d0d0;
  border-bottom: 1px solid #d0d0d0;
  border-right: 1px solid #d0d0d0;
  margin-left: 5rem;
  padding-left: 0.2rem;
  height: 100%;
  width: 100%;
}

.sa-calendar-day-slot-text div {
  height: 100%;
  display: inline-flex;
  vertical-align: middle;
  align-items: center;
}

.tab-griglia {
  height: 100% !important;
}

.tabs-griglia {
  height: 100%;
}

.tab-content {
  height: 100%;
}
.sa-calendar-day {
  height: 100%;
  padding-bottom: 1.25rem;
  overflow-y: none;
}
.sa-calendar-day-content {
  height: 100%;
}
#agenda-day-grid {
  height: 100%;
  padding-bottom: 45px;
}
.sa-calendar-day-grid-slot {
  height: 100%;
}
.sa-calendar-day-grid-table-slot {
  width: 100%;
  height: 100%;
}
.sa-calendar-day-grid-slot-hour {
  background-color: red;
}
.sa-calendar-day-grid-table-slot td {
  border: solid 1px rgb(194, 191, 191);
  border-collapse: collapse;
  vertical-align: top;
  padding: 5px;
}
.sa-calendar-popover .popover-header {
  margin-top: 0px !important;
  background-color: blue;
}

.sa-calendar-day-slot-prenotazione span {
  border-radius: 0.3rem;
  vertical-align: middle;
  display: table-cell;
  padding: 0.3rem;
  white-space: nowrap;
  line-height: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  color: white;
  font-size: 0.8rem;
  padding: 10px;
}

.disponibile span {
  background-color: #abf7b3;
  border-left: 3px solid green;
  color: black;
}
.disponibile span:active {
  background-color: green;
  border-left: 3px solid #abf7b3;
}

.nodisponibile span {
  background-color: #9bc3ff;
  border-left: 3px solid blue;
  color: black;
}

.nodisponibile span:active {
  background-color: rgb(86, 86, 248);
  border-left: 3px solid #9bc3ff;
}
.sa-icon-btn {
  width: 2rem;
  height: 2rem;
}
.sa-icon-btn svg {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0.5rem;
}
.sa-icon-btn svg:hover {
  color: #000;
  cursor: pointer;
}
.sa-agenda-popover-span {
  padding-left: 5px;
}
.disabled {
  pointer-events: none;
  opacity: 0.5;
}
.mx-input:disabled,
.mx-input.disabled {
  color: #000;
}
</style>
