<template>
    <div class="sa-page">
        <div class="content-header">
            <p>Gesan SRL</p>
         </div>
        <div class="content">
            <b-card header="Dettagli Tampone" header-tag="header" title>
                <b-card-text>
                    <b-container>
                        <b-row>
                            <b-col cols="12" md="2" class="sa-text-right">Nome:</b-col>
                            <b-col cols="12" md="4">
                                <b-form-input v-model="gruppo.nome" placeholder="Nome"></b-form-input>
                            </b-col>
                            <b-col cols="12" md="2" class="sa-text-right">Descrizione:</b-col>
                            <b-col cols="12" md="4">
                                <b-form-input v-model="gruppo.descrizione" placeholder="Descrizione"></b-form-input>
                            </b-col>
                        </b-row>
                    </b-container>
                </b-card-text>
            </b-card>

            <b-card no-body class="card-primary card-outline card-tabs">
                <b-tabs card>
                    <b-tab title="Menu" active>
                        <b-card-text>
                            <b-table responsive striped hover :items="gruppo.menu" :fields="fieldsMenu" head-variant="light" sort-icon-left>
                                <template v-slot:cell(iconCls)="{ item }">
                                    <i :class="'fas fa-' + item.iconCls"></i>
                                    <!-- You can also use the font-awesome-icon component here -->
                                </template>
                                <template v-slot:cell(text)="{ item }">
                                    <div>
                                        <span class="sa-text-bold">{{ item.text }}</span>
                                    </div>
                                    <div>
                                        <span>{{ item.view }}</span>
                                    </div>
                                </template>
                                <template v-slot:cell(selected)="{ item }">
                                    <b-form-checkbox v-model="item.selezionato" name="check-button" switch size="lg"></b-form-checkbox>
                                </template>
                            </b-table>
                        </b-card-text>
                    </b-tab>
                    <b-tab title="Permessi">
                        <b-card-text>
                            <b-row>
                                <b-col lg="12" class="my-1">
                                    <b-form-group label="" label-cols-sm="0" label-align-sm="right" label-size="sm" label-for="filterInput" class="mb-0">
                                        <b-input-group size="sm">
                                            <b-form-input v-model="filter" type="search" id="filterInput" placeholder="Nome"></b-form-input>
                                            <b-input-group-append>
                                                <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                                            </b-input-group-append>
                                        </b-input-group>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <b-table responsive striped hover :filter="filter" :filterIncludedFields="filterOn" :items="gruppo.sezioni" :fields="fieldsSezioni" head-variant="light" sort-icon-left>
                                <template v-slot:cell(permessiSezione)="{ item }">
                                    <div></div>
                                    <!--<b-form-checkbox v-for="permesso in verificaPermessoSezione(item.permessiSezione, permessi)" :key="permesso.valore" switch size="lg" class="sa-normal-label">-->
                                    <div v-for="(permesso, index) in item.permessi" :key="index">
                                        <b-form-checkbox v-model="permesso.attivo" size="lg" switch class="sa-normal-label">{{ permesso.descrizione }}</b-form-checkbox>
                                    </div>
                                </template>
                            </b-table>
                        </b-card-text>
                    </b-tab>
                </b-tabs>
            </b-card>
        </div>

        <div class="content-footer">
            <div class="row sa-margin">
                <div class="col-12">
                    <b-button-toolbar class="float-right" aria-label="Toolbar with button groups and dropdown menu">
                        <b-button variant="light sa-margin-left" size="sm" @click="onSave"><b-icon icon="check2" variant="secondary"></b-icon>Salva</b-button>
                        <b-button variant="light sa-margin-left" size="sm" @click="onBack"><b-icon icon="chevron-left" variant="secondary"></b-icon>Indietro</b-button>
                    </b-button-toolbar>
                </div>
            </div>
        </div>
        <sa-modal-loading ref="sa-modal-loading" />
    </div>
</template>
<script>
import axios from "axios";
import { SaModalLoading } from "../../../components/modal";
export default {
    components: { SaModalLoading },
    label: "Dettagllio TAmpone",
    data() {
        return {
            idGruppo: String,
            pathRESTRequest : '/tamponi',
            gruppo: { nome: "", descrizioneGruppo: "", menu: [], sezioni: [] },
            label: "Dettagllio Tampone",
            filter: null,
            filterOn: [],
            fieldsMenu: [
                {
                    label: "",
                    key: "iconCls",
                    sortable: false,
                    class: "sa-col-icon",
                },
                {
                    label: "Menu",
                    key: "text",
                    sortable: false,
                },
                {
                    label: "Padre",
                    key: "idPadre",
                    sortable: false,
                },
                {
                    key: "selected",
                    label: "",
                    sortable: false,
                    class: "sa-width-2 sa-td-vertical-middle",
                },
            ],
            fieldsSezioni: [
                {
                    label: "Sezione",
                    key: "descrizione",
                    sortable: true,
                },
                {
                    label: "Permessi",
                    key: "permessiSezione",
                    sortable: false,
                    class: "sa-width-12",
                },
            ],
        };
    },
    mounted: function() {
        let me = this;
        me.id = this.$route.params.id;
        this.loadData();
    },
    methods: {
        verificaPermessoSezione(permesso) {
            console.log(permesso);

            return permesso ? "checked" : "unchecked";
        },
        loadData() {
            let me = this;
            me.$refs["sa-modal-loading"].show();
            let link = process.env.VUE_APP_PATH_API + "/vocimenu";
            axios.get(link).then((response) => {
                me.menu = response.data.data;
                link = process.env.VUE_APP_PATH_API + me.pathRESTRequest + "/";
                axios.get(link + me.id).then((response) => {
                    me.gruppo = response.data.data;
                    me.$refs["sa-modal-loading"].close();
                });
            });
        },
        onSave() {
            let me = this;
            me.$refs["sa-modal-loading"].show();
            if (me.id == "-1") {
              let link = process.env.VUE_APP_PATH_API + me.pathRESTRequest;
                axios
                    .post(link, JSON.stringify(me.gruppo))
                    .then((response) => {
                        me.gruppo = response.data.data;
                        me.$refs["sa-modal-loading"].close();
                        me.$router.replace(me.pathRESTRequest).catch((err) => {
                            err;
                        });
                    })
                    .catch((error) => {
                        me.$refs["sa-modal-loading"].close();
                        this.$bvModal
                            .msgBoxOk(error.response.data.messaggio, {
                                title: "ATTENZIONE",
                                size: "sm",
                                okVariant: "outline-danger",
                                headerClass: "sa-msg-header-danger",
                                footerClass: "p-2 border-top-0",
                                centered: true,
                            })
                            .then((value) => {
                                this.boxTwo = value;
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                    });
            } else {
              let link = process.env.VUE_APP_PATH_API + me.pathRESTRequest +"/";
                axios
                    .put(link + me.id, JSON.stringify(me.gruppo))
                    .then((response) => {
                        me.gruppo = response.data.data;
                        me.$refs["sa-modal-loading"].close();
                        me.$router.replace(me.pathRESTRequest).catch((err) => {
                            err;
                        });
                    })
                    .catch((error) => {
                        me.$refs["sa-modal-loading"].close();
                        this.$bvModal
                            .msgBoxOk(error.response.data.messaggio, {
                                title: "ATTENZIONE",
                                size: "sm",
                                okVariant: "outline-danger",
                                headerClass: "sa-msg-header-danger",
                                footerClass: "p-2 border-top-0",
                                centered: true,
                            })
                            .then((value) => {
                                this.boxTwo = value;
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                    });
            }
        },
        onBack() {
            let me = this;
            if (me.gruppo.id) {
                me.$router.push(me.pathRESTRequest + "/view/" + me.gruppo.id);
            } else {
                me.$router.push(me.pathRESTRequest);
            }
        },
    },
};
</script>
<style>
.sa-text-right {
    text-align: right;
}
@media only screen and (max-width: 767px) {
    .sa-text-right {
        text-align: left;
    }
}
.card {
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.125), 0 1px 3px rgba(0, 0, 0, 0.2);
    margin-bottom: 1rem;
}
</style>
