<template>
    <sa-page-view-edit :backVisible="true" :editVisible="true" :linkback="linkback" :linkedit="linkedit" :pathResource="pathResource" :data="data" :showmolalloading="showmolalloading">
        <template slot="header">
            <label>Numeratori</label>
        </template>
        <template slot="body">
            <div style="max-height: 18rem; overflow: auto;">
                <b-row style="margin-left: 0.5rem; margin-right: 0.5rem; margin-top: 1rem">
                    <b-col sm="6" md="6" lg="6">
                        <fieldset class="sa-fieldset-intestazione">
                            <label>Codice: </label>
                            <p>{{ data.codice }}</p> <hr/>
                            <label>Anno: </label>
                            <p>{{ data.anno }}</p> <hr/>
                            <label> Valore: </label>
                            <p>{{ data.valore }}</p> <hr/>
                        </fieldset>
                    </b-col>
                    <b-col sm="6" md="6" lg="6">
                        <fieldset class="sa-fieldset-intestazione">
                            <label>Descrizione: </label>
                            <p>{{ data.descrizione }}</p> <hr/>
                            <label>Codice Struttura: </label>
                            <p>{{ data.codiceStruttura }}</p> <hr/>
                            <label> Note: </label>
                            <p>{{ data.note }}</p> <hr/>
                        </fieldset>
                    </b-col>
                </b-row>
            </div>
        </template>
    </sa-page-view-edit>
</template>
<script>
import axios from "axios";
import SaPageViewEdit from '../../../template/sa/components/layout/contents/SaPageViewEdit.vue';
export default {
    components:{SaPageViewEdit},
    data(){
        return{
            pathResource: "/numeratori",
            linkback:"/fatturazionenumeratori",
            linkedit:"",
            id : "",
            showmolalloading: false,
            data:{},
        }
    },
    mounted(){
        let me = this;
        me.id = this.$route.params.id;
        me.linkedit = "/fatturazionenumeratori/edit/" + me.id
        me.loadData();
    },
    methods:{
        loadData(){
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
            me.showmolalloading = true;
            axios
            .get(link + me.id)
            .then((response) => {
                me.data = response.data.data;
                me.showmolalloading = false;
            })
            .catch(() => {
                me.showmolalloading = false;
            }); 
        },
    },
}
</script>