import { render, staticRenderFns } from "./GestioneAntigeniciView.vue?vue&type=template&id=459a26b8&"
import script from "./GestioneAntigeniciView.vue?vue&type=script&lang=js&"
export * from "./GestioneAntigeniciView.vue?vue&type=script&lang=js&"
import style0 from "./GestioneAntigeniciView.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports