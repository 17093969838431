<template>
    <div>
        <b-row class="sa-row-range">
            <b-col cols="12">
                <vue-range-slider @drag-end="onDragEnd" ref="slider" class="sa-slider" v-model="value" :min="min" :max="max" :enable-cross="enableCross" :formatter="onFormatter"></vue-range-slider>
            </b-col>
        </b-row>
    </div>
</template>
<script>
import "vue-range-component/dist/vue-range-slider.css";
import VueRangeSlider from "vue-range-component";

export default {
    props: {
        rangeValue: {
            type: Array,
            default: function() {
                return [0, 0];
            },
        },
        maxWidth: {
            type: Number,
        },
        min: {
            type: Number,
        },
        max: {
            type: Number,
        },
        precision: {
            type: Number,
            default() {
                return 0;
            },
        },
        scala: {
            type: Number,
            default() {
                return 0;
            },
        },
        center: {
            type: Number,
            default() {
                return 0;
            },
        },
    },
    components: {
        VueRangeSlider,
    },
    data() {
        return {
            enableCross: false,
        };
    },
    computed: {
        value: {
            get: function() {
                return this.rangeValue;
            },
            set: function() {},
        },
        minSlider: {
            get: function() {
                var val = parseInt(this.value[0]);
                return val;
            },
            set: function(val) {
                val = parseInt(val);
                this.value = val;
            },
        },
        maxSlider: {
            get: function() {
                var val = parseInt(this.value[1]);
                return val;
            },
            set: function(val) {
                val = parseInt(val);
                this.value = val;
            },
        },
    },
    methods: {
        onDragEnd(value) {
            this.$emit("update", value.currentValue);
        },
        onFormatter(value) {
            let me = this;
            let label = "";
            if (me.maxWidth) {
                label = ((me.maxWidth / 2 - value) * (me.scala / 2)) / (me.maxWidth / 2);
                label = (label + me.center) / 10;
                label = label.toFixed(me.precision);
            }

            return label;
        },
    },
};
</script>
<style>
.slider-process {
    background: #2eafb4 !important;
}
.vue-range-slider.slider-component .slider-tooltip-wrap .slider-tooltip {
    background-color: #2eafb4;
    border: #2eafb4;
}
</style>
