<template>
    <div class="brand-img logo">
        <router-link :to="to" class="brand-link">
            <span class="logo-lg">
                <img :src="logo" :srcset="`${logo}`" :class="['brand-image', 'img-circle']" :alt="header" />
            </span>
            <span class="logo-sm">
                <img :src="logosmall" :srcset="`${logosmall}`" :class="['brand-image', 'img-circle']" :alt="header" />
            </span>
        </router-link>
    </div>
</template>

<script>
import elevation from "../../mixins/elevation";

export default {
    mixins: [elevation()],
    props: {
        logo: {
            type: String,
            required: true,
        },
        logosmall: {
            type: String,
            required: true,
        },
        logosmall2x: {
            type: String,
            required: false,
        },
        logo2x: {
            type: String,
            default: "",
        },
        to: {
            type: String,
            default: "/",
        },
        header: {
            type: String,
            default: "",
        },
    },
};
</script>
