<template>
    <sa-page-view-edit ref="pgaView" :data="data" :pathResource="pathResource" :backVisible="true" :saveVisible="true" :titolo="data.username" :linkback="pagelink" :linkedit="linkedit" :showmolalloading="showmolalloading">
        <template slot="body">
            <div>
                <div class="sa-form-section">
                    <b-row>
                        <b-col cols="12" xs="12" sm="3" md="3" lg="3" xl="3">
                            <span class="sa-form-label">Trofismo</span>
                            <b-form-textarea v-model="data.trofismo" disabled="true"></b-form-textarea>
                        </b-col>
                        <b-col cols="12" xs="12" sm="3" md="3" lg="3" xl="3">
                            <b-row
                                ><b-col><span class="sa-form-label">Peso</span> <b-form-input v-model="data.anamnesiFisiologica" disabled="true"></b-form-input></b-col><b-col><span class="sa-form-label">Peso</span> <b-form-input v-model="data.anamnesiFisiologica" disabled="true"></b-form-input></b-col
                            ></b-row>
                        </b-col>
                        <b-col cols="12" xs="12" sm="3" md="3" lg="3" xl="3">
                            <span class="sa-form-label">Apparato Locomotore</span>
                            <b-form-textarea v-model="data.apparatoLocomotore" disabled="true"></b-form-textarea>
                        </b-col>
                        <b-col cols="12" xs="12" sm="3" md="3" lg="3" xl="3">
                            <span class="sa-form-label">Torace</span>
                            <b-form-textarea v-model="data.torace" disabled="true"></b-form-textarea>
                        </b-col>
                    </b-row>
                </div>
                <div class="sa-form-section">
                    <b-row>
                        <b-col cols="12" xs="12" sm="5" md="5" lg="5" xl="5">
                            <span class="sa-form-label">Apparato Respiratorio</span>
                            <b-form-textarea v-model="data.apparatoRespiratorio" disabled="true"></b-form-textarea>
                        </b-col>
                        <b-col cols="12" xs="12" sm="5" md="5" lg="5" xl="5">
                            <span class="sa-form-label">Apparato Circolatorio</span>
                            <b-form-textarea v-model="data.apparatoCircolatorio" disabled="true"></b-form-textarea>
                        </b-col>
                        <b-col cols="12" xs="12" sm="2" md="2" lg="2" xl="2">
                            <span class="sa-form-label">P.A. Riposo</span>
                            <b-form-input v-model="data.riposoPA" disabled="true"></b-form-input>
                        </b-col>
                    </b-row>
                </div>
                <div class="sa-form-section">
                    <b-row>
                        <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                            <span class="sa-form-label">Addome</span>
                            <b-form-textarea v-model="data.addome" disabled="true"></b-form-textarea>
                        </b-col>
                        <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                            <span class="sa-form-label">Organi genitali</span>
                            <b-form-textarea v-model="data.organiGenitali" disabled="true"></b-form-textarea
                        ></b-col>
                        <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                            <span class="sa-form-label">Arti</span>
                            <b-form-textarea v-model="data.arti" disabled="true"></b-form-textarea>
                        </b-col>
                    </b-row>
                </div>
                <div class="sa-form-section">
                    <b-row>
                        <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                            <b-row>
                                <b-col
                                    ><span class="sa-form-label">Acuità vista Naturale</span>
                                    <b-row>
                                        <b-col><span class="sa-form-label">Dx</span> <b-form-input v-model="data.vistaNaturaleOcchioDx" disabled="true"></b-form-input></b-col>
                                        <b-col><span class="sa-form-label">Sx</span> <b-form-input v-model="data.vistaNaturaleOcchioSx" disabled="true"></b-form-input></b-col>
                                    </b-row>
                                </b-col>
                                <b-col
                                    ><span class="sa-form-label">Acuità vista Corretta</span>
                                    <b-row>
                                        <b-col><span class="sa-form-label">Dx</span> <b-form-input v-model="data.vistaCorrettaOcchioDx" disabled="true"></b-form-input></b-col>
                                        <b-col><span class="sa-form-label">Sx</span> <b-form-input v-model="data.vistaCorrettaOcchioSx" disabled="true"></b-form-input></b-col> </b-row
                                ></b-col>
                            </b-row>
                        </b-col>
                        <b-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                            <span class="sa-form-label">Senso Cromatico</span>
                            <b-form-textarea v-model="data.sensoCromatico" disabled="true"></b-form-textarea>
                        </b-col>
                        <b-col cols="12" xs="12" sm="2" md="2" lg="2" xl="2">
                            <span class="sa-form-label">Obblico Lenti Correttive</span>
                            <b-form-checkbox v-model="data.obbligoLenti" :checked="obbligoLenti" disabled="true"></b-form-checkbox>
                        </b-col>
                    </b-row>
                </div>
                <div class="sa-form-section">
                    <b-row>
                        <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                            <span class="sa-form-label">Udito</span>
                            <b-form-textarea v-model="data.udito" disabled="true"></b-form-textarea>
                        </b-col>
                        <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                            <span class="sa-form-label">Conclusioni Esame Obiettivo</span>
                            <b-form-textarea v-model="data.conclusioniEsameObiettivo" disabled="true"></b-form-textarea
                        ></b-col>
                        <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                            <span class="sa-form-label">Note su scheda di valutazione</span>
                            <b-form-textarea v-model="data.noteEsameObiettivo" disabled="true"></b-form-textarea>
                        </b-col>
                    </b-row>
                </div>
            </div>
        </template>
    </sa-page-view-edit>
</template>
<script>
import axios from "axios";
//import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
//import moment from "moment";
import { SaPageViewEdit } from "../../../template/sa/components/layout/contents";
export default {
    components: { SaPageViewEdit },
    data() {
        return {
            pathRest: "/esameobiettivo",
            pathResource: "/esameobiettivo",
            pagelink: null,
            linkedit: null,
            showmolalloading: false,
            id: "-1",
            data: [],
        };
    },
    computed: {},
    mounted: function() {
        let me = this;
        me.id = this.$route.params.id;
        me.pagelink = "/paziente/visita/anamnesi";
        me.loadDefaultData();
    },
    methods: {
        loadDefaultData() {
            //let me = this;
        },
        loadData() {
            //let me = this;
        },
        onClick() {
            console.log("HAI CLICCATO");
        },
        onSave() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource;
            let jsonData = JSON.stringify(me.data);

            me.showmolalloading = true;
            if (me.id == "-1") {
                axios
                    .post(link, jsonData)
                    .then((response) => {
                        me.data = response.data.data;
                        me.showmolalloading = false;
                        me.$router.replace(me.routeLink).catch((err) => {
                            err;
                        });
                    })
                    .catch((error) => {
                        me.showmolalloading = false;
                        this.$bvModal
                            .msgBoxOk(error.response.data.messaggio, {
                                title: "ATTENZIONE",
                                size: "sm",
                                okVariant: "outline-danger",
                                headerClass: "sa-msg-header-danger",
                                footerClass: "p-2 border-top-0",
                                centered: true,
                            })
                            .then((value) => {
                                this.boxTwo = value;
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                    });
            } else {
                axios
                    .put(link + "/" + me.id, jsonData)
                    .then((response) => {
                        me.gruppo = response.data.data;
                        me.$refs["sa-modal-loading"].close();
                        me.$router.replace(me.routeLink).catch((err) => {
                            err;
                        });
                    })
                    .catch((error) => {
                        me.$refs["sa-modal-loading"].close();
                        this.$bvModal
                            .msgBoxOk(error.response.data.messaggio, {
                                title: "ATTENZIONE",
                                size: "sm",
                                okVariant: "outline-danger",
                                headerClass: "sa-msg-header-danger",
                                footerClass: "p-2 border-top-0",
                                centered: true,
                            })
                            .then((value) => {
                                this.boxTwo = value;
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                    });
            }
        },
    },
};
</script>
