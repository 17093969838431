<template>
    <sa-page-layout ref="pgaView" :items="items" :pathResource="pathResource" :btnAnnullaVisible="true" :btnEditVisible="true" :linkback="linkback" :linkedit="linkedit" :showModalLoading="showModalLoading">
        <template slot="toolbar-title">
            <paziente-anagrafica :linkPatient="linkPatient" :idPaziente="idPaziente"></paziente-anagrafica>
        </template>
        <template slot="toolbar-row">
            <paziente-dashboard-component :idPaziente="idPaziente"></paziente-dashboard-component>
        </template>
        <template slot="table-header">
            <b-row>
                <b-col cols="12" md="2" class="sa-text-right">{{ this.$i18n.t("therapy.lblDrug") }}:</b-col>
                <b-col cols="12" md="4">
                    <span class="sa-text-data">{{ items.farmaco }}</span>
                </b-col>
                <b-col cols="12" md="2" class="sa-text-right">{{ this.$i18n.t("therapy.lblQuantity") }}:</b-col>
                <b-col cols="12" md="4">
                    <template>
                        <span class="sa-text-data">{{ items.quantita }}</span>
                    </template>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" md="2" class="sa-text-right">{{ this.$i18n.t("therapy.lblFrequency") }}:</b-col>
                <b-col cols="12" md="4">
                    <span class="sa-text-data">{{ items.frequenza }}</span>
                </b-col>

                <b-col cols="12" md="2" class="sa-text-right">{{ this.$i18n.t("therapy.lblPosology") }}:</b-col>
                <b-col cols="12" md="4">
                    <span class="sa-text-data">{{ items.posologia }}</span>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" md="2" class="sa-text-right">{{ this.$i18n.t("therapy.lblStartDate") }}:</b-col>
                <b-col cols="12" md="4">
                    <span class="sa-text-data">{{ formatDate(items.dataInizio) }}</span>
                </b-col>
                <b-col cols="12" md="2" class="sa-text-right">{{ this.$i18n.t("therapy.lblEndDate") }}:</b-col>
                <b-col cols="12" md="4">
                    <span class="sa-text-data">{{ formatDate(items.dataFine) }}</span>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" md="2" class="sa-text-right">{{ this.$i18n.t("therapy.lblStatus") }}:</b-col>
                <b-col cols="12" md="">
                    <span class="sa-text-data">{{ items.stato }}</span>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" md="2" class="sa-text-right">{{ this.$i18n.t("therapy.lblNote") }}:</b-col>
                <b-col cols="12" md="10">
                    <span class="sa-text-data">{{ items.note }}</span>
                </b-col>
            </b-row>
        </template>
        <template slot="table-body">
            <div class="sa-tab-scroll">
                <div style="display: flex; height: 100%;flex-direction: column;">
                    <div style="max-height: 10rem"></div>
                    <div style="height: 100%">
                        <div class="b-table-sticky-header">
                            <b-table sticky-header ref="table" responsive stacked="lg" striped hover :items="somministrazioneItems" :fields="fields" sort-icon-left head-variant="light" class="sa-b-table">
                                <template #cell(index)="data">
                                    {{ data.index + 1 }}
                                </template>
                            </b-table>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <b-row>
        <b-col cols="12" md="2" class="sa-text-right"></b-col>
        <b-col cols="12" md="10">
        <b-button variant="success" @click="onSave()">Inserisci</b-button>
        </b-col>
    </b-row>  -->
        </template>
    </sa-page-layout>
</template>
<script>
import axios from "axios";
import moment from "moment";
//import DatePicker from "vue2-datepicker";
import PazienteAnagrafica from "../../components/PazienteAnagrafica.vue";
import SaPageLayout from "../../../../template/sa/components/layout/contents/SaPageLayout.vue";
import PazienteDashboardComponent from "../../components/PazienteDashboardComponent.vue";
export default {
    components: { PazienteAnagrafica, SaPageLayout, PazienteDashboardComponent },
    props: {
        utente: {
            type: Object,
            default: function() {
                return null;
            },
        },
    },
    data() {
        return {
            id: -1,
            pathRest: "/pazientiterapia",
            pathResource: "/terapia",
            linkback: "/terapia",
            linkSomministrazione: "/pazienteterapiesomministrazioni",
            linkedit: null,
            items: [],
            somministrazioneItems: [],
            showModalLoading: false,
            fields: [
                {
                    label: "N.",
                    key: "index",
                    sortable: false,
                    thStyle: "width: 4em",
                },
                {
                    label: this.$i18n.t("therapy.lblStatus"),
                    key: "stato",
                    sortable: false,
                    thStyle: "width: 4em",
                },
                {
                    label: this.$i18n.t("therapy.lblDateOfAdministration"),
                    key: "statoDataOra",
                    formatter: (value) => {
                        if (!value) {
                            return "";
                        } else {
                            return moment(new Date(value)).format("DD-MM-YYYY HH:mm:ss");
                        }
                    },
                    sortable: false,
                    thStyle: "width: 4em",
                },
                {
                    label: this.$i18n.t("therapy.lblQuantity"),
                    key: "quantitaAssunta",
                    sortable: false,
                    thStyle: "width: 4em",
                },
                {
                    label: this.$i18n.t("therapy.lblNote"),
                    key: "note",
                    sortable: false,
                    thStyle: "width: 4em",
                },
            ],
            data: {
                dataInizio: null,
                dataFine: null,
                farmaco: null,
                quantita: null,
                frequenza: null,
                posologia: null,
                stato: null,
                note: null,
            },
        };
    },
    computed: {
        idPaziente() {
            return this.$route.query.idPaziente;
        },
        linkPatient: {
            get: function() {
                let returnValue = "";
                if (this.utente.gruppo) {
                    if (this.utente.gruppo.menuGruppo) {
                        if (this.utente.gruppo.menuGruppo.length > 1) {
                            return "/paziente/view/" + this.idPaziente;
                        } else {
                            if (this.utente.gruppo.menuGruppo.length == 1) {
                                if (this.utente.gruppo.menuGruppo[0].primaryKey === "pazienti") {
                                    return "/paziente/view/" + this.idPaziente;
                                } else {
                                    return "/paziente/homepaziente";
                                }
                            }
                        }
                    }
                }
                return returnValue;
            },
        },
        // rows() {
        //     return this.somministrazioneItems.length;
        // },
    },
    mounted: function() {
        let me = this;
        me.id = this.$route.params.id;
        me.linkedit = "/paziente/terapia/edit/" + me.id + "?idPaziente=" + me.idPaziente;
        me.linkback = "/paziente/terapia?idPaziente=" + me.idPaziente;

        this.loadData();
        me.loadSomministrazione();
    },
    methods: {
        loadData() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathRest + "/";
            me.isBusy = true;
            axios
                .get(link + me.id)
                .then((response) => {
                    me.items = [];
                    me.items = response.data.data;
                })
                .catch(() => {
                    me.showModalLoading = false;
                });
        },
        loadSomministrazione() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkSomministrazione + "?idTerapia=" + me.id;
            me.isBusy = true;
            me.data.idPaziente = me.idPaziente;
            axios
                .get(link, { params: me.data })
                .then((response) => {
                    me.somministrazioneItems = [];
                    me.somministrazioneItems = response.data.data;
                    //me.$refs["SaPageList"].close();
                })
                .catch(() => {
                    me.showModalLoading = false;
                });
        },

        formatDate(value) {
            if (value) {
                return moment(new Date(value)).format("DD-MM-YYYY");
            } else {
                return "---";
            }
        },
    },
};
</script>
