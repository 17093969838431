<template>
    <div class="user-panel">
        <div class="image">
            <img :src="image" :srcset="`${image2x || image} 2x`" :class="['img-circle']" :alt="username" />
        </div>
        <div class="info">
            <router-link :to="to">{{ username }}</router-link>
            <p class="text-muted">{{ ruolo }}</p>
        </div>
    </div>
</template>

<script>
import elevation from "../../mixins/elevation";

export default {
    mixins: [elevation()],
    props: {
        image: {
            type: String,
            required: true,
        },
        image2x: {
            type: String,
            default: "",
        },
        to: {
            type: String,
            default: "",
        },
        username: {
            type: String,
            default: "",
        },
        ruolo: {
            type: String,
            default: "",
        },
    },
};
</script>
