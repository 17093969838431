<template>
  <div class="sa-page">
    <div class="content-header">
      <b-row>
        <b-col>
          <div style="float: left; margin-top: 0rem; width: 100%;">
            <span style="margin-left: 0.5rem; float: left;"> </span>
          </div>
          <template class="toolbar-title">
            <paziente-anagrafica :linkPatient="linkPatient" :idPaziente="idPaziente"></paziente-anagrafica>
          </template>
        </b-col>
        <b-col class="sa-col-toolbar">
          <div style="float: right">
            <b-button variant="success btn-toolbar float-sm-right sa-margin-right" size="sm" @click="onSave"><b-icon icon="check2"></b-icon>Salva</b-button>
            <b-button variant="danger btn-toolbar sa-margin-right float-sm-right" size="sm" @click="onBack"><b-icon icon="x"></b-icon>Annulla</b-button>
          </div>
        </b-col>
      </b-row>
      <slot class="toolbar-row">
        <paziente-dashboard-component :idPaziente="idPaziente"></paziente-dashboard-component>
      </slot>
    </div>
    <b-card>
      <div class="content">
        <div class="sa-table">
          <div class="sa-table-header"><slot name="table-header"> </slot></div>
          <div class="sa-table-body">
            <slot name="table-body">
              <b-col xs="6" sm="6" md="6" lg="6" xl="6">
                <span>KIT:</span>
                <b-form-select v-model="kit.kitId" :options="kitOptions" size="l" :value="null" value-field="kitId" text-field="kitName"></b-form-select>
              </b-col>
            </slot>
          </div>
          <!--<div class="sa-table-footer" style="height: 30px"><slot name="table-footer"></slot></div>-->
          <div class="sa-table-footer"><slot name="table-footer"></slot></div>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import PazienteAnagrafica from "./PazienteAnagrafica.vue";

import axios from "axios";
import PazienteDashboardComponent from "./PazienteDashboardComponent.vue";
export default {
  data() {
    return {
      linkback: "/paziente/hassistokits",
      pathResource: "/hassistokits/assignkit",
      pathKitlist: "/hassistokits",

      kit: { kitId: null, patientId: this.idPaziente },
      kitOptions: [{ kitId: null, kitName: "--Seleziona KIT Libero--" }],
    };
  },
  props: {
    utente: {
      type: Object,
      default: function() {
        return null;
      },
    },
  },
  components: { PazienteAnagrafica, PazienteDashboardComponent },
  computed: {
    idPaziente() {
      let me = this;
      return me.$route.query.idPaziente;
    },
    linkPatient: {
      get: function() {
        let returnValue = "";
        if (this.utente.gruppo) {
          if (this.utente.gruppo.menuGruppo) {
            if (this.utente.gruppo.menuGruppo.length > 1) {
              return "/paziente/view/" + this.idPaziente;
            } else {
              if (this.utente.gruppo.menuGruppo.length == 1) {
                if (this.utente.gruppo.menuGruppo[0].primaryKey === "pazienti") {
                  return "/paziente/view/" + this.idPaziente;
                } else {
                  return "/paziente/homepaziente";
                }
              }
            }
          }
        }
        return returnValue;
      },
    },
  },
  mounted() {
    let me = this;
    me.loadKits();
  },
  methods: {
    onBack() {
      let me = this;
      me.$router.replace(me.linkback).catch((err) => {
        err;
      });
    },
    loadKits() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathKitlist;
      axios.get(link).then((Response) => {
        Response.data.data.forEach((element) => {
          if (element.patientId === null) {
            console.log(element);
            me.kitOptions.push(element.kitId);
          }
        });
      });
    },
    onSave() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResource;
      axios
        .put(link, { kitId: this.kit.kitId, patientId: this.idPaziente })
        .then((Response) => {
          console.log(Response);
          me.$router.replace(me.linkback).catch((e) => {
            e;
          });
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>
