<template>
    <sa-page-layout ref="pgaView" :pathResource="pathResource" :btnEditVisible="true" :btnAnnullaVisible="true" :saveVisible="false" :linkback="linkback" :linkedit="linkedit" :showmolalloading="showmolalloading">
        <template slot="toolbar-title">
            <paziente-anagrafica :linkPatient="linkPatient" :idPaziente="idPaziente"></paziente-anagrafica>
        </template>
        <template slot="table-body">
            <div class="sa-tab-scroll">
                <anamnesi-view-component :idEvento="idEvento"></anamnesi-view-component>
            </div>
        </template>
    </sa-page-layout>
</template>
<script>
import PazienteAnagrafica from "../../../components/PazienteAnagrafica.vue";
import SaPageLayout from "../../../../../template/sa/components/layout/contents/SaPageLayout.vue";
import AnamnesiViewComponent from "../components/AnamnesiViewComponent.vue";
export default {
    components: { PazienteAnagrafica, SaPageLayout, AnamnesiViewComponent },
    props: {
        utente: {
            type: Object,
            default: function() {
                return null;
            },
        },
    },
    data() {
        return {
            pathResource: "/diabetologiaanamnesi",
            linkback: null,
            linkedit: null,
            showmolalloading: false,
            id: "-1",
            profili: [],
            provenienzaOption: [],
        };
    },
    computed: {
        idPaziente() {
            return this.$route.query.idPaziente;
        },
        idEvento() {
            return this.$route.params.id;
        },
        dataEventoPlaceholder() {
            return this.$i18n.t("patient.events.lblEventDate");
        },
        linkPatient: {
            get: function() {
                let returnValue = "";
                if (this.utente.gruppo) {
                    if (this.utente.gruppo.menuGruppo) {
                        if (this.utente.gruppo.menuGruppo.length > 1) {
                            return "/paziente/view/" + this.idPaziente;
                        } else {
                            if (this.utente.gruppo.menuGruppo.length == 1) {
                                if (this.utente.gruppo.menuGruppo[0].primaryKey === "pazienti") {
                                    return "/paziente/view/" + this.idPaziente;
                                } else {
                                    return "/paziente/homepaziente";
                                }
                            }
                        }
                    }
                }
                return returnValue;
            },
        },
    },
    mounted: function() {
        let me = this;
        me.id = this.$route.params.id;
        me.linkedit = "/paziente/diabetologia/anamnesi/edit/" + me.id + "?idPaziente=" + me.idPaziente;
        me.linkback = "/paziente/diabetologia/anamnesi?idPaziente=" + me.idPaziente;
    },
};
</script>
