<template>
    <sa-page-view-edit ref="pgaView" :data="data" :pathResource="pathResource" :backVisible="true" :saveVisible="true" :titolo="data.titolo" :linkback="linkback" :linkedit="linkedit" :showmolalloading="showmolalloading">
        <template slot="body">
            <b-row>
                <b-col cols="12" lg="2" md="2" class="sa-text-right"><b>{{ this.$i18n.t("pazienti.lblDiabeticHistory")}}</b></b-col>
            </b-row>

            <b-row>
                <b-col cols="12" lg="2" md="2" class="sa-text-right">{{ this.$i18n.t("pazienti.lblOrigin") }}</b-col>
                <b-col cols="12" lg="10" md="10" class="sa-text-left">
                    <b-form-input v-model="data.provenienza" size="sm" placeholder="Provenienza" required></b-form-input>
                </b-col>
            </b-row>
            <b-row>

                <b-col cols="12" lg="2" md="2" class="sa-text-right">{{ this.$i18n.t("pazienti.lblAgeOfAppearance") }}:</b-col>
                <b-col cols="6" lg="2" md="2" class="sa-text-left">
                    <b-form-input v-model="data.etaDiComparsa" size="sm" placeholder="Eta Di Comparsa" required></b-form-input>
                </b-col>

                <b-col cols="12" lg="2" md="2" class="sa-text-right">{{ this.$i18n.t("pazienti.lblSymptoms") }}:</b-col>
                <b-col cols="6" lg="6" md="6" class="sa-text-left">
                    <b-form-input v-model="data.primiSintomi" size="sm" placeholder="Primi Sintomi" required></b-form-input>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" lg="2" md="2" class="sa-text-right">{{ this.$i18n.t("pazienti.lblDiagnosis") }}:</b-col>
                <b-col cols="12" lg="10" md="10" class="sa-text-left">
                    <b-form-input v-model="data.diagnosi" size="sm" placeholder="Diagnosi" required></b-form-input>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" lg="2" md="2" class="sa-text-right">{{ this.$i18n.t("pazienti.lblReasonOfCheck") }}:</b-col>
                <b-col cols="12" lg="10" md="10" class="sa-text-left">
                    <b-form-input v-model="data.motivoDelControlloAlCentro" size="sm" placeholder="Motivo Del Controllo Al Centro" required></b-form-input>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" lg="2" md="2" class="sa-text-right">{{ this.$i18n.t("pazienti.lblCurrentSymptoms") }}:</b-col>
                <b-col cols="12" lg="10" md="10" class="sa-text-left">
                    <b-form-input v-model="data.sintomatologiaAttuale" size="sm" placeholder="Sintomatologia Attuale" required></b-form-input>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" lg="2" md="2" class="sa-text-right">{{ this.$i18n.t("pazienti.lblComplications") }}:</b-col>
                <b-col cols="12" lg="10" md="10" class="sa-text-left">
                    <b-form-input v-model="data.complicanze" size="sm" placeholder="Complicanze" required></b-form-input>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" lg="2" md="2" class="sa-text-right">{{ this.$i18n.t("pazienti.lblConnectionOtherDiseases") }}:</b-col>
            </b-row>
            <b-row>
                <b-col cols="6" lg="2" md="1" class="sa-text-right">{{ this.$i18n.t("pazienti.lblPregnancy") }}</b-col>
                <input type="checkbox" id="gravidanze" name="gravidanze" value="gravidanze">

                <b-col cols="6" lg="2" md="1" class="sa-text-right">{{ this.$i18n.t("pazienti.lblClimacteric") }}</b-col>
                <input type="checkbox" id="climaterio" name="climaterio" value="climaterio">

                <b-col cols="6" lg="2" md="1" class="sa-text-right">{{ this.$i18n.t("pazienti.lblTrauma")}}</b-col>
                <input type="checkbox" id="traumi" name="traumi" value="traumi">

                <b-col cols="6" lg="2" md="1" class="sa-text-right">{{ this.$i18n.t("pazienti.lblMedications")}}</b-col>
                <input type="checkbox" id="farmaci" name="farmaci" value="farmaci">

                <b-col cols="6" lg="2" md="1" class="sa-text-right">{{ this.$i18n.t("pazienti.lblStress")}}</b-col>
                <input type="checkbox" id="stress" name="stress" value="stress">

            </b-row>

            <b-row>
                <b-col cols="6" lg="2" md="1" class="sa-text-right">{{ this.$i18n.t("pazienti.lblDiseases")}}</b-col>
                <input type="checkbox" id="malattie" name="malattie" value="malattie">

                <b-col cols="12" lg="6" md="6" class="sa-text-left">
                    <b-form-input v-model="data.vuoto1" size="sm" placeholder="vuoto1" required></b-form-input>
                </b-col>
            </b-row>

            <b-row>
                <b-col cols="6" lg="2" md="1" class="sa-text-right">{{ this.$i18n.t("pazienti.lblMore")}}</b-col>
                <input type="checkbox" id="altro" name="altro" value="altro">

                <b-col cols="12" lg="6" md="6" class="sa-text-left">
                    <b-form-input v-model="data.vuoto2" size="sm" placeholder="vuoto2" required></b-form-input>
                </b-col>
            </b-row>

            <b-row>
                <b-col cols="12" lg="2" md="2" class="sa-text-right">{{ this.$i18n.t("pazienti.lblConcomitantDiseases") }}:</b-col>
            </b-row>

            <b-row>
                <b-col cols="6" lg="2" md="1" class="sa-text-right">{{ this.$i18n.t("pazienti.lblObesity") }}</b-col>
                <input type="checkbox" id="obesita" name="obesita" value="obesità">

                <b-col cols="6" lg="2" md="1" class="sa-text-right">{{ this.$i18n.t("pazienti.lblDyslipidemia") }}</b-col>
                <input type="checkbox" id="displidemia" name="diplidemia" value="displidemia">

                <b-col cols="6" lg="2" md="1" class="sa-text-right">{{ this.$i18n.t("pazienti.lblHypertension") }}</b-col>
                <input type="checkbox" id="ipertensione" name="ipertensione" value="ipertensione">

                <b-col cols="6" lg="2" md="1" class="sa-text-right">{{ this.$i18n.t("pazienti.lblLithiasis") }}</b-col>
                <input type="checkbox" id="litiasi" name="litiasi" value="litiasi">

                <b-col cols="6" lg="2" md="1" class="sa-text-right">{{ this.$i18n.t("pazienti.lblUlcer") }}</b-col>
                <input type="checkbox" id="ulcera" name="ulcera" value="ulcera">

            </b-row>

            <b-row>

                <b-col cols="6" lg="2" md="1" class="sa-text-right">{{ this.$i18n.t("pazienti.lblArthrosis") }}</b-col>
                <input type="checkbox" id="artrosi" name="artrosi" value="artrosi">

                <b-col cols="6" lg="2" md="1" class="sa-text-right">{{ this.$i18n.t("pazienti.lblVas") }}</b-col>
                <input type="checkbox" id="vas" name="vas" value="vas">

                <b-col cols="6" lg="2" md="1" class="sa-text-right">{{ this.$i18n.t("pazienti.lblPsychiatricDiseases") }}</b-col>
                <input type="checkbox" id="malattiepsi" name="malattiepsi" value="malattiepsi">

                <b-col cols="6" lg="2" md="1" class="sa-text-right">{{ this.$i18n.t("pazienti.lblGenitalSphere") }}</b-col>
                <input type="checkbox" id="malattiegen" name="malattiegen" value="malattiegen">

                <b-col cols="6" lg="2" md="1" class="sa-text-right">{{ this.$i18n.t("pazienti.lblBronchopneumus") }}</b-col>
                <input type="checkbox" id="broncopneumo" name="broncopneumo" value="broncopneumo">

                <b-col cols="6" lg="2" md="1" class="sa-text-right">{{ this.$i18n.t("pazienti.lblThyroid") }}</b-col>
                <input type="checkbox" id="tiroide" name="tiroide" value="tiroide">

                <b-col cols="6" lg="2" md="1" class="sa-text-right">{{ this.$i18n.t("pazienti.lblOsteoporosis") }}</b-col>
                <input type="checkbox" id="osteoporosi" name="osteoporosi" value="osteoporosi">
            </b-row>

            <b-row>
                <b-col cols="6" lg="2" md="1" class="sa-text-right">{{ this.$i18n.t("pazienti.lblOtherEndochronopathies") }}</b-col>
                <input type="checkbox" id="endocronopatie" name="endocronopatie" value="endrocronopatie">


                <b-col cols="12" lg="9" md="9" class="sa-text-left">
                    <b-form-input v-model="data.vuoto3" size="sm" placeholder="vuoto3" required></b-form-input>
                </b-col>


                <b-col cols="6" lg="2" md="1" class="sa-text-right">{{ this.$i18n.t("pazienti.lblMore") }}</b-col>
                <input type="checkbox" id="altro2" name="altro2" value="altro2">

                <b-col cols="12" lg="9" md="9" class="sa-text-left">
                    <b-form-input v-model="data.vuoto4" size="sm" placeholder="vuoto4" required></b-form-input>
                </b-col>
            </b-row>

            <b-row>
                <b-col cols="12" lg="2" md="2" class="sa-text-right">{{ this.$i18n.t("pazienti.lblTherapyPraticated") }}:</b-col>
            </b-row>

            <b-row>
                <b-col cols="6" lg="2" md="1" class="sa-text-right">{{ this.$i18n.t("pazienti.lblDiet") }}</b-col>
                <input type="checkbox" id="dieta" name="dieta" value="dieta">
                <b-col cols="12" lg="9" md="9" class="sa-text-left">
                    <b-form-input v-model="data.vuoto5" size="sm" placeholder="vuoto5" required></b-form-input>
                </b-col>
            </b-row>

            <b-row>
                <b-col cols="6" lg="2" md="1" class="sa-text-right">{{ this.$i18n.t("pazienti.lblOralAntiDiabetic") }}</b-col>
                <input type="checkbox" id="antidiab" name="antidiab" value="antidiab">
                <b-col cols="12" lg="9" md="9" class="sa-text-left">
                    <b-form-input v-model="data.vuoto6" size="sm" placeholder="vuoto6" required></b-form-input>
                </b-col>
            </b-row>

            <b-row>
                <b-col cols="6" lg="2" md="1" class="sa-text-right">{{ this.$i18n.t("pazienti.lblInsulin") }}</b-col>
                <input type="checkbox" id="insulina" name="insulina" value="insulina">
                <b-col cols="12" lg="9" md="9" class="sa-text-left">
                    <b-form-input v-model="data.vuoto7" size="sm" placeholder="vuoto7" required></b-form-input>
                </b-col>
            </b-row>

            <b-row>
                <b-col cols="6" lg="2" md="1" class="sa-text-right">{{ this.$i18n.t("pazienti.lblOtherMedications") }}</b-col>
                <input type="checkbox" id="altrifarmaci" name="altrifarmaci" value="altrifarmaci">
                <b-col cols="12" lg="9" md="9" class="sa-text-left">
                    <b-form-input v-model="data.vuoto8" size="sm" placeholder="vuoto8" required></b-form-input>
                </b-col>
            </b-row>

            <b-row>
                <b-col cols="12" lg="2" md="2" class="sa-text-right">{{ this.$i18n.t("pazienti.lblProgressiveEvent") }}:</b-col>
                <b-col cols="6" lg="4" md="4" class="sa-text-left">
                    <b-form-input v-model="data.progressivoEvento" size="sm" placeholder="Progressivo Evento" required></b-form-input>
                </b-col>
                <b-col cols="12" lg="2" md="2" class="sa-text-right">{{ this.$i18n.t("pazienti.lblDateEvent") }}:</b-col>
                <b-col cols="12" lg="4" md="4" class="sa-text-right">
                    <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="data.dataNascita" type="date" placeholder="Data Nascita"></date-picker>
                </b-col>
            </b-row>

        </template>
    </sa-page-view-edit>
</template>
<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import { SaPageViewEdit } from "../../../template/sa/components/layout/contents";
import moment from "moment";
import axios from "axios";
export default {
    components: { SaPageViewEdit, DatePicker },
   // components: { SaPageViewEdit},
    data() {
        return {
            pathRest: "/eventidiabetologiaanamnesi",
            pathResource: "/eventidiabetologiaanamnesi",
            linkback: "/paziente",
            // linkback: "/paziente", // DA CONTROLLARE IN TUTTI Diabetologia (singolare o plurale?)
            linkedit: null,
            showmolalloading: false,
            id: "-1",
            data: {},
        };
    },
    computed: {
        dataAavvioData: {
            get: function() {
                return new Date(this.data.avvioData);
            },
            set: function(value) {
                this.data.avvioData = moment(String(value)).valueOf();
            },
        },
    },
    mounted: function() {
        let me = this;
        me.id = this.$route.params.id;
        if (me.id !== "-1") {
            me.linkback = me.linkback + "/view/" + me.id;
        }
        me.loadData();
    },
    methods: {
        formatDate(value) {
            if (value) {
                return moment(new Date(value)).format("DD-MM-YYYY HH:mm:ss");
            } else {
                return "---";
            }
        },
        loadData() {
            let me = this;
            me.showmolalloading = false;
            //:if (me.id === "-1") {
            //    me.linkback = "/pazientidiabetologia";
            //    me.showmolalloading = false;
            //} else {
            //    me.linkback = "/pazientidiabetologia/view/" + me.id;
            //    me.showmolalloading = true;
            //    let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
            //    axios.get(link + me.id).then((response) => {
            //        me.data = response.data.data;
            //        me.showmolalloading = false;
            //    });
            //}
        },
        onSave() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource;
            let jsonData = JSON.stringify(me.data);

            me.showmolalloading = true;
            if (me.id == "-1") {
                axios
                    .post(link, jsonData)
                    .then((response) => {
                        me.data = response.data.data;
                        me.showmolalloading = false;
                        me.$router.replace(me.routeLink).catch((err) => {
                            err;
                        });
                    })
                    .catch((error) => {
                        me.showmolalloading = false;
                        this.$bvModal
                            .msgBoxOk(error.response.data.messaggio, {
                                title: "ATTENZIONE",
                                size: "sm",
                                okVariant: "outline-danger",
                                headerClass: "sa-msg-header-danger",
                                footerClass: "p-2 border-top-0",
                                centered: true,
                            })
                            .then((value) => {
                                this.boxTwo = value;
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                    });
            } else {
                axios
                    .put(link + "/" + me.id, jsonData)
                    .then((response) => {
                        me.gruppo = response.data.data;
                        me.$refs["sa-modal-loading"].close();
                        me.$router.replace(me.routeLink).catch((err) => {
                            err;
                        });
                    })
                    .catch((error) => {
                        me.$refs["sa-modal-loading"].close();
                        this.$bvModal
                            .msgBoxOk(error.response.data.messaggio, {
                                title: "ATTENZIONE",
                                size: "sm",
                                okVariant: "outline-danger",
                                headerClass: "sa-msg-header-danger",
                                footerClass: "p-2 border-top-0",
                                centered: true,
                            })
                            .then((value) => {
                                this.boxTwo = value;
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                    });
            }
        },
        onBack() {
            let me = this;
            me.$router.replace(me.routeLink).catch((err) => {
                err;
            });
        },
    },
};
</script>