<template>
    <div class="sa-page">
        <div class="content-header">
            <div class="card-header sa-card-header-custom">
                <div style="float: left">
                    <b-button v-if="backVisible" class="float-sm-left btn-toolbar sa-btn-icon-text" variant="secondary sa-margin-left" size="sm" @click="onBack">
                        <b-icon icon="chevron-left" variant="secondary"></b-icon>
                        Indietro
                    </b-button>
                </div>
                <div class="sa-card-header-custom-title">
                    <span>{{ titolo }}</span>
                </div>
                <div style="float: right; data: in-line">
                    <b-button-group>
                        <b-button v-if="editVisible" variant="primary btn-toolbar sa-margin-right" size="sm" @click="onEdit"><b-icon icon="pencil"></b-icon>Modifica</b-button>
                        <b-button v-if="saveVisible" variant="success btn-toolbar sa-margin-right" size="sm" @click="onSave"><b-icon icon="check2"></b-icon>Salva</b-button>
                        <print-component v-if="printVisible" :reportName="reportName" :data="data"></print-component>
                    </b-button-group>
                    <slot name="btn-left"></slot>
                </div>
            </div>
        </div>
        <b-card>
            <div class="content">
                <div class="sa-table">
                    <div class="sa-table-filter">
                        <slot name="filter"></slot>
                    </div>
                    <div class="sa-table-header-page" style="height: 50px"><slot name="header"></slot></div>
                    <div class="sa-table-body-page">
                        <slot name="body"></slot>
                    </div>
                    <div class="sa-table-footer-page" style="height: 30px"><slot name="footer"></slot></div>
                </div>
            </div>
        </b-card>
        <sa-modal-loading ref="sa-modal-loading" />
        <b-modal ref="mdlStampaReferto" size="xl" style="height: 100%;">
            <b-embed type="iframe" :src="stampaReport" allowfullscreen></b-embed>
        </b-modal>
    </div>
</template>
<script>
import axios from "axios";
import { SaModalLoading } from "../../../../../components/modal";
import PrintComponent from '../../../../../app/utility/components/PrintComponent.vue';
export default {
    components: { SaModalLoading, PrintComponent },
    props: {
        titolo: String,
        linkback: String,
        linkedit: String,
        //linkStampa: String,
        reportName: String,
        pathResource: String,
        //stampaReport: String,
        showmolalloading: Boolean,
        backVisible: Boolean,
        editVisible: { type: Boolean, default: false },
        saveVisible: { type: Boolean, default: false },
        printVisible: { type: Boolean, default: false },
        data: { type: Object },
    },
    data(){
        return{
            stampaReport:null,
        }
    },
    watch: {
        showmolalloading(value) {
            let me = this;
            if (value) {
                me.$refs["sa-modal-loading"].show();
            } else {
                setTimeout(me.$refs["sa-modal-loading"].close(), 200);
            }
        },
    },
    methods: {
        onBack() {
            let me = this;
            me.$router.replace(me.linkback).catch((err) => {
                err;
            });
        },
        onEdit() {
            let me = this;
            me.$router.replace(me.linkedit).catch((err) => {
                err;
            });
        },
        // onPrint(){
        //     let me = this;
        //     let link = process.env.VUE_APP_PATH_API + me.linkStampa;
        //         axios
        //             .post(link, {reportName: me.reportName, data: me.data})
        //             .then((response) => {
        //                 me.$refs.mdlStampaReferto.show();
        //                 me.stampaReport = "data:application/pdf;base64," + response.data.base64;
        //             })
        // },
        onSave() {
            let me = this;
            me.$refs["sa-modal-loading"].show();
            if (me.data.id === "-1" || me.data.id === null || me.data.id === undefined) {
                let link = process.env.VUE_APP_PATH_API + me.pathResource;
                axios
                    .post(link, JSON.stringify(me.data))
                    .then((response) => {
                        me.gruppo = response.data.data;
                        me.$refs["sa-modal-loading"].close();
                        me.$router.replace(me.linkback).catch((err) => {
                            err;
                        });
                    })
                    .catch((error) => {
                        me.$refs["sa-modal-loading"].close();
                        this.$bvModal
                            .msgBoxOk(error.response.data.messaggio, {
                                title: "ATTENZIONE",
                                size: "sm",
                                okVariant: "outline-danger",
                                headerClass: "sa-msg-header-danger",
                                footerClass: "p-2 border-top-0",
                                centered: true,
                            })
                            .then((value) => {
                                this.boxTwo = value;
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                    });
            } else {
                let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.data.id;
                axios
                    .put(link, JSON.stringify(me.data))
                    .then(() => {
                        //me.linkback = me.linkback + "/view/" + response.data.data.id;
                        me.$refs["sa-modal-loading"].close();
                        me.$router.replace(me.linkback).catch((err) => {
                            err;
                        });
                    })
                    .catch((error) => {
                        me.$refs["sa-modal-loading"].close();
                        this.$bvModal
                            .msgBoxOk(error.response.data.messaggio, {
                                title: "ATTENZIONE",
                                size: "sm",
                                okVariant: "outline-danger",
                                headerClass: "sa-msg-header-danger",
                                footerClass: "p-2 border-top-0",
                                centered: true,
                            })
                            .then((value) => {
                                console.log(value);
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                    });
            }
        },
    },
};
</script>
