<template>
  <sa-page-layout :showModalLoading="showModalLoading" :btnNewVisible="btnNewVisible" :linkedit="linkedit" :btnRefreshVisible="true" @refresh="onRefresh">
    <template slot="toolbar-title">
      <paziente-anagrafica :linkPatient="linkPatient" :idPaziente="idPaziente"></paziente-anagrafica>
    </template>
    <template slot="toolbar-row">
      <paziente-dashboard-component :idPaziente="idPaziente"></paziente-dashboard-component>
    </template>
    <!-- <template slot="toolbar">
      <b-button class="float-sm-right" variant="success btn-toolbar sa-margin-right" size="sm" @click="onAdd"><b-icon icon="plus" variant="secondary"></b-icon>{{ this.$i18n.t("global.lblBtnNew") }}</b-button>
      <b-button class="float-sm-right" variant="info btn-toolbar" size="sm" @click="onRefresh" v-b-modal.modal-center><b-icon icon="arrow-clockwise" variant="secondary" />Refresh</b-button>
    </template> -->
    <template slot="table-header">
      <b-row>
        <b-col>
          <span>{{ this.$i18n.t("patient.rangeConfig.lblNotes") }}</span>
        </b-col>
      </b-row>
    </template>
    <template slot="table-body">
      <div class="sa-tab-scroll">
        <!-- <b-row v-for="item in items" :key="item.id">
                    <b-col cols="6">{{ item.misureNome }}</b-col>
                    <b-col cols="6"><input-range-component :precision="0" :scala="2000" :center="1200" :maxValueAlert="maxValueAlert" :maxValueWarning="maxValueWarning" :minValueWarning="minValueWarning" :minValueAlert="minValueAlert"></input-range-component></b-col>
                </b-row> -->
        <ul v-for="item in items" :key="item.id">
          <li v-if="verificaRange(item)">
            <span>
              <strong>{{ item.misureNome }}</strong>
            </span>
            <b-row>
              <b-col cols="1"
                ><span class="sa-form-label">{{ getTitle("global.lblMaxAllert") }} </span><b-form-input type="number" v-model="item.maxRangeAllert"/>
                <b-form-input v-if="item.misureNome === 'total_sleep_time'" readonly type="text" :placeholder="formatToHours(item.maxRangeAllert)"></b-form-input
              ></b-col>
              <b-col cols="1"
                ><span class="sa-form-label"> {{ getTitle("global.lblMaxWarning") }}</span> <b-form-input type="number" v-model="item.maxRangeWarning"/><b-form-input
                  v-if="item.misureNome === 'total_sleep_time'"
                  readonly
                  type="text"
                  :placeholder="formatToHours(item.maxRangeWarning)"
                ></b-form-input
              ></b-col>
              <b-col cols="1"
                ><span class="sa-form-label"> {{ getTitle("global.lblMinWarning") }} </span><b-form-input type="number" v-model="item.minRangeWarning"/><b-form-input
                  v-if="item.misureNome === 'total_sleep_time'"
                  readonly
                  type="text"
                  :placeholder="formatToHours(item.minRangeWarning)"
                ></b-form-input
              ></b-col>
              <b-col cols="1"
                ><span class="sa-form-label">{{ getTitle("global.lblMinAllert") }}</span> <b-form-input type="number" v-model="item.minRangeAllert"/><b-form-input
                  v-if="item.misureNome === 'total_sleep_time'"
                  readonly
                  type="text"
                  :placeholder="formatToHours(item.minRangeAllert)"
                ></b-form-input
              ></b-col>
              <b-col cols="7">
                <input-range-component
                  :index="item.id"
                  :precision="getPrecision(item)"
                  :scala="getScala(item)"
                  :center="getCenter(item)"
                  :maxValueAlert="item.maxRangeAllert"
                  :maxValueWarning="item.maxRangeWarning"
                  :minValueWarning="item.minRangeWarning"
                  :minValueAlert="item.minRangeAllert"
                  @update="onUpdateRange"
                  @updateRangeRight="onUpdateRangeRight"
                ></input-range-component>
              </b-col>
              <b-col cols="1">
                <b-button class="no-text" @click="salva(item)" variant="outline-info"><b-icon icon="check-square"></b-icon></b-button>
              </b-col>
            </b-row>
            <hr />
          </li>
        </ul>
      </div>
    </template>
  </sa-page-layout>
</template>
<script>
import axios from "axios";
import { SaPageLayout } from "../../../template/sa/components/layout/contents";
import PazienteAnagrafica from "./PazienteAnagrafica.vue";
import UtilityMixin from "../../utility/UtilityMixin.js";
import PazienteDashboardComponent from "./PazienteDashboardComponent.vue";
import InputRangeComponent from "../../utility/components/InputRangeComponent.vue";

export default {
  props: {
    utente: {
      type: Object,
      default: function() {
        return null;
      },
    },
  },
  mixins: [UtilityMixin],
  components: { SaPageLayout, PazienteAnagrafica, PazienteDashboardComponent, InputRangeComponent },
  computed: {
    idPaziente() {
      return this.$route.query.idPaziente;
    },

    rows() {
      return this.items.length;
    },
    btnNewVisible: {
      get: function() {
        let me = this;
        return UtilityMixin.methods.verificaPermessi(me.utente, "misurerange", 2);
      },
    },
    readOnly: {
      get: function() {
        let me = this;
        return !UtilityMixin.methods.verificaPermessi(me.utente, "misurerange", 2);
      },
    },
    btnHiddenEdit: {
      get: function() {
        let me = this;
        return !UtilityMixin.methods.verificaPermessi(me.utente, "misurerange", 2);
      },
    },
    btnHiddenDelete: {
      get: function() {
        let me = this;
        return !UtilityMixin.methods.verificaPermessi(me.utente, "misurerange", 2);
      },
    },
    linkPatient: {
      get: function() {
        let returnValue = "";
        if (this.utente.gruppo) {
          if (this.utente.gruppo.menuGruppo) {
            if (this.utente.gruppo.menuGruppo.length > 1) {
              return "/paziente/view/" + this.idPaziente;
            } else {
              if (this.utente.gruppo.menuGruppo.length == 1) {
                if (this.utente.gruppo.menuGruppo[0].primaryKey === "pazienti") {
                  return "/paziente/view/" + this.idPaziente;
                } else {
                  return "/paziente/homepaziente";
                }
              }
            }
          }
        }
        return returnValue;
      },
    },

    SliderMinValueAlert: {
      get: function() {
        var val = parseInt(this.minValueAlert);
        return val;
      },
      set: function(val) {
        val = parseInt(val);
        this.minValueAlert = val;
      },
    },
    SliderMaxValueAlert: {
      get: function() {
        var val = parseInt(this.maxValueAlert);
        return val;
      },
      set: function(val) {
        val = parseInt(val);
        this.maxValueAlert = val;
      },
    },
    SliderMinValueWarning: {
      get: function() {
        var val = parseInt(this.minValueWarning);
        return val;
      },
      set: function(val) {
        let value = parseInt(val);
        // console.log(value);
        this.minValueWarning = value;
      },
    },
    SliderMaxValueWarning: {
      get: function() {
        var val = parseInt(this.maxValueWarning);
        return val;
      },
      set: function(val) {
        val = parseInt(val);
        this.maxValueWarning = val;
      },
    },
  },
  data() {
    return {
      maxValueAlert: 0,
      maxValueWarning: 0,
      minValueWarning: 0,
      minValueAlert: 0,
      pathResource: "/pazientemisurerange",
      pagelink: "/paziente/rangeconfiguration",
      linkedit: "/paziente/rangeconfiguration",
      linktipodieta: "",
      perPage: 10,
      currentPage: 1,
      componentKey: 0,
      value: [0, 100],
      min: 0,
      max: 250,
      enableCross: false,
      array: 10,
      note: "",
      filter: null,
      showModalLoading: false,
      id: "-1",
      filtro: {},
      filterOn: [],
      fields: [
        {
          label: this.$i18n.t("patient.rangeConfig.lblMeasurementName"),
          key: "misureNome",
          thStyle: "width: 3rem",
        },
        {
          label: this.$i18n.t("patient.rangeConfig.lblMinAlertValue"),
          key: "minRangeAllert",
          thStyle: "width: 2rem",
          variant: "danger",
        },
        {
          label: this.$i18n.t("patient.rangeConfig.lblMinWarningValue"),
          key: "minRangeWarning",
          thStyle: "width: 2rem",
          variant: "warning",
        },
        {
          label: "",
          key: "save",
          thStyle: "width: 2rem;",
          tdClass: "text-center",
        },
        //{ class: "sa-table-column-action-single", thStyle: "width: 10rem" ,key: "actions", label: "" },
      ],
      items: [],
      data: {
        misureNome: null,
        idPaziente: null,
        minRangeAllert: 0,
        maxRangeAllert: 0,
        minRangeWarning: 0,
        maxRangeWarning: 0,
        createUser: null,
        createDate: null,
        updateUser: null,
        updateDate: null,
        proprietario: null,
      },
    };
  },
  created: function() {},
  mounted: function() {
    let me = this;
    me.id = this.$route.params.id;
    me.data.idPaziente = this.$route.query.idPaziente;
    me.loadData();
  },
  methods: {
    formatToHours(value) {
      return new Date(value * 1000).toISOString().substr(11, 8);
    },
    stampa(item) {
      console.log(item.id);
    },
    async salva(item) {
      let me = this;
      me.showModalLoading = true;
      let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + item.id;
      if (item.createDate === null) {
        item.createDate = Number(new Date());
      }
      item.updateDate = Number(new Date());
      item.minRangeAllert = Number(item.minRangeAllert);
      item.maxRangeAllert = Number(item.maxRangeAllert);
      item.minRangeWarning = Number(item.minRangeWarning);
      item.maxRangeWarning = Number(item.maxRangeWarning);
      // console.log(item);
      await axios
        .put(link, item)
        .then((res) => {
          me.showModalLoading = false;
          alert(res.data.messaggio);
        })
        .catch((err) => {
          me.showModalLoading = false;
          alert(err.toString());
        });
      //window.location.reload();
    },
    cancella(id) {
      let me = this;
      me.showModalLoading = true;
      let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + id;
      axios
        .delete(link)
        .then(() => {
          me.showModalLoading = false;
          // console.log(response);
          alert("Range misurazione " + id + " eliminato correttamente");
        })
        .catch((err) => {
          me.showModalLoading = false;
          alert(err.toString());
        });
    },
    forceRerender() {
      this.componentKey += 1;
    },
    getTitle(value) {
      return this.$i18n.t(value);
    },
    loadData() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResource + "?idPaziente=" + me.data.idPaziente;
      me.showModalLoading = true;
      axios
        .get(link, { params: me.filtro })
        .then((response) => {
          me.items = [];
          me.items = response.data.data;
          // console.log(me.items);
          me.showModalLoading = false;
        })
        .catch(() => {
          me.showModalLoading = false;
        });
    },
    onSubmit() {
      let me = this;
      let sessionStorage = window.sessionStorage;
      sessionStorage["filtro"] = JSON.stringify(me.filtro);
      me.loadData();
    },
    onReset() {
      let me = this;
      me.filtro = {};
      let sessionStorage = window.sessionStorage;
      sessionStorage["filtro"] = JSON.stringify(me.filtro.codiceSacca);
      me.loadData();
    },
    onRefresh() {
      let me = this;
      me.loadData();
    },
    getScala(item) {
      let returnValue = 0;
      switch (item.misureNome) {
        case "Diastolic Blood Pressure (mmHg)":
          returnValue = 1400;
          break;
        case "Body Temperature (celsius)":
          returnValue = 180;
          break;
        case "Systolic Blood Pressure (mmHg)":
          returnValue = 2200;
          break;
        case "sleep_score":
          returnValue = 2000;
          break;
        case "total_sleep_time":
          returnValue = 500000;
          break;
        case "Heart Pulse (bpm) - only for BPM devices":
          returnValue = 2500;
          break;
        case "steps":
          returnValue = 150000;
          break;
        case "Weight (kg)":
          returnValue = 1000;
          break;
        case "out_of_bed_count":
          returnValue = 100;
          break;

        default:
          returnValue = 0;
      }
      return returnValue;
    },
    getCenter(item) {
      let returnValue = 0;
      switch (item.misureNome) {
        case "Diastolic Blood Pressure (mmHg)":
          returnValue = 700;
          break;
        case "Body Temperature (celsius)":
          returnValue = 360;
          break;
        case "Systolic Blood Pressure (mmHg)":
          returnValue = 1100;
          break;
        case "sleep_score":
          returnValue = 1000;
          break;
        case "total_sleep_time":
          returnValue = 270000;
          break;
        case "Heart Pulse (bpm) - only for BPM devices":
          returnValue = 700;
          break;
        case "steps":
          returnValue = 65000;
          break;
        case "Weight (kg)":
          returnValue = 500;
          break;
        case "out_of_bed_count":
          returnValue = 50;
          break;

        default:
          returnValue = 0;
      }
      return returnValue;
    },
    getPrecision(item) {
      let returnValue = 0;
      switch (item.misureNome) {
        case "Body Temperature (celsius)":
          returnValue = 1;
          break;
        default:
          returnValue = 0;
      }
      return returnValue;
    },
    verificaRange(item) {
      let returnValue = false;
      switch (item.misureNome) {
        case "Diastolic Blood Pressure (mmHg)":
          returnValue = true;
          break;
        case "Body Temperature (celsius)":
          returnValue = true;
          break;
        case "Systolic Blood Pressure (mmHg)":
          returnValue = true;
          break;
        case "sleep_score":
          returnValue = true;
          break;
        case "total_sleep_time":
          returnValue = true;
          break;
        case "Heart Pulse (bpm) - only for BPM devices":
          returnValue = true;
          break;
        case "steps":
          returnValue = true;
          break;
        case "Weight (kg)":
          returnValue = true;
          break;
        case "out_of_bed_count":
          returnValue = true;
          break;

        default:
          returnValue = false;
      }
      return returnValue;
    },
    onUpdateRange(value, index) {
      this.items.forEach((element) => {
        if (element.id === index) {
          element.maxRangeAllert = parseFloat(value[0]);
          element.maxRangeWarning = parseFloat(value[1]);
          //   element.minRangeWarning = parseFloat(value[0]);
          //   element.minRangeAllert = parseFloat(value[1]);
        }
      });
    },
    onUpdateRangeRight(value, index) {
      this.items.forEach((element) => {
        if (element.id === index) {
          element.minRangeWarning = parseFloat(value[0]);
          element.minRangeAllert = parseFloat(value[1]);
        }
      });
    },
  },
};
</script>

<style scoped>
.centered {
  align-self: center;
}
.body {
  text-align: center !important;
}
.little {
  width: 50% !important;
}
</style>
