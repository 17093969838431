<template>
    <sa-page-list>
        <template slot="toolbar">
            <div style="float: right">
                <b-button-group>
                    <b-button class="float-sm-right " variant="success btn-toolbar sa-margin-right" size="sm" @click="onAdd"><b-icon icon="plus"></b-icon>{{ this.$i18n.t("global.lblBtnNew") }}</b-button>
                    <b-button class="float-sm-right " variant="info btn-toolbar" size="sm" @click="onRefresh" v-b-modal.modal-center><b-icon icon="arrow-clockwise" />Refresh</b-button>
                    <print-component :reportName="reportName" :data="data"></print-component>
                </b-button-group>
            </div>
        </template>

        <template slot="table-filter">
            <b-form @submit.prevent="onSubmit">
                <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                    <b-col sm="6">
                        <b-form-input v-model="filtro.numeroRichiesta" type="search" id="nome" placeholder="Numero Richiesta"></b-form-input>
                    </b-col>
                    <b-col sm="6">
                        <b-form-select id="inline-form-custom-select-pref" v-model="filtro.statoAttuale" class="mb-2 mr-sm-2 mb-sm-0" :options="statoOption" :value="null"></b-form-select>
                    </b-col>
                </b-row>
                <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                    <b-col sm="6">
                        <b-form-select id="inline-form-custom-select-pref" v-model="filtro.gruppoSanguigno" class="mb-2 mr-sm-2 mb-sm-0" :options="gruppoSanguignoOption" :value="null"></b-form-select>
                    </b-col>
                    <b-col sm="6">
                        <b-form-select id="inline-form-custom-select-pref" v-model="filtro.fattoreRh" class="mb-2 mr-sm-2 mb-sm-0" :options="fattoreRhOption" :value="null"></b-form-select>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="sa-padding-right text-right">
                        <b-button type="submit" variant="info">{{ this.$i18n.t("global.lblFind") }}</b-button>
                        <b-button type="reset" v-on:click="onReset" variant="danger">{{ this.$i18n.t("global.lblResetFilter") }}</b-button>
                    </b-col>
                </b-row>
            </b-form>
        </template>

        <template slot="table-header">
            <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col lg="3">
                    <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
                </b-col>
                <b-col lg="9">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
            </b-row>
        </template>

        <template slot="table-body">
            <div class="b-table-sticky-header">
                <b-table sticky-header ref="table" id="tbl" :filter="filter" fixed stacked="xl" striped hover :items="data" :fields="fields" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                    <template v-slot:cell(numeroRichiesta)="{ item }">
                        <router-link class="sa-edit-link" :to="'/bancaematicarichiestereparto/view/' + item.id">{{ item.numeroRichiesta }}</router-link>
                        <br />
                    </template>
                    <template v-slot:cell(gruppoSanguigno)="{ item }">
                        <a> {{ item.gruppoSanguigno }} {{ onModificaFattoreRh(item.fattoreRh) }} </a>
                    </template>
                    <template v-slot:cell(nominativo)="{ item }">
                        <a> {{ item.nomePaziente }} {{ item.cognomePaziente }} </a>
                    </template>
                    <template #cell(actions)="row">
                        <b-button size="sm" variant="btn btn-primary waves-effect  waves-light width-sm no-text" style="margin-right:3px" @click="onCambioStato(row.item, row.index, $event.target)">
                            <b-icon icon="pencil-square"></b-icon>
                        </b-button>
                    </template>
                </b-table>
            </div>
        </template>

        <template slot="table-footer">
            <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col lg="3">
                    <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
                </b-col>
                <b-col lg="9">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
            </b-row>
            <b-modal ref="tstModal" id="my-modal" @ok="onOk">
                <b-row>
                    <b-form-select id="inline-form-custom-select-pref" v-model="items.stato" class="mb-2 mr-sm-2 mb-sm-0" :options="statoOption" :value="null"></b-form-select>
                </b-row>
                <b-row>
                    <b-form-textarea id="textarea-no-resize" v-model="items.note" placeholder="Inserisci Note" rows="3" no-resize></b-form-textarea>
                </b-row>
            </b-modal>
        </template>
    </sa-page-list>
</template>
<script>
import axios from "axios";
import moment from "moment";
import { SaPageList } from "../../../template/sa/components/layout/contents";
import PrintComponent from "../../utility/components/PrintComponent.vue";
export default {
    components: { SaPageList, PrintComponent },
    computed: {
        rows() {
            return this.data.length;
        },
    },
    data() {
        return {
            perPage: 10,
            pathResourceAggiornaStato: "/aggiornaStato",
            linkstato: "/bancaematicatrasfusionirichiesterepartistatitipi",
            reportName: "ReportListaRichiestaReparto",
            currentPage: 1,
            note: "",
            filter: null,
            id: "-1",
            pathRest: "/bancaematicatrasfusionirichiestereparti",
            pathEdit: "/bancaematicarichiestereparto",

            linkgrupposanguigno: "/bancheematichegruppisanguigni",
            linkfattorerh: "/bancaematicafattorirh",

            filtro: { statoAttuale: null, gruppoSanguigno: null, fattoreRh: null },
            fields: [
                {
                    label: "Num.",
                    key: "numeroRichiesta",
                    sortable: true,
                    thStyle: "width: 5rem",
                },
                {
                    label: "Cod. Prov.",
                    key: "codiceProvettaPretrasfusionale",
                    thStyle: "width: 6rem",
                    sortable: true,
                },
                {
                    label: "Cod. Diagnosi",
                    key: "codiceDiagnosi",
                    thStyle: "width: 8rem",
                    sortable: true,
                },

                {
                    label: "Gruppo Sang.",
                    key: "gruppoSanguigno",
                    thStyle: "width: 8rem",
                    sortable: true,
                },
                {
                    label: "Reparto",
                    key: "reparto",
                    thStyle: "width: 8rem",
                    sortable: true,
                },
                {
                    label: "Nom. Paziente",
                    key: "nominativo",
                    thStyle: "width: 8rem",
                    sortable: true,
                },
                {
                    label: "Sacche",
                    key: "numeroSaccheRichieste",
                    thStyle: "width: 6rem",
                    sortable: true,
                },
                {
                    label: "Grado Urg.",
                    key: "gradoUrgenza",
                    thStyle: "width: 7rem",
                    sortable: true,
                },

                {
                    label: "Stato",
                    key: "statoAttuale",
                    sortable: true,
                },
                {
                    label: "Data Scadenza",
                    key: "statoAttualeDataOra",
                    formatter: (value) => {
                        if (!value) {
                            return "";
                        } else {
                            return moment(new Date(value)).format("DD-MM-YYYY HH:mm:ss");
                        }
                    },
                    thStyle: "width: 8rem",
                    sortable: true,
                },
                {
                    label: "",
                    key: "actions",
                    thStyle: "width: 3rem",
                },
            ],
            data: [],
            statoOption: [{ value: null, text: "--Seleziona Valore--" }],
            gruppoSanguignoOption: [{ value: null, text: "--Gruppo Sanguigno--" }],
            fattoreRhOption: [{ value: null, text: "--Fattore Rh--" }],
            items: { stato: null, note: "", idBancaEmatica: "" },
        };
    },

    created: function() {},
    mounted: function() {
        let me = this;
        me.id = this.$route.params.id;
        me.loadData();
        me.loadStato();
        me.loadGruppoSanguigno();
        me.loadFattoreRh();
    },
    methods: {
        loadData() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathRest;
            me.isBusy = true;
            axios
                .get(link, { params: me.filtro })
                .then((response) => {
                    me.data = [];
                    me.data = response.data.data;
                    for (let element in me.data) {
                        me.data[element]["_cellVariants"] = {};
                        if (me.data[element]["statoAttualeDataOra"] - 259200 > Date.now()) {
                            me.data[element]["_cellVariants"].statoAttualeDataOra = "success";
                        } else if (me.data[element]["statoAttualeDataOra"] - 259200 <= Date.now()) {
                            me.data[element]["_cellVariants"].statoAttualeDataOra = "danger";
                        }

                        if (me.data[element]["statoAttuale"] === "RICHIESTA") {
                            me.data[element]["_cellVariants"].statoAttuale = "success";
                        } else if (me.data[element]["statoAttuale"] == "EVASA") {
                            me.data[element]["_cellVariants"].statoAttuale = "danger";
                        }
                    }
                })
                .catch(() => {
                    me.showmolalloading = false;
                });
        },
        onSubmit() {
            let me = this;
            let sessionStorage = window.sessionStorage;
            sessionStorage["filtro"] = JSON.stringify(me.filtro);
            me.loadData();
        },
        onReset() {
            let me = this;
            me.filtro = {};
            let sessionStorage = window.sessionStorage;
            sessionStorage["filtro"] = JSON.stringify(me.filtro);
            me.loadData();
        },
        onAdd() {
            let me = this;
            me.$router.replace(me.pathEdit + "/edit/-1").catch((err) => {
                err;
            });
        },
        onRefresh() {
            let me = this;
            me.loadData();
        },

        onCambioStato(item) {
            let me = this;
            me.$refs.tstModal.show();
            me.items.idBancaEmatica = item.id;
            me.items.note = item.note;
        },
        loadStato() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkstato;
            axios.get(link).then((response) => {
                response.data.data.forEach((element) => {
                    me.statoOption.push({ value: element.valore, text: element.valore });
                });
            });
        },
        loadGruppoSanguigno() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkgrupposanguigno;
            axios.get(link).then((response) => {
                response.data.data.forEach((element) => {
                    me.gruppoSanguignoOption.push({ value: element.valore, text: element.valore });
                });
            });
        },
        loadFattoreRh() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkfattorerh;
            axios.get(link).then((response) => {
                response.data.data.forEach((element) => {
                    me.fattoreRhOption.push({ value: element.valore, text: element.valore });
                });
            });
        },
        onModificaFattoreRh(valore) {
            if (valore === "POSITIVO") {
                return "+";
            } else {
                return "-";
            }
        },
        onOk() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathRest + me.pathResourceAggiornaStato;
            let jsonData = JSON.stringify(me.data);
            axios.post(link, jsonData).then(() => {
                me.showmolalloading = false;
                me.loadData();
            });
        },
    },
};
</script>
