<template>
  <div class="sa-form-section">
    <b-row>
      <b-col xs="12" sm="3" md="3" lg="3" xl="3">
        <span class="sa-form-label">{{ this.$i18n.t("patient.idoneity.lblSG") }}</span> <br />
        <b-form-input v-model="data.sg" tipe="number" step="0.01"></b-form-input>
        <span class="sa-form-label">{{ this.$i18n.t("patient.idoneity.lblPh") }}</span> <br />
        <b-form-input v-model="data.ph" tipe="number" step="0.01"></b-form-input>
        <span class="sa-form-label">{{ this.$i18n.t("patient.idoneity.lblUBJValue") }}</span
        ><br />
        <b-form-input v-model="data.valoreUbj" tipe="number" step="0.01"></b-form-input>
        <span class="sa-form-label">{{ this.$i18n.t("patient.idoneity.lblGLU") }}</span
        ><br />
        <b-form-select v-model="data.glu" no-resize options=""></b-form-select>
      </b-col>
      <b-col xs="12" sm="12" md="3" lg="3" xl="3">
        <span class="sa-form-label">{{ this.$i18n.t("patient.idoneity.lblNIT") }}</span
        ><br />
        <b-form-select v-model="data.nit" no-resize options=""></b-form-select>
        <span class="sa-form-label">{{ this.$i18n.t("patient.idoneity.lblLeu") }}</span
        ><br />
        <b-form-select v-model="data.leu" no-resize options=""></b-form-select>
        <span class="sa-form-label">{{ this.$i18n.t("patient.idoneity.lblPRO") }}</span
        ><br />
        <b-form-select v-model="data.pro" no-resize options=""></b-form-select>
        <span class="sa-form-label">{{ this.$i18n.t("patient.idoneity.lblEry") }}</span
        ><br />
        <b-form-select v-model="data.ery" no-resize options=""></b-form-select>
      </b-col>
      <b-col xs="12" sm="12" md="3" lg="3" xl="3">
        <span class="sa-form-label">{{ this.$i18n.t("patient.idoneity.lblKET") }}</span
        ><br />
        <b-form-select v-model="data.ket" no-resize options=""></b-form-select>
        <span class="sa-form-label">{{ this.$i18n.t("patient.idoneity.lblLeuValue") }}</span
        ><br />
        <b-form-input v-model="data.valoreLeu" tipe="number" step="0.01"></b-form-input>
        <span class="sa-form-label">{{ this.$i18n.t("patient.idoneity.lblPROValue") }}</span
        ><br />
        <b-form-input v-model="data.valorePro" tipe="number" step="0.01"></b-form-input>
      </b-col>
      <b-col xs="12" sm="12" md="3" lg="3" xl="3">
        <span class="sa-form-label">{{ this.$i18n.t("patient.idoneity.lblKETValue") }}</span
        ><br />
        <b-form-input v-model="data.valoreKet" tipe="number" step="0.01"></b-form-input>
        <span class="sa-form-label">{{ this.$i18n.t("patient.idoneity.lblUBJ") }}</span
        ><br />
        <b-form-select v-model="data.ubj" no-resize options=""></b-form-select>
        <span class="sa-form-label">{{ this.$i18n.t("patient.idoneity.lblBIL") }}</span
        ><br />
        <b-form-select v-model="data.bil" no-resize options=""></b-form-select>
      </b-col>
    </b-row>
    <b-row>
      <b-col xs="12" sm="12" md="12" lg="12" xl="12">
        <span class="sa-form-label">{{ this.$i18n.t("patient.idoneity.lblReport") }}</span
        ><br />
        <b-form-textarea v-model="data.refertoUrine" no-resize></b-form-textarea>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
        <b-row>
          <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
            <strong>Stato </strong><br />
            {{ data.firma }}
          </b-col>
        </b-row>
        <hr />
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  props: ["data"],
};
</script>
