var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',{staticStyle:{"margin-left":"0.5rem","margin-right":"0.5rem"}},[_c('b-col',{attrs:{"lg":"3"}},[_c('p',[_vm._v(_vm._s(this.$i18n.t("global.lblTotalRecords"))+": "+_vm._s(_vm.rows))])]),_c('b-col',{attrs:{"lg":"9"}},[_c('b-pagination',{attrs:{"total-rows":_vm.rows,"per-page":_vm.perPage,"align":"right","size":"sm"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1),_c('div',{staticClass:"b-table-sticky-header"},[_c('b-table',{ref:"table",staticClass:"sa-b-table",attrs:{"sticky-header":"","filter":null,"stacked":"xl","striped":"","hover":"","items":_vm.items,"fields":_vm.fields,"current-page":_vm.currentPage,"per-page":_vm.perPage,"sort-icon-left":"","head-variant":"light"},scopedSlots:_vm._u([{key:"cell(hrMin)",fn:function(ref){
var item = ref.item;
return [_c('span',{class:'badge badge-' + _vm.returnBadgeRischio('hrMin', item.hrMin) + ' badge-pill',staticStyle:{"font-size":"16px"}},[_vm._v(_vm._s(item.hrMin))])]}},{key:"cell(hrMax)",fn:function(ref){
var item = ref.item;
return [_c('span',{class:'badge badge-' + _vm.returnBadgeRischio('hrMax', item.hrMax) + ' badge-pill',staticStyle:{"font-size":"16px"}},[_vm._v(_vm._s(item.hrMax))])]}},{key:"cell(hrAverage)",fn:function(ref){
var item = ref.item;
return [_c('span',{class:'badge badge-' + _vm.returnBadgeRischio('hrAverage', item.hrAverage) + ' badge-pill',staticStyle:{"font-size":"16px"}},[_vm._v(_vm._s(item.hrAverage))])]}},{key:"cell(calories)",fn:function(ref){
var item = ref.item;
return [_c('span',{class:'badge badge-' + _vm.returnBadgeRischio('calories', item.calories) + ' badge-pill',staticStyle:{"font-size":"16px"}},[_vm._v(_vm._s(item.calories))])]}},{key:"cell(totalcalories)",fn:function(ref){
var item = ref.item;
return [_c('span',{class:'badge badge-' + _vm.returnBadgeRischio('totalcalories', item.totalcalories) + ' badge-pill',staticStyle:{"font-size":"16px"}},[_vm._v(_vm._s(item.totalcalories))])]}},{key:"cell(steps)",fn:function(ref){
var item = ref.item;
return [_c('span',{class:'badge badge-' + _vm.returnBadgeRischio('steps', item.steps) + ' badge-pill',staticStyle:{"font-size":"16px"}},[_vm._v(_vm._s(item.steps))])]}},{key:"cell(active)",fn:function(ref){
var item = ref.item;
return [_c('span',{class:'badge badge-' + _vm.returnBadgeRischio('active', item.active) + ' badge-pill',staticStyle:{"font-size":"16px"}},[_vm._v(_vm._s(item.active))])]}},{key:"cell(distance)",fn:function(ref){
var item = ref.item;
return [_c('span',{class:'badge badge-' + _vm.returnBadgeRischio('distance', item.distance) + ' badge-pill',staticStyle:{"font-size":"16px"}},[_vm._v(_vm._s(item.distance))])]}},{key:"cell(sleep)",fn:function(ref){
var item = ref.item;
return [_c('span',{class:'badge badge-' + _vm.returnBadgeRischio('sleep', item.sleep) + ' badge-pill',staticStyle:{"font-size":"16px"}},[_vm._v(_vm._s(_vm.convertHour(item.sleep)))])]}},{key:"cell(diastolicBloodPressure)",fn:function(ref){
var item = ref.item;
return [_c('span',{class:'badge badge-' + _vm.returnBadgeRischio('diastolicBloodPressure', item.diastolicBloodPressure) + ' badge-pill',staticStyle:{"font-size":"16px"}},[_vm._v(_vm._s(item.diastolicBloodPressure))])]}},{key:"cell(systolicBloodPressure)",fn:function(ref){
var item = ref.item;
return [_c('span',{class:'badge badge-' + _vm.returnBadgeRischio('systolicBloodPressure', item.systolicBloodPressure) + ' badge-pill',staticStyle:{"font-size":"16px"}},[_vm._v(_vm._s(item.systolicBloodPressure))])]}},{key:"cell(heartPulse)",fn:function(ref){
var item = ref.item;
return [_c('span',{class:'badge badge-' + _vm.returnBadgeRischio('heartPulse', item.heartPulse) + ' badge-pill',staticStyle:{"font-size":"16px"}},[_vm._v(_vm._s(item.heartPulse))])]}},{key:"cell(spo2)",fn:function(ref){
var item = ref.item;
return [_c('span',{class:'badge badge-' + _vm.returnBadgeRischio('spo2', item.spo2) + ' badge-pill',staticStyle:{"font-size":"16px"}},[_vm._v(_vm._s(item.spo2))])]}}])})],1),_c('b-row',{staticStyle:{"margin-left":"0.5rem","margin-right":"0.5rem"}},[_c('b-col',{attrs:{"lg":"3"}},[_c('p',[_vm._v(_vm._s(this.$i18n.t("ambulatori.lblTotalRecords"))+": "+_vm._s(_vm.rows))])]),_c('b-col',{attrs:{"lg":"9"}},[_c('b-pagination',{attrs:{"total-rows":_vm.rows,"per-page":_vm.perPage,"align":"right","size":"sm"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }