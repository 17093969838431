<template>
    <sa-page-view-edit ref="pgaView" :data="data" :pathResource="pathResource" :backVisible="true" :saveVisible="true" :titolo="data.nome" :linkback="linkback" :linkedit="linkedit" :showmolalloading="showmolalloading">
        <template slot="body">
            <div style="height: 100%; overflow: auto;">
                <b-row>
                    <b-col cols="12" md="2" class="sa-text-right">Nome:</b-col>
                    <b-col cols="12" md="10">
                        <b-form-input v-model="data.nome" placeholder="Nome"></b-form-input>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="12" md="2" class="sa-text-right">Tipo:</b-col>
                    <b-col cols="12" md="10">
                        <b-form-input v-model="data.tipo" placeholder="Tipo"></b-form-input>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="12" md="2" class="sa-text-right">Note:</b-col>
                    <b-col cols="12" md="10">
                        <b-form-textarea v-model="data.note" placeholder="Note" rows="7"></b-form-textarea>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="12" md="2" class="sa-text-right">Parametro:</b-col>
                    <b-col cols="12" md="10">
                        <div class="text-danger">{{ jsonerror }}</div>
                        <b-form-textarea id="jsonText" v-model="data.parametro" placeholder="Parametro" rows="7"></b-form-textarea>
                        <pre>{{ prettyFormat }}</pre>
                    </b-col>
                </b-row>
            </div>
        </template>
    </sa-page-view-edit>
</template>
<script>
import { SaPageViewEdit } from "../../../template/sa/components/layout/contents";
//import moment from "moment";
import axios from "axios";
export default {
    components: { SaPageViewEdit },
    data() {
        return {
            pathResource: "/configparameter",
            linkback: "/configparameter",
            linkedit: null,
            showmolalloading: false,
            id: "-1",
            profili: [],
            jsonstr: "",
            jsonerror: "",
            data: {},
        };
    },
    computed: {
        prettyFormat: function() {
            let me = this;
            me.jsonerror = "";
            let jsonValue = "";
            try {
                jsonValue = JSON.parse(me.data.parametro);
            } catch (e) {
                console.log(e.message);
                me.jsonerror = JSON.stringify(e.message);
                var textarea = document.getElementById("jsonText");
                if (me.jsonerror.indexOf("position") > -1) {
                    // highlight error position
                    var positionStr = me.jsonerror.lastIndexOf("position") + 8;
                    var posi = parseInt(me.jsonerror.substr(positionStr, me.jsonerror.lastIndexOf('"')));
                    if (posi >= 0) {
                        textarea.setSelectionRange(posi, posi + 1);
                    }
                }
                console.log(me.jsonerror);
                return "";
            }
            return JSON.stringify(jsonValue, null, 2);
        },
    },
    mounted: function() {
        let me = this;
        me.id = this.$route.params.id;
        me.data.id = this.$route.params.id;
        me.loadData();
    },
    methods: {
        loadData() {
            let me = this;

            if (me.id === "-1") {
                me.showmolalloading = false;
            } else {
                me.showmolalloading = false;
                let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
                axios.get(link + me.id).then((response) => {
                    me.data = response.data.data;
                    me.showmolalloading = false;
                });
            }
        },
        /*onSave() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathRest;
            let jsonData = JSON.stringify(me.data);

            me.$refs["sa-modal-loading"].show();
            if (me.id == "-1") {
                axios
                    .post(link, jsonData)
                    .then((response) => {
                        me.gruppo = response.data.data;
                        me.$refs["sa-modal-loading"].close();
                        me.$router.replace(me.pathRest).catch((err) => {
                            err;
                        });
                    })
                    .catch((error) => {
                        me.$refs["sa-modal-loading"].close();
                        this.$bvModal
                            .msgBoxOk(error.response.data.messaggio, {
                                title: "ATTENZIONE",
                                size: "sm",
                                okVariant: "outline-danger",
                                headerClass: "sa-msg-header-danger",
                                footerClass: "p-2 border-top-0",
                                centered: true,
                            })
                            .then((value) => {
                                this.boxTwo = value;
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                    });
            } else {
                axios
                    .put(link + "/" + me.id, jsonData)
                    .then((response) => {
                        me.gruppo = response.data.data;
                        me.$refs["sa-modal-loading"].close();
                        me.$router.replace(me.pathRest).catch((err) => {
                            err;
                        });
                    })
                    .catch((error) => {
                        me.$refs["sa-modal-loading"].close();
                        this.$bvModal
                            .msgBoxOk(error.response.data.messaggio, {
                                title: "ATTENZIONE",
                                size: "sm",
                                okVariant: "outline-danger",
                                headerClass: "sa-msg-header-danger",
                                footerClass: "p-2 border-top-0",
                                centered: true,
                            })
                            .then((value) => {
                                this.boxTwo = value;
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                    });
            }
        },
        onBack() {
            let me = this;
            me.$router.replace(me.pathRest).catch((err) => {
                err;
            });
        },*/
    },
};
</script>
