<template>
  <sa-page-layout :showModalLoading="showModalLoading" :linkedit="linkedit" :btnNewVisible="true">
    <template slot="toolbar-title">
      <paziente-anagrafica :linkPatient="linkPatient" :idPaziente="idPaziente"></paziente-anagrafica>
    </template>
    <template slot="table-header">
      <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
        <b-col cols="6">
          <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
        </b-col>
        <b-col cols="6">
          <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
        </b-col>
      </b-row>
    </template>
    <template slot="table-body">
      <div class="b-table-sticky-header">
        <b-table sticky-header ref="table" responsive stacked="lg" striped hover :items="items" :fields="fields" sort-icon-left head-variant="light" class="sa-b-table">
          <template v-slot:cell(dataEvento)="{ item }">
            <router-link class="sa-edit-link" :to="'/paziente/gastroenterologia/ecoendoscopia/view/' + item.id + '?idPaziente=' + idPaziente"> {{ formatDate(item.dataEvento) }} </router-link>
          </template>
        </b-table>
      </div>
    </template>
    <template slot="table-footer">
      <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
        <b-col cols="6">
          <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
        </b-col>
        <b-col cols="6">
          <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
        </b-col>
      </b-row>
    </template>
  </sa-page-layout>
</template>
<script>
import axios from "axios";
import moment from "moment";
import SaPageLayout from "../../../../../template/sa/components/layout/contents/SaPageLayout.vue";
import PazienteAnagrafica from "../../../components/PazienteAnagrafica.vue";
import UtilityMixin from "../../../../utility/UtilityMixin";
export default {
  mixins: [UtilityMixin],
  components: { SaPageLayout, PazienteAnagrafica },
  props: {
    utente: {
      type: Object,
      default: function() {
        return null;
      },
    },
  },
  data() {
    return {
      showModalLoading: false,
      linkedit: null,
      currentPage: 1,
      id: "-1",
      perPage: 10,
      filtro: {},
      items: [],
      pathResource: "/ecoendoscopie",
      fields: [
        {
          label: this.$i18n.t("patient.gastro.lblEventDate"),
          key: "dataEvento",
          sortable: true,
          formatter: (value) => {
            if (!value) {
              return "";
            } else {
              return moment(new Date(value)).format("DD-MM-YYYY");
            }
          },
        },
      ],
    };
  },
  mounted() {
    let me = this;
    me.id = this.$route.params.id;
    me.loadData();
    me.linkedit = "/paziente/gastroenterologia/ecoendoscopia";
  },
  computed: {
    idPaziente() {
      return this.$route.query.idPaziente;
    },
    rows() {
      return this.items.length;
    },
    linkPatient: {
      get: function() {
        let returnValue = "";
        if (this.utente.gruppo) {
          if (this.utente.gruppo.menuGruppo) {
            if (this.utente.gruppo.menuGruppo.length > 1) {
              return "/paziente/view/" + this.idPaziente;
            } else {
              if (this.utente.gruppo.menuGruppo.length == 1) {
                if (this.utente.gruppo.menuGruppo[0].primaryKey === "pazienti") {
                  return "/paziente/view/" + this.idPaziente;
                } else {
                  return "/paziente/homepaziente";
                }
              }
            }
          }
        }
        return returnValue;
      },
    },
  },
  methods: {
    loadData() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResource + "?idPaziente=" + me.idPaziente;
      axios
        .get(link)
        .then((response) => {
          me.items = response.data.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>
