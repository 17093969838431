<template>
    <div style="height: 100%">
        <b-row class="content-header">
            <b-col>
                <paziente-anagrafica :linkPatient="linkPatient" :idPaziente="idPaziente"></paziente-anagrafica>
            </b-col>
            <b-col>
                <b-button v-b-modal.modal-xl-1 class="float-sm-right" variant="info btn-toolbar" size="sm"><b-icon icon="speedometer" variant="secondary"></b-icon>Dashboard</b-button>
                <b-button v-b-modal.modal-xl-2 class="float-sm-right" variant="primary btn-toolbar" size="sm" @click="onAllegati"><b-icon icon="paperclip" variant="secondary"></b-icon>Allegati</b-button>

                <b-modal id="modal-xl-1" size="xl" title="Dashboard">
                    <date-picker format="DD-MM-YYYY HH:mm" value-type="timestamp" v-model="selezioneDataIniziale" type="datetime" placeholder="Seleziona data iniziale"></date-picker>

                    <div style="height: 30px"></div>

                    <date-picker format="DD-MM-YYYY HH:mm" value-type="timestamp" v-model="selezioneDataFinale" type="datetime" placeholder="Seleziona data finale"></date-picker>

                    <div style="height: 20px"></div>

                    <b-button v-b-modal.modal-xl-10 variant="primary" @click="onSubmit"><b-icon icon="check"></b-icon>{{ this.$i18n.t("patient.lblBtnConferma") }}</b-button>
                    <div class="sa-graphic-dashboard">
                        <div class="sa-grafico sa-grafico-item-1"><line-chart :chart-data="dataSpo2" :height="290" v-if="loaded"></line-chart></div>
                        <div class="sa-grafico sa-grafico-item-2"><line-chart :chart-data="dataTemperatura" :height="290" v-if="loaded"></line-chart></div>
                        <div class="sa-grafico sa-grafico-item-3"><line-chart :chart-data="dataFreqCardiaca" :height="290" v-if="loaded"></line-chart></div>
                        <div class="sa-grafico sa-grafico-item-4"><line-chart :chart-data="dataPressioneTot" :height="290" v-if="loaded"></line-chart></div>
                    </div>
                </b-modal>

                <b-modal id="modal-xl-2" size="xl" title="Allegati">
                    <div class="b-table-sticky-header">
                        <b-table sticky-header ref="table" :filter="filter" responsive stacked="lg" striped hover :items="items" :fields="fields" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                            <template v-slot:cell(actions)="row">
                                <b-button size="sm" variant="btn btn-purple waves-effect  waves-light width-sm no-text" style="margin-right: 3px" @click="onClick(row.item, row.index, $event.target)" v-b-modal.visualizzaAllegato>
                                    <b-icon icon="search"></b-icon>
                                </b-button>
                            </template>
                        </b-table>
                    </div>
                </b-modal>
            </b-col>
        </b-row>
        <b-row style="height: 100%">
            <iframe allow="camera;microphone" src="https://ehealth.hassisto.xyz/teleconsulto.php?patientId=0b99941a-8f07-4c5c-820a-f2d388637c23&callURL=https://cdpt.in/NDU1MTEy" width="100%" height="100%" frameborder="0"></iframe>
            <!--<iframe allow="camera;microphone" src="https://ihealth.ibb.cnr.it/MQTTButton_DEMO.php?patientId=fe9fce45-8cfe-47e1-bdb3-7112b7082706&callURL=https://cdpt.in/NDU1MTEy" width="100%" height="100%" frameborder="0"></iframe>-->
        </b-row>
        <template slot="table-footer">
            <b-modal size="xl" id="visualizzaAllegato" title="Visualizza Allegato" @show="onShow" @click="onClick">
                <template>
                    <b-embed type="iframe" :src="file" allowfullscreen></b-embed>
                </template>
            </b-modal>
        </template>
    </div>
</template>
<script>
import PazienteAnagrafica from "./PazienteAnagrafica.vue";
import DatePicker from "vue2-datepicker";
import LineChart from "./LineChart.js";
import axios from "axios";
import moment from "moment";
export default {
    props: {
        utente: {
            type: Object,
            default: function() {
                return null;
            },
        },
    },
    components: { PazienteAnagrafica, DatePicker, LineChart },
    computed: {
        idPaziente() {
            return this.$route.query.idPaziente;
        },
        linkPatient: {
            get: function() {
                let returnValue = "";
                if (this.utente.gruppo) {
                    if (this.utente.gruppo.menuGruppo) {
                        if (this.utente.gruppo.menuGruppo.length > 1) {
                            return "/paziente/view/" + this.idPaziente;
                        } else {
                            if (this.utente.gruppo.menuGruppo.length == 1) {
                                if (this.utente.gruppo.menuGruppo[0].primaryKey === "pazienti") {
                                    return "/paziente/view/" + this.idPaziente;
                                } else {
                                    return "/paziente/homepaziente";
                                }
                            }
                        }
                    }
                }
                return returnValue;
            },
        },
    },
    data() {
        return {
            pathRest: "/paziente",
            pathMisure: "/pazientemisure",
            loaded: false,
            dataSpo2: null,
            dataTemperatura: null,
            dataFreqCardiaca: null,
            dataPressioneTot: null,
            selezioneDataIniziale: null,
            selezioneDataFinale: null,

            // Parametri per la modal di allegati

            pathAllegati: "/allegati",
            perPage: 10,
            currentPage: 1,
            filter: null,
            file: null,
            fields: [
                {
                    label: this.$i18n.t("pazienti.lblUploadDate"),
                    key: "dataCaricamento",
                    sortable: true,
                    thStyle: "width: 7rem",
                    formatter: (value) => {
                        return moment(new Date(value)).format("DD-MM-YYYY");
                    },
                },
                {
                    label: this.$i18n.t("pazienti.lblDocumentType"),
                    key: "tipoDocumento",
                    thStyle: "width: 7rem",
                    sortable: true,
                },
                {
                    label: this.$i18n.t("pazienti.lblDescription"),
                    key: "descrizione",
                    thStyle: "width: 7rem",
                    sortable: true,
                },
                {
                    label: "",
                    key: "actions",
                    thStyle: "width: 3rem",
                },
                //{ class: "sa-table-column-action-single", key: "actions", label: "" },
            ],

            items: [],

            // Parametri per la modal di dashboard
        };
    },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        let dateStart = new Date();
        dateStart.setHours(0, 0, 0, 0);
        let dateEnd = new Date();
        dateEnd.setHours(23, 59, 59, 0);

        me.selezioneDataIniziale = dateStart.getTime();

        me.selezioneDataFinale = dateEnd.getTime();
    },
    methods: {
        loadData() {
            let me = this;
            let dataSelezionataIniziale = moment(me.selezioneDataIniziale).format("YYYY-MM-DD HH:mm:ss");
            let dataSelezionataFinale = moment(me.selezioneDataFinale).format("YYYY-MM-DD HH:mm:ss");
            let link = process.env.VUE_APP_PATH_API + me.pathMisure + "?idPaziente=" + me.idPaziente + "&dal=" + dataSelezionataIniziale + "&al=" + dataSelezionataFinale;
            axios
                .get(link)
                .then((response) => {
                    me.items = response.data.data;

                    let spoLabels = [];
                    let spoData = [];

                    let temperatureLabels = [];
                    let temperatureData = [];

                    let freqCardiacaLabels = [];
                    let freqCardiacaData = [];
                    let pressioneMinLabels = [];
                    let pressioneMinData = [];

                    let pressioneMaxLabels = [];
                    let pressioneMaxData = [];

                    for (let index = 0; index < me.items.length; index++) {
                        switch (me.items[index].measurename) {
                            case "spo2":
                                spoLabels.push(moment(me.items[index].istant).format("DD/MM/YYYY, HH:mm:ss"));
                                spoData.push(me.items[index].value);
                                break;
                            case "temperature":
                                temperatureLabels.push(moment(me.items[index].istant).format("DD/MM/YYYY, HH:mm:ss"));
                                temperatureData.push(me.items[index].value);
                                break;

                            case "pulse":
                                freqCardiacaLabels.push(moment(me.items[index].istant).format("DD/MM/YYYY, HH:mm:ss"));
                                freqCardiacaData.push(me.items[index].value);
                                break;

                            case "pressureMin":
                                pressioneMinLabels.push(moment(me.items[index].istant).format("DD/MM/YYYY, HH:mm:ss"));
                                pressioneMinData.push(me.items[index].value);
                                break;

                            case "pressureMax":
                                pressioneMaxLabels.push(moment(me.items[index].istant).format("DD/MM/YYYY, HH:mm:ss"));
                                pressioneMaxData.push(me.items[index].value);
                                break;
                        }
                    }

                    this.dataSpo2 = {
                        labels: spoLabels,
                        datasets: [
                            {
                                label: "Spo2",
                                data: spoData,
                                lineTension: 0,

                                backgroundColor: "rgba(255, 100, 100, 0.2)",
                                pointBorderColor: "rgba(255, 100, 100, 3)",
                                bgColor: "'rgba(100, 0, 0, 0)'",
                                dotColor: "'rgba(100, 10, 255, 1.8)'",
                                borderColor: "'rgba(2, 100, 100, 3)'",
                            },
                        ],
                    };

                    this.dataTemperatura = {
                        labels: temperatureLabels,
                        datasets: [
                            {
                                label: "Temperatura",
                                data: temperatureData,
                                lineTension: 0,

                                backgroundColor: "rgba(100, 100, 100, 0.2)",
                                pointBorderColor: "rgba(255, 100, 100, 1)",
                                bgColor: "'rgba(100, 0, 0, 0)'",
                                dotColor: "'rgba(100, 10, 255, 1.8)'",
                                borderColor: "'rgba(100, 10, 255, 1)'",
                            },
                        ],
                    };

                    this.dataFreqCardiaca = {
                        labels: freqCardiacaLabels,
                        datasets: [
                            {
                                label: "Frequenza Cardiaca",
                                data: freqCardiacaData,
                                lineTension: 0,

                                backgroundColor: "rgba(100, 0, 0, 0.2)",
                                pointBorderColor: "rgba(255, 100, 100, 1)",
                                bgColor: "'rgba(100, 0, 0, 0)'",
                                dotColor: "'rgba(100, 10, 255, 1.8)'",
                                borderColor: "'rgba(100, 10, 255, 1)'",
                            },
                        ],
                    };

                    this.dataPressioneTot = {
                        labels: pressioneMinLabels,
                        datasets: [
                            {
                                label: "Pressione Min",
                                data: pressioneMinData,
                                lineTension: 0,

                                backgroundColor: "rgba(100, 0, 0, 0.2)",
                                pointBorderColor: "rgba(255, 100, 100, 1)",
                                bgColor: "'rgba(100, 0, 0, 0)'",
                                dotColor: "'rgba(100, 10, 255, 1.8)'",
                                borderColor: "'rgba(100, 10, 255, 1)'",
                            },
                            {
                                label: "Pressione Max",
                                data: pressioneMaxData,
                                lineTension: 0,

                                backgroundColor: "rgba(100, 10, 0, 0.2)",
                                pointBorderColor: "rgba(255, 100, 100, 1)",
                                bgColor: "'rgba(100, 0, 0, 0)'",
                                dotColor: "'rgba(100, 10, 255, 1.8)'",
                                borderColor: "'rgba(100, 10, 255, 1)'",
                            },
                        ],
                    };

                    this.loaded = true;
                })
                .catch((e) => {
                    me.errors.push(e);
                });
        },
        onSubmit() {
            let me = this;
            me.loadData();
        },
        onAllegati() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathAllegati + "?idPaziente=";
            me.isBusy = true;
            axios
                .get(link + me.idPaziente)
                .then((response) => {
                    me.items = [];
                    me.items = response.data.data;
                    me.data = response.data.data;
                    me.perPage = me.data.length;
                })
                .catch(() => {
                    me.showmolalloading = false;
                });
        },
        onClick(item) {
            let me = this;
            me.file = "data:application/pdf;base64," + item.file;
        },
        onShow() {},
    },
};
</script>
<style scoped>
.sa-graphic-dashboard {
    display: grid;
    grid-column-gap: 50px;
    grid-row-gap: 50px;
}

.sa-grafico {
    min-height: 400px;
    max-height: 400px;
    /*border: 1px solid #cdcdcd;*/
}
.sa-grafico-item-1 {
    grid-row-start: 1;
    grid-row-end: 2;
    grid-column-start: 1;
    grid-column-end: 2;
}

.sa-grafico-item-2 {
    grid-row-start: 1;
    grid-row-end: 2;
    grid-column-start: 2;
    grid-column-end: 3;
}
.sa-grafico-item-3 {
    grid-row-start: 2;
    grid-row-end: 3;
    grid-column-start: 1;
    grid-column-end: 2;
}
.sa-grafico-item-4 {
    grid-row-start: 2;
    grid-row-end: 3;
    grid-column-start: 2;
    grid-column-end: 3;
}
</style>
