import { Bar, mixins } from "vue-chartjs";
const { reactiveProp } = mixins;

export default {
    extends: Bar,
    mixins: [reactiveProp],
    //props: ["options"],
    props: {
        options: {type: Object},
        backgroundColor: {type: Object},
        bgColor:{type: String},
        dotColor:{type: String},
        borderColor:{type: String},
        responsive: true,
        mantainAspectRatio: false,
    },
    mounted() {
        // this.chartData is created in the mixin.
        // If you want to pass options please create a local options object
        this.renderChart(this.chartData, this.options);
    },
};
