<template>
    <div>
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="4">
                        <strong>Trofismo </strong><br />
                        <b-form-textarea v-model="data.trofismo" no-resize></b-form-textarea>
                        <b-input-group style="margin-top: 5px">
                            <b-form-select class="mb-2 mr-sm-2 mb-sm-0" :options="trofismoOptions" :value="null" value-field="descrizione" text-field="descrizione" @input="onTrofismoInput($event)"></b-form-select>
                            <b-button variant="outline-secondary" style="margin-right: 5px;" size="sm" @click="onClickAggiungiDescrizione(data.trofismo, linkTrofismo)"><b-icon icon="plus"></b-icon></b-button>
                            <b-button variant="outline-secondary" size="sm" @click="onDeleteDescrizione(data.trofismo, linkTrofismo, trofismoOptions)"><b-icon icon="trash"></b-icon></b-button>
                        </b-input-group>
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="4">
                        <strong>Peso(kg) </strong><br />
                        <b-form-input v-model="data.peso"></b-form-input>
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="4">
                        <strong>Altezza(cm) </strong><br />
                        <b-form-input v-model="data.altezza"></b-form-input>
                    </b-col>
                </b-row>
                <hr />
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="4">
                        <strong>Apparato Locomotore </strong><br />
                        <b-form-textarea v-model="data.apparatoLocomotore" no-resize></b-form-textarea>
                        <b-input-group style="margin-top: 5px">
                            <b-form-select class="mb-2 mr-sm-2 mb-sm-0" :options="apparatoLocomotoreOptions" :value="null" value-field="descrizione" text-field="descrizione" @input="onApparatoLocomotoreInput($event)"></b-form-select>
                            <b-button variant="outline-secondary" size="sm" @click="onClickAggiungiDescrizione(data.apparatoLocomotore, linkApparatoLocomotore)"><b-icon icon="plus"></b-icon></b-button>
                            <b-button variant="outline-secondary" size="sm" @click="onDeleteDescrizione(data.apparatoLocomotore, linkApparatoLocomotore, apparatoLocomotoreOptions)"><b-icon icon="trash"></b-icon></b-button>
                        </b-input-group>
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="4">
                        <strong>Torace </strong><br />
                        <b-form-textarea v-model="data.torace" no-resize></b-form-textarea>
                        <b-input-group style="margin-top: 5px">
                            <b-form-select class="mb-2 mr-sm-2 mb-sm-0" :options="toraceOptions" :value="null" value-field="descrizione" text-field="descrizione" @input="onToraceInput($event)"></b-form-select>
                            <b-button variant="outline-secondary" size="sm" @click="onClickAggiungiDescrizione(data.torace, linkTorace)"><b-icon icon="plus"></b-icon></b-button>
                            <b-button variant="outline-secondary" size="sm" @click="onDeleteDescrizione(data.torace, linkTorace, toraceOptions)"><b-icon icon="trash"></b-icon></b-button>
                        </b-input-group>
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="4">
                        <strong>Apparato Respiratorio </strong><br />
                        <b-form-textarea v-model="data.apparatoRespiratorio" no-resize></b-form-textarea>
                        <b-input-group style="margin-top: 5px">
                            <b-form-select class="mb-2 mr-sm-2 mb-sm-0" :options="apparatoRespiratorioOptions" :value="null" value-field="descrizione" text-field="descrizione" @input="onApparatoRespiratorioInput($event)"></b-form-select>
                            <b-button variant="outline-secondary" size="sm" @click="onClickAggiungiDescrizione(data.apparatoRespiratorio, linkApparatoRespiratorio)"><b-icon icon="plus"></b-icon></b-button>
                            <b-button variant="outline-secondary" size="sm" @click="onDeleteDescrizione(data.apparatoRespiratorio, linkApparatoRespiratorio, apparatoRespiratorioOptions)"><b-icon icon="trash"></b-icon></b-button>
                        </b-input-group>
                    </b-col>
                </b-row>
                <hr />
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="4">
                        <strong>Apparato Circolatorio </strong><br />
                        <b-form-textarea v-model="data.apparatoCardiocircolatorio" no-resize></b-form-textarea>
                        <b-input-group style="margin-top: 5px">
                            <b-form-select class="mb-2 mr-sm-2 mb-sm-0" :options="apparatoCardiocircolatorioOptions" :value="null" value-field="descrizione" text-field="descrizione" @input="onApparatoCardiocircolatorioInput($event)"></b-form-select>
                            <b-button variant="outline-secondary" size="sm" @click="onClickAggiungiDescrizione(data.apparatoCardiocircolatorio, linkApparatoCardiocircolatorio)"><b-icon icon="plus"></b-icon></b-button>
                            <b-button variant="outline-secondary" size="sm" @click="onDeleteDescrizione(data.apparatoCardiocircolatorio, linkApparatoCardiocircolatorio, apparatoCardiocircolatorioOptions)"><b-icon icon="trash"></b-icon></b-button>
                        </b-input-group>
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="4">
                        <strong>P.A. riposo </strong><br />
                        <strong style="margin-right:10px;">Sistolica</strong>
                        <strong style="margin-right:10px;">Diastolica</strong>
                        <b-row>
                            <b-form-input v-model="data.pressioneArteriosaRiposoSistolica" style="width:50px;" maxlength="3"></b-form-input> <span style="width: 10px"></span>
                            <span style="width: 10px"></span>
                            <b-form-input v-model="data.pressioneArteriosaRiposoDiastolica" style="width:50px" maxlength="3"></b-form-input>
                        </b-row>
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="4">
                        <strong>Addome </strong><br />
                        <b-form-textarea v-model="data.addome" no-resize></b-form-textarea>
                        <b-input-group style="margin-top: 5px">
                            <b-form-select class="mb-2 mr-sm-2 mb-sm-0" :options="addomeOptions" :value="null" value-field="descrizione" text-field="descrizione" @input="onAddomeInput($event)"></b-form-select>
                            <b-button variant="outline-secondary" size="sm" @click="onClickAggiungiDescrizione(data.addome, linkAddome)"><b-icon icon="plus"></b-icon></b-button>
                            <b-button variant="outline-secondary" size="sm" @click="onDeleteDescrizione(data.addome, linkAddome, addomeOptions)"><b-icon icon="trash"></b-icon></b-button>
                        </b-input-group>
                    </b-col>
                </b-row>
                <hr />
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="3">
                        <strong>Organi genitali </strong><br />
                        <b-form-textarea v-model="data.organiGenitali" no-resize></b-form-textarea>
                        <b-input-group style="margin-top: 5px">
                            <b-form-select class="mb-2 mr-sm-2 mb-sm-0" :options="organiGenitaliOptions" :value="null" value-field="descrizione" text-field="descrizione" @input="onOrganiGenitaliInput($event)"></b-form-select>
                            <b-button variant="outline-secondary" size="sm" @click="onClickAggiungiDescrizione(data.organiGenitali, linkOrganiGenitali)"><b-icon icon="plus"></b-icon></b-button>
                            <b-button variant="outline-secondary" size="sm" @click="onDeleteDescrizione(data.organiGenitali, linkOrganiGenitali, organiGenitaliOptions)"><b-icon icon="trash"></b-icon></b-button>
                        </b-input-group>
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="3">
                        <strong>Arti </strong><br />
                        <b-form-textarea v-model="data.arti" no-resize></b-form-textarea>
                        <b-input-group style="margin-top: 5px">
                            <b-form-select class="mb-2 mr-sm-2 mb-sm-0" :options="artiOptions" :value="null" value-field="descrizione" text-field="descrizione" @input="onArtiInput($event)"></b-form-select>
                            <b-button variant="outline-secondary" size="sm" @click="onClickAggiungiDescrizione(data.arti, linkArti)"><b-icon icon="plus"></b-icon></b-button>
                            <b-button variant="outline-secondary" size="sm" @click="onDeleteDescrizione(data.arti, linkArti, artiOptions)"><b-icon icon="trash"></b-icon></b-button>
                        </b-input-group>
                    </b-col>

                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="2">
                        <strong>Acuità visiva naturale </strong><br />
                        <strong>O.D. </strong>
                        <strong style="margin-left: 50px;">O.S. </strong>
                        <b-row>
                            <b-form-input v-model="data.acuitaVisivaNaturaleOcchioDestro" style="width:50px;" maxlength="3"></b-form-input> <span class="span-label">/10</span>
                            <span style="width: 10px"></span>
                            <b-form-input v-model="data.acuitaVisivaNaturaleOcchioSinistro" style="width:50px;" maxlength="3"></b-form-input> <span class="span-label">/10</span>
                        </b-row>
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="2">
                        <strong>Corretta </strong><br />
                        <strong>O.D. </strong>
                        <strong style="margin-left: 50px;">O.S. </strong>
                        <b-row>
                            <b-form-input v-model="data.correttaOcchioDestro" style="width:50px;" maxlength="3"></b-form-input> <span class="span-label">/10</span>
                            <span style="width: 10px"></span>
                            <b-form-input v-model="data.correttaOcchioSinistro" style="width:50px;" maxlength="3"></b-form-input> <span class="span-label">/10</span>
                        </b-row>
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="2">
                        <strong>Obbligo lenti correttive </strong><br />
                        <b-form-checkbox v-model="data.obbligoLentiCorrettive" :checked="data.obbligoLentiCorrettive" @click="data.obbligoLentiCorrettive = !data.obbligoLentiCorrettive" no-resize></b-form-checkbox>
                    </b-col>
                </b-row>
                <hr />
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="6">
                        <strong>Senso Cromatico </strong><br />
                        <b-form-textarea v-model="data.sensoCromatico" no-resize></b-form-textarea>
                        <b-input-group style="margin-top: 5px">
                            <b-form-select class="mb-2 mr-sm-2 mb-sm-0" :options="sensoCromaticoOptions" :value="null" value-field="descrizione" text-field="descrizione" @input="onSensoCromaticoInput($event)"></b-form-select>
                            <b-button variant="outline-secondary" size="sm" @click="onClickAggiungiDescrizione(data.sensoCromatico, linkSensoCromatico)"><b-icon icon="plus"></b-icon></b-button>
                            <b-button variant="outline-secondary" size="sm" @click="onDeleteDescrizione(data.sensoCromatico, linkSensoCromatico, sensoCromaticoOptions)"><b-icon icon="trash"></b-icon></b-button>
                        </b-input-group>
                    </b-col>

                    <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="6">
                        <strong>Udito </strong><br />
                        <b-form-textarea v-model="data.udito" no-resize></b-form-textarea>
                        <b-input-group style="margin-top: 5px">
                            <b-form-select class="mb-2 mr-sm-2 mb-sm-0" :options="uditoOptions" :value="null" value-field="descrizione" text-field="descrizione" @input="onUditoInput($event)"></b-form-select>
                            <b-button variant="outline-secondary" size="sm" @click="onClickAggiungiDescrizione(data.udito, linkUdito)"><b-icon icon="plus"></b-icon></b-button>
                            <b-button variant="outline-secondary" size="sm" @click="onDeleteDescrizione(data.udito, linkUdito, uditoOptions)"><b-icon icon="trash"></b-icon></b-button>
                        </b-input-group>
                    </b-col>
                </b-row>
                <hr />
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="6">
                        <strong>Conclusioni esame obiettivo </strong><br />
                        <b-form-textarea v-model="data.conclusioniEsameObiettivo" no-resize></b-form-textarea>
                        <b-input-group style="margin-top: 5px">
                            <b-form-select class="mb-2 mr-sm-2 mb-sm-0" :options="conclusioniEsameObiettivoOptions" :value="null" value-field="descrizione" text-field="descrizione" @input="onConclusioniEsameObiettivoInput($event)"></b-form-select>
                            <b-button variant="outline-secondary" size="sm" @click="onClickAggiungiDescrizione(data.conclusioniEsameObiettivo, linkConclusioniEsameObiettivo)"><b-icon icon="plus"></b-icon></b-button>
                            <b-button variant="outline-secondary" size="sm" @click="onDeleteDescrizione(data.conclusioniEsameObiettivo, linkConclusioniEsameObiettivo, conclusioniEsameObiettivoOptions)"><b-icon icon="trash"></b-icon></b-button>
                        </b-input-group>
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="6">
                        <strong>Note su scheda di valutazione </strong><br />
                        <b-form-textarea v-model="data.noteSuSchedaDiValutazione" no-resize></b-form-textarea>
                    </b-col>
                </b-row>
                <hr />
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                        <strong>Stato </strong><br />
                        {{ data.firma }}
                    </b-col>
                </b-row>
                <hr />
            </b-col>
        </b-row>
    </div>
</template>
<script>
import axios from "axios";
export default {
    components: {},
    props: {
        data: {
            Type: Object,
            default: function() {
                return {
                    trofismo: "",
                    peso: null,
                    altezza: null,
                    apparatoLocomotore: "",
                    torace: "",
                    apparatoRespiratorio: "",
                    apparatoCardiocircolatorio: "",
                    pressioneArteriosaRiposoSistolica: null,
                    pressioneArteriosaRiposoDiastolica: null,
                    addome: "",
                    organiGenitali: "",
                    arti: "",
                    acuitaVisivaNaturaleOcchioDestro: null,
                    acuitaVisivaNaturaleOcchioSinistro: null,
                    correttaOcchioDestro: null,
                    correttaOcchioSinistro: null,
                    sensoCromatico: "",
                    obbligoLentiCorrettive: null,
                    udito: "",
                    conclusioniEsameObiettivo: "",
                    noteSuSchedaDiValutazione: "",
                    firma: "",
                };
            },
        },
    },
    data() {
        return {
            linkTrofismo: "/visiteesameobiettivotrofismo",
            linkApparatoLocomotore: "/visiteesameobiettivoapparatolocomotore",
            linkTorace: "/visiteesameobiettivotorace",
            linkApparatoRespiratorio: "/visiteesameobiettivoapparatorespiratorio",
            linkApparatoCardiocircolatorio: "/visiteesameobiettivoapparatocircolatorio",
            linkAddome: "/visiteesameobiettivoaddome",
            linkOrganiGenitali: "/visiteesameobiettivoorganigenitali",
            linkArti: "/visiteesameobiettivoarti",
            linkSensoCromatico: "/visiteesameobiettivosensocromatico",
            linkUdito: "/visiteesameobiettivoudito",
            linkConclusioniEsameObiettivo: "/visiteesameobiettivoconclusioniesameobiettivo",
            trofismoOptions: [],
            apparatoLocomotoreOptions: [],
            toraceOptions: [],
            apparatoRespiratorioOptions: [],
            apparatoCardiocircolatorioOptions: [],
            addomeOptions: [],
            organiGenitaliOptions: [],
            artiOptions: [],
            sensoCromaticoOptions: [],
            uditoOptions: [],
            conclusioniEsameObiettivoOptions: [],
        };
    },
    mounted: function() {
        let me = this;
        me.loadDefaultData();
    },
    methods: {
        loadDefaultData() {
            let me = this;
            me.loadTrofismo();
            me.loadApparatoLocomotore();
            me.loadTorace();
            me.loadApparatoRespiratorio();
            me.loadApparatoCardiocircolatorio();
            me.loadAddome();
            me.loadOrganiGenitali();
            me.loadArti();
            me.loadSensoCromatico();
            me.loadUdito();
            me.loadConclusioniEsameObiettivo();
        },
        loadTrofismo() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkTrofismo;
            axios.get(link).then((response) => {
                me.trofismoOptions = response.data.data;
            });
        },
        loadApparatoLocomotore() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkApparatoLocomotore;
            axios.get(link).then((response) => {
                me.apparatoLocomotoreOptions = response.data.data;
            });
        },
        loadTorace() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkTorace;
            axios.get(link).then((response) => {
                me.toraceOptions = response.data.data;
            });
        },
        loadApparatoRespiratorio() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkApparatoRespiratorio;
            axios.get(link).then((response) => {
                me.apparatoRespiratorioOptions = response.data.data;
            });
        },
        loadApparatoCardiocircolatorio() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkApparatoCardiocircolatorio;
            axios.get(link).then((response) => {
                me.apparatoCardiocircolatorioOptions = response.data.data;
            });
        },
        loadAddome() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkAddome;
            axios.get(link).then((response) => {
                me.addomeOptions = response.data.data;
            });
        },
        loadOrganiGenitali() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkOrganiGenitali;
            axios.get(link).then((response) => {
                me.organiGenitaliOptions = response.data.data;
            });
        },
        loadArti() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkArti;
            axios.get(link).then((response) => {
                me.artiOptions = response.data.data;
            });
        },
        loadSensoCromatico() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkSensoCromatico;
            axios.get(link).then((response) => {
                me.sensoCromaticoOptions = response.data.data;
            });
        },
        loadUdito() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkUdito;
            axios.get(link).then((response) => {
                me.uditoOptions = response.data.data;
            });
        },
        loadConclusioniEsameObiettivo() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkConclusioniEsameObiettivo;
            axios.get(link).then((response) => {
                me.conclusioniEsameObiettivoOptions = response.data.data;
            });
        },

        onTrofismoInput(value) {
            let me = this;
            me.data.trofismo = value;
        },
        onApparatoLocomotoreInput(value) {
            let me = this;
            me.data.apparatoLocomotore = value;
        },
        onToraceInput(value) {
            let me = this;
            me.data.torace = value;
        },
        onApparatoRespiratorioInput(value) {
            let me = this;
            me.data.apparatoRespiratorio = value;
        },
        onApparatoCardiocircolatorioInput(value) {
            let me = this;
            me.data.apparatoCardiocircolatorio = value;
        },
        onAddomeInput(value) {
            let me = this;
            me.data.addome = value;
        },
        onOrganiGenitaliInput(value) {
            let me = this;
            me.data.organiGenitali = value;
        },
        onArtiInput(value) {
            let me = this;
            console.log(value);
            me.data.arti = value;
        },
        onSensoCromaticoInput(value) {
            let me = this;
            me.data.sensoCromatico = value;
        },
        onUditoInput(value) {
            let me = this;
            me.data.udito = value;
        },
        onConclusioniEsameObiettivoInput(value) {
            let me = this;
            me.data.conclusioniEsameObiettivo = value;
        },

        onClickAggiungiDescrizione(descrizione, path) {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + path;
            let jsonData = { descrizione: descrizione };
            axios
                .post(link, JSON.stringify(jsonData))
                .then((response) => {
                    this.$bvModal.msgBoxOk(response.data.messaggio, {
                        title: "",
                        size: "sm",
                        okVariant: "outline-danger",
                        headerClass: "sa-msg-header-danger",
                        footerClass: "p-2 border-top-0",
                        centered: true,
                    });
                    me.loadDefaultData();
                })
                .catch((e) => {
                    // console.log(e);
                    this.$bvModal
                        .msgBoxOk(e.response.data.messaggio, {
                            title: "ATTENZIONE",
                            size: "sm",
                            okVariant: "outline-danger",
                            headerClass: "sa-msg-header-danger",
                            footerClass: "p-2 border-top-0",
                            centered: true,
                        })
                        .then((value) => {
                            this.boxTwo = value;
                        });
                });
        },

        onDeleteDescrizione(descrizione, path, array) {
            let me = this;
            this.$bvModal
                .msgBoxConfirm("Sei sicuro di procedere alla cancellazione?", {
                    title: "Conferma Cancellazione",
                    size: "sm",
                    buttonSize: "sm",
                    okVariant: "secondary",
                    cancelVariant: "danger",
                    okTitle: "SI",
                    cancelTitle: "NO",
                    footerClass: "p-2",
                    hideHeaderClose: false,
                    centered: true,
                })
                .then((value) => {
                    if (value) {
                        let link = process.env.VUE_APP_PATH_API + path;
                        array.forEach((element) => {
                            if (element.descrizione === descrizione) {
                                axios.delete(link + "/" + element.id).then((response) => {
                                    this.$bvModal.msgBoxOk(response.data.messaggio, {
                                        title: "",
                                        size: "sm",
                                        okVariant: "outline-danger",
                                        headerClass: "sa-msg-header-danger",
                                        footerClass: "p-2 border-top-0",
                                        centered: true,
                                    });
                                    me.loadDefaultData();
                                });
                            } else if (descrizione === null || descrizione === "") {
                                this.$bvModal.msgBoxOk("Nessun elemento da cancellare.", {
                                    title: "Attenzione!",
                                    Text: "Nessun valore da cancellare!",
                                    size: "sm",
                                    okVariant: "outline-danger",
                                    headerClass: "sa-msg-header-danger",
                                    footerClass: "p-2 border-top-0",
                                    centered: true,
                                });
                            }
                        });
                    }
                })
                .catch((e) => {
                    this.$bvModal
                        .msgBoxOk(e.response.data.messaggio, {
                            title: "ATTENZIONE",
                            size: "sm",
                            okVariant: "outline-danger",
                            headerClass: "sa-msg-header-danger",
                            footerClass: "p-2 border-top-0",
                            centered: true,
                        })
                        .then((value) => {
                            this.boxTwo = value;
                        });
                });
        },
    },
};
</script>
<style>
.span-label {
    font-size: 16px;
    margin-top: 7px;
    margin-left: 3px;
}
</style>
