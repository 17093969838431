<template>
    <paziente-scheda-sic-component v-if="isCreated" :utente="utente" :jsonData="jsonData" :idPaziente="idPaziente"></paziente-scheda-sic-component>
</template>
<script>
import axios from "axios";
import PazienteSchedaSicComponent from "./PazienteSchedaSicComponent.vue";
export default {
    props: {
        utente: {
            required: true,
            type: Object,
            default: function() {
                return null;
            },
        },
    },
    components: { PazienteSchedaSicComponent },
    created() {
        let me = this;
        me.loadUserData();
    },
    computed: {},
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        me.idPaziente = me.id;
    },
    data() {
        return {
            idPaziente: null,
            isCreated: false,
            pathResource: "/pazienti",
            jsonData: {},
        };
    },
    methods: {
        loadUserData() {
            let me = this;

            let link = process.env.VUE_APP_PATH_API + "/users/profile";
            axios
                .get(link)
                .then((response) => {
                    let utente = response.data.data;
                    this.$user = utente;
                    // me.idPaziente = utente.id;
                    me.nominativoUtente = utente.lastname + " " + utente.firstname;
                    me.ruolo = utente.profilo;
                    me.menu = response.data.data.gruppo.menuGruppo;
                    me.loadData();
                })
                .catch((e) => {
                    console.log(e);
                });
        },
        loadData() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "?idPaziente=" + me.idPaziente;
            axios
                .get(link)
                .then((response) => {
                    response.data.data.forEach((element) => {
                        if (element.id === me.id) {
                            me.jsonData = element;
                            me.isCreated = true;
                        }
                    });
                })
                .catch(function(error) {
                    console.log(error);
                });
        },
    },
};
</script>
