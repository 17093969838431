<template>
  <div class="modal-body">
    <b-row>
      <b-col>
        <label for="dataInizio">{{ this.$i18n.t("patient.agenda.lblStartTime") }}</label>
        <date-picker format="HH:mm" value-type="timestamp" v-model="setDalleOre" type="time"></date-picker>
      </b-col>
      <b-col>
        <label for="indirizzo">{{ this.$i18n.t("patient.agenda.lblEndTime") }}</label>
        <date-picker format="HH:mm" value-type="timestamp" v-model="setAlleOre" type="time"></date-picker>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <label for="colore">{{ this.$i18n.t("patient.agenda.lblColor") }}</label>
        <b-form-select id="inline-form-custom-select-pref" :options="coloriOptions" v-model="setClsColor" :value="null" value-field="value" text-field="clsColor"></b-form-select>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import DatePicker from "vue2-datepicker";

export default {
  components: { DatePicker },
  props: {
    jsonData: {
      type: Object,
      default: function() {
        return { dalleOre: null, alleOre: null, clsColor: null };
      },
    },
  },
  data() {
    return {
      coloriOptions: [
        { value: null, clsColor: "---- Seleziona un colore ----" },
        { value: "Giallo", clsColor: "Giallo" },
        { value: "Rosso", clsColor: "Rosso" },
        { value: "Verde", clsColor: "Verde" },
        { value: "Blu", clsColor: "Blu" },
      ],
    };
  },
  computed: {
    setDalleOre: {
      get: function() {
        return this.jsonData.dalleOre;
      },
      set: function(value) {
        this.jsonData.dalleOre = value;
      },
    },
    setAlleOre: {
      get: function() {
        return this.jsonData.alleOre;
      },
      set: function(value) {
        this.jsonData.alleOre = value;
      },
    },
    setClsColor: {
      get: function() {
        return this.jsonData.clsColor;
      },
      set: function(value) {
        this.jsonData.clsColor = value;
      },
    },
  },
};
</script>
