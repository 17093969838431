<template>
    <div class="sa-page-paziente">
        <div class="sa-page-paziente-content">
            <b-row>
                <b-col cols="12" xs="12" sm="8" md="8" lg="8" xl="8">
                    <b-card class="mb-8">
                        <b-row>
                            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                <b-alert v-if="messageDanger" show variant="danger"> Scheda Paziente non collegata con Withings, clicca <b-link :href="wtLogin" target="_blank">QUI</b-link> per l'associazione </b-alert>
                            </b-col>
                        </b-row>
                        <div class="bg-picture card-box">
                            <div class="profile-info-name">
                                <div class="container-fluid">
                                    <b-row>
                                        <b-col>
                                            <b-row>
                                                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                                    <div style="height: 100%; display: flex; align-items: center;">
                                                        <div style="width: 6rem; height: 6rem; float: left;"><img :src="patientAvatar" class="rounded-circle avatar-xl img-thumbnail float-left mr-3" alt="profile-image" /></div>
                                                        <div style="margin-left: 10px;">
                                                            <h4 class="m-0">{{ data.cognome }} {{ data.nome }}</h4>
                                                            <p class="text-muted">
                                                                <i>{{ data.codiceFiscale }}</i>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </b-col>
                                            </b-row>
                                        </b-col>
                                        <b-col class="text-right">
                                            <b-button v-if="btnEditAnagrafica" variant="purple btn-generic btn-notext sa-margin-right" size="sm" @click="onEdit"><b-icon icon="pencil"></b-icon></b-button>
                                            <b-button variant="purple btn-generic btn-notext sa-margin-right" size="sm" v-b-toggle.info-1><b-icon icon="info" scale="2"></b-icon></b-button>
                                        </b-col>
                                    </b-row>
                                </div>
                                <hr />
                                <div class="container-fluid">
                                    <b-collapse id="info-1" role="tabpanel">
                                        <div class="row">
                                            <div class="col">
                                                <strong>CF</strong><br />
                                                {{ data.codiceFiscale }}
                                            </div>
                                            <div class="col">
                                                <strong>Sesso</strong><br />
                                                <i class="fas fa-venus rosa font-18"></i> {{ data.sesso }}
                                            </div>
                                            <div class="col">
                                                <strong><i class="fas fa-tint rosso"></i> Gruppo S.</strong><br />
                                                {{ data.gruppoSanguigno }}
                                            </div>
                                        </div>
                                        <hr />
                                        <div class="row">
                                            <div class="col">
                                                <strong>Nata a </strong><br />
                                                {{ data.comuneNascita }} ({{ data.provinciaNascita }})
                                            </div>
                                            <div class="col">
                                                <strong>Il </strong><br />
                                                {{ formatDate(data.dataNascita) }}
                                            </div>
                                            <div class="col">
                                                <strong>Residenza </strong><br />
                                                {{ data.indirizzoResidenza }} {{ data.capResidenza }} {{ data.comuneResidenza }} ({{ data.provinciaResidenza }})
                                            </div>
                                        </div>
                                        <hr />
                                        <div class="row">
                                            <div class="col">
                                                <strong>Domicilio </strong><br />
                                                {{ data.indirizzoDomicilio }} {{ data.capDomicilio }} {{ data.comuneDomicilio }} ({{ data.provinciaDomicilio }})
                                            </div>
                                            <div class="col">
                                                <strong>Telefono </strong><br />
                                                {{ data.telefono }}
                                            </div>
                                            <div class="col">
                                                <strong>E-Mail </strong><br />
                                                {{ data.email }}
                                            </div>
                                        </div>
                                        <hr
                                    /></b-collapse>
                                    <h5 class="mt-0">
                                        <span class="text-dark"><i class="fas fa-comment-medical "></i> {{ this.$i18n.t("patient.evidences.lblEvidences") }} </span>
                                    </h5>
                                    <!-- <b-button v-if="btnDashboardbtnDashboard" class="float-sm-right " variant="primary btn-toolbar" size="sm" @click="onDashboard"><b-icon icon="tachometer-alt" variant="secondary"></b-icon>Dashboard</b-button> -->
                                    <b-button v-if="btnAllegati" class="float-sm-right " variant="primary btn-toolbar" size="sm" @click="onAllegati"><b-icon icon="paperclip" variant="secondary"></b-icon>Allegati</b-button>
                                </div>
                                <div class="clearfix"></div>
                            </div>
                        </div>
                    </b-card>
                    <b-card class="mb-4">
                        <h5 class="mt-0">
                            <span class="text-dark"><i class="fas fa-comment-medical "></i> {{ this.$i18n.t("patient.activities.lblActivities") }}</span>
                        </h5>
                    </b-card>
                    <b-card>
                        <div class="card-box">
                            <div class="media mb-2">
                                <div class="media-body">
                                    <h5 class="mt-0">
                                        <span class="text-dark"><i class=" fas fa-capsules"></i> {{ this.$i18n.t("patient.menu.therapy") }} </span>
                                    </h5>
                                    <ul class="list-unstyled mb-3">
                                        <li v-for="item in terapia" :key="item.id">
                                            <strong>{{ item.posologia }}</strong> <strong>{{ item.farmaco }}</strong>
                                            <br />
                                            <span class="text-danger">{{ traslater("patient.therapy.lblFrequency") }} {{ item.frequenza }} </span>
                                            <br />
                                            <span class="text-danger">{{ traslater("patient.therapy.lblStartTherapy") }} {{ formatDate(item.dataInizio) }}</span>
                                            <br />
                                            <span class="text-danger">{{ item.tempo }}</span>
                                            <i class="blockquote-footer"> {{ item.nomeMedico }} {{ item.cognomeMedico }}</i>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </b-card>
                </b-col>
                <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                    <b-card class="mb-4">
                        <h4 class="header-title mt-0 mb-3"><i class="mdi mdi-notification-clear-all mr-1"></i> Diario</h4>
                        <ul class="timeline2" v-for="item in diario" :key="item.id">
                            <li class="event" data-date="25 Maggio 2021 | 10:30">
                                <span class="mdi mdi-circle pal"></span>
                                <span class="sa-timeline-date">{{ formatDateTime(item.dataEvento) }}</span>
                                <h3>{{ item.gruppo }}</h3>
                                <p>{{ item.lastnameUpdater }} {{ item.firstnameUpdater }}</p>
                            </li>
                        </ul>
                    </b-card>
                    <b-card class="scroll">
                        <div v-for="item in misure" v-bind:key="item.id">
                            <b-card :class="'sa-card-border-' + checkclass(item.range)" :border-variant="checkclass(item.range)" align="center" v-if="checkName(item.measurename) !== 'unknown'">
                                <div :key="'card-body text-' + checkclass(item.range)">
                                    <b-row>
                                        <b-col cols="2">
                                            <h5 :class="'card-title text-' + checkclass(item.range)" @click="onDashboard"><img :src="checkimage(item.measurename)" width="35" height="35" alt="" /></h5>
                                        </b-col>
                                        <b-col cols="8">
                                            <h4 :class="'card-title text-' + checkclass(item.range)">
                                                <p style="margin-top: 14px; margin-left: 10px;">{{ checkName(item.measurename) }}</p>
                                            </h4>
                                        </b-col>
                                        <b-col cols="2">
                                            <h4>
                                                <span :class="'badge badge-' + checkclass(item.range) + ' badge-pill'">{{ item.value }}</span>
                                            </h4>
                                        </b-col>
                                    </b-row>
                                </div>
                                <b-row class="text-left">
                                    <b-col cols="2"></b-col>
                                    <b-col cols="8">
                                        <span>
                                            <b style="font-size: 12px !important;">{{ returnlabel() }}: {{ formatDate(item.createDate) }}</b>
                                            <!-- <b style="font-size: 12px !important;">{{ this.$i18n.t("patient.rangeConfig.lblDetectedOn") }} {{ formatDate(item.createDate) }}</b> -->
                                        </span>
                                    </b-col>
                                    <b-col cols="2"></b-col>
                                </b-row>
                            </b-card>
                        </div>
                    </b-card>
                </b-col>
            </b-row>
        </div>
    </div>
</template>
<script>
import axios from "axios";
import moment from "moment";
import female from "@/assets/images/female.jpg";
import male from "@/assets/images/male.jpg";
import UtilityMixin from "../../utility/UtilityMixin";
export default {
    mixins: [UtilityMixin],
    props: {
        idPaziente: String,
        btnAllegati: { type: Boolean, default: false },
        messageDanger: { type: Boolean, default: false },
        utente: {
            required: true,
            type: Object,
            default: function() {
                return null;
            },
        },
    },
    data() {
        return {
            titolo: "",
            pathResource: "/pazienti",
            pagelink: "/paziente",
            linkMeasurement: "/lastmeasure",
            data: {},
            terapia: [],
            misure: [],
            wtLogin: null,
            misurePrioritarie: [],
            pazienteTerapie: [
                {
                    modalita: "Terapia Orale",
                    terapia: "Oki",
                    quantita: "1",
                    unitaMisura: "Milligramo",
                    dataInizio: "19-03-2021",
                    tempo: "Ogni Mattina",
                    nomeMedico: "Michele",
                    cognomeMedico: "Annunziata",
                },
                {
                    modalita: "Terapia in Endovena",
                    terapia: "Cortisone",
                    quantita: "1",
                    unitaMisura: "Siringa",
                    dataInizio: "25-04-2021",
                    tempo: "Ogni 12 ore",
                    nomeMedico: "Michele",
                    cognomeMedico: "Annunziata",
                },
            ],
            diarioStatico: [
                {
                    titolo: "ECG",
                    descrizione: "ELETTROCADIOGRAMMA",
                    data: new Date("2021/03/30"),
                },
                { titolo: "RM", descrizione: "Risonanza Magnetica" },
                { titolo: "ECO", descrizione: "ECOGRAFIA" },
            ],
            diario: [
                {
                    titolo: "ECG",
                    descrizione: "ELETTROCADIOGRAMMA",
                    data: new Date("2021/03/30"),
                },
                { titolo: "RM", descrizione: "Risonanza Magnetica" },
                { titolo: "ECO", descrizione: "ECOGRAFIA" },
            ],
            misureRange: [],
        };
    },
    computed: {
        patientAvatar: {
            get: function() {
                let me = this;
                if (me.data.sesso === "M") {
                    return male;
                } else {
                    return female;
                }
            },
        },
        btnEditAnagrafica: {
            get: function() {
                let me = this;
                return UtilityMixin.methods.verificaPermessi(me.utente, "pazienti", 4);
            },
        },
    },
    mounted() {
        let me = this;
        if (me.idPaziente) {
            me.wtLogin = "https://account.withings.com/oauth2_user/authorize2?response_type=code&client_id=c7378cec5a59c2e35fd7dd158191fa9bd1162ac6edd89be1e45d2a86fd5d127c&redirect_uri=https://hyperhealth.gesan.it/api/hyperhealth/subscribed&state=" + me.idPaziente + "&scope=user.activity,user.info,user.metrics";
            me.loadData();
            me.loadDiario();
            me.loadTerapie();
            me.loadMeasurement();
        }
    },
    methods: {
        returnlabel() {
            let label = this.$i18n.t("patient.rangeConfig.lblDetectedOn");
            return label;
        },
        formatDate(value) {
            if (value) {
                return moment(new Date(value)).format("DD-MM-YYYY");
            } else {
                return "---";
            }
        },
        formatDateTime(value) {
            if (value) {
                return moment(new Date(value)).format("DD-MM-YYYY HH:mm");
            } else {
                return "---";
            }
        },
        traslater(key) {
            return this.$i18n.t(key);
        },
        loadData() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
            axios
                .get(link + me.idPaziente)
                .then((response) => {
                    me.data = response.data.data;
                })
                .catch((e) => {
                    this.errors.push(e);
                });
        },
        onDashboard() {
            let me = this;
            me.$router.replace(me.pagelink + "/dashboard?idPaziente=" + me.idPaziente).catch((err) => {
                err;
            });
        },
        loadDiario() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + "/pazienteeventi" + "/";
            axios
                .get(link, { params: { idPaziente: me.idPaziente } })
                .then((response) => {
                    me.diario = response.data.data;
                })
                .catch((e) => {
                    console.log(e);
                    //this.errors.push(e);
                });
        },
        loadTerapie() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + "/pazientiterapia/attiva";
            axios
                .get(link, { params: { idPaziente: me.idPaziente } })
                .then((response) => {
                    me.terapia = response.data.data;
                })
                .catch((e) => {
                    console.log("Error: " + e.response.status + " " + link);
                });
        },
        onPazienteTeleconsulto: function() {
            let me = this;
            me.$router.replace("/paziente/teleconsulti/" + me.idPaziente).catch((err) => {
                err;
            });
        },
        onEdit: function() {
            let me = this;
            me.$router.replace(me.pagelink + "/edit/" + me.id).catch((err) => {
                err;
            });
        },
        // onDashboard() {
        //   let me = this;
        //   me.$router.replace(me.pagelink + "/dashboard/" + me.idPaziente).catch((err) => {
        //     err;
        //   });
        // },
        onAllegati() {
            let me = this;
            me.$router.replace("/paziente/allegati/" + me.idPaziente).catch((err) => {
                err;
            });
        },
        loadMeasurement() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + "/hyperhealth" + me.linkMeasurement + "?idPaziente=" + me.idPaziente;
            axios
                .get(link)
                .then((response) => {
                    me.misurePrioritarie = ["Diastolic Blood Pressure (mmHg)", "Systolic Blood Pressure (mmHg)"];
                    console.log(response.data.data);
                    let daOrdinare = response.data.data;
                    for (let i = 0; i < daOrdinare.length; i++) {
                        for (let j = 0; j < me.misurePrioritarie.length; j++) {
                            if (daOrdinare[i].measurename === me.misurePrioritarie[j]) {
                                let scambio = daOrdinare[j];
                                daOrdinare[j] = daOrdinare[i];
                                daOrdinare[i] = scambio;
                            }
                        }
                    }
                    me.misure = daOrdinare;
                    // console.log(me.misure);
                })
                .catch((e) => {
                    console.log("Error: " + e.response.status + " " + link);
                });
        },
        checkimage(name) {
            let image = "";
            switch (name) {
                case "hr_average":
                    image = "battiti2.png";
                    break;
                case "totalcalories":
                    image = "calorie.png";
                    break;
                case "calories":
                    image = "calorie.png";
                    break;
                case "active":
                    image = "camminare.png";
                    break;
                case "total_sleep_time":
                    image = "camminare.png";
                    break;
                case "sleep_efficiency":
                    image = "sonno.png";
                    break;
                case "hr_min":
                    image = "battiti2.png";
                    break;
                case "hr_max":
                    image = "battiti2.png";
                    break;
                case "elevation":
                    image = "scale.png";
                    break;
                case "out_of_bed_count":
                    image = "veglia_notturna.png";
                    break;
                case "Skin Temperature (celsius)":
                    image = "temperatura.png";
                    break;
                case "Bone Mass (kg)":
                    image = "massa_ossea.png";
                    break;
                case "Body Temperature (celsius)":
                    image = "temperatura_corporea.png";
                    break;
                case "Temperature (celsius)":
                    image = "temperatura.png";
                    break;
                case "Pulse Wave Velocity (m/s)":
                    image = "battiti.png";
                    break;
                case "SP02 (%)":
                    image = "ossigeno.png";
                    break;
                case "Heart Pulse (bpm) - only for BPM devices":
                    image = "battiti.png";
                    break;
                case "Systolic Blood Pressure (mmHg)":
                    image = "pressione_sis.png";
                    break;
                case "Diastolic Blood Pressure (mmHg)":
                    image = "pressione_sis2.png";
                    break;
                case "delete-unlink-update":
                    image = "noimage.png";
                    break;
                case "sleep_score":
                    image = "sonno.png";
                    break;
                case "Weight (kg)":
                    image = "bilancia.png";
                    break;
                case "Fat Free Mass (kg)":
                    image = "massa_magra.png";
                    break;
                case "Height (meter)":
                    image = "altezza.png";
                    break;
                case "Fat Mass Weight (kg)":
                    image = "massa_grassa.png";
                    break;
                case "distance":
                    image = "distanza.png";
                    break;
                case "Fat Ratio (%)":
                    image = "grasso.png";
                    break;
                case "steps":
                    image = "camminare.png";
                    break;
                case "Hydration (kg)":
                    image = "idratazione.png";
                    break;
                case "Muscle Mass (kg)":
                    image = "massa_muscolare.png";
                    break;
                default:
                    image = "noimage.png";
                    break;
            }
            try {
                return require("../../../assets/images/icons/measurement/" + image);
            } catch (Exeption) {
                return require("../../../assets/images/icons/measurement/noimage.png");
            }
        },
        checkName(name) {
            let label = "";
            switch (name) {
                // case "hr_average":
                //   label = this.$i18n.t("patient.card.lblHrAvarage");
                //   break;
                // case "totalcalories":
                //   label = this.$i18n.t("patient.card.lblTotalCalories");
                //   break;
                // case "calories":
                //   label = this.$i18n.t("patient.card.lblCalories");
                //   break;
                // case "active":
                //   label = this.$i18n.t("patient.card.lblActive");
                //   break;
                // case "total_sleep_time":
                //   label = this.$i18n.t("patient.card.lblTotalSleepTime");
                //   break;
                case "sleep_efficiency":
                    label = this.$i18n.t("patient.card.lblSleepEfficiency");
                    break;
                case "hr_min":
                    label = this.$i18n.t("patient.card.lblHrMin");
                    break;
                case "hr_max":
                    label = this.$i18n.t("patient.card.lblHrMax");
                    break;
                // case "elevation":
                //   label = this.$i18n.t("patient.card.lblElevation");
                //   break;
                // case "out_of_bed_count":
                //   label = this.$i18n.t("patient.card.lblOutOfBedTime");
                //   break;
                // case "Skin Temperature (celsius)":
                //   label = this.$i18n.t("patient.card.lblSkinTemperature");
                //   break;
                // case "Bone Mass (kg)":
                //   label = this.$i18n.t("patient.card.lblBoneMass");
                //   break;
                // case "Body Temperature (celsius)":
                //   label = this.$i18n.t("patient.card.lblBodyTemperature");
                //   break;
                // case "Temperature (celsius)":
                //   label = this.$i18n.t("patient.card.lblTemperature");
                //   break;
                // case "Pulse Wave Velocity (m/s)":
                //   label = this.$i18n.t("patient.card.lblPulseWaveVelocity");
                //   break;
                // case "SP02 (%)":
                //   label = this.$i18n.t("patient.card.lblSPO2");
                //   break;
                // case "Heart Pulse (bpm) - only for BPM devices":
                //   label = this.$i18n.t("patient.card.lblHeartPulseBPM");
                //   break;
                case "Systolic Blood Pressure (mmHg)":
                    label = this.$i18n.t("patient.card.lblSystolicBloodPressure");
                    break;
                case "Diastolic Blood Pressure (mmHg)":
                    label = this.$i18n.t("patient.card.lblDiastolicBloodPressure");
                    break;
                case "delete-unlink-update":
                    label = this.$i18n.t("patient.card.lblDeleted");
                    break;
                // case "sleep_score":
                //   label = this.$i18n.t("patient.card.lblSleepScore");
                //   break;
                case "Weight (kg)":
                    label = this.$i18n.t("patient.card.lblWeight");
                    break;
                // case "Fat Free Mass (kg)":
                //   label = this.$i18n.t("patient.card.lblFatFreeMass");
                //   break;
                // case "Height (meter)":
                //   label = this.$i18n.t("patient.card.lblHeight");
                //   break;
                // case "Fat Mass Weight (kg)":
                //   label = this.$i18n.t("patient.card.lblFatMassWeight");
                //   break;
                // case "distance":
                //   label = this.$i18n.t("patient.card.lblDistance");
                //   break;
                // case "Fat Ratio (%)":
                //   label = this.$i18n.t("patient.card.lblFatRatio");
                //   break;
                case "steps":
                    label = this.$i18n.t("patient.card.lblSteps");
                    break;
                // case "Hydration (kg)":
                //   label = this.$i18n.t("patient.card.lblHydration");
                //   break;
                // case "Muscle Mass (kg)":
                //   label = this.$i18n.t("patient.card.lblMuscleMass");
                //   break;
                default:
                    label = "unknown";
                    break;
            }
            return label;
        },

        checkclass(range) {
            switch (range) {
                case "WARNING":
                    return "warning";
                case "NORMAL":
                    return "success";
                case "ALERT":
                    return "alert";
                default:
                    return "secondary";
            }
        },
    },
};
</script>

<style scoped>
.sa-card-border-alert {
    border-color: #ff5b5b !important;
}
.sa-card-border-warning {
    border-color: #ffc107 !important;
}
.sa-card-border-success {
    border: 2px solid !important;
    border-color: #10c469 !important;
}
.sa-card-border-secondary {
    border: 2px solid !important;
    border-color: #6c757d !important;
}
</style>
