import Leonida from "../";

const routes = [
    { name: "Atleti", path: "/atleti", component: Leonida.AtletiList, meta: { title: "Lista Atleti" } },
    { name: "Worklist", path: "/worklist", component: Leonida.WorkList, meta: { title: "WorkList" } },
    { name: "WorklistView", path: "/worklist/view/:id", component: Leonida.WorkListView, meta: { title: "WorkList" } },
    { name: "AtletaEdit", path: "/atleti/edit/:id", component: Leonida.AtletaEdit, meta: { title: "Atleta" } },
    { name: "PendingList", path: "/pendinglist", component: Leonida.PendingList, meta: { title: "Pending List" } },
    { name: "VisitaMedicoSportiva", path: "/worklist/visitamedicosportiva/view/:id", component: Leonida.VisitaMedicoSportivaView, meta: { title: "Visita Medico Sportiva" } },
    { name: "VisitaMedicoSportivaEdit", path: "/worklist/visitamedicosportiva/edit/:id", component: Leonida.VisitaMedicoSportivaEdit, meta: { title: "Visita Medico Sportiva" } },
    { name: "VisiteList", path: "/visite", component: Leonida.VisiteList, meta: { title: "Visite Medico Sportive" } },
];

export default routes;
