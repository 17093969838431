<template>
  <div>
    <b-row>
      <b-col>
        <span class="sa-form-label">{{ this.$i18n.t("patient.idoneity.lblTrofism") }}</span>
        <b-form-input v-model="data.trofismo" :disabled="!edit"></b-form-input>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <span class="sa-form-label">{{ this.$i18n.t("patient.idoneity.lblBody") }}</span>
        <b-form-input type="number" v-model="data.corpo" :disabled="!edit"></b-form-input>
        <span class="sa-form-label">{{ this.$i18n.t("patient.idoneity.lblBMI") }}</span>
        <b-form-input type="number" v-model="data.bmi" :disabled="!edit"></b-form-input>
      </b-col>
      <b-col>
        <span class="sa-form-label">{{ this.$i18n.t("patient.idoneity.lblWaist") }}</span>
        <b-form-input type="number" v-model="data.vita" :disabled="!edit"></b-form-input>
        <span class="sa-form-label">{{ this.$i18n.t("patient.idoneity.lblBSA") }}</span>
        <b-form-input type="number" v-model="data.bsa" :disabled="!edit"></b-form-input>
      </b-col>
      <b-col>
        <span class="sa-form-label">{{ this.$i18n.t("patient.idoneity.lblWeight") }}</span>
        <b-form-input type="number" v-model="data.peso" :disabled="!edit"></b-form-input> <span class="sa-form-label">{{ this.$i18n.t("patient.idoneity.lblGlycemia") }}</span>
        <b-form-input type="number" v-model="data.glicemia" :disabled="!edit"></b-form-input>
      </b-col>
      <b-col>
        <span class="sa-form-label">{{ this.$i18n.t("patient.idoneity.lblHeight") }}</span>
        <b-form-input type="number" v-model="data.altezza" :disabled="!edit"></b-form-input> <span class="sa-form-label">{{ this.$i18n.t("patient.idoneity.lblPAMax") }}</span>
        <b-form-input type="number" v-model="data.paMax" :disabled="!edit"></b-form-input>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="3">
        <span class="sa-form-label">{{ this.$i18n.t("patient.idoneity.lblPAMin") }}</span>
        <b-form-input type="number" v-model="data.paMin" :disabled="!edit"></b-form-input>
      </b-col>
      <b-col cols="3">
        <span class="sa-form-label">{{ this.$i18n.t("patient.idoneity.lblCholesterol") }}</span>
        <b-form-input type="number" v-model="data.colesterolo" :disabled="!edit"></b-form-input>
      </b-col>
      <b-col>
        <span class="sa-form-label">{{ this.$i18n.t("patient.idoneity.lblDiabetes") }}</span>
        <b-form-input v-model="data.diabete" :disabled="!edit"></b-form-input>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="5">
        <span class="sa-form-label">{{ this.$i18n.t("patient.idoneity.lblBloodGroup") }}</span>
        <b-form-input v-model="data.gruppoSanguigno" :disabled="!edit"></b-form-input>
        <span class="sa-form-label">{{ this.$i18n.t("patient.idoneity.lblCirculatorySystem") }}</span>
        <b-form-textarea rows="4" max-rows="9" v-model="data.apparatoCircolatorio" :disabled="!edit"></b-form-textarea>
        <span class="sa-form-label">{{ this.$i18n.t("patient.idoneity.lblLocomotorSystem") }}</span>
        <b-form-textarea rows="4" max-rows="9" v-model="data.apparatoLocomotorio" :disabled="!edit"></b-form-textarea>
      </b-col>
      <b-col></b-col>
      <b-col cols="5">
        <span class="sa-form-label">{{ this.$i18n.t("patient.idoneity.lblRHFactor") }}</span>
        <b-form-input v-model="data.fattoreRh" :disabled="!edit"></b-form-input>
        <span class="sa-form-label">{{ this.$i18n.t("patient.idoneity.lblRespiratorySystem") }}</span>
        <b-form-textarea rows="4" max-rows="9" v-model="data.apparatoRespiratorio" :disabled="!edit"></b-form-textarea>
        <span class="sa-form-label">{{ this.$i18n.t("patient.idoneity.lblAbdomenGeneralOrgans") }}</span>
        <b-form-textarea rows="4" max-rows="9" v-model="data.addomeOrgGen" :disabled="!edit"></b-form-textarea>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <span class="sa-form-label">{{ this.$i18n.t("patient.idoneity.lblLimbs") }}</span> <b-form-textarea rows="4" max-rows="9" v-model="data.arti" :disabled="!edit"></b-form-textarea>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <span class="sa-form-label">{{ this.$i18n.t("patient.idoneity.lblNaturalSightRightEye") }}</span>
        <b-form-input type="number" v-model="data.vistaNOd" :disabled="!edit"></b-form-input>
        <span class="sa-form-label">{{ this.$i18n.t("patient.idoneity.lblCurrentSightRightEye") }}</span>
        <b-form-input type="number" v-model="data.vistaCOd" :disabled="!edit"></b-form-input>
      </b-col>
      <b-col>
        <span class="sa-form-label">{{ this.$i18n.t("patient.idoneity.lblNaturalSightLeftEye") }}</span>
        <b-form-input type="number" v-model="data.vistaNOs" :disabled="!edit"></b-form-input>
        <span class="sa-form-label">{{ this.$i18n.t("patient.idoneity.lblCurrentSightLeftEye") }}</span>
        <b-form-input type="number" v-model="data.vistaCOs" :disabled="!edit"></b-form-input>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <span class="sa-form-label">{{ this.$i18n.t("patient.idoneity.lblCromaticSense") }}</span>
        <b-form-textarea rows="4" max-rows="9" v-model="data.sensoCromatico" :disabled="!edit"></b-form-textarea>
        <span class="sa-form-label">{{ this.$i18n.t("patient.idoneity.lblHearingSense") }}</span>
        <b-form-textarea rows="4" max-rows="9" v-model="data.udito" :disabled="!edit"></b-form-textarea>
        <span class="sa-form-label">{{ this.$i18n.t("patient.idoneity.lblExaminationConclusion") }}</span>
        <b-form-textarea rows="4" max-rows="9" v-model="data.conclusioniEsObiettivo" :disabled="!edit"></b-form-textarea>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <span class="sa-form-label">Stato</span><br />
        {{ data.firmaEo }}
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  props: ["data", "edit"],
  data() {
    return {};
  },
};
</script>
