<template>
    <sa-page-layout :btnAnnullaVisible="true" :btnEditVisible="true" :linkback="linkback" :linkedit="linkedit">
        <template slot="toolbar-title">
            <paziente-anagrafica :linkPatient="linkPatient" :idPaziente="idPaziente"></paziente-anagrafica>
        </template>
        <template slot="table-body">
            <div class="sa-tab-scroll">
                <anamnesi-familiare-view-component :idEvento="idEvento"></anamnesi-familiare-view-component>
            </div>
        </template>
    </sa-page-layout>
</template>
<script>
import SaPageLayout from "../../../../../template/sa/components/layout/contents/SaPageLayout.vue";
import PazienteAnagrafica from "../../../components/PazienteAnagrafica.vue";
import AnamnesiFamiliareViewComponent from "./AnamnesiFamiliareViewComponent.vue";
export default {
    props: {
        utente: {
            type: Object,
            default: function() {
                return null;
            },
        },
    },
    components: { SaPageLayout, PazienteAnagrafica, AnamnesiFamiliareViewComponent },
    computed: {
        idPaziente() {
            return this.$route.query.idPaziente;
        },
        idEvento() {
            return this.$route.params.id;
        },
        linkPatient: {
            get: function() {
                let returnValue = "";
                if (this.utente.gruppo) {
                    if (this.utente.gruppo.menuGruppo) {
                        if (this.utente.gruppo.menuGruppo.length > 1) {
                            return "/paziente/view/" + this.idPaziente;
                        } else {
                            if (this.utente.gruppo.menuGruppo.length == 1) {
                                if (this.utente.gruppo.menuGruppo[0].primaryKey === "pazienti") {
                                    return "/paziente/view/" + this.idPaziente;
                                } else {
                                    return "/paziente/homepaziente";
                                }
                            }
                        }
                    }
                }
                return returnValue;
            },
        },
    },
    data() {
        return {
            linkback: "/paziente/diabetologia/anamnesifamiliare",
            linkedit: "/paziente/diabetologia/anamnesifamiliare/edit/",
        };
    },
    mounted() {
        let me = this;
        me.linkedit = me.linkedit + me.idEvento;
    },
    methods: {},
};
</script>
