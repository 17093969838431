import AfaList from "./components/AfaList.vue";
import AfaEdit from "./components/AfaEdit.vue";
import AfaView from "./components/AfaView.vue";
import IdoneitaList from "./components/idoneita/IdoneitaList.vue";
import IdoneitaEdit from "./components/idoneita/IdoneitaEdit.vue";
import IdoneitaView from "./components/idoneita/IdoneitaView.vue";

export default {
  AfaList,
  AfaEdit,
  AfaView,
  IdoneitaList,
  IdoneitaEdit,
  IdoneitaView,
};
