<template>
    <sa-page-view-edit ref="pgaView" :backVisible="true" :editVisible="true" :titolo="data.titolo" :linkback="pagelink" :linkedit="linkedit" :showmolalloading="showmolalloading">
        <template slot="btn-left"> </template>
        <template slot="filter">
            <b-row>
                <b-col cols="12" lg="2" md="2" class="sa-text-right">Messaggio:</b-col>
                <b-col cols="12" lg="10" md="10" class="sa-text-left">
                    <span class="sa-text-data">{{ data.testo }}</span>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" lg="2" md="2" class="sa-text-right">Note:</b-col>
                <b-col cols="12" lg="10" md="10" class="sa-text-left">
                    <span class="sa-text-data">{{ data.note }}</span>
                </b-col>
            </b-row>
            <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col lg="12">
                    <p>Riepilogo</p>
                </b-col>
                <b-col cols="4" sm="2" md="2" lg="2">
                    <p @click="onFilterDetails('TUTTI')">
                        Totale: <span class="sa-text-bold sa-click">{{ rows }}</span>
                    </p>
                </b-col>
                <b-col cols="4" sm="3" md="3" lg="3">
                    <p @click="onFilterDetails('ATTESA_INVIO')">
                        Attesa Invio: <span class="sa-text-bold sa-click">{{ attesaInvio }}</span>
                    </p>
                </b-col>
                <b-col cols="4" sm="3" md="3" lg="3">
                    <p class="sa-text-green" @click="onFilterDetails('INVIATI')">
                        Inviati: <span class="sa-text-bold sa-click">{{ inviati }}</span>
                    </p>
                </b-col>
                <b-col cols="4" sm="2" md="2" lg="2">
                    <p class="sa-text-red" @click="onFilterDetails('ERROR')">
                        Errori: <span class="sa-text-bold sa-click">{{ errori }}</span>
                    </p>
                </b-col>
                <b-col cols="4" sm="2" md="2" lg="2">
                    <p class="sa-text-red" @click="onFilterDetails('NON_VALIDI')">
                        Non Validi: <span class="sa-text-bold sa-click">{{ nonValidi }}</span>
                    </p>
                </b-col>
            </b-row>
            <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col lg="12" class="my-1 text-right">
                    <b-button :disabled="elaborazione" variant="light sa-margin-left" size="sm" @click="onSendAll"><b-icon icon="arrow-up" variant="secondary"></b-icon>&nbsp;Invia Tutti</b-button>
                    <b-button :disabled="elaborazione" variant="light sa-margin-left" v-b-modal.mdlCaricaCsv size="sm" @click="onNuovoFileCsv"><b-icon icon="upload" variant="secondary"></b-icon>&nbsp;Carica File '.csv'</b-button>
                    <b-button variant="light sa-margin-left" size="sm" @click="onRefreshDettatio"><b-icon icon="plus" variant="secondary"></b-icon>&nbsp;Aggiorna</b-button>
                    <b-button variant="light sa-margin-left" v-b-modal.mdlDettaglioRicevente size="sm" @click="onNuovoRicevente"><b-icon icon="plus" variant="secondary"></b-icon>&nbsp;Nuovo</b-button>
                </b-col>
            </b-row>
        </template>
        <template slot="header">
            <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col cols="6" lg="3">
                    <p>Totale Record: {{ rows }}</p>
                </b-col>
                <b-col cols="6" lg="9">
                    <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
            </b-row>
        </template>
        <template slot="body">
            <div class="b-table-sticky-header">
                <b-table sticky-header ref="table" id="tblTask" :filter="filter" stacked="xl" striped hover :items="items" :fields="fields" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table" @filtered="onFiltered">
                    <!--<template #cell(nome)="{ item }">
                                    <router-link class="sa-edit-link" :to="'/gesantask/view/' + item.id">{{ item.nome }}</router-link>
                                </template>-->
                    <template class="text-center" #cell(numeroCellulare)="{ item }">
                        <span v-if="item.numeroCellulareValido">{{ item.numeroCellulare }} <font-awesome-icon size="lg" class="sa-text-green" icon="check" tabindex="-1"/></span>
                        <span v-else>{{ item.numeroCellulare }} <font-awesome-icon size="lg" class="sa-text-red" icon="times" tabindex="-1"/></span>
                    </template>
                    <template class=".d-none" #cell(statoInvio)="{ item }">
                        <span>{{ item.statoInvio }} </span>
                    </template>
                    <template class="text-center" v-slot:cell(actions)="row">
                        <b-button size="sm" class="mr-1" variant="outline-primary" @click="onEditRicevente(row.item, row.index, $event.target)">
                            <font-awesome-icon size="lg" icon="pencil-alt" tabindex="-1" />
                        </b-button>
                        <b-button size="sm" class="mr-1" variant="outline-danger" @click="onDeleteRicevente(row.item, row.index, $event.target)">
                            <font-awesome-icon size="lg" icon="trash" tabindex="-1" />
                        </b-button>
                    </template>
                </b-table>
            </div>
        </template>
        <template slot="footer">
            <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col cols="6" lg="3">
                    <p>Totale Record: {{ rows }}</p>
                </b-col>
                <b-col cols="6" lg="9">
                    <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
            </b-row>
            <b-modal id="mdlDettaglioRicevente" ref="mdlDettaglioRicevente" no-close-on-backdrop centered :title="data.titolo" @hidden="onHideDettaglioRicevente" @show="onShowDettaglioRicevente" ok-only>
                <b-row>
                    <b-col cols="12" lg="12" md="12" class="sa-text-left">
                        <b-form-input v-model="ricevente.nome" size="sm" placeholder="Nome" required></b-form-input>
                    </b-col>
                    <b-col cols="12" lg="12" md="12" class="sa-text-left">
                        <b-form-input v-model="ricevente.cognome" size="sm" placeholder="Cognome" required></b-form-input>
                    </b-col>
                    <b-col cols="12" lg="12" md="12" class="sa-text-left">
                        <b-form-input v-model="ricevente.codiceFiscale" size="sm" placeholder="Codice Fiscale" required></b-form-input>
                    </b-col>
                    <b-col cols="12" lg="12" md="12" class="sa-text-left">
                        <b-form-input v-model="ricevente.testoOpzionale" size="sm" placeholder="Testo Opzionale" required></b-form-input>
                    </b-col>
                    <b-col cols="12" lg="12" md="12" class="sa-text-left">
                        <b-form-input v-model="ricevente.numeroCellulare" size="sm" placeholder="Numero Cellulare" required></b-form-input>
                    </b-col>
                </b-row>

                <template #modal-footer="{ ok }">
                    <b-button size="sm" variant="outline-secondary" @click="onSalvaReicevente()"> <font-awesome-icon size="xs" icon="save" tabindex="-1" /> Salva </b-button>
                    <b-button size="sm" variant="info" @click="ok()">
                        Chiudi
                    </b-button>
                </template>
            </b-modal>
            <b-modal id="mdlCaricaCsv" ref="mdlCaricaCsv" no-close-on-backdrop centered :title="data.titolo" @hidden="onHideCaricaFileCsv" @show="onShowCaricaFileCsv" ok-only>
                <b-row>
                    <b-col cols="12" lg="12" md="12" class="sa-text-left">
                        <b-form-file v-model="fileCsv.file" ref="file" accept=".csv" placeholder="Seleziona o trascina il file qui..."></b-form-file>
                    </b-col>
                    <b-col cols="12" lg="12" md="12" class="sa-text-left">
                        <b-form-checkbox v-model="fileCsv.primaRigaIntestazione" name="check-button" switch>
                            La prima riga è l'intestazione
                        </b-form-checkbox>
                    </b-col>
                </b-row>

                <template #modal-footer="{ ok }">
                    <b-button size="sm" variant="outline-secondary" @click="onCaricaFileCsv()"> <font-awesome-icon size="xs" icon="upload" tabindex="-1" /> Carica </b-button>
                    <b-button size="sm" variant="info" @click="ok()">
                        Chiudi
                    </b-button>
                </template>
            </b-modal>
        </template>
    </sa-page-view-edit>
</template>
<script>
import axios from "axios";
import moment from "moment";
import { SaPageViewEdit } from "../../../template/sa/components/layout/contents";
export default {
    components: { SaPageViewEdit },
    computed: {
        rows() {
            return this.items.length;
        },
        attesaInvio() {
            let atesaInvio = 0;
            this.items.forEach(function(item) {
                if (item.statoInvio === "ATTESA INVIO") {
                    atesaInvio++;
                }
            });
            return atesaInvio;
        },
        inviati() {
            let inviati = 0;
            this.items.forEach(function(item) {
                if (item.statoInvio === "INVIATO") {
                    inviati++;
                }
            });
            return inviati;
        },
        errori() {
            let errori = 0;
            this.items.forEach(function(item) {
                if (item.statoInvio === "ERROR") {
                    errori++;
                }
            });
            return errori;
        },
        nonValidi() {
            let nonValidi = 0;
            this.items.forEach(function(item) {
                if (!item.numeroCellulareValido) {
                    nonValidi++;
                }
            });
            return nonValidi;
        },
    },
    data() {
        return {
            task: { nome: "" },
            showmolalloading: false,
            pagelink: "/sms",
            linkedit: null,
            elaborazione: false,
            currentPage: 1,
            totalRows: 1,
            perPage: 100,
            filter: "",
            pathResource: "/inviosms",
            fields: [
                {
                    label: "Cognome",
                    key: "cognome",
                    sortable: true,
                    thStyle: "width: 9rem",
                },
                {
                    label: "Nome",
                    key: "nome",
                    sortable: true,
                    thStyle: "width: 9rem",
                },
                {
                    label: "Codice Fiscale",
                    key: "codiceFiscale",
                    sortable: true,
                    thStyle: "width: 11rem",
                },
                {
                    label: "Testo Opzionale",
                    key: "testoOpzionale",
                    sortable: true,
                },
                {
                    label: "Numero",
                    key: "numeroCellulare",
                    sortable: true,
                    thStyle: "width: 9rem",
                },
                {
                    label: "Stato",
                    key: "statoInvio",
                    sortable: true,
                    thStyle: "width: 9rem",
                },
                { key: "actions", label: "", thStyle: "width: 7rem", tdClass: "text-center" },
            ],
            items: [],
            data: {},
            ricevente: { id: "-1", idInvioSms: null },
            fileCsv: { primaRigaIntestazione: true, file: null },
            stato: { log: "" },
            filtro: {},
        };
    },
    created: function() {},
    mounted: function() {
        let me = this;
        me.id = this.$route.params.id;
        me.linkedit = "/sms/edit/" + me.id;
        me.loadData();
    },
    update: function() {
        console.log("Update");
    },
    methods: {
        formatDate(value) {
            if (value) {
                return moment(new Date(value)).format("DD-MM-YYYY HH:mm:ss");
            } else {
                return "---";
            }
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        loadData() {
            let me = this;
            //me.showmolalloading = true;
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
            axios.get(link + me.id).then((response) => {
                me.data = response.data.data;
                me.showmolalloading = false;
                me.loadDataDetails();
                me.onShowStato();
            });
        },
        loadDataDetails() {
            let me = this;
            me.showmolalloading = true;
            let link = process.env.VUE_APP_PATH_API + "/inviosmsdettaglio";
            axios.get(link, { params: { idInvioSms: me.id } }).then((response) => {
                me.items = response.data.data;
                me.totalRows = me.items.length;
                me.showmolalloading = false;
            });
        },
        onEdit() {
            let me = this;
            me.$router.replace(me.routeLink + "/edit/" + me.data.id).catch((err) => {
                err;
            });
        },
        onBack() {
            let me = this;
            me.$router.replace(me.routeLink).catch((err) => {
                err;
            });
            clearInterval(me.interval);
        },
        onShowStato() {
            let me = this;
            me.interval = setInterval(() => me.getStato(), 2000);
        },
        getStato() {
            let me = this;
            //me.showmolalloading = true;
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "/stato/";
            axios.get(link + me.id).then((response) => {
                let data = response.data.data;
                if (data.stato) {
                    if (data.stato === "FERMO") {
                        clearInterval(me.interval);
                        me.elaborazione = false;
                    } else {
                        me.loadDataDetails();
                        me.elaborazione = true;
                    }
                } else {
                    me.elaborazione = false;
                    clearInterval(me.interval);
                }
            });
        },
        onFilterDetails(el) {
            let me = this;
            switch (el) {
                case "ATTESA_INVIO":
                    me.filter = "ATTESA INVIO";
                    break;
                case "INVIATI":
                    me.filter = "INVIATO";
                    break;
                case "ERROR":
                    me.filter = "ERROR";
                    break;
                case "NON_VALIDI":
                    me.filter = "false";
                    break;
                case "TUTTI":
                    me.filter = null;
                    break;
            }
        },
        onNuovoRicevente() {
            let me = this;
            me.ricevente.idInvioSms = me.data.id;
        },
        onHideDettaglioRicevente() {
            let me = this;
            me.ricevente = { id: "-1", idInvioSms: me.data.id };
            //console.log("OK");
        },
        onShowDettaglioRicevente() {},
        onEditRicevente(item) {
            let me = this;
            me.ricevente = item;
            me.$refs.mdlDettaglioRicevente.show();
        },
        onNuovoFileCsv() {},
        onCaricaFileCsv() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "/upload";
            let reader = new FileReader();
            reader.readAsDataURL(me.fileCsv.file);
            reader.onload = () => {
                let invioFileCsv = {};
                invioFileCsv.idInvioSms = me.data.id;
                invioFileCsv.file = reader.result;
                invioFileCsv.primaRigaIntestazione = me.fileCsv.primaRigaIntestazione;
                let jsonData = JSON.stringify(invioFileCsv);
                axios.post(link, jsonData).then(() => {
                    me.showmolalloading = false;
                    me.loadDataDetails();
                    me.$refs.mdlCaricaCsv.hide();
                    me.onShowStato();
                });
            };
        },
        onShowCaricaFileCsv() {},
        onHideCaricaFileCsv() {},
        onRefreshDettatio() {
            let me = this;
            me.loadDataDetails();
        },
        onSendAll() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "/sendall/" + me.data.id;
            axios.get(link).then(() => {
                me.showmolalloading = false;
                me.loadDataDetails();
                me.onShowStato();
            });
        },
        onSalvaReicevente() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + "/inviosmsdettaglio";
            let jsonData = JSON.stringify(me.ricevente);

            me.$refs.mdlDettaglioRicevente.hide();

            me.showmolalloading = true;
            if (me.ricevente.id == "-1") {
                axios
                    .post(link, jsonData)
                    .then((response) => {
                        me.data = response.data.data;
                        me.showmolalloading = false;
                        me.loadDataDetails();
                        me.$refs.mdlDettaglioRicevente.hide();
                    })
                    .catch((error) => {
                        me.showmolalloading = false;
                        this.$bvModal
                            .msgBoxOk(error.response.data.messaggio, {
                                title: "ATTENZIONE",
                                size: "sm",
                                okVariant: "outline-danger",
                                headerClass: "sa-msg-header-danger",
                                footerClass: "p-2 border-top-0",
                                centered: true,
                            })
                            .then((value) => {
                                this.boxTwo = value;
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                    });
            } else {
                axios
                    .put(link + "/" + me.ricevente.id, jsonData)
                    .then((response) => {
                        me.gruppo = response.data.data;
                        me.showmolalloading = false;
                        me.loadDataDetails();
                        me.$refs.mdlDettaglioRicevente.hide();
                    })
                    .catch((error) => {
                        me.showmolalloading = false;
                        this.$bvModal
                            .msgBoxOk(error.response.data.messaggio, {
                                title: "ATTENZIONE",
                                size: "sm",
                                okVariant: "outline-danger",
                                headerClass: "sa-msg-header-danger",
                                footerClass: "p-2 border-top-0",
                                centered: true,
                            })
                            .then((value) => {
                                this.boxTwo = value;
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                    });
            }
        },
    },
};
</script>
