<template>
    <div>
        <b-row><h4>Valori</h4></b-row>
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <b-row>
                    <b-col xs="4" sm="4" md="4" lg="4" xl="4">
                        <strong>FVC (L)</strong><br />
                        {{ data.fvc }}
                    </b-col>
                    <b-col xs="4" sm="4" md="4" lg="4" xl="4">
                        <strong>FEV1 (L)</strong><br />
                        {{ data.fev1 }}
                    </b-col>
                    <b-col xs="4" sm="4" md="4" lg="4" xl="4">
                        <strong>FEV1/FVC% (%) </strong><br />
                        {{ data.fev1Fvc }}
                    </b-col>
                </b-row>
                <hr />
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <b-row>
                    <b-col xs="4" sm="4" md="4" lg="4" xl="4">
                        <strong>PEF(L/s)</strong><br />
                        {{ data.pef }}
                    </b-col>
                    <b-col xs="4" sm="4" md="4" lg="4" xl="4">
                        <strong>FEF-25-75% (L/s) </strong><br />
                        {{ data.fef2575 }}
                    </b-col>
                    <b-col xs="4" sm="4" md="4" lg="4" xl="4">
                        <strong>MEF25% (L/s) </strong><br />
                        {{ data.mef25 }}
                    </b-col>
                </b-row>
                <hr />
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <b-row>
                    <b-col xs="4" sm="4" md="4" lg="4" xl="4">
                        <strong>MEF50% (L/s)</strong><br />
                        {{ data.mef50 }}
                    </b-col>
                    <b-col xs="4" sm="4" md="4" lg="4" xl="4">
                        <strong>MEF75% (L/s) </strong><br />
                        {{ data.mef75 }}
                    </b-col>
                    <b-col xs="4" sm="4" md="4" lg="4" xl="4">
                        <strong>MVV(L/m) </strong><br />
                        {{ data.mvv }}
                    </b-col>
                </b-row>
                <hr />
            </b-col>
        </b-row>
        <br />
        <b-row><h4>Valori Normali</h4></b-row>
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <b-row>
                    <b-col xs="4" sm="4" md="4" lg="4" xl="4">
                        <strong>FVC (L)</strong><br />
                        {{ data.fvcNormale }}
                    </b-col>
                    <b-col xs="4" sm="4" md="4" lg="4" xl="4">
                        <strong>FEV1 (L)</strong><br />
                        {{ data.fev1Normale }}
                    </b-col>
                    <b-col xs="4" sm="4" md="4" lg="4" xl="4">
                        <strong>FEV1/FVC% (%)</strong><br />
                        {{ data.fev1FvcNormale }}
                    </b-col>
                </b-row>
                <hr />
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <b-row>
                    <b-col xs="4" sm="4" md="4" lg="4" xl="4">
                        <strong>PEF(L/s)</strong><br />
                        {{ data.pefNormale }}
                    </b-col>
                    <b-col xs="4" sm="4" md="4" lg="4" xl="4">
                        <strong>FEF-25-75% (L/s) </strong><br />
                        {{ data.fef2575Normale }}
                    </b-col>
                    <b-col xs="4" sm="4" md="4" lg="4" xl="4">
                        <strong>MEF25% (L/s) </strong><br />
                        {{ data.mef25Normale }}
                    </b-col>
                </b-row>
                <hr />
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <b-row>
                    <b-col xs="4" sm="4" md="4" lg="4" xl="4">
                        <strong>MEF50% (L/s)</strong><br />
                        {{ data.mef50Normale }}
                    </b-col>
                    <b-col xs="4" sm="4" md="4" lg="4" xl="4">
                        <strong>MEF75% (L/s) </strong><br />
                        {{ data.mef75Normale }}
                    </b-col>
                    <b-col xs="4" sm="4" md="4" lg="4" xl="4">
                        <strong>MVV(L/m) </strong><br />
                        {{ data.mvvNormale }}
                    </b-col>
                </b-row>
                <hr />
            </b-col>
        </b-row>
        <br />
        <h4>Conclusioni:</h4>
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                {{ data.conclusioni }}
            </b-col>
        </b-row>
        <br />
        <hr />
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                        <strong>Stato </strong><br />
                        {{ data.firma }}
                    </b-col>
                </b-row>
                <hr />
            </b-col>
        </b-row>
    </div>
</template>
<script>
export default {
    props: {
        data: {
            Type: Object,
            default: function() {
                return {
                    fvc: null,
                    fev1: null,
                    fev1Fvc: null,
                    pef: null,
                    fef2575: null,
                    mef25: null,
                    mef50: null,
                    mef75: null,
                    mvv: null,
                    conclusioni: "",
                    firma: "",
                    /*Valori Nomali */

                    fvcNormale: null,
                    fev1Normale: null,
                    fev1fvcNormale: null,
                    pefNormale: null,
                    fef2575Normale: null,
                    mef25Normale: null,
                    mef50Normale: null,
                    mef75Normale: null,
                    mvvNormale: null,
                };
            },
        },
    },
};
</script>
