<template>
    <sa-page-layout :showModalLoading="showModalLoading" :btnSaveVisible="true" :btnAnnullaVisible="true" :btnBackVisible="false" :linkback="linkback" :pathResource="pathResource" :data="jsonData">
        <template slot="toolbar-title">
            <paziente-anagrafica :linkPatient="linkPatient" :idPaziente="idPaziente"></paziente-anagrafica>
        </template>
        <template slot="table-header">
            <h5 class="sa-text-left">Dati</h5>
        </template>
        <template slot="table-body">
            <div class="sa-tab-scroll">
                <!-- InizioBody  -->
                <h6 class="sa-text-left">{{ this.$i18n.t("menu.pazienti.diabetology.anamnesis") }}</h6>
                <div class="sa-form-section">
                    <b-row>
                        <b-col xs="2" sm="2" md="6" lg="6" xl="6">
                            <strong>{{ this.$i18n.t("patient.diabetology.lblAgeOfAppearance") }}</strong>
                            <br />
                            <b-form-input v-model="jsonData.etaDiComparsa"></b-form-input>
                        </b-col>

                        <b-col xs="10" sm="10" md="6" lg="6" xl="6">
                            <strong>{{ this.$i18n.t("patients.lblSymptoms") }}</strong>
                            <br />
                            <b-form-input v-model="jsonData.primiSintomi"></b-form-input>
                        </b-col>
                    </b-row>
                    <hr />
                </div>
                <div class="sa-form-section">
                    <b-row>
                        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                            <strong>{{ this.$i18n.t("patients.lblDiagnosis") }}</strong>
                            <br />
                            <b-form-input v-model="jsonData.diagnosi"></b-form-input>
                        </b-col>
                        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                            <strong>{{ this.$i18n.t("patients.lblReasonOfCheck") }}</strong>
                            <br />
                            <b-form-input v-model="jsonData.motivoDelControlloAlCentro"></b-form-input>
                        </b-col>
                    </b-row>
                    <hr />

                    <b-row>
                        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                            <strong>{{ this.$i18n.t("patients.lblCurrentSymptoms") }}</strong>
                            <br />
                            <b-form-textarea v-model="jsonData.sintomatologiaAttuale" no-resize></b-form-textarea>
                        </b-col>
                        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                            <strong>{{ this.$i18n.t("patients.lblComplications") }}</strong>
                            <br />
                            <b-form-textarea v-model="jsonData.complicanze" no-resize></b-form-textarea>
                        </b-col>
                    </b-row>
                </div>
                <div class="sa-form-section">
                    <b-row>
                        <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                            <h6 class="sa-text-left">{{ this.$i18n.t("patients.lblConnectionOtherDiseases") }}</h6>
                            <br />
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col xs="12" sm="12" md="12" lg="2" xl="2">
                            <strong>{{ this.$i18n.t("patients.lblPregnancy") }}</strong>
                            <b-form-checkbox v-model="jsonData.coincidenzaConGravidanza" name="check-button" switch size="lg"></b-form-checkbox>
                        </b-col>
                        <b-col xs="12" sm="12" md="12" lg="2" xl="2">
                            <strong>{{ this.$i18n.t("patients.lblStress") }}</strong>
                            <b-form-checkbox v-model="jsonData.coincidenzaConStress" name="check-button" switch size="lg"></b-form-checkbox>
                        </b-col>
                        <b-col xs="12" sm="12" md="12" lg="2" xl="2">
                            <strong>{{ this.$i18n.t("patients.lblClimacteric") }}</strong>
                            <b-form-checkbox v-model="jsonData.coincidenzaConClimaterio" name="check-button" switch size="lg"></b-form-checkbox>
                        </b-col>
                        <b-col xs="12" sm="12" md="12" lg="2" xl="2">
                            <strong>{{ this.$i18n.t("patients.lblTrauma") }}</strong>
                            <b-form-checkbox v-model="jsonData.coincidenzaConTraumi" name="check-button" switch size="lg"></b-form-checkbox>
                        </b-col>
                        <b-col xs="12" sm="12" md="12" lg="2" xl="2">
                            <strong>{{ this.$i18n.t("patients.lblMedications") }}</strong>
                            <b-form-checkbox v-model="jsonData.coincidenzaConAssunzioniFarmaci" name="check-button" switch size="lg"></b-form-checkbox>
                        </b-col>
                    </b-row>
                    <br />
                    <b-row>
                        <b-col xs="12" sm="12" md="12" lg="2" xl="2">
                            <strong>{{ this.$i18n.t("patients.lblDiseases") }}</strong>
                            <b-form-checkbox @change="onFormattText('coincidenzaConMalattie')" v-model="jsonData.coincidenzaConMalattie" name="check-button" switch size="lg"></b-form-checkbox>
                        </b-col>
                        <b-col xs="10" sm="10" md="10" lg="10" xl="10">
                            <br />
                            <b-form-input :disabled="!jsonData.coincidenzaConMalattie" v-model="jsonData.coincidenzaConMalattieText"></b-form-input>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col xs="12" sm="12" md="12" lg="2" xl="2">
                            <strong>{{ this.$i18n.t("patients.lblMore") }}</strong>
                            <b-form-checkbox @change="onFormattText('coincidenzaConAltro')" v-model="jsonData.coincidenzaConAltro" name="check-button" switch size="lg"></b-form-checkbox>
                        </b-col>
                        <b-col xs="10" sm="10" md="10" lg="10" xl="10">
                            <br />
                            <b-form-input :disabled="!jsonData.coincidenzaConAltro" v-model="jsonData.coincidenzaConAltroText"></b-form-input>
                        </b-col>
                    </b-row>
                </div>
                <hr />
                <div class="sa-form-section">
                    <b-row>
                        <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                            <h6 class="sa-text-left">{{ this.$i18n.t("patients.lblConcomitantDiseases") }}</h6>
                            <br />
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col xs="12" sm="12" md="12" lg="2" xl="2">
                            <strong>{{ this.$i18n.t("patients.lblObesity") }}</strong>
                            <b-form-checkbox v-model="jsonData.patologieConcomitantiObesita" name="check-button" switch size="lg"></b-form-checkbox>
                        </b-col>
                        <b-col xs="12" sm="12" md="12" lg="2" xl="2">
                            <strong>{{ this.$i18n.t("patients.lblDyslipidemia") }}</strong>
                            <b-form-checkbox v-model="jsonData.patologieConcomitantiDislipidemia" name="check-button" switch size="lg"></b-form-checkbox>
                        </b-col>
                        <b-col xs="12" sm="12" md="12" lg="2" xl="2">
                            <strong> {{ this.$i18n.t("patients.lblHypertension") }}</strong>
                            <b-form-checkbox v-model="jsonData.patologieConcomitantiIpertensione" name="check-button" switch size="lg"></b-form-checkbox>
                        </b-col>

                        <b-col xs="12" sm="12" md="12" lg="2" xl="2">
                            <strong>{{ this.$i18n.t("patients.lblLithiasis") }}</strong>
                            <b-form-checkbox v-model="jsonData.patologieConcomitantiLitiasiRenaleColec" name="check-button" switch size="lg"></b-form-checkbox>
                        </b-col>
                        <b-col xs="12" sm="12" md="12" lg="2" xl="2">
                            <strong>{{ this.$i18n.t("patients.lblUlcer") }}</strong>
                            <b-form-checkbox v-model="jsonData.patologieConcomitantiUlceraGastricaDuodenale" name="check-button" switch size="lg"></b-form-checkbox>
                        </b-col>
                        <b-col xs="12" sm="12" md="12" lg="2" xl="2">
                            <strong>{{ this.$i18n.t("patients.lblArthrosis") }}</strong>
                            <b-form-checkbox v-model="jsonData.patologieConcomitantiArtrosi" name="check-button" switch size="lg"></b-form-checkbox>
                        </b-col>
                    </b-row>
                    <br />
                    <b-row>
                        <b-col xs="12" sm="12" md="12" lg="2" xl="2">
                            <strong>{{ this.$i18n.t("patients.lblVas") }}</strong>
                            <b-form-checkbox v-model="jsonData.patologieConcomitantiVasculopatiaCoronaricaCerebralePerife" name="check-button" switch size="lg"></b-form-checkbox>
                        </b-col>
                        <b-col xs="12" sm="12" md="12" lg="2" xl="2">
                            <strong>{{ this.$i18n.t("patients.lblPsychiatricDiseases") }}</strong>
                            <b-form-checkbox v-model="jsonData.patologieConcomitantiMPsichiatriche" name="check-button" switch size="lg"></b-form-checkbox>
                        </b-col>
                        <b-col xs="12" sm="12" md="12" lg="2" xl="2">
                            <strong>{{ this.$i18n.t("patients.lblGenitalSphere") }}</strong>
                            <b-form-checkbox v-model="jsonData.patologieConcomitantiMSferaGenitale" name="check-button" switch size="lg"></b-form-checkbox>
                        </b-col>
                        <b-col xs="12" sm="12" md="12" lg="2" xl="2">
                            <strong>{{ this.$i18n.t("patients.lblBronchopneumus") }}</strong>
                            <b-form-checkbox v-model="jsonData.patologieConcomitantiBroncopneumopatie" name="check-button" switch size="lg"></b-form-checkbox>
                        </b-col>
                        <b-col xs="12" sm="12" md="12" lg="2" xl="2">
                            <strong>{{ this.$i18n.t("patients.lblOsteoporosis") }}</strong>
                            <b-form-checkbox v-model="jsonData.patologieConcomitantiOsteoporosi" name="check-button" switch size="lg"></b-form-checkbox>
                        </b-col>
                        <b-col xs="12" sm="12" md="12" lg="2" xl="2">
                            <strong>{{ this.$i18n.t("patients.lblThyroid") }}</strong>
                            <b-form-checkbox v-model="jsonData.patologieConcomitantiMTiroide" name="check-button" switch size="lg"></b-form-checkbox>
                        </b-col>
                    </b-row>
                    <br />
                    <b-row>
                        <b-col xs="12" sm="12" md="12" lg="2" xl="2">
                            <strong>{{ this.$i18n.t("patient.diabetology.lblOtherEndocrinopathies") }}</strong>
                            <b-form-checkbox @change="onFormattText('patologieConcomitantiAltreEndocrinopatie')" v-model="jsonData.patologieConcomitantiAltreEndocrinopatie" name="check-button" switch size="lg"></b-form-checkbox>
                        </b-col>
                        <b-col xs="10" sm="10" md="10" lg="10" xl="10">
                            <br />
                            <b-form-input :disabled="!jsonData.patologieConcomitantiAltreEndocrinopatie" v-model="jsonData.patologieConcomitantiAltreEndocrinopatieText"></b-form-input>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col xs="12" sm="12" md="12" lg="2" xl="2">
                            <strong>{{ this.$i18n.t("patients.lblMore") }}</strong>
                            <b-form-checkbox @change="onFormattText('patologieConcomitantiAltro')" v-model="jsonData.patologieConcomitantiAltro" name="check-button" switch size="lg"></b-form-checkbox>
                        </b-col>
                        <b-col xs="10" sm="10" md="10" lg="10" xl="10">
                            <br />
                            <b-form-input :disabled="!jsonData.patologieConcomitantiAltro" v-model="jsonData.patologieConcomitantiAltroText"></b-form-input>
                        </b-col>
                    </b-row>
                </div>
                <br />
                <hr />
                <div class="sa-form-section">
                    <h6 class="sa-text-left">{{ this.$i18n.t("patients.lblTherapyPraticated") }}</h6>
                    <br />
                    <b-row>
                        <b-col xs="12" sm="12" md="12" lg="2" xl="2">
                            <strong>{{ this.$i18n.t("patient.diet.lblTitle") }}</strong>
                            <b-form-checkbox v-model="jsonData.terapiePraticateDieta" name="check-button" switch size="lg"></b-form-checkbox>
                        </b-col>
                    </b-row>
                    <br />
                    <b-row>
                        <b-col xs="12" sm="12" md="12" lg="2" xl="2">
                            <strong>{{ this.$i18n.t("patients.lblOralAntiDiabetic") }}</strong>
                            <b-form-checkbox @change="onFormattText('terapiePraticateAntidiabeticiOrali')" v-model="jsonData.terapiePraticateAntidiabeticiOrali" name="check-button" switch size="lg"></b-form-checkbox>
                        </b-col>
                        <b-col xs="10" sm="10" md="10" lg="10" xl="10">
                            <br />
                            <b-form-input :disabled="!jsonData.terapiePraticateAntidiabeticiOrali" v-model="jsonData.terapiePraticateAntidiabeticiOraliText"></b-form-input>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col xs="12" sm="12" md="12" lg="2" xl="2">
                            <strong>{{ this.$i18n.t("patients.lblInsulin") }}</strong>
                            <b-form-checkbox @change="onFormattText('terapiePraticateInsulina')" v-model="jsonData.terapiePraticateInsulina" name="check-button" switch size="lg"></b-form-checkbox>
                        </b-col>
                        <b-col xs="10" sm="10" md="10" lg="10" xl="10">
                            <br />
                            <b-form-input :disabled="!jsonData.terapiePraticateInsulina" v-model="jsonData.terapiePraticateInsulinaText"></b-form-input>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col xs="12" sm="12" md="12" lg="2" xl="2">
                            <strong>{{ this.$i18n.t("patient.diabetology.lblOtherMedicines") }}</strong>
                            <b-form-checkbox @change="onFormattText('terapiePraticateAltriFarmaci')" v-model="jsonData.terapiePraticateAltriFarmaci" name="check-button" switch size="lg"></b-form-checkbox>
                        </b-col>
                        <b-col xs="10" sm="10" md="10" lg="10" xl="10">
                            <br />
                            <b-form-input :disabled="!jsonData.terapiePraticateAltriFarmaci" v-model="jsonData.terapiePraticateAltriFarmaciText"></b-form-input>
                        </b-col>
                    </b-row>
                </div>
            </div>
        </template>
    </sa-page-layout>
</template>
<script>
import axios from "axios";
import SaPageLayout from "../../../../../template/sa/components/layout/contents/SaPageLayout.vue";
import PazienteAnagrafica from "../../../components/PazienteAnagrafica.vue";
export default {
    props: {
        utente: {
            type: Object,
            default: function() {
                return null;
            },
        },
    },
    components: { SaPageLayout, PazienteAnagrafica },
    data() {
        return {
            linkback: null,
            pathResource: "/diabetologiaanamnesi",
            jsonData: {},
            showModalLoading: false,
        };
    },
    computed: {
        idPaziente() {
            return this.$route.query.idPaziente;
        },
        linkPatient: {
            get: function() {
                let returnValue = "";
                if (this.utente.gruppo) {
                    if (this.utente.gruppo.menuGruppo) {
                        if (this.utente.gruppo.menuGruppo.length > 1) {
                            return "/paziente/view/" + this.idPaziente;
                        } else {
                            if (this.utente.gruppo.menuGruppo.length == 1) {
                                if (this.utente.gruppo.menuGruppo[0].primaryKey === "pazienti") {
                                    return "/paziente/view/" + this.idPaziente;
                                } else {
                                    return "/paziente/homepaziente";
                                }
                            }
                        }
                    }
                }
                return returnValue;
            },
        },
    },
    mounted() {
        let me = this;
        me.jsonData.idPaziente = this.$route.query.idPaziente;
        me.jsonData.tipoEvento = "Diabetologia - Anamnesi";
        me.id = this.$route.params.id;
        if (me.id === "-1") {
            me.jsonData.dataEvento = new Date();
        }
        me.linkedit = "/paziente/diabetologia/anamnesi/edit/" + me.id + "?idPaziente=" + me.idPaziente;
        if (me.id !== "-1") {
            me.linkback = "/paziente/diabetologia/anamnesi/view/" + me.id + "?idPaziente=" + me.idPaziente;
            me.loadData();
        } else {
            me.linkback = "/paziente/diabetologia/anamnesi?idPaziente=" + me.idPaziente;
        }
    },

    methods: {
        loadData() {
            let me = this;
            if (me.id === "-1") {
                me.showModalLoading = false;
            } else {
                let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.id;
                axios.get(link).then((response) => {
                    me.jsonData = response.data.data;
                    me.showModalLoading = false;
                });
            }
        },
        onFormattText(value) {
            let me = this;
            switch (value) {
                case "coincidenzaConMalattie":
                    me.jsonData.coincidenzaConMalattieText = me.jsonData.coincidenzaConMalattie ? me.jsonData.coincidenzaConMalattieText : "";
                    break;
                case "coincidenzaConAltro":
                    me.jsonData.coincidenzaConAltroText = me.jsonData.coincidenzaConAltro ? me.jsonData.coincidenzaConAltroText : "";
                    break;
                case "patologieConcomitantiAltreEndocrinopatie":
                    me.jsonData.patologieConcomitantiAltreEndocrinopatieText = me.jsonData.patologieConcomitantiAltreEndocrinopatie ? me.jsonData.patologieConcomitantiAltreEndocrinopatieText : "";
                    break;
                case "terapiePraticateAltriFarmaci":
                    me.jsonData.terapiePraticateAltriFarmaciText = me.jsonData.terapiePraticateAltriFarmaci ? me.jsonData.terapiePraticateAltriFarmaciText : "";
                    break;
                case "terapiePraticateInsulina":
                    me.jsonData.terapiePraticateInsulinaText = me.jsonData.terapiePraticateInsulina ? me.jsonData.terapiePraticateInsulinaText : "";
                    break;
                case "patologieConcomitantiAltro":
                    me.jsonData.patologieConcomitantiAltroText = me.jsonData.patologieConcomitantiAltro ? me.jsonData.patologieConcomitantiAltroText : "";
                    break;
                case "terapiePraticateAntidiabeticiOrali":
                    me.jsonData.terapiePraticateAntidiabeticiOraliText = me.jsonData.terapiePraticateAntidiabeticiOrali ? me.jsonData.terapiePraticateAntidiabeticiOraliText : "";
                    break;
            }
        },
    },
};
</script>
