<template>
  <div>
    <b-row>
      <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
        <b-row>
          <b-col xs="12" sm="6" md="6" lg="6" xl="6">
            <strong>{{ this.$i18n.t("patient.idoneity.lblCV") }}</strong
            ><br />
            {{ jsonData.cv }} <br /><br />
            <strong>{{ this.$i18n.t("patient.idoneity.lblCVF") }}</strong> <br />
            {{ jsonData.cvf }} <br /><br />
            <strong>{{ this.$i18n.t("patient.idoneity.lblUBJVEMS") }}</strong
            ><br />
            {{ jsonData.vems }} <br /><br />
            <strong>{{ this.$i18n.t("patient.idoneity.lblVemsCvf") }}</strong
            ><br />
            {{ jsonData.vemsCvf }} <br /><br />
            <strong>{{ this.$i18n.t("patient.idoneity.lblMVV") }}</strong
            ><br />
            {{ jsonData.mvv }}
          </b-col>
          <b-col xs="12" sm="6" md="6" lg="6" xl="6">
            <strong>{{ this.$i18n.t("patient.idoneity.lblCVNormal") }}</strong
            ><br />
            {{ jsonData.cvNormale }} <br /><br />
            <strong>{{ this.$i18n.t("patient.idoneity.lblCVFNormal") }}</strong> <br />
            {{ jsonData.cvfNormale }} <br /><br />
            <strong>{{ this.$i18n.t("patient.idoneity.lblUBJVEMSNormal") }}</strong
            ><br />
            {{ jsonData.vemsNormale }} <br /><br />
            <strong>{{ this.$i18n.t("patient.idoneity.lblVemsCvfNormale") }}</strong
            ><br />
            {{ jsonData.vemsCvfNormale }} <br /><br />
            <strong>{{ this.$i18n.t("patient.idoneity.lblMVVNormale") }}</strong
            ><br />
            {{ jsonData.mvvNormale }}
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <hr />
    <b-row>
      <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
        <b-row>
          <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
            <strong>{{ this.$i18n.t("patient.idoneity.lblReport") }} </strong><br />
            {{ jsonData.refertoUrine }}
          </b-col>
        </b-row>
        <hr />
        <b-row>
          <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
            <b-row>
              <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <strong>Stato </strong><br />
                {{ jsonData.firma }}
              </b-col>
            </b-row>
            <hr />
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>
<script>
export default {
  props: {
    jsonData: {
      Type: Object,
      require: true,
    },
  },
};
</script>
