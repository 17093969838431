<template>
    <sa-page-layout :showmolalloading="showmolalloading" :btnAnnullaVisible="true" :btnSaveVisible="true" :linkback="linkback" :pathResource="pathResource" :data="data">
        <template slot="toolbar-title">
            <paziente-anagrafica :linkPatient="linkPatient" :idPaziente="idPaziente"></paziente-anagrafica>
        </template>
        <template slot="toolbar-row">
            <paziente-dashboard-component :idPaziente="idPaziente"></paziente-dashboard-component>
        </template>
        <template slot="table-header"> </template>
        <template slot="table-body">
            <b-card
                ><b-row
                    ><b-form-group
                        ><b-row
                            ><b-col
                                ><span>{{ this.$i18n.t("patient.rangeConfig.lblMeasurementName") }}</span
                                ><b-form-select v-model="data.misureNome" :options="opzioniMisure"></b-form-select></b-col
                            ><b-col
                                ><span>{{ this.$i18n.t("patient.rangeConfig.lblMinAlertValue") }}</span
                                ><b-form-input v-model="data.minRangeAllert" type="number" step="0.1"></b-form-input
                            ></b-col>
                            <b-col
                                ><span>{{ this.$i18n.t("patient.rangeConfig.lblMaxAlertValue") }}</span
                                ><b-form-input v-model="data.maxRangeAllert" type="number" step="0.1"></b-form-input
                            ></b-col>
                            <b-col
                                ><span>{{ this.$i18n.t("patient.rangeConfig.lblMinWarningValue") }}</span
                                ><b-form-input v-model="data.minRangeWarning" type="number" step="0.1"></b-form-input
                            ></b-col>
                            <b-col
                                ><span>{{ this.$i18n.t("patient.rangeConfig.lblMaxWarningValue") }}</span
                                ><b-form-input v-model="data.maxRangeWarning" type="number" step="0.1"></b-form-input
                            ></b-col>
                            <b-col></b-col> </b-row></b-form-group
                ></b-row>
            </b-card>
        </template>
        <template slot="table-footer"> </template>
    </sa-page-layout>
</template>
<script>
import axios from "axios";
import PazienteAnagrafica from "./PazienteAnagrafica.vue";
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
import PazienteDashboardComponent from "./PazienteDashboardComponent.vue";
export default {
    props: {
        utente: {
            type: Object,
            default: function() {
                return null;
            },
        },
    },
    components: { PazienteAnagrafica, SaPageLayout, PazienteDashboardComponent },
    computed: {
        idPaziente() {
            return this.$route.query.idPaziente;
        },
        rows() {
            return this.items.length;
        },
        linkPatient: {
            get: function() {
                let returnValue = "";
                if (this.utente.gruppo) {
                    if (this.utente.gruppo.menuGruppo) {
                        if (this.utente.gruppo.menuGruppo.length > 1) {
                            return "/paziente/view/" + this.idPaziente;
                        } else {
                            if (this.utente.gruppo.menuGruppo.length == 1) {
                                if (this.utente.gruppo.menuGruppo[0].primaryKey === "pazienti") {
                                    return "/paziente/view/" + this.idPaziente;
                                } else {
                                    return "/paziente/homepaziente";
                                }
                            }
                        }
                    }
                }
                return returnValue;
            },
        },
    },
    data() {
        return {
            pathResource: "/pazientemisurerange",
            linkback: "/paziente/rangeconfiguration",
            linktipodieta: "",
            perPage: 10,
            currentPage: 1,
            note: "",
            filter: null,
            showmolalloading: false,
            id: "-1",
            filtro: {},
            filterOn: [],
            fields: [
                {
                    label: this.$i18n.t("patient.rangeConfig.lblMeasurementName"),
                    key: "misureNome",
                    thStyle: "width: 3rem",
                    sortable: true,
                },
                {
                    label: this.$i18n.t("patient.rangeConfig.lblMinAlertValue"),
                    key: "minRangeAllert",
                    thStyle: "width: 2rem",
                    variant: "danger",
                },
                {
                    label: this.$i18n.t("patient.rangeConfig.lblMaxAlertValue"),
                    key: "maxRangeAllert",
                    thStyle: "width: 2rem",
                    variant: "danger",
                },
                {
                    label: this.$i18n.t("patient.rangeConfig.lblMinWarningValue"),
                    key: "minRangeWarning",
                    thStyle: "width: 2rem",
                    variant: "warning",
                },
                {
                    label: this.$i18n.t("patient.rangeConfig.lblMaxWarningValue"),
                    key: "maxRangeWarning",
                    thStyle: "width: 2rem",
                    variant: "warning",
                },
                {
                    label: "",
                    key: "save",
                    thStyle: "width: 2rem",
                },
                {
                    label: "",
                    key: "delete",
                    thStyle: "width: 2rem",
                },
                //{ class: "sa-table-column-action-single", thStyle: "width: 10rem" ,key: "actions", label: "" },
            ],
            opzioniMisure: [],
            items: [],
            data: {
                misureNome: null,
                idPaziente: null,
                minRangeAllert: 0,
                maxRangeAllert: 0,
                minRangeWarning: 0,
                maxRangeWarning: 0,
                createUser: null,
                createDate: null,
                updateUser: null,
                updateDate: null,
                proprietario: null,
            },
        };
    },
    created: function() {},
    mounted: function() {
        let me = this;
        me.id = this.$route.params.id;
        me.data.idPaziente = this.$route.query.idPaziente;
        me.loadData();
    },
    methods: {
        stampa(item) {
            console.log(item.id);
        },
        onSave() {
            let me = this;
            if (me.data.id === "-1" || me.data.id === null || me.data.id === undefined) {
                let link = process.env.VUE_APP_PATH_API + me.pathResource;
                axios
                    .post(link, JSON.stringify(me.data))
                    .then((response) => {
                        me.gruppo = response.data.data;
                        me.$router.replace(me.linkback + "?idPaziente=" + me.idPaziente).catch((err) => {
                            err;
                        });
                    })
                    .catch((error) => {
                        me.$refs["sa-modal-loading"].close();
                        this.$bvModal
                            .msgBoxOk(error.response.data.messaggio, {
                                title: "ATTENZIONE",
                                size: "sm",
                                okVariant: "outline-danger",
                                headerClass: "sa-msg-header-danger",
                                footerClass: "p-2 border-top-0",
                                centered: true,
                            })
                            .then((value) => {
                                this.boxTwo = value;
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                    });
            } else {
                let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.data.id;
                axios
                    .put(link, JSON.stringify(me.data))
                    .then(() => {
                        //me.linkback = me.linkback + "/view/" + response.data.data.id;
                        me.$router.replace(me.linkback).catch((err) => {
                            err;
                        });
                    })
                    .catch((error) => {
                        this.$bvModal
                            .msgBoxOk(error.response.data.messaggio, {
                                title: "ATTENZIONE",
                                size: "sm",
                                okVariant: "outline-danger",
                                headerClass: "sa-msg-header-danger",
                                footerClass: "p-2 border-top-0",
                                centered: true,
                            })
                            .then((value) => {
                                console.log(value);
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                    });
            }
        },
        loadData() {
            let me = this;
            me.opzioniMisure = [];
            let link = process.env.VUE_APP_PATH_API + "/hyperhealth/misure";
            axios
                .get(link)
                .then((response) => {
                    let elenco = Object.values(response.data.data);
                    elenco.forEach((element) => {
                        let opzioniConfigurabili = ["Weight (kg)", "Systolic Blood Pressure (mmHg)", "Diastolic Blood Pressure (mmHg)", "sleep_score", "steps"];
                        if (opzioniConfigurabili.includes(element)) {
                            me.opzioniMisure.push(element);
                        }
                    });
                    console.log(me.opzioniMisure);
                })
                .catch((err) => {
                    alert("Errore acquisizione elenco misurazioni \n" + err);
                });
        },
        onSubmit() {
            let me = this;
            let sessionStorage = window.sessionStorage;
            sessionStorage["filtro"] = JSON.stringify(me.filtro);
            me.loadData();
        },
        onReset() {
            let me = this;
            me.filtro = {};
            let sessionStorage = window.sessionStorage;
            sessionStorage["filtro"] = JSON.stringify(me.filtro.codiceSacca);
            me.loadData();
        },
        onAdd() {
            let me = this;
            me.$router.replace(me.pagelink + "/edit/-1?idPaziente=" + me.idPaziente).catch((err) => {
                err;
            });
        },
        onBack() {
            let me = this;
            window.location.replace("#" + me.linkback + "?idPaziente=" + me.idPaziente);
        },
    },
};
</script>
