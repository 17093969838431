<template>
  <sa-page-layout :btnAnnullaVisible="true" :linkback="linkback" :pathResource="pathResource" :data="jsonData" :btnSaveVisible="true">
    <template slot="toolbar-title">
      <paziente-anagrafica :linkPatient="linkPatient" :idPaziente="idPaziente"></paziente-anagrafica>
    </template>
    <template slot="toolbar-row">
      <paziente-dashboard-component :idPaziente="idPaziente"></paziente-dashboard-component>
    </template>
    <template slot="table-body">
      <div class="sa-form-section">
        <b-row style="margin-top: 2rem;">
          <b-form-file v-model="file1" :state="Boolean(file1)" placeholder="Trascina un file qui" @change="handleFile" ref="file-input" class="custom-input" type="file" accept=".pdf"></b-form-file>
          <div class="mt-3">File Selezionato: {{ file1 ? file1.name : "" }}</div>
        </b-row>
        <b-row>
          <b-col sm="12" md="12" lg="12" xl="12">
            <b-button class="float-sm-right btn-toolbar" variant="danger sa-margin-right" size="sm" @click="onDelete" v-b-modal.modal-center><b-icon icon="x-circle" />Cancella</b-button>
          </b-col>
        </b-row>
        <hr />
        <b-row>
          <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
            <strong>Tipo Documento</strong>
            <b-form-input v-model="jsonData.tipoDocumento" placeholder="Tipo Documento"></b-form-input>
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
            <strong>Data Caricamento</strong>
            <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="jsonData.dataCaricamento" type="datetime" placeholder="Data Caricamento"></date-picker>
          </b-col>
        </b-row>
        <hr />
        <b-row>
          <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
            <strong>Descrizione</strong>
            <b-form-textarea v-model="jsonData.descrizione" placeholder="Descrizione" no-resize></b-form-textarea>
          </b-col>
        </b-row>
      </div>
    </template>
  </sa-page-layout>
</template>
<script>
import SaPageLayout from "../../../../template/sa/components/layout/contents/SaPageLayout.vue";
import PazienteAnagrafica from "../../components/PazienteAnagrafica.vue";
import DatePicker from "vue2-datepicker";
import PazienteDashboardComponent from "../../components/PazienteDashboardComponent.vue";
export default {
  props: {
    utente: {
      type: Object,
      default: function() {
        return null;
      },
    },
  },
  components: { SaPageLayout, PazienteAnagrafica, DatePicker, PazienteDashboardComponent },
  computed: {
    idPaziente() {
      return this.$route.query.idPaziente;
    },
    linkPatient: {
      get: function() {
        let returnValue = "";
        if (this.utente.gruppo) {
          if (this.utente.gruppo.menuGruppo) {
            if (this.utente.gruppo.menuGruppo.length > 1) {
              return "/paziente/view/" + this.idPaziente;
            } else {
              if (this.utente.gruppo.menuGruppo.length == 1) {
                if (this.utente.gruppo.menuGruppo[0].primaryKey === "pazienti") {
                  return "/paziente/view/" + this.idPaziente;
                } else {
                  return "/paziente/homepaziente";
                }
              }
            }
          }
        }
        return returnValue;
      },
    },
  },
  mounted() {
    let me = this;
    me.id = this.$route.params.id;
    me.jsonData.idRisorsa = me.idPaziente;
    me.jsonData.idAnagrafica = me.idPaziente;
    me.linkback = "/paziente/allegati?idPaziente=" + me.idPaziente;
  },
  data() {
    return {
      pathResource: "/allegati",
      linkback: null,
      file1: null,
      jsonData: { file: null, idRisorsa: "", nomeFile: "", size: null, tipo: "", tipoDocumento: "", dataCaricamento: null, descrizione: "", idAnagrafica: "" },
    };
  },
  methods: {
    onDelete() {
      this.$refs["file-input"].reset();
    },
    handleFile(e) {
      let me = this;
      const selectedFile = e.target.files[0];
      console.log(e.target.files[0]);
      me.jsonData.nomeFile = e.target.files[0].name;
      me.jsonData.size = e.target.files[0].size;
      me.jsonData.tipo = e.target.files[0].type;
      this.createBase64(selectedFile);
    },
    createBase64(fileObject) {
      let me = this;
      const reader = new FileReader();
      reader.onload = (e) => {
        me.pdf = e.target.result;
        me.jsonData.file = me.pdf;
      };
      reader.readAsDataURL(fileObject);
    },
  },
};
</script>
