<template>
  <b-card>
    <div class="card-box">
      <div class="media mb-2">
        <div class="media-body">
          <h5 class="mt-0">
            <span class="text-dark"><i class=" fas fa-capsules"></i> {{ this.$i18n.t("patient.menu.therapy") }} </span>
          </h5>

          <ul class="list-unstyled mb-3">
            <li v-for="item in terapia" :key="item.id">
              <strong>{{ item.posologia }}</strong> <strong>{{ item.farmaco }}</strong> <span class="text-danger"> {{ item.frequenza }} </span>
              <span class="text-danger">{{ startTherapy() }} {{ formatDate(item.dataInizio) }}</span> <span class="text-danger">{{ item.tempo }}</span>
              <i class="blockquote-footer"> {{ item.nomeMedico }} {{ item.cognomeMedico }}</i>
            </li>
          </ul>
        </div>
      </div>
      <!--  media -->
    </div>
  </b-card>
</template>

<script>
import axios from "axios";
import moment from "moment";

export default {
  data() {
    return {
      terapia: [],
    };
  },
  mounted() {
    let me = this;
    me.id = this.$route.params.id;
    me.loadTerapie();
  },
  methods: {
    loadTerapie() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + "/pazientiterapia/attiva";
      axios
        .get(link, { params: { idPaziente: me.id } })
        .then((response) => {
          me.terapia = response.data.data;
        })
        .catch((e) => {
          console.log("Error: " + e.response.status + " " + link);
        });
    },
    formatDate(value) {
      if (value) {
        return moment(new Date(value)).format("DD-MM-YYYY");
      } else {
        return "---";
      }
    },
    startTherapy() {
      return this.$i18n.t("patient.lblStartTherapy");
    },
  },
};
</script>
