<template>
    <li class="nav-item">
        <router-link :to="to" :class="setActivate(to)" exact>
            <sa-icon :icon="icon" class="nav-icon" />
            <p>
                {{ this.$i18n.t(title) }}

                <span v-if="badge" :class="['right', 'badge', `badge-${badgeVariant}`]">{{ badge }}</span>
            </p>
        </router-link>
    </li>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            required: true,
        },
        to: {
            type: String,
            required: true,
        },
        icon: {
            type: [String, Array],
            default: "circle",
        },
        badge: {
            type: String,
            default: "",
        },
        badgeVariant: {
            type: String,
            default: "danger",
        },
    },
    methods: {
        setActivate(el) {
            let arrPath = this.$route.fullPath.match(/^\/.[^?/]+\/.[^?/]+/gm);
            let elPath = el.match(/^\/.[^?/]+\/.[^?/]+/gm);
            let path = arrPath[0];
            if (path === elPath[0]) {
                return "nav-link active";
            } else {
                return "nav-link";
            }
        },
    },
};
</script>
