<template>
  <div>
    <b-row>
      <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
        <b-row>
          <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
            <strong>{{ this.$i18n.t("patient.idoneity.lblTrofism") }}</strong>
            <br />
            {{ jsonData.trofismo }}
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
            <strong>{{ this.$i18n.t("patient.idoneity.lblBody") }}</strong>
            <br />
            {{ jsonData.corpo }}
          </b-col>
          <b-col cols="12" xs="12" sm="6" md="6" lg="4" xl="4">
            <strong>{{ this.$i18n.t("patient.idoneity.lblWaist") }}</strong>
            <br />
            {{ jsonData.vita }}
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <hr />
    <b-row>
      <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
        <b-row>
          <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
            <strong>{{ this.$i18n.t("patient.idoneity.lblWeight") }}</strong>
            <br />
            {{ jsonData.peso }}
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
            <strong>{{ this.$i18n.t("patient.idoneity.lblHeight") }}</strong>
            <br />
            {{ jsonData.altezza }}
          </b-col>
          <b-col cols="12" xs="12" sm="6" md="6" lg="4" xl="4">
            <strong>{{ this.$i18n.t("patient.idoneity.lblBMI") }}.</strong>
            <br />
            {{ jsonData.bmi }}
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <hr />
    <b-row>
      <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
        <b-row>
          <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
            <strong>{{ this.$i18n.t("patient.idoneity.lblBSA") }}</strong>
            <br />
            {{ jsonData.bsa }}
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
            <strong>{{ this.$i18n.t("patient.idoneity.lblGlycemia") }}</strong>
            <br />
            {{ jsonData.glicemia }}
          </b-col>
          <b-col cols="12" xs="12" sm="6" md="6" lg="4" xl="4">
            <strong>{{ this.$i18n.t("patient.idoneity.lblDiabetes") }}</strong>
            <br />
            {{ jsonData.diabete }}
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <hr />
    <b-row>
      <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
        <b-row>
          <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
            <strong>{{ this.$i18n.t("patient.idoneity.lblPAMax") }}</strong>
            <br />
            {{ jsonData.paMax }}
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
            <strong>{{ this.$i18n.t("patient.idoneity.lblPAMin") }}</strong>
            <br />
            {{ jsonData.paMin }}
          </b-col>
          <b-col cols="12" xs="12" sm="6" md="6" lg="4" xl="4">
            <strong>{{ this.$i18n.t("patient.idoneity.lblCholesterol") }}</strong>
            <br />
            {{ jsonData.colesterolo }}
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <hr />
    <b-row>
      <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
        <b-row>
          <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
            <strong>{{ this.$i18n.t("patient.idoneity.lblBloodGroup") }}</strong>
            <br />
            {{ jsonData.gruppoSanguigno }}
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
            <strong>{{ this.$i18n.t("patient.idoneity.lblRHFactor") }}</strong>
            <br />
            {{ jsonData.fattoreRh }}
          </b-col>
          <b-col cols="12" xs="12" sm="6" md="6" lg="4" xl="4">
            <strong>{{ this.$i18n.t("patient.idoneity.lblCirculatorySystem") }}</strong>
            <br />
            {{ jsonData.apparatoCircolatorio }}
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <hr />
    <b-row>
      <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
        <b-row>
          <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
            <strong>{{ this.$i18n.t("patient.idoneity.lblLocomotorSystem") }}</strong>
            <br />
            {{ jsonData.apparatoLocomotorio }}
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
            <strong>{{ this.$i18n.t("patient.idoneity.lblRespiratorySystem") }}</strong>
            <br />
            {{ jsonData.apparatoRespiratorio }}
          </b-col>
          <b-col cols="12" xs="12" sm="6" md="6" lg="4" xl="4">
            <strong>{{ this.$i18n.t("patient.idoneity.lblAbdomenGeneralOrgans") }}</strong>
            <br />
            {{ jsonData.addomeOrgGen }}
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <hr />
    <b-row>
      <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
        <b-row>
          <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
            <strong>{{ this.$i18n.t("patient.idoneity.lblLimbs") }}</strong
            ><br />
            {{ jsonData.arti }}
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
            <strong>{{ this.$i18n.t("patient.idoneity.lblNaturalSightRightEye") }}</strong
            ><br />
            {{ jsonData.vistaNOd }}
          </b-col>
          <b-col cols="12" xs="12" sm="6" md="6" lg="4" xl="4">
            <strong>{{ this.$i18n.t("patient.idoneity.lblNaturalSightLeftEye") }}</strong
            ><br />
            {{ jsonData.vistaNOs }}
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <hr />
    <b-row>
      <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
        <b-row>
          <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
            <strong>{{ this.$i18n.t("patient.idoneity.lblCurrentSightRightEye") }}</strong
            ><br />
            {{ jsonData.vistaCOd }}
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
            <strong>{{ this.$i18n.t("patient.idoneity.lblCurrentSightLeftEye") }}</strong
            ><br />
            {{ jsonData.vistaCOs }}
          </b-col>
          <b-col cols="12" xs="12" sm="6" md="6" lg="4" xl="4">
            <strong>{{ this.$i18n.t("patient.idoneity.lblCromaticSense") }}</strong
            ><br />
            {{ jsonData.sensoCromatico }}
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <hr />
    <b-row>
      <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
        <b-row>
          <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
            <strong>{{ this.$i18n.t("patient.idoneity.lblHearingSense") }}</strong
            ><br />
            {{ jsonData.udito }}
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
            <strong>{{ this.$i18n.t("patient.idoneity.lblExaminationConclusion") }}</strong
            ><br />
            {{ jsonData.conclusioniEsObiettivo }}
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <hr />
    <b-row>
      <b-col>
        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
          <strong>Stato</strong><br />
          {{ jsonData.firmaEo }}
        </b-col>
      </b-col>
    </b-row>
    <hr />
  </div>
</template>
<script>
export default {
  props: {
    jsonData: {
      Type: Object,
      require: true,
    },
  },
};
</script>
