<template>
    <sa-page-layout :btnEditVisible="true" :linkedit="linkedit" :btnBackVisible="true" :linkback="linkback" :pathResource="pathResource" :data="jsonData">
        <template slot="toolbar-title">
            <paziente-anagrafica :linkPatient="linkPatient" :idPaziente="idPaziente"></paziente-anagrafica>
        </template>
        <template slot="table-body">
            <paziente-sic-intolleranze-view-component :jsonData="jsonData"></paziente-sic-intolleranze-view-component>
        </template>
    </sa-page-layout>
</template>
<script>
import axios from "axios";
import SaPageLayout from "../../../../template/sa/components/layout/contents/SaPageLayout.vue";
import PazienteAnagrafica from "../../components/PazienteAnagrafica.vue";
import PazienteSicIntolleranzeViewComponent from "./PazienteSicIntolleranzeViewComponent.vue";
export default {
    components: { SaPageLayout, PazienteAnagrafica, PazienteSicIntolleranzeViewComponent },
    props: {
        utente: {
            type: Object,
            default: function() {
                return null;
            },
        },
    },
    computed: {
        idPaziente() {
            return this.$route.query.idPaziente;
        },
        linkPatient: {
            get: function() {
                let returnValue = "";
                if (this.utente.gruppo) {
                    if (this.utente.gruppo.menuGruppo) {
                        if (this.utente.gruppo.menuGruppo.length > 1) {
                            return "/paziente/view/" + this.idPaziente;
                        } else {
                            if (this.utente.gruppo.menuGruppo.length == 1) {
                                if (this.utente.gruppo.menuGruppo[0].primaryKey === "pazienti") {
                                    return "/paziente/view/" + this.idPaziente;
                                } else {
                                    return "/paziente/homepaziente";
                                }
                            }
                        }
                    }
                }
                return returnValue;
            },
        },
    },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        me.linkedit = "/paziente/sic/intolleranze/edit/" + me.id + "?idPaziente=" + me.idPaziente;
        me.linkback = "/paziente/sic/intolleranze" + "?idPaziente=" + me.idPaziente;
        me.loadData();
    },
    data() {
        return {
            linkedit: null,
            linkback: null,
            pathResource: "/intolleranze",
            jsonData: {},
        };
    },
    methods: {
        loadData() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.id;
            me.showModalLoading = true;
            axios
                .get(link)
                .then((response) => {
                    me.jsonData = response.data.data;
                })
                .catch(() => {
                    me.showModalLoading = false;
                });
        },
    },
};
</script>
