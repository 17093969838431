import Pazienti from "../";
import PazientiAdminSic from "../adminsic";

const routes = [
    { name: "PazientiList", path: "/pazienti", component: Pazienti.PazientiList, meta: { title: "pazienti.lblTitle" } },
    { name: "PazienteEdit", path: "/pazienti/edit/:id", component: Pazienti.PazienteEdit, meta: { title: "pazienti.lblTitle" } },
    { name: "PazientiListSic", path: "/pazientiadminsic", component: PazientiAdminSic.PazientiList, meta: { title: "pazienti.lblTitle" } },
    { name: "PazienteEditSic", path: "/pazientiadminsic/edit/:id", component: PazientiAdminSic.PazienteEdit, meta: { title: "pazienti.lblTitle" } },
];

export default routes;
