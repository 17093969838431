<template>
    <sa-page-list :titolo="titolo" :showmolalloading="showmolalloading">
        <template slot="toolbar">
            <b-button pill class="float-sm-right sa-btn-header sa-btn-icon-text" variant="light sa-margin-right" size="sm" @click="onAdd"><b-icon icon="plus" variant="secondary"></b-icon>Nuovo</b-button>
            <b-button pill class="float-sm-right sa-btn-header sa-btn-icon-text" variant="light sa-margin-right" size="sm" @click="onRefresh" v-b-modal.modal-center><b-icon icon="arrow-clockwise" variant="secondary" />Refresh</b-button>
        </template>
        <template slot="table-filter"> </template>
        <template slot="table-header">
            <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col cols="6">
                    <p>Totale Record: {{ rows }}</p>
                </b-col>
                <b-col cols="6">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
            </b-row>
        </template>
        <template slot="table-body">
            <div class="b-table-sticky-header">
                <b-table class="sa-b-table" ref="table" id="tblParams" :filter="filter" stacked="lg" striped hover :items="items" :fields="fields" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light">
                    <template #cell(nome)="{ item }">
                        <router-link class="sa-edit-link" :to="'/configparameter/edit/' + item.id">{{ item.nome }}</router-link>
                    </template>
                </b-table>
            </div>
        </template>
        <template slot="table-footer">
            <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col cols="6">
                    <p>Totale Record: {{ rows }}</p>
                </b-col>
                <b-col cols="6">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
            </b-row>
        </template>
    </sa-page-list>
</template>
<script>
import axios from "axios";
import { SaPageList } from "../../../template/sa/components/layout/contents";
export default {
    components: { SaPageList },
    computed: {
        rows() {
            return this.items.length;
        },
    },
    data() {
        return {
            titolo: "Parametri",
            showmolalloading: false,
            currentPage: 1,
            perPage: 30,
            filter: "",
            isBusy: false,
            pathRest: "/configparameter",
            fields: [
                {
                    label: "Nome",
                    key: "nome",
                    sortable: true,
                    thStyle: "width: 12rem",
                },
                {
                    label: "Parametro",
                    key: "parametro",
                    sortable: true,
                },
                {
                    label: "Note",
                    key: "note",
                    sortable: true,
                },
                {
                    label: "Tipo",
                    key: "tipo",
                    sortable: true,
                    thStyle: "width: 6rem",
                },
                { class: "sa-table-column-action-single", key: "actions", label: "", thStyle: "width: 8rem" },
            ],
            items: [],
            data: [],
            filtro: {},
        };
    },
    created: function() {},
    mounted: function() {
        this.loadData();
    },
    update: function() {
        console.log("Update");
    },
    methods: {
        loadData() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathRest;
            me.isBusy = true;
            me.showmolalloading = true;
            axios
                .get(link, { params: me.filtro })
                .then((response) => {
                    me.items = [];
                    me.items = response.data.data;
                    me.showmolalloading = false;
                })
                .catch(function(error) {
                    me.showmolalloading = false;
                    me.$bvToast.toast(error.response.data.messaggio, {
                        title: "Sorveglianza Covid Admin",
                        variant: "danger",
                        solid: true,
                    });
                });
        },
        onRefresh() {
            let me = this;
            me.loadData();
        },
        onAdd() {
            let me = this;
            me.$router.replace(me.pathRest + "/edit/-1").catch((err) => {
                err;
            });
        },
        onSubmit: function() {
            let me = this;
            me.loadData();
            return false;
        },
    },
};
</script>
