import LogsList from "./components/LogsList.vue";
import ModuliList from "./components/ModuliList.vue";
import ModuliEdit from "./components/ModuliEdit.vue";
import ParamsList from "./components/ParamsList.vue";
import ParamsEdit from "./components/ParamsEdit.vue";
import PatientAccessList from "./components/PatientAccessList.vue";
import PatientAccessEdit from "./components/PatientAccessEdit.vue";

export default {
    LogsList,
    ParamsList,
    ParamsEdit,
    ModuliList,
    ModuliEdit,
    PatientAccessList,
    PatientAccessEdit,
};
