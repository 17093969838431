<template>
    <sa-page-view-edit ref="pgaView" :data="data" :pathResource="pathResource" :backVisible="true" :saveVisible="true" :titolo="data.titolo" :linkback="linkback" :linkedit="linkedit" :showmolalloading="showmolalloading">
        <template slot="body">

            <b-row>
                <b-col cols="12" lg="2" md="2" class="sa-text-right"><b>{{ this.$i18n.t("pazienti.lblPhysiologicalHistory")}}</b></b-col>
            </b-row>

            <b-row>
                <b-col cols="12" lg="2" md="2" class="sa-text-right">{{ this.$i18n.t("pazienti.lblOrigin") }}</b-col>
                <b-col cols="12" lg="10" md="10" class="sa-text-left">
                    <b-form-input v-model="data.provenienza" size="sm" placeholder="Provenienza" required></b-form-input>
                </b-col>
            </b-row>

            <b-row>
                <b-col cols="12" lg="2" md="2" class="sa-text-right">{{ this.$i18n.t("pazienti.lblBirthChildbirth") }}:</b-col>
                <b-col cols="12" lg="2" md="2" class="sa-text-left">
                    <b-form-input v-model="data.nascitaparto" size="sm" placeholder="Nascita Parto" required></b-form-input>
                </b-col>

                <b-col cols="12" lg="2" md="2" class="sa-text-right">{{ this.$i18n.t("pazienti.lblWeight") }}:</b-col>
                <b-col cols="12" lg="2" md="2" class="sa-text-left">
                    <b-form-input v-model="data.peso" size="sm" placeholder="Peso" required></b-form-input>
                </b-col>

                <b-col cols="12" lg="2" md="2" class="sa-text-right">{{ this.$i18n.t("pazienti.lblGrowth") }}:</b-col>
                <b-col cols="12" lg="2" md="2" class="sa-text-left">
                    <b-form-input v-model="data.cresita" size="sm" placeholder="Crescita" required></b-form-input>
                </b-col>
            </b-row>

            <b-row>
                <b-col cols="12" lg="2" md="2" class="sa-text-right">{{ this.$i18n.t("pazienti.lblPuberty") }}:</b-col>
                <b-col cols="12" lg="2" md="2" class="sa-text-left">
                    <b-form-input v-model="data.puberta" size="sm" placeholder="Pubertà" required></b-form-input>
                </b-col>

                <b-col cols="12" lg="2" md="2" class="sa-text-right">{{ this.$i18n.t("pazienti.lblChildren") }}:</b-col>
                <b-col cols="12" lg="2" md="2" class="sa-text-left">
                    <b-form-input v-model="data.figli" size="sm" placeholder="Figli" required></b-form-input>
                </b-col>

                <b-col cols="12" lg="2" md="2" class="sa-text-right">{{ this.$i18n.t("pazienti.lblMenarche") }}:</b-col>
                <b-col cols="12" lg="2" md="2" class="sa-text-left">
                    <b-form-input v-model="data.menarca" size="sm" placeholder="Menarca" required></b-form-input>
                </b-col>
            </b-row>

            <b-row>
                <b-col cols="12" lg="2" md="2" class="sa-text-right">{{ this.$i18n.t("pazienti.lblMenopause") }}:</b-col>
                <b-col cols="12" lg="2" md="2" class="sa-text-left">
                    <b-form-input v-model="data.menopausa" size="sm" placeholder="Menopausa" required></b-form-input>
                </b-col>

                <b-col cols="12" lg="2" md="2" class="sa-text-right">{{ this.$i18n.t("pazienti.lblPregnancy") }}</b-col>
                <b-col cols="12" lg="2" md="2" class="sa-text-left">
                    <b-form-input v-model="data.gravidanze" size="sm" placeholder="Gravidanze" required></b-form-input>
                </b-col>

                <b-col cols="12" lg="2" md="2" class="sa-text-right">{{ this.$i18n.t("pazienti.lblAbortions") }}:</b-col>
                <b-col cols="12" lg="2" md="2" class="sa-text-left">
                    <b-form-input v-model="data.aborti" size="sm" placeholder="Aborti" required></b-form-input>
                </b-col>
            </b-row>

            <b-row>
                <b-col cols="12" lg="2" md="2" class="sa-text-right">{{ this.$i18n.t("pazienti.lblMacrosomalBirths") }}:</b-col>
                <b-col cols="12" lg="10" md="10" class="sa-text-left">
                    <b-form-input v-model="data.partimacrosomici" size="sm" placeholder="Parti Macrosomici" required></b-form-input>
                </b-col>
            </b-row>

            <b-row>
                <b-col cols="12" lg="2" md="2" class="sa-text-right">{{ this.$i18n.t("pazienti.lblDieteticCheck") }}:</b-col>
                <b-dropdown text="NON RILEVATO" block variant="secondary" class="m-2">
                    <b-dropdown-item >{{ this.$i18n.t("pazienti.lblNotDetected") }}</b-dropdown-item>
                    <b-dropdown-item >{{ this.$i18n.t("pazienti.lblGood") }}</b-dropdown-item>
                    <b-dropdown-item >{{ this.$i18n.t("pazienti.lblMediocre") }}</b-dropdown-item>
                    <b-dropdown-item >{{ this.$i18n.t("pazienti.lblBad") }}</b-dropdown-item>
                    <b-dropdown-item >{{ this.$i18n.t("pazienti.lblTerrible") }}</b-dropdown-item>
                </b-dropdown>

            </b-row>

            <b-row>
                <b-col cols="6" lg="2" md="1" class="sa-text-right">{{ this.$i18n.t("pazienti.lblSmoke") }}</b-col>
                <input type="checkbox" id="fumo" name="fumo" value="fumo">

                <b-col cols="12" lg="2" md="2" class="sa-text-right">{{ this.$i18n.t("pazienti.lblSmokeQuantity") }}:</b-col>
                <b-dropdown text="SELEZIONA" block variant="secondary" class="m-2">
                    <b-dropdown-item >0-10/gg</b-dropdown-item>
                    <b-dropdown-item >11-20/gg</b-dropdown-item>
                    <b-dropdown-item >21-40/gg</b-dropdown-item>
                    <b-dropdown-item >> 40/gg</b-dropdown-item>
                     </b-dropdown>
            </b-row>


            <b-row>
                <b-col cols="6" lg="2" md="1" class="sa-text-right">{{ this.$i18n.t("pazienti.lblCoffe") }}</b-col>
                <input type="checkbox" id="caffe" name="caffe" value="caffe">

                <b-col cols="12" lg="2" md="2" class="sa-text-right">{{ this.$i18n.t("pazienti.lblCoffeQuantity") }}:</b-col>
                <b-dropdown text="SELEZIONA" block variant="secondary" class="m-2">
                    <b-dropdown-item >1</b-dropdown-item>
                    <b-dropdown-item >2</b-dropdown-item>
                    <b-dropdown-item >3</b-dropdown-item>
                    <b-dropdown-item >> 3</b-dropdown-item>
                     </b-dropdown>
            </b-row>

            <b-row>
                <b-col cols="12" lg="2" md="2" class="sa-text-right">{{ this.$i18n.t("pazienti.lblMore") }}:</b-col>
                <b-col cols="12" lg="10" md="10" class="sa-text-left">
                    <b-form-input v-model="data.provenienza" size="sm" placeholder="altro" required></b-form-input>
                </b-col>
            </b-row>


            <b-row>
                <b-col cols="12" lg="2" md="2" class="sa-text-right">{{ this.$i18n.t("pazienti.lblThirst") }}</b-col>
            </b-row>

            <b-row>
                <b-col cols="6" lg="2" md="1" class="sa-text-right">{{ this.$i18n.t("pazienti.lblNormal") }}</b-col>
                <input type="checkbox" id="normale" name="normale" value="normale">

                <b-col cols="6" lg="2" md="1" class="sa-text-right">{{ this.$i18n.t("pazienti.lblIncreased") }}</b-col>
                <input type="checkbox" id="aumentata" name="aumentata" value="aumentata">

                <b-col cols="6" lg="2" md="1" class="sa-text-right">{{ this.$i18n.t("pazienti.lblExcessive") }}</b-col>
                <input type="checkbox" id="eccessiva" name="eccessiva" value="eccessiva">

            </b-row>

            <b-row>
                <b-col cols="12" lg="2" md="2" class="sa-text-right">{{ this.$i18n.t("pazienti.lblDiuresis") }}</b-col>
            </b-row>

            <b-row>
                <b-col cols="6" lg="2" md="1" class="sa-text-right">{{ this.$i18n.t("pazienti.lblNormal") }}</b-col>
                <input type="checkbox" id="normale" name="normale" value="normale">

                <b-col cols="6" lg="2" md="1" class="sa-text-right">{{ this.$i18n.t("pazienti.lblPolyuria") }}</b-col>
                <input type="checkbox" id="poliura" name="poliura" value="poliura">

                <b-col cols="6" lg="2" md="1" class="sa-text-right">{{ this.$i18n.t("pazienti.lblOliguria") }}</b-col>
                <input type="checkbox" id="oliguria" name="oliguria" value="oliguria">

                <b-col cols="6" lg="2" md="1" class="sa-text-right">{{ this.$i18n.t("pazienti.lblNocturia") }}</b-col>
                <input type="checkbox" id="nicturia" name="nicturia" value="nicturia">
            </b-row>

            <b-row>
                <b-col cols="12" lg="2" md="2" class="sa-text-right">{{ this.$i18n.t("pazienti.lblHunger") }}</b-col>
            </b-row>

            <b-row>
                <b-col cols="6" lg="2" md="1" class="sa-text-right">{{ this.$i18n.t("pazienti.lblNormal") }}</b-col>
                <input type="checkbox" id="normale" name="normale" value="normale">

                <b-col cols="6" lg="2" md="1" class="sa-text-right">{{ this.$i18n.t("pazienti.lblIncreased") }}</b-col>
                <input type="checkbox" id="aumentata" name="aumentata" value="aumentata">
            </b-row>

            <b-row>
                <b-col cols="12" lg="2" md="2" class="sa-text-right">{{ this.$i18n.t("pazienti.lblIntestinalFunctions") }}</b-col>
            </b-row>

            <b-row>
                <b-col cols="6" lg="2" md="1" class="sa-text-right">{{ this.$i18n.t("pazienti.lblRegular") }}</b-col>
                <input type="checkbox" id="regolari" name="regolari" value="regolari">

                <b-col cols="6" lg="2" md="1" class="sa-text-right">{{ this.$i18n.t("pazienti.lblConstipation") }}</b-col>
                <input type="checkbox" id="stipsi" name="stipsi" value="stipsi">

                <b-col cols="6" lg="2" md="1" class="sa-text-right">{{ this.$i18n.t("pazienti.lblDiarrhea") }}</b-col>
                <input type="checkbox" id="diarrea" name="diarrea" value="diarrea">
            </b-row>

            <b-row>
                <b-col cols="12" lg="2" md="2" class="sa-text-right">{{ this.$i18n.t("pazienti.lblFatigue") }}:</b-col>
                <b-dropdown text="SELEZIONA" block variant="secondary" class="m-2">
                    <b-dropdown-item ></b-dropdown-item>
                    <b-dropdown-item >{{ this.$i18n.t("pazienti.lblYES") }}</b-dropdown-item>
                    <b-dropdown-item >{{ this.$i18n.t("pazienti.lblNO") }}</b-dropdown-item>
                </b-dropdown>
            </b-row>

            <b-row>
                <b-col cols="12" lg="2" md="2" class="sa-text-right">{{ this.$i18n.t("pazienti.lblSexualActivity") }}</b-col>
            </b-row>

            <b-row>
                <b-col cols="6" lg="2" md="1" class="sa-text-right">{{ this.$i18n.t("pazienti.lblNormal") }}</b-col>
                <input type="checkbox" id="normale" name="normale" value="normale">

                <b-col cols="6" lg="2" md="1" class="sa-text-right">{{ this.$i18n.t("pazienti.lblIncreased") }}</b-col>
                <input type="checkbox" id="aumentata" name="aumentata" value="aumentata">

                <b-col cols="6" lg="2" md="1" class="sa-text-right">{{ this.$i18n.t("pazienti.lblAbsent") }}</b-col>
                <input type="checkbox" id="assente" name="assente" value="assente">
            </b-row>

            <b-row>

                <b-col cols="12" lg="2" md="2" class="sa-text-right">{{ this.$i18n.t("pazienti.lblProgressiveEvent") }}:</b-col>
                <b-col cols="6" lg="4" md="4" class="sa-text-left">
                    <b-form-input v-model="data.progressivoEvento" size="sm" placeholder="Progressivo Evento" required></b-form-input>
                </b-col>
                <b-col cols="12" lg="2" md="2" class="sa-text-right">{{ this.$i18n.t("pazienti.lblDateEvent") }}:</b-col>
                <b-col cols="12" lg="4" md="4" class="sa-text-right">
                    <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="data.dataNascita" type="date" placeholder="Data Nascita"></date-picker>
                </b-col>
            </b-row>

        </template>
    </sa-page-view-edit>
</template>
<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import { SaPageViewEdit } from "../../../template/sa/components/layout/contents";
import moment from "moment";
import axios from "axios";
export default {
    components: { SaPageViewEdit, DatePicker },
    //components: { SaPageViewEdit},
    data() {
        return {
            pathRest: "/eventidiabetologiaanamnesifisiologica",
            pathResource: "/eventidiabetologiaanamnesifisiologica",
            //linkback: "/pazientidiabetologia",
            linkback: "/paziente",
            linkedit: null,
            showmolalloading: false,
            id: "-1",
            data: {},
        };
    },
    computed: {
        dataAavvioData: {
            get: function() {
                return new Date(this.data.avvioData);
            },
            set: function(value) {
                this.data.avvioData = moment(String(value)).valueOf();
            },
        },
    },
    mounted: function() {
        let me = this;
        me.id = this.$route.params.id;
        if (me.id !== "-1") {
            me.linkback = me.linkback + "/view/" + me.id;
        }
        me.loadData();
    },
    methods: {
        formatDate(value) {
            if (value) {
                return moment(new Date(value)).format("DD-MM-YYYY HH:mm:ss");
            } else {
                return "---";
            }
        },
        loadData() {
            let me = this;
            me.showmolalloading = false;
            //:if (me.id === "-1") {
            //    me.linkback = "/pazientidiabetologia";
            //    me.showmolalloading = false;
            //} else {
            //    me.linkback = "/pazientidiabetologia/view/" + me.id;
            //    me.showmolalloading = true;
            //    let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
            //    axios.get(link + me.id).then((response) => {
            //        me.data = response.data.data;
            //        me.showmolalloading = false;
            //    });
            //}
        },
        onSave() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource;
            let jsonData = JSON.stringify(me.data);

            me.showmolalloading = true;
            if (me.id == "-1") {
                axios
                    .post(link, jsonData)
                    .then((response) => {
                        me.data = response.data.data;
                        me.showmolalloading = false;
                        me.$router.replace(me.routeLink).catch((err) => {
                            err;
                        });
                    })
                    .catch((error) => {
                        me.showmolalloading = false;
                        this.$bvModal
                            .msgBoxOk(error.response.data.messaggio, {
                                title: "ATTENZIONE",
                                size: "sm",
                                okVariant: "outline-danger",
                                headerClass: "sa-msg-header-danger",
                                footerClass: "p-2 border-top-0",
                                centered: true,
                            })
                            .then((value) => {
                                this.boxTwo = value;
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                    });
            } else {
                axios
                    .put(link + "/" + me.id, jsonData)
                    .then((response) => {
                        me.gruppo = response.data.data;
                        me.$refs["sa-modal-loading"].close();
                        me.$router.replace(me.routeLink).catch((err) => {
                            err;
                        });
                    })
                    .catch((error) => {
                        me.$refs["sa-modal-loading"].close();
                        this.$bvModal
                            .msgBoxOk(error.response.data.messaggio, {
                                title: "ATTENZIONE",
                                size: "sm",
                                okVariant: "outline-danger",
                                headerClass: "sa-msg-header-danger",
                                footerClass: "p-2 border-top-0",
                                centered: true,
                            })
                            .then((value) => {
                                this.boxTwo = value;
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                    });
            }
        },
        onBack() {
            let me = this;
            me.$router.replace(me.routeLink).catch((err) => {
                err;
            });
        },
    },
};
</script>