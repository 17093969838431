<template>
    <sa-page-view-edit ref="pgaView" :data="item" :backVisible="true" :editVisible="true" :linkback="pagelink" :linkedit="linkedit">
        <template slot="body">
            <div class="bg-picture card-box">
                <div class="profile-info-name">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col">
                                <h5 class="m-0">{{ this.$i18n.t("clinic.lblClinicRegistry") }}</h5>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div class="container-fluid sa-view-form">
                        <b-row>
                            <b-col xs="12" sm="6" md="6" lg="4" xl="3">
                                <div class="sa-view-form-data">
                                    <strong>{{ this.$i18n.t("clinic.lblStructure") }}</strong>
                                    <br />
                                    <span class="sa-form-label">{{ item.denominazione }}</span>
                                </div>
                            </b-col>
                            <b-col xs="12" sm="6" md="6" lg="4" xl="3">
                                <div class="sa-view-form-data">
                                    <strong>{{ this.$i18n.t("clinic.lblBranca") }}</strong>
                                    <br />
                                    <span class="sa-form-label">{{ item.branca }}</span>
                                </div>
                            </b-col>
                            <b-col xs="12" sm="6" md="6" lg="4" xl="3">
                                <div class="sa-view-form-data">
                                    <strong>{{ this.$i18n.t("clinic.lblSpecializations") }}</strong>
                                    <br />
                                    <span class="sa-form-label">{{ item.specializzazione }}</span>
                                </div>
                            </b-col>
                            <b-col xs="12" sm="6" md="6" lg="4" xl="3">
                                <div class="sa-view-form-data">
                                    <strong>{{ this.$i18n.t("clinic.lblName") }}</strong>
                                    <br />
                                    <span class="sa-form-label">{{ item.nome }}</span>
                                </div>
                            </b-col>
                            <b-col xs="12" sm="6" md="6" lg="4" xl="3">
                                <div class="sa-view-form-data">
                                    <strong>{{ this.$i18n.t("clinic.lblClinicManager") }}</strong>
                                    <br />
                                    <span class="sa-form-label">{{ item.responsabile }}</span>
                                </div>
                            </b-col>
                            <b-col xs="12" sm="6" md="6" lg="4" xl="3">
                                <div class="sa-view-form-data">
                                    <strong>{{ this.$i18n.t("clinic.lblAddress") }} </strong>
                                    <br />
                                    <span class="sa-form-label">{{ item.indirizzo }}</span>
                                </div>
                            </b-col>
                            <b-col xs="12" sm="6" md="6" lg="4" xl="3">
                                <div class="sa-view-form-data">
                                    <strong> {{ this.$i18n.t("clinic.lblStreetNumber") }} </strong>
                                    <br />
                                    <span class="sa-form-label">{{ item.numeroCivico }}</span>
                                </div>
                            </b-col>
                            <b-col xs="12" sm="6" md="6" lg="4" xl="3">
                                <div class="sa-view-form-data">
                                    <strong> {{ this.$i18n.t("clinic.lblProvince") }} </strong>
                                    <br />
                                    <span class="sa-form-label">{{ item.provincia }}</span>
                                </div>
                            </b-col>
                            <b-col xs="12" sm="6" md="6" lg="4" xl="3">
                                <div class="sa-view-form-data">
                                    <strong> {{ this.$i18n.t("clinic.lblCity") }} </strong>
                                    <br />
                                    <span class="sa-form-label">{{ item.comune }}</span>
                                </div>
                            </b-col>
                            <b-col xs="12" sm="6" md="6" lg="4" xl="3">
                                <div class="sa-view-form-data">
                                    <strong>{{ this.$i18n.t("clinic.lblPostalCode") }}</strong>
                                    <br />
                                    <span class="sa-form-label">{{ item.cap }}</span>
                                </div>
                            </b-col>
                            <b-col xs="12" sm="6" md="6" lg="4" xl="3">
                                <div class="sa-view-form-data">
                                    <strong> {{ this.$i18n.t("clinic.lblAsl") }} </strong>
                                    <br />
                                    <span class="sa-form-label">{{ item.asl }}</span>
                                </div>
                            </b-col>
                            <b-col xs="12" sm="6" md="6" lg="4" xl="3">
                                <div class="sa-view-form-data">
                                    <strong> {{ this.$i18n.t("clinic.lblAslCode") }} </strong>
                                    <br />
                                    <span class="sa-form-label">{{ item.codiceAsl }}</span>
                                </div>
                            </b-col>
                            <b-col xs="12" sm="6" md="6" lg="4" xl="3">
                                <div class="sa-view-form-data">
                                    <strong> {{ this.$i18n.t("clinic.lblTelephoneNumber") }} </strong>
                                    <br />
                                    <span class="sa-form-label">{{ item.telefono }}</span>
                                </div>
                            </b-col>
                            <b-col xs="12" sm="6" md="6" lg="4" xl="3">
                                <div class="sa-view-form-data">
                                    <strong> {{ this.$i18n.t("clinic.lblEmail") }} </strong>
                                    <br />
                                    <span class="sa-form-label">{{ item.email }}</span>
                                </div>
                            </b-col>
                        </b-row>
                    </div>
                </div>
            </div>
        </template>
    </sa-page-view-edit>
</template>
<script>
import axios from "axios";
//import moment from "moment";
import { SaPageViewEdit } from "../../../template/sa/components/layout/contents";
export default {
    components: { SaPageViewEdit },
    computed: {
        rows() {
            return this.items.length;
        },
    },
    data() {
        return {
            perPage: 10,
            currentPage: 1,
            filter: null,
            pagelink: "/ambulatori",
            pathRest: "/ambulatori",
            linkedit: null,
            pathRESTRequest: "/ambulatorio",
            filtro: {},
            filterOn: [],
            fields: [],
            items: [],
            item: {},
        };
    },
    created: function() {},
    mounted: function() {
        let me = this;
        me.id = this.$route.params.id;
        me.linkedit = "/ambulatorio/edit/" + me.id;
        me.loadData();
    },
    methods: {
        loadData() {
            let me = this;
            me.showmolalloading = true;
            let link = process.env.VUE_APP_PATH_API + me.pathRest + "/";
            axios.get(link + me.id).then((response) => {
                me.item = response.data.data;
                me.showmolalloading = false;
            });
        },
        onAdd() {
            let me = this;
            me.$router.replace(me.pathRESTRequest + "/edit/-1").catch((err) => {
                err;
            });
        },
        onRefresh() {
            let me = this;
            me.loadData();
        },
    },
};
</script>
