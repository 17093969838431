<template>
  <div>
    <b-row>
      <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
        <b-row>
          <b-col cols="12" xs="14" sm="4" md="4" lg="4" xl="4">
            <strong>{{ this.$i18n.t("patient.afa.lblFinalJudgement") }}</strong>
            <br />
            {{ jsonData.tipoCertificato }}
          </b-col>
          <b-col cols="12" xs="4" sm="4" md="4" lg="4" xl="4">
            <strong>{{ this.$i18n.t("patient.afa.lblFinalJudgement") }}</strong>
            <br />
            {{ jsonData.sportPraticato }}
          </b-col>
          <b-col cols="12" xs="4" sm="4" md="4" lg="4" xl="4">
            <strong>{{ this.$i18n.t("patient.afa.lblFinalJudgement") }}</strong>
            <br />
            {{ jsonData.giudizioIdoneita }}
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
            <strong>{{ this.$i18n.t("patient.afa.lblFinalJudgement") }}</strong>
            <br />
            {{ jsonData.giudizioFinale }}
          </b-col>
          <b-col cols="12" xs="14" sm="4" md="4" lg="4" xl="4">
            <strong>{{ this.$i18n.t("patient.afa.lblFinalJudgement") }}</strong>
            <br />
            {{ jsonData.luogo }}
          </b-col>
          <b-col cols="12" xs="4" sm="4" md="4" lg="4" xl="4">
            <strong>{{ this.$i18n.t("patient.afa.lblFinalJudgement") }}</strong>
            <br />
            {{ jsonData.periodoIdoneita }}
          </b-col>
          <b-col cols="12" xs="4" sm="4" md="4" lg="4" xl="4">
            <strong>{{ this.$i18n.t("patient.afa.lblFinalJudgement") }}</strong>
            <br />
            {{ jsonData.dataScadenza }}
          </b-col>
          <b-col cols="12" xs="4" sm="4" md="4" lg="4" xl="4">
            <strong>{{ this.$i18n.t("patient.afa.lblFinalJudgement") }}</strong>
            <br />
            {{ jsonData.numeroCertificato }}
          </b-col>
        </b-row>
        <hr />
        <b-row>
          <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
            <span class="sa-form-label">Stato</span><br />
            <span class="sa-form-label">Esame Obiettivo: </span> {{ jsonData.firmaEo }} <br />
            <span class="sa-form-label">Elettrocardiogramma: </span> {{ jsonData.firmaEcg }} <br />
            <span class="sa-form-label">Giudizio Finale: </span>{{ jsonData.firmaGf }}
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>
<script>
export default {
  props: {
    jsonData: {
      Type: Object,
      require: true,
    },
  },
};
</script>
