<template>
  <sa-page-layout :showModalLoading="showModalLoading" :btnSaveVisible="true" :btnAnnullaVisible="true" :btnBackVisible="false" :linkback="linkback" :pathResource="pathResource" :data="jsonData">
    <template slot="toolbar-title">
      <paziente-anagrafica :linkPatient="linkPatient" :idPaziente="idPaziente"></paziente-anagrafica>
    </template>
    <template slot="table-header">
      <h5 class="sa-text-left">Dati</h5>
    </template>
    <template slot="table-body">
      <div class="sa-tab-scroll">
        <!-- InizioBody  -->
        <b-row>
          <b-col xs="12" sm="12" md="12" lg="12" xl="12"
            ><h5>{{ getTranslate("patient.cardiology.lblFamilyAnamnesys") }}</h5></b-col
          >
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblHypertensiveFather") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.padreIperteso" switch :checked="jsonData.padreIperteso"></b-form-checkbox
          ></b-col>

          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblHypertensiveMother") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.madreIpertesa" switch :checked="jsonData.madreIpertesa"></b-form-checkbox
          ></b-col>

          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblHypertensiveSibilings") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.fratelliIpertesi" switch :checked="jsonData.fratelliIpertesi"></b-form-checkbox
          ></b-col>

          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblDiabeticRelatives") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.parentiDiabetici" switch :checked="jsonData.parentiDiabetici"></b-form-checkbox
          ></b-col>

          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblNephropathicRelatives") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.parentiNefropatici" switch :checked="jsonData.parentiNefropatici"></b-form-checkbox
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblDyslipidemiaRelatives") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.parentiDislipidemia" switch :checked="jsonData.parentiDislipidemia"></b-form-checkbox
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblDyslipidemiaRelatives") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.parentiDislipidemia" switch :checked="jsonData.parentiDislipidemia"></b-form-checkbox
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblIschemicRelatives") }}</span
            ><br />
            <b-form-select v-model="jsonData.parentiDislipidemia" :options="opzioniMalattia"></b-form-select
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblCerebrovascularAccidentsRelatives") }}</span
            ><br />
            <b-form-select v-model="jsonData.parentiAccidentiCerebrovascolari" :options="opzioniMalattia"></b-form-select
          ></b-col>
          <b-col xs="12" sm="12" md="12" lg="12" xl="12"
            ><hr />
            <h5>{{ getTranslate("patient.cardiology.lblPersonalAnamnesys") }}</h5></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblHypertensiveYears") }}</span
            ><br />
            <b-form-input v-model="jsonData.anniIperteso" type="text"></b-form-input
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblDiabeticYears") }}</span
            ><br />
            <b-form-input v-model="jsonData.anniDiabetico" type="text"></b-form-input
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblDyslipidemia") }}</span
            ><br />
            <b-form-select v-model="jsonData.dislipidemia" :options="opzioniDislipidemia"></b-form-select
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblPaBeforeTherapy") }}</span
            ><br />
            <b-row
              ><b-col xs="12" sm="12" md="6" lg="6" xl="6"><b-form-input v-model="jsonData.paMin" type="number" placeholder="min"></b-form-input></b-col
              ><b-col xs="12" sm="12" md="6" lg="6" xl="6"><b-form-input v-model="jsonData.paMax" type="number" placeholder="max"></b-form-input></b-col></b-row
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblOnTherapy") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.terapiaUltimoAnno" switch :checked="jsonData.terapiaUltimoAnno"></b-form-checkbox
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblCarbohydrateIntolerance") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.intolleranzaCarboidrati" switch :checked="jsonData.intolleranzaCarboidrati"></b-form-checkbox
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblDiabetesMellitus") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.diabeteMellito" switch :checked="jsonData.diabeteMellito"></b-form-checkbox
          ></b-col>
          <b-col xs="0" sm="8" md="8" lg="8" xl="8"></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblAlcoholConsumption") }}</span
            ><br />
            <b-form-select v-model="jsonData.consumoAlcohol" :options="opzioniAlcohol"></b-form-select
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblQualification") }}</span
            ><br />
            <b-form-select v-model="jsonData.titoloStudio" :options="opzioniStudio"></b-form-select
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblWorkActivity") }}</span
            ><br />
            <b-form-select v-model="jsonData.attivitaLavoro" :options="opzioniLavoro"></b-form-select
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblIrrascibilityWork") }}</span
            ><br />
            <b-form-select v-model="jsonData.irracibilitaLavoro" :options="opzioniSiNo"></b-form-select
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblPersonalMatters") }}</span
            ><br />
            <b-form-select v-model="jsonData.irracibilitaMotiviPersonali" :options="opzioniSiNo"></b-form-select
          ></b-col>
          <b-col xs="0" sm="4" md="4" lg="4" xl="4"></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblPastIMA") }}</span
            ><br />
            <b-row
              ><b-col xs="12" sm="12" md="6" lg="6" xl="6"><b-form-checkbox v-model="jsonData.imaPregressa" switch :checked="jsonData.imaPregressa"></b-form-checkbox></b-col
              ><b-col xs="12" sm="12" md="6" lg="6" xl="6"><b-form-select v-model="jsonData.imaSede" :options="opzioniSede1" :disabled="!jsonData.imaPregressa"></b-form-select></b-col></b-row
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblPastPTCA") }}</span
            ><br />
            <b-row
              ><b-col xs="12" sm="12" md="6" lg="6" xl="6"><b-form-checkbox v-model="jsonData.ptcaPregressa" switch :checked="jsonData.ptcaPregressa"></b-form-checkbox></b-col
              ><b-col xs="12" sm="12" md="6" lg="6" xl="6"><b-form-select v-model="jsonData.ptcaSede" :options="opzioniSede2" :disabled="!jsonData.ptcaPregressa"></b-form-select></b-col></b-row
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblPastCABG") }}</span
            ><br />
            <b-row
              ><b-col xs="12" sm="12" md="6" lg="6" xl="6"><b-form-checkbox v-model="jsonData.cabgPregressa" switch :checked="jsonData.cabgPregressa"></b-form-checkbox></b-col
              ><b-col xs="12" sm="12" md="6" lg="6" xl="6"><b-form-select v-model="jsonData.cabgSede" :options="opzioniSede1" :disabled="!jsonData.cabgPregressa"></b-form-select></b-col></b-row
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblValvulopathy") }}</span
            ><br />
            <b-row
              ><b-col xs="12" sm="12" md="6" lg="6" xl="6"><b-form-checkbox v-model="jsonData.valvulopatia" switch :checked="jsonData.valvulopatia"></b-form-checkbox></b-col
              ><b-col xs="12" sm="12" md="6" lg="6" xl="6"><b-form-input v-model="jsonData.valvulopatiaSede" type="text" :disabled="!jsonData.valvulopatia"></b-form-input></b-col></b-row
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblPastCrerebralIschemia") }}</span
            ><br />
            <b-row
              ><b-col xs="12" sm="12" md="6" lg="6" xl="6"><b-form-checkbox v-model="jsonData.ischemiaPregressa" switch :checked="jsonData.ischemiaPregressa"></b-form-checkbox></b-col
              ><b-col xs="12" sm="12" md="6" lg="6" xl="6"><b-form-input v-model="jsonData.ischemiaSede" type="text" :disabled="!jsonData.ischemiaPregressa"></b-form-input></b-col></b-row
          ></b-col>
          <b-col xs="12" sm="12" md="12" lg="12" xl="12"
            ><hr />
            <h5>{{ getTranslate("patient.cardiology.lblAnamnesysPositivity") }}</h5></b-col
          ><b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblRenalLithiasis") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.litiasiRenale" switch :checked="jsonData.litiasiRenale"></b-form-checkbox
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblUrinaryInfections") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.infezioniUrinarie" switch :checked="jsonData.infezioniUrinarie"></b-form-checkbox
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblAlcoholAbuse") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.alcohol" switch :checked="jsonData.alcohol"></b-form-checkbox
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblLicoriceAbuse") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.liquirizia" switch :checked="jsonData.liquirizia"></b-form-checkbox
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblNoseSpray") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.sprayNasali" switch :checked="jsonData.sprayNasali"></b-form-checkbox
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblLipidloweringDrugs") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.farmaciIpolipemizzanti" switch :checked="jsonData.farmaciIpolipemizzanti"></b-form-checkbox
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblMenoAndroPause") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.menoAndroPausa" switch :checked="jsonData.menoAndroPausa"></b-form-checkbox
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblOralContraceptives") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.contraccettiviOrali" switch :checked="jsonData.contraccettiviOrali"></b-form-checkbox
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblPregnancyHypertension") }}</span
            ><br />
            <b-form-checkbox v-model="jsonData.ipertensioneGravidanza" switch :checked="jsonData.ipertensioneGravidanza"></b-form-checkbox
          ></b-col>
          <b-col xs="12" sm="12" md="12" lg="12" xl="12"
            ><hr />
            <h5>{{ getTranslate("patient.cardiology.lbllDietActivityHabits") }}</h5></b-col
          >
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblFruit") }}</span
            ><br />
            <b-form-input v-model="jsonData.frutta" type="text"></b-form-input
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblFish") }}</span
            ><br />
            <b-form-input v-model="jsonData.pesce" type="text"></b-form-input
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblOil") }}</span
            ><br />
            <b-form-input v-model="jsonData.olio" type="text"></b-form-input
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblCheese") }}</span
            ><br />
            <b-form-input v-model="jsonData.cheese" type="text"></b-form-input
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblPhysicalActivity") }}</span
            ><br />
            <b-form-input v-model="jsonData.attivitaFisica" type="text"></b-form-input
          ></b-col>
          <b-col xs="12" sm="4" md="4" lg="4" xl="4"
            ><span>{{ getTranslate("patient.cardiology.lblPersonality") }}</span
            ><br />
            <b-form-input v-model="jsonData.personalita" type="text"></b-form-input
          ></b-col>
          <b-col xs="12" sm="12" md="12" lg="12" xl="12"
            ><span>{{ getTranslate("patient.cardiology.lblOther") }}</span
            ><br />
            <b-form-textarea v-model="jsonData.altro" type="text"></b-form-textarea
          ></b-col>
        </b-row>
      </div>
    </template>
  </sa-page-layout>
</template>
<script>
import axios from "axios";
import SaPageLayout from "../../../../../template/sa/components/layout/contents/SaPageLayout.vue";
import PazienteAnagrafica from "../../../components/PazienteAnagrafica.vue";
export default {
  props: {
    utente: {
      type: Object,
      default: function() {
        return null;
      },
    },
  },
  components: { SaPageLayout, PazienteAnagrafica },
  data() {
    return {
      linkback: null,
      pathResource: "/cardioanamnesi",
      jsonData: {},
      showModalLoading: false,
      opzioniMalattia: [
        { text: "No", value: "no" },
        { text: "Si", value: "yes" },
        { text: "Ictus -- sopravvissuti", value: "Ictus -- sopravvissuti" },
        { text: "Ictus -- non sopravvissuti", value: "Ictus -- non sopravvissuti" },
        { text: "TIA", value: "TIA" },
        { text: "Vivente", value: "Vivente" },
        { text: "Deceduto", value: "Deceduto" },
      ],
      opzioniDislipidemia: [
        { text: "No", value: "no" },
        { text: "Ipertriglicemica", value: "Ipertriglicemica" },
        { text: "Ipercolesterolemica", value: "Ipercolesterolemica" },
        { text: "Mista", value: "Mista" },
      ],
      opzioniSede1: [
        { text: "Anteriore", value: "anteriore" },
        { text: "Inferiore", value: "inferiore" },
        { text: "Laterale", value: "laterale" },
        { text: "Apicale", value: "apicale" },
        { text: "Settale", value: "settale" },
      ],
      opzioniSede2: [
        { text: "Anteriore", value: "anteriore" },
        { text: "Laterale", value: "laterale" },
        { text: "Apicale", value: "apicale" },
        { text: "Settale", value: "settale" },
      ],
      opzioniSiNo: [
        { text: "Sì", value: true },
        { text: "No", value: false },
      ],
      opzioniLavoro: [
        { text: "-", value: "-" },
        { text: "Sedentaria", value: "sedentaria" },
        { text: "Moderata", value: "moderata" },
        { text: "Stressante", value: "stressante" },
        { text: "Turnista", value: "turnista" },
      ],
      opzioniStudio: [
        { text: "Nessuno", value: "nessuno" },
        { text: "Scuola Elementare", value: "scuola-elementare" },
        { text: "Scuola Media", value: "scuola-media" },
        { text: "Scuola Superiore", value: "scuola-superiore" },
        { text: "Diploma Accademico", value: "diploma-accademico" },
        { text: "Laurea", value: "laurea" },
      ],
      opzioniAlcohol: [
        { text: "<1/2 Lt", value: "<1/2 Lt" },
        { text: "1/2 Lt", value: "1/2 Lt" },
        { text: "1-2 Lt", value: "1-2 Lt" },
        { text: ">2 Lt", value: ">2 Lt" },
      ],
    };
  },
  computed: {
    idPaziente() {
      return this.$route.query.idPaziente;
    },
    linkPatient: {
      get: function() {
        if (this.utente.gruppo.menuGruppo) {
          if (this.utente.gruppo.menuGruppo.length > 1) {
            return "/paziente/view/" + this.idPaziente;
          } else {
            if (this.utente.gruppo.menuGruppo.length == 1) {
              if (this.utente.gruppo.menuGruppo[0].primaryKey === "pazienti") {
                return "/paziente/view/" + this.idPaziente;
              } else {
                return "/paziente/homepaziente";
              }
            } else {
              return "";
            }
          }
        } else {
          return "";
        }
      },
    },
  },
  mounted() {
    let me = this;
    me.jsonData.idPaziente = this.$route.query.idPaziente;
    me.jsonData.tipoEvento = "Cardiologia - Anamnesi";
    me.id = this.$route.params.id;
    me.linkedit = "/paziente/cardiologia/anamnesi/edit/" + me.id + "?idPaziente=" + me.idPaziente;
    if (me.id !== "-1") {
      me.linkback = "/paziente/cardiologia/anamnesi?idPaziente=" + me.idPaziente;
      me.loadData();
    } else {
      me.linkback = "/paziente/cardiologia/anamnesi?idPaziente=" + me.idPaziente;
    }
  },

  methods: {
    loadData() {
      let me = this;
      if (me.id === "-1") {
        me.showModalLoading = false;
      } else {
        let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.id;
        axios.get(link).then((response) => {
          me.jsonData = response.data.data;
          me.showModalLoading = false;
        });
      }
    },
    getTranslate(text) {
      return this.$i18n.t(text);
    },
  },
};
</script>
