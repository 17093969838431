<template>
  <div>
    <h6 class="sa-text-left">{{ this.$i18n.t("menu.pazienti.diabetology.anamnesis") }}</h6>
    <div class="sa-form-section">
      <b-row>
        <b-col xs="2" sm="2" md="6" lg="6" xl="6">
          <strong>{{ this.$i18n.t("patient.diabetology.lblAgeOfAppearance") }}</strong>
          <br />
          {{ jsonData.etaDiComparsa }}
        </b-col>

        <b-col xs="10" sm="10" md="6" lg="6" xl="6">
          <strong>{{ this.$i18n.t("patients.lblSymptoms") }}</strong>
          <br />
          {{ jsonData.primiSintomi }}
        </b-col>
      </b-row>
      <hr />
    </div>
    <div class="sa-form-section">
      <b-row>
        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
          <strong>{{ this.$i18n.t("patients.lblDiagnosis") }}</strong>
          <br />
          {{ jsonData.diagnosi }}
        </b-col>
        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
          <strong>{{ this.$i18n.t("patients.lblReasonOfCheck") }}</strong>
          <br />
          {{ jsonData.motivoDelControlloAlCentro }}
        </b-col>
      </b-row>
      <hr />

      <b-row>
        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
          <strong>{{ this.$i18n.t("patients.lblCurrentSymptoms") }}</strong>
          <br />
          {{ jsonData.sintomatologiaAttuale }}
        </b-col>
        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
          <strong>{{ this.$i18n.t("patients.lblComplications") }}</strong>
          <br />
          {{ jsonData.complicanze }}
        </b-col>
      </b-row>
    </div>
    <hr />
    <br />
    <div class="sa-form-section">
      <b-row>
        <b-col xs="12" sm="12" md="12" lg="12" xl="12">
          <h6 class="sa-text-left">{{ this.$i18n.t("patients.lblConnectionOtherDiseases") }}</h6>
          <br />
        </b-col>
      </b-row>
      <b-row>
        <b-col xs="12" sm="12" md="12" lg="2" xl="2">
          <strong>{{ this.$i18n.t("patients.lblPregnancy") }}</strong>
          <br />
          <span :class="setColor(jsonData.coincidenzaConGravidanza)"> <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.coincidenzaConGravidanza)"></b-icon></span>
        </b-col>
        <b-col xs="2" sm="2" md="2" lg="2" xl="2">
          <strong>{{ this.$i18n.t("patients.lblStress") }}</strong>
          <br />
          <span :class="setColor(jsonData.coincidenzaConStress)"> <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.coincidenzaConStress)"></b-icon></span>
        </b-col>
        <b-col xs="2" sm="2" md="2" lg="2" xl="2">
          <strong>{{ this.$i18n.t("patients.lblClimacteric") }}</strong>
          <br />
          <span :class="setColor(jsonData.coincidenzaConClimaterio)"> <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.coincidenzaConClimaterio)"></b-icon></span>
        </b-col>
        <b-col xs="2" sm="2" md="2" lg="2" xl="2">
          <strong>{{ this.$i18n.t("patients.lblTrauma") }}</strong>
          <br />
          <span :class="setColor(jsonData.coincidenzaConTraumi)"> <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.coincidenzaConTraumi)"></b-icon></span>
        </b-col>
        <b-col xs="2" sm="2" md="12" lg="2" xl="2">
          <strong>{{ this.$i18n.t("patients.lblMedications") }}</strong>
          <br />
          <span :class="setColor(jsonData.coincidenzaConAssunzioniFarmaci)"><b-icon :font-scale="fontScale" :icon="setIcon(jsonData.coincidenzaConAssunzioniFarmaci)"></b-icon></span>
        </b-col>
      </b-row>
      <br />
      <b-row>
        <b-col xs="2" sm="2" md="2" lg="2" xl="2">
          <strong>{{ this.$i18n.t("patients.lblDiseases") }}</strong>
          <br />
          <span :class="setColor(jsonData.coincidenzaConMalattie)"> <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.coincidenzaConMalattie)"></b-icon></span>
        </b-col>
        <b-row></b-row>
        <b-col xs="10" sm="10" md="10" lg="10" xl="10">
          <br />
          {{ jsonData.coincidenzaConMalattieText }}
        </b-col>
      </b-row>
      <b-row>
        <b-col xs="2" sm="2" md="2" lg="2" xl="2">
          <strong>{{ this.$i18n.t("patients.lblMore") }}</strong>
          <br />
          <span :class="setColor(jsonData.coincidenzaConAltro)"> <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.coincidenzaConAltro)"></b-icon></span>
        </b-col>
        <b-col xs="10" sm="10" md="10" lg="10" xl="10">
          <br />
          {{ jsonData.coincidenzaConAltroText }}
        </b-col>
      </b-row>
    </div>
    <hr />
    <div class="sa-form-section">
      <b-row>
        <b-col xs="12" sm="12" md="12" lg="12" xl="12">
          <h6 class="sa-text-left">{{ this.$i18n.t("patients.lblConcomitantDiseases") }}</h6>
          <br />
        </b-col>
      </b-row>
      <b-row>
        <b-col xs="2" sm="2" md="2" lg="2" xl="2">
          <strong>{{ this.$i18n.t("patients.lblObesity") }}</strong>
          <br />
          <span :class="setColor(jsonData.patologieConcomitantiObesita)"> <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.patologieConcomitantiObesita)"></b-icon> </span>
          <br />
        </b-col>
        <b-col xs="2" sm="2" md="2" lg="2" xl="2">
          <strong>{{ this.$i18n.t("patients.lblDyslipidemia") }}</strong>
          <br />
          <span :class="setColor(jsonData.patologieConcomitantiDislipidemia)"> <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.patologieConcomitantiDislipidemia)"></b-icon></span>
          <br />
        </b-col>
        <b-col xs="2" sm="2" md="2" lg="2" xl="2">
          <strong>{{ this.$i18n.t("patients.lblHypertension") }}</strong>
          <br />
          <span :class="setColor(jsonData.patologieConcomitantiIpertensione)"> <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.patologieConcomitantiIpertensione)"></b-icon></span>
          <br />
        </b-col>

        <b-col xs="2" sm="2" md="2" lg="2" xl="2">
          <strong>{{ this.$i18n.t("patients.lblLithiasis") }}</strong>
          <br />
          <span :class="setColor(jsonData.patologieConcomitantiLitiasiRenaleColec)"> <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.patologieConcomitantiLitiasiRenaleColec)"></b-icon></span>
        </b-col>
        <b-col xs="2" sm="2" md="2" lg="2" xl="2">
          <strong>{{ this.$i18n.t("patients.lblUlcer") }}</strong>
          <br />
          <span :class="setColor(jsonData.patologieConcomitantiUlceraGastricaDuodenale)"> <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.patologieConcomitantiUlceraGastricaDuodenale)"></b-icon></span>
        </b-col>
        <b-col xs="2" sm="2" md="12" lg="2" xl="2">
          <strong>{{ this.$i18n.t("patients.lblArthrosis") }}</strong>
          <br />
          <span :class="setColor(jsonData.patologieConcomitantiArtrosi)"> <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.patologieConcomitantiArtrosi)"></b-icon></span>
        </b-col>
      </b-row>
      <br />
      <b-row>
        <b-col xs="2" sm="2" md="2" lg="2" xl="2">
          <strong>{{ this.$i18n.t("patients.lblVas") }}</strong>
          <br />
          <span :class="setColor(jsonData.patologieConcomitantiVasculopatiaCoronaricaCerebralePerife)"> <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.patologieConcomitantiVasculopatiaCoronaricaCerebralePerife)"></b-icon></span>
          <br />
        </b-col>
        <b-col xs="2" sm="2" md="2" lg="2" xl="2">
          <strong>{{ this.$i18n.t("patients.lblPsychiatricDiseases") }}</strong>
          <br />
          <span :class="setColor(jsonData.patologieConcomitantiMPsichiatriche)"><b-icon :font-scale="fontScale" :icon="setIcon(jsonData.patologieConcomitantiMPsichiatriche)"></b-icon></span>
          <br />
        </b-col>
        <b-col xs="2" sm="2" md="2" lg="2" xl="2">
          <strong>{{ this.$i18n.t("patients.lblGenitalSphere") }}</strong>
          <br />
          <span :class="setColor(jsonData.patologieConcomitantiMSferaGenitale)"> <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.patologieConcomitantiMSferaGenitale)"></b-icon></span>
          <br />
        </b-col>
        <b-col xs="2" sm="2" md="2" lg="2" xl="2">
          <strong>{{ this.$i18n.t("patients.lblBronchopneumus") }}</strong>
          <br />
          <span :class="setColor(jsonData.patologieConcomitantiBroncopneumopatie)"> <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.patologieConcomitantiBroncopneumopatie)"></b-icon></span>
        </b-col>
        <b-col xs="2" sm="2" md="2" lg="2" xl="2">
          <strong>{{ this.$i18n.t("patients.lblOsteoporosis") }}</strong>
          <br />
          <span :class="setColor(jsonData.patologieConcomitantiOsteoporosi)"> <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.patologieConcomitantiOsteoporosi)"></b-icon></span>
        </b-col>
        <b-col xs="2" sm="2" md="2" lg="2" xl="2">
          <strong>{{ this.$i18n.t("patients.lblThyroid") }}</strong>
          <br />
          <span :class="setColor(jsonData.patologieConcomitantiMTiroide)"> <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.patologieConcomitantiMTiroide)"></b-icon></span>
        </b-col>
      </b-row>
      <br />
      <b-row>
        <b-col xs="2" sm="2" md="2" lg="2" xl="2">
          <strong>{{ this.$i18n.t("patient.diabetology.lblOtherEndocrinopathies") }}</strong>
          <br />
          <span :class="setColor(jsonData.patologieConcomitantiAltreEndocrinopatie)"> <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.patologieConcomitantiAltreEndocrinopatie)"></b-icon></span>
        </b-col>
        <hr />
        <b-col xs="10" sm="10" md="10" lg="10" xl="10">
          <br />
          {{ jsonData.patologieConcomitantiAltreEndocrinopatieText }}
          <br />
        </b-col>
      </b-row>
      <br />
      <b-row>
        <b-col xs="2" sm="2" md="2" lg="2" xl="2">
          <strong>{{ this.$i18n.t("patients.lblMore") }}</strong>
          <br />
          <span :class="setColor(jsonData.patologieConcomitantiAltro)"> <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.patologieConcomitantiAltro)"></b-icon></span>
        </b-col>
        <hr />
        <b-col xs="10" sm="10" md="10" lg="10" xl="10">
          <br />
          {{ jsonData.coincidenzaConAltroText }}
          <br />
        </b-col>
      </b-row>
    </div>
    <br />
    <hr />
    <div class="sa-form-section">
      <h6 class="sa-text-left">{{ this.$i18n.t("patients.lblTherapyPraticated") }}</h6>
      <br />
      <b-row>
        <b-col xs="2" sm="2" md="2" lg="2" xl="2">
          <strong>{{ this.$i18n.t("patient.diet.lblTitle") }}</strong>
          <br />
          <span :class="setColor(jsonData.terapiePraticateDieta)"> <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.terapiePraticateDieta)"></b-icon></span>
        </b-col>
      </b-row>
      <br />
      <b-row>
        <b-col xs="2" sm="2" md="2" lg="2" xl="2">
          <strong>{{ this.$i18n.t("patients.lblOralAntiDiabetic") }}</strong>
          <br />
          <span :class="setColor(jsonData.terapiePraticateAntidiabeticiOrali)"> <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.terapiePraticateAntidiabeticiOrali)"></b-icon></span>
        </b-col>
        <b-col xs="10" sm="10" md="10" lg="10" xl="10">
          <br />
          {{ jsonData.terapiePraticateAntidiabeticiOraliText }}
        </b-col>
      </b-row>
      <br />
      <b-row>
        <b-col xs="2" sm="2" md="2" lg="2" xl="2">
          <strong>{{ this.$i18n.t("patients.lblInsulin") }}</strong>
          <br />
          <span :class="setColor(jsonData.terapiePraticateInsulina)"> <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.terapiePraticateInsulina)"></b-icon></span>
        </b-col>
        <b-col xs="10" sm="10" md="10" lg="10" xl="10">
          <br />
          {{ jsonData.terapiePraticateInsulinaText }}
        </b-col>
      </b-row>
      <br />
      <b-row>
        <b-col xs="2" sm="2" md="2" lg="2" xl="2">
          <strong>{{ this.$i18n.t("patient.diabetology.lblOtherMedicines") }}</strong>
          <br />
          <span :class="setColor(jsonData.terapiePraticateAltriFarmaci)"> <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.terapiePraticateAltriFarmaci)"></b-icon></span>
        </b-col>
        <b-col xs="10" sm="10" md="10" lg="10" xl="10">
          <br />
          {{ jsonData.terapiePraticateAltriFarmaciText }}
        </b-col>
      </b-row>
      <br />
      <hr />
    </div>

    <b-row>
      <b-col xs="12" sm="12" md="12" lg="12" xl="12">
        <div class="sa-view-form-data"><user-information :data="jsonData"></user-information></div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import moment from "moment";
import UtilityMixin from "../../../../utility/UtilityMixin";
import UserInformation from "../../../../utenti/components/UserInformation.vue";
import axios from "axios";
export default {
  mixins: [UtilityMixin],
  props: {
    idEvento: { type: String, require: true },
  },
  components: { UserInformation },
  data() {
    return {
      fontScale: 2,
      jsonData: {},
      pathResources: "/diabetologiaanamnesi",
    };
  },
  computed: {
    idPaziente() {
      return this.$route.query.idPaziente;
    },
    dataEventoPlaceholder() {
      return this.$i18n.t("patient.events.lblEventDate");
    },
  },
  mounted() {
    let me = this;
    me.onLoadData();
  },

  methods: {
    formatDate(value) {
      if (value) {
        return moment(new Date(value)).format("DD-MM-YYYY HH:mm:ss");
      } else {
        return "---";
      }
    },

    onLoadData() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResources + "?idPaziente=" + me.idPaziente;
      axios.get(link).then((Response) => {
        me.jsonData = Response.data.data[0];
      });
    },
    setColor(value) {
      let color = value == true ? "text-success" : "text-danger";
      return color;
    },
    setIcon(value) {
      let icon = value === true ? "check" : "x";
      return icon;
    },
  },
};
</script>
