import AccettazioneList from "./components/AccettazioneList.vue";
import AccettazioneView from "./components/AccettazioneView.vue";
import AccettazioneEdit from "./components/AccettazioneEdit.vue";
import AccettazioneStrutturaEdit from "./components/AccettazioneStrutturaEdit.vue";

export default {
    AccettazioneList,
    AccettazioneEdit,
    AccettazioneView,
    AccettazioneStrutturaEdit,
};
