<template>
    <sa-page-layout :showModalLoading="showModalLoading" @refresh="onRefresh" :btnRefreshVisible="true">
        <template slot="toolbar-title">
            <paziente-anagrafica :linkPatient="linkPatient" :idPaziente="idPaziente"></paziente-anagrafica>
        </template>
        <template slot="toolbar-row">
            <paziente-dashboard-component :idPaziente="idPaziente"></paziente-dashboard-component>
        </template>
        <template slot="table-header">
            <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col lg="3">
                    <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
                </b-col>
                <b-col lg="9">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
            </b-row>
        </template>
        <template slot="table-body">
            <div class="b-table-sticky-header">
                <b-table sticky-header ref="table" :filter="filter" responsive stacked="lg" striped hover :items="items" :fields="fields" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                    <template v-slot:cell(descrizione)="{ item }">
                        <b-link class="sa-edit-link" :href="item.link" target="_blank">{{ item.descrizione }}</b-link>
                    </template>
                    <template v-slot:cell(icon)="{ item }">
                        <b-icon :icon="item.icon" font-scale="1.6" />
                        <!-- <p class="h4 mb-2"><b-icon :icon="item.icon"></b-icon></p> -->
                    </template>
                </b-table>
            </div>
        </template>
        <template slot="table-footer"> </template>
    </sa-page-layout>
</template>
<script>
import axios from "axios";
import PazienteAnagrafica from "./PazienteAnagrafica.vue";
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
import PazienteDashboardComponent from "./PazienteDashboardComponent.vue";
export default {
    props: {
        utente: {
            type: Object,
            default: function() {
                return null;
            },
        },
    },
    components: { PazienteAnagrafica, SaPageLayout, PazienteDashboardComponent },
    computed: {
        idPaziente() {
            return this.$route.query.idPaziente;
        },
        rows() {
            return this.items.length;
        },
        linkPatient: {
            get: function() {
                let returnValue = "";
                if (this.utente.gruppo) {
                    if (this.utente.gruppo.menuGruppo) {
                        if (this.utente.gruppo.menuGruppo.length > 1) {
                            return "/paziente/view/" + this.idPaziente;
                        } else {
                            if (this.utente.gruppo.menuGruppo.length == 1) {
                                if (this.utente.gruppo.menuGruppo[0].primaryKey === "pazienti") {
                                    return "/paziente/view/" + this.idPaziente;
                                } else {
                                    return "/paziente/homepaziente";
                                }
                            }
                        }
                    }
                }
                return returnValue;
            },
        },
    },
    data() {
        return {
            pathResource: "/linkutili",
            pagelink: "",
            perPage: 10,
            currentPage: 1,
            note: "",
            pdf: null,
            filter: null,
            showModalLoading: false,
            id: "-1",
            filtro: {},
            fields: [
                {
                    label: "",
                    key: "icon",
                    sortable: false,
                    thStyle: "width: 2rem",
                },
                {
                    label: this.$i18n.t("link.lblDescription"),
                    key: "descrizione",
                    sortable: true,
                    thStyle: "width: 64rem",
                },
                // {
                //     label: "",
                //     key: "actions",
                //     thStyle: "width: 3rem",
                // },
                //{ class: "sa-table-column-action-single", thStyle: "width: 10rem" ,key: "actions", label: "" },
            ],
            items: [],
            data: {},
        };
    },
    created: function() {},
    mounted: function() {
        let me = this;
        me.id = this.$route.params.id;
        me.data.idPaziente = this.$route.query.idPaziente;
        me.loadData();
    },
    methods: {
        loadData() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "?idAnagrafica=" + me.data.idPaziente;
            me.showModalLoading = true;
            axios
                .get(link, { params: me.filtro })
                .then((response) => {
                    me.items = [];
                    me.items = response.data.data;
                    me.showModalLoading = false;
                })
                .catch(() => {
                    me.showModalLoading = false;
                });
        },
        onRefresh() {
            let me = this;
            me.loadData();
        },
        onClick(item) {
            let me = this;
            me.pdf = "data:application/pdf;base64," + item.pdf;
        },
        onAdd() {
            let me = this;
            me.$router.replace(me.pathRESTRequest + "/edit/-1?idPaziente=" + me.idPaziente).catch((err) => {
                err;
            });
        },
    },
};
</script>
