<template>
  <sa-page-layout :data="device" :btnSaveVisible="true" :btnBackVisible="true" :linkback="linkback" :pathResource="pathResource" :linkedit="linkedit">
    <template slot="table-body">
      <b-row>
        <b-col xs="6" sm="6" md="6" lg="6" xl="6">
          <span>Mac Address:</span> <br />
          <b-form-input v-model="device.deviceMAC" no-resize placeholder="Address" required></b-form-input>
        </b-col>
        <b-col xs="6" sm="6" md="6" lg="6" xl="6">
          <span>Password:</span> <br />
          <b-form-input type="text" v-model="device.password" placeholder="Password" required></b-form-input>
        </b-col>
      </b-row>
      <b-row>
        <b-col xs="6" sm="6" md="6" lg="6" xl="6">
          <span>Username:</span> <br />
          <b-form-input type="text" v-model="device.userPermission" placeholder="Username" required></b-form-input>
        </b-col>
        <b-col xs="6" sm="6" md="6" lg="6" xl="6">
          <span>Tipo Dispositivo:</span> <br />
          <b-form-select v-model="device.deviceType" id="inline-form-custom-select-pref" :options="typeOptions" :value="null" required></b-form-select>
        </b-col>
      </b-row>
    </template>
  </sa-page-layout>
</template>

<script>
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
export default {
  components: { SaPageLayout },

  data() {
    return {
      typeOptions: [
        { text: "-Seleziona Valore-", value: null },
        { text: "Termometro Rycom ", value: "t1" },
        { text: "Pulsossimetro ", value: "p1" },
        { text: "SfigmomanometroAlphagoMed", value: "s1" },
        { text: "Bracciale", value: "b1" },
        { text: "Ecg palmare", value: "e1" },
        { text: "Ecg D-Heart", value: "e2" },
        { text: "Glucometro", value: "g1" },
      ],
      pathResource: "/hassistodevices",
      linkback: "/hassistodevice",
      linkedit: null,
      device: {},
    };
  },

  mounted: function() {
    let me = this;
    me.id = this.$route.params.id;
  },

  methods: {},
};
</script>
