import Cardiologia from "../";

const routes = [
  { name: "AnamnesiList", path: "/paziente/cardiologia/anamnesi/", component: Cardiologia.CardioAnamnesiList, meta: { title: "Anamnesi Cardiologia" } },
  { name: "AnamnesiView", path: "/paziente/cardiologia/anamnesi/view/:id", component: Cardiologia.CardioAnamnesiView, meta: { title: "Anamnesi Cardiologia" } },
  { name: "AnamnesiEdit", path: "/paziente/cardiologia/anamnesi/edit/:id", component: Cardiologia.CardioAnamnesiEdit, meta: { title: "Anamnesi Cardiologia" } },

  { name: "RefertazioneList", path: "/paziente/cardiologia/refertazione/", component: Cardiologia.CardioRefertazioneList, meta: { title: "Refertazione Cardiologia" } },
  { name: "RefertazioneView", path: "/paziente/cardiologia/refertazione/view/:id", component: Cardiologia.CardioRefertazioneView, meta: { title: "Refertazione Cardiologia" } },
  { name: "RefertazioneEdit", path: "/paziente/cardiologia/refertazione/edit/:id", component: Cardiologia.CardioRefertazioneEdit, meta: { title: "Anamnesi Cardiologia" } },
];
export default routes;
