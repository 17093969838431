<template>
  <div>
    <div class="sa-form-section" style="margin-top: 2rem;">
      <b-row>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <strong>{{ this.$i18n.t("patient.diabetology.lblFather") }}</strong>
          <br />
          {{ jsonData.padre }}
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <strong>{{ this.$i18n.t("patient.diabetology.lblMother") }}</strong>
          <br />
          {{ jsonData.madre }}
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <strong>{{ this.$i18n.t("patient.diabetology.lblSons") }}</strong>
          <br />
          {{ jsonData.figli }}
        </b-col>
      </b-row>
      <hr />
      <b-row>
        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
          <strong>{{ this.$i18n.t("patient.diabetology.lblAscending") }}</strong>
          <br />
          {{ jsonData.ascendenti }}
        </b-col>
        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
          <strong>{{ this.$i18n.t("patient.diabetology.lblCollateral") }}</strong>
          <br />
          {{ jsonData.collaterali }}
        </b-col>
      </b-row>
      <hr />
      <b-row>
        <b-col lg="3">
          <p>{{ this.$i18n.t("clinic.lblTotalRecords") }}: {{ rows }}</p>
        </b-col>
        <b-col lg="9">
          <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
        </b-col>
      </b-row>
      <div class="b-table-sticky-header">
        <b-table sticky-header ref="table" stacked="xl" striped hover :items="jsonData.diabetologiaAnamnesiFamiliareFamiliari" :fields="fields" sort-icon-left head-variant="light" class="sa-b-table"> </b-table>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  props: {
    idEvento: {
      Type: String,
      require: true,
    },
  },
  computed: {
    rows() {
      return this.jsonData.diabetologiaAnamnesiFamiliareFamiliari.length;
    },
  },
  data() {
    return {
      jsonData: { diabetologiaAnamnesiFamiliareFamiliari: [] },
      pathResource: "/diabetologiaanamnesifamiliare",
      currentPage: 1,
      perPage: 10,
      fields: [
        {
          label: "Tipo Parentela",
          key: "tipoParentela",
          thStyle: "width: 8rem",
          sortable: true,
        },
        {
          label: "Diabete",
          key: "diabete",
          thStyle: "width: 5rem",
          sortable: true,
        },
        {
          label: "Ipertensione",
          key: "ipertensione",
          thStyle: "width: 7rem",
          sortable: true,
        },
        {
          label: "Obesità",
          key: "obesita",
          thStyle: "width: 5rem",
          sortable: true,
        },
        {
          label: "Dislipidemia",
          key: "dislipidemia",
          thStyle: "width: 7rem",
          sortable: true,
        },
        {
          label: "Altro",
          key: "altro",
          sortable: true,
        },
      ],
    };
  },
  mounted() {
    let me = this;
    me.loadData();
  },
  methods: {
    loadData() {
      let me = this;
      me.showmolalloading = true;
      let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
      axios.get(link + me.idEvento).then((response) => {
        me.jsonData = response.data.data;
      });
    },
  },
};
</script>
