<template>
  <div class="sa-page">
    <div class="content-header">
      <b-row>
        <b-col>
          <div style="float: left; margin-top: 0rem; width: 100%;">
            <span style="margin-left: 0.5rem; float: left;">{{ titolo }}</span>
            <slot name="toolbar-title"> </slot>
          </div>
        </b-col>
        <b-col class="sa-col-toolbar">
          <div style="float: right">
            <b-button v-if="btnNewVisible" class="float-sm-right " variant="success btn-toolbar sa-margin-right" size="sm" @click="onAdd"><b-icon icon="plus"></b-icon>{{ this.$i18n.t("global.lblBtnNew") }}</b-button>
            <b-button v-if="btnRefreshVisible" class="float-sm-right " variant="info btn-toolbar" size="sm" @click="onRefresh" v-b-modal.modal-center><b-icon icon="arrow-clockwise" />Refresh</b-button>
            <!--<b-button variant="purple btn-toolbar sa-margin-right" size="sm" @click="onEdit"><b-icon icon="pencil"></b-icon>Modifica</b-button>
                        <b-button  variant="success btn-toolbar sa-margin-right" size="sm" @click="onSave"><b-icon icon="check2"></b-icon>Salva</b-button>-->
            <slot name="toolbar"></slot>
          </div>
          <!--<div class="sa-card-header-custom-title"></div>
                    <div>
                        <slot name="toolbar"> </slot>
                    </div>-->
        </b-col>
      </b-row>
    </div>
    <b-card>
      <div class="content">
        <div class="sa-table">
          <div class="sa-table-filter">
            <slot name="table-filter"></slot>
          </div>
          <div class="sa-table-header"><slot name="table-header"></slot></div>
          <div class="sa-table-body">
            <slot name="table-body"></slot>
          </div>
          <div class="sa-table-footer" style="height: 30px"><slot name="table-footer"></slot></div>
        </div>
      </div>
    </b-card>

    <sa-modal-loading ref="sa-modal-loading" />
  </div>
</template>
<script>
//import axios from "axios";
import { SaModalLoading } from "../../../../../components/modal";
export default {
  components: { SaModalLoading },
  props: {
    titolo: String,
    linkedit: String,
    showmolalloading: Boolean,
    btnRefreshVisible: { type: Boolean, default: false },
    btnNewVisible: { type: Boolean, default: false },
  },
  watch: {
    showmolalloading(value) {
      let me = this;
      if (value) {
        me.$refs["sa-modal-loading"].show();
      } else {
        setTimeout(me.$refs["sa-modal-loading"].close(), 200);
      }
    },
  },
  methods: {
    onAdd() {
      let me = this;
      me.$router.replace(me.linkedit + "/edit/-1").catch((err) => {
        err;
      });
    },
    onRefresh() {
      let me = this;
      me.$emit("refresh");
    },
  },
};
</script>
