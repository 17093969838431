<template>
    <sa-page-view-edit ref="pgaView" :data="data" :pathResource="pathResource" :backVisible="true" :saveVisible="true" :linkback="linkback" :linkedit="linkedit" :showmolalloading="showmolalloading">
        <template slot="body">
            <div>
                <div class="sa-form-section">
                    <h5 class="sa-text-left">{{ this.$i18n.t("structure.lblLogo") }}</h5>
                    <b-row>
                        <b-form-file v-model="file1" :state="Boolean(file1)" placeholder="Trascina un file qui" @change="handleImage" class="custom-input" type="file" accept="logo/*"></b-form-file>
                        <div class="mt-3">File Selezionato: {{ file1 ? file1.name : "" }}</div>
                    </b-row>
                </div>
                <div class="sa-form-section">
                    <h5 class="sa-text-left">{{ this.$i18n.t("structure.lblClinicRegistry") }}</h5>
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <span class="sa-form-label">{{ this.$i18n.t("structure.lblName") }}</span>
                            <b-form-input v-model="data.denominazione" placeholder="Nome Struttura"></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <span class="sa-form-label">{{ this.$i18n.t("structure.lblRegionCode") }}</span>
                            <b-form-input v-model="data.codiceRegione" placeholder="Codice Regione"></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <span class="sa-form-label">{{ this.$i18n.t("structure.lblCompanyCode") }}</span>
                            <b-form-input v-model="data.codiceStruttura" placeholder="Codice Struttura"></b-form-input>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                            <span class="sa-form-label">{{ this.$i18n.t("structure.lblVatNumber") }}</span>
                            <b-form-input v-model="data.partitaIva" placeholder="Partita Iva"></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                            <span class="sa-form-label">{{ this.$i18n.t("structure.lblFiscalCode") }}</span>
                            <b-form-input v-model="data.codiceFiscale" placeholder="Codice Fiscale"></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                            <span class="sa-form-label">{{ this.$i18n.t("structure.lblIban") }}</span>
                            <b-form-input v-model="data.iban" placeholder="IBAN"></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                            <span class="sa-form-label">{{ this.$i18n.t("structure.lblTaxRegime") }}</span>
                            <b-form-select id="inline-form-custom-select-pref" v-model="data.codiceRegimeFiscale" class="mb-2 mr-sm-2 mb-sm-0" :options="codiciRegimiFiscaliOptions" :value="null"></b-form-select>
                        </b-col>
                    </b-row>
                </div>
                <div class="sa-form-section">
                    <h5 class="sa-text-left">{{ this.$i18n.t("structure.lblClinicInformation") }}</h5>
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <span class="sa-form-label">{{ this.$i18n.t("structure.lblAddress") }}</span>
                            <b-form-input v-model="data.indirizzo" placeholder="Indirizzo"></b-form-input>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="4" xl="4">
                            <span class="sa-form-label">{{ this.$i18n.t("structure.lblProvince") }}</span>
                            <b-form-select id="inline-form-custom-select-pref" v-model="data.provincia" class="mb-2 mr-sm-2 mb-sm-0" :options="provinciaOption" :value="null" @change="loadComune(data.provincia)"></b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="4" xl="4">
                            <span class="sa-form-label">{{ this.$i18n.t("structure.lblCity") }}</span>
                            <b-form-select id="inline-form-custom-select-pref" v-model="data.localita" class="mb-2 mr-sm-2 mb-sm-0" :options="comuneOption" :value="null" @change="loadCap(data.localita)"></b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="4" xl="4">
                            <span class="sa-form-label">{{ this.$i18n.t("structure.lblPostalCode") }}</span>
                            <b-form-input v-model="data.cap" placeholder="CAP"></b-form-input>
                        </b-col>
                    </b-row>
                </div>
                <div class="sa-form-section">
                    <h5 class="sa-text-left">{{ this.$i18n.t("structure.lblContacts") }}</h5>
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <span class="sa-form-label">{{ this.$i18n.t("structure.lblTelephoneNumber") }}</span>
                            <b-form-input v-model="data.telefono" placeholder="Telefono"></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <span class="sa-form-label">{{ this.$i18n.t("structure.lblEmail") }}</span>
                            <b-form-input v-model="data.email" placeholder="Email"></b-form-input>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <span class="sa-form-label">{{ this.$i18n.t("structure.lblFax") }}</span>
                            <b-form-input v-model="data.fax" placeholder="Fax"></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <span class="sa-form-label">{{ this.$i18n.t("structure.lblUrl") }}</span>
                            <b-form-input v-model="data.url" placeholder="Url"></b-form-input>
                        </b-col>
                    </b-row>
                </div>
                <div class="sa-form-section">
                    <h5 class="sa-text-left">{{ this.$i18n.t("structure.lblNote") }}</h5>
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <span class="sa-form-label">{{ this.$i18n.t("structure.lblNote") }}</span>
                            <b-form-textarea v-model="data.note" placeholder="Note"></b-form-textarea>
                        </b-col>
                    </b-row>
                </div>
            </div>
        </template>
    </sa-page-view-edit>
</template>
<script>
import axios from "axios";
//import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import { SaPageViewEdit } from "../../../template/sa/components/layout/contents";
export default {
    components: { SaPageViewEdit },
    data() {
        return {
            pathRest: "/strutture",
            pathResource: "/strutture",
            linkback: "/strutture",
            linkProvince: "/province",
            linkComune: "/comuni/provincia",
            linkComuneByIstat: "/comuni/istat",
            linkRegimiFiscali: "/regimifiscali",
            linkStrutture: "/strutture",
            linkedit: null,
            showmolalloading: false,
            file1: null,
            id: "-1",
            profili: [],
            strutturaOption: [{ value: null, text: "-Seleziona Struttura-" }],
            provinciaOption: [{ value: null, text: "-Seleziona Provincia-" }],
            comuneOption: [{ value: null, text: "-Seleziona Comune-" }],
            codiciRegimiFiscaliOptions: [{ value: null, text: "-Seleziona Regime Fiscale-" }],
            data: { localita: null, provincia: null, cap: null, codiceRegimeFiscale: null },
        };
    },
    computed: {},
    mounted: function() {
        let me = this;
        me.id = this.$route.params.id;
        me.loadDefaultData();
    },
    methods: {
        loadDefaultData() {
            let me = this;
            me.loadProvince();
            me.loadData();
            me.loadRegimiFiscali();
        },
        loadData() {
            let me = this;

            //console.log(me.id);
            if (me.id === "-1") {
                me.showmolalloading = false;
            } else {
                me.showmolalloading = true;
                let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
                axios.get(link + me.id).then((response) => {
                    me.data = response.data.data;
                    me.loadComune(me.data.provincia);
                    me.loadCap(me.data.localita);
                    me.showmolalloading = false;
                });
            }
        },
        loadProvince() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkProvince;
            axios.get(link).then((response) => {
                response.data.data.forEach((element) => {
                    me.provinciaOption.push({ value: element.sigla, text: element.provincia });
                });
            });
        },
        loadComune(sigla) {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkComune + "/";
            axios.get(link + sigla).then((response) => {
                me.comuneOption = [];
                response.data.data.forEach((element) => {
                    me.comuneOption.push({ value: element.codiceIstat, text: element.comune });
                });
            });
        },
        loadCap(localita) {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkComuneByIstat + "/" + localita;
            axios.get(link, { params: {} }).then((response) => {
                me.data.cap = response.data.data.cap;
            });
        },
        loadRegimiFiscali() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkRegimiFiscali;
            axios.get(link).then((response) => {
                response.data.data.forEach((element) => {
                    let descrizione = "(" + element.codiceRegimeFiscale + ") " + element.descrizioneRegimeFiscale;
                    me.codiciRegimiFiscaliOptions.push({ value: element.codiceRegimeFiscale, text: descrizione });
                });
            });
        },
        handleImage(e) {
            const selectedImage = e.target.files[0]; // get first file
            this.createBase64Image(selectedImage);
        },
        createBase64Image(fileObject) {
            let me = this;
            const reader = new FileReader();
            reader.onload = (e) => {
                me.logo = e.target.result;
                me.data.logo = me.logo;
                // this.uploadImage();
            };
            reader.readAsDataURL(fileObject);
        },
        // uploadImage() {
        //     const { logo } = this;
        //     let me = this;
        //     let link = process.env.VUE_APP_PATH_API + me.pathResource
        //     axios.post(link, { logo })
        //         .then((response) => {
        //         this.remoteUrl = response.data.url;
        //     })
        //     .catch((err) => {
        //         return new Error(err.message);
        //     })
        // },
        onSave() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource;
            let jsonData = JSON.stringify(me.data);

            me.showmolalloading = true;
            if (me.id == "-1") {
                axios
                    .post(link, jsonData)
                    .then((response) => {
                        me.data = response.data.data;
                        me.showmolalloading = false;
                        me.$router.replace(me.routeLink).catch((err) => {
                            err;
                        });
                    })
                    .catch((error) => {
                        me.showmolalloading = false;
                        this.$bvModal
                            .msgBoxOk(error.response.data.messaggio, {
                                title: "ATTENZIONE",
                                size: "sm",
                                okVariant: "outline-danger",
                                headerClass: "sa-msg-header-danger",
                                footerClass: "p-2 border-top-0",
                                centered: true,
                            })
                            .then((value) => {
                                this.boxTwo = value;
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                    });
            } else {
                axios
                    .put(link + "/" + me.id, jsonData)
                    .then((response) => {
                        me.gruppo = response.data.data;
                        me.$refs["sa-modal-loading"].close();
                        me.$router.replace(me.routeLink).catch((err) => {
                            err;
                        });
                    })
                    .catch((error) => {
                        me.$refs["sa-modal-loading"].close();
                        this.$bvModal
                            .msgBoxOk(error.response.data.messaggio, {
                                title: "ATTENZIONE",
                                size: "sm",
                                okVariant: "outline-danger",
                                headerClass: "sa-msg-header-danger",
                                footerClass: "p-2 border-top-0",
                                centered: true,
                            })
                            .then((value) => {
                                this.boxTwo = value;
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                    });
            }
        },
    },
};
</script>
