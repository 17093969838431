<template>
    <sa-page-view-edit ref="pgaView" :backVisible="true" :editVisible="true" :titolo="data.titolo" :linkback="pagelink" :linkedit="linkedit" :showmolalloading="showmolalloading">
        <template slot="body">
            <b-card lg="8" overlay img-src="https://picsum.photos/900/250/?image=3" img-alt="Card Image" text-variant="white" title="Image Overlay" sub-title="Subtitle">
                <b-card-text>
                    Some quick example text to build on the card and make up the bulk of the card's content.
                </b-card-text>
            </b-card>
            <b-card lg="4" overlay img-src="https://picsum.photos/900/250/?image=3" img-alt="Card Image" text-variant="white" title="Image Overlay" sub-title="Subtitle">
                <b-card-text>
                    Some quick example text to build on the card and make up the bulk of the card's content.
                </b-card-text>
            </b-card>
        </template>

        <!--<template slot="filter">
            <b-row>
                <b-dropdown text="Anamnesi" block variant="primary" class="m-2">
                    <b-dropdown-item @click="onEditAnamnesiDiabetologia">{{ this.$i18n.t("pazienti.lblDiabeticHistory")}}</b-dropdown-item>
                    <b-dropdown-item @click="onEditAnamnesiFamiliare">{{ this.$i18n.t("pazienti.lblFamilyHistory")}}</b-dropdown-item>
                    <b-dropdown-item @click="onEditAnamnesiFisiologica">{{ this.$i18n.t("pazienti.lblPhysiologicalHistory")}}</b-dropdown-item>
                    <b-dropdown-item @click="onEditAnamnesiPatologia">{{ this.$i18n.t("pazienti.lblPathologyHistory")}}</b-dropdown-item>
                </b-dropdown>

                <b-dropdown text="Eventi" block variant="primary" class="m-2">
                    <b-dropdown-item @click="onEditControlliSpecialistici">{{ this.$i18n.t("pazienti.lblSpecialistChecks")}}</b-dropdown-item>
                    <b-dropdown-item @click="onEditDatiLaboratorio">{{ this.$i18n.t("pazienti.lblLaboratoryData")}}</b-dropdown-item>
                    <b-dropdown-item @click="onEditFollowUP">Follow UP</b-dropdown-item>
                    <b-dropdown-item @click="onEditPrimaVisita">{{ this.$i18n.t("pazienti.lblFirstExamination")}}</b-dropdown-item>
                </b-dropdown>
            </b-row>
        </template>-->
    </sa-page-view-edit>
</template>

<script>
//import axios from "axios";
import moment from "moment";
import { SaPageViewEdit } from "../../../template/sa/components/layout/contents";
export default {
    components: { SaPageViewEdit },
    computed: {
        rows() {
            return this.items.length;
        },
        attesaInvio() {
            let atesaInvio = 0;
            this.items.forEach(function(item) {
                if (item.statoInvio === "ATTESA INVIO") {
                    atesaInvio++;
                }
            });
            return atesaInvio;
        },
        inviati() {
            let inviati = 0;
            this.items.forEach(function(item) {
                if (item.statoInvio === "INVIATO") {
                    inviati++;
                }
            });
            return inviati;
        },
        errori() {
            let errori = 0;
            this.items.forEach(function(item) {
                if (item.statoInvio === "ERROR") {
                    errori++;
                }
            });
            return errori;
        },
        nonValidi() {
            let nonValidi = 0;
            this.items.forEach(function(item) {
                if (!item.numeroCellulareValido) {
                    nonValidi++;
                }
            });
            return nonValidi;
        },
    },
    data() {
        return {
            task: { nome: "" },
            showmolalloading: false,
            pagelink: "/pazienti",
            pagelinkDiabetologia: "/pazientidiabetologia", //  Da controllare
            linkedit: null,
            elaborazione: false,
            currentPage: 1,
            totalRows: 1,
            perPage: 100,
            filter: "",
            pathResource: "/pazientidiabetologia",
            fields: [
                {
                    label: "Cognome",
                    key: "cognome",
                    sortable: true,
                    thStyle: "width: 9rem",
                },
                {
                    label: "Nome",
                    key: "nome",
                    sortable: true,
                    thStyle: "width: 9rem",
                },
                {
                    label: "Codice Fiscale",
                    key: "codiceFiscale",
                    sortable: true,
                    thStyle: "width: 11rem",
                },
                {
                    label: "Testo Opzionale",
                    key: "testoOpzionale",
                    sortable: true,
                },
                {
                    label: "Numero",
                    key: "numeroCellulare",
                    sortable: true,
                    thStyle: "width: 9rem",
                },
                {
                    label: "Stato",
                    key: "statoInvio",
                    sortable: true,
                    thStyle: "width: 9rem",
                },
                { key: "actions", label: "", thStyle: "width: 7rem", tdClass: "text-center" },
            ],
            items: [],
            data: {},
            ricevente: { id: "-1", idInvioPazienti: null },
            fileCsv: { primaRigaIntestazione: true, file: null },
            stato: { log: "" },
            filtro: {},
        };
    },
    created: function() {},
    mounted: function() {
        let me = this;
        me.id = this.$route.params.id;
        me.linkedit = "/pazientidiabetologia/edit/" + me.id;
        me.loadData();
    },
    update: function() {
        console.log("Update");
    },
    methods: {
        formatDate(value) {
            if (value) {
                return moment(new Date(value)).format("DD-MM-YYYY HH:mm:ss");
            } else {
                return "---";
            }
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        loadData() {
            let me = this;
            me.showmolalloading = false;
            //let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
            //axios.get(link + me.id).then((response) => {
            //    me.data = response.data.data;
            //    me.showmolalloading = false;

            //});
        },
        loadDataDetails() {
            //let me = this;
            //me.showmolalloading = true;
            //let link = process.env.VUE_APP_PATH_API + "/inviopazientidettaglio";
            //axios.get(link, { params: { idInvioPazienti: me.id } }).then((response) => {
            //    me.items = response.data.data;
            //    me.totalRows = me.items.length;
            //    me.showmolalloading = false;
            //});
        },
        onEdit() {
            // Deve richiamare l'edit di paziente
            let me = this;
            me.$router.replace(me.routeLink + "/edit/" + me.data.id).catch((err) => {
                err;
            });
        },
        onEditAnamnesiDiabetologia() {
            console.log("Cambio rotta");
            let me = this;
            me.$router.replace(me.pagelinkDiabetologia + "anamnesidiabetologia").catch((err) => {
                err;
            });
        },

        onEditAnamnesiFamiliare() {
            console.log("Cambio rotta");
            let me = this;
            me.$router.replace(me.pagelinkDiabetologia + "anamnesifamiliare").catch((err) => {
                err;
            });
        },

        onEditAnamnesiFisiologica() {
            console.log("Cambio rotta");
            let me = this;
            me.$router.replace(me.pagelinkDiabetologia + "anamnesifisiologica").catch((err) => {
                err;
            });
        },

        onEditAnamnesiPatologia() {
            console.log("Cambio rotta");
            let me = this;
            me.$router.replace(me.pagelinkDiabetologia + "anamnesipatologia").catch((err) => {
                err;
            });
        },

        onEditControlliSpecialistici() {
            console.log("Cambio rotta");
            let me = this;
            me.$router.replace(me.pagelinkDiabetologia + "controllispecialistici").catch((err) => {
                err;
            });
        },

        onEditDatiLaboratorio() {
            console.log("Cambio rotta");
            let me = this;
            me.$router.replace(me.pagelinkDiabetologia + "datilaboratorio").catch((err) => {
                err;
            });
        },
        onEditPrimaVisita() {
            console.log("Cambio rotta");
            let me = this;
            me.$router.replace(me.pagelinkDiabetologia + "primavisita").catch((err) => {
                err;
            });
        },

        onEditFollowUP() {
            console.log("Cambio rotta");
            let me = this;
            me.$router.replace(me.pagelinkDiabetologia + "followup").catch((err) => {
                err;
            });
        },
        onBack() {
            let me = this;
            me.$router.replace(me.routeLink).catch((err) => {
                err;
            });
            clearInterval(me.interval);
        },
    },
};
</script>
