<template>
  <sa-page-layout :showModalLoading="showModalLoading" :btnSaveVisible="true" :btnAnnullaVisible="true" :linkback="linkback" :pathResource="pathResource" :data="jsonData">
    <template slot="toolbar-title">
      <paziente-anagrafica :linkPatient="linkPatient" :idPaziente="idPaziente"></paziente-anagrafica>
    </template>
    <template slot="table-body">
      <div class="sa-tab-scroll">
        <b-row>
          <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
            <b-row>
              <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <span class="sa-form-label">{{ this.$i18n.t("patient.fisiologica.lblConcomitantDiseases") }}</span>
                <b-form-textarea v-model="jsonData.malattieConcomitanti" no-resize></b-form-textarea>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <span class="sa-form-label">{{ this.$i18n.t("patient.fisiologica.lblPreviousSurgicalInterventions") }}</span>
                <b-form-textarea v-model="jsonData.pregressiInterventi" no-resize></b-form-textarea>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                <span class="sa-form-label">{{ this.$i18n.t("patient.fisiologica.lblSmoke") }}</span>
                <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.fumo" :options="fumoOptions" :value="null" value-field="value" text-field="text" @input="onInputFumo($event)"></b-form-select>
              </b-col>
              <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                <span class="sa-form-label">{{ this.$i18n.t("patient.fisiologica.lblSmokeQuantity") }}</span>
                <b-form-select
                  class="mb-2 mr-sm-2 mb-sm-0"
                  v-model="jsonData.quantitaFumo"
                  :options="quantitaFumoOptions"
                  :value="null"
                  value-field="value"
                  text-field="text"
                  :disabled="quantitaFumoDisabled"
                ></b-form-select>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                <span class="sa-form-label">{{ this.$i18n.t("patient.fisiologica.lblAlcoholicBeverages") }}</span>
                <b-form-select
                  class="mb-2 mr-sm-2 mb-sm-0"
                  v-model="jsonData.bevandeAlcoliche"
                  :options="bevandeAlcolicheOptions"
                  :value="null"
                  value-field="value"
                  text-field="text"
                  @input="onInputBevandeAlcoliche($event)"
                ></b-form-select>
              </b-col>
              <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                <span class="sa-form-label">{{ this.$i18n.t("patient.fisiologica.lblAlcoholicBeveragesQuantity") }}</span>
                <b-form-select
                  class="mb-2 mr-sm-2 mb-sm-0"
                  v-model="jsonData.quantitaBevandeAlcoliche"
                  :options="quantitaBevandeAlcolicheOptions"
                  :value="null"
                  value-field="value"
                  text-field="text"
                  :disabled="quantitaBevandeAlcolicheDisabled"
                ></b-form-select>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                <span class="sa-form-label">{{ this.$i18n.t("patient.fisiologica.lblCoffee") }}</span>
                <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.caffe" :options="caffeOptions" :value="null" value-field="value" text-field="text" @input="onInputCaffe($event)"></b-form-select>
              </b-col>
              <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                <span class="sa-form-label">{{ this.$i18n.t("patient.fisiologica.lblCoffeeQuantity") }}</span>
                <b-form-select
                  class="mb-2 mr-sm-2 mb-sm-0"
                  v-model="jsonData.quantitaCaffe"
                  :options="quantitaCaffeOptions"
                  :value="null"
                  value-field="value"
                  text-field="text"
                  :disabled="quantitaCaffeDisabled"
                ></b-form-select>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </div>
    </template>
  </sa-page-layout>
</template>
<script>
import axios from "axios";
import SaPageLayout from "../../../../../template/sa/components/layout/contents/SaPageLayout.vue";
import PazienteAnagrafica from "../../../components/PazienteAnagrafica.vue";
export default {
  components: { SaPageLayout, PazienteAnagrafica },
  props: {
    utente: {
      type: Object,
      default: function() {
        return null;
      },
    },
  },
  computed: {
    idPaziente() {
      return this.$route.query.idPaziente;
    },
    linkPatient: {
      get: function() {
        let returnValue = "";
        if (this.utente.gruppo) {
          if (this.utente.gruppo.menuGruppo) {
            if (this.utente.gruppo.menuGruppo.length > 1) {
              return "/paziente/view/" + this.idPaziente;
            } else {
              if (this.utente.gruppo.menuGruppo.length == 1) {
                if (this.utente.gruppo.menuGruppo[0].primaryKey === "pazienti") {
                  return "/paziente/view/" + this.idPaziente;
                } else {
                  return "/paziente/homepaziente";
                }
              }
            }
          }
        }
        return returnValue;
      },
    },
  },
  mounted() {
    let me = this;
    me.jsonData.idPaziente = this.$route.query.idPaziente;
    me.jsonData.tipoEvento = "Gastroenterologia - Anamnesi Fisiologica";
    me.id = this.$route.params.id;
    me.linkedit = "/paziente/gastroenterologia/anamnesifisiologica/edit/" + me.id + "?idPaziente=" + me.idPaziente;
    if (me.id !== "-1") {
      me.linkback = "/paziente/gastroenterologia/anamnesifisiologica/view/" + me.id + "?idPaziente=" + me.idPaziente;
    } else {
      me.linkback = "/paziente/gastroenterologia/anamnesifisiologica?idPaziente=" + me.idPaziente;
    }
    me.loadData();
  },

  data() {
    return {
      linkback: null,
      pathResource: "/gastroenterologiaanamnesifisiologica",
      jsonData: { fumo: null, quantitaFumo: null, bevandeAlcoliche: null, quantitaBevandeAlcoliche: null, caffe: null, quantitaCaffe: null },
      quantitaFumoDisabled: false,
      quantitaBevandeAlcolicheDisabled: false,
      quantitaCaffeDisabled: false,
      showModalLoading: false,
      fumoOptions: [
        { value: null, text: "-Seleziona Valore-" },
        { value: "SI", text: "SI" },
        { value: "NO", text: "NO" },
      ],
      quantitaFumoOptions: [
        { value: null, text: "-Seleziona Valore-" },
        { text: "0 - 10/gg", value: "0 - 10/gg" },
        { text: "11 - 20/gg", value: "11 - 20/gg" },
        { text: "21 - 40/gg", value: "21 - 40/gg" },
        { text: ">40/gg", value: ">40/gg" },
      ],
      bevandeAlcolicheOptions: [
        { value: null, text: "-Seleziona Valore-" },
        { value: "SI", text: "SI" },
        { value: "NO", text: "NO" },
      ],
      quantitaBevandeAlcolicheOptions: [
        { value: null, text: "-Seleziona Valore-" },
        { text: "<1/2 Lt", value: "<1/2 Lt" },
        { text: "1/2 Lt", value: "1/2 Lt" },
        { text: "1-2 Lt", value: "1-2 Lt" },
        { text: ">2 Lt", value: ">2 Lt" },
      ],
      caffeOptions: [
        { value: null, text: "-Seleziona Valore-" },
        { value: "SI", text: "SI" },
        { value: "NO", text: "NO" },
      ],
      quantitaCaffeOptions: [
        { value: null, text: "-Seleziona Valore-" },
        { text: "1", value: "1" },
        { text: "2", value: "2" },
        { text: "3", value: "3" },
        { text: ">3", value: ">3" },
      ],
    };
  },

  methods: {
    loadData() {
      let me = this;
      if (me.id === "-1") {
        me.showModalLoading = false;
      } else {
        let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.id;
        axios.get(link).then((response) => {
          me.jsonData = response.data.data;
          me.showModalLoading = false;
        });
      }
    },
    onInputFumo(value) {
      let me = this;
      me.quantitaFumoDisabled = false;
      if (value === null || value === "NO") {
        me.jsonData.quantitaFumo = null;
        me.quantitaFumoDisabled = true;
      }
    },
    onInputBevandeAlcoliche(value) {
      let me = this;
      me.quantitaBevandeAlcolicheDisabled = false;
      if (value === null || value === "NO") {
        me.jsonData.quantitaBevandeAlcoliche = null;
        me.quantitaBevandeAlcolicheDisabled = true;
      }
    },
    onInputCaffe(value) {
      let me = this;
      me.quantitaCaffeDisabled = false;
      if (value === null || value === "NO") {
        me.jsonData.quantitaCaffe = null;
        me.quantitaCaffeDisabled = true;
      }
    },
  },
};
</script>
