<template>
    <sa-page-layout :showModalLoading="showModalLoading" :btnSaveVisible="true" :btnBackVisible="false" :linkback="linkback" :btnAnnullaVisible="true" :pathResource="pathResource" :data="jsonData">
        <template slot="toolbar-title">
            <paziente-anagrafica :linkPatient="linkPatient" :idPaziente="idPaziente"></paziente-anagrafica>
        </template>
        <template slot="toolbar">
            <firma-component :idEvento="jsonData.id" :fieldName="firmaFieldName" :data="jsonData" :pathRestFirma="pathResource" :pathRestValidation="pathRestValidation" :disabled="btnFirmaDisabled"></firma-component>
        </template>
        <template slot="table-header">
            <h3>{{ this.$i18n.t("patient.gastroenterology.lblRectumColonoscopy") }}</h3>
        </template>
        <template slot="table-body">
            <b-tabs content-class="mt-3" class="sa-tab" style="height: 100%;" fill @input="onTabInput">
                <b-tab>
                    <template #title>
                        <span :class="getColorDati()"><b-icon :icon="getIconDati()"></b-icon>&nbsp;{{ getTranslate("patient.gastroenterology.lblData") }}</span>
                    </template>
                    <div class="sa-tab-scroll">
                        <gastro-dati-colonscopia-edit :data="jsonData" :edit="edit"></gastro-dati-colonscopia-edit>
                    </div> </b-tab
                ><b-tab :title="this.$i18n.t('patient.gastroenterology.lblSuggestedTherapies')"> <div class="sa-tab-scroll"></div> </b-tab><b-tab :title="this.$i18n.t('patient.gastroenterology.lblImages')"> <div class="sa-tab-scroll"></div> </b-tab
                ><b-tab :title="this.$i18n.t('patient.gastroenterology.lblAttachments')">
                    <div class="sa-tab-scroll"></div>
                </b-tab> </b-tabs
        ></template>
    </sa-page-layout>
</template>
<script>
import axios from "axios";
import { SaPageLayout } from "../../../../../template/sa/components/layout/contents";
import PazienteAnagrafica from "../../../components/PazienteAnagrafica.vue";
import FirmaComponent from "../../components/FirmaComponent.vue";
import GastroDatiColonscopiaEdit from "./GastroDatiColonscopiaEdit.vue";
export default {
    props: {
        utente: {
            type: Object,
            default: function() {
                return null;
            },
        },
    },
    components: {
        SaPageLayout,
        PazienteAnagrafica,
        FirmaComponent,
        GastroDatiColonscopiaEdit,
    },
    computed: {
        idPaziente: {
            get: function() {
                return this.$route.query.idPaziente;
            },
            set: function() {},
        },
        linkPatient: {
            get: function() {
                if (this.utente.gruppo.menuGruppo) {
                    if (this.utente.gruppo.menuGruppo.length > 1) {
                        return "view/" + this.idPaziente;
                    } else {
                        if (this.utente.gruppo.menuGruppo.length == 1) {
                            if (this.utente.gruppo.menuGruppo[0].primaryKey === "pazienti") {
                                return "view/" + this.idPaziente;
                            } else {
                                return "homepaziente";
                            }
                        } else {
                            return "";
                        }
                    }
                } else {
                    return "";
                }
            },
        },
    },
    data() {
        return {
            id: null,
            jsonData: { firmaEo: null, firmaEcg: null, firmaGf: null },
            linkback: "",
            edit: true,
            showModalLoading: false,
            pathResource: "/gastroanorettocolonscopia",
            pathRestValidation: "",
            btnFirmaDisabled: false,
            firmaFieldName: null,
            editDati: true,
        };
    },
    created: function() {},
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        me.linkback = "/paziente/gastroenterologia/anorettocolonscopia/view/" + me.id;
        me.loadData();
    },
    methods: {
        loadData() {
            let me = this;
            me.showModalLoading = true;
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
            axios
                .get(link + me.id)
                .then((response) => {
                    me.jsonData = response.data.data;
                    me.idPaziente = me.jsonData.idPaziente;
                    me.showModalLoading = false;
                })
                .catch(() => {
                    me.showModalLoading = false;
                });
        },
        getTranslate(text) {
            return this.$i18n.t(text);
        },
        onTabInput(index) {
            let me = this;
            if (me.jsonData.firmaGf !== null) {
                me.btnFirmaDisabled = true;
                me.edit = false;
                me.editDati = false;
            } else {
                switch (index) {
                    case 0:
                        me.btnFirmaDisabled = true;
                        break;
                    case 1:
                        me.btnFirmaDisabled = true;
                        break;
                    case 2:
                        me.firmaFieldName = "firma_eo";
                        if (me.jsonData.firmaEo == null) {
                            me.btnFirmaDisabled = false;
                        } else {
                            me.btnFirmaDisabled = true;
                            me.editDati = false;
                        }
                        break;
                    case 3:
                        break;
                    case 4:
                        me.btnFirmaDisabled = true;
                        break;
                    case 5:
                        me.btnFirmaDisabled = true;
                        break;
                    case 6:
                        me.btnFirmaDisabled = true;
                        break;
                    case 7:
                        me.firmaFieldName = "firma_gf";
                        if (me.jsonData.firmaGf == null) {
                            me.btnFirmaDisabled = false;
                        } else {
                            me.btnFirmaDisabled = true;
                            // me.edit = false;
                        }
                        break;
                }
            }
        },
        getColorDati() {
            let me = this;
            let colore = me.jsonData.firmaEo != null ? "text-success" : "text-danger";
            return colore;
        },
        getIconDati() {
            let me = this;
            let icon = me.jsonData.firmaEo != null ? "check2" : "exclamation-triangle";
            return icon;
        },
    },
};
</script>
