<template>
    <sa-page-layout :btnNewVisible="false" :btnRefreshVisible="true" :showModalLoading="showModalLoading" @refresh="onRefresh" :linkedit="linkedit">
        <template slot="table-filter">
            <b-form @submit.prevent="onSubmit">
                <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                    <b-col lg="6">
                        <b-form-input v-model="filtro.cognome" type="search" id="txtRicercaCognome" placeholder="Cognome"></b-form-input>
                    </b-col>
                    <b-col lg="6">
                        <b-form-input v-model="filtro.nome" type="search" id="txtNome" placeholder="Nome"></b-form-input>
                    </b-col>
                    <b-col lg="12">
                        <b-form-input v-model="filtro.codiceFiscale" type="search" id="txtCodiceFiscale" placeholder="Codice Fiscale"></b-form-input>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="sa-padding-right text-right">
                        <b-button type="submit" variant="info">Cerca</b-button>
                        <b-button @click="onReset" variant="danger">Reset</b-button>
                    </b-col>
                </b-row>
            </b-form>
        </template>
        <template slot="table-header">
            <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col lg="3">
                    <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
                </b-col>
                <b-col lg="9">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
            </b-row>
        </template>
        <template slot="table-body">
            <div class="b-table-sticky-header">
                <b-table sticky-header ref="table" id="tblPazienti" :filter="filter" responsive stacked="lg" striped hover :items="items" :fields="fields" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light">
                    <template #cell(cognome)="{ item }">
                        <router-link class="sa-edit-link" :to="'/pazientisic/edit/' + item.id">{{ item.cognome }} {{ item.nome }}</router-link>
                    </template>
                </b-table>
            </div>
        </template>
        <template slot="table-footer">
            <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col lg="3">
                    <p>{{ this.$i18n.t("patients.lblTotalRecords") }}: {{ rows }}</p>
                </b-col>
                <b-col lg="9">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
            </b-row>
        </template>
    </sa-page-layout>
</template>
<script>
import axios from "axios";
import SaPageLayout from "../../../../template/sa/components/layout/contents/SaPageLayout.vue";
export default {
    components: { SaPageLayout },
    computed: {
        rows() {
            return this.items.length;
        },
    },
    data() {
        return {
            currentPage: 1,
            perPage: 30,
            filter: "",
            showModalLoading: false,
            linkedit: null,
            pathRest: "/pazienti",
            fields: [
                {
                    label: "Cognome",
                    key: "cognome",
                    sortable: true,
                },
                {
                    label: "Codice Fiscale",
                    key: "codiceFiscale",
                    sortable: true,
                },
            ],
            items: [],
            data: [],
            filtro: {},
        };
    },
    created: function() {},
    mounted: function() {
        let me = this;
        let sessionStorage = window.sessionStorage;
        console.log(sessionStorage);
        console.log(sessionStorage["pazientiSicFiltro"]);

        if (sessionStorage["pazientiSicFiltro"]) {
            me.filtro = JSON.parse(sessionStorage["pazientiSicFiltro"]);
            this.loadData();
        }
        //
    },
    update: function() {
        console.log("Update");
    },
    methods: {
        loadData() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathRest;
            axios
                .get(link, { params: me.filtro })
                .then((response) => {
                    me.items = [];
                    me.items = response.data.data;
                })
                .catch(function(error) {
                    me.$bvToast.toast(error.response.data.messaggio, {
                        title: "Sorveglianza Covid Admin",
                        variant: "danger",
                        solid: true,
                    });
                });
        },
        onAdd() {
            let me = this;
            me.$router.replace(me.pathRest + "/edit/-1").catch((err) => {
                err;
            });
        },
        onSubmit: function() {
            let me = this;
            let sessionStorage = window.sessionStorage;
            sessionStorage["pazientiSicFiltro"] = JSON.stringify(me.filtro);
            me.loadData();
            return false;
        },
        onRefresh() {
            let me = this;
            me.loadData();
        },
        onReset() {
            let me = this;
            me.filtro = {};
            let sessionStorage = window.sessionStorage;
            sessionStorage["pazientiSicFiltro"] = JSON.stringify(me.filtro);
            me.items = [];
        },
    },
};
</script>
