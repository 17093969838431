import saContent from './saContent.vue';
import saFooter from './saFooter.vue';
import saHeader from './saHeader.vue';
import saLayoutPage from './saLayoutPage.vue';
import saLayoutLogin from './saLayoutLogin.vue';

const components = {
  saContent,
  saFooter,
  saHeader,
  saLayoutPage,
  saLayoutLogin,
};

const VuePlugin = {
  install(Vue) {
    Object.keys(components).forEach((component) => {
      Vue.component(component, components[component]);
    });
  },
};

export default VuePlugin;
