<template>
    <div>
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <b-row>
                    <b-col cols="12" xs="12" sm="6" md="4" lg="2" xl="2">
                        <strong>Prog. Anno </strong><br />
                        <b-form-input v-model="data.progressivoAnno" readonly></b-form-input>
                    </b-col>
                    <b-col cols="12" xs="12" sm="6" md="4" lg="2" xl="2">
                        <strong>Data Visita </strong><br />
                        <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="data.dataVisita" type="timestamp" placeholder="Data Visita" disabled></date-picker>
                    </b-col>
                    <b-col cols="12" xs="12" sm="6" md="4" lg="2" xl="2">
                        <strong>Tipo Atleta </strong><br />
                        <b-form-select v-model="data.tipoAttleta" class="mb-2 mr-sm-2 mb-sm-0" :options="tipiAltetaOptions" :value="null" value-field="value" text-field="text"></b-form-select>
                    </b-col>
                    <b-col cols="12" xs="12" sm="6" md="4" lg="2" xl="2">
                        <strong>Num. Certificato </strong><br />
                        <b-form-input v-model="data.numeroCertificato"></b-form-input>
                    </b-col>
                    <b-col cols="12" xs="12" sm="6" md="4" lg="2" xl="2">
                        <strong>Validità </strong><br />
                        <b-form-input v-model="data.validitaCertificato" readonly></b-form-input>
                    </b-col>
                    <b-col cols="12" xs="12" sm="6" md="4" lg="2" xl="2">
                        <strong>Data Scadenza </strong><br />
                        <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="data.dataScadenza" type="timestamp" placeholder="Data Scadenza" :disabled="true"></date-picker>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                        <b-row>
                            <b-col cols="12" xs="12" sm="6" md="4" lg="2" xl="2">
                                <strong>Esame Urine Mancante</strong><br />
                                <b-form-checkbox v-model="data.urineMancanti" :value="returnCheckboxUrineValue(data.visitaEsameUrine.firma)" :disabled="true"></b-form-checkbox>
                            </b-col>
                            <b-col cols="12" xs="12" sm="6" md="4" lg="2" xl="2">
                                <strong>Esame Spirografia Mancante</strong><br />
                                <b-form-checkbox v-model="data.spirografiaNonEseguita" :value="returnCheckboxSpirografiaValue(data.visitaSpirografia.firma)" :disabled="true"></b-form-checkbox>
                            </b-col>
                            <b-col cols="12" xs="12" sm="6" md="4" lg="2" xl="2">
                                <strong>E.C.G. Non Eseguito</strong><br />
                                <b-form-checkbox v-model="data.ecgNonEseguito" :value="returnCheckboxEcgValue(data.visitaEcg.firma)" :disabled="true"></b-form-checkbox>
                            </b-col>
                            <b-col cols="12" xs="12" sm="6" md="4" lg="2" xl="2">
                                <strong>Provenienza </strong><br />
                                {{ data.provenienza }}
                            </b-col>
                            <b-col cols="12" xs="12" sm="6" md="4" lg="2" xl="2">
                                <strong>Sport </strong><br />
                                <b-form-select v-model="data.sport" class="mb-2 mr-sm-2 mb-sm-0" :options="tipiSportOptions" :value="null" value-field="codiceSport" text-field="sport" @input="onInputTipoSport($event)"></b-form-select>
                            </b-col>
                            <b-col cols="12" xs="12" sm="6" md="4" lg="2" xl="2">
                                <strong>Tipo Visita </strong><br />
                                <b-form-input :disabled="disabilitato" v-model="data.tipoVisita"></b-form-input>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
    </div>
</template>
<script>
import moment from "moment";
import DatePicker from "vue2-datepicker";
import axios from "axios";
export default {
    components: { DatePicker },
    props: {
        data: {
            Type: Object,
            default: function() {
                return {
                    progressivoAnno: null,
                    dataVisita: null,
                    tipoAttleta: null,
                    numeroCertificato: "",
                    validitaCertificato: null,
                    dataScadenza: null,
                    provenienza: "",
                    sport: "",
                    tipoVisita: "",
                    urineMancanti: false,
                    spirografiaNonEseguita: false,
                    ecgNonEseguito: false,
                    visitaEsameUrine: { firma: null },
                    visitaSpirografia: { firma: null },
                    visitaEcg: { firma: null },
                };
            },
        },
    },
    mounted() {
        let me = this;
        me.loadTipiSport();
    },
    data() {
        return {
            linkTipiSport: "/tipisport",
            disabilitato: false,
            tipiAltetaOptions: [
                { value: null, text: "-Seleziona Valore-" },
                { value: "AGONISTA", text: "AGONISTA" },
                { value: "NON AGONISTA", text: "NON AGONISTA" },
            ],
            tipiSportOptions: [],
        };
    },
    methods: {
        returnCheckboxUrineValue(value) {
            let me = this;
            if (value === null) {
                return (me.data.urineMancanti = true);
            }
        },
        returnCheckboxSpirografiaValue(value) {
            let me = this;
            if (value === null) {
                return (me.data.spirografiaNonEseguita = true);
            }
        },
        returnCheckboxEcgValue(value) {
            let me = this;
            if (value === null) {
                return (me.data.ecgNonEseguito = true);
            }
        },

        loadTipiSport() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkTipiSport;
            axios.get(link).then((response) => {
                me.tipiSportOptions = response.data.data;
                me.tipiSportOptions.unshift({ codiceSport: null, sport: "-Seleziona Struttura-" });
            });
        },
        onInputTipoSport(value) {
            let me = this;
            me.tipiSportOptions.forEach((sportSelezionato) => {
                if (sportSelezionato.codiceSport === value) {
                    me.data.validitaCertificato = sportSelezionato.validita;
                    me.data.tipoVisita = sportSelezionato.tipoTar;
                    let dataScadenzaCertificato = moment(Date()).add(me.data.validitaCertificato, "months");
                    me.data.dataScadenza = moment(dataScadenzaCertificato)
                        .toDate()
                        .getTime();
                }
            });
        },
    },
    watch: {
        "data.tipoAttleta": {
            immediate: true,
            handler(value) {
                let me = this;
                if (value === "AGONISTA" || value === null) {
                    me.disabilitato = true;
                } else {
                    me.disabilitato = false;
                }
            },
        },
    },
};
</script>
