<template>
    <div>
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
                        <strong>{{ this.$i18n.t("patient.afa.lblTrofism") }}</strong>
                        <br />
                        {{ jsonData.trofismo }}
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
                        <strong>{{ this.$i18n.t("patient.afa.lblBody") }}</strong>
                        <br />
                        {{ jsonData.corpo }}
                    </b-col>
                    <b-col cols="12" xs="12" sm="6" md="6" lg="4" xl="4">
                        <strong>{{ this.$i18n.t("patient.afa.lblWaist") }}</strong>
                        <br />
                        {{ jsonData.vita }}
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <hr />
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
                        <strong>{{ this.$i18n.t("patient.afa.lblWeight") }}</strong>
                        <br />
                        {{ jsonData.peso }}
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
                        <strong>{{ this.$i18n.t("patient.afa.lblHeight") }}</strong>
                        <br />
                        {{ jsonData.altezza }}
                    </b-col>
                    <b-col cols="12" xs="12" sm="6" md="6" lg="4" xl="4">
                        <strong>{{ this.$i18n.t("patient.afa.lblBMI") }}.</strong>
                        <br />
                        {{ jsonData.bmi }}
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <hr />
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
                        <strong>{{ this.$i18n.t("patient.afa.lblBSA") }}</strong>
                        <br />
                        {{ jsonData.bsa }}
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
                        <strong>{{ this.$i18n.t("patient.afa.lblGlycemia") }}</strong>
                        <br />
                        {{ jsonData.glicemia }}
                    </b-col>
                    <b-col cols="12" xs="12" sm="6" md="6" lg="4" xl="4">
                        <strong>{{ this.$i18n.t("patient.afa.lblDiabetes") }}</strong>
                        <br />
                        {{ jsonData.diabete }}
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <hr />
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
                        <strong>{{ this.$i18n.t("patient.afa.lblPAMax") }}</strong>
                        <br />
                        {{ jsonData.paMax }}
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
                        <strong>{{ this.$i18n.t("patient.afa.lblPAMin") }}</strong>
                        <br />
                        {{ jsonData.paMin }}
                    </b-col>
                    <b-col cols="12" xs="12" sm="6" md="6" lg="4" xl="4">
                        <strong>{{ this.$i18n.t("patient.afa.lblCholesterol") }}</strong>
                        <br />
                        {{ jsonData.colesterolo }}
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <hr />
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
                        <strong>{{ this.$i18n.t("patient.afa.lblBloodGroup") }}</strong>
                        <br />
                        {{ jsonData.gruppoSanguigno }}
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
                        <strong>{{ this.$i18n.t("patient.afa.lblRHFactor") }}</strong>
                        <br />
                        {{ jsonData.fattoreRh }}
                    </b-col>
                    <b-col cols="12" xs="12" sm="6" md="6" lg="4" xl="4">
                        <strong>{{ this.$i18n.t("patient.afa.lblCirculatorySystem") }}</strong>
                        <br />
                        {{ jsonData.apparatoCircolatorio }}
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <hr />
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
                        <strong>{{ this.$i18n.t("patient.afa.lblLocomotorSystem") }}</strong>
                        <br />
                        {{ jsonData.apparatoLocomotorio }}
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
                        <strong>{{ this.$i18n.t("patient.afa.lblRespiratorySystem") }}</strong>
                        <br />
                        {{ jsonData.apparatoRespiratorio }}
                    </b-col>
                    <b-col cols="12" xs="12" sm="6" md="6" lg="4" xl="4">
                        <strong>{{ this.$i18n.t("patient.afa.lblAbdomenGeneralOrgans") }}</strong>
                        <br />
                        {{ jsonData.addomeOrgGen }}
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <hr />
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
                        <strong>{{ this.$i18n.t("patient.afa.lblLimbs") }}</strong
                        ><br />
                        {{ jsonData.arti }}
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
                        <strong>{{ this.$i18n.t("patient.afa.lblNaturalSightRightEye") }}</strong
                        ><br />
                        {{ jsonData.vistaNOd }}
                    </b-col>
                    <b-col cols="12" xs="12" sm="6" md="6" lg="4" xl="4">
                        <strong>{{ this.$i18n.t("patient.afa.lblNaturalSightLeftEye") }}</strong
                        ><br />
                        {{ jsonData.vistaNOs }}
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <hr />
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
                        <strong>{{ this.$i18n.t("patient.afa.lblCurrentSightRightEye") }}</strong
                        ><br />
                        {{ jsonData.vistaCOd }}
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
                        <strong>{{ this.$i18n.t("patient.afa.lblCurrentSightLeftEye") }}</strong
                        ><br />
                        {{ jsonData.vistaCOs }}
                    </b-col>
                    <b-col cols="12" xs="12" sm="6" md="6" lg="4" xl="4">
                        <strong>{{ this.$i18n.t("patient.afa.lblCromaticSense") }}</strong
                        ><br />
                        {{ jsonData.sensoCromatico }}
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <hr />
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
                        <strong>{{ this.$i18n.t("patient.afa.lblHearingSense") }}</strong
                        ><br />
                        {{ jsonData.udito }}
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
                        <strong>{{ this.$i18n.t("patient.afa.lblExaminationConclusion") }}</strong
                        ><br />
                        {{ jsonData.conclusioniEsObiettivo }}
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <hr />
        <b-row>
            <b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <strong>Stato</strong><br />
                    {{ jsonData.firmaEo }}
                </b-col>
            </b-col>
        </b-row>
        <hr />
    </div>
</template>
<script>
export default {
    props: {
        jsonData: {
            Type: Object,
            require: true,
        },
    },
};
</script>
