import TamponiList from "./components/TamponiList.vue";
import TamponiView from "./components/TamponiView.vue";
import TamponiEdit from "./components/TamponiEdit.vue";
import FileProtezioneCivile from "./components/FileProtezioneCivile.vue";

export default {
  TamponiList,
  TamponiView,
  TamponiEdit,
  FileProtezioneCivile
};
