<template>
    <sa-page-list :titolo="titolo" :showmolalloading="showmolalloading">
        <template slot="toolbar">
            <b-button pill class="float-sm-right sa-btn-header sa-btn-icon-text" variant="light sa-margin-right" size="sm" @click="onAdd"><b-icon icon="plus" variant="secondary"></b-icon>Nuovo</b-button>
            <b-button pill class="float-sm-right sa-btn-header sa-btn-icon-text" variant="light sa-margin-right" size="sm" @click="onRefresh" v-b-modal.modal-center><b-icon icon="arrow-clockwise" variant="secondary" />Refresh</b-button>
        </template>
        <template slot="table-filter">
            <b-row>
                <b-col cols="12" sm="12" class="my-1">
                    <b-form-group label="" label-cols-sm="0" label-align-sm="right" label-size="sm" label-for="filterInput" class="mb-0">
                        <b-input-group size="sm">
                            <b-form-input v-model="filter" type="search" id="filterInput" placeholder="Username"></b-form-input>
                            <b-input-group-append>
                                <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                </b-col>
            </b-row>
        </template>
        <template slot="table-header">
            <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col cols="6">
                    <p>Totale Record: {{ rows }}</p>
                </b-col>
                <b-col cols="6">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
            </b-row>
        </template>
        <template slot="table-body">
            <div class="b-table-sticky-header">
                <!--<b-table  ref="table" id="tbl" :filter="filter" responsive stacked="lg" striped hover :items="items" :fields="fields" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table" sticky-header>-->
                <b-table ref="table" id="tblUtenti" :filter="filter" :filterIncludedFields="filterOn" stacked="lg" striped hover :items="items" :fields="fields" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light">
                    <template v-slot:cell(gruppo)="{ item }">
                        <router-link class="sa-edit-link" :to="'/gruppi/view/' + item.gruppo.id">{{ item.gruppo.nome }}</router-link>
                    </template>
                    <template v-slot:cell(username)="{ item }">
                        <router-link class="sa-edit-link" :to="'/users/edit/' + item.id">{{ item.username }}</router-link>
                    </template>
                    <template v-slot:cell(actions)="row">
                        <b-container class="bv-example-row">
                            <b-row>
                                <b-col>
                                    <b-button size="sm" variant="outline-danger" @click="onDelete(row.item, row.index, $event.target)" class="mr-1 no-text">
                                        <b-icon icon="trash" variant="danger"></b-icon>
                                    </b-button>
                                </b-col>
                                <b-col> <b-form-checkbox v-model="row.item.attivo" name="check-button" switch size="lg" @change="onAttivaChange(row.item)"></b-form-checkbox></b-col>
                            </b-row>
                        </b-container>
                    </template>
                </b-table>
            </div>
        </template>
        <template slot="table-footer">
            <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col cols="6">
                    <p>Totale Record: {{ rows }}</p>
                </b-col>
                <b-col cols="6">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
            </b-row>
        </template>
    </sa-page-list>
</template>
<script>
import axios from "axios";
import { SaPageList } from "../../../template/sa/components/layout/contents";
export default {
    components: { SaPageList },
    computed: {
        rows() {
            return this.items.length;
        },
    },
    data() {
        return {
            titolo: "Lista Utenti",
            showmolalloading: false,
            perPage: 10,
            currentPage: 1,
            filtro: {},
            filter: null,
            filterOn: [],
            fields: [
                {
                    label: "Gruppo",
                    key: "gruppo",
                    sortable: true,
                },
                {
                    label: "Profilo",
                    key: "profilo",
                    sortable: true,
                },
                {
                    label: "Cognome",
                    key: "lastname",
                    sortable: false,
                },
                {
                    label: "Nome",
                    key: "firstname",
                    sortable: false,
                },
                {
                    label: "Username",
                    key: "username",
                    sortable: false,
                },
                { class: "sa-table-column-action-double", key: "actions", label: "" },
            ],
            items: [],
        };
    },
    created: function() {
        this.fetchItems();
    },
    methods: {
        fetchItems() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + "/users";
            axios.get(link).then((response) => {
                me.items = response.data.data;
            });
        },
        onAdd() {
            let me = this;
            me.$router.replace("/users/edit/-1").catch((err) => {
                err;
            });
        },
        onRefresh() {},
        onDelete(item) {
            let me = this;
            this.$bvModal
                .msgBoxConfirm("Sicuro di voler Eliminare il gruppo " + item.gruppo.nome)
                .then((value) => {
                    if (value) {
                        let link = process.env.VUE_APP_PATH_API + "/users/";
                        axios.delete(link + item.id).then(() => {
                            me.fetchItems();
                        });
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        onAttivaChange(item) {
            let link = process.env.VUE_APP_PATH_API + "/users/attiva/";
            axios
                .put(link + item.id)
                .then(() => {
                    this.$bvToast.toast("Operazione effettuata con successo", {
                        variant: "success",
                        title: "Utenti",
                        autoHideDelay: 3000,
                        solid: true,
                        noCloseButton: true,
                    });
                })
                .catch((error) => {
                    this.$bvToast.toast("Errore nell'attivare l'utente: " + error.response.data.messaggio, {
                        variant: "danger",
                        title: "Utenti",
                        autoHideDelay: 3000,
                        solid: true,
                        noCloseButton: true,
                    });
                });
        },
    },
};
</script>
