<template>
    <div class="sa-page-paziente">
        <div class="sa-page-paziente-content">
            <b-row>
                <b-col cols="12" xs="12" sm="8" md="8" lg="8" xl="8">
                    <b-card class="mb-8">
                        <div class="bg-picture card-box">
                            <div class="profile-info-name">
                                <div class="container-fluid">
                                    <b-row>
                                        <b-col>
                                            <b-row>
                                                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                                    <div style="height: 100%; display: flex; align-items: center;">
                                                        <div style="width: 6rem; height: 6rem; float: left;"><img :src="patientAvatar" class="rounded-circle avatar-xl img-thumbnail float-left mr-3" alt="profile-image" /></div>
                                                        <div style="margin-left: 10px;">
                                                            <h4 class="m-0">{{ jsonData.cognome }} {{ jsonData.nome }}</h4>
                                                            <p class="text-muted">
                                                                <i>{{ jsonData.codiceFiscale }}</i>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </b-col>
                                            </b-row>
                                        </b-col>
                                        <b-col class="text-right">
                                            <b-button v-if="btnEditAnagrafica" variant="purple btn-generic btn-notext sa-margin-right" size="sm" @click="onEdit"><b-icon icon="pencil"></b-icon></b-button>
                                            <b-button variant="purple btn-generic btn-notext sa-margin-right" size="sm" v-b-toggle.info-1><b-icon icon="info" scale="2"></b-icon></b-button>
                                        </b-col>
                                    </b-row>
                                </div>
                                <hr />
                                <div class="container-fluid">
                                    <b-collapse id="info-1" role="tabpanel">
                                        <div class="row">
                                            <div class="col">
                                                <strong>CF</strong><br />
                                                {{ jsonData.identificativo }}
                                            </div>
                                            <div class="col">
                                                <strong>Sesso</strong><br />
                                                <i class="fas fa-venus rosa font-18"></i> {{ jsonData.sesso }}
                                            </div>
                                            <div class="col">
                                                <strong><i class="fas fa-tint rosso"></i> Gruppo S.</strong><br />
                                                {{ jsonData.gruppoSanguigno }}
                                            </div>
                                        </div>
                                        <hr />
                                        <div class="row">
                                            <div class="col">
                                                <strong>Nata a </strong><br />
                                                {{ jsonData.comuneNascita }} ({{ jsonData.provinciaNascita }})
                                            </div>
                                            <div class="col">
                                                <strong>Il </strong><br />
                                                {{ formatDate(jsonData.jsonDataNascita) }}
                                            </div>
                                            <div class="col">
                                                <strong>Residenza </strong><br />
                                                {{ jsonData.indirizzoResidenza }} {{ jsonData.capResidenza }} {{ jsonData.comuneResidenza }} ({{ jsonData.provinciaResidenza }})
                                            </div>
                                        </div>
                                        <hr />
                                        <div class="row">
                                            <div class="col">
                                                <strong>Domicilio </strong><br />
                                                {{ jsonData.indirizzoDomicilio }} {{ jsonData.capDomicilio }} {{ jsonData.comuneDomicilio }} ({{ jsonData.provinciaDomicilio }})
                                            </div>
                                            <div class="col">
                                                <strong>Telefono </strong><br />
                                                {{ jsonData.telefono }}
                                            </div>
                                            <div class="col">
                                                <strong>E-Mail </strong><br />
                                                {{ jsonData.email }}
                                            </div>
                                        </div>
                                        <hr
                                    /></b-collapse>
                                </div>
                                <div class="clearfix"></div>
                            </div>
                        </div>
                    </b-card>
                    <b-card>
                        <div class="card-box">
                            <div class="media mb-2">
                                <div class="media-body">
                                    <h5 class="mt-0">
                                        <span class="text-dark"><i class=" fas fa-capsules"></i>Sintomi</span>
                                    </h5>
                                    <ul class="list-unstyled mb-3">
                                        <b-row>
                                            <li v-for="item in sintomiOptions" :key="item.id">
                                                <a href="#" onclick="return false" :id="item.id">
                                                    <b-badge style="margin-right: 5px" :class="getColoreSintomi(item.descrizione)">{{ item.descrizione }}</b-badge>
                                                </a>
                                                <b-popover :target="item.id" title="Evoluzione Sintomi" triggers="focus">
                                                    <template>
                                                        <p v-for="stato in pazientiSintomiOptions" :key="stato.id">
                                                            <span v-if="stato.sintomi == item.descrizione">
                                                                <span :class="getColoreStato(stato.stato)">
                                                                    {{ stato.stato }}
                                                                </span>
                                                                <span>
                                                                    {{ formatDateTime(stato.statoDataOra) }}
                                                                </span>
                                                                <hr />
                                                            </span>
                                                        </p>
                                                    </template>
                                                </b-popover>
                                            </li>
                                        </b-row>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </b-card>
                </b-col>
                <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                    <b-card class="scroll mb-4">
                        <h4 class="header-title mt-0 mb-3"><i class="mdi mdi-notification-clear-all mr-1"></i>Tamponi</h4>
                        <ul class="timeline2" v-for="item in tamponiSinfonia" :key="item.id">
                            <li class="event">
                                <span v-if="item.risultato === '9'" class="mdi mdi-circle pal" style="background: red;"></span>
                                <span v-else-if="item.risultato === '10'" class="mdi mdi-circle pal" style="background: green;"></span>
                                <span v-else-if="item.risultato === '11'" class="mdi mdi-circle pal" style="background: grey;"></span>
                                <span style="font-size: 0.95rem" :class="getColoreEsitoTampone(item.risultato)">{{ formatText(item.risultato) }}</span> <br />
                                <span class="sa-timeline-date">{{ formatDateTime(item.dataPrelievo) }}</span> <br />
                                <span>{{ item.tipologiaTampone }}</span> <br />
                                <span>{{ item.laboratorioDiDestinazione }}</span> <br />
                            </li>
                        </ul>
                    </b-card>
                </b-col>
            </b-row>
        </div>
    </div>
</template>
<script>
import axios from "axios";
import moment from "moment";
import female from "@/assets/images/female.jpg";
import male from "@/assets/images/male.jpg";
import UtilityMixin from "../../../utility/UtilityMixin";
export default {
    mixins: [UtilityMixin],
    props: {
        idPaziente: String,
        utente: {
            required: true,
            type: Object,
            default: function() {
                return null;
            },
        },
        jsonData: {
            type: Object,
            required: true,
            default: function() {
                return {
                    identificativo: null,
                    tipoIdentificativo: null,
                    cognome: null,
                    nome: null,
                    dataNascita: null,
                    comuneNascitaCodiceIstat: null,
                    comuneResidenzaCodiceIstat: null,
                    comuneDomicilioCodiceIstat: null,
                    indirizzoResidenza: null,
                    indirizzoDomicilio: null,
                    note: null,
                    medicoCurante: null,
                    codiceFiscaleMedicoCurante: null,
                    capDomicilio: null,
                    capResidenza: null,
                    telefono: null,
                    email: null,
                    sesso: null,
                    codiceStruttura: null,
                    comuneResidenza: null,
                    comuneDomicilio: null,
                    comuneNascita: null,
                    provinciaResidenza: null,
                    provinciaDomicilio: null,
                    provinciaNascita: null,
                };
            },
        },
    },
    computed: {
        patientAvatar: {
            get: function() {
                let me = this;
                if (me.jsonData.sesso === "M") {
                    return male;
                } else {
                    return female;
                }
            },
        },
        btnEditAnagrafica: {
            get: function() {
                let me = this;
                return UtilityMixin.methods.verificaPermessi(me.utente, "pazienti", 4);
            },
        },
    },
    mounted() {
        let me = this;
        me.loadTamponi();
        me.loadPazienteSintomi();
        me.loadSintomi();
    },
    data() {
        return {
            pathResource: "/pazienti",
            linkedit: "/pazientisic",
            pathResourceTamponi: "/tamponisinfonia",
            pathResourcePazienteSintomi: "/pazientisicsintomi",
            pathResourceSintomi: "/sintomi",
            tamponiSinfonia: [],
            sintomiOptions: [],
            pazientiSintomiOptions: [],
            jsonDataTamponi: {},
            stati: [],
        };
    },
    methods: {
        formatDate(value) {
            if (value) {
                return moment(new Date(value)).format("DD-MM-YYYY");
            } else {
                return "---";
            }
        },
        formatDateTime(value) {
            if (value) {
                return moment(new Date(value)).format("DD-MM-YYYY HH:mm");
            } else {
                return "---";
            }
        },
        loadTamponi() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceTamponi + "?codiceFiscale=" + me.jsonData.identificativo;
            axios
                .get(link)
                .then((response) => {
                    me.tamponiSinfonia = response.data.data;
                })
                .catch((e) => {
                    console.log(e);
                    //this.errors.push(e);
                });
        },
        getColoreEsitoTampone(value) {
            // let me = this;
            let colore = null;
            if (value === "9") {
                colore = "text-danger";
            } else if (value === "10") {
                colore = "text-success";
            } else if (value === "11") {
                colore = "text-secondary";
            }
            return colore;
        },
        onEdit: function() {
            let me = this;
            me.$router.replace(me.linkedit + "/edit/" + me.idPaziente).catch((err) => {
                err;
            });
        },
        loadPazienteSintomi() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourcePazienteSintomi + "?idPaziente=" + me.idPaziente;
            axios
                .get(link)
                .then((response) => {
                    me.pazientiSintomiOptions = response.data.data;
                    me.pazientiSintomiOptions.forEach((element) => {
                        if (!me.stati[element.sintomi]) {
                            me.stati[element.sintomi] = [];
                        }

                        me.stati[element.sintomi].push(element);
                    });
                })
                .catch((e) => {
                    console.log(e);
                    //this.errors.push(e);
                });
        },
        loadSintomi() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceSintomi;
            axios
                .get(link)
                .then((response) => {
                    me.sintomiOptions = response.data.data;
                })
                .catch((e) => {
                    console.log(e);
                    //this.errors.push(e);
                });
        },
        getColoreSintomi(value) {
            let me = this;
            let colore = null;
            if (value) {
                me.pazientiSintomiOptions.forEach((element) => {
                    if (element.sintomi === value) {
                        if (element.stato === "NON RILEVATO") {
                            colore = colore == null ? "bg-secondary" : colore;
                        } else if (element.stato === "ASSENTE") {
                            colore = colore == null ? "bg-success" : colore;
                        } else if (element.stato === "LIEVE") {
                            colore = colore == null ? "bg-warning" : colore;
                        } else if (element.stato === "MODERATO") {
                            colore = colore == null ? "bg-orange" : colore;
                        } else {
                            colore = colore == null ? "bg-danger" : colore;
                        }
                    }
                });
            }
            return colore;
        },
        getColoreStato(value) {
            let me = this;
            let colore = null;
            if (value) {
                me.pazientiSintomiOptions.forEach((element) => {
                    if (element.stato === value) {
                        if (element.stato === "NON RILEVATO") {
                            colore = "text-secondary";
                        } else if (element.stato === "ASSENTE") {
                            colore = "text-success";
                        } else if (element.stato === "LIEVE") {
                            colore = "text-warning";
                        } else if (element.stato === "MODERATO") {
                            colore = "text-orange";
                        } else {
                            colore = "text-danger";
                        }
                    }
                });
            }
            return colore;
        },
        id() {
            let me = this;
            console.log(me.index);
            return me.index;
        },
        formatText(value) {
            if (value === "9") {
                value = "POSITIVO";
            } else if (value === "10") {
                value = "NEGATIVO";
            } else {
                value = "NON RILEVATO";
            }
            return value;
        },
    },
};
</script>
<style scoped>
.badge {
    font-size: 100%;
    padding: 0.5rem;
}
</style>
