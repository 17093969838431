<template>
    <sa-page-list :titolo="titolo" :showmolalloading="showmolalloading">
        <template slot="toolbar">
            <b-button pill class="float-sm-right sa-btn-header sa-btn-icon-text" variant="light sa-margin-right" size="sm" @click="onRefresh" v-b-modal.modal-center><b-icon icon="arrow-clockwise" variant="secondary" />Refresh</b-button>
        </template>
        <template slot="table-filter">
            <b-form @submit.prevent="onSubmit">
                <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                    <b-col sm="6">
                        <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="filtro.dataDal" type="datetime" placeholder="Data Dal"></date-picker>
                    </b-col>
                    <b-col sm="6">
                        <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="filtro.dataAl" type="datetime" placeholder="Data Al"></date-picker>
                    </b-col>
                </b-row>
                <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                    <b-col sm="6">
                        <b-form-input v-model="filtro.codiceFiscale" type="search" id="txtCodiceFiscale" placeholder="Codice Fiscale"></b-form-input>
                    </b-col>
                    <b-col sm="6">
                        <b-form-select v-model="filtro.stato" :options="options"></b-form-select>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="sa-padding-right text-right">
                        <b-button type="submit" variant="info">Cerca</b-button>
                    </b-col>
                </b-row>
            </b-form>
            <div>
                <b-row class="sa-text-bold">Legenda</b-row>
                <b-row>
                    <b-button variant="info" class="m-2"
                        >9: <b-badge variant="danger"> Positivo<span class="sr-only">unread messages</span></b-badge></b-button
                    >
                    <b-button variant="info" class="m-2"
                        >10: <b-badge variant="success"> Negativo<span class="sr-only">unread messages</span></b-badge></b-button
                    >
                    <b-button variant="info" class="m-2"
                        >11: <b-badge variant="secondary"> Non valido<span class="sr-only">unread messages</span></b-badge></b-button
                    >
                </b-row>
            </div>
        </template>
        <template slot="table-header">
            <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col sm="12" lg="12" class="my-1 text-right">
                    <b-button variant="light sa-margin-left" size="sm" @click="onAdd"><b-icon icon="plus" variant="secondary"></b-icon>&nbsp;Nuovo</b-button>
                </b-col>
            </b-row>
            <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col lg="3">
                    <p>Totale Record: {{ rows }}</p>
                </b-col>
                <b-col lg="9">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
            </b-row>
        </template>
        <template slot="table-body">
            <div class="b-table-sticky-header">
                <b-table sticky-header ref="table" :filter="filter" responsive stacked="lg" striped hover :items="items" :fields="fields" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                    <template v-slot:cell(codiceFiscale)="{ item }">
                        <router-link class="sa-edit-link" :to="'/tamponi/view/' + item.id">{{ item.codiceFiscale }}</router-link>
                    </template>
                    <template class="text-center" v-slot:cell(actions)="row">
                        <b-button :disabled="disabledAccept(row.item.stato != 'INVIATO')" size="sm" class="mr-1" variant="outline-secondary" @click="onInviaDati(row.item, row.index, $event.target)">
                            <font-awesome-icon size="lg" icon="paper-plane" tabindex="-1" />
                            Invia Sinfonia
                        </b-button>
                    </template>
                </b-table>
            </div>
        </template>
        <template slot="table-footer">
            <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col lg="3">
                    <p>Totale Record: {{ rows }}</p>
                </b-col>
                <b-col lg="9">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
            </b-row>
        </template>
    </sa-page-list>
</template>
<script>
import axios from "axios";
import moment from "moment";
import DatePicker from "vue2-datepicker";
import { SaPageList } from "../../../template/sa/components/layout/contents";
export default {
    components: { SaPageList, DatePicker },
    computed: {
        rows() {
            return this.items.length;
        },
    },
    data() {
        return {
            titolo: "Lista Tamponi",
            showmolalloading: false,
            perPage: 100,
            pagelink: "/tamponi",
            pathResource: "/tamponi",
            currentPage: 1,
            filter: null,
            filtro: {},
            filterOn: [],
            options: [
                { value: null, text: "Stato" },
                { value: "ERROR", text: "ERROR" },
                { value: "INVIATO", text: "INVIATO" },
            ],
            fields: [
                {
                    label: "Codice Fiscale",
                    key: "codiceFiscale",
                    sortable: true,
                },
                {
                    label: "Cognome",
                    key: "cognome",
                    sortable: true,
                },
                {
                    label: "Nome",
                    key: "nome",
                    sortable: true,
                },
                {
                    label: "Risultato",
                    key: "risultato",
                    sortable: true,
                },
                {
                    label: "Data",
                    key: "dataPrestazione",
                    sortable: true,
                    formatter: (value) => {
                        return moment(value).format("DD-MM-YYYY");
                    },
                },
                {
                    label: "Stato",
                    key: "statoAttuale",
                    sortable: true,
                    thStyle: "width: 10rem",
                },
                { key: "actions", label: "", thStyle: "width: 10rem", tdClass: "text-center" },
            ],
            items: [],
        };
    },
    created: function() {
        let me = this;
        let sessionStorage = window.sessionStorage;
        me.filtro = sessionStorage.getItem("filtro") == null ? {} : JSON.parse(sessionStorage.getItem("filtro"));
        me.loadData({ codiceFiscale: "", stato: "" });
    },
    methods: {
        disabledAccept(value) {
            if (value) {
                return false;
            } else {
                return true;
            }
        },
        loadData() {
            let me = this;
            me.showmolalloading = true;
            let link = process.env.VUE_APP_PATH_API + me.pathResource;
            axios
                .get(link, { params: me.filtro })
                .then((response) => {
                    me.items = response.data.data;
                    me.showmolalloading = false;
                    for (let element in me.items) {
                        if (me.items[element]["statoAttuale"] === "ERROR") {
                            me.items[element]["_cellVariants"] = { statoAttuale: "danger" };
                        } else if (me.items[element]["statoAttuale"] === "INVIATO") {
                            me.items[element]["_cellVariants"] = { statoAttuale: "success" };
                        } else {
                            me.items[element]["_cellVariants"] = {};
                        }
                    }
                })
                .catch(() => {
                    me.showmolalloading = false;
                });
        },
        onAdd() {
            let me = this;
            me.$router.replace(me.pathRESTRequest + "/edit/-1").catch((err) => {
                err;
            });
        },
        onSubmit() {
            let me = this;
            let sessionStorage = window.sessionStorage;
            sessionStorage["filtro"] = JSON.stringify(me.filtro);
            me.loadData();
        },
        onRefresh() {
            let me = this;
            me.loadData();
        },
        onInviaDati(items) {
            let me = this;
            me.showmolalloading = true;
            let link = process.env.VUE_APP_PATH_API + "/tamponi/inviasinfonia/" + items.id;
            axios
                .put(link)
                .then(() => {
                    me.loadData();
                    me.showmolalloading = false;
                })
                .catch((error) => {
                    me.showmolalloading = false;
                    this.$bvToast.toast(error.response.statusText + " (" + error.response.status + ") ", {
                        title: "Error",
                        variant: "danger",
                        solid: true,
                        toaster: "b-toaster-bottom-center",
                    });
                });
        },
    },
};
</script>
