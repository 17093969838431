<template>
  <div class="sa-page">
    <b-navbar toggleable="xl" type="light" variant="light">
      <b-navbar-brand href="#" @click="showDatePicker = !showDatePicker">{{ subTitle }}, {{ numeroDisponibilita }}</b-navbar-brand>
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <!-- Modal Calendario -->
      <b-modal id="modal-calendar" v-model="showDatePicker" @show="onShowDatePicker" @ok="onCambioData" :title="this.$i18n.t('agenda.lblChangeDate')">
        <div>
          <b-row>
            <b-col cols="12">
              <b-card style="width: fit-content">
                <b-calendar style="width: fit-content" class="border rounded p-2" selected-variant="info" today-variant="info" nav-button-variant="info" v-model="dataSelezionata"></b-calendar>
              </b-card>
            </b-col>
          </b-row>
        </div>
      </b-modal>

      <b-collapse id="nav-collapse" is-nav>
        <!-- Right aligned nav items -->
        <!-- <b-navbar-nav class="ml-auto"> -->
        <b-col cols="12" xs="12" sm="12" md="12" lg="5" xl="5">
          <b-input-group :prepend="this.$i18n.t('agenda.lblPerformances')">
            <b-form-tags v-model="tags" tag-class="sa-btag-color" :disabled="disabilitato" placeholder="" no-add-on-enter no-outer-focus style="width: auto"></b-form-tags>
            <b-input-group-append>
              <b-button class="sa-btn-tags" @click="modalShow = !modalShow" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Aggiungi cod.Prestazione"><b-icon icon="bookmark-plus"></b-icon></b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>
        <!-- Button Group -->
        <b-col cols="4" xs="12" sm="12" md="12" lg="4" xl="4">
          <b-button-group>
            <b-button :variant="btnAgendaVariant" class="sa-base-color" @click="onAgenda">{{ this.$i18n.t("agendas.lblDiary") }}</b-button>
            <b-button :variant="btnGiornoVariant" class="sa-base-color" @click="onGiorno">{{ this.$i18n.t("patient.agenda.lblDay") }}</b-button>
            <b-button :variant="btnSettimanaVariant" class="sa-base-color" @click="onSettimana">{{ this.$i18n.t("patient.agenda.lblWeek") }}</b-button>
            <b-button :variant="btnMeseVariant" class="sa-base-color" @click="onMese">{{ this.$i18n.t("patient.agenda.lblMonth") }}</b-button>
            <b-button :variant="btnElencoAppVariant" class="sa-base-color" @click="onElencoApp">{{ this.$i18n.t("agenda.btnAppointmentList") }}</b-button>
          </b-button-group>
        </b-col>
        <b-col>
          <b-button variant="secondary btn-toolbar sa-margin-right float-sm-right" size="sm" @click="onBack"> <b-icon icon="chevron-left"></b-icon>{{ this.$i18n.t("global.lblBtnBack") }} </b-button>
          <b-button class="float-sm-right" variant="info btn-toolbar" size="sm" @click="onRefresh"> <b-icon icon="arrow-clockwise" variant="secondary" />{{ this.$i18n.t("global.lblBtnRefresh") }} </b-button>
        </b-col>
        <!-- </b-navbar-nav> -->
      </b-collapse>
    </b-navbar>
    <div class="content">
      <b-carousel ref="crsCalendar" class="sa-carousel-inner-max-height" :interval="0">
        <!--PRIMA SLIDE AGENDA-->
        <b-carousel-slide id="crsSlideAgenda">
          <template slot="img">
            <b-card title="Card Title" class="sc-card-max-height" no-body>
              <b-card-body class="text-center">
                <agenda
                  :descrizionePrestazione="descrizionePrestazione"
                  :idAgenda="idAgenda"
                  :costo="costo"
                  :codicePrestazione="codicePrestazione"
                  v-if="disponibilita"
                  :disponibilita="disponibilita"
                  @update="loadData"
                ></agenda>
                <div v-else>{{ this.$i18n.t("patient.agenda.lbl.NoDisponibility") }}</div>
              </b-card-body>
            </b-card>
          </template>
        </b-carousel-slide>

        <!--SECONDA SLIDE DAY-->
        <b-carousel-slide>
          <template slot="img">
            <b-card title="Card Title" no-body>
              <b-card-header header-tag="nav">
                <span class="sa-riferimento-data">{{ giornoRiferimento }}</span>
              </b-card-header>
              <b-card-body>
                <agenda-day
                  :idAgenda="idAgenda"
                  :codicePrestazione="codicePrestazione"
                  :descrizionePrestazione="descrizionePrestazione"
                  :costo="costo"
                  :dataRiferimento="riferimento"
                  v-if="disponibilita"
                  :disponibilita="disponibilita"
                  @update="updateData"
                ></agenda-day>
                <div v-else>{{ this.$i18n.t("patient.agenda.lbl.NoDisponibility") }}</div>
              </b-card-body>
            </b-card>
          </template>
        </b-carousel-slide>

        <!-- AGENDA WEEK-->
        <b-carousel-slide>
          <template slot="img">
            <b-card title="Card Title" class="sc-card-max-height" no-body>
              <b-card-header header-tag="nav"
                ><span class="sa-settimana">{{ this.$i18n.t("patient.agenda.lblWeek") }}</span>
              </b-card-header>

              <b-card-body class="text-center">
                <agenda-week
                  :idAgenda="idAgenda"
                  :descrizionePrestazione="descrizionePrestazione"
                  :costo="costo"
                  :codicePrestazione="codicePrestazione"
                  v-if="disponibilita"
                  :disponibilita="disponibilita"
                  @update="updateData"
                ></agenda-week>
                <div v-else>{{ this.$i18n.t("patient.agenda.lbl.NoDisponibility") }}</div>
              </b-card-body>
            </b-card>
          </template>
        </b-carousel-slide>

        <!-- AGENDA MONTH-->
        <b-carousel-slide>
          <template slot="img">
            <b-card class="sc-card-max-height" no-body>
              <b-card-body class="text-center">
                <month @onDayClick="onDayClick"></month>
              </b-card-body>
            </b-card>
          </template>
        </b-carousel-slide>

        <!-- SLIDE ELENCO APPUNTAMENTI -->
        <b-carousel-slide>
          <template slot="img">
            <b-card title="Card Title" class="sc-card-max-height" no-body>
              <b-card-header header-tag="nav"
                ><span class="sa-settimana">{{ this.$i18n.t("agenda.btnAppointmentList") }}</span>
              </b-card-header>
              <b-card class="sc-card-max-height" no-body>
                <b-card-body class="text-center">
                  <agenda-elenco-appuntamenti :idAgenda="idAgenda" :periodo="periodo" :dataRiferimento="riferimento"></agenda-elenco-appuntamenti>
                </b-card-body>
              </b-card>
            </b-card>
          </template>
        </b-carousel-slide>
      </b-carousel>
    </div>
    <!-- Modal Cod. Prestazioni -->
    <b-modal v-model="modalShow" size="lg" :title="this.$i18n.t('patient.agenda.lblPerformanceArchive')">
      <div class="modal-body">
        <b-form @submit.prevent="onSubmit">
          <b-row>
            <b-input-group size="sm">
              <b-form-input id="filter-input" v-model="filter" type="search" :placeholder="this.$i18n.t('agenda.lblTypeToSeacrh')"></b-form-input>

              <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''">{{ this.$i18n.t("agenda.lblClear") }}</b-button>
              </b-input-group-append>
            </b-input-group>
          </b-row>
        </b-form>
        <div style="height: 10px"></div>
        <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
          <b-col lg="3">
            <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
          </b-col>
          <b-col lg="9">
            <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
          </b-col>
        </b-row>
        <b-table
          sticky-header
          ref="tblNomenclatore"
          :filter="filter"
          responsive
          stacked="lg"
          striped
          hover
          :items="nomenclatore"
          :fields="fieldsNomenclatore"
          :current-page="currentPage"
          :per-page="perPage"
          sort-icon-left
          head-variant="light"
          class="sa-b-table"
        >
          <template v-slot:cell(descrizionePrestazione)="{ item }">
            <a>
              <strong>{{ onTraduci("patient.agenda.lblCost") }} </strong>
              <i>{{ item.costo }} €</i> <br />
              <hr />
              {{ item.descrizionePrestazione }}
              <br />
              <hr />
              <strong>Cod. </strong> {{ item.codicePrestazione }}
              <hr />
              <strong>{{ onTraduci("agenda.lblDuration") }} </strong>{{ item.durata }} {{ onTraduci("agenda.lblMinutes") }}
            </a>
          </template>
          <template v-slot:cell(aggiungi)="row">
            <b-button size="sm" class="mr-1 no-text" variant="outline-info" @click="onAggiungi(row.item, row.index, $event.target)">
              <b-icon icon="plus-circle" variant="outline-primary" />
            </b-button>
          </template>
        </b-table>
      </div>
    </b-modal>
    <sa-modal-loading ref="sa-modal-loading" />
  </div>
</template>
<script>
import moment from "moment";
import Agenda from "./Agenda.vue";
import AgendaDay from "./AgendaDay.vue";
import AgendaWeek from "./AgendaWeek.vue";
import Month from "./Month.vue";
import AgendaElencoAppuntamenti from "./AgendaElencoAppuntamenti.vue";
import axios from "axios";
import { BCalendar } from "bootstrap-vue";
import { SaModalLoading } from "../../../components/modal";

require("moment/locale/it");

moment.locale("it");
export default {
  components: {
    Agenda,
    AgendaDay,
    AgendaWeek,
    Month,
    BCalendar,
    SaModalLoading,
    AgendaElencoAppuntamenti,
  },
  data() {
    return {
      pathRest: "/agendadisponibilita",
      pathNomenclatore: "/nomenclatore",
      pathRestPrestazioni: "/agendaprestazioni",
      btnAgendaVariant: "primary",
      btnGiornoVariant: "outline-primary",
      btnSettimanaVariant: "outline-primary",
      btnMeseVariant: "outline-primary",
      btnElencoAppVariant: "outline-primary",
      subTitle: "",
      riferimento: "",
      periodo: "",
      pagelink: "/agende",
      codicePrestazione: null,
      descrizionePrestazione: "",
      disponibilita: [],
      fields: [],
      slotDisponibili: 0,
      giornoRiferimento: "",
      tags: [],
      modalShow: false,
      nomenclatore: [],
      fieldsNomenclatore: [
        {
          label: this.$i18n.t("patient.agenda.lblDescriptionPerformance"),
          key: "descrizionePrestazione",
          sortable: true,
        },
        {
          label: this.$i18n.t("patient.diet.lblNote"),
          key: "nota",
          sortable: true,
        },
        {
          label: "",
          key: "aggiungi",
        },
      ],
      currentPage: 1,
      filter: null,
      perPage: 3,
      filtro: {
        descrizionePrestazione: "",
        codicePrestazione: null,
        codicePrestazioneAgg: null,
      },
      showModalLoading: false,
      data: {
        accettazionePrestazioni: [],
      },
      showDatePicker: false,
      disabilitato: true,
      cambioData: null,
      costo: null,
      idAgenda: null,
      dataSelezionata: null,
    };
  },
  created: function() {
    let me = this;
    me.id = this.$route.params.id;
    me.idAgenda = me.id;
  },
  mounted: function() {
    let me = this;
    me.id = this.$route.params.id;
    me.setRiferimento();
    me.loadDefaultData();
  },
  computed: {
    numeroDisponibilita: {
      get() {
        if (this.slotDisponibili === 0) {
          return this.$i18n.t("agenda.lblNotAvailability");
        } else {
          return this.slotDisponibili + " " + this.$i18n.t("agenda.lblAvailability");
        }
      },
      set(value) {
        this.slotDisponibili = value;
      },
    },
    rows() {
      return this.nomenclatore.length;
    },
  },
  methods: {
    updateData() {
      let me = this;
      me.loadData();
    },
    onBack() {
      let me = this;
      me.$router.replace(me.pagelink).catch((err) => {
        err;
      });
    },
    setRiferimento(dataSelezionata = null) {
      let me = this;
      if (dataSelezionata === null) {
        dataSelezionata = new Date();
      }
      switch (me.$refs.crsCalendar.index) {
        case 0:
          me.riferimento = moment().format("YYYY-MM-DD");
          me.subTitle = moment().format("YYYY");
          me.periodo = "A";
          me.loadData();
          break;
        case 1:
          me.riferimento = moment(dataSelezionata).format("YYYY-MM-DD");
          me.giornoRiferimento = moment(dataSelezionata).format("dddd DD ");
          me.subTitle = moment(dataSelezionata).format("DD MMMM YYYY");
          me.periodo = "G";
          me.loadData();
          break;
        case 2:
          me.riferimento = moment(dataSelezionata).format("YYYY-MM-DD");
          me.giornoRiferimento = moment(dataSelezionata).format("dddd DD ");
          me.subTitle = moment(dataSelezionata).format("DD MMMM YYYY");
          me.periodo = "W";
          me.loadData();
          break;
        case 3:
          me.riferimento = toString(moment().format("M") - 1);
          me.subTitle = moment().format("YYYY");
          me.periodo = "M";
          break;
        case 4:
          me.riferimento = moment(dataSelezionata).format("YYYY-MM-DD");
          me.giornoRiferimento = moment(dataSelezionata).format("dddd DD");
          me.subTitle = moment(dataSelezionata).format("DD MMMM YYYY");
          me.periodo = "G";
          break;
      }
    },
    onCambioData(dataSelezionata) {
      let me = this;
      me.riferimento = moment(dataSelezionata).format("YYYY-MM-DD");
      me.giornoRiferimento = moment(dataSelezionata).format("dddd DD ");
      me.subTitle = moment(dataSelezionata).format("DD MMMM YYYY");
      me.periodo = "G";
      me.setRiferimento(me.dataSelezionata);
    },
    onShowDatePicker() {},
    onDayClick(giorno) {
      let me = this;
      me.btnAgendaVariant = "outline-primary";
      me.btnGiornoVariant = "primary";
      me.btnSettimanaVariant = "outline-primary";
      me.btnMeseVariant = "outline-primary";
      me.btnElencoAppVariant = "outline-primary";
      me.$refs.crsCalendar.setSlide(1);
      me.dataSelezionata = giorno.date;
      me.setRiferimento(giorno.date);
      me.onCambioData(giorno.date);
    },
    onAgenda() {
      let me = this;
      this.btnAgendaVariant = "primary";
      this.btnGiornoVariant = "outline-primary";
      this.btnSettimanaVariant = "outline-primary";
      this.btnMeseVariant = "outline-primary";
      this.btnElencoAppVariant = "outline-primary";
      this.$refs.crsCalendar.setSlide(0);
      me.setRiferimento();
      me.onCambioData();
    },
    onGiorno() {
      let me = this;
      this.btnAgendaVariant = "outline-primary";
      this.btnGiornoVariant = "primary";
      this.btnSettimanaVariant = "outline-primary";
      this.btnMeseVariant = "outline-primary";
      this.btnElencoAppVariant = "outline-primary";
      this.$refs.crsCalendar.setSlide(1);
      me.setRiferimento();
      me.onCambioData();
    },
    onSettimana() {
      let me = this;
      this.btnAgendaVariant = "outline-primary";
      this.btnGiornoVariant = "outline-primary";
      this.btnSettimanaVariant = "primary";
      this.btnMeseVariant = "outline-primary";
      this.btnElencoAppVariant = "outline-primary";
      this.$refs.crsCalendar.setSlide(2);
      me.setRiferimento();
      me.onCambioData();
    },
    onMese() {
      let me = this;
      me.riferimento = moment(new Date()).format("MMMM, YYYY");
      this.btnAgendaVariant = "outline-primary";
      this.btnGiornoVariant = "outline-primary";
      this.btnSettimanaVariant = "outline-primary";
      this.btnMeseVariant = "primary";
      this.btnElencoAppVariant = "outline-primary";
      this.$refs.crsCalendar.setSlide(3);
      me.setRiferimento();
    },
    onElencoApp() {
      let me = this;
      this.btnAgendaVariant = "outline-primary";
      this.btnGiornoVariant = "outline-primary";
      this.btnSettimanaVariant = "outline-primary";
      this.btnMeseVariant = "outline-primary";
      this.btnElencoAppVariant = "primary";
      this.$refs.crsCalendar.setSlide(4);
      me.setRiferimento();
      me.onCambioData();
    },
    loadNomenclatore() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathNomenclatore;
      me.$refs["sa-modal-loading"].show();
      axios
        .get(link, { params: me.filtro })
        .then((response) => {
          me.nomenclatore = [];
          me.nomenclatore = response.data.data;
          me.$refs["sa-modal-loading"].close();
        })
        .catch(() => {
          me.$refs["sa-modal-loading"].close();
        });
    },
    onAggiungi(row) {
      let me = this;
      let exist = false;
      me.data.accettazionePrestazioni.forEach((element, index) => {
        if (element.codicePrestazione === row.codicePrestazione) {
          me.data.accettazionePrestazioni[index].quantita++;
          exist = true;
        }
      });
      if (!exist) {
        row.quantita = 1;
        me.tags.push(row.codicePrestazione);
        me.tags.splice(0, 1);
        me.codicePrestazione = me.tags.toString();
        me.durataAppuntamento = me.durata;
        me.$emit("update", me.durataAppuntamento);
        me.loadData(true);
        me.modalShow = false;
      }
    },
    onClickPopover(item) {
      return {
        title: "Branca Specialistica",
        content: "BRANCA: " + item.branca,
      };
    },
    loadDefaultData() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathRestPrestazioni;
      axios
        .get(link, {
          params: {
            idAgenda: me.id,
          },
        })
        .then((response) => {
          me.nomenclatore = [];
          me.nomenclatore = response.data.data;
          me.codicePrestazione = response.data.data[0].codicePrestazione;
          me.descrizionePrestazione = response.data.data[0].descrizionePrestazione;
          me.costo = response.data.data[0].costo;
          me.idAgenda = me.id;
          me.tags.push(me.codicePrestazione);
          me.codicePrestazione = me.tags.toString();
          me.loadData(false);
        })
        .catch(() => {});
    },
    loadData(flag) {
      let me = this;
      if (me.codicePrestazione) {
        let link = process.env.VUE_APP_PATH_API + me.pathRest + "/";
        me.$refs["sa-modal-loading"].show();
        axios
          .get(link, {
            params: {
              idAgenda: me.id,
              periodo: me.periodo,
              riferimento: me.riferimento,
              codicePrestazione: me.codicePrestazione,
            },
          })
          .then((response) => {
            me.disponibilita = response.data.data;
            let value = 0;
            me.disponibilita.forEach((elemento) => {
              value += elemento.disponibilita.length;
            });
            me.numeroDisponibilita = value;
            me.$refs["sa-modal-loading"].close();
            let link = process.env.VUE_APP_PATH_API + me.pathRestPrestazioni;
            if (flag) {
              axios
                .get(link, {
                  params: {
                    idAgenda: me.id,
                  },
                })
                .then((response) => {
                  for (let i = 0; response.data.data.length; i++) {
                    if (me.codicePrestazione === response.data.data[i].codicePrestazione) {
                      me.nomenclatore = [];
                      me.nomenclatore = response.data.data;
                      me.descrizionePrestazione = response.data.data[i].descrizionePrestazione;
                      me.costo = response.data.data[i].costo;
                      me.idAgenda = me.id;
                      me.tags.splice(0, 1);
                      me.tags.push(me.codicePrestazione);
                    }
                  }
                })
                .catch(() => {});
            }
          })
          .catch(function(error) {
            me.$refs["sa-modal-loading"].close();
            me.$bvToast.toast(error.response.data.messaggio, {
              title: "Agenda",
              variant: "danger",
              solid: true,
            });
          });
      } else {
        return;
      }
    },
    onTraduci(value) {
      return this.$i18n.t(value);
    },
    onSubmit() {
      let me = this;
      let sessionStorage = window.sessionStorage;
      sessionStorage["filtro"] = JSON.stringify(me.filtro);
    },
    onRefresh() {
      let me = this;
      me.loadData();
    },
  },
};
</script>
<style lang="css">
.sa-carousel-inner-max-height .carousel-inner {
  height: 100%;
}
.sa-carousel-inner-max-height .carousel-item {
  height: 100%;
  padding-bottom: 15px;
}
.sc-card-max-height {
  height: 100%;
}
.card {
  text-shadow: none;
}
.carousel {
  height: 100%;
  padding-bottom: 10px;
}

.sa-panel {
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  width: 100%;
}

.sa-agenda-container {
  display: flex;
  height: 100%;
  padding: 0px;
  flex: 1 1 100%;
}

#crsSlideAgenda .card-body {
  padding: 0.25rem;
}

.sa-riferimento-data,
.sa-settimana {
  font-size: 1.3rem;
  font-weight: bold;
}

/* .b-form-tags {
  width: 600px;
  height: 36px;
  border-color: #2eafb4;
} */

/* .sa-btn-tags {
  margin-right: 20px;
  background-color: white;
  color: #2eafb4;
  border-color: #2eafb4;
} */

/* .sa-btn-tags:hover,
.sa-btn-tags:focus {
  background-color: #2eafb4;
  border-color: #2eafb4;
} */
.modal-sm {
  max-height: 200px;
}
#modal-calendar {
  width: min-content !important;
  margin-left: 40%;
}
</style>
