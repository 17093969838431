<template>
  <div id="sa-misure-range" class="sa-misure-range">
    <div class="sa-misure-range-red" :style="redLeftRange"></div>
    <div class="sa-misure-range-yellow" :style="yellowLeftRange"></div>
    <div class="sa-misure-range-green" :style="normalRange"></div>
    <div class="sa-misure-range-yellow" :style="yellowRightRange"></div>
    <div class="sa-misure-range-red" :style="redRightRange"></div>
    <div class="sa-misure-input-range">
      <b-row>
        <b-col cols="6"
          ><input-range :precision="precision" :scala="scala" :center="center" :maxWidth="maxWidth" :rangeValue="rangeValueLeft" :min="rangeMinLeft" :max="rangeMaxLeft" @update="onRangeUpdateLeft"></input-range
        ></b-col>
        <b-col cols="6"
          ><input-range :precision="precision" :scala="scala" :center="center" :maxWidth="maxWidth" :rangeValue="rangeValueRight" :min="rangeMinRight" :max="rangeMaxRight" @update="onRangeUpdateRight"></input-range
        ></b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import InputRange from "./InputRange.vue";
export default {
  props: {
    index: {
      default() {
        return "";
      },
    },
    precision: {
      type: Number,
      default() {
        return 0;
      },
    },
    scala: {
      type: Number,
      default() {
        return 0;
      },
    },
    center: {
      type: Number,
      default() {
        return 0;
      },
    },
    maxValueAlert: {
      type: Number,
      default() {
        return 0;
      },
    },
    maxValueWarning: {
      type: Number,
      default() {
        return 0;
      },
    },
    minValueWarning: {
      type: Number,
      default() {
        return 0;
      },
    },
    minValueAlert: {
      type: Number,
      default() {
        return 0;
      },
    },
  },
  components: { InputRange },
  data() {
    return {
      greenWidth: 0,
      widthYellowLeft: 0,
      widthYellowRight: 0,
      widthRedLeft: 0,
      widthRedRight: 0,
      rangeValueLeft: [0, 0],
      rangeMinLeft: 0,
      rangeMaxLeft: 0,
      maxWidth: 0,
      rangeValueRight: [0, 0],
      rangeMinRight: 0,
      rangeMaxRight: 0,
    };
  },
  computed: {
    normalRange() {
      return {
        width: this.greenWidth + "px",
        position: "relative",
      };
    },
    yellowLeftRange() {
      return {
        width: this.widthYellowLeft + "px",
        position: "relative",
      };
    },
    yellowRightRange() {
      return {
        width: this.widthYellowRight + "px",
        position: "relative",
      };
    },
    redLeftRange() {
      return {
        width: this.widthRedLeft + "px",
        position: "relative",
      };
    },
    redRightRange() {
      return {
        width: this.widthRedRight + "px",
        position: "relative",
      };
    },
  },
  mounted() {
    let me = this;
    me.setValueRangeLeft();
    me.setValueRangeRight();
  },
  methods: {
    setValueRangeLeft() {
      let me = this;

      const div = document.getElementById("sa-misure-range");
      me.maxWidth = div.clientWidth;
      let maxWidth = div.clientWidth / 2;

      let start = me.getPosition(me.maxValueAlert);
      let finish = me.getPosition(me.maxValueWarning);
      me.rangeValueLeft = [start, finish];
      me.rangeMinLeft = 0;
      me.rangeMaxLeft = maxWidth;
      me.setRangeLeft(me.rangeValueLeft);
    },
    setValueRangeRight() {
      let me = this;
      const div = document.getElementById("sa-misure-range");
      me.maxWidth = div.clientWidth;
      let maxWidth = div.clientWidth / 2;
      let start = me.getPosition(me.minValueWarning);
      let finish = me.getPosition(me.minValueAlert);
      me.rangeValueRight = [start, finish];
      me.rangeMinRight = maxWidth;
      me.rangeMaxRight = maxWidth * 2;
      me.setRangeRight(me.rangeValueRight);
    },
    onRangeUpdateRight(value) {
      let me = this;
      me.setRangeRight(value);
      let returnMinValueWarning = me.getLabel(value[0]);
      let returnMinValueAlert = me.getLabel(value[1]);
      let returnData = [returnMinValueWarning, returnMinValueAlert];
      me.$emit("updateRangeRight", returnData, me.index);
    },
    onRangeUpdateLeft(value) {
      let me = this;
      me.setRangeLeft(value);
      let returnMaxValueAlert = me.getLabel(value[0]);
      let returnMaxValueWarning = me.getLabel(value[1]);
      let returnData = [returnMaxValueAlert, returnMaxValueWarning];
      me.$emit("update", returnData, me.index);
    },
    setRangeRight(value) {
      let me = this;
      const div = document.getElementById("sa-misure-range");
      let maxWidth = div.clientWidth;
      me.widthYellowRight = value[1] - value[0];
      me.widthRedRight = maxWidth - value[1];
    },
    setRangeLeft(value) {
      let me = this;
      me.widthYellowLeft = value[1] - value[0];
      me.widthRedLeft = value[0];
    },
    getPosition(value) {
      let me = this;
      let position = 0;
      if (me.maxWidth) {
        position = ((me.maxWidth / 2) * (me.scala / 2 + me.center - value * 10)) / (me.scala / 2);
      }

      return position;
    },
    getLabel(value) {
      let me = this;
      let label = value;
      // console.log(me.maxWidth);
      if (me.maxWidth) {
        label = ((me.maxWidth / 2 - value) * (me.scala / 2)) / (me.maxWidth / 2);
        label = (label + me.center) / 10;
        label = label.toFixed(me.precision);
      }
      // console.log(label);
      return label;
    },
  },
};
</script>
