<template>
  <div :key="componentKey">
    <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
      <b-col lg="3">
        <p>Totale Record: {{ rows }}</p>
      </b-col>
      <b-col lg="9">
        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
      </b-col>
    </b-row>
    <div class="b-table-sticky-header">
      <b-table
        sticky-header
        ref="table"
        :filter="filter"
        responsive
        stacked="lg"
        striped
        hover
        :items="items"
        :fields="fields"
        :current-page="currentPage"
        :per-page="perPage"
        sort-icon-left
        head-variant="light"
        class="sa-b-table"
      >
        <template v-slot:cell(actions)="row">
          <b-button size="sm" variant="btn btn-purple waves-effect  waves-light width-sm no-text" style="margin-right:3px" @click="onClick(row.item, row.index, $event.target)" v-b-modal.visualizzaAllegato>
            <b-icon icon="search"></b-icon>
          </b-button>
        </template>
      </b-table>
    </div>
    <b-modal size="xl" id="visualizzaAllegato" title="Visualizza Allegato" @show="onShow" @click="onClick">
      <template>
        <b-embed type="iframe" :src="file" allowfullscreen></b-embed>
      </template>
    </b-modal>
  </div>
</template>
<script>
import axios from "axios";
import moment from "moment";
//import DatePicker from "vue2-datepicker";
export default {
  computed: {
    idPaziente() {
      return this.$route.query.idPaziente;
    },
    rows() {
      return this.items.length;
    },
  },
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      filter: null,
      id: null,
      componentKey: 0,
      pathResource: "/allegati",
      linkedit: "/paziente/allegati",
      file: null,
      filtro: {},
      filterOn: [],
      fields: [
        {
          label: this.$i18n.t("patients.lblUploadDate"),
          key: "dataCaricamento",
          sortable: true,
          thStyle: "width: 7rem",
          formatter: (value) => {
            return moment(new Date(value)).format("DD-MM-YYYY");
          },
        },
        {
          label: this.$i18n.t("patients.lblDocumentType"),
          key: "tipoDocumento",
          thStyle: "width: 7rem",
          sortable: true,
        },
        {
          label: this.$i18n.t("patients.lblDescription"),
          key: "descrizione",
          thStyle: "width: 7rem",
          sortable: true,
        },
        {
          label: "",
          key: "actions",
          thStyle: "width: 3rem",
        },
        //{ class: "sa-table-column-action-single", key: "actions", label: "" },
      ],
      items: [],
    };
  },
  created: function() {},
  mounted: function() {
    let me = this;
    // me.id = me.$route.params.idPaziente;
    me.loadData();
    me.updateList();
  },
  methods: {
    updateList() {
      let me = this;
      document.addEventListener("reload", function() {
        console.log("AAAAAAA");
        me.loadData();
        // this.forceRerender();
      });
    },

    loadData() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResource + "?idPaziente=";
      me.isBusy = true;
      axios
        .get(link + me.idPaziente)
        .then((response) => {
          me.items = [];
          me.items = response.data.data;
          me.data = response.data.data;
          //console.log(me.data)
          //me.$refs["SaPageList"].close();
          me.perPage = me.data.length;
        })
        .catch(() => {
          me.showmolalloading = false;
        });
    },
    onSubmit() {
      let me = this;
      let sessionStorage = window.sessionStorage;
      sessionStorage["filtro"] = JSON.stringify(me.filtro);
      me.loadData();
    },
    onReset() {
      let me = this;
      me.filtro = {};
      let sessionStorage = window.sessionStorage;
      sessionStorage["filtro"] = JSON.stringify(me.filtro);
      me.loadData();
    },
    onRefresh() {
      let me = this;
      me.loadData();
    },
    onShow() {},
    onClick(item) {
      let me = this;
      me.file = "data:application/pdf;base64," + item.file;
    },
    onBack() {
      let me = this;
      me.$router.replace(me.pagelink + "/view/" + me.id).catch((err) => {
        err;
      });
    },
    onVisualizzaPDF() {
      console.log("ciao");
    },
  },
};
</script>
