<template>
    <sa-page-list :titolo="titolo" :showmolalloading="showmolalloading">
        <template slot="toolbar">
            <b-button class="float-sm-right " variant="success btn-toolbar sa-margin-right" size="sm" @click="onAdd"><b-icon icon="plus" variant="secondary"></b-icon>Nuovo</b-button>
            <b-button class="float-sm-right " variant="info btn-toolbar" size="sm" @click="onRefresh" v-b-modal.modal-center><b-icon icon="arrow-clockwise" variant="secondary" />Refresh</b-button>
        </template>
        <template slot="table-filter">
            <b-form @submit.prevent="onSubmit">
                <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                    <b-col lg="12">
                        <b-input-group size="sm">
                            <b-form-input v-model="filter" type="search" id="filterInput" placeholder="Testo da Cercare"></b-form-input>
                            <b-input-group-append>
                                <b-button :disabled="!filter" @click="filter = ''">Svuota</b-button>
                            </b-input-group-append>
                            <b-form-input v-model="filtro.testo" type="search" placeholder="Testo"></b-form-input>
                        </b-input-group>
                    </b-col>
                </b-row>
            </b-form>
        </template>
        <template slot="table-header">
            <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col cols="6">
                    <p>Totale Record: {{ rows }}</p>
                </b-col>
                <b-col cols="6">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
            </b-row>
        </template>
        <template slot="table-body">
            <div class="b-table-sticky-header">
                <b-table ref="table" id="tbl" :filter="filter" stacked="lg" striped hover :items="items" :fields="fields" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" sticky-header class="sa-b-table">
                    <template #cell(titolo)="{ item }">
                        <router-link class="sa-edit-link" :to="'/sms/view/' + item.id">{{ item.titolo }}</router-link>
                    </template>
                </b-table>
            </div>
        </template>
        <template slot="table-footer">
            <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col cols="6">
                    <p>Totale Record: {{ rows }}</p>
                </b-col>
                <b-col cols="6">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
            </b-row>
        </template>
    </sa-page-list>
</template>
<script>
import axios from "axios";
import moment from "moment";
import { SaPageList } from "../../../template/sa/components/layout/contents";
export default {
    components: { SaPageList },
    computed: {
        rows() {
            return this.items.length;
        },
    },
    data() {
        return {
            titolo: "Lista Messaggi",
            showmolalloading: false,
            pagelink: "/sms",
            pathResource: "/inviosms",
            currentPage: 1,
            perPage: 30,
            filter: "",
            pathRest: "/inviosms",
            routelink: "/sms",
            fields: [
                {
                    label: this.$i18n.t("sms.lblDate"),
                    key: "data",
                    sortable: true,
                    thStyle: "width: 9rem",
                    formatter: (value) => {
                        return moment(new Date(value)).format("DD-MM-YYYY HH:mm:ss");
                    },
                },
                {
                    label: "Titolo",
                    key: "titolo",
                    sortable: true,
                    thStyle: "width: 8rem",
                },
                {
                    label: "Testo",
                    key: "testo",
                    sortable: true,
                },
                {
                    label: "Note",
                    key: "note",
                    sortable: true,
                },
            ],
            items: [],
            data: [],
            filtro: {},
        };
    },
    created: function() {},
    mounted: function() {
        let me = this;
        me.loadData();
    },
    update: function() {
        console.log("Update");
    },
    methods: {
        formatDate(value) {
            if (value) {
                return moment(new Date(value)).format("DD-MM-YYYY HH:mm:ss");
            } else {
                return "---";
            }
        },
        loadData() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource;
            me.showmolalloading = true;
            axios
                .get(link, { params: me.filtro })
                .then((response) => {
                    me.items = [];
                    me.items = response.data.data;
                    me.showmolalloading = false;
                })
                .catch(function(error) {
                    me.showmolalloading = false;
                    me.$bvToast.toast(error.response.data.messaggio, {
                        title: "Sorveglianza Covid Admin",
                        variant: "danger",
                        solid: true,
                    });
                });
        },
        onAdd() {
            let me = this;
            me.$router.replace(me.pagelink + "/edit/-1").catch((err) => {
                err;
            });
        },
        onRefresh() {
            let me = this;
            me.loadData();
        },
        onSubmit: function() {
            let me = this;
            me.filter = me.filtro.titolo;
            return false;
        },
    },
};
</script>
<style scoped></style>
