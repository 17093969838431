<template>
    <sa-page-list :titolo="titolo" :showmolalloading="showmolalloading">
        <template slot="toolbar"> </template>
        <template slot="table-filter">
            <b-form @submit.prevent="onSubmit" @reset="onReset">
                <b-row>
                    <b-col xs="12" sm="6" lg="2" class="my-1">
                        <b-form-input id="input-1" v-model="form.sezione" type="text" placeholder="Sezione"></b-form-input>
                    </b-col>
                    <b-col sm="6" lg="4" class="my-1">
                        <b-form-input id="input-1" v-model="form.evento" type="text" placeholder="Descrizione Evento"></b-form-input>
                    </b-col>
                    <b-col sm="6" lg="2" class="my-1">
                        <b-form-input id="input-1" v-model="form.username" type="text" placeholder="Username"></b-form-input>
                    </b-col>
                    <b-col sm="6" lg="4" class="my-1">
                        <b-form-input id="input-1" v-model="form.ip" type="text" placeholder="Ip"></b-form-input>
                    </b-col>
                    <b-col sm="6" lg="4" class="my-1">
                        <b-form-input id="input-1" v-model="form.userAgent" type="text" placeholder="User-Agent"></b-form-input>
                    </b-col>
                    <b-col sm="6" lg="4" class="my-1">
                        <date-picker format="DD-MM-YYYY" value-type="YYYY-MM-DD" v-model="form.dataDal" placeholder="Data Dal" type="date"></date-picker>
                    </b-col>
                    <b-col sm="6" lg="4" class="my-1">
                        <date-picker format="DD-MM-YYYY" value-type="YYYY-MM-DD" v-model="form.dataAl" placeholder="Data Al" type="date"></date-picker>
                    </b-col>
                    <b-col sm="6" lg="4" class="my-1">
                        <b-form-input id="input-1" v-model="form.stato" type="text" placeholder="Stato"></b-form-input>
                    </b-col>
                    <b-col sm="6" lg="4" class="my-1">
                        <b-form-input id="input-1" v-model="form.idRisorsa" type="text" placeholder="Id Risorsa"></b-form-input>
                    </b-col>
                    <b-col sm="6" lg="4" class="my-1">
                        <b-form-input id="input-1" v-model="form.operazione" type="text" placeholder="Operazione"></b-form-input>
                    </b-col>
                    <b-col sm="6" lg="4" class="my-1">
                        <b-form-input id="input-1" v-model="form.idGruppo" type="text" placeholder="Id Gruppo"></b-form-input>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="sa-padding-right text-right">
                        <b-button type="submit" variant="primary">Submit</b-button>
                        <b-button type="reset" variant="danger">Reset</b-button>
                    </b-col>
                </b-row>
            </b-form>
        </template>
        <template slot="table-header">
            <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col cols="6" sm="6">
                    <p>Totale Record: {{ rows }}</p>
                </b-col>
                <b-col cols="6" sm="6">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
            </b-row>
        </template>
        <template slot="table-body">
            <b-table id="tblLogs" class="sa-b-table" responsive striped hover :items="elenco" :fields="fields" head-variant="light" :current-page="currentPage" :per-page="perPage" sort-icon-left>
                <template v-slot:cell(stato)="{ item }">
                    <div>
                        <b-alert :variant="getStato(item.stato)" show>{{ item.stato }}</b-alert>
                    </div>
                </template>
            </b-table>
        </template>
        <template slot="table-footer">
            <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col cols="6" sm="6">
                    <p>Totale Record: {{ rows }}</p>
                </b-col>
                <b-col cols="6" sm="6">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
            </b-row>
        </template>
    </sa-page-list>
</template>
<script>
import DatePicker from "vue2-datepicker";
import axios from "axios";
import moment from "moment";
import { SaPageList } from "../../../template/sa/components/layout/contents";
export default {
    components: { SaPageList, DatePicker },
    label: "Dettagllio Tampone",
    computed: {
        rows() {
            return this.elenco.length;
        },
    },
    data() {
        return {
            titolo: "Logs",
            showmolalloading: false,
            currentPage: 1,
            perPage: 10,
            elenco: [],
            form: {},
            pathRESTRequest: "/logs",
            filter: null,
            filterOn: [],
            fields: [
                {
                    label: "Sezione",
                    key: "sezione",
                    sortable: true,
                },
                {
                    label: "Descrizione Evento",
                    key: "descrizioneEvento",
                    sortable: true,
                },
                {
                    label: "Data/ora",
                    key: "dataOra",
                    formatter: (value) => {
                        return moment(value).format("DD-MM-YYYY HH:mm:ss");
                    },
                    sortable: true,
                },
                {
                    label: "Stato",
                    key: "stato",
                    sortable: true,
                },
                {
                    label: "Id Risorsa",
                    key: "idRisorsa",
                    sortable: true,
                    thStyle: { minWidth: "110px" },
                },
                {
                    label: "Operazione",
                    key: "operation",
                    sortable: true,
                },
                {
                    label: "Username",
                    key: "username",
                    sortable: true,
                },
                {
                    label: "Id Gruppo",
                    key: "igGruppo",
                    sortable: true,
                    thStyle: { minWidth: "110px" },
                },
            ],
        };
    },
    mounted: function() {
        let me = this;
        me.showmolalloading = true;
        this.loadData();
    },
    methods: {
        getStato(stato) {
            console.log(stato);
            let returnValue = "dark";
            switch (stato) {
                case "INFO":
                    returnValue = "info";
                    break;
                case "ERROR":
                    returnValue = "danger";
                    break;
                case "WARNING":
                    returnValue = "warning";
                    break;
            }
            return returnValue;
        },
        loadData() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathRESTRequest + "/";
            axios.get(link, { params: me.form }).then((response) => {
                me.elenco = response.data.data;
                me.showmolalloading = false;
            });
        },
        onSubmit() {
            let me = this;
            me.loadData();
            return false;
        },
        onReset() {
            return 0;
        },
    },
};
</script>
<style>
.sa-text-right {
    text-align: right;
}
@media only screen and (max-width: 767px) {
    .sa-text-right {
        text-align: left;
    }
}
.card {
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.125), 0 1px 3px rgba(0, 0, 0, 0.2);
    margin-bottom: 1rem;
}
.sa-td-min-width {
    min-width: 110px;
}
</style>
