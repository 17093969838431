<template>
    <sa-page-list :showmolalloading="showmolalloading">
        <template slot="toolbar">
            <b-button class="float-sm-right " v-b-modal.inviaRichiesta variant="info btn-toolbar sa-margin-right"><b-icon icon="cart-plus"></b-icon>&nbsp;{{ this.$i18n.t("global.lblSendRequest") }}</b-button>
            <b-button class="float-sm-right " v-b-modal.visualizzaCarrello variant="info btn-toolbar"><b-icon icon="cart-plus"></b-icon>&nbsp;{{ this.$i18n.t("global.lblShowCart") }}</b-button>
        </template>
        <template slot="table-filter">
            <ricerca-anagrafica-paziente :link="indirizzoLink" @update="onUpdateData"></ricerca-anagrafica-paziente>
        </template>
        <template slot="table-header">
            <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col lg="3">
                    <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
                </b-col>
                <b-col lg="9">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
            </b-row>
        </template>
        <template slot="table-body">
            <b-tabs ref="tbPrescrizioniRipetibili" class="sa-tabs" content-class="mt-3" @activate-tab="onActiveTabs" fill>
                <b-tab title="Prescrizioni Ripetibili">
                    <div class="b-table-sticky-header">
                        <b-table sticky-header ref="table" :filter="filter" responsive stacked="lg" striped hover :items="data" :fields="fields" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                            <template v-slot:cell(descrizione)="{ item }">
                                <a>{{ item.descrizione }} {{ item.confezione }} ({{ item.principioAttivo }})</a>
                            </template>
                            <template #cell(aggiungi)="row">
                                <b-button size="sm" variant="btn btn-purple waves-effect waves-light width-sm no-text" style="margin-right:3px" @click="onClick(row.item, row.index, $event.target)">
                                    <b-icon icon="cart-plus"></b-icon>
                                </b-button>
                            </template>
                        </b-table>
                    </div>
                </b-tab>
                <b-tab title="Storico Messaggi">
                    <div class="b-table-sticky-header">
                        <b-table sticky-header ref="table" :filter="filter" responsive stacked="lg" striped hover :items="messaggiInviati" :fields="fieldsMessaggi" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                            <template v-slot:cell(oggetto)="{ item }">
                                <b-link v-b-modal.visualizzaPrescrizioniMessaggio @click="loadMessaggioById(item)">{{ item.oggetto }}</b-link>
                            </template>
                        </b-table>
                    </div>
                </b-tab>
            </b-tabs>
        </template>
        <template slot="table-footer">
            <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col lg="3">
                    <p>{{ this.$i18n.t("patients.lblTotalRecords") }}: {{ rows }}</p>
                </b-col>
                <b-col lg="9">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
            </b-row>
            <b-modal id="inviaRichiesta" title="Invia Richiesta" size="xl" @show="onShow" @ok="onInvia" @hidden="onClose">
                <b-row>
                    <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                        <span class="sa-form-label">{{ this.$i18n.t("repeatablePrescriptions.lblSenderTaxCode") }}</span>
                        <br />
                        <strong>{{ messaggio.codiceFiscaleMittente }}</strong>
                    </b-col>
                    <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                        <span class="sa-form-label">{{ this.$i18n.t("repeatablePrescriptions.lblSenderName") }}</span>
                        <br />
                        <strong>{{ messaggio.nominativoMittente }}</strong>
                    </b-col>
                    <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                        <span class="sa-form-label">{{ this.$i18n.t("repeatablePrescriptions.lblRecipientTaxCode") }}</span>
                        <br />
                        <strong>{{ messaggio.codiceFiscaleDestinatario }}</strong>
                    </b-col>
                    <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                        <span class="sa-form-label">{{ this.$i18n.t("repeatablePrescriptions.lblRecipientName") }}</span>
                        <br />
                        <strong>{{ messaggio.nominativoDestinatario }}</strong>
                    </b-col>
                    <b-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                        <span class="sa-form-label">{{ this.$i18n.t("repeatablePrescriptions.lblObject") }}</span>
                        <br />
                        <strong>{{ messaggio.oggetto }}</strong>
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                        <span class="sa-form-label">{{ this.$i18n.t("repeatablePrescriptions.lblMessage") }}</span>
                        <b-form-textarea v-model="messaggio.messaggio" placeholder="Messaggio" rows="3" no-resize></b-form-textarea>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                        <span class="sa-form-label">{{ this.$i18n.t("repeatablePrescriptions.lblEmail") }}</span>
                        <b-form-input v-model="messaggio.emailDestinatario" placeholder="Email"></b-form-input>
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                        <div>
                            <b-table sticky-header ref="table" :filter="filter" responsive stacked="lg" striped hover :items="farmacie" :fields="fieldsFarmacie" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table" selectable select-mode="single" @row-selected="onRowSelected">
                                <template v-slot:cell(descrizione)="{ item }">
                                    <a>{{ item.nome }} {{ item.indirizzo }} ({{ item.provincia }})</a>
                                </template>
                            </b-table>
                        </div>
                    </b-col>
                </b-row>
            </b-modal>
            <b-modal id="visualizzaPrescrizioniMessaggio" title="Prescrizioni" size="lg">
                <b-table sticky-header ref="table" :filter="filter" responsive stacked="lg" striped hover :items="messaggioPrescrizioneById" :fields="fieldsMessaggiPrescrizioni" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                    <template v-slot:cell(descrizione)="{ item }">
                        <a>{{ item.descrizione }} {{ item.confezione }} ({{ item.principioAttivo }})</a>
                    </template>
                </b-table>
            </b-modal>
            <b-modal id="visualizzaCarrello" title="Carrello" size="lg">
                <div class="b-table-sticky-header">
                    <b-table sticky-header ref="table" :filter="filter" responsive stacked="lg" striped hover :items="prescrizione" :fields="fields" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                        <template v-slot:cell(descrizione)="{ item }">
                            <a>{{ item.descrizione }} {{ item.confezione }} ({{ item.principioAttivo }})</a>
                        </template>
                        <template #cell(aggiungi)="row">
                            <b-button size="sm" variant="btn btn-danger waves-effect waves-light width-sm no-text" @click="onDelete(row.index)">
                                <b-icon icon="trash"></b-icon>
                            </b-button>
                        </template>
                    </b-table>
                </div>
            </b-modal>
        </template>
    </sa-page-list>
</template>
<script>
import axios from "axios";
import moment from "moment";
import { SaPageList } from "../../../template/sa/components/layout/contents";
import RicercaAnagraficaPaziente from "./RicercaAnagraficaPaziente.vue";
export default {
    components: { SaPageList, RicercaAnagraficaPaziente },
    computed: {
        rows() {
            return this.data.length;
        },
        indirizzoLink() {
            return process.env.VUE_APP_PATH_API + this.pathRest;
        },
    },
    data() {
        return {
            perPage: 10,
            currentPage: 1,
            filter: null,
            pathRest: "/prescrizioniripetibili",
            pathRESTRequest: "/pazienti",
            linkMessaggi: "/messaggi",
            linkMessaggiInviati: "/inviati",
            messaggio: { codiceFiscaleMittente: "", nominativoMittente: "", codiceFiscaleDestinatario: "", nominativoDestinatario: "", oggetto: "", messaggio: "", emailDestinatario: "" },
            prescrizione: [],
            rigaSelezionata: null,
            filtro: {},
            filterOn: [],
            showmolalloading: false,
            fields: [
                {
                    label: this.$i18n.t("repeatablePrescriptions.lblDescription"),
                    key: "descrizione",
                    sortable: true,
                    thStyle: "width: 20rem",
                },
                {
                    label: this.$i18n.t("repeatablePrescriptions.lblDateLastPrescription"),
                    key: "dataUltimaPrescrizione",
                    sortable: true,
                    formatter: (value) => {
                        return moment(new Date(value)).format("DD/MM/YYYY");
                    },
                    thStyle: "width: 7rem",
                },
                {
                    label: "",
                    key: "aggiungi",
                    thStyle: "width: 1rem",
                },
                // { class: "sa-table-column-action-single", key: "actions", label: "" },
            ],
            fieldsMessaggi: [
                {
                    label: this.$i18n.t("repeatablePrescriptions.lblObject"),
                    key: "oggetto",
                    sortable: true,
                    //thStyle: "width: 7rem",
                },
                {
                    label: this.$i18n.t("repeatablePrescriptions.lblMessage"),
                    key: "messaggio",
                    sortable: true,
                    //thStyle: "width: 7rem",
                },
                {
                    label: this.$i18n.t("repeatablePrescriptions.lblStatus"),
                    key: "stato",
                    sortable: true,
                    //thStyle: "width: 7rem",
                },
                {
                    label: this.$i18n.t("repeatablePrescriptions.lblMessageDate"),
                    key: "dataMessaggio",
                    sortable: true,
                    formatter: (value) => {
                        return moment(new Date(value)).format("DD/MM/YYYY");
                    },
                },
                {
                    label: "",
                    key: "aggiungi",
                },
                // { class: "sa-table-column-action-single", key: "actions", label: "" },
            ],
            fieldsMessaggiPrescrizioni: [
                {
                    label: this.$i18n.t("repeatablePrescriptions.lblDescription"),
                    key: "descrizione",
                    sortable: true,
                    thStyle: "width: 20rem",
                },
                {
                    label: this.$i18n.t("repeatablePrescriptions.lblResponseMessage"),
                    key: "messaggioRisposta",
                    sortable: true,
                    thStyle: "width: 20rem",
                },
                {
                    label: this.$i18n.t("repeatablePrescriptions.lblStatus"),
                    key: "stato",
                    sortable: true,
                    thStyle: "width: 7rem",
                },
            ],
            fieldsFarmacie: [
                {
                    label: this.$i18n.t("repeatablePrescriptions.lblDescription"),
                    key: "nome",
                    sortable: true,
                    thStyle: "width: 20rem",
                },
                {
                    label: this.$i18n.t("repeatablePrescriptions.lblEmail"),
                    key: "emailDestinatario",
                    sortable: true,
                    thStyle: "width: 20rem",
                },
            ],
            data: [],
            messaggiInviati: [],
            messaggioById: [],
            messaggioPrescrizioneById: [],
            farmacie: [
                { nome: "Farmacia Fugaro", indirizzo: "Via Pier Paolo Pasolini", provincia: "(Caserta)", emailDestinatario: "farmacia.fugaro@gmail.com" },
                { nome: "Farmacia Odierna Gadola", indirizzo: "Via G. A. Acquaviva, 134/136", provincia: "(Caserta)", emailDestinatario: "farmacia.odierna@gmail.com" },
            ],
        };
    },
    created: function() {},
    mounted: function() {},
    methods: {
        formatDate(value) {
            if (value) {
                return moment(new Date(value)).format("DD-MM-YYYY HH:mm:ss");
            } else {
                return "---";
            }
        },
        onUpdateData(newValue, filtro) {
            this.data = newValue;
            this.filtro = filtro;
        },
        loadMessaggiInviati() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkMessaggi + me.linkMessaggiInviati + "?codiceFiscaleMittente=" + me.filtro.codiceFiscalePaziente;
            axios
                .get(link)
                .then((response) => {
                    me.messaggiInviati = [];
                    me.messaggiInviati = response.data.data;
                    for (let element in me.messaggiInviati) {
                        me.messaggiInviati[element]["_cellVariants"] = {};
                        if (me.messaggiInviati[element]["stato"] == "evasa") {
                            me.messaggiInviati[element]["_cellVariants"].stato = "success";
                        } else if (me.messaggiInviati[element]["stato"] == "nonevasa") {
                            me.messaggiInviati[element]["_cellVariants"].stato = "danger";
                        }
                    }
                    //me.$refs["SaPageList"].close();
                })
                .catch(() => {
                    me.showmolalloading = false;
                });
        },
        loadMessaggioById(item) {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkMessaggi + "/" + item.id;
            axios
                .get(link)
                .then((response) => {
                    me.messaggioById = [];
                    me.messaggioById = response.data.data;
                    me.messaggioPrescrizioneById = [];
                    me.messaggioPrescrizioneById = response.data.data.prescrizione;
                    for (let element in me.messaggioPrescrizioneById) {
                        me.messaggioPrescrizioneById[element]["_cellVariants"] = {};
                        if (me.messaggioPrescrizioneById[element]["stato"] == "accettata") {
                            me.messaggioPrescrizioneById[element]["_cellVariants"].stato = "success";
                        } else if (me.messaggioPrescrizioneById[element]["stato"] == "rifiutata") {
                            me.messaggioPrescrizioneById[element]["_cellVariants"].stato = "danger";
                        }
                    }
                    //me.$refs["SaPageList"].close();
                })
                .catch(() => {
                    me.showmolalloading = false;
                });
        },
        onShow() {
            let me = this;
            me.messaggio.codiceFiscaleMittente = me.filtro.codiceFiscalePaziente;
            me.messaggio.codiceFiscaleDestinatario = me.filtro.codiceFiscaleMedico;
            me.messaggio.nominativoMittente = me.filtro.nominativoPaziente;
            me.messaggio.nominativoDestinatario = me.filtro.nominativoMedico;
            me.messaggio.oggetto = "RICHIESTA PRESCRIZIONE DEL " + me.formatDate(Date());
            //console.log(me.messaggio);
        },
        onClick(row) {
            let me = this;
            let selezionato = true;
            for (let element in me.prescrizione) {
                if (row === me.prescrizione[element]) {
                    selezionato = false;
                    break;
                }
            }
            if (selezionato) {
                row.nominativoPaziente = me.filtro.nominativoPaziente;
                row.nominativoMedico = me.filtro.nominativoMedico;
                row.selezionato = true;
                me.prescrizione.push(row);

                this.$bvToast.toast("Farmaco aggiunto con successo!", {
                    title: "Aggiunto",
                    variant: "success",
                    solid: true,
                });
            } else {
                this.$bvToast.toast("Farmaco già presente", {
                    title: "Attenzione",
                    variant: "warning",
                    solid: true,
                });
            }
        },
        onRowSelected(items) {
            let me = this;
            if (items.length > 0) {
                me.rigaSelezionata = items[0];
            }
            me.messaggio.emailDestinatario = me.rigaSelezionata.emailDestinatario;
        },
        onDelete(index) {
            let me = this;
            me.prescrizione[index].selezionato = false;
            me.prescrizione.splice(index, 1);

            this.$bvToast.toast("Farmaco eliminato dal carrello", {
                title: "Eliminato",
                variant: "success",
                solid: true,
            });
        },
        onInvia() {
            let me = this;
            me.messaggio.prescrizione = [];
            for (let i in me.prescrizione) {
                me.messaggio.prescrizione.push(me.prescrizione[i]);
            }
            let link = process.env.VUE_APP_PATH_API + me.linkMessaggi;
            let jsonData = JSON.stringify(me.messaggio);
            axios
                .post(link, jsonData)
                .then(() => {
                    me.showmolalloading = false;
                    me.loadMessaggiInviati();
                    me.prescrizione = [];
                    me.messaggio.messaggio = "";
                })
                .catch((error) => {
                    me.showmolalloading = false;
                    this.$bvModal
                        .msgBoxOk(error.response.data.messaggio, {
                            title: "ATTENZIONE",
                            size: "sm",
                            okVariant: "outline-danger",
                            headerClass: "sa-msg-header-danger",
                            footerClass: "p-2 border-top-0",
                            centered: true,
                        })
                        .then((value) => {
                            this.boxTwo = value;
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                });
        },
        onClose() {
            let me = this;
            me.messaggio.messaggio = "";
            me.messaggio.emailDestinatario = "";
        },
        onActiveTabs(newTabIndex) {
            let me = this;
            if (newTabIndex === 1) {
                me.loadMessaggiInviati();
            }
        },
    },
};
</script>
