<template>
    <div>
        <b-row>
            <b-col>
                <span class="sa-form-label">{{ this.$i18n.t("patient.afa.lblTrofism") }}</span>
                <b-form-input v-model="data.trofismo" :disabled="!edit"></b-form-input>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <span class="sa-form-label">{{ this.$i18n.t("patient.afa.lblBody") }}</span>
                <b-form-input type="number" v-model="data.corpo" :disabled="!edit"></b-form-input>
                <span class="sa-form-label">{{ this.$i18n.t("patient.afa.lblBMI") }}</span>
                <b-form-input type="number" v-model="data.bmi" :disabled="!edit"></b-form-input>
            </b-col>
            <b-col>
                <span class="sa-form-label">{{ this.$i18n.t("patient.afa.lblWaist") }}</span>
                <b-form-input type="number" v-model="data.vita" :disabled="!edit"></b-form-input>
                <span class="sa-form-label">{{ this.$i18n.t("patient.afa.lblBSA") }}</span>
                <b-form-input type="number" v-model="data.bsa" :disabled="!edit"></b-form-input>
            </b-col>
            <b-col>
                <span class="sa-form-label">{{ this.$i18n.t("patient.afa.lblWeight") }}</span>
                <b-form-input type="number" v-model="data.peso" :disabled="!edit"></b-form-input> <span class="sa-form-label">{{ this.$i18n.t("patient.afa.lblGlycemia") }}</span>
                <b-form-input type="number" v-model="data.glicemia" :disabled="!edit"></b-form-input>
            </b-col>
            <b-col>
                <span class="sa-form-label">{{ this.$i18n.t("patient.afa.lblHeight") }}</span>
                <b-form-input type="number" v-model="data.altezza" :disabled="!edit"></b-form-input> <span class="sa-form-label">{{ this.$i18n.t("patient.afa.lblPAMax") }}</span> <b-form-input type="number" v-model="data.paMax" :disabled="!edit"></b-form-input>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="3">
                <span class="sa-form-label">{{ this.$i18n.t("patient.afa.lblPAMin") }}</span>
                <b-form-input type="number" v-model="data.paMin" :disabled="!edit"></b-form-input>
            </b-col>
            <b-col cols="3">
                <span class="sa-form-label">{{ this.$i18n.t("patient.afa.lblCholesterol") }}</span>
                <b-form-input type="number" v-model="data.colesterolo" :disabled="!edit"></b-form-input>
            </b-col>
            <b-col>
                <span class="sa-form-label">{{ this.$i18n.t("patient.afa.lblDiabetes") }}</span>
                <b-form-input v-model="data.diabete" :disabled="!edit"></b-form-input>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="5">
                <span class="sa-form-label">{{ this.$i18n.t("patient.afa.lblBloodGroup") }}</span>
                <b-form-input v-model="data.gruppoSanguigno" :disabled="!edit"></b-form-input>
                <span class="sa-form-label">{{ this.$i18n.t("patient.afa.lblCirculatorySystem") }}</span>
                <b-form-textarea rows="4" max-rows="9" v-model="data.apparatoCircolatorio" :disabled="!edit"></b-form-textarea>
                <span class="sa-form-label">{{ this.$i18n.t("patient.afa.lblLocomotorSystem") }}</span>
                <b-form-textarea rows="4" max-rows="9" v-model="data.apparatoLocomotorio" :disabled="!edit"></b-form-textarea>
            </b-col>
            <b-col></b-col>
            <b-col cols="5">
                <span class="sa-form-label">{{ this.$i18n.t("patient.afa.lblRHFactor") }}</span>
                <b-form-input v-model="data.fattoreRh" :disabled="!edit"></b-form-input>
                <span class="sa-form-label">{{ this.$i18n.t("patient.afa.lblRespiratorySystem") }}</span>
                <b-form-textarea rows="4" max-rows="9" v-model="data.apparatoRespiratorio" :disabled="!edit"></b-form-textarea>
                <span class="sa-form-label">{{ this.$i18n.t("patient.afa.lblAbdomenGeneralOrgans") }}</span>
                <b-form-textarea rows="4" max-rows="9" v-model="data.addomeOrgGen" :disabled="!edit"></b-form-textarea>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <span class="sa-form-label">{{ this.$i18n.t("patient.afa.lblLimbs") }}</span> <b-form-textarea rows="4" max-rows="9" v-model="data.arti" :disabled="!edit"></b-form-textarea>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <span class="sa-form-label">{{ this.$i18n.t("patient.afa.lblNaturalSightRightEye") }}</span>
                <b-form-input type="number" v-model="data.vistaNOd" :disabled="!edit"></b-form-input>
                <span class="sa-form-label">{{ this.$i18n.t("patient.afa.lblCurrentSightRightEye") }}</span>
                <b-form-input type="number" v-model="data.vistaCOd" :disabled="!edit"></b-form-input>
            </b-col>
            <b-col>
                <span class="sa-form-label">{{ this.$i18n.t("patient.afa.lblNaturalSightLeftEye") }}</span>
                <b-form-input type="number" v-model="data.vistaNOs" :disabled="!edit"></b-form-input>
                <span class="sa-form-label">{{ this.$i18n.t("patient.afa.lblCurrentSightLeftEye") }}</span>
                <b-form-input type="number" v-model="data.vistaCOs" :disabled="!edit"></b-form-input>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <span class="sa-form-label">{{ this.$i18n.t("patient.afa.lblCromaticSense") }}</span>
                <b-form-textarea rows="4" max-rows="9" v-model="data.sensoCromatico" :disabled="!edit"></b-form-textarea>
                <span class="sa-form-label">{{ this.$i18n.t("patient.afa.lblHearingSense") }}</span>
                <b-form-textarea rows="4" max-rows="9" v-model="data.udito" :disabled="!edit"></b-form-textarea>
                <span class="sa-form-label">{{ this.$i18n.t("patient.afa.lblExaminationConclusion") }}</span>
                <b-form-textarea rows="4" max-rows="9" v-model="data.conclusioniEsObiettivo" :disabled="!edit"></b-form-textarea>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <span class="sa-form-label">Stato</span><br />
                {{ data.firmaEo }}
            </b-col>
        </b-row>
    </div>
</template>

<script>
export default {
    props: ["data", "edit"],
    data() {
        return {};
    },
};
</script>
