<template>
  <b-card class="sc-card-max-height" style="height: 100%; width: 100%" no-body>
    <div class="sa-calendar-day-grid-slot">
      <table class="sa-calendar-day-grid-table-slot">
        <tr v-for="(row, i) in griglia" :key="i">
          <td v-for="(td, x) in row" :key="x">
            {{ td.dalleOre }} - {{ td.alleOre }}
            <agenda-slot
              :idAgenda="idAgenda"
              :costo="costo"
              :codicePrestazione="codicePrestazione"
              :descrizionePrestazione="descrizionePrestazione"
              :index="getIndex(i, x)"
              :slotDisponibilita="td"
              :riferimento="riferimento"
              @update="updateData"
            ></agenda-slot>
          </td>
        </tr>
      </table>
    </div>
  </b-card>
</template>
<script>
import AgendaSlot from "./AgendaSlot.vue";

export default {
  components: { AgendaSlot },

  props: {
    griglia: Array,
    disponibilita: {
      type: Array,
      default: function() {
        return [{ disponibilita: [] }];
      },
    },
    codicePrestazione: String,
    descrizionePrestazione: String,
    costo: Number,
    idAgenda: String,
    riferimento: String,
  },
  data() {
    return {};
  },
  mounted: function() {},
  methods: {
    getIndex(indexA, indexB) {
      return "day_griglia_" + indexA + "_" + indexB;
    },
    updateData() {
      let me = this;
      me.$emit("update");
    },
  },
};
</script>
<style scoped>
.sa-calendar-day-grid-slot {
  height: 100%;
  overflow-y: auto;
}
.sa-calendar-day-grid-table-slot {
  width: 100%;
  height: 100%;
}
.sa-calendar-day-grid-slot-hour {
  background-color: red;
}
.sa-calendar-day-grid-table-slot td {
  border: solid 1px rgb(194, 191, 191);
  border-collapse: collapse;
  vertical-align: top;
  padding: 5px;
}
</style>
