<template>
    <sa-page-list>
        <template slot="toolbar">
            <b-button class="float-sm-right " variant="success btn-toolbar sa-margin-right" size="sm" @click="onAdd"><b-icon icon="plus" variant="secondary"></b-icon>{{ this.$i18n.t("global.lblBtnNew") }}</b-button>
            <b-button class="float-sm-right " variant="info btn-toolbar" size="sm" @click="onRefresh" v-b-modal.modal-center><b-icon icon="arrow-clockwise" variant="secondary" />Refresh</b-button>
        </template>
        <template slot="table-filter">
            <b-form @submit.prevent="onSubmit">
                <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                    <b-col sm="6">
                        <b-form-input v-model="filtro.nome" type="search" id="nome" placeholder="Nome Ambulatorio"></b-form-input>
                    </b-col>
                    <b-col sm="6">
                        <b-form-input v-model="filtro.cognome" type="search" id="responsabile" placeholder="Responsabile Ambulatorio"></b-form-input>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="sa-padding-right text-right">
                        <b-button type="submit" variant="info">{{ this.$i18n.t("global.lblFind") }}</b-button>
                        <b-button type="reset" v-on:click="onReset" variant="danger">{{ this.$i18n.t("global.lblResetFilter") }}</b-button>
                    </b-col>
                </b-row>
            </b-form>
        </template>
        <template slot="table-header">
            <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col lg="3">
                    <p>{{ this.$i18n.t("clinic.lblTotalRecords") }}: {{ rows }}</p>
                </b-col>
                <b-col lg="9">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
            </b-row>
        </template>
        <template slot="table-body">
            <div class="b-table-sticky-header">
                <b-table sticky-header ref="table" :filter="filter" responsive stacked="lg" striped hover :items="items" :fields="fields" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                    <template v-slot:cell(nome)="{ item }">
                        <router-link class="sa-edit-link" :to="'/ambulatorio/view/' + item.id"> {{ item.nome }} </router-link>
                    </template>
                </b-table>
            </div>
        </template>
        <template slot="table-footer">
            <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col lg="3">
                    <p>{{ this.$i18n.t("clinic.lblTotalRecords") }}: {{ rows }}</p>
                </b-col>
                <b-col lg="9">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
            </b-row>
        </template>
    </sa-page-list>
</template>
<script>
import axios from "axios";
//import moment from "moment";
import { SaPageList } from "../../../template/sa/components/layout/contents";
export default {
    components: { SaPageList },
    computed: {
        rows() {
            return this.items.length;
        },
    },
    data() {
        return {
            perPage: 10,
            currentPage: 1,
            filter: null,
            id: "-1",
            pathRest: "/ambulatori",
            pathRESTRequest: "/ambulatorio",
            filtro: {},
            filterOn: [],
            fields: [
                {
                    label: this.$i18n.t("clinic.lblName"),
                    key: "nome",
                    sortable: true,
                    //thStyle: "width: 7rem",
                },
                {
                    label: this.$i18n.t("clinic.lblClinicManager"),
                    key: "responsabile",
                    //thStyle: "width: rem",
                    sortable: true,
                },
                {
                    label: this.$i18n.t("clinic.lblTelephoneNumber"),
                    key: "telefono",
                    sortable: true,
                },
                { class: "sa-table-column-action-single", key: "actions", label: "" },
            ],
            items: [],
        };
    },
    created: function() {},
    mounted: function() {
        let me = this;
        me.loadData();
    },
    methods: {
        loadData() {
            let me = this;
            // me.items = [{id:"2364848421511", nome:"Cardiologia", responsabile:"Antonio",telefono: "0823258998"},
            //            {id:"23648484232221",nome:"Diabetologia", responsabile:"Giovanni",telefono: "0823258998"}]
            let link = process.env.VUE_APP_PATH_API + me.pathRest;
            me.isBusy = true;
            axios
                .get(link, { params: me.filtro })
                .then((response) => {
                    me.items = [];
                    me.items = response.data.data;
                    //me.$refs["SaPageList"].close();
                })
                .catch(() => {
                    me.showmolalloading = false;
                });
        },
        onSubmit() {
            let me = this;
            let sessionStorage = window.sessionStorage;
            sessionStorage["filtro"] = JSON.stringify(me.filtro);
            me.loadData();
        },
        onReset() {
            let me = this;
            me.filtro = {};
            let sessionStorage = window.sessionStorage;
            sessionStorage["filtro"] = JSON.stringify(me.filtro);
            me.loadData();
        },
        onAdd() {
            let me = this;
            me.$router.replace(me.pathRESTRequest + "/edit/-1").catch((err) => {
                err;
            });
        },
        onRefresh() {
            let me = this;
            me.loadData();
        },
    },
};
</script>
