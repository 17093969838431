<template>
    <sa-layout-page>
        <sa-nav-bar :btnChatVisible="verificaPermessi" :image="avatar" :pageTitle="pageTitle" :flagimage="flagimage" :languageMenu="languageMenu" :userName="nominativoUtente" @update="onChatVisibleUpdate" />
        <sa-menu-paziente v-bind:externalmenu="menu" v-bind:nominativoUtente="nominativoUtente" :ruolo="ruolo" :idPaziente="id" />
        <sa-content v-sa-aside-toggle>
            <b-container slot="header" fluid>
                <b-row class="mb-2">
                    <b-col sm="6">
                        <h1 class="m-0 text-dark">{{ $t("login.welcome") }}</h1>
                    </b-col>
                    <b-col sm="6" class="d-flex justify-content-end">
                        <b-button-toolbar class="float-right" aria-label="Toolbar with button groups and dropdown menu">
                            <!--<b-button v-if="editButton" variant="outline-secondary sa-margin-left">Modifica</b-button>
                            <b-button v-if="undoButton" variant="outline-danger sa-margin-left invisible">Indietro</b-button>-->
                        </b-button-toolbar>
                    </b-col>
                </b-row>
            </b-container>

            <transition name="fade" mode="out-in">
                <router-view :utente="utente" />
            </transition>
        </sa-content>

        <div id="sidebar-overlay" v-sa-aside-toggle></div>
        <div><chat v-if="verificaPermessi" :utente="utente" :hidden="chatHidden" :btnChatVisible="btnChatVisible" @update="onChatVisibleUpdate"></chat></div>
    </sa-layout-page>
</template>
<script>
import axios from "axios";
import { SaNavBar } from "../../template/layout";
import Chat from "../../chat/componets/Chat.vue";
import { SaMenuPaziente } from "../../../components/menupaziente";
import UtilityMixin from "../../utility/UtilityMixin";
//import flagimage from
//import { SaModalLoading } from "../../components/modal";

export default {
    mixins: [UtilityMixin],
    name: "Main",
    components: { SaNavBar, SaMenuPaziente, Chat },
    computed: {
        title() {
            let title = process.env.VUE_APP_NAME;
            if (this.$route.meta && this.$route.meta.label) {
                title = this.$route.meta.label;
            } else if (this.$route.name) {
                title = this.$route.name;
            }
            return title;
        },
        breadcrumbs() {
            return this.$route.matched;
        },
        pageTitle() {
            let title = this.$i18n.t(this.$route.meta.title); //$i18n.t(this.$route.meta.title);
            return title;
        },
        verificaPermessi: {
            get: function() {
                return UtilityMixin.methods.verificaPermessi(this.utente, "chat", 1);
            },
            set: function() {},
        },
    },
    data() {
        return {
            id: "",
            flagimage: "",
            test: 0,
            avatar: "",
            undoButton: false,
            editButton: false,
            nominativoUtente: "",
            btnChatVisible: false,
            chatHidden: true,
            ruolo: "",
            utente: { gruppo: null },
            menu: [],
            languageMenu: [
                { flag: require("../../../assets/images/it.svg"), language: "it", languageDescription: "Italiano" },
                { flag: require("../../../assets/images/en.svg"), language: "en", languageDescription: "English" },
                { flag: require("../../../assets/images/hr.png"), language: "hr", languageDescription: "Hrvatski" },
                { flag: require("../../../assets/images/sv.svg"), language: "sv", languageDescription: "Svenska" },
                { flag: require("../../../assets/images/tr.svg"), language: "tr", languageDescription: "Türkçe" },
            ],
        };
    },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        if (this.$route.query.idPaziente !== undefined) {
            me.id = this.$route.query.idPaziente;
        }
        if (me.id === undefined) {
            me.id = null;
        }
        me.flagimage = require("../../../assets/images/" + this.$i18n.locale + ".svg");
        window.addEventListener("resize", this.onResize);
    },
    ready: function() {},
    created() {
        this.loadUserData();
        this.loadPatientMenu();
        this.updateSidebar();
        this.interval = setInterval(() => this.verificaSessione(), 180000);
    },
    destroyed() {},
    methods: {
        verificaSessione() {
            let link = process.env.VUE_APP_PATH_API + "/sessioncontrol";
            axios
                .get(link)
                .then(() => {})
                .catch(() => {
                    clearInterval(this.interval);
                    this.$router.push("/login");
                });
        },
        loadUserData() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + "/users/profile";
            axios
                .get(link)
                .then((response) => {
                    me.utente = response.data.data;
                    if (me.id === null) {
                        me.id = me.utente.id;
                    }
                    me.nominativoUtente = me.utente.lastname + " " + me.utente.firstname;
                    me.ruolo = me.utente.profilo;
                })
                .catch((e) => {
                    this.errors.push(e);
                });
        },
        loadPatientMenu() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + "/pazientemenu";
            axios
                .get(link, { params: { tipo: "left" } })
                .then((response) => {
                    me.menu = response.data.data;
                })
                .catch((e) => {
                    this.errors.push(e);
                });
        },
        onResize() {
            this.updateSidebar();
        },
        updateSidebar() {
            if (window.innerWidth > 1024) {
                document.body.classList.add("sidebar-mini");
                document.body.classList.add("layout-fixed");
                document.body.classList.remove("sidebar-closed");
                document.body.classList.remove("sidebar-collapse");
            } else if (window.innerWidth <= 1024) {
                document.body.classList.add("sidebar-mini");
                document.body.classList.add("layout-fixed");
                document.body.classList.add("sidebar-closed");
                document.body.classList.add("sidebar-collapse");
            }
        },
        onChatVisibleUpdate() {
            let me = this;
            console.log("Ciao Sisto");
            me.chatHidden = !me.chatHidden;
        },
    },
};
</script>
