<template>
  <sa-page-list :showmolalloading="showmolalloading">
    <template slot="toolbar-title">
      <paziente-anagrafica :linkPatient="linkPatient" :idPaziente="idPaziente"></paziente-anagrafica>
    </template>
    <template slot="toolbar">
      <b-button class="float-sm-right " variant="success btn-toolbar sa-margin-right" size="sm" @click="onAdd"><b-icon icon="plus" variant="secondary"></b-icon>{{ this.$i18n.t("global.lblBtnNew") }}</b-button>
      <b-button class="float-sm-right " variant="info btn-toolbar" size="sm" @click="onRefresh" v-b-modal.modal-center><b-icon icon="arrow-clockwise" variant="secondary" />Refresh</b-button>
    </template>
    <template slot="table-filter"> </template>
    <template slot="table-header">
      <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
        <b-col lg="3">
          <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
        </b-col>
        <b-col lg="9">
          <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
        </b-col>
      </b-row>
    </template>
    <template slot="table-body">
      <div class="b-table-sticky-header">
        <b-table sticky-header ref="table" :filter="filter" responsive stacked="lg" striped hover :items="items" :fields="fields" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
          <template v-slot:cell(dataEvento)="{ item }">
            <router-link class="sa-edit-link" :to="'/paziente/anamnesi/view/' + item.id + '?idPaziente=' + idPaziente"> {{ formatDate(item.dataEvento) }} </router-link>
          </template>
        </b-table>
      </div>
    </template>
    <template slot="table-footer">
      <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
        <b-col lg="3">
          <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
        </b-col>
        <b-col lg="9">
          <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
        </b-col>
      </b-row>
    </template>
  </sa-page-list>
</template>
<script>
import UtilityMixin from "../../utility/UtilityMixin";
import axios from "axios";
import { SaPageList } from "../../../template/sa/components/layout/contents";
import PazienteAnagrafica from "./PazienteAnagrafica.vue";
export default {
  props: {
    utente: {
      type: Object,
      default: function() {
        return null;
      },
    },
  },
  mixins: [UtilityMixin],
  components: { SaPageList, PazienteAnagrafica },
  computed: {
    idPaziente() {
      return this.$route.query.idPaziente;
    },
    rows() {
      return this.items.length;
    },
    linkPatient: {
      get: function() {
        if (this.utente.gruppo.menuGruppo) {
          if (this.utente.gruppo.menuGruppo.length > 1) {
            return "view/" + this.idPaziente;
          } else {
            if (this.utente.gruppo.menuGruppo.length == 1) {
              if (this.utente.gruppo.menuGruppo[0].primaryKey === "pazienti") {
                return "view/" + this.idPaziente;
              } else {
                return "homepaziente";
              }
            } else {
              return "";
            }
          }
        } else {
          return "";
        }
      },
    },
  },
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      filter: null,
      id: "-1",
      pathRest: "/pazienteeventoanamnesipatologica",
      pagelink: "/paziente/anamnesi",
      filtro: {},
      filterOn: [],
      showmolalloading: false,
      fields: [
        {
          label: "Data Evento",
          key: "dataEvento",
          sortable: true,
          //thStyle: "width: 7rem",
        },
        {
          label: "Firma",
          key: "firma",
          sortable: false,
          //thStyle: "width: rem",
        },
        {
          label: "Relatore",
          key: "createUser",
          sortable: true,
        },
        // { key: "actions", label: "", thStyle: "width: 8rem" },
      ],
      items: [],
    };
  },
  mounted() {
    let me = this;
    me.loadData();
  },
  methods: {
    loadData() {
      let me = this;
      me.showmolalloading = true;
      let link = process.env.VUE_APP_PATH_API + me.pathRest + "?idPaziente=";
      axios
        .get(link + me.idPaziente, { params: me.filtro })
        .then((response) => {
          me.items = [];
          me.items = response.data.data;
          me.showmolalloading = false;
        })
        .catch(() => {
          me.showmolalloading = false;
        });
    },
    onAdd() {
      let me = this;
      me.$router.replace(me.pagelink + "/edit/-1?idPaziente=" + me.idPaziente).catch((err) => {
        err;
      });
    },
    onRefresh() {
      let me = this;
      me.loadData();
    },
  },
};
</script>
