<template>
    <div>
        <div class="sa-form-section">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <h4>E.C.G A RIPOSO</h4>
                    <hr />
                    <b-row>
                        <b-col>
                            <span class="sa-form-label">Freq.Media</span>
                            <b-form-input placeholder="Frequenza Media" v-model="data.frequenzaMediaRiposo"></b-form-input>
                        </b-col>

                        <b-col>
                            <span class="sa-form-label">PR</span>
                            <b-form-input placeholder="Tratto PR" v-model="data.prRiposo"></b-form-input>
                        </b-col>
                        <b-col>
                            <span class="sa-form-label">QT</span>
                            <b-form-input placeholder="Tratto QT" v-model="data.qtRiposo"> </b-form-input>
                        </b-col>
                    </b-row>
                    <br />
                    <span class="sa-form-label">Referto</span>
                    <b-form-textarea v-model="data.refertoRiposo" placeholder="Referto Medico" no-resize></b-form-textarea>
                    <b-input-group style="margin-top: 5px">
                        <b-form-select class="mb-2 mr-sm-2 mb-sm-0" :options="refertoEcgARiposoOptions" :value="null" value-field="descrizione" text-field="descrizione" @input="onEcgARiposoInput($event)"></b-form-select>
                        <b-button variant="outline-secondary" style="margin-right: 5px;" size="sm" @click="onClickAggiungiDescrizione(data.refertoRiposo, linkRefertoEcgARiposo)"><b-icon icon="plus"></b-icon></b-button>
                        <b-button variant="outline-secondary" size="sm" @click="onDeleteDescrizione(data.refertoRiposo, linkRefertoEcgARiposo, refertoEcgARiposoOptions)"><b-icon icon="trash"></b-icon></b-button>
                    </b-input-group>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <h4>E.C.G DOPO SFORZO</h4>
                    <hr />
                    <b-row>
                        <b-col>
                            <span class="sa-form-label">Freq.Media</span>
                            <b-form-input placeholder="Frequenza Media" v-model="data.frequenzaMediaDopoSforzo"></b-form-input>
                        </b-col>

                        <b-col>
                            <span class="sa-form-label">PR</span>
                            <b-form-input v-model="data.prDopoSforzo" placeholder="Tratto PR"></b-form-input>
                        </b-col>
                        <b-col>
                            <span class="sa-form-label">QT</span>
                            <b-form-input v-model="data.qtDopoSforzo" placeholder="Tratto QT"></b-form-input>
                        </b-col>
                    </b-row>
                    <br />
                    <span class="sa-form-label">Referto</span>
                    <b-form-textarea placeholder="Referto Medico" v-model="data.refertoDopoSforzo" no-resize></b-form-textarea>
                    <b-input-group style="margin-top: 5px">
                        <b-form-select class="mb-2 mr-sm-2 mb-sm-0" :options="refertoEcgDopoSforzoOptions" :value="null" value-field="descrizione" text-field="descrizione" @input="onEcgDopoSforzoInput($event)"></b-form-select>
                        <b-button variant="outline-secondary" style="margin-right: 5px;" size="sm" @click="onClickAggiungiDescrizione(data.refertoDopoSforzo, linkRefertoEcgDopoSforzo)"><b-icon icon="plus"></b-icon></b-button>
                        <b-button variant="outline-secondary" size="sm" @click="onDeleteDescrizione(data.refertoDopoSforzo, linkRefertoEcgDopoSforzo, refertoEcgDopoSforzoOptions)"><b-icon icon="trash"></b-icon></b-button>
                    </b-input-group>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <h4>INDICE RAPIDO INDONIETA'</h4>
                    <hr />
                    <b-row>
                        <b-col>
                            <span class="sa-form-label">Durata Eserc.</span>
                            <b-form-input placeholder="In secondi" v-model="data.durataEsercizio" @change="onCalcoloIRI(data.durataEsercizio, data.numeroBattiti)"></b-form-input>
                        </b-col>
                        <b-col>
                            <span class="sa-form-label">Num.Battiti</span>
                            <b-form-input type="number" placeholder='da 60" a 90"' v-model="data.numeroBattiti" @change="onCalcoloIRI(data.durataEsercizio, data.numeroBattiti)"></b-form-input>
                        </b-col>
                        <b-col>
                            <span class="sa-form-label">I.R.I</span>
                            <b-form-input v-model="data.iri"></b-form-input>
                        </b-col>
                    </b-row>
                    <br />
                    <span class="sa-form-label">N.B.: I parametri "durata esercizio" e "numero battiti" consentono di calcolare automaticamente il valore relativo all'indice. </span>
                </b-col>
            </b-row>
            <div style="height: 80px"></div>
            <div>
                <b-table striped hover :items="items"></b-table>
            </div>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <strong>Stato </strong><br />
                            {{ data.firma }}
                        </b-col>
                    </b-row>
                    <hr />
                </b-col>
            </b-row>
        </div>
    </div>
</template>
<script>
import axios from "axios";
export default {
    props: {
        data: {
            Type: Object,
            default: function() {
                return {
                    frequenzaMediaRiposo: null,
                    prRiposo: null,
                    qtRiposo: null,
                    refertoRiposo: "",
                    frequenzaMediaDopoSforzo: null,
                    prDopoSforzo: null,
                    qtDopoSforzo: null,
                    refertoDopoSforzo: "",
                    durataEsercizio: null,
                    numeroBattiti: null,
                    iri: null,
                };
            },
        },
    },
    data() {
        return {
            linkRefertoEcgARiposo: "/visiteecgariposo",
            linkRefertoEcgDopoSforzo: "/visiteecgdoposforzo",
            refertoEcgARiposoOptions: [],
            refertoEcgDopoSforzoOptions: [],
            items: [
                {
                    Riposo: " ",
                    DuranteEsercizio: "",
                    DopoSforzo: " ",
                    "I.R.I": " ",
                },
            ],
        };
    },
    computed: {},
    mounted: function() {
        let me = this;
        me.id = this.$route.params.id;
        me.loadDefaultData();
    },
    methods: {
        loadDefaultData() {
            let me = this;
            me.loadEcgARiposo();
            me.loadEcgDopoSforzo();
        },
        onCalcoloIRI(durata, battiti) {
            let calcolo = (durata * 100) / (5.5 * battiti);
            this.data.iri = Math.round(calcolo);
        },

        loadEcgARiposo() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkRefertoEcgARiposo;
            axios.get(link).then((response) => {
                me.refertoEcgARiposoOptions = response.data.data;
            });
        },
        loadEcgDopoSforzo() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkRefertoEcgDopoSforzo;
            axios.get(link).then((response) => {
                me.refertoEcgDopoSforzoOptions = response.data.data;
            });
        },

        onEcgARiposoInput(value) {
            let me = this;
            me.data.refertoRiposo = value;
        },
        onEcgDopoSforzoInput(value) {
            let me = this;
            me.data.refertoDopoSforzo = value;
        },

        onClickAggiungiDescrizione(descrizione, path) {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + path;
            let jsonData = { descrizione: descrizione };
            axios
                .post(link, JSON.stringify(jsonData))
                .then((response) => {
                    this.$bvModal.msgBoxOk(response.data.messaggio, {
                        title: "",
                        size: "sm",
                        okVariant: "outline-danger",
                        headerClass: "sa-msg-header-danger",
                        footerClass: "p-2 border-top-0",
                        centered: true,
                    });
                    me.loadDefaultData();
                })
                .catch((e) => {
                    // console.log(e);
                    this.$bvModal
                        .msgBoxOk(e.response.data.messaggio, {
                            title: "ATTENZIONE",
                            size: "sm",
                            okVariant: "outline-danger",
                            headerClass: "sa-msg-header-danger",
                            footerClass: "p-2 border-top-0",
                            centered: true,
                        })
                        .then((value) => {
                            this.boxTwo = value;
                        });
                });
        },
        onDeleteDescrizione(descrizione, path, array) {
            let me = this;
            this.$bvModal
                .msgBoxConfirm("Sei sicuro di procedere alla cancellazione?", {
                    title: "Conferma Cancellazione",
                    size: "sm",
                    buttonSize: "sm",
                    okVariant: "secondary",
                    cancelVariant: "danger",
                    okTitle: "SI",
                    cancelTitle: "NO",
                    footerClass: "p-2",
                    hideHeaderClose: false,
                    centered: true,
                })
                .then((value) => {
                    if (value) {
                        let link = process.env.VUE_APP_PATH_API + path;
                        array.forEach((element) => {
                            if (element.descrizione === descrizione) {
                                axios.delete(link + "/" + element.id).then((response) => {
                                    this.$bvModal.msgBoxOk(response.data.messaggio, {
                                        title: "",
                                        size: "sm",
                                        okVariant: "outline-danger",
                                        headerClass: "sa-msg-header-danger",
                                        footerClass: "p-2 border-top-0",
                                        centered: true,
                                    });
                                    me.loadDefaultData();
                                });
                            } else if (descrizione === null || descrizione === "") {
                                this.$bvModal.msgBoxOk("Nessun elemento da cancellare.", {
                                    title: "Attenzione!",
                                    Text: "Nessun valore da cancellare!",
                                    size: "sm",
                                    okVariant: "outline-danger",
                                    headerClass: "sa-msg-header-danger",
                                    footerClass: "p-2 border-top-0",
                                    centered: true,
                                });
                            }
                        });
                    }
                })
                .catch((e) => {
                    this.$bvModal
                        .msgBoxOk(e.response.data.messaggio, {
                            title: "ATTENZIONE",
                            size: "sm",
                            okVariant: "outline-danger",
                            headerClass: "sa-msg-header-danger",
                            footerClass: "p-2 border-top-0",
                            centered: true,
                        })
                        .then((value) => {
                            this.boxTwo = value;
                        });
                });
        },
    },
};
</script>
