<template>
    <sa-page-view-edit ref="pgaView" :pathResource="pathResource" :backVisible="true" :editVisible="false" :saveVisible="false" :linkback="pagelink" :linkedit="linkedit">
        <template slot="filter">
            <div style="max-height: 18rem; overflow: auto;">
                <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                    <b-col lg="5">
                        <fieldset class="sa-fieldset-intestazione">
                            <legend>Dati Struttura:</legend>
                            <p><label>Struttura: </label>{{ data.accettazioneStruttura.denominazione }}</p>
                            <p><label>Indirizzo: </label>{{ data.accettazioneStruttura.indirizzo }} {{ data.accettazioneStruttura.localita }} {{ data.accettazioneStruttura.cap }}</p>
                            <p><label>Telefono: </label>{{ data.accettazioneStruttura.telefono }}</p>
                        </fieldset>
                    </b-col>
                    <b-col lg="1">
                        <b-button size="sm" variant="btn btn-purple waves-effect waves-light width-sm no-text" @click="onModificaStrutturaClick">
                            <b-icon icon="pencil"></b-icon>
                        </b-button>
                    </b-col>
                    <b-col lg="5">
                        <fieldset class="sa-fieldset-intestazione">
                            <legend>Dati Paziente:</legend>
                            <p><label>Paziente: </label>{{ data.accettazioneAnagrafica.nome }} {{ data.accettazioneAnagrafica.cognome }} {{ data.accettazioneAnagrafica.codiceFiscale }}</p>
                            <p><label>Telefono: </label>{{ data.accettazioneAnagrafica.telefono }}</p>
                            <p><label>Email: </label>{{ data.accettazioneAnagrafica.email }}</p>
                            <p><label>Indirizzo Residenza: </label>{{ data.accettazioneAnagrafica.indirizzoResidenza }} {{ data.accettazioneAnagrafica.comuneResidenza }} {{ data.accettazioneAnagrafica.capResidenza }}</p>
                        </fieldset>
                    </b-col>
                    <b-col lg="1">
                        <b-button size="sm" variant="btn btn-purple waves-effect waves-light width-sm no-text" @click="onModificaAnagraficaClick">
                            <b-icon icon="pencil"></b-icon>
                        </b-button>
                        <b-modal ref="mdlModificaAnagraficaAccettazione" id="mdlModificaAnagraficaAccettazione" size="xl" style="height: 100%;">
                            <div>
                                <accettazione-anagrafica-component :data="data" :link="indirizzoLink" @update="onUpdateData" style="display: block;"></accettazione-anagrafica-component>
                            </div>
                        </b-modal>
                    </b-col>
                </b-row>
            </div>
        </template>

        <template slot="body">
            <div style="display: flex; height: 100%;flex-direction: column;">
                <div style="max-height: 10rem"></div>
                <div style="height: 100%">
                    <div>
                        <b-tabs class="sa-tabs" content-class="mt-3" @activate-tab="onActiveTabs" fill>
                            <b-tab title="Lista Prestazioni">
                                <b-table sticky-header ref="tblPrestazioni" responsive stacked="lg" striped hover :items="data.accettazionePrestazioni" :fields="fieldsPrestazioni" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                                    <template #cell(index)="data">
                                        {{ data.index + 1 }}
                                    </template>
                                </b-table>
                            </b-tab>
                            <b-tab title="Lista Stati">
                                <b-table sticky-header ref="tblPrestazioni" responsive stacked="lg" striped hover :items="accettazioneStati" :fields="fieldsStati" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table"> </b-table>
                            </b-tab>
                        </b-tabs>
                    </div>
                </div>
            </div>
        </template>
        <template slot="footer"> </template>
    </sa-page-view-edit>
</template>

<script>
import AccettazioneAnagraficaComponent from "./AccettazioneAnagraficaComponent.vue";
import axios from "axios";
import moment from "moment";
require("moment/locale/it");
import { SaPageViewEdit } from "../../../template/sa/components/layout/contents";

moment.locale("it");
export default {
    components: { SaPageViewEdit, AccettazioneAnagraficaComponent },
    computed: {
        rows() {
            return this.items.length;
        },
        indirizzoLink() {
            return process.env.VUE_APP_PATH_API + this.pathResource + "/";
        },
    },
    data() {
        return {
            pathResource: "/accettazioni",
            pagelink: "/pazienteaccettazione",
            linkStruttura: "/accettazionestruttura",
            linkedit: null,
            currentPage: 1,
            perPage: null,
            fieldsPrestazioni: [
                {
                    label: "N.",
                    key: "index",
                    sortable: false,
                    thStyle: "width: 4rem",
                },
                {
                    label: "Descrizione",
                    key: "descrizione",
                    sortable: false,
                    thStyle: "width: 4rem",
                },
                {
                    label: "Quantita'",
                    key: "quantita",
                    sortable: false,
                    thStyle: "width: 4rem",
                },
                {
                    label: "Stato",
                    key: "statoAttuale",
                    sortable: false,
                    thStyle: "width: 4rem",
                },
                {
                    label: "Stato Attuale Data Ora",
                    key: "statoAttualeDataOra",
                    formatter: (value) => {
                        return moment(new Date(value)).format("DD-MM-YYYY");
                    },
                    sortable: false,
                    thStyle: "width: 4rem",
                },
                {
                    label: "Ambulatorio",
                    key: "accettazionePrestazioneAmbulatorio.nome",
                    sortable: false,
                    thStyle: "width: 4rem",
                },
            ],
            fieldsStati: [
                {
                    label: "Stato",
                    key: "stato",
                    sortable: false,
                    thStyle: "width: 4em",
                },
                {
                    label: "Data e Ora",
                    key: "dataOra",
                    formatter: (value) => {
                        return moment(new Date(value)).format("DD-MM-YYYY");
                    },
                    sortable: false,
                    thStyle: "width: 4em",
                },
            ],
            items: [],
            data: {
                accettazioneStruttura: { codiceStruttura: null, denominazione: "", indirizzo: "", localita: "", cap: "", telefono: "", idStruttura: "" },
                accettazioneAnagrafica: { cognome: "", nome: "", codiceFiscale: "", indirizzoResidenza: "", comuneResidenza: "", capResidenza: "", telefono: "", email: "" },
                accettazionePrestazioni: [{ descrizione: "", quantita: null, statoAttuale: "", statoAttualeDataOra: null, accettazionePrestazioneAmbulatorio: { nome: "" } }],
            },

            accettazioneStati: [],
        };
    },
    mounted: function() {
        let me = this;
        me.id = this.$route.params.id;
        me.linkedit = "/pazienteaccettazione/edit/" + me.id;
        this.loadData();
    },
    methods: {
        onUpdateData(newValue, filtro) {
            this.data = newValue;
            this.datiPaziente = filtro;
        },
        loadData() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
            axios
                .get(link + me.id)
                .then((response) => {
                    me.data = response.data.data;
                    // me.accettazioneStruttura = response.data.data.accettazioneStruttura;
                    // me.accettazioneAnagrafica = response.data.data.accettazioneAnagrafica;
                    //me.accettazionePrestazioni = response.data.data.accettazionePrestazioni;
                    me.accettazioneStati = response.data.data.accettazioneStati;
                })
                .catch(() => {
                    me.showmolalloading = false;
                });
        },
        formatDate(value) {
            if (value) {
                return moment(new Date(value)).format("DD-MM-YYYY");
            } else {
                return "---";
            }
        },
        onModificaAnagraficaClick() {
            let me = this;
            me.$refs.mdlModificaAnagraficaAccettazione.show();
        },
        onModificaStrutturaClick() {
            console.log("Hai Cliccato");
            // let me = this;
            // console.log(me.accettazioneStruttura.idStruttura);
            // let idStruttura = me.accettazioneStruttura.idStruttura;
            // me.$router.replace(me.linkStruttura + "/edit/" + idStruttura).catch((err) => {
            //     err;
            // });
        },
        onActiveTabs() {},
    },
};
</script>
<style lang="css">
/*.sa-documenti-azienda {
    font-size: 1.05em;
    font-weight: bold;
}

.sa-fieldset-intestazione legend {
    font-size: 1em;
    font-weight: bold;
    text-decoration: underline;
}
.sa-fieldset-intestazione p {
    margin-bottom: 0.5em;
    line-height: 1.1em;
}
.sa-fieldset-intestazione label {
    padding-right: 0.3em;
    margin-bottom: 0em;
}
.sa-documenti-table {
    font-size: 0.75rem;
}*/
</style>
