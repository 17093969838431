<template>
    <div>
        <div class="sa-form-section">
            <b-row>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <strong>{{ this.$i18n.t("patient.diabetology.lblHeight") }}</strong>
                    <br />
                    {{ jsonData.altezza }}
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <strong>{{ this.$i18n.t("patient.diabetology.lblWeight") }}</strong>
                    <br />
                    {{ jsonData.peso }}
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <strong>{{ this.$i18n.t("patient.diabetology.lblBloodPressure") }}</strong>
                    <br />
                    {{ jsonData.pressioneArteriosa }}
                </b-col>
            </b-row>
        </div>
        <hr />
        <div class="sa-form-section">
            <b-row>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <strong>{{ this.$i18n.t("patient.diabetology.lblHeartRate") }}</strong>
                    <br />
                    {{ jsonData.frequenzaCardiaca }}
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <strong>{{ this.$i18n.t("patient.diabetology.lblOutpatientBloodSugar") }}</strong>
                    <br />
                    {{ jsonData.glicemiaAmbulatoriale }}
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <strong>{{ this.$i18n.t("patient.diabetology.lblKetonuria") }}</strong>
                    <br />
                    {{ jsonData.chetonuria }}
                </b-col>
            </b-row>
        </div>
        <hr />

        <div class="sa-form-section">
            <b-row>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <strong>{{ this.$i18n.t("patient.diabetology.lblFructosamine") }}</strong>
                    <br />
                    {{ jsonData.fruttosamina }}
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <strong>{{ this.$i18n.t("patient.diabetology.lblGlycosylatedHemoglobin") }}</strong>
                    <br />
                    {{ jsonData.emoglobinaGlicosilata }}
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <strong>{{ this.$i18n.t("patient.diabetology.lblCalorieDiet") }}</strong>
                    <br />
                    {{ jsonData.dietaKcal }}
                </b-col>
            </b-row>
        </div>
        <hr />

        <div class="sa-form-section">
            <b-row>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <strong>{{ this.$i18n.t("patient.diabetology.lblHoursFromMeal") }}</strong>
                    <br />
                    {{ jsonData.oreDalPasto }}
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <strong>{{ this.$i18n.t("patient.diabetology.lblGlycemia") }}</strong>
                    <br />
                    {{ jsonData.glicemia }}
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <strong>{{ this.$i18n.t("patient.diabetology.lblFastingBloodGlucose") }}</strong>
                    <br />
                    {{ jsonData.glicemiaADigiuno }}
                </b-col>
            </b-row>
        </div>
        <hr />
        <div class="sa-form-section">
            <b-row>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <strong>{{ this.$i18n.t("patient.diabetology.lblGlycemiaBeforeLunch") }}</strong>
                    <br />
                    {{ jsonData.glicemiaPrimaPranzo }}
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <strong>{{ this.$i18n.t("patient.diabetology.lblBloodSugarTwoHoursAfterLunch") }}</strong>
                    <br />
                    {{ jsonData.glicemiaDueOreDopoPranzo }}
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <strong>{{ this.$i18n.t("patient.diabetology.lblGlycemiaBeforeSupper") }}</strong>
                    <br />
                    {{ jsonData.glicemiaPrimaCena }}
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <strong>{{ this.$i18n.t("patient.diabetology.lblbloodSugarTwoHoursAfterDinner") }}</strong>
                    <br />
                    {{ jsonData.glicemiaDueOreDopoCena }}
                </b-col>
            </b-row>
        </div>
        <hr />
        <div class="sa-form-section">
            <b-row>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <strong>{{ this.$i18n.t("patient.diabetology.lblhypoglycemiaWeek") }}</strong>
                    <br />
                    {{ jsonData.ipoglicemieSettimana }}
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <strong>{{ this.$i18n.t("patient.diabetology.lblHypoglycemicTherapy") }}</strong>
                    <br />
                    {{ jsonData.terapiaIpoglicemica }}
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <strong>{{ this.$i18n.t("patient.diabetology.lblHypoglycemicSchedule") }}</strong>
                    <br />
                    {{ jsonData.orarioIpoglicemie }}
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <strong>{{ this.$i18n.t("patient.diabetology.lblOtherMedicines") }}</strong>
                    <br />
                    {{ jsonData.altriFarmaci }}
                </b-col>
            </b-row>
        </div>
        <hr />
        <b-row>
            <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                <div class="sa-view-form-data"><user-information :data="jsonData"></user-information></div>
            </b-col>
        </b-row>
    </div>
</template>
<script>
import moment from "moment";
import UtilityMixin from "../../../../utility/UtilityMixin";
import UserInformation from "../../../../utenti/components/UserInformation.vue";
import axios from "axios";
export default {
    components: { UserInformation },
    mixins: [UtilityMixin],
    props: {
        idEvento: { type: String, require: true },
    },
    computed: {
        idPaziente() {
            return this.$route.query.idPaziente;
        },
    },
    data() {
        return {
            jsonData: {},
            pathResources: "/diabetologiafollowup",
        };
    },
    mounted() {
        let me = this;
        me.loadData();
    },
    methods: {
        formatDate(value) {
            if (value) {
                return moment(new Date(value)).format("DD-MM-YYYY HH:mm:ss");
            } else {
                return "---";
            }
        },

        loadData() {
            let me = this;
            if (me.id === "-1") {
                me.showmolalloading = false;
            } else {
                me.showmolalloading = true;
                let link = process.env.VUE_APP_PATH_API + me.pathResources + "/";

                axios.get(link + me.idEvento).then((response) => {
                    me.jsonData = response.data.data;
                });
            }
        },
    },
};
</script>
