<template>
<sa-page-view-edit :backVisible="true" :editVisible="true" :linkback="linkback" :linkedit="linkedit" :pathResource="pathResource" :data="data" :showmolalloading="showmolalloading">
    <template slot="header">
        <b-row>
            <b-col>
                <label>Causale Documento</label>
            </b-col>
        </b-row>
    </template>
    <template slot="body">
        <div style="max-height: 18rem; overflow: auto;">
            <b-row style="margin-left: 0.5rem; margin-right: 0.5rem; margin-top: 1rem">
                <b-col sm="4" md="4" lg="4">
                    <fieldset class="sa-fieldset-intestazione">
                        <label>Codice Tipo Documento: </label>
                        <p>{{ data.codiceTipoDocumento }}</p> <hr/>
                        <label>Codice Causale: </label>
                        <p>{{ data.codiceCausale }}</p> <hr/>
                        <label>Soggetti Collegati: </label>
                        <p>{{ data.soggettiCollegati }}</p> <hr/>
                    </fieldset>
                </b-col>
                <b-col sm="4" md="4" lg="4">
                    <fieldset class="sa-fieldset-intestazione">
                        <label>Descrizione Tipo Documento: </label>
                        <p>{{ data.descrizioneCausale }}</p> <hr/>
                        <label>Operazione Magazzino: </label>
                        <p>{{ data.operazioneMagazzino }}</p> <hr/>
                        <label>Documenti Collegati: </label>
                        <b-link href="#/tipidocumento"><p>{{ data.documentiCollegati }}</p></b-link> <hr/>
                    </fieldset>
                </b-col>
                <b-col sm="4" md="4" lg="4">
                    <fieldset class="sa-fieldset-intestazione">
                        <label>Operazione Contabile: </label>
                        <p>{{ data.operazioneContabile }}</p> <hr/>
                        <label>Operazione Magazzino: </label>
                        <p>{{ data.operazioneMagazzino }}</p> <hr/>
                    </fieldset>
                </b-col>
            </b-row>
        </div>
    </template>
</sa-page-view-edit>
</template>
<script>
import axios from "axios";
import SaPageViewEdit from '../../../template/sa/components/layout/contents/SaPageViewEdit.vue';
export default {
    components:{ SaPageViewEdit },
    data(){
        return{
            pathResource: "/causalidocumento",
            linkedit: null,
            linkback: "/causalidocumento",
            id:"",
            showmolalloading: false,
            data:{},
        }
    },
    mounted(){
        let me = this;
        me.id = this.$route.params.id;
        me.linkedit = "/causalidocumento/edit/" + me.id
        me.loadData();
    },
    methods:{
        loadData(){
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
            me.showmolalloading = true;
            axios
            .get(link + me.id)
            .then((response) => {
                me.data = response.data.data;
                me.showmolalloading = false;
            })
            .catch(() => {
                me.showmolalloading = false;
            }); 
        },
    },
}
</script>