<template>
    <div>
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <b-row>
                    <b-col cols="12" xs="12" sm="6" md="4" lg="2" xl="2">
                        <strong>Prog. Anno </strong><br />
                        {{ data.progressivoAnno }}
                    </b-col>
                    <b-col cols="12" xs="12" sm="6" md="4" lg="2" xl="2">
                        <strong>Data Visita </strong><br />
                        {{ formatDate(data.dataVisita) }}
                    </b-col>
                    <b-col cols="12" xs="12" sm="6" md="4" lg="2" xl="2">
                        <strong>Tipo Atleta </strong><br />
                        {{ data.tipoAttleta }}
                    </b-col>
                    <b-col cols="12" xs="12" sm="6" md="4" lg="2" xl="2">
                        <strong>Num. Certificato </strong><br />
                        {{ data.numeroCertificato }}
                    </b-col>
                    <b-col cols="12" xs="12" sm="6" md="4" lg="2" xl="2">
                        <strong>Validità </strong><br />
                        {{ data.validitaCertificato }}
                    </b-col>
                    <b-col cols="12" xs="12" sm="6" md="4" lg="2" xl="2">
                        <strong>Data Scadenza </strong><br />
                        {{ formatDate(data.dataScadenza) }}
                    </b-col>
                </b-row>
                <hr />
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <b-row>
                    <b-col cols="12" xs="12" sm="6" md="4" lg="2" xl="2">
                        <strong>Urine</strong><br />
                        <span :class="getColorUrine()"><b-icon class="sa-size-icon-15" :icon="getIconUrine()"></b-icon></span>
                    </b-col>
                    <b-col cols="12" xs="12" sm="6" md="4" lg="2" xl="2">
                        <strong>Spirografia</strong><br />
                        <span :class="getColorSpirografia()"><b-icon class="sa-size-icon-15" :icon="getIconSpirografia()"></b-icon></span>
                    </b-col>
                    <b-col cols="12" xs="12" sm="6" md="4" lg="2" xl="2">
                        <strong>E.C.G.</strong><br />
                        <span :class="getColorEcg()"><b-icon class="sa-size-icon-15" :icon="getIconEcg()"></b-icon></span>
                    </b-col>
                    <b-col cols="12" xs="12" sm="6" md="4" lg="2" xl="2">
                        <strong>Provenienza </strong><br />
                        {{ data.provenienza }}
                    </b-col>
                    <b-col cols="12" xs="12" sm="6" md="4" lg="2" xl="2">
                        <strong>Sport </strong><br />
                        {{ data.sport }}
                    </b-col>
                    <b-col cols="12" xs="12" sm="6" md="4" lg="2" xl="2">
                        <strong>Tipo Visita </strong><br />
                        {{ data.tipoVisita }}
                    </b-col>
                </b-row>
                <hr />
            </b-col>
        </b-row>
        <b-row>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <strong>Stato</strong><br />
                    <span><b-icon class="sa-size-icon-15"></b-icon>&nbsp;{{ statoText }}</span>
                </b-col>
            </b-row>
        </b-row>
    </div>
</template>
<script>
import UtilityMixin from "../../utility/UtilityMixin";
export default {
    mixins: [UtilityMixin],
    props: {
        data: {
            Type: Object,
            default: function() {
                return {
                    progressivoAnno: null,
                    dataVisita: null,
                    tipoAttleta: "",
                    numeroCertificato: "",
                    validitaCertificato: null,
                    dataScadenza: null,
                    provenienza: "",
                    sport: "",
                    tipoVisita: "",
                };
            },
        },
    },
    data() {
        return {
            statoText: "",
            statoIcon: "exclamation-triangle",
            statoColor: "text-danger",
        };
    },
    methods: {
        setFormatStato() {
            let me = this;
            me.statoColor = "text-success";
            me.statoIcon = "check2";
            me.statoText = "COMPLETO";
            switch (me.data.statoAttuale) {
                case "ATTESA_ACCERTAMENTI":
                    me.statoText = "IN ATTESA DI ACCERTAMENTI DIAGNOSTICI SUPPLEMENTARI";
                    me.statoIcon = "exclamation-triangle";
                    me.statoColor = "text-danger";
                    break;
            }
        },

        getColorUrine() {
            let me = this;
            let color = null;
            if (me.data.visitaEsameUrine.firma !== null) {
                color = me.data.urineMancanti = "text-sucess";
            } else {
                color = me.data.urineMancanti = "text-danger";
            }
            return color;
        },
        getIconUrine() {
            let me = this;
            let icon = null;
            if (me.data.visitaEsameUrine.firma !== null) {
                icon = me.data.urineMancanti = "check2";
            } else {
                icon = me.data.urineMancanti = "exclamation-triangle";
            }
            return icon;
        },

        getColorSpirografia() {
            let me = this;
            let color = null;
            if (me.data.visitaSpirografia.firma !== null) {
                color = me.data.spirografiaNonEseguita = "text-sucess";
            } else {
                color = me.data.spirografiaNonEseguita = "text-danger";
            }
            return color;
        },
        getIconSpirografia() {
            let me = this;
            let icon = null;
            if (me.data.visitaSpirografia.firma !== null) {
                icon = me.data.spirografiaNonEseguita = "check2";
            } else {
                icon = me.data.spirografiaNonEseguita = "exclamation-triangle";
            }
            return icon;
        },

        getIconEcg() {
            let me = this;
            let icon = null;
            if (me.data.visitaEcg.firma !== null) {
                icon = me.data.ecgNonEseguito = "check2";
            } else {
                icon = me.data.ecgNonEseguito = "exclamation-triangle";
            }
            return icon;
        },
        getColorEcg() {
            let me = this;
            let color = null;
            if (me.data.visitaEcg.firma !== null) {
                color = me.data.ecgNonEseguito = "text-sucess";
            } else {
                color = me.data.ecgNonEseguito = "text-danger";
            }
            return color;
        },
    },
};
</script>
