import BancaEmaticaList from "./components/BancaEmaticaList.vue";
import BancaEmaticaEdit from "./components/BancaEmaticaEdit.vue";
import BancaEmaticaView from "./components/BancaEmaticaView.vue";

import BancaEmaticaRichiestaScorteList from "./components/BancaEmaticaRichiestaScorteList.vue";
import BancaEmaticaRichiestaScorteView from "./components/BancaEmaticaRichiestaScorteView.vue";
import BancaEmaticaRichiestaScorteEdit from "./components/BancaEmaticaRichiestaScorteEdit.vue";


import BancaEmaticaRichiesteRepartoList from "./components/BancaEmaticaRichiesteRepartoList.vue"
import BancaEmaticaRichiesteRepartoEdit from "./components/BancaEmaticaRichiesteRepartoEdit.vue"
import BancaEmaticaRichiesteRepartoView from "./components/BancaEmaticaRichiesteRepartoView.vue"

export default {
    BancaEmaticaList,
    BancaEmaticaEdit,
    BancaEmaticaView,
    BancaEmaticaRichiesteRepartoList,
    BancaEmaticaRichiesteRepartoEdit,
    BancaEmaticaRichiesteRepartoView,
    BancaEmaticaRichiestaScorteList,
    BancaEmaticaRichiestaScorteView,
    BancaEmaticaRichiestaScorteEdit,
};
