<template>
    <sa-page-view-edit ref="pgaView" :data="data" :pathResource="pathResource" :backVisible="true" :saveVisible="true" :titolo="data.titolo" :linkback="linkback" :linkedit="linkedit" :showmolalloading="showmolalloading">
        <template slot="body">
            <b-row>
                <b-col cols="12" lg="2" md="2" class="sa-text-right">Data Avvio:</b-col>
                <b-col cols="12" lg="10" md="10" class="sa-text-left">
                    <date-picker format="DD-MM-YYYY HH:mm:sss" value-type="timestamp" v-model="data.data" type="datetime"></date-picker>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" lg="2" md="2" class="sa-text-right">Titolo:</b-col>
                <b-col cols="12" lg="10" md="10" class="sa-text-left">
                    <b-form-input v-model="data.titolo" size="sm" placeholder="Titolo" required></b-form-input>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" lg="2" md="2" class="sa-text-right">Messaggio:</b-col>
                <b-col cols="12" lg="10" md="10" class="sa-text-left">
                    <b-form-textarea id="textarea" v-model="data.testo" placeholder="Testo del Messaggio..." rows="7" required></b-form-textarea>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" lg="2" md="2" class="sa-text-right">Note:</b-col>
                <b-col cols="12" lg="10" md="10" class="sa-text-left">
                    <b-form-textarea id="textarea" v-model="data.note" placeholder="Note..." rows="7"></b-form-textarea>
                </b-col>
            </b-row>
        </template>
    </sa-page-view-edit>
</template>
<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import { SaPageViewEdit } from "../../../template/sa/components/layout/contents";
import moment from "moment";
import axios from "axios";
export default {
    components: { SaPageViewEdit, DatePicker },
    data() {
        return {
            pathResource: "/inviosms",
            linkback: "/sms",
            linkedit: null,
            showmolalloading: false,
            id: "-1",
            data: {},
        };
    },
    computed: {
        dataAavvioData: {
            get: function() {
                return new Date(this.data.avvioData);
            },
            set: function(value) {
                this.data.avvioData = moment(String(value)).valueOf();
            },
        },
    },
    mounted: function() {
        let me = this;
        me.id = this.$route.params.id;
        if (me.id !== "-1") {
            me.linkback = me.linkback + "/view/" + me.id;
        }
        me.loadData();
    },
    methods: {
        formatDate(value) {
            if (value) {
                return moment(new Date(value)).format("DD-MM-YYYY HH:mm:ss");
            } else {
                return "---";
            }
        },
        loadData() {
            let me = this;
            me.showmolalloading = true;
            if (me.id === "-1") {
                me.linkback = "/sms";
                me.showmolalloading = false;
            } else {
                me.linkback = "/sms/view/" + me.id;
                me.showmolalloading = true;
                let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
                axios.get(link + me.id).then((response) => {
                    me.data = response.data.data;
                    me.showmolalloading = false;
                });
            }
        },
        onSave() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource;
            let jsonData = JSON.stringify(me.data);

            me.showmolalloading = true;
            if (me.id == "-1") {
                axios
                    .post(link, jsonData)
                    .then((response) => {
                        me.data = response.data.data;
                        me.showmolalloading = false;
                        me.$router.replace(me.routeLink).catch((err) => {
                            err;
                        });
                    })
                    .catch((error) => {
                        me.showmolalloading = false;
                        this.$bvModal
                            .msgBoxOk(error.response.data.messaggio, {
                                title: "ATTENZIONE",
                                size: "sm",
                                okVariant: "outline-danger",
                                headerClass: "sa-msg-header-danger",
                                footerClass: "p-2 border-top-0",
                                centered: true,
                            })
                            .then((value) => {
                                this.boxTwo = value;
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                    });
            } else {
                axios
                    .put(link + "/" + me.id, jsonData)
                    .then((response) => {
                        me.gruppo = response.data.data;
                        me.$refs["sa-modal-loading"].close();
                        me.$router.replace(me.routeLink).catch((err) => {
                            err;
                        });
                    })
                    .catch((error) => {
                        me.$refs["sa-modal-loading"].close();
                        this.$bvModal
                            .msgBoxOk(error.response.data.messaggio, {
                                title: "ATTENZIONE",
                                size: "sm",
                                okVariant: "outline-danger",
                                headerClass: "sa-msg-header-danger",
                                footerClass: "p-2 border-top-0",
                                centered: true,
                            })
                            .then((value) => {
                                this.boxTwo = value;
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                    });
            }
        },
        onBack() {
            let me = this;
            me.$router.replace(me.routeLink).catch((err) => {
                err;
            });
        },
    },
};
</script>
