<template>
    <div>
        <div v-if="orientation === 'Vertical'">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <div :class="'sa-card-border-' + getPressureRange() + ''" align="center">
                        <div :key="'card-body text-warning'">
                            <b-row>
                                <b-col cols="2">
                                    <h5 :class="'card-title text-warning'" @click="onDashboard"><img :src="checkimage('Systolic Blood Pressure (mmHg)')" width="35" height="35" alt="" /></h5>
                                </b-col>
                                <b-col cols="10">
                                    <p style="margin-top: 3px; margin-left: 10px; font-size: 12px;">{{ this.$i18n.t("patient.card.lblPressure") }}</p>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col cols="12">
                                    <p style="margin-top: 3px; margini-left: 10px; font-size: 12px">{{ getDate("Systolic Blood Pressure (mmHg)") }}</p>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col cols="6">
                                    {{ this.$i18n.t("patient.card.lblSystolic") }}:
                                    <span :class="'sa-text-misura-value badge badge-' + getMisuraRange('Systolic Blood Pressure (mmHg)') + ' badge-pill'">{{ getMisuraValue("Systolic Blood Pressure (mmHg)") }}</span>
                                </b-col>
                                <b-col cols="6">
                                    {{ this.$i18n.t("patient.card.lblDiastolic") }}:
                                    <span :class="'sa-text-misura-value badge badge-' + getMisuraRange('Diastolic Blood Pressure (mmHg)') + ' badge-pill'">{{ getMisuraValue("Diastolic Blood Pressure (mmHg)") }}</span>
                                </b-col>
                            </b-row>
                        </div>
                    </div>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <div :class="'sa-card-border-' + getSleepRange() + ''" align="center">
                        <div :key="'card-body text-warning'">
                            <b-row>
                                <b-col cols="2">
                                    <h5 :class="'card-title text-warning'" @click="onDashboard"><img :src="checkimage('wakeupcount')" width="35" height="35" alt="" /></h5>
                                </b-col>
                                <b-col cols="10">
                                    <p style="margin-top: 3px; margin-left: 10px; font-size: 12px;">{{ checkName("sleep_efficiency") }}</p>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col cols="12">
                                    <p style="margin-top: 3px; margini-left: 10px; font-size: 12px">{{ getDate("sleep_efficiency") }}</p>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col cols="6">
                                    {{ this.$i18n.t("patient.card.lblHours") }}:
                                    <span :class="'sa-text-misura-value badge badge-' + getMisuraRange('total_sleep_time') + ' badge-pill'">{{ convertHour(getMisuraValue("total_sleep_time")) }}</span>
                                </b-col>
                                <b-col cols="6">
                                    {{ this.$i18n.t("patient.card.lblInterruptions") }}: <span :class="'sa-text-misura-value badge badge-' + getMisuraRange('wakeupcount') + ' badge-pill'">{{ getMisuraValue("wakeupcount") }}</span>
                                </b-col>
                            </b-row>
                        </div>
                    </div>
                </b-col>
            </b-row>
            <!--<b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <div :class="'sa-card-border-' + getHrRange() + ''" align="center">
                        <div :key="'card-body text-warning'">
                            <b-row>
                                <b-col cols="2">
                                    <h5 :class="'card-title text-warning'" @click="onDashboard"><img :src="checkimage('hr_min')" width="35" height="35" alt="" /></h5>
                                </b-col>
                                <b-col cols="10">
                                    <p style="margin-top: 3px; margin-left: 10px; font-size: 12px;">{{ checkName("hr") }}</p>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col cols="12">
                                    <p style="margin-top: 3px; margini-left: 10px; font-size: 12px">{{ getDate("hr_max") }}</p>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col cols="6">
                                    {{ this.$i18n.t("patient.card.lblMin") }}: <span :class="'sa-text-misura-value badge badge-' + getMisuraRange('hr_min') + ' badge-pill'">{{ getMisuraValue("hr_min") }}</span>
                                </b-col>
                                <b-col cols="6">
                                    {{ this.$i18n.t("patient.card.lblMax") }}: <span :class="'sa-text-misura-value badge badge-' + getMisuraRange('hr_max') + ' badge-pill'">{{ getMisuraValue("hr_max") }}</span>
                                </b-col>
                            </b-row>
                        </div>
                    </div>
                </b-col>
            </b-row>-->
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <div :class="'sa-card-border-' + getMisuraRange('steps') + ''" align="center">
                        <div :key="'card-body text-warning'">
                            <b-row>
                                <b-col cols="2">
                                    <h5 :class="'card-title text-warning'" @click="onDashboard"><img :src="checkimage('steps')" width="35" height="35" alt="" /></h5>
                                </b-col>
                                <b-col cols="10">
                                    <p style="margin-top: 3px; margin-left: 10px; font-size: 12px;">{{ checkName("steps") }}</p>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col cols="12">
                                    <p style="margin-top: 3px; margini-left: 10px; font-size: 12px">{{ getDate("steps") }}</p>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col cols="12">
                                    {{ this.$i18n.t("patient.card.lblNum") }}: <span :class="'sa-text-misura-value badge badge-' + getMisuraRange('steps') + ' badge-pill'">{{ getMisuraValue("steps") }}</span>
                                </b-col>
                            </b-row>
                        </div>
                    </div>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <div :class="'sa-card-border-' + getMisuraRange('Weight (kg)') + ''" align="center">
                        <div :key="'card-body text-warning'">
                            <b-row>
                                <b-col cols="2">
                                    <h5 :class="'card-title text-warning'" @click="onDashboard"><img :src="checkimage('Weight (kg)')" width="35" height="35" alt="" /></h5>
                                </b-col>
                                <b-col cols="10">
                                    <p style="margin-top: 3px; margin-left: 10px; font-size: 12px;">{{ checkName("Weight (kg)") }}</p>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col cols="12">
                                    <p style="margin-top: 3px; margini-left: 10px; font-size: 12px">{{ getDate("Weight (kg)") }}</p>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col cols="12">
                                    {{ this.$i18n.t("patient.card.lblNum") }}: <span :class="'sa-text-misura-value badge badge-' + getMisuraRange('Weight (kg)') + ' badge-pill'">{{ getMisuraValue("Weight (kg)") }}</span>
                                </b-col>
                            </b-row>
                        </div>
                    </div>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <div :class="'sa-card-border-' + getMisuraRange('RiskCalculationResult') + ''" align="center">
                        <div :key="'card-body text-warning'">
                            <b-row>
                                <b-col cols="2">
                                    <h5 :class="'card-title text-warning'" @click="onDashboard"><img :src="checkimage('RiskCalculationResult')" width="35" height="35" alt="" /></h5>
                                </b-col>
                                <b-col cols="10">
                                    <p style="margin-top: 3px; margin-left: 10px; font-size: 12px;">{{ checkName("RiskCalculationResult") }}</p>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col cols="12">
                                    <p style="margin-top: 3px; margini-left: 10px; font-size: 12px">{{ getDate("RiskCalculationResult") }}</p>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col cols="12">
                                    {{ this.$i18n.t("patient.card.lblNum") }}:
                                    <span :class="'sa-text-misura-value badge badge-' + getMisuraRange('RiskCalculationResult') + ' badge-pill'">{{ getMisuraValue("RiskCalculationResult") }}</span>
                                </b-col>
                            </b-row>
                        </div>
                    </div>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <div :class="'sa-card-border-' + getMisuraRange('Adherence') + ''" align="center">
                        <div :key="'card-body text-warning'">
                            <b-row>
                                <b-col cols="2">
                                    <h5 :class="'card-title text-warning'" @click="onDashboard"><img :src="checkimage('Adherence')" width="35" height="35" alt="" /></h5>
                                </b-col>
                                <b-col cols="10">
                                    <p style="margin-top: 3px; margin-left: 10px; font-size: 12px; white-space: nowrap;">{{ checkName("Adherence") }}</p>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col cols="12">
                                    <p style="margin-top: 3px; margini-left: 10px; font-size: 12px" ali>{{ getDate("Adherence") }}</p>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col cols="12">
                                    {{ this.$i18n.t("patient.card.lblNum") }}:
                                    <span :class="'sa-text-misura-value badge badge-' + getMisuraRange('Adherence') + ' badge-pill'">{{ getMisuraValue("Adherence") }}</span>
                                </b-col>
                            </b-row>
                        </div>
                    </div>
                </b-col>
            </b-row>
        </div>
        <div v-else>
            <b-row>
                <b-col cols="12" xs="12" sm="6" md="4" lg="4" xl="2">
                    <div :class="'sa-card-border-' + getPressureRange() + ''" align="center">
                        <div :key="'card-body text-warning'">
                            <b-row>
                                <b-col cols="2">
                                    <h5 :class="'card-title text-warning'" @click="onDashboard"><img :src="checkimage('Systolic Blood Pressure (mmHg)')" width="35" height="35" alt="" /></h5>
                                </b-col>
                                <b-col cols="10">
                                    <p style="margin-top: 3px; margin-left: 10px; font-size: 12px;">{{ this.$i18n.t("patient.card.lblPressure") }}</p>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col cols="12">
                                    <p style="margin-top: 3px; margini-left: 10px; font-size: 12px">{{ getDate("Systolic Blood Pressure (mmHg)") }}</p>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col cols="6">
                                    {{ this.$i18n.t("patient.card.lblSystolic") }}:
                                    <span :class="'sa-text-misura-value badge badge-' + getMisuraRange('Systolic Blood Pressure (mmHg)') + ' badge-pill'">{{ getMisuraValue("Systolic Blood Pressure (mmHg)") }}</span>
                                </b-col>
                                <b-col cols="6">
                                    {{ this.$i18n.t("patient.card.lblDiastolic") }}:
                                    <span :class="'sa-text-misura-value badge badge-' + getMisuraRange('Diastolic Blood Pressure (mmHg)') + ' badge-pill'">{{ getMisuraValue("Diastolic Blood Pressure (mmHg)") }}</span>
                                </b-col>
                            </b-row>
                        </div>
                    </div>
                </b-col>
                <b-col cols="12" xs="12" sm="6" md="4" lg="4" xl="2">
                    <div :class="'sa-card-border-' + getSleepRange() + ''" align="center">
                        <div :key="'card-body text-warning'">
                            <b-row>
                                <b-col cols="2">
                                    <h5 :class="'card-title text-warning'" @click="onDashboard"><img :src="checkimage('wakeupcount')" width="35" height="35" alt="" /></h5>
                                </b-col>
                                <b-col cols="10">
                                    <p style="margin-top: 3px; margin-left: 10px; font-size: 12px;">{{ checkName("sleep_efficiency") }}</p>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col cols="12">
                                    <p style="margin-top: 3px; margini-left: 10px; font-size: 12px">{{ getDate("sleep_efficiency") }}</p>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col cols="6">
                                    {{ this.$i18n.t("patient.card.lblHours") }}:
                                    <span :class="'sa-text-misura-value badge badge-' + getMisuraRange('total_sleep_time') + ' badge-pill'">{{ convertHour(getMisuraValue("total_sleep_time")) }}</span>
                                </b-col>
                                <b-col cols="6">
                                    {{ this.$i18n.t("patient.card.lblInterruptions") }}: <span :class="'sa-text-misura-value badge badge-' + getMisuraRange('wakeupcount') + ' badge-pill'">{{ getMisuraValue("wakeupcount") }}</span>
                                </b-col>
                            </b-row>
                        </div>
                    </div>
                </b-col>
                <!--<b-col cols="12" xs="12" sm="6" md="4" lg="4" xl="2">
                    <div :class="'sa-card-border-' + getHrRange() + ''" align="center">
                        <div :key="'card-body text-warning'">
                            <b-row>
                                <b-col cols="2">
                                    <h5 :class="'card-title text-warning'" @click="onDashboard"><img :src="checkimage('hr_min')" width="35" height="35" alt="" /></h5>
                                </b-col>
                                <b-col cols="10">
                                    <p style="margin-top: 3px; margin-left: 10px; font-size: 12px; align: center;">{{ checkName("hr") }}</p>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col cols="12">
                                    <p style="margin-top: 3px; margini-left: 15px; font-size: 12px">{{ getDate("hr_min") }}</p>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col cols="6">
                                    {{ this.$i18n.t("patient.card.lblMin") }}: <span :class="'sa-text-misura-value badge badge-' + getMisuraRange('hr_min') + ' badge-pill'">{{ getMisuraValue("hr_min") }}</span>
                                </b-col>
                                <b-col cols="6">
                                    {{ this.$i18n.t("patient.card.lblMax") }}: <span :class="'sa-text-misura-value badge badge-' + getMisuraRange('hr_max') + ' badge-pill'">{{ getMisuraValue("hr_max") }}</span>
                                </b-col>
                            </b-row>
                        </div>
                    </div>
                </b-col>-->
                <b-col cols="12" xs="12" sm="6" md="4" lg="4" xl="2">
                    <div :class="'sa-card-border-' + getMisuraRange('steps') + ''" align="center">
                        <div :key="'card-body text-warning'">
                            <b-row>
                                <b-col cols="2">
                                    <h5 :class="'card-title text-warning'" @click="onDashboard"><img :src="checkimage('steps')" width="35" height="35" alt="" /></h5>
                                </b-col>
                                <b-col cols="10">
                                    <p style="margin-top: 3px; margin-left: 10px; font-size: 12px;">{{ checkName("steps") }}</p>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col cols="12">
                                    <p style="margin-top: 3px; margini-left: 10px; font-size: 12px">{{ getDate("steps") }}</p>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col cols="12">
                                    {{ this.$i18n.t("patient.card.lblNum") }}: <span :class="'sa-text-misura-value badge badge-' + getMisuraRange('steps') + ' badge-pill'">{{ getMisuraValue("steps") }}</span>
                                </b-col>
                            </b-row>
                        </div>
                    </div>
                </b-col>
                <b-col cols="12" xs="12" sm="6" md="4" lg="4" xl="2">
                    <div :class="'sa-card-border-' + getMisuraRange('Weight (kg)') + ''" align="center">
                        <div :key="'card-body text-warning'">
                            <b-row>
                                <b-col cols="2">
                                    <h5 :class="'card-title text-warning'" @click="onDashboard"><img :src="checkimage('Weight (kg)')" width="35" height="35" alt="" /></h5>
                                </b-col>
                                <b-col cols="10">
                                    <p style="margin-top: 3px; margin-left: 10px; font-size: 12px;">{{ checkName("Weight (kg)") }}</p>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col cols="12">
                                    <p style="margin-top: 3px; margini-left: 10px; font-size: 12px">{{ getDate("Weight (kg)") }}</p>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col cols="12">
                                    {{ this.$i18n.t("patient.card.lblNum") }}: <span :class="'sa-text-misura-value badge badge-' + getMisuraRange('Weight (kg)') + ' badge-pill'">{{ getMisuraValue("Weight (kg)") }}</span>
                                </b-col>
                            </b-row>
                        </div>
                    </div>
                </b-col>
                <b-col cols="12" xs="12" sm="6" md="4" lg="4" xl="2">
                    <div :class="'sa-card-border-' + getMisuraRange('RiskCalculationResult') + ''" align="center">
                        <div :key="'card-body text-warning'">
                            <b-row>
                                <b-col cols="2">
                                    <h5 :class="'card-title text-warning'" @click="onDashboard"><img :src="checkimage('RiskCalculationResult')" width="35" height="35" alt="" /></h5>
                                </b-col>
                                <b-col cols="10">
                                    <p style="margin-top: 3px; margin-left: 10px; font-size: 12px;">{{ checkName("RiskCalculationResult") }}</p>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col cols="12">
                                    <p style="margin-top: 3px; margini-left: 10px; font-size: 12px" ali>{{ getDate("RiskCalculationResult") }}</p>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col cols="12">
                                    {{ this.$i18n.t("patient.card.lblNum") }}:
                                    <span :class="'sa-text-misura-value badge badge-' + getMisuraRange('RiskCalculationResult') + ' badge-pill'">{{ getMisuraValue("RiskCalculationResult") }}</span>
                                </b-col>
                            </b-row>
                        </div>
                    </div>
                </b-col>
                <b-col cols="12" xs="12" sm="6" md="4" lg="4" xl="2">
                    <div :class="'sa-card-border-' + getMisuraRange('Adherence') + ''" align="center">
                        <div :key="'card-body text-warning'">
                            <b-row>
                                <b-col cols="2">
                                    <h5 :class="'card-title text-warning'" @click="onDashboard"><img :src="checkimage('Adherence')" width="35" height="35" alt="" /></h5>
                                </b-col>
                                <b-col cols="10">
                                    <p style="margin-top: 3px; margin-left: 10px; font-size: 12px; white-space: nowrap;">{{ checkName("Adherence") }}</p>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col cols="12">
                                    <p style="margin-top: 3px; margini-left: 10px; font-size: 12px" ali>{{ getDate("Adherence") }}</p>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col cols="12">
                                    {{ this.$i18n.t("patient.card.lblNum") }}:
                                    <span :class="'sa-text-misura-value badge badge-' + getMisuraRange('Adherence') + ' badge-pill'">{{ getMisuraValue("Adherence") }}</span>
                                </b-col>
                            </b-row>
                        </div>
                    </div>
                </b-col>
                <!--<b-col cols="12" xs="12" sm="2" md="2" lg="2" xl="2" v-for="item in misure" v-bind:key="item.id">
            <b-card :class="'sa-card-border-' + checkclass(item.range)" align="center">
                <p>{{ item.measurename }}</p>
            </b-card>
        </b-col>-->
            </b-row>
        </div>
    </div>
</template>

<script>
import moment from "moment";
import axios from "axios";
export default {
    props: {
        idPaziente: { type: String },
        orientation: {
            type: String,
            default: function() {
                return "";
            },
        },
    },
    data() {
        return {
            linkMeasurement: "/lastmeasure",
            misure: [],
        };
    },
    mounted() {
        let me = this;
        me.id = this.$route.query.idPaziente;
        me.loadMeasurement();
    },
    methods: {
        convertHour(time) {
            let returnValue = "---";
            try {
                returnValue = new Date(time * 1000).toISOString().substr(12, 4);
            } catch {
                returnValue = "---";
            }
            return returnValue;
        },
        getMisuraValue(label) {
            let me = this;
            let returnValue = "---";
            if (label === "Adherence") {
                returnValue = "70%";
            } else {
                me.misure.forEach((element) => {
                    if (element.measurename === label) {
                        returnValue = element.value;
                    }
                });
            }

            return returnValue;
        },
        getMisuraRange(label) {
            let me = this;
            let returnValue = "NORMAL";
            if (label === "Adherence") {
                returnValue = "warning";
            } else if (label === "RiskCalculationResult") {
                me.misure.forEach((element) => {
                    if (element.measurename === label) {
                        if (element.value <= 1) {
                            returnValue = "success";
                        } else if (element.value > 1 && element.value <= 3) {
                            returnValue = "worning";
                        } else {
                            returnValue = "alert";
                        }
                        // returnValue = element.range === null ? "NORMAL" : element.range;
                    }
                });
            } else {
                // console.log("OK " + label + " ");
                me.misure.forEach((element) => {
                    if (element.measurename === label) {
                        returnValue = element.range === null ? "NORMAL" : element.range;
                        // console.log(returnValue);
                    }
                });
            }

            returnValue = returnValue === "NORMAL" ? "success" : returnValue;
            return returnValue.toLowerCase();
        },
        getPressureRange() {
            let me = this;
            let returnValue = "success";
            me.misure.forEach((element) => {
                if (element.measurename === "Systolic Blood Pressure (mmHg)" && returnValue !== "alert") {
                    if (element.measurename === "Systolic Blood Pressure (mmHg)" && returnValue !== "warning") {
                        returnValue = element.range;
                    }
                }
                if (element.measurename === "Diastolic Blood Pressure (mmHg)" && returnValue !== "alert") {
                    if (element.measurename === "Diastolic Blood Pressure (mmHg)" && returnValue !== "warning") {
                        returnValue = element.range;
                    }
                }
            });
            returnValue = returnValue === "NORMAL" ? "success" : returnValue;
            return returnValue.toLowerCase();
        },
        getSleepRange() {
            let me = this;
            let returnValue = "success";
            me.misure.forEach((element) => {
                //console.log(element.measurename + " " + returnValue + " " + element.range);
                if (element.measurename === "total_sleep_time" && returnValue.toLowerCase() !== "alert") {
                    if (element.measurename === "total_sleep_time" && returnValue.toLowerCase() !== "warning") {
                        returnValue = element.range;
                    }
                }
                if (element.measurename === "wakeupcount" && returnValue.toLowerCase() !== "alert") {
                    if (element.measurename === "wakeupcount" && returnValue.toLowerCase() !== "warning") {
                        returnValue = element.range;
                    }
                }
            });

            returnValue = returnValue === "NORMAL" ? "success" : returnValue;

            return returnValue.toLowerCase();
        },
        getHrRange() {
            let me = this;
            let returnValue = "success";
            me.misure.forEach((element) => {
                if (element.measurename === "hr_min" && returnValue !== "alert") {
                    returnValue = element.range;
                }
                if (element.measurename === "hr_max" && returnValue !== "alert") {
                    returnValue = element.range;
                }
            });

            returnValue = returnValue === "NORMAL" ? "success" : returnValue;

            return returnValue.toLowerCase();
        },
        loadMeasurement() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + "/hyperhealth" + me.linkMeasurement + "?idPaziente=" + me.idPaziente;
            axios
                .get(link)
                .then((response) => {
                    response.data.data.forEach((element) => {
                        if (me.checkName(element.measurename) !== "unknown") {
                            me.misure.push(element);
                        }
                    });
                })
                .catch((e) => {
                    console.log("Error: " + e.response.status + " " + link);
                });
        },
        returnlabel() {
            let label = this.$i18n.t("patient.rangeConfig.lblDetectedOn");
            return label;
        },
        onDashboard() {
            let me = this;
            console.log(me.idPaziente);
            me.$router.replace("/paziente/dashboard?idPaziente=" + me.idPaziente).catch((err) => {
                err;
            });
        },
        checkclass(range) {
            switch (range) {
                case "WARNING":
                    return "warning";
                case "NORMAL":
                    return "success";
                case "ALERT":
                    return "alert";
                default:
                    return "secondary";
            }
        },
        formatDate(value) {
            if (value) {
                return moment(new Date(value)).format("DD-MM-YYYY");
            } else {
                return "---";
            }
        },
        checkName(name) {
            let label = "";
            switch (name) {
                case "sleep_efficiency":
                    label = this.$i18n.t("patient.card.lblSleepEfficiency");
                    break;
                case "hr":
                    label = this.$i18n.t("patient.card.lblHr");
                    break;
                case "hr_min":
                    label = this.$i18n.t("patient.card.lblHrMin");
                    break;
                case "hr_max":
                    label = this.$i18n.t("patient.card.lblHrMax");
                    break;
                case "Systolic Blood Pressure (mmHg)":
                    label = this.$i18n.t("patient.card.lblSystolicBloodPressure");
                    break;
                case "Diastolic Blood Pressure (mmHg)":
                    label = this.$i18n.t("patient.card.lblDiastolicBloodPressure");
                    break;
                case "delete-unlink-update":
                    label = this.$i18n.t("patient.card.lblDeleted");
                    break;
                case "Weight (kg)":
                    label = this.$i18n.t("patient.card.lblWeight");
                    break;
                case "steps":
                    label = this.$i18n.t("patient.card.lblSteps");
                    break;
                case "wakeupcount":
                    label = this.$i18n.t("patient.card.lblWakeupcount");
                    break;
                case "total_sleep_time":
                    label = this.$i18n.t("patient.card.lblTotalSleepTime");
                    break;
                case "RiskCalculationResult":
                    label = this.$i18n.t("patient.card.lblRiskCalculationResult");
                    break;
                case "Adherence":
                    label = this.$i18n.t("patient.card.lblAdherence");
                    break;
                default:
                    label = "unknown";
                    break;
            }
            return label;
        },
        getDate(name) {
            let me = this;
            let returnValue = "";
            if (name === "Adherence") {
                returnValue = "22-12-2021";
            } else {
                me.misure.forEach((element) => {
                    if (element.measurename === name) {
                        returnValue = moment(element.istant).format("DD-MM-YYYY");
                    }
                });
            }

            return this.$i18n.t("global.lblDate") + ": " + returnValue;
        },
        checkimage(name) {
            let image = "";
            switch (name) {
                case "hr_average":
                    image = "battiti2.png";
                    break;
                case "totalcalories":
                    image = "calorie.png";
                    break;
                case "calories":
                    image = "calorie.png";
                    break;
                case "active":
                    image = "camminare.png";
                    break;
                case "total_sleep_time":
                    image = "sonno.png";
                    break;
                case "sleep_efficiency":
                    image = "sonno.png";
                    break;
                case "hr_min":
                    image = "battiti2.png";
                    break;
                case "hr_max":
                    image = "battiti2.png";
                    break;
                case "elevation":
                    image = "scale.png";
                    break;
                case "out_of_bed_count":
                    image = "veglia_notturna.png";
                    break;
                case "Skin Temperature (celsius)":
                    image = "temperatura.png";
                    break;
                case "Bone Mass (kg)":
                    image = "massa_ossea.png";
                    break;
                case "Body Temperature (celsius)":
                    image = "temperatura_corporea.png";
                    break;
                case "Temperature (celsius)":
                    image = "temperatura.png";
                    break;
                case "Pulse Wave Velocity (m/s)":
                    image = "battiti.png";
                    break;
                case "SP02 (%)":
                    image = "ossigeno.png";
                    break;
                case "Heart Pulse (bpm) - only for BPM devices":
                    image = "battiti.png";
                    break;
                case "Systolic Blood Pressure (mmHg)":
                    image = "pressione_sis.png";
                    break;
                case "Diastolic Blood Pressure (mmHg)":
                    image = "pressione_sis2.png";
                    break;
                case "delete-unlink-update":
                    image = "noimage.png";
                    break;
                case "sleep_score":
                    image = "sonno.png";
                    break;
                case "wakeupcount":
                    image = "sonno.png";
                    break;
                case "Weight (kg)":
                    image = "bilancia.png";
                    break;
                case "Fat Free Mass (kg)":
                    image = "massa_magra.png";
                    break;
                case "Height (meter)":
                    image = "altezza.png";
                    break;
                case "Fat Mass Weight (kg)":
                    image = "massa_grassa.png";
                    break;
                case "distance":
                    image = "distanza.png";
                    break;
                case "Fat Ratio (%)":
                    image = "grasso.png";
                    break;
                case "steps":
                    image = "camminare.png";
                    break;
                case "Hydration (kg)":
                    image = "idratazione.png";
                    break;
                case "Muscle Mass (kg)":
                    image = "massa_muscolare.png";
                    break;
                case "RiskCalculationResult":
                    image = "rischio.png";
                    break;
                case "Adherence":
                    image = "pillole.png";
                    break;
                default:
                    image = "noimage.png";
                    break;
            }
            try {
                return require("../../../assets/images/icons/measurement/" + image);
            } catch (Exeption) {
                return require("../../../assets/images/icons/measurement/noimage.png");
            }
        },
    },
};
</script>

<style scoped>
.sa-card-border-alert {
    border-color: #ff5b5b !important;
    padding-bottom: 5px;
}
.sa-card-border-warning {
    border-color: #ffc107 !important;
    padding-bottom: 5px;
}
.sa-card-border-success {
    border: 2px solid !important;
    border-color: #10c469 !important;
    padding-bottom: 5px;
}
.sa-card-border-secondary {
    border: 2px solid !important;
    border-color: #6c757d !important;
}
.sa-text-misura-value {
    font-size: 1rem;
}
</style>
