<template>
  <sa-header border-bottom>
    <b-navbar no-caret type="dark" variant="info fixed-top sa-navbar" class="navbar-expand navbar-custom">
      <b-navbar-nav>
        <b-nav-item v-sa-aside-toggle class="dropdown button-menu-mobile sa-nav-item-icon-custom">
          <b-icon icon="justify"></b-icon>
        </b-nav-item>
      </b-navbar-nav>
      <b-navbar-brand class="sa-navbar-brand-title-custom">{{ pageTitle }}</b-navbar-brand>

      <b-navbar-nav class="ml-auto">
        <b-popover placement="bottomleft" target="popover-button-open" triggers="click blur" title="Gesan srl" customClass="sa-menu-bar">
          <b-list-group flush>
            <b-list-group-item button @click="onCambiaPassword"><font-awesome-icon icon="lock" /> <span>Cambia Password</span></b-list-group-item>
            <b-list-group-item button @click="onLogout"><font-awesome-icon icon="power-off" /> <span>Logout</span></b-list-group-item>
          </b-list-group>
        </b-popover>

        <b-nav-item v-if="btnChatVisible" class="sa-toolbar-btn-chat" no-caret right @click="onChatClick">
          <b-icon icon="chat"></b-icon>
          <!--<b-badge variant="danger" class="navbar-badge rounded-circle">15</b-badge>-->
        </b-nav-item>
        <b-nav-item-dropdown no-caret right>
          <template slot="button-content">
            <b-img :src="slcFlagimage" fluid alt="Responsive image"></b-img>
          </template>
          <b-dropdown-item v-for="(item, key) in languageMenu" :key="key" @click="onLanguageClick(item.language, item.flag)">
            <b-img style="height: 32px" :src="item.flag" fluid alt="Responsive image"></b-img> {{ item.languageDescription }}
          </b-dropdown-item>
        </b-nav-item-dropdown>
        <b-nav-item-dropdown class="item-dropdown-user">
          <template slot="button-content">
            <img :src="image" :srcset="`${image2x || image} 2x`" :class="['img-circle']" :alt="noimage" />
            <span class="pro-user-name ml-1">{{ userName }}</span>
          </template>
          <b-dropdown-item @click="onCambiaPassword" class="sa-custom-mnu-item"><font-awesome-icon icon="lock" /> <span>Cambia Password</span></b-dropdown-item>
          <b-dropdown-divider></b-dropdown-divider>
          <b-dropdown-item @click="onLogout" class="sa-custom-mnu-item"><font-awesome-icon icon="power-off" /> <span>Logout</span></b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-navbar>
    <b-modal ref="mdlCambioPassword" no-close-on-backdrop centered title="Cambio Password" @ok="onOk">
      <b-row>
        <b-col cols="12" sm="4">Vecchia Password:</b-col>
        <b-col cols="12" sm="8">
          <b-form-input v-model="data.oldPassword" type="password" size="sm" placeholder="Vecchia Password" required></b-form-input>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" sm="4">Nuova Password:</b-col>
        <b-col cols="12" sm="8">
          <b-form-input v-model="data.newPassword" type="password" size="sm" placeholder="Nuova Password" required></b-form-input>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" sm="4">Verifica Password:</b-col>
        <b-col cols="12" sm="8">
          <b-form-input v-model="data.verificaPassword" type="password" size="sm" placeholder="Verifica Password" required></b-form-input>
        </b-col>
      </b-row>
    </b-modal>
  </sa-header>
</template>

<script>
import avatar from "../../../../assets/images/no-avatar.png";
import axios from "axios";
export default {
  name: "NavBar",
  props: {
    languageMenu: {
      type: Array,
      required: true,
    },
    pageTitle: {
      type: String,
      required: true,
      default: "",
    },
    image: {
      type: String,
      required: true,
      default: avatar,
    },
    image2x: {
      type: String,
      default: avatar,
    },
    flagimage: {
      type: String,
      required: true,
    },
    userName: {
      type: String,
      required: true,
    },
    btnChatVisible: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      flag: null,
      noimage: "no-image",
      data: { oldPassword: "", newPassword: "", verificaPassword: "" },
    };
  },
  computed: {
    slcFlagimage: {
      get: function() {
        if (this.flag) {
          return this.flag;
        } else {
          return this.flagimage;
        }
      },
      set: function(newValue) {
        this.flag = newValue;
      },
    },
  },
  methods: {
    onCambiaPassword() {
      let me = this;
      me.$refs.mdlCambioPassword.show();
    },
    onOk() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + "/users/cambiapassword";
      axios
        .put(link, JSON.stringify(me.data))
        .then(() => {
          this.$bvToast.toast("Operazione avvenuta correttamente!", {
            title: "",
            variant: "success",
            solid: true,
          });
        })
        .catch((error) => {
          me.showmolalloading = false;
          this.$bvModal
            .msgBoxOk(error.response.data.messaggio, {
              title: "ATTENZIONE",
              size: "sm",
              okVariant: "outline-danger",
              headerClass: "sa-msg-header-danger",
              footerClass: "p-2 border-top-0",
              centered: true,
            })
            .then((value) => {
              this.boxTwo = value;
            })
            .catch((err) => {
              console.log(err);
            });
        });
    },
    onLogout() {
      let link = process.env.VUE_APP_PATH_API + "/logout";
      axios.get(link, {}).then(() => {
        this.$router.push("login");
        location.reload();
      });
    },
    onLanguageClick: function(value, flag) {
      let me = this;
      me.slcFlagimage = flag;
      if (this.$route.query.language !== value) {
        this.$router.push({ path: this.$route.path, query: { ...this.$route.query, language: value } });
        // setTimeout(this.$router.go(), 300000);
        //this.$router.go();
        setTimeout(() => {
          this.$router.go();
        }, 500);
      }
    },
    onChatClick() {
      let me = this;
      me.$emit("update");
    },
  },
};
</script>

<style>
.sa-menu-bar .popover-body {
  padding: 0rem;
}

.sa-menu-bar button > span {
  padding-left: 0.8rem;
}
</style>
