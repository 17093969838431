<template>
    <b-card>
        <b-card-body>
            <b-row>
                <b-col>
                    <p>
                        <b>{{ this.$i18n.t("patient.afa.lblWeight") }}:</b> {{ data.peso }}
                    </p>
                </b-col>

                <b-col>
                    <p>
                        <b>{{ this.$i18n.t("patient.afa.lblHeight") }}:</b>
                        {{ data.altezza }}
                    </p>
                </b-col>
                <b-col>
                    <p>
                        <b>{{ this.$i18n.t("patient.afa.lblBMI") }}:</b> {{ data.bmi }}
                    </p>
                </b-col>
                <b-col>
                    <p>
                        <b>{{ this.$i18n.t("patient.afa.lblBSA") }}:</b> {{ data.bsa }}
                    </p>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <span class="sa-form-label">{{ this.$i18n.t("patient.afa.lblTBW") }}</span>
                    <b-form-input type="number" step="0.01" v-model="data.tbw" :disabled="!edit"></b-form-input>
                    <span class="sa-form-label">{{ this.$i18n.t("patient.afa.lblLeanMass") }}</span>
                    <b-form-input type="number" step="0.01" v-model="data.massaMagra" :disabled="!edit"></b-form-input>
                </b-col>
                <b-col>
                    <span class="sa-form-label">{{ this.$i18n.t("patient.afa.lblECW") }}</span>
                    <b-form-input type="number" step="0.01" v-model="data.ecw" :disabled="!edit"></b-form-input>
                    <span class="sa-form-label">{{ this.$i18n.t("patient.afa.lblFatMass") }}</span>
                    <b-form-input type="number" step="0.01" v-model="data.massaGrassa" :disabled="!edit"></b-form-input>
                </b-col>
                <b-col>
                    <span class="sa-form-label">{{ this.$i18n.t("patient.afa.lblBasalMetabolism") }}</span>
                    <b-form-input type="number" step="0.01" v-model="data.metabolismoBasale" :disabled="!edit"></b-form-input>
                    <span class="sa-form-label">{{ this.$i18n.t("patient.afa.lblMuscleMass") }}</span>
                    <b-form-input type="number" step="0.01" v-model="data.massaMuscolare" :disabled="!edit"></b-form-input>
                </b-col>
            </b-row>
        </b-card-body>
    </b-card>
</template>

<script>
export default {
    props: ["data", "edit"],
    data() {
        return {};
    },
};
</script>
