<template>
    <sa-page-layout :btnSaveVisible="true" :btnAnnullaVisible="true" :linkback="linkback" :pathResource="pathResource" :data="jsonData">
        <template slot="toolbar-title">
            <paziente-anagrafica :linkPatient="linkPatient" :idPaziente="idPaziente"></paziente-anagrafica>
        </template>
        <template slot="table-body">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <b-row>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <span class="sa-form-label">{{ this.$i18n.t("patient.diabetology.lblFather") }}</span>
                            <br />
                            <b-form-select v-model="jsonData.padre" class="mb-2 mr-sm-2 mb-sm-0" :options="padreOptions" :value="null"></b-form-select>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <span class="sa-form-label">{{ this.$i18n.t("patient.diabetology.lblMother") }}</span>
                            <br />
                            <b-form-select v-model="jsonData.madre" class="mb-2 mr-sm-2 mb-sm-0" :options="madreOptions" :value="null"></b-form-select>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <span class="sa-form-label">{{ this.$i18n.t("patient.diabetology.lblSons") }}</span>
                            <br />
                            <b-form-select v-model="jsonData.figli" class="mb-2 mr-sm-2 mb-sm-0" :options="figliOptions" :value="null"></b-form-select>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                            <span class="sa-form-label">{{ this.$i18n.t("patient.diabetology.lblAscending") }}</span>
                            <br />
                            <b-form-select v-model="jsonData.ascendenti" class="mb-2 mr-sm-2 mb-sm-0" :options="ascendentiOptions" :value="null"></b-form-select>
                        </b-col>
                        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                            <span class="sa-form-label">{{ this.$i18n.t("patient.diabetology.lblCollateral") }}</span>
                            <br />
                            <b-form-select v-model="jsonData.collaterali" class="mb-2 mr-sm-2 mb-sm-0" :options="collateraliOptions" :value="null"></b-form-select>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-button class="float-sm-right" style="margin-top: 5px; margin-bottom: 5px;" variant="outline-primary sa-margin-right" @click="onAddFamiliare"><b-icon icon="plus"></b-icon> Aggiungi</b-button>
                </b-col>
            </b-row>
            <b-row>
                <b-table sticky-header ref="table" responsive stacked="xl" striped hover :items="jsonData.diabetologiaAnamnesiFamiliareFamiliari" :fields="fields" sort-icon-left head-variant="light" class="sa-b-table">
                    <template #cell(actions)="row">
                        <b-button-group>
                            <b-button size="sm" variant="btn btn-outline-primary waves-effect  waves-light width-sm no-text" style="margin-right:5px" @click="onEditRigaFamiliari(row.index)">
                                <b-icon icon="pencil"></b-icon>
                            </b-button>
                            <b-button size="sm" variant="btn btn-outline-danger waves-effect  waves-light width-sm no-text" style="margin-left:5px" @click="onDeleteRigaFamiliari(row.index)">
                                <b-icon icon="trash"></b-icon>
                            </b-button>
                        </b-button-group>
                    </template>
                </b-table>
            </b-row>
        </template>
        <template slot="table-footer">
            <b-modal ref="mdlFamiliari" size="lg" @ok="onOkMdlFamiliari">
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                        <b-row>
                            <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                                <span class="sa-form-label">Tipo Parentela</span>
                                <br />
                                <b-form-select v-model="rigaSelezionata.tipoParentela" class="mb-2 mr-sm-2 mb-sm-0" :options="tipoParentelaOptions" :value="null"></b-form-select>
                            </b-col>
                            <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                                <span class="sa-form-label">Diabete</span>
                                <br />
                                <b-form-select v-model="rigaSelezionata.diabete" class="mb-2 mr-sm-2 mb-sm-0" :options="diabeteOptions" :value="null"></b-form-select>
                            </b-col>
                            <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                                <span class="sa-form-label">Ipertensione</span>
                                <br />
                                <b-form-select v-model="rigaSelezionata.ipertensione" class="mb-2 mr-sm-2 mb-sm-0" :options="ipertensioneOptions" :value="null"></b-form-select>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                                <span class="sa-form-label">Obesità</span>
                                <br />
                                <b-form-select v-model="rigaSelezionata.obesita" class="mb-2 mr-sm-2 mb-sm-0" :options="obesitaOptions" :value="null"></b-form-select>
                            </b-col>
                            <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                                <span class="sa-form-label">Dislipidemia</span>
                                <br />
                                <b-form-select v-model="rigaSelezionata.dislipidemia" class="mb-2 mr-sm-2 mb-sm-0" :options="dislipidemiaOptions" :value="null"></b-form-select>
                            </b-col>
                            <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                                <span class="sa-form-label">Altro</span>
                                <br />
                                <b-form-textarea v-model="rigaSelezionata.altro" no-resize></b-form-textarea>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </b-modal>
        </template>
    </sa-page-layout>
</template>
<script>
import SaPageLayout from "../../../../../template/sa/components/layout/contents/SaPageLayout.vue";
import PazienteAnagrafica from "../../../components/PazienteAnagrafica.vue";
import axios from "axios";
export default {
    props: {
        utente: {
            type: Object,
            default: function() {
                return null;
            },
        },
    },
    components: { SaPageLayout, PazienteAnagrafica },
    computed: {
        idPaziente() {
            return this.$route.query.idPaziente;
        },
        idEvento() {
            return this.$route.params.id;
        },
        linkPatient: {
            get: function() {
                let returnValue = "";
                if (this.utente.gruppo) {
                    if (this.utente.gruppo.menuGruppo) {
                        if (this.utente.gruppo.menuGruppo.length > 1) {
                            return "/paziente/view/" + this.idPaziente;
                        } else {
                            if (this.utente.gruppo.menuGruppo.length == 1) {
                                if (this.utente.gruppo.menuGruppo[0].primaryKey === "pazienti") {
                                    return "/paziente/view/" + this.idPaziente;
                                } else {
                                    return "/paziente/homepaziente";
                                }
                            }
                        }
                    }
                }
                return returnValue;
            },
        },
    },
    mounted() {
        let me = this;
        me.jsonData.idPaziente = this.$route.query.idPaziente;
        me.jsonData.tipoEvento = "diabetologiaanamnesifamiliare";
        if (me.idEvento === "-1") {
            me.linkback = "/paziente/diabetologia/anamnesifamiliare";
        } else {
            me.linkback = "/paziente/diabetologia/anamnesifamiliare/view/" + me.idEvento;
        }
        me.loadData();
    },
    data() {
        return {
            linkback: null,
            pathResource: "/diabetologiaanamnesifamiliare",
            jsonData: { padre: "NON RILEVATO", madre: "NON RILEVATO", figli: "NON RILEVATO", ascendenti: "NON RILEVATO", collaterali: "NON RILEVATO", diabetologiaAnamnesiFamiliareFamiliari: [{ tipoParentela: null, diabete: null, ipertensione: null, obesita: null, dislipidemia: null, altro: null }] },
            rigaSelezionata: { tipoParentela: null, diabete: null, ipertensione: null, obesita: null, dislipidemia: null, altro: null },
            indexRigaSelezionata: null,
            padreOptions: [
                { value: "NON RILEVATO", text: "NON RILEVATO" },
                { value: "SI", text: "SI" },
                { value: "NO", text: "NO" },
            ],
            madreOptions: [
                { value: "NON RILEVATO", text: "NON RILEVATO" },
                { value: "SI", text: "SI" },
                { value: "NO", text: "NO" },
            ],
            figliOptions: [
                { value: "NON RILEVATO", text: "NON RILEVATO" },
                { value: "SI", text: "SI" },
                { value: "NO", text: "NO" },
            ],
            ascendentiOptions: [
                { value: "NON RILEVATO", text: "NON RILEVATO" },
                { value: "SI", text: "SI" },
                { value: "NO", text: "NO" },
            ],
            collateraliOptions: [
                { value: "NON RILEVATO", text: "NON RILEVATO" },
                { value: "SI", text: "SI" },
                { value: "NO", text: "NO" },
            ],
            tipoParentelaOptions: [
                { value: null, text: "Seleziona tipo parentela" },
                { value: "PADRE", text: "PADRE" },
                { value: "MADRE", text: "MADRE" },
                { value: "FIGLI", text: "FIGLI" },
            ],
            diabeteOptions: [
                { value: null, text: "NON RILEVATO" },
                { value: "SI", text: "SI" },
                { value: "NO", text: "NO" },
            ],
            ipertensioneOptions: [
                { value: null, text: "NON RILEVATO" },
                { value: "SI", text: "SI" },
                { value: "NO", text: "NO" },
            ],
            obesitaOptions: [
                { value: null, text: "NON RILEVATO" },
                { value: "SI", text: "SI" },
                { value: "NO", text: "NO" },
            ],
            dislipidemiaOptions: [
                { value: null, text: "NON RILEVATO" },
                { value: "SI", text: "SI" },
                { value: "NO", text: "NO" },
            ],
            fields: [
                {
                    label: "Tipo Parentela",
                    key: "tipoParentela",
                    thStyle: "width: 4rem",
                    sortable: true,
                },
                {
                    label: "Diabete",
                    key: "diabete",
                    thStyle: "width: 4rem",
                    sortable: true,
                },
                {
                    label: "Ipertensione",
                    key: "ipertensione",
                    thStyle: "width: 4rem",
                    sortable: true,
                },
                {
                    label: "Obesità",
                    key: "obesita",
                    thStyle: "width: 4rem",
                    sortable: true,
                },
                {
                    label: "Dislipidemia",
                    key: "dislipidemia",
                    thStyle: "width: 4rem",
                    sortable: true,
                },
                {
                    label: "Altro",
                    key: "altro",
                    sortable: true,
                },
                {
                    label: "",
                    key: "actions",
                    thStyle: "width: 7rem",
                    tdClass: "text-center",
                },
            ],
        };
    },
    methods: {
        loadData() {
            let me = this;
            if (me.idEvento === "-1") {
                me.showModalLoading = false;
            } else {
                let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
                axios.get(link + me.idEvento).then((response) => {
                    me.jsonData = response.data.data;
                    me.showModalLoading = false;
                });
            }
        },
        onAddFamiliare() {
            let me = this;
            let nuovaRiga = { tipoParentela: null, diabete: null, ipertensione: null, obesita: null, dislipidemia: null, altro: null };
            me.rigaSelezionata = nuovaRiga;
            me.$refs.mdlFamiliari.show();
        },
        onOkMdlFamiliari() {
            let me = this;
            if (me.indexRigaSelezionata === null) {
                me.jsonData.diabetologiaAnamnesiFamiliareFamiliari.push(me.rigaSelezionata);
            } else {
                me.jsonData.diabetologiaAnamnesiFamiliareFamiliari[me.indexRigaSelezionata] = me.rigaSelezionata;
                me.indexRigaSelezionata = null;
            }
        },
        onDeleteRigaFamiliari(index) {
            let me = this;
            console.log(index);
            me.jsonData.diabetologiaAnamnesiFamiliareFamiliari.splice(index, 1);

            this.$bvToast.toast("Riga eliminata con successo", {
                title: "Eliminato",
                variant: "success",
                solid: true,
            });
        },
        onEditRigaFamiliari(index) {
            let me = this;
            me.indexRigaSelezionata = index;
            me.rigaSelezionata = me.jsonData.diabetologiaAnamnesiFamiliareFamiliari[me.indexRigaSelezionata];
            me.$refs.mdlFamiliari.show();
        },
    },
};
</script>
