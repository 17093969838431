<template>
  <div>
    <b-row>
      <b-col>
        <span class="sa-form-label">{{ this.$i18n.t("patient.idoneity.lblRestingHR") }}</span>
        <b-form-input type="number" v-model="data.hr1" :disabled="!edit"></b-form-input>
        <span class="sa-form-label">{{ this.$i18n.t("patient.idoneity.lblRestingPR") }}</span>
        <b-form-input type="number" v-model="data.pr1" :disabled="!edit"></b-form-input>
        <span class="sa-form-label">{{ this.$i18n.t("patient.idoneity.lblRestingQT") }}</span>
        <b-form-input type="number" v-model="data.qt1" :disabled="!edit"></b-form-input>
        <span class="sa-form-label">{{ this.$i18n.t("patient.idoneity.lblRestingQTC") }}</span>
        <b-form-input type="number" v-model="data.qtcRiposo" :disabled="!edit"></b-form-input>
        <span class="sa-form-label">{{ this.$i18n.t("patient.idoneity.lblIri") }}</span>
        <b-form-input type="number" v-model="data.iri" :disabled="!edit"></b-form-input>
      </b-col>
      <b-col cols="4"></b-col>
      <b-col>
        <span class="sa-form-label">{{ this.$i18n.t("patient.idoneity.lblAfterStressHR") }} </span>
        <b-form-input type="number" v-model="data.hr2" :disabled="!edit"></b-form-input>
        <span class="sa-form-label">{{ this.$i18n.t("patient.idoneity.lblAfterStressPR") }}</span>
        <b-form-input type="number" v-model="data.pr2" :disabled="!edit"></b-form-input>
        <span class="sa-form-label">{{ this.$i18n.t("patient.idoneity.lblAfterStressQT") }}</span>
        <b-form-input type="number" v-model="data.qt2" :disabled="!edit"></b-form-input>
        <span class="sa-form-label">{{ this.$i18n.t("patient.idoneity.lblPulsation") }}</span>
        <b-form-input type="number" v-model="data.hr3" :disabled="!edit"></b-form-input>
        <span class="sa-form-label">{{ this.$i18n.t("patient.idoneity.lblIriJudgement") }}</span>
        <b-form-input v-model="data.giudizioIri" :disabled="!edit"></b-form-input>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <span class="sa-form-label">{{ this.$i18n.t("patient.idoneity.lblReport") }}</span> <b-form-textarea rows="4" max-rows="9" v-model="data.referto" :disabled="!edit"></b-form-textarea
      ></b-col>
    </b-row>
    <b-row>
      <b-col>
        <span class="sa-form-label">Stato</span><br />
        {{ data.firmaEcg }}
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  props: ["data", "edit"],
  data() {
    return {};
  },
};
</script>
