<template>
    <div style="margin-top: 10px">
        <b-row style="margin-bottom: 10px;">
            <b-col>
                <b-button variant="outline-primary sa-margin-right float-sm-right" size="sm" @click="onClickAggiungiEsame"><b-icon icon="plus"></b-icon>Aggiungi</b-button>
            </b-col>
        </b-row>
        <div class="b-table-sticky-header">
            <b-table sticky-header ref="table" responsive stacked="xl" striped hover :items="items" :fields="fields" sort-icon-left head-variant="light" class="sa-b-table">
                <template v-slot:cell(nominativoMedicoRichiedente)="{ item }"> {{ item.nomeMedicoRichiedente }} {{ item.cognomeMedicoRichiedente }} </template>
            </b-table>
        </div>
        <div>
            <b-modal ref="mdlAggiungiEsame" title="Aggiungi Esame" size="xl" @ok="onOkMdlAggiungiEsame">
                <div class="sa-form-section">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <strong>Motivazione </strong><br />
                            <b-form-textarea v-model="nuovaRiga.motivazione" no-resize></b-form-textarea>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <strong>Data/Ora richiesta </strong><br />
                            <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="nuovaRiga.assegnatoAttualeDataOra" type="timestamp" placeholder="Data Documento"></date-picker>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="nuovaRiga.tipoVisita" :options="tipoEsameOptions" :value="null" value-field="tipoEsame" text-field="descrizione"></b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="nuovaRiga.tipoVisita1" :options="tipoEsameOptions" :value="null" value-field="tipoEsame" text-field="descrizione"></b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="nuovaRiga.tipoVisita2" :options="tipoEsameOptions" :value="null" value-field="tipoEsame" text-field="descrizione"></b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="nuovaRiga.tipoVisita3" :options="tipoEsameOptions" :value="null" value-field="tipoEsame" text-field="descrizione"></b-form-select>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <strong>Conclusioni </strong><br />
                            <b-form-textarea v-model="nuovaRiga.conclusioni" no-resize></b-form-textarea>
                        </b-col>
                    </b-row>
                </div>
            </b-modal>
        </div>
    </div>
</template>
<script>
import moment from "moment";
import DatePicker from "vue2-datepicker";
export default {
    components: { DatePicker },
    props: {},
    data() {
        return {
            nuovaRiga: { tipoVisita: null, tipoVisita1: null, tipoVisita2: null, tipoVisita3: null, motivazione: "", assegnatoAttualeDataOra: Date.now(), conclusioni: "" },
            items: [],
            fields: [
                {
                    label: "Esame",
                    key: "tipoVisita",
                    sortable: true,
                },
                {
                    label: "Motivazione",
                    key: "motivazione",
                    sortable: true,
                },
                {
                    label: "Medico Richiedente",
                    key: "nominativoMedicoRichiedente",
                    thStyle: "width: 5rem",
                    sortable: true,
                },
                {
                    label: "Data/Ora richiesta",
                    key: "assegnatoAttualeDataOra",
                    formatter: (value) => {
                        return moment(new Date(value)).format("DD/MM/YYYY HH:mm");
                    },
                    thStyle: "width: 5rem",
                    sortable: true,
                },
            ],
            tipoEsameOptions: [
                { tipoEsame: null, descrizione: "-Seleziona Esame-" },
                { tipoEsame: "ECOCARDIOGRAFIA COLOR-DOPPLER", descrizione: "ECOCARDIOGRAFIA COLOR-DOPPLER" },
                { tipoEsame: "ECG DINAMICO DELLE 24H SECONDO HOLTER CON SEDUTA DI ALLENAMENTO", descrizione: "ECG DINAMICO DELLE 24H SECONDO HOLTER CON SEDUTA DI ALLENAMENTO" },
                { tipoEsame: "ECG DA SFORZO AL CICLOERGOMETRO", descrizione: "ECG DA SFORZO AL CICLOERGOMETRO" },
                { tipoEsame: "RISONANZA MAGNETICA CARDIACA", descrizione: "RISONANZA MAGNETICA CARDIACA" },
                { tipoEsame: "RISONANZA MAGNETICA CARDIACA CON MDC", descrizione: "RISONANZA MAGNETICA CARDIACA CON MDC" },
                { tipoEsame: "STUFIO ELETTROFISIOLOGICO", descrizione: "STUFIO ELETTROFISIOLOGICO" },
            ],
        };
    },
    methods: {
        onClickAggiungiEsame() {
            let me = this;
            me.$refs.mdlAggiungiEsame.show();
        },
        onOkMdlAggiungiEsame() {
            let me = this;
            me.items.push(me.nuovaRiga);
        },
    },
};
</script>
