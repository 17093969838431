import Utility from "../";

const routes = [
    { name: "Logs", path: "/logs", component: Utility.LogsList },
    { name: "PatientAccessList", path: "/patientaccess", component: Utility.PatientAccessList },
    { name: "PatientAccessEdit", path: "/patientaccess/edit/:id", component: Utility.PatientAccessEdit },
    { name: "ModuliList", path: "/moduli", component: Utility.ModuliList },
    { name: "ModuliEdit", path: "/moduli/edit/:id", component: Utility.ModuliEdit },
    { name: "ParamsList", path: "/configparameter", component: Utility.ParamsList },
    { name: "ParamsEdit", path: "/configparameter/edit/:id", component: Utility.ParamsEdit },
];

export default routes;
