<template>
    <sa-page-layout :btnBackVisible="true" :btnEditVisible="true" :linkedit="linkedit" :linkback="linkback" :showModalLoading="showModalLoading">
        <template slot="toolbar-title">
            <paziente-anagrafica :linkPatient="linkPatient" :idPaziente="idPaziente"></paziente-anagrafica>
        </template>
        <template slot="toolbar-row">
            <paziente-dashboard-component :idPaziente="idPaziente"></paziente-dashboard-component>
        </template>
        <template slot="filter">
            <div class="bg-picture card-box">
                <div class="profile-info-name">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col">
                                <h5 class="m-0">{{ this.$i18n.t("videocare.lblDoctorData") }}</h5>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col">
                                <strong>{{ this.$i18n.t("videocare.lblName") }}</strong
                                ><br />
                                <span class="sa-form-label">{{ data.nomeMedicoPresente }}</span>
                            </div>
                            <div class="col">
                                <strong>{{ this.$i18n.t("videocare.lblSurname") }}</strong
                                ><br />
                                <span class="sa-form-label">{{ data.cognomeMedico }}</span>
                            </div>
                            <div class="col">
                                <strong>{{ this.$i18n.t("videocare.lblDoctorCode") }}</strong
                                ><br />
                                <span class="sa-form-label">{{ data.codiceMedicoPresente }}</span>
                            </div>
                        </div>
                        <hr />
                        <div class="row">
                            <div class="col">
                                <strong>{{ this.$i18n.t("videocare.lblPhoneNumber") }} </strong><br />
                                <span class="sa-form-label">{{ data.telefonoMedico }}</span>
                            </div>
                            <div class="col">
                                <strong> {{ this.$i18n.t("videocare.lblEmail") }} </strong><br />
                                <span class="sa-form-label">{{ data.emailMedico }}</span>
                            </div>
                            <div class="col"></div>
                        </div>
                        <hr />
                        <div class="row">
                            <div class="col">
                                <h5 class="m-0">{{ this.$i18n.t("videocare.lblPatientData") }}</h5>
                            </div>
                        </div>
                        <hr />
                        <div class="row">
                            <div class="col">
                                <strong>Username</strong><br />
                                <span class="sa-form-label">{{ data.usernameRichiedente }}</span>
                            </div>
                            <div class="col">
                                <strong>{{ this.$i18n.t("videocare.lblPhoneNumber") }}</strong
                                ><br />
                                <span class="sa-form-label">{{ data.telefonoPaziente }}</span>
                            </div>
                            <div class="col">
                                <strong>{{ this.$i18n.t("videocare.lblEmail") }}</strong
                                ><br />
                                <span class="sa-form-label">{{ data.emailPaziente }}</span>
                            </div>
                        </div>
                        <hr />
                        <div class="row">
                            <div class="col">
                                <h5 class="m-0">{{ this.$i18n.t("videocare.lblReservation") }}</h5>
                            </div>
                        </div>
                        <hr />
                        <div class="row">
                            <div class="col">
                                <strong>{{ this.$i18n.t("videocare.lblBookingDateAndTime") }}</strong
                                ><br />
                                <span class="sa-form-label">{{ formatDate(data.dataOraPrenotazione) }}</span>
                            </div>
                        </div>
                        <hr />
                    </div>
                </div>
            </div>
        </template>
        <template slot="table-body">
            <div class="b-table-sticky-header">
                <b-table sticky-header ref="table" :filter="filter" responsive stacked="lg" striped hover :items="items" :fields="fields" sort-icon-left head-variant="light" class="sa-b-table">
                    <!-- <template v-slot:cell(nominativo)="{ item }">
                        <router-link class="sa-edit-link" :to="'/paziente/view/' + item.id">{{ item.nome }} {{ item.cognome }} </router-link>
                        <br />
                        {{ item.codiceFiscale }}
                    </template> -->
                </b-table>
            </div>
        </template>
    </sa-page-layout>
</template>
<script>
import axios from "axios";
import moment from "moment";
import PazienteAnagrafica from "./PazienteAnagrafica.vue";
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
import PazienteDashboardComponent from "./PazienteDashboardComponent.vue";
export default {
    props: {
        utente: {
            type: Object,
            default: function() {
                return null;
            },
        },
    },
    components: { PazienteAnagrafica, SaPageLayout, PazienteDashboardComponent },
    computed: {
        idPaziente() {
            return this.$route.query.idPaziente;
        },
        linkPatient: {
            get: function() {
                let returnValue = "";
                if (this.utente.gruppo) {
                    if (this.utente.gruppo.menuGruppo) {
                        if (this.utente.gruppo.menuGruppo.length > 1) {
                            return "/paziente/view/" + this.idPaziente;
                        } else {
                            if (this.utente.gruppo.menuGruppo.length == 1) {
                                if (this.utente.gruppo.menuGruppo[0].primaryKey === "pazienti") {
                                    return "/paziente/view/" + this.idPaziente;
                                } else {
                                    return "/paziente/homepaziente";
                                }
                            }
                        }
                    }
                }
                return returnValue;
            },
        },
    },
    data() {
        return {
            showModalLoading: false,
            linkedit: null,
            linkback: "/paziente/teleconsulti",
            elaborazione: false,
            currentPage: 1,
            filter: "",
            pathResource: "/teleconsultoprenotazioni",
            // pathRest: "/teleconsultopartecipanti",
            fields: [
                {
                    label: "Nome",
                    key: "nomeMedicoPresente",
                    sortable: true,
                    //thStyle: "width: 7rem",
                },
                {
                    label: "Cognome",
                    key: "cognome",
                    //thStyle: "width: rem",
                    sortable: true,
                },
                {
                    label: "Email",
                    key: "email",
                    sortable: true,
                },
                {
                    label: "Numero di Telefono",
                    key: "numeroTelefono",
                    sortable: true,
                },
                { class: "sa-table-column-action-single", key: "actions", label: "" },
            ],
            items: [],
            data: {},
        };
    },
    created: function() {},
    mounted: function() {
        let me = this;
        me.id = this.$route.params.id;
        me.linkback = "/paziente/teleconsulti?idPaziente=" + me.idPaziente;
        me.linkedit = "/paziente/teleconsulti/edit/" + me.id + "?idPaziente=" + me.idPaziente;
        me.loadData();
    },

    methods: {
        loadData() {
            let me = this;
            me.showModalLoading = true;
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
            axios.get(link + me.id).then((response) => {
                me.data = response.data.data;
                me.showModalLoading = false;
                me.loadDataPartecipanti();
            });
        },
        formatDate(value) {
            if (value) {
                return moment(new Date(value)).format("DD-MM-YYYY");
            } else {
                return "---";
            }
        },
        loadDataPartecipanti() {
            let me = this;
            me.showModalLoading = true;
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
            axios
                .get(link + me.data.id)
                .then((response) => {
                    me.items = response.data.data;
                    me.showModalLoading = false;
                })
                .catch((err) => {
                    console.log("ERRORE:    " + err);
                });
        },
    },
};
</script>
