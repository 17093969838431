<template>
    <sa-page-layout :btnNewVisible="true" :linkedit="linkedit" :btnRefreshVisible="true" @refresh="onRefresh" :pathResource="pathResource">
        <template slot="toolbar-title">
            <paziente-anagrafica :linkPatient="linkPatient" :idPaziente="idPaziente"></paziente-anagrafica>
        </template>
        <template slot="toolbar-row">
            <paziente-dashboard-component :idPaziente="idPaziente"></paziente-dashboard-component>
        </template>
        <template slot="table-filter">
            <b-form @submit.prevent="onSubmit">
                <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                    <b-col sm="6">
                        <b-form-input v-model="filtro.descrizione" type="search" id="numeroDocumento" placeholder="Descrizione Documento"></b-form-input>
                    </b-col>
                    <b-col sm="6">
                        <b-form-input v-model="filtro.tipoDocumento" type="search" id="tipoDocumentoDescrizione" placeholder="Tipo Documento"></b-form-input>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="sa-padding-right text-right">
                        <b-button type="submit" variant="info">{{ this.$i18n.t("global.lblFind") }}</b-button>
                        <b-button type="reset" v-on:click="onReset" variant="danger">Resetta Filtro</b-button>
                    </b-col>
                </b-row>
            </b-form>
        </template>
        <template slot="table-header">
            <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col lg="3">
                    <p>Totale Record: {{ rows }}</p>
                </b-col>
                <b-col lg="9">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
            </b-row>
        </template>
        <template slot="table-body">
            <div class="b-table-sticky-header">
                <b-table sticky-header ref="table" :filter="filter" responsive stacked="lg" striped hover :items="items" :fields="fields" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                    <template v-slot:cell(actions)="row">
                        <b-button size="sm" variant="btn btn-purple waves-effect  waves-light width-sm no-text" style="margin-right:3px" @click="onClick(row.item, row.index, $event.target)" v-b-modal.visualizzaAllegato>
                            <b-icon icon="search"></b-icon>
                        </b-button>
                    </template>
                </b-table>
            </div>
        </template>
        <template slot="table-footer">
            <b-modal size="xl" id="visualizzaAllegato" title="Visualizza Allegato" @show="onShow" @click="onClick">
                <template>
                    <b-embed type="iframe" :src="file" allowfullscreen></b-embed>
                </template>
            </b-modal>
        </template>
    </sa-page-layout>
</template>
<script>
import axios from "axios";
import moment from "moment";
//import DatePicker from "vue2-datepicker";
import SaPageLayout from "../../../../template/sa/components/layout/contents/SaPageLayout.vue";
import PazienteAnagrafica from "../../components/PazienteAnagrafica.vue";
import PazienteDashboardComponent from "../../components/PazienteDashboardComponent.vue";
export default {
    props: {
        utente: {
            type: Object,
            default: function() {
                return null;
            },
        },
    },
    components: { SaPageLayout, PazienteAnagrafica, PazienteDashboardComponent },
    computed: {
        idPaziente() {
            return this.$route.query.idPaziente;
        },
        rows() {
            return this.items.length;
        },
        linkPatient: {
            get: function() {
                let returnValue = "";
                if (this.utente.gruppo) {
                    if (this.utente.gruppo.menuGruppo) {
                        if (this.utente.gruppo.menuGruppo.length > 1) {
                            return "/paziente/view/" + this.idPaziente;
                        } else {
                            if (this.utente.gruppo.menuGruppo.length == 1) {
                                if (this.utente.gruppo.menuGruppo[0].primaryKey === "pazienti") {
                                    return "/paziente/view/" + this.idPaziente;
                                } else {
                                    return "/paziente/homepaziente";
                                }
                            }
                        }
                    }
                }
                return returnValue;
            },
        },
    },
    data() {
        return {
            perPage: 10,
            currentPage: 1,
            filter: null,
            id: null,
            pathResource: "/allegati",
            linkedit: "/paziente/allegati",
            file: null,
            filtro: {},
            filterOn: [],
            fields: [
                {
                    label: this.$i18n.t("patients.lblUploadDate"),
                    key: "dataCaricamento",
                    sortable: true,
                    thStyle: "width: 7rem",
                    formatter: (value) => {
                        return moment(new Date(value)).format("DD-MM-YYYY");
                    },
                },
                {
                    label: this.$i18n.t("patients.lblDocumentType"),
                    key: "tipoDocumento",
                    thStyle: "width: 7rem",
                    sortable: true,
                },
                {
                    label: this.$i18n.t("patients.lblDescription"),
                    key: "descrizione",
                    thStyle: "width: 7rem",
                    sortable: true,
                },
                {
                    label: "",
                    key: "actions",
                    thStyle: "width: 3rem",
                },
                //{ class: "sa-table-column-action-single", key: "actions", label: "" },
            ],
            items: [],
        };
    },
    created: function() {},
    mounted: function() {
        let me = this;
        me.id = this.$route.params.id;
        this.loadData();
    },
    methods: {
        loadData() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "?idPaziente=";
            me.isBusy = true;
            axios
                .get(link + me.idPaziente)
                .then((response) => {
                    me.items = [];
                    me.items = response.data.data;
                    console.log(me.items);
                    me.perPage = me.data.length;
                })
                .catch(() => {
                    me.showmolalloading = false;
                });
        },
        onSubmit() {
            let me = this;
            let sessionStorage = window.sessionStorage;
            sessionStorage["filtro"] = JSON.stringify(me.filtro);
            me.loadData();
        },
        onReset() {
            let me = this;
            me.filtro = {};
            let sessionStorage = window.sessionStorage;
            sessionStorage["filtro"] = JSON.stringify(me.filtro);
            me.loadData();
        },
        onRefresh() {
            let me = this;
            me.loadData();
        },
        onShow() {},
        onClick(item) {
            let me = this;
            me.file = item.file;
        },
        onBack() {
            let me = this;
            me.$router.replace(me.pagelink + "/view/" + me.id).catch((err) => {
                err;
            });
        },
        onVisualizzaPDF() {
            console.log("ciao");
        },
    },
};
</script>
