import PazientiList from "./components/PazientiList.vue";
import PazienteEdit from "./components/PazienteEdit.vue";
import PazienteView from "./components/PazienteView.vue";

import PazientiDiabetologiaAnamnesiDiabetologiaEdit from "./components/PazientiDiabetologiaAnamnesiDiabetologiaEdit.vue";
import PazientiDiabetologiaAnamnesiFamiliareEdit from "./components/PazientiDiabetologiaAnamnesiFamiliareEdit.vue";
import PazientiDiabetologiaAnamnesiPatologiaEdit from "./components/PazientiDiabetologiaAnamnesiPatologiaEdit.vue";
import PazientiDiabetologiaAnamnesiFisiologicaEdit from "./components/PazientiDiabetologiaAnamnesiFisiologicaEdit.vue";

import PazientiDiabetologiaFollowUP from "./components/PazientiDiabetologiaFollowUP.vue";
import PazientiDiabetologiaPrimaVisitaEdit from "./components/PazientiDiabetologiaPrimaVisitaEdit.vue";

import PazientiDiabetologiaControlliSpecialisticiEdit from "./components/PazientiDiabetologiaControlliSpecialisticiEdit.vue";
import PazientiDiabetologiaDatiLaboratorio from "./components/PazientiDiabetologiaDatiLaboratorio.vue";

export default {
    PazientiList,
    PazienteEdit,
    PazienteView,

    PazientiDiabetologiaAnamnesiDiabetologiaEdit,
    PazientiDiabetologiaAnamnesiFamiliareEdit,
    PazientiDiabetologiaAnamnesiPatologiaEdit,
    PazientiDiabetologiaAnamnesiFisiologicaEdit,

    PazientiDiabetologiaFollowUP,
    PazientiDiabetologiaPrimaVisitaEdit,

    PazientiDiabetologiaControlliSpecialisticiEdit,
    PazientiDiabetologiaDatiLaboratorio,
};
