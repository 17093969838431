<template>
    <aside :class="['main-sidebar', `sidebar-${type}-${variant}`, elevationClass]">
        <slot />
    </aside>
</template>

<script>
import elevation from "../mixins/elevation";

export default {
    mixins: [elevation()],
    props: {
        type: {
            type: String,
            default: "light",
        },
        variant: {
            type: String,
            default: "primary",
        },
        mini: {
            type: Boolean,
            default: false,
        },
        collapse: {
            type: Boolean,
            default: false,
        },
    },
    created() {},
};
</script>
