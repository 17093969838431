import Accettazione from "../";

const routes = [
    { name: "AccettazioneList", path: "/pazienteaccettazione", component: Accettazione.AccettazioneList, meta: { title: "accettazione.lblTitle" } },
    { name: "AccettazioneView", path: "/pazienteaccettazione/view/:id", component: Accettazione.AccettazioneView, meta: { title: "accettazione.lblEditTitle" } },
    { name: "AccettazioneEdit", path: "/pazienteaccettazione/edit/:id", component: Accettazione.AccettazioneEdit, meta: { title: "accettazione.lblViewTitle" } },
    { name: "AccettazioneEdit", path: "/accettazionestruttura/edit/:id", component: Accettazione.AccettazioneStrutturaEdit, meta: { title: "accettazione.lblViewTitle" } },
];

export default routes;
