import CardioAnamnesiList from "./components/CardioAnamnesiList.vue";
import CardioAnamnesiEdit from "./components/CardioAnamnesiEdit.vue";
import CardioAnamnesiView from "./components/CardioAnamnesiView.vue";
import CardioRefertazioneList from "./components/CardioRefertazioneList.vue";
import CardioRefertazioneEdit from "./components/CardioRefertazioneEdit.vue";
import CardioRefertazioneView from "./components/CardioRefertazioneView.vue";

export default {
  CardioAnamnesiList,
  CardioAnamnesiEdit,
  CardioAnamnesiView,
  CardioRefertazioneList,
  CardioRefertazioneEdit,
  CardioRefertazioneView,
};
