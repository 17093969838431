import PazienteSic from "./components/PazienteSic.vue";
import PazienteSicList from "./components/PazienteSicList.vue";
import PazienteSicAllergieList from "./components/PazienteSicAllergieList.vue";
import PazienteSicAllergieView from "./components/PazienteSicAllergieView.vue";
import PazienteSicAllergieEdit from "./components/PazienteSicAllergieEdit.vue";
import PazienteSicIntolleranzeList from "./components/PazienteSicIntolleranzeList.vue";
import PazienteSicIntolleranzeView from "./components/PazienteSicIntolleranzeView.vue";
import PazienteSicIntolleranzeEdit from "./components/PazienteSicIntolleranzeEdit.vue";

export default {
    PazienteSic,
    PazienteSicList,
    PazienteSicAllergieList,
    PazienteSicAllergieView,
    PazienteSicAllergieEdit,
    PazienteSicIntolleranzeList,
    PazienteSicIntolleranzeView,
    PazienteSicIntolleranzeEdit,
};
