<template>
    <sa-page-layout :pathResource="pathResource" :btnEditVisible="true" :btnAnnullaVisible="true" :linkback="linkback" :linkedit="linkedit">
        <template slot="toolbar-title">
            <paziente-anagrafica :linkPatient="linkPatient" :idPaziente="idPaziente"></paziente-anagrafica>
        </template>
        <template slot="table-body">
            <div class="sa-tab-scroll">
                <visita-hh-component :idEvento="idEvento"></visita-hh-component>
            </div>
        </template>
    </sa-page-layout>
</template>
<script>
import SaPageLayout from "../../../../../template/sa/components/layout/contents/SaPageLayout.vue";
import PazienteAnagrafica from "../../../components/PazienteAnagrafica.vue";
import VisitaHhComponent from "./VisitaHhViewComponent.vue";

export default {
    components: { SaPageLayout, PazienteAnagrafica, VisitaHhComponent },
    props: {
        utente: {
            type: Object,
            default: function() {
                return null;
            },
        },
    },
    data() {
        return {
            pathResource: "/visitehh",
            linkedit: "/paziente/hyperhealtheventi/visitahh/edit/",
            linkback: "/paziente/hyperhealtheventi/visitehh/",
        };
    },
    computed: {
        idPaziente() {
            return this.$route.query.idPaziente;
        },
        idEvento() {
            return this.$route.params.id;
        },
        linkPatient: {
            get: function() {
                let returnValue = "";
                if (this.utente.gruppo) {
                    if (this.utente.gruppo.menuGruppo) {
                        if (this.utente.gruppo.menuGruppo.length > 1) {
                            return "/paziente/view/" + this.idPaziente;
                        } else {
                            if (this.utente.gruppo.menuGruppo.length == 1) {
                                if (this.utente.gruppo.menuGruppo[0].primaryKey === "pazienti") {
                                    return "/paziente/view/" + this.idPaziente;
                                } else {
                                    return "/paziente/homepaziente";
                                }
                            }
                        }
                    }
                }
                return returnValue;
            },
        },
    },
    mounted: function() {
        let me = this;
        me.id = this.$route.params.id;
        me.linkedit = "/paziente/hyperhealtheventi/visitahh/edit/" + me.id + "?idPaziente=" + me.idPaziente;
        me.linkback = "/paziente/hyperhealtheventi/visitehh?idPaziente=" + me.idPaziente;
    },
    methods: {
        loadData() {},
    },
};
</script>
