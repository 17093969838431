<template>
    <div>
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                        <strong>Giudizio</strong><br />
                        {{ data.giudizio }}
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                        <strong>Validita Mesi</strong><br />
                        {{ data.validitaCertificato }}
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                        <strong>Data Scadenza </strong><br />
                        {{ formatDate(data.dataScadenza) }}
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <hr />
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                        <strong>Giudizio conclusivo di non idoneità o sospensione</strong><br />
                        {{ data.giudizioConclusivo }}
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <hr />
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                        <strong>Stato </strong><br />
                        {{ data.firma }}
                    </b-col>
                </b-row>
                <hr />
            </b-col>
        </b-row>
    </div>
</template>
<script>
import UtilityMixin from "../../utility/UtilityMixin.js";
export default {
    mixins: [UtilityMixin],
    components: {},
    props: {
        data: {
            Type: Object,
            default: function() {
                return {
                    giudizio: "",
                    giudizioConclusivo: "",
                    validitaCertificato: null,
                    dataScadenza: null,
                };
            },
        },
    },
    data() {
        return {
            pathRestFirma: "/certificatianamnesi",
        };
    },
};
</script>
