<template>
    <sa-page-view-edit ref="pgaView" :data="data" :pathResource="pathResource" :backVisible="true" :saveVisible="true" :titolo="data.titolo" :linkback="linkback" :linkedit="linkedit" :showmolalloading="showmolalloading">
        <template slot="body">

            <b-row>
                <b-col cols="12" lg="2" md="2" class="sa-text-right"><b>{{ this.$i18n.t("pazienti.lblSpecialistChecks")}}</b></b-col>
            </b-row>

            <b-row>
                <b-col cols="12" lg="2" md="2" class="sa-text-right">{{ this.$i18n.t("pazienti.lblOrigin")}}:</b-col>
                <b-col cols="12" lg="10" md="10" class="sa-text-left">
                    <b-form-input v-model="data.provenienza" size="sm" placeholder="Provenienza" required></b-form-input>
                </b-col>
            </b-row>

            <b-row>
                <b-col cols="12" lg="2" md="2" class="sa-text-right">{{ this.$i18n.t("pazienti.lblNeurologicalVisit")}}:</b-col>
                <b-col cols="12" lg="10" md="10" class="sa-text-left">
                    <b-form-input v-model="data.visitaNeurologica" size="sm" placeholder="Visita Neurologica" required></b-form-input>
                </b-col>
            </b-row>

            <b-row>
                <b-col cols="12" lg="2" md="2" class="sa-text-right">{{ this.$i18n.t("pazienti.lblRightMalleolusBiothesiometry")}}:</b-col>
                <b-dropdown text="Seleziona" block variant="secondary" class="m-2">
                    <b-dropdown-item ></b-dropdown-item>
                    <b-dropdown-item >{{ this.$i18n.t("pazienti.lblNormal")}}</b-dropdown-item>
                    <b-dropdown-item >{{ this.$i18n.t("pazienti.lblReduced")}}</b-dropdown-item>
            </b-dropdown>
                <b-col cols="12" lg="7" md="7" class="sa-text-left">
                    <b-form-input v-model="data.biotesiometriaMalleoloDx" size="sm" placeholder="Biotesiometria Malleolo Dx" required></b-form-input>
                </b-col>
            </b-row>

            <b-row>
                <b-col cols="12" lg="2" md="2" class="sa-text-right">{{ this.$i18n.t("pazienti.lblLeftMalleolusBiothesiometry")}}:</b-col>
                <b-dropdown text="Seleziona" block variant="secondary" class="m-2">
                    <b-dropdown-item ></b-dropdown-item>
                    <b-dropdown-item >{{ this.$i18n.t("pazienti.lblNormal")}}</b-dropdown-item>
                    <b-dropdown-item >{{ this.$i18n.t("pazienti.lblReduced")}}</b-dropdown-item>
                </b-dropdown>
                <b-col cols="12" lg="7" md="7" class="sa-text-left">
                    <b-form-input v-model="data.biotesiometriaMalleoloSx" size="sm" placeholder="Biotesiometria Malleolo Sx" required></b-form-input>
                </b-col>
            </b-row>

            <b-row>

                <b-col cols="12" lg="2" md="2" class="sa-text-right">{{ this.$i18n.t("pazienti.lblRightFirstToeBiothesiometry")}}:</b-col>
                <b-dropdown text="Seleziona" block variant="secondary" class="m-2">
                    <b-dropdown-item ></b-dropdown-item>
                    <b-dropdown-item >{{ this.$i18n.t("pazienti.lblNormal")}}</b-dropdown-item>
                    <b-dropdown-item >{{ this.$i18n.t("pazienti.lblReduced")}}</b-dropdown-item>
                </b-dropdown>
                <b-col cols="12" lg="7" md="7" class="sa-text-left">
                    <b-form-input v-model="data.biotesiometriaprimoditopiededx" size="sm" placeholder="Biotesiometria Primo Dito Piede Dx:" required></b-form-input>
                </b-col>
            </b-row>

            <b-row>
                <b-col cols="12" lg="2" md="2" class="sa-text-right">{{ this.$i18n.t("pazienti.lblLeftFirstToeBiothesiometry")}}:</b-col>
                <b-dropdown text="Seleziona" block variant="secondary" class="m-2">
                    <b-dropdown-item ></b-dropdown-item>
                    <b-dropdown-item >{{ this.$i18n.t("pazienti.lblNormal")}}</b-dropdown-item>
                    <b-dropdown-item >{{ this.$i18n.t("pazienti.lblReduced")}}</b-dropdown-item>
                </b-dropdown>
                <b-col cols="12" lg="7" md="7" class="sa-text-left">
                    <b-form-input v-model="data.biotesiometriPprimoDitoPiedeSx" size="sm" placeholder="Biotesiometria Primo Dito Piede Sx" required></b-form-input>
                </b-col>
            </b-row>

            <b-row>
                <b-col cols="12" lg="2" md="2" class="sa-text-right">{{ this.$i18n.t("pazienti.lblElectromyography")}}:</b-col>
                <b-col cols="12" lg="10" md="10" class="sa-text-left">
                    <b-form-input v-model="data.elettromiografia" size="sm" placeholder="Elettromiografia" required></b-form-input>
                </b-col>
            </b-row>

            <b-row>
                <b-col cols="12" lg="2" md="2" class="sa-text-right">{{ this.$i18n.t("pazienti.lblCarioangiologicalVisit")}}:</b-col>
                <b-col cols="12" lg="10" md="10" class="sa-text-left">
                    <b-form-input v-model="data.visitaCarioangiologica" size="sm" placeholder="Visita Carioangiologica" required></b-form-input>
                </b-col>
            </b-row>

            <b-row>
                <b-col cols="12" lg="2" md="2" class="sa-text-right">{{ this.$i18n.t("pazienti.lblECG")}}:</b-col>
                <b-col cols="12" lg="10" md="10" class="sa-text-left">
                    <b-form-input v-model="data.ecg" size="sm" placeholder="Ecg" required></b-form-input>
                </b-col>
            </b-row>

            <b-row>
                <b-col cols="12" lg="2" md="2" class="sa-text-right">Doppler:</b-col>
                <b-col cols="12" lg="10" md="10" class="sa-text-left">
                    <b-form-input v-model="data.doppler" size="sm" placeholder="Doppler" required></b-form-input>
                </b-col>
            </b-row>

            <b-row>
                <b-col cols="12" lg="2" md="2" class="sa-text-right">{{ this.$i18n.t("pazienti.lblEyeExamination")}}:</b-col>
                <b-col cols="12" lg="10" md="10" class="sa-text-left">
                    <b-form-input v-model="data.visitaOculistica" size="sm" placeholder="Visita Oculistica" required></b-form-input>
                </b-col>
            </b-row>
            
            <b-row>
                <b-col cols="12" lg="2" md="2" class="sa-text-right">{{ this.$i18n.t("pazienti.lblFluoroangiography")}}:</b-col>
                <b-col cols="12" lg="10" md="10" class="sa-text-left">
                    <b-form-input v-model="data.fluoroangiografia" size="sm" placeholder="Fluoroangiografia" required></b-form-input>
                </b-col>
            </b-row>


            <b-row>
                <b-col cols="12" lg="2" md="2" class="sa-text-right">{{ this.$i18n.t("pazienti.lblProgressiveEvent") }}:</b-col>
                <b-col cols="6" lg="4" md="4" class="sa-text-left">
                    <b-form-input v-model="data.progressivoEvento" size="sm" placeholder="Progressivo Evento" required></b-form-input>
                </b-col>
                <b-col cols="12" lg="2" md="2" class="sa-text-right">{{ this.$i18n.t("pazienti.lblDateEvent") }}:</b-col>
                <b-col cols="12" lg="4" md="4" class="sa-text-right">
                    <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="data.dataNascita" type="date" placeholder="Data Nascita"></date-picker>
                </b-col>
            </b-row>

        </template>
    </sa-page-view-edit>
</template>
<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import { SaPageViewEdit } from "../../../template/sa/components/layout/contents";
import moment from "moment";
import axios from "axios";
export default {
    components: { SaPageViewEdit, DatePicker },
    //components: { SaPageViewEdit},
    data() {
        return {
            pathRest: "/eventidiabetologiacontrollispecialisticiammissione",
            pathResource: "/eventidiabetologiacontrollispecialisticiammissione",
            //linkback: "/pazientidiabetologia",
            linkback: "/paziente",

            linkedit: null,
            showmolalloading: false,
            id: "-1",
            data: {},
        };
    },
    computed: {
        dataAavvioData: {
            get: function() {
                return new Date(this.data.avvioData);
            },
            set: function(value) {
                this.data.avvioData = moment(String(value)).valueOf();
            },
        },
    },
    mounted: function() {
        let me = this;
        me.id = this.$route.params.id;
        if (me.id !== "-1") {
            me.linkback = me.linkback + "/view/" + me.id;
        }
        me.loadData();
    },
    methods: {
        formatDate(value) {
            if (value) {
                return moment(new Date(value)).format("DD-MM-YYYY HH:mm:ss");
            } else {
                return "---";
            }
        },
        loadData() {
            let me = this;
            me.showmolalloading = false;
            //:if (me.id === "-1") {
            //    me.linkback = "/pazientidiabetologia";
            //    me.showmolalloading = false;
            //} else {
            //    me.linkback = "/pazientidiabetologia/view/" + me.id;
            //    me.showmolalloading = true;
            //    let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
            //    axios.get(link + me.id).then((response) => {
            //        me.data = response.data.data;
            //        me.showmolalloading = false;
            //    });
            //}
        },
        onSave() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource;
            let jsonData = JSON.stringify(me.data);

            me.showmolalloading = true;
            if (me.id == "-1") {
                axios
                    .post(link, jsonData)
                    .then((response) => {
                        me.data = response.data.data;
                        me.showmolalloading = false;
                        me.$router.replace(me.routeLink).catch((err) => {
                            err;
                        });
                    })
                    .catch((error) => {
                        me.showmolalloading = false;
                        this.$bvModal
                            .msgBoxOk(error.response.data.messaggio, {
                                title: "ATTENZIONE",
                                size: "sm",
                                okVariant: "outline-danger",
                                headerClass: "sa-msg-header-danger",
                                footerClass: "p-2 border-top-0",
                                centered: true,
                            })
                            .then((value) => {
                                this.boxTwo = value;
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                    });
            } else {
                axios
                    .put(link + "/" + me.id, jsonData)
                    .then((response) => {
                        me.gruppo = response.data.data;
                        me.$refs["sa-modal-loading"].close();
                        me.$router.replace(me.routeLink).catch((err) => {
                            err;
                        });
                    })
                    .catch((error) => {
                        me.$refs["sa-modal-loading"].close();
                        this.$bvModal
                            .msgBoxOk(error.response.data.messaggio, {
                                title: "ATTENZIONE",
                                size: "sm",
                                okVariant: "outline-danger",
                                headerClass: "sa-msg-header-danger",
                                footerClass: "p-2 border-top-0",
                                centered: true,
                            })
                            .then((value) => {
                                this.boxTwo = value;
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                    });
            }
        },
        onBack() {
            let me = this;
            me.$router.replace(me.routeLink).catch((err) => {
                err;
            });
        },
    },
};
</script>