<template>
    <sa-page-layout :btnNewVisible="true" :btnRefreshVisible="true" :linkedit="linkedit" :pathResource="pathResource" :showModalLoading="showModalLoading">
        <template slot="table-filter">
            <b-form @submit.prevent="onSubmit">
                <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                    <b-col sm="6">
                        <b-form-input v-model="filtro.nome" type="search" id="nome" placeholder="Nome"></b-form-input>
                    </b-col>
                    <b-col sm="6">
                        <b-form-input v-model="filtro.cognome" type="search" id="cognome" placeholder="Cognome"></b-form-input>
                    </b-col>
                </b-row>
                <!-- <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                    <b-col sm="6">
                        <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="filtro.dataAccettazione" type="date" placeholder="Data Inizio"></date-picker>
                    </b-col>
                </b-row> -->
                <b-row>
                    <b-col class="sa-padding-right text-right">
                        <b-button type="submit" variant="info">{{ this.$i18n.t("global.lblFind") }}</b-button>
                        <b-button type="reset" v-on:click="onReset" variant="danger">{{ this.$i18n.t("patients.lblResetFilter") }}</b-button>
                    </b-col>
                </b-row>
            </b-form>
        </template>
        <template slot="table-header">
            <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col lg="3">
                    <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
                </b-col>
                <b-col lg="9">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
            </b-row>
        </template>
        <template slot="table-body">
            <div class="b-table-sticky-header">
                <b-table sticky-header ref="table" responsive stacked="xl" striped hover :items="items" :fields="fields" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                    <template v-slot:cell(nominativo)="{ item }">
                        <router-link class="sa-edit-link" :to="'/worklist/visitamedicosportiva/view/' + item.id">{{ item.nome }} {{ item.cognome }}</router-link>
                    </template>
                </b-table>
            </div>
        </template>
        <template slot="table-footer">
            <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col lg="3">
                    <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
                </b-col>
                <b-col lg="9">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
            </b-row>
        </template>
    </sa-page-layout>
</template>
<script>
import axios from "axios";
import moment from "moment";
// import DatePicker from "vue2-datepicker";
// import "vue2-datepicker/index.css";
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
export default {
    components: { SaPageLayout },
    computed: {
        rows() {
            return this.items.length;
        },
    },
    data() {
        return {
            time3: null,
            perPage: 10,
            currentPage: 1,
            pathResource: "/visite",
            filtro: { dataAccettazione: null, nome: "", cognome: "" },
            showModalLoading: false,
            linkedit: "/pendinglist",
            items: [],
            fields: [
                {
                    label: "Nominativo",
                    key: "nominativo",
                    sortable: true,
                },
                {
                    label: "Sesso",
                    key: "sesso",
                    thStyle: "width: 2rem",
                    sortable: true,
                },
                {
                    label: "Data Nascita",
                    key: "dataNascita",
                    formatter: (value) => {
                        return moment(new Date(value)).format("DD/MM/YYYY");
                    },
                    thStyle: "width: 5rem",
                    sortable: true,
                },
                {
                    label: "Data Accettazione",
                    key: "dataAccettazione",
                    formatter: (value) => {
                        return moment(new Date(value)).format("DD/MM/YYYY");
                    },
                    thStyle: "width: 5rem",
                    sortable: true,
                },
                {
                    label: "Pr. Anno",
                    key: "progressivoAnno",
                    thStyle: "width: 5rem",
                    sortable: true,
                },
                {
                    label: "Sport",
                    key: "sport",
                    thStyle: "width: 5rem",
                    sortable: true,
                },
                {
                    label: "Tipo Visita",
                    key: "tipoVisita",
                    thStyle: "width: 5rem",
                    sortable: true,
                },
                {
                    label: "Tipo Atleta",
                    key: "tipoAttleta", //******Sul Back-end è scritto Attleta******
                    thStyle: "width: 5rem",
                    sortable: true,
                },
                {
                    label: "Medico Esecutore",
                    key: "nomeMedicoAssegnato",
                    thStyle: "width: 5rem",
                    sortable: true,
                },
            ],
        };
    },
    mounted() {
        let me = this;
        me.loadData();
    },
    methods: {
        loadData() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource;
            me.showModalLoading = true;
            axios
                .get(link)
                .then((response) => {
                    me.items = [];
                    me.items = response.data.data;
                    me.showModalLoading = false;
                })
                .catch(() => {
                    me.showModalLoading = false;
                });
        },
        onRefresh() {
            let me = this;
            me.loadData();
        },
        onSubmit() {
            let me = this;
            let sessionStorage = window.sessionStorage;
            sessionStorage["filtro"] = JSON.stringify(me.filtro);
            me.loadData();
        },
        onReset() {
            let me = this;
            me.filtro = {};
            let sessionStorage = window.sessionStorage;
            sessionStorage["filtro"] = JSON.stringify(me.filtro);
            me.loadData();
        },
    },
};
</script>
