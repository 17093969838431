<template>
  <sa-page-layout :showModalLoading="showModalLoading" :btnSaveVisible="true" :btnAnnullaVisible="true" :btnBackVisible="false" :linkback="linkback" :pathResource="pathResource" :data="jsonData">
    <template slot="toolbar-title">
      <paziente-anagrafica :linkPatient="linkPatient" :idPaziente="idPaziente"></paziente-anagrafica>
    </template>
    <template slot="table-body">
      <div class="sa-tab-scroll">
        <b-row>
          <b-col>
            <!-- <b-tabs content-class="mt-3" align="center" justified> -->
            <br />
            <b-tab title="Dati" active>
              <div class="sa-form-section">
                <b-row>
                  <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <strong>{{ this.$i18n.t("pazienti.egds.lblInstrument") }}</strong>
                    <br />
                    <b-form-select v-model="jsonData.strumento" :options="optionsStrumento" type="text"></b-form-select>
                  </b-col>
                  <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <strong>{{ this.$i18n.t("pazienti.egds.lblWashingDisinfection") }}</strong>
                    <br />
                    <b-form-select v-model="jsonData.lavaggioDisinfezione" :options="optionsLavaggio" type="text"></b-form-select>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <strong>{{ this.$i18n.t("pazienti.egds.lblTherapiesInPlace") }}</strong>
                    <br />
                    <b-form-input v-model="jsonData.terapieInAtto" type="text"></b-form-input>
                  </b-col>
                  <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <strong>{{ this.$i18n.t("pazienti.egds.lblIndications") }}</strong>
                    <br />
                    <b-form-select v-model="jsonData.indicazione" :options="optionsIndicazioni" type="text"></b-form-select>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                    <strong>{{ this.$i18n.t("pazienti.egds.lblEsophagus") }}</strong>
                    <br />
                    <b-form-textarea v-model="jsonData.esofago" type="text" no-resize></b-form-textarea>
                  </b-col>
                  <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                    <strong>{{ this.$i18n.t("pazienti.egds.lblStomach") }}</strong>
                    <br />
                    <b-form-textarea v-model="jsonData.stomaco" type="text" no-resize></b-form-textarea>
                  </b-col>
                  <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                    <strong>{{ this.$i18n.t("pazienti.egds.lblDuodenumFasting") }}</strong>
                    <br />
                    <b-form-textarea v-model="jsonData.duodenoDigiuno" type="text" no-resize></b-form-textarea>
                  </b-col>
                  <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                    <strong>{{ this.$i18n.t("pazienti.egds.lblConclusions") }}</strong>
                    <br />
                    <b-form-textarea v-model="jsonData.conclusioni" type="text" no-resize></b-form-textarea>
                  </b-col>
                  <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                    <strong>{{ this.$i18n.t("pazienti.egds.lblHpTest") }}</strong>
                    <br />
                    <b-form-select v-model="jsonData.helicobacterPilori" :options="hpOptions" :value="null"></b-form-select>
                  </b-col>
                </b-row>
              </div>
            </b-tab>
            <!-- <b-tab title="Foto"> page foto </b-tab>
              <b-tab title="Allegati"> page allegati </b-tab> -->
            <!-- </b-tabs> -->
          </b-col>
        </b-row>
        <br />
      </div>
    </template>
  </sa-page-layout>
</template>
<script>
import axios from "axios";
import SaPageLayout from "../../../../../template/sa/components/layout/contents/SaPageLayout.vue";
import PazienteAnagrafica from "../../../components/PazienteAnagrafica.vue";
export default {
  components: { SaPageLayout, PazienteAnagrafica },
  props: {
    utente: {
      type: Object,
      default: function() {
        return { gruppo: { menuGruppo: [] } };
      },
    },
  },
  data() {
    return {
      linkback: null,
      pathResource: "/gastroenterologiaegds",
      jsonData: {},
      showModalLoading: false,
      optionsStrumento: [],
      optionsLavaggio: [],
      optionsIndicazioni: [],
      hpOptions: [
        { text: "Non Effettuato", value: "Non Effettuato" },
        { text: "Positivo", value: "Positivo" },
        { text: "Negativo", value: "Negativo" },
      ],
      lavaggioDisinfezioneOptions: [
        { text: "--Seleziona Opzione--", value: null },
        { text: "Prova", value: "Prova" },
      ],
      indicazioneOptions: [{ text: "--Seleziona Indicazione--", value: null }],
    };
  },
  mounted() {
    let me = this;
    me.jsonData.idPaziente = this.$route.query.idPaziente;
    me.jsonData.tipoEvento = "Gastroenterologia - EGDS";
    me.id = this.$route.params.id;
    me.linkedit = "/paziente/gastroenterologia/egds/edit/" + me.id + "?idPaziente=" + me.idPaziente;
    let link1 = process.env.VUE_APP_PATH_API + "/gastroenterologiaegdsindicazione";
    axios
      .get(link1)
      .then((res) => {
        // console.log(res.data.data);
        let array = res.data.data;
        array.forEach((obj) => {
          if (obj.descrizione !== null && obj.descrizione !== undefined) {
            let oggetto = obj.descrizione;
            me.optionsIndicazioni.push(oggetto);
          }
        });
      })
      .catch();

    let link2 = process.env.VUE_APP_PATH_API + "/gastroenterologiaegdslavaggiodisinfezione";
    axios
      .get(link2)
      .then((res) => {
        // console.log(res.data.data);
        let array = res.data.data;
        array.forEach((obj) => {
          if (obj.descrizione !== null && obj.descrizione !== undefined) {
            let oggetto = obj.descrizione;
            me.optionsLavaggio.push(oggetto);
          }
        });
      })
      .catch();

    let link3 = process.env.VUE_APP_PATH_API + "/gastroenterologiaegdsstrumento";
    axios
      .get(link3)
      .then((res) => {
        // console.log(res.data.data);
        let array = res.data.data;
        array.forEach((obj) => {
          if (obj.descrizione !== null && obj.descrizione !== undefined) {
            let oggetto = obj.descrizione;
            me.optionsStrumento.push(oggetto);
          }
        });
      })
      .catch();

    if (me.id !== "-1") {
      me.linkback = "/paziente/gastroenterologia/egds/view/" + me.id + "?idPaziente=" + me.idPaziente;
      me.loadData();
    } else {
      me.linkback = "/paziente/gastroenterologia/egds?idPaziente=" + me.idPaziente;
    }
  },
  computed: {
    idPaziente() {
      return this.$route.query.idPaziente;
    },
    linkPatient: {
      get: function() {
        let returnValue = "";
        if (this.utente.gruppo) {
          if (this.utente.gruppo.menuGruppo) {
            if (this.utente.gruppo.menuGruppo.length > 1) {
              return "/paziente/view/" + this.idPaziente;
            } else {
              if (this.utente.gruppo.menuGruppo.length == 1) {
                if (this.utente.gruppo.menuGruppo[0].primaryKey === "pazienti") {
                  return "/paziente/view/" + this.idPaziente;
                } else {
                  return "/paziente/homepaziente";
                }
              }
            }
          }
        }
        return returnValue;
      },
    },
  },
  methods: {
    loadData() {
      let me = this;

      if (me.id === "-1") {
        me.showModalLoading = false;
      } else {
        let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.id;
        axios.get(link).then((response) => {
          me.jsonData = response.data.data;
          me.showModalLoading = false;
        });
      }
    },
  },
};
</script>
