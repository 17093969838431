<template>
    <sa-page-layout :showModalLoading="showModalLoading" :linkedit="linkedit" :btnNewVisible="true">
        <template slot="toolbar-title">
            <paziente-anagrafica :linkPatient="linkPatient" :idPaziente="idPaziente"></paziente-anagrafica>
            <template slot="toolbar">
                <b-button class="float-sm-right " variant="success btn-toolbar sa-margin-right" size="sm" @click="onAdd"><b-icon icon="plus" variant="secondary"></b-icon></b-button>
                <b-button class="float-sm-right " variant="info btn-toolbar" size="sm" @click="onRefresh"><b-icon icon="arrow-clockwise" variant="secondary" />Refresh</b-button>
            </template>
        </template>
        <template slot="table-header">
            <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col lg="12">
                    <b-pagination v-model="currentPage" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
            </b-row>
        </template>
        <template slot="table-body">
            <div class="b-table-sticky-header">
                <b-table sticky-header ref="table" responsive stacked="lg" striped hover :items="items" :fields="fields" sort-icon-left head-variant="light" class="sa-b-table">
                    <template v-slot:cell(dataEvento)="{ item }">
                        <router-link class="sa-edit-link" :to="'/paziente/diabetologia/controllispecialistici/view/' + item.id + '?idPaziente=' + idPaziente"> {{ formatDate(item.dataEvento) }} </router-link>
                    </template>
                    <template #cell(actions)>
                        <!--<b-button size="sm" variant="btn btn-primary waves-effect  waves-light width-sm no-text" style="margin-right:3px" @click="onEdit">
                            <b-icon icon="display"></b-icon>
                        </b-button>-->
                        <b-button size="sm" variant="btn btn-purple waves-effect  waves-light width-sm no-text" style="margin-right:3px" @click="onStampa">
                            <b-icon icon="printer"></b-icon>
                        </b-button>
                    </template>
                </b-table>
            </div>
        </template>
    </sa-page-layout>
</template>
<script>
import PazienteAnagrafica from "../../../components/PazienteAnagrafica.vue";
import axios from "axios";
import UtilityMixin from "../../../../utility/UtilityMixin.js";
import SaPageLayout from "../../../../../template/sa/components/layout/contents/SaPageLayout.vue";
export default {
    props: {
        utente: {
            type: Object,
            default: function() {
                return null;
            },
        },
    },
    mixins: [UtilityMixin],
    components: { PazienteAnagrafica, SaPageLayout },
    computed: {
        idPaziente() {
            return this.$route.query.idPaziente;
        },
        linkPatient: {
            get: function() {
                let returnValue = "";
                if (this.utente.gruppo) {
                    if (this.utente.gruppo.menuGruppo) {
                        if (this.utente.gruppo.menuGruppo.length > 1) {
                            return "/paziente/view/" + this.idPaziente;
                        } else {
                            if (this.utente.gruppo.menuGruppo.length == 1) {
                                if (this.utente.gruppo.menuGruppo[0].primaryKey === "pazienti") {
                                    return "/paziente/view/" + this.idPaziente;
                                } else {
                                    return "/paziente/homepaziente";
                                }
                            }
                        }
                    }
                }
                return returnValue;
            },
        },
    },
    data() {
        return {
            pathResources: "/eventidiabetologiacontrollispecialisticiammissione",
            linkedit: null,
            showModalLoading: false,
            items: [{ dataEvento: Date.now() }],
            filtro: {},
            perPage: 10,
            currentPage: 1,
            filter: null,
            id: "-1",
            fields: [
                {
                    label: "Data Evento",
                    key: "dataEvento",
                    sortable: true,
                },
                {
                    label: "",
                    key: "actions",
                    thStyle: "width: 4rem",
                },
            ],
        };
    },
    mounted() {
        let me = this;
        me.linkedit = "/paziente/diabetologia/controllispecialistici";
        // me.loadData();
    },
    methods: {
        onAdd() {},
        onRefresh() {},
        loadData() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResources + "?idPaziente=";
            axios.get(link + me.idPaziente, { params: me.filtro }).then((response) => {
                me.items = [];
                me.items = response.data.data;
                me.showModalLoading = false;
            });
        },
        onEdit() {},
        onStampa() {},
    },
};
</script>
