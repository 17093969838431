<template>
    <sa-page-view-edit :backVisible="true" :data="data" :pathResource="pathResource" :linkback="linkback" :saveVisible="true" :showmolalloading="showmolalloading">
        <template slot="body">
            <div>
                <div class="sa-form-section">
                    <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                        <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                            <span class="sa-form-label">Codice</span>
                            <b-form-input v-model="data.codice" placeholder="Codice"></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                            <span class="sa-form-label">Descrizione</span>
                            <b-form-input v-model="data.descrizione" placeholder="Descrizione"></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                            <span class="sa-form-label">Valore</span>
                            <b-form-input v-model="data.valore" placeholder="Valore" type="number"></b-form-input>
                        </b-col>
                    </b-row>
                    <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                        <b-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                            <span class="sa-form-label">Anno</span>
                            <b-form-input v-model="data.anno" placeholder="Anno" type="number"></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                            <span class="sa-form-label">Codice Struttura</span>
                            <b-form-input v-model="data.codiceStruttura" placeholder="Codice Struttura"></b-form-input>
                        </b-col>
                    </b-row>
                    <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <b-form-textarea v-model="data.note" placeholder="" rows="3" max-rows="6" no-resize></b-form-textarea>
                        </b-col>
                    </b-row>
                </div>
            </div>
        </template>
    </sa-page-view-edit>
</template>
<script>
import axios from "axios";
import SaPageViewEdit from '../../../template/sa/components/layout/contents/SaPageViewEdit.vue';
export default {
    components:{ SaPageViewEdit },
    data(){
        return{
            pathResource: "/numeratori",
            id: "",
            showmolalloading: false,
            linkback: null,
            data:{codice: "", descrizione: "", anno: null, codiceStruttura: "", valore: null, note: ""},
        }
    },
    mounted(){
        let me = this;
        me.id = this.$route.params.id;
        if(me.id  === "-1"){
            me.linkback = "/fatturazionenumeratori"
        }else{
            me.linkback = "/fatturazionenumeratori/view/" + me.id;
        }
        me.loadData();
    },
    methods:{
        loadData(){
            let me = this;
        if (me.id === "-1") {
            me.showmolalloading = false;
        } else {
            me.showmolalloading = true;
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
            axios.get(link + me.id).then((response) => {
                me.data = response.data.data;
                me.showmolalloading = false;
            });
        }
        },
    },
}
</script>