<template>
    <sa-page-view-edit :pathResource="pathResource" :backVisible="true" :saveVisible="true" :data="data" :linkback="linkback" :showmolalloading="showmolalloading">
        <template slot="body">
            <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                    <span class="sa-form-label">Tipo Documento</span>
                    <b-form-select v-model="data.idTipoDocumento" class="mb-2 mr-sm-2 mb-sm-0" :options="tipiDocumentoOptions" :value="null" value-field="id" text-field="descrizioneTipoDocumento" @input="onIdTipoDocumentoInput"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                    <span class="sa-form-label">Codice Tipo Documento</span>
                    <b-form-input v-model="data.codiceTipoDocumento" readonly placeholder="Descrizione Causale"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                    <span class="sa-form-label">Codice Causale</span>
                    <b-form-input v-model="data.codiceCausale" placeholder="Codice Causale"></b-form-input>
                </b-col>
                
            </b-row>
            <b-row style="margin-left: 0.5rem; margin-right: 0.5rem; margin-top: 1rem">
                <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                    <span class="sa-form-label">Descrizione Causale</span>
                    <b-form-input v-model="data.descrizioneCausale" placeholder="Descrizione Causale"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                    <span class="sa-form-label">Operazione Magazzino</span>
                    <b-form-select v-model="data.operazioneMagazzino" class="mb-2 mr-sm-2 mb-sm-0" :options="operazioniMagazzinoOptions" :value="null"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                    <span class="sa-form-label">Operazione Contabile</span>
                    <b-form-select v-model="data.operazioneContabile" class="mb-2 mr-sm-2 mb-sm-0" :options="operazioniContabileOptions" :value="null"></b-form-select>
                </b-col>
            </b-row>
            <b-row style="margin-left: 0.5rem; margin-right: 0.5rem; margin-top: 1rem">
                <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                    <span class="sa-form-label">Stampa Prezzi</span>
                    <b-form-checkbox v-model="data.stampaPrezzi" size="lg" name="check-button" switch></b-form-checkbox>
                </b-col>
            </b-row>
        </template>
    </sa-page-view-edit>
</template>
<script>
import axios from "axios";
import SaPageViewEdit from "../../../template/sa/components/layout/contents/SaPageViewEdit.vue";
export default {
    components: { SaPageViewEdit },
    data() {
        return {
            pathResource: "/causalidocumento",
            pathResourceTipiDocumento: "/tipidocumento",
            linkback: "/causalidocumento",
            showmolalloading: false,
            id: null,
            data: { idTipoDocumento: null, codiceTipoDocumento: "",operazioneMagazzino: null, operazioneContabile: null, soggettiCollegati: "-", codiceCausale: "", descrizioneCausale: "", stampaPrezzi: false, documentiCollegati: "-" },
            tipiDocumentoOptions: [],
            operazioniContabileOptions: [
                { value: null, text: "-Seleziona Valore-" },
                { value: "INVARIATO", text: "INVARIATO" },
                { value: "DARE", text: "DARE" },
                { value: "AVERE", text: "AVERE" },
            ],
            operazioniMagazzinoOptions: [
                { value: null, text: "-Seleziona Valore-" },
                { value: "INVARIATO", text: "INVARIATO" },
                { value: "CARICO", text: "CARICO" },
                { value: "SCARICO", text: "SCARICO" },
            ],
        };
    },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        if (me.id === "-1") {
            me.linkback = "/causalidocumento";
        } else {
            me.linkback = "/causalidocumento/view/" + me.id;
        }
        me.loadData();
        me.loadTipiDocumento();
    },
    methods: {
        loadData() {
            let me = this;
            if (me.id === "-1") {
                me.showmolalloading = false;
            } else {
                me.showmolalloading = true;
                let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
                axios.get(link + me.id).then((response) => {
                    me.data = response.data.data;
                    me.showmolalloading = false;
                });
            }
        },
        loadTipiDocumento() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceTipiDocumento;
            axios.get(link).then((response) => {
                me.tipiDocumentoOptions = response.data.data;
                me.tipiDocumentoOptions.unshift({ id: null, descrizioneTipoDocumento: "- Seleziona Tipo Documento -" });
            });
        },
        onIdTipoDocumentoInput(value){
            let me = this;
            me.tipiDocumentoOptions.forEach((tipiDocumento) => {
                if (tipiDocumento.id === value) {
                    me.data.codiceTipoDocumento = tipiDocumento.codiceTipoDocumento;
                }
            });
        },
    },
};
</script>
