<template>
    <div>
        <b-row>
            <b-col
                ><span class="sa-form-label">{{ this.$i18n.t("patient.afa.lblDateVisit") }}</span>
                <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="data.dataEvento" :disabled="!edit" type="datetime"></date-picker>
                <span class="sa-form-label">{{ this.$i18n.t("patient.afa.lblSportPracticed") }}</span>
                <b-form-input v-model="data.sportPraticato" :disabled="!edit"></b-form-input>
                <span class="sa-form-label">{{ this.$i18n.t("patient.afa.lblFollowingVisit") }}</span>
                <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="data.visitaSuccessiva" :disabled="!edit" type="datetime"></date-picker>
                <span class="sa-form-label">{{ this.$i18n.t("patient.afa.lblWeeklyWorkouts") }}</span>
                <b-form-input v-model="data.allenamentiSettimana" :disabled="!edit"></b-form-input>
                <span class="sa-form-label">{{ this.$i18n.t("patient.afa.lblSpeciality") }}</span>
                <b-form-input v-model="data.specialita" :disabled="!edit"></b-form-input>
                <span class="sa-form-label">{{ this.$i18n.t("patient.afa.lblFederation") }}</span>
                <b-form-input v-model="data.federazione" :disabled="!edit"></b-form-input>
                <span class="sa-form-label">{{ this.$i18n.t("patient.afa.lblOtherSports") }}</span>
                <b-form-input v-model="data.altriSport" :disabled="!edit"></b-form-input>
                <span class="sa-form-label">{{ this.$i18n.t("patient.afa.lblAntitetanus") }}</span>
                <b-form-input v-model="data.antitetanica" :disabled="!edit"></b-form-input>
                <span class="sa-form-label">{{ this.$i18n.t("patient.afa.lblAlcohol") }}</span>
                <b-form-input v-model="data.alcool" :disabled="!edit"></b-form-input>
                <span class="sa-form-label">{{ this.$i18n.t("patient.afa.lblNarcoticSubstances") }}</span>
                <b-form-input v-model="data.sostenzeStupefacenti" :disabled="!edit"></b-form-input> </b-col
            ><b-col cols="2"></b-col
            ><b-col
                ><span class="sa-form-label">{{ this.$i18n.t("patient.afa.lblNextVisit") }}</span>
                <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="data.prossimaVisita" :disabled="!edit" type="datetime"></date-picker>
                <span class="sa-form-label">{{ this.$i18n.t("patient.afa.lblHourNextVisit") }}</span>
                <b-form-timepicker v-model="data.oraAppuntamento" :disabled="!edit"></b-form-timepicker>
                <span class="sa-form-label">{{ this.$i18n.t("patient.afa.lblYearsNumber") }}</span>
                <b-form-input v-model="data.numeroAnni" :disabled="!edit"></b-form-input>
                <span class="sa-form-label">{{ this.$i18n.t("patient.afa.lblHoursWorkout") }}</span>
                <b-form-input v-model="data.oreAllenamento" :disabled="!edit"></b-form-input>
                <span class="sa-form-label">{{ this.$i18n.t("patient.afa.lblCorporation") }}</span>
                <b-form-input v-model="data.societa" :disabled="!edit"></b-form-input>
                <span class="sa-form-label">{{ this.$i18n.t("patient.afa.lblLHB") }}</span>
                <b-form-input v-model="data.aslNew" :disabled="!edit"></b-form-input>
                <span class="sa-form-label">{{ this.$i18n.t("patient.afa.lblMenarche") }}</span>
                <b-form-input v-model="data.menarca" :disabled="!edit"></b-form-input>
                <span class="sa-form-label">{{ this.$i18n.t("patient.afa.lblSmoking") }}</span>
                <b-form-input v-model="data.fumo" :disabled="!edit"></b-form-input>
                <span class="sa-form-label">{{ this.$i18n.t("patient.afa.lblCoffee") }}</span>
                <b-form-input v-model="data.caffe" :disabled="!edit"></b-form-input>
                <span class="sa-form-label">{{ this.$i18n.t("patient.afa.lblMilitary") }}</span>
                <b-form-input v-model="data.militare" :disabled="!edit"></b-form-input>
                <span class="sa-form-label">{{ this.$i18n.t("patient.afa.lblMilitaryMotif") }}</span>
                <b-form-input v-model="data.militareMotivo" :disabled="!edit"></b-form-input>
            </b-col>
        </b-row>
        <div style="height: 30px;"></div>
        <b-row>
            <b-col
                ><span class="sa-form-label">{{ this.$i18n.t("patient.afa.lblIdentityDocument") }}</span> <b-form-input v-model="data.documentoIdentitaTipo" :disabled="!edit"></b-form-input>
            </b-col>
            <b-col>
                <span class="sa-form-label">{{ this.$i18n.t("patient.afa.lblIdentityDocumentNumber") }}</span> <b-form-input v-model="data.documentoIdentitaNumero" :disabled="!edit"></b-form-input>
            </b-col>
            <b-col>
                <span class="sa-form-label">{{ this.$i18n.t("patient.afa.lblIdentityDocumentReleaser") }}</span
                ><b-form-input v-model="data.documentoIdentitaRilasciatoDa" :disabled="!edit"></b-form-input>
                <span class="sa-form-label">{{ this.$i18n.t("patient.afa.lblIdentityDocumentDateRelease") }}</span>
                <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="data.documentoIdentitaRilasciatoIl" :disabled="!edit" type="datetime"></date-picker>
            </b-col>
        </b-row>
        <b-form-group> </b-form-group>
    </div>
</template>

<script>
import DatePicker from "vue2-datepicker";

export default {
    props: ["data", "edit"],
    components: { DatePicker },
};
</script>
