<template>
    <div>
        <b-button v-b-modal.stampaReferto variant="btn--md btn-light waves-effect waves-light btn-toolbar sa-margin-right" size="sm" @click="onPrint"><b-icon icon="printer"/>Stampa</b-button>
        <template slot="body">
            <b-modal ref="mdlStampaReferto" size="xl" style="height: 100%;">
                <b-embed type="iframe" :src="stampaReport" allowfullscreen></b-embed>
            </b-modal>
        </template>
    </div>
</template>
<script>
import axios from "axios";
export default {
    props: {
        reportName: String,
        data: null,
    },
    data(){
        return{
            stampaReport:null,
            linkStampa: "/print/createreport",
        }
    },
    methods: {
        onPrint(){
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkStampa;
            axios
                .post(link, {reportName: me.reportName, data: me.data})
                .then((response) => {
                    me.$refs.mdlStampaReferto.show();
                    me.stampaReport = "data:application/pdf;base64," + response.data.base64;
                })
        },
    }
}
</script>