<template>
  <div>
    <b-row>
      <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
        <b-row>
          <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
            <strong>{{ this.$i18n.t("patient.idoneity.lblDateVisit") }}</strong>
            <br />
            {{ formatDate(jsonData.dataEvento) }}
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
            <strong>{{ this.$i18n.t("patient.idoneity.lblSportPracticed") }}</strong>
            <br />
            {{ jsonData.sportPraticato }}
          </b-col>
          <b-col cols="12" xs="12" sm="6" md="6" lg="4" xl="4">
            <strong>{{ this.$i18n.t("patient.idoneity.lblFollowingVisit") }}</strong>
            <br />
            {{ formatDate(jsonData.visitaSuccessiva) }}
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <hr />
    <b-row>
      <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
        <b-row>
          <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
            <strong>{{ this.$i18n.t("patient.idoneity.lblWeeklyWorkouts") }}</strong>
            <br />
            {{ jsonData.allenamentiSettimana }}
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
            <strong>{{ this.$i18n.t("patient.idoneity.lblSpeciality") }}</strong>
            <br />
            {{ jsonData.specialita }}
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
            <strong>{{ this.$i18n.t("patient.idoneity.lblFederation") }}</strong>
            <br />
            {{ jsonData.federazione }}
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <hr />
    <b-row>
      <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
        <b-row>
          <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
            <strong>{{ this.$i18n.t("patient.idoneity.lblOtherSports") }}</strong>
            <br />
            {{ jsonData.altriSport }}
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
            <strong>{{ this.$i18n.t("patient.idoneity.lblAntitetanus") }}</strong>
            <br />
            {{ jsonData.antitetanica }}
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
            <strong>{{ this.$i18n.t("patient.idoneity.lblAlcohol") }}</strong>
            <br />
            {{ jsonData.alcool }}
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <hr />
    <b-row>
      <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
        <b-row>
          <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
            <strong>{{ this.$i18n.t("patient.idoneity.lblNarcoticSubstances") }}</strong>
            <br />
            {{ jsonData.sostenzeStupefacenti }}
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
            <strong>{{ this.$i18n.t("patient.idoneity.lblNextVisit") }}</strong>
            <br />
            {{ formatDate(jsonData.prossimaVisita) }}
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
            <strong>{{ this.$i18n.t("patient.idoneity.lblHourNextVisit") }}</strong>
            <br />
            {{ jsonData.oraAppuntamento }}
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <hr />
    <b-row>
      <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
        <b-row>
          <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
            <strong>{{ this.$i18n.t("patient.idoneity.lblYearsNumber") }}</strong>
            <br />
            {{ jsonData.numeroAnni }}
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
            <strong>{{ this.$i18n.t("patient.idoneity.lblHoursWorkout") }}</strong>
            <br />
            {{ formatTime(jsonData.oreAllenamento) }}
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
            <strong>{{ this.$i18n.t("patient.idoneity.lblCorporation") }}</strong>
            <br />
            {{ jsonData.societa }}
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <hr />
    <b-row>
      <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
        <b-row>
          <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
            <strong>{{ this.$i18n.t("patient.idoneity.lblLHB") }}</strong>
            <br />
            {{ jsonData.aslNew }}
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
            <strong>{{ this.$i18n.t("patient.idoneity.lblMenarche") }}</strong>
            <br />
            {{ jsonData.menarca }}
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
            <strong>{{ this.$i18n.t("patient.idoneity.lblSmoking") }}</strong>
            <br />
            {{ jsonData.fumo }}
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <hr />
    <b-row>
      <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
        <b-row>
          <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
            <strong>{{ this.$i18n.t("patient.idoneity.lblCoffee") }}</strong>
            <br />
            {{ jsonData.caffe }}
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
            <strong>{{ this.$i18n.t("patient.idoneity.lblMilitary") }}</strong>
            <br />
            {{ jsonData.militare }}
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
            <strong>{{ this.$i18n.t("patient.idoneity.lblMilitaryMotif") }}</strong>
            <br />
            {{ jsonData.militareMotivo }}
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <hr />
    <b-row>
      <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
        <b-row>
          <b-col cols="12" xs="12" sm="12" md="12" lg="3" xl="3">
            <strong>{{ this.$i18n.t("patient.idoneity.lblIdentityDocument") }}</strong>
            <br />
            {{ jsonData.documentoIdentitaTipo }}
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="12" lg="3" xl="3">
            <strong>{{ this.$i18n.t("patient.idoneity.lblIdentityDocumentNumber") }}</strong>
            <br />
            {{ jsonData.documentoIdentitaNumero }}
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="12" lg="3" xl="3">
            <strong>{{ this.$i18n.t("patient.idoneity.lblIdentityDocumentReleaser") }}</strong>
            <br />
            {{ jsonData.documentoIdentitaRilasciatoDa }}
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="12" lg="3" xl="3">
            <strong>{{ this.$i18n.t("patient.idoneity.lblIdentityDocumentDateRelease") }}</strong>
            <br />
            {{ formatDate(jsonData.documentoIdentitaRilasciatoIl) }}
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <hr />
  </div>
</template>
<script>
import UtilityMixin from "../../../../utility/UtilityMixin";
export default {
  mixins: [UtilityMixin],
  props: {
    jsonData: { Type: Object, require: true },
  },
};
</script>
