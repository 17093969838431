<template>
    <div class="sa-page">
        <div class="content-header">
            <b-row>
                <b-col>
                    <div style="float: left; margin-top: 0rem; width: 100%;">
                        <span style="margin-left: 0.5rem; float: left;">{{ titolo }}</span>
                        <slot name="toolbar-title"> </slot>
                    </div>
                </b-col>
                <b-col class="sa-col-toolbar">
                    <div class="sa-card-header-custom-title"></div>
                    <div>
                        <b-button v-if="saveVisible" class="float-sm-right " variant="success btn-toolbar sa-margin-right" size="sm" @click="onSave"><b-icon icon="plus" variant="secondary"></b-icon>{{ this.$i18n.t("global.lblBtnSave") }}</b-button>
                        <b-button v-if="signatureVisible" class="float-sm-right " variant="purple btn-toolbar sa-margin-right" size="sm" @click="onSignature"><b-icon icon="vector-pen" variant="secondary"></b-icon>{{ this.$i18n.t("global.lblBtnSignature") }}</b-button>
                        <b-button v-if="editVisible" class="float-sm-right " variant="purple btn-toolbar sa-margin-right" size="sm" @click="onEdit"><b-icon icon="pencil"></b-icon>Modifica</b-button>
                        <b-button class="float-sm-right " variant="secondary btn-toolbar" size="sm" @click="onBack"><b-icon icon="chevron-left" variant="secondary" />{{ this.$i18n.t("global.lblBtnBack") }}</b-button>
                        <slot name="toolbar"></slot>
                    </div>
                </b-col>
            </b-row>
        </div>
        <b-card>
            <div class="content">
                <div class="sa-table">
                    <div class="sa-table-filter">
                        <slot name="filter"></slot>
                    </div>
                    <div class="sa-table-header-page" style="height: 50px"><slot name="header"></slot></div>
                    <div class="sa-table-body-page">
                        <slot name="body"></slot>
                    </div>
                    <div class="sa-table-footer-page" style="height: 30px"><slot name="footer"></slot></div>
                </div>
            </div>
        </b-card>
        <sa-modal-loading ref="sa-modal-loading" />
    </div>
</template>
<script>
import axios from "axios";
import { SaModalLoading } from "../../../../../components/modal";
export default {
    components: { SaModalLoading },
    props: {
        titolo: String,
        linkback: String,
        linkedit: String,
        pathResource: String,
        showmolalloading: Boolean,
        backVisible: Boolean,
        editVisible: { type: Boolean, default: false },
        saveVisible: { type: Boolean, default: false },
        signatureVisible: { type: Boolean, default: false },
        data: { type: Object },
    },
    computed: {
        showmolalloadingLocal: {
            get: function() {
                return this.showmolalloading;
            },
            set: function(value) {
                let me = this;
                if (value) {
                    me.$refs["sa-modal-loading"].show();
                } else {
                    setTimeout(me.$refs["sa-modal-loading"].close(), 200);
                }
            },
        },
    },
    watch: {
        showmolalloading(value) {
            let me = this;
            if (value) {
                me.$refs["sa-modal-loading"].show();
            } else {
                setTimeout(me.$refs["sa-modal-loading"].close(), 200);
            }
        },
    },
    methods: {
        onBack() {
            let me = this;
            me.$router.replace(me.linkback).catch((err) => {
                err;
            });
        },
        onEdit() {
            let me = this;
            me.$router.replace(me.linkedit).catch((err) => {
                err;
            });
        },
        onSignature() {
            console.log("Firma");
        },
        onSave() {
            let me = this;
            me.showmolalloadingLocal = true;
            if (me.data.id === "-1" || me.data.id === null || me.data.id === undefined) {
                let link = process.env.VUE_APP_PATH_API + me.pathResource;
                axios
                    .post(link, JSON.stringify(me.data))
                    .then((response) => {
                        me.gruppo = response.data.data;
                        me.$router.replace(me.linkback).catch((err) => {
                            err;
                        });
                    })
                    .catch((error) => {
                        me.showmolalloadingLocal = false;
                        this.$bvModal
                            .msgBoxOk(error.response.data.messaggio, {
                                title: "ATTENZIONE",
                                size: "sm",
                                okVariant: "outline-danger",
                                headerClass: "sa-msg-header-danger",
                                footerClass: "p-2 border-top-0",
                                centered: true,
                            })
                            .then((value) => {
                                this.boxTwo = value;
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                    });
            } else {
                let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.data.id;
                axios
                    .put(link, JSON.stringify(me.data))
                    .then(() => {
                        me.$router.replace(me.linkback).catch((err) => {
                            err;
                        });
                    })
                    .catch((error) => {
                        this.$bvModal
                            .msgBoxOk(error.response.data.messaggio, {
                                title: "ATTENZIONE",
                                size: "sm",
                                okVariant: "outline-danger",
                                headerClass: "sa-msg-header-danger",
                                footerClass: "p-2 border-top-0",
                                centered: true,
                            })
                            .then((value) => {
                                console.log(value);
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                    });
            }
        },
    },
};
</script>
