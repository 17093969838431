<template>
  <div>
    <div>
      <div class="sa-form-section">
        <b-row style="margin-top: 2rem;">
          <b-form-file v-model="file1" :state="Boolean(file1)" placeholder="Trascina un file qui" @change="handleFile" ref="file-input" class="custom-input" type="file" accept=".pdf"></b-form-file>
          <div class="mt-3">File Selezionato: {{ file1 ? file1.name : "" }}</div>
        </b-row>
        <b-row>
          <b-col sm="12" md="12" lg="12" xl="12">
            <b-button class="float-sm-right btn-toolbar" variant="success sa-margin-right" size="sm" @click="onSave" v-b-modal.modal-center><b-icon icon="x-circle" />Salva</b-button>
            <b-button class="float-sm-right btn-toolbar" variant="danger sa-margin-right" size="sm" @click="onDelete" v-b-modal.modal-center><b-icon icon="x-circle" />Cancella</b-button>
          </b-col>
        </b-row>
        <hr />
        <b-row>
          <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
            <strong>Tipo Documento</strong>
            <b-form-input v-model="jsonData.tipoDocumento" placeholder="Tipo Documento" disabled></b-form-input>
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
            <strong>Data Caricamento</strong>
            <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="jsonData.dataCaricamento" type="datetime" placeholder="Data Caricamento"></date-picker>
          </b-col>
        </b-row>
        <hr />
        <b-row>
          <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
            <strong>Descrizione</strong>
            <b-form-textarea v-model="jsonData.descrizione" placeholder="Descrizione" no-resize></b-form-textarea>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import DatePicker from "vue2-datepicker";
export default {
  components: { DatePicker },
  computed: {
    idPaziente() {
      return this.$route.query.idPaziente;
    },
  },
  mounted() {
    let me = this;
    me.id = this.$route.params.id;
    me.idRisorsa = me.idPaziente;
    me.jsonData.idAnagrafica = me.idPaziente;
    me.jsonData.tipoDocumento = "Specialists Controls";
    console.log(me.idRisorsa);
  },
  data() {
    return {
      pathResource: "/allegati",
      linkback: null,
      file1: null,
      jsonData: { file: null, idRisorsa: "", nomeFile: "", size: null, tipo: "", tipoDocumento: "", dataCaricamento: null, descrizione: "" },
    };
  },
  methods: {
    onDelete() {
      this.$refs["file-input"].reset();
    },
    onSave() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResource;
      axios
        .post(link, me.jsonData)
        .then(() => {
          const event = new CustomEvent("reload");
          document.dispatchEvent(event);
        })
        .catch();
    },
    handleFile(e) {
      let me = this;
      const selectedFile = e.target.files[0];
      console.log(e.target.files[0]);
      me.jsonData.nomeFile = e.target.files[0].name;
      me.jsonData.size = e.target.files[0].size;
      me.jsonData.tipo = e.target.files[0].type;
      this.createBase64(selectedFile);
    },
    createBase64(fileObject) {
      let me = this;
      const reader = new FileReader();
      reader.onload = (e) => {
        me.pdf = e.target.result;
        me.jsonData.file = me.pdf;
      };
      reader.readAsDataURL(fileObject);
    },
  },
};
</script>
