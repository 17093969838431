<template>
    <sa-page-layout :btnNewVisible="true" :btnRefreshVisible="true" :linkedit="linkedit" @refresh="onRefresh" :pathResource="pathResource" :showModalLoading="showModalLoading">
        <template slot="table-title">
            <paziente-anagrafica :linkPatient="linkPatient" :idPaziente="idPaziente"></paziente-anagrafica>
        </template>
        <template slot="table-header"> </template>
        <template slot="table-body">
            <div class="sa-tab-scroll">
                <b-tabs content-class="mt-3" class="sa-tab" style="height: 100%;" fill>
                    <b-tab active>
                        <template #title>Ultima Intolleranza Rilevata</template>
                        <div style="padding-top: 10px;">
                            <paziente-sic-intolleranze-view-component :jsonData="lastRecord"> </paziente-sic-intolleranze-view-component>
                        </div>
                    </b-tab>
                    <b-tab>
                        <template #title>Storico Intolleranze</template>
                        <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                            <b-col lg="3">
                                <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
                            </b-col>
                            <b-col lg="9">
                                <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                            </b-col>
                        </b-row>
                        <div>
                            <div class="b-table-sticky-header" style="padding-top: 10px;">
                                <b-table sticky-header ref="table" responsive stacked="xl" striped hover :items="items" :fields="fields" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                                    <template v-slot:cell(sostanza)="{ item }">
                                        <router-link class="sa-edit-link" :to="'/paziente/sic/intolleranze/view/' + item.id + '?idPaziente=' + idPaziente">{{ item.sostanza }} ({{ item.codiceSnomed }})</router-link>
                                    </template>
                                </b-table>
                            </div>
                        </div>
                    </b-tab>
                </b-tabs>
            </div>
        </template>
    </sa-page-layout>
</template>
<script>
import axios from "axios";
import moment from "moment";
import SaPageLayout from "../../../../template/sa/components/layout/contents/SaPageLayout.vue";
import PazienteAnagrafica from "../../components/PazienteAnagrafica.vue";
import PazienteSicIntolleranzeViewComponent from "./PazienteSicIntolleranzeViewComponent.vue";
export default {
    components: { SaPageLayout, PazienteAnagrafica, PazienteSicIntolleranzeViewComponent },
    props: {
        utente: {
            type: Object,
            default: function() {
                return null;
            },
        },
    },
    computed: {
        rows() {
            return this.items.length;
        },
        idPaziente() {
            return this.$route.query.idPaziente;
        },
        linkPatient: {
            get: function() {
                let returnValue = "";
                if (this.utente.gruppo) {
                    if (this.utente.gruppo.menuGruppo) {
                        if (this.utente.gruppo.menuGruppo.length > 1) {
                            return "/paziente/view/" + this.idPaziente;
                        } else {
                            if (this.utente.gruppo.menuGruppo.length == 1) {
                                if (this.utente.gruppo.menuGruppo[0].primaryKey === "pazienti") {
                                    return "/paziente/view/" + this.idPaziente;
                                } else {
                                    return "/paziente/homepaziente";
                                }
                            }
                        }
                    }
                }
                return returnValue;
            },
        },
    },
    mounted() {
        let me = this;
        me.loadData();
    },
    data() {
        return {
            linkedit: "/paziente/sic/intolleranze",
            currentPage: 1,
            perPage: 10,
            id: null,
            showModalLoading: null,
            pathResource: "/intolleranze",
            lastRecord: {},
            items: [],
            // items: [{ dataInizio: Date.now(), sostanza: "Carne", stato: "ATTIVA", id: "212121215" }],
            fields: [
                {
                    label: "Data Inizio",
                    key: "data",
                    sortable: true,
                    thStyle: "width: 7rem",
                    formatter: (value) => {
                        return moment(value).format("DD-MM-YYYY");
                    },
                },
                {
                    label: "Sostanza(SNOMED)",
                    key: "sostanza",
                    sortable: true,
                },
                {
                    label: "Stato",
                    key: "statoOsservazione",
                    sortable: true,
                },
            ],
        };
    },
    methods: {
        loadData() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource;
            me.showModalLoading = true;
            axios
                .get(link, { params: me.idPaziente })
                .then((response) => {
                    me.items = [];
                    me.items = response.data.data;
                    if (me.items[0]) {
                        me.lastRecord = me.items[0];
                    }
                    me.showModalLoading = false;
                })
                .catch(() => {
                    me.showModalLoading = false;
                });
        },
        onRefresh() {
            let me = this;
            me.loadData();
        },
    },
};
</script>
