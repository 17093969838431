<template>
    <sa-page-layout :btnSaveVisible="true" :btnAnnullaVisible="true" :linkback="linkback" :pathResource="pathResource" :data="jsonData">
        <template slot="toolbar-title">
            <paziente-anagrafica :linkPatient="linkPatient" :idPaziente="idPaziente"></paziente-anagrafica>
        </template>
        <template slot="table-body">
            <div class="sa-tab-scroll">
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="6" xl="6">
                        <span class="sa-form-label">Data Rilevazione</span>
                        <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="jsonData.data" type="datetime"></date-picker>
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="6" xl="6">
                        <span class="sa-form-label">Sostanza</span>
                        <b-form-select v-model="jsonData.codiceSnomed" :options="sostanzeOptions" :value="null" value-field="codiceSnomed" text-field="descrizione" @input="onInputSostanze($event)"></b-form-select>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="6" xl="6">
                        <span class="sa-form-label">Tipo Reazione</span>
                        <b-form-select v-model="jsonData.tipoReazione" :options="tipoReazioniOptions" :value="null" value-field="value" text-field="descrizione"></b-form-select>
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="6" xl="6">
                        <span class="sa-form-label">Reazione</span>
                        <b-form-select v-model="jsonData.reazione" :options="reazioneOptions" :value="null" value-field="value" text-field="descrizione"></b-form-select>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="6" xl="6">
                        <span class="sa-form-label">Gravità Reazione</span>
                        <b-form-select v-model="jsonData.gravitaReazione" :options="gravitaReazioneOptions" :value="null" value-field="value" text-field="descrizioneGravitaReazione"></b-form-select>
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="6" xl="6">
                        <span class="sa-form-label">Stato Osservazione</span>
                        <b-form-select v-model="jsonData.statoOsservazione" :options="statoOsservazioneOptions" :value="null" value-field="value" text-field="descrizione"></b-form-select>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                        <span class="sa-form-label">Stato Osservazione</span>
                        <b-form-textarea v-model="jsonData.note" no-resize></b-form-textarea>
                    </b-col>
                </b-row>
            </div>
        </template>
    </sa-page-layout>
</template>
<script>
import axios from "axios";
import SaPageLayout from "../../../../template/sa/components/layout/contents/SaPageLayout.vue";
import PazienteAnagrafica from "../../components/PazienteAnagrafica.vue";
import DatePicker from "vue2-datepicker";
export default {
    components: { SaPageLayout, PazienteAnagrafica, DatePicker },
    props: {
        utente: {
            type: Object,
            default: function() {
                return null;
            },
        },
    },
    computed: {
        idPaziente() {
            return this.$route.query.idPaziente;
        },
        linkPatient: {
            get: function() {
                let returnValue = "";
                if (this.utente.gruppo) {
                    if (this.utente.gruppo.menuGruppo) {
                        if (this.utente.gruppo.menuGruppo.length > 1) {
                            return "/paziente/view/" + this.idPaziente;
                        } else {
                            if (this.utente.gruppo.menuGruppo.length == 1) {
                                if (this.utente.gruppo.menuGruppo[0].primaryKey === "pazienti") {
                                    return "/paziente/view/" + this.idPaziente;
                                } else {
                                    return "/paziente/homepaziente";
                                }
                            }
                        }
                    }
                }
                return returnValue;
            },
        },
    },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        me.jsonData.idAnagrafica = me.idPaziente;
        if (me.id === "-1") {
            me.linkback = "/paziente/sic/intolleranze" + "?idPaziente=" + me.idPaziente;
        } else {
            me.linkback = "/paziente/sic/intolleranze/view/" + me.id + "?idPaziente=" + me.idPaziente;
        }
        me.jsonData.data = Date.now();
        me.loadDefaultData();
    },
    data() {
        return {
            linkback: null,
            id: "",
            pathResource: "/intolleranze",
            pathResourceSostanza: "/sostanze",
            pathResourceTipoReazione: "/tiporeazioni",
            pathResourceReazione: "/reazioni",
            pathResourceGravitaReazione: "/gravitareazioni",
            jsonData: { data: null, codiceSnomed: null, sostanza: "", tipoReazione: null, reazione: null, gravitaReazione: null, statoOsservazione: null, note: "" },
            sostanzeOptions: [{ value: null, text: "-Seleziona Sostanza-" }],
            tipoReazioniOptions: [{ value: null, descrizione: "-Seleziona Valore-" }],
            reazioneOptions: [{ value: null, descrizione: "-Seleziona Reazione-" }],
            gravitaReazioneOptions: [{ value: null, descrizioneGravitaReazione: "-Seleziona Valore-" }],
            statoOsservazioneOptions: [
                { value: null, descrizione: "-Seleziona Valore-" },
                { value: "NON RILEVATO", descrizione: "NON RILEVATO" },
                { value: "ATTIVA", descrizione: "ATTIVA" },
                { value: "CHIUSA", descrizione: "CHIUSA" },
                { value: "NUOVA", descrizione: "NUOVA" },
            ],
        };
    },
    methods: {
        loadDefaultData() {
            let me = this;
            me.loadData();
            me.loadSostanze();
            me.loadTipiReazioni();
            me.loadReazioni();
            me.loadGravitaReazioni();
        },
        loadData() {
            let me = this;
            if (me.id === "-1") {
                me.showModalLoading = false;
            } else {
                let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.id;
                me.showModalLoading = true;
                axios
                    .get(link)
                    .then((response) => {
                        me.jsonData = response.data.data;
                        me.showModalLoading = false;
                    })
                    .catch(() => {
                        me.showModalLoading = false;
                    });
            }
        },
        loadSostanze() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceSostanza;
            axios.get(link).then((response) => {
                me.sostanzeOptions = response.data.data;
                me.sostanzeOptions.unshift({ codiceSnomed: null, descrizione: "-Seleziona Sostanza-" });
            });
        },
        onInputSostanze(value) {
            let me = this;
            if (value) {
                me.sostanzeOptions.forEach((element) => {
                    if (element.codiceSnomed === value) {
                        me.jsonData.sostanza = element.descrizione;
                    }
                });
            }
        },
        loadTipiReazioni() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceTipoReazione;
            axios.get(link).then((response) => {
                response.data.data.forEach((element) => {
                    me.tipoReazioniOptions.push({ value: element.descrizione, descrizione: element.descrizione });
                });
            });
        },
        loadReazioni() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceReazione;
            axios.get(link).then((response) => {
                response.data.data.forEach((element) => {
                    me.reazioneOptions.push({ value: element.descrizione, descrizione: element.descrizione });
                });
            });
        },
        loadGravitaReazioni() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceGravitaReazione;
            axios.get(link).then((response) => {
                response.data.data.forEach((element) => {
                    me.gravitaReazioneOptions.push({ value: element.descrizioneGravitaReazione, descrizioneGravitaReazione: element.descrizioneGravitaReazione });
                });
            });
        },
    },
};
</script>
