<template>
    <div class="sa-form-section">
        <b-row>
            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                <span class="sa-form-label">Aspetto</span> <br />
                <b-form-input v-model="data.aspetto" no-resize placeholder="Aspetto" required></b-form-input>
            </b-col>
            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                <span class="sa-form-label">Colore</span><br />
                <b-form-input v-model="data.colore" no-resize placeholder="Colore"></b-form-input>
            </b-col>
        </b-row>
        <b-row>
            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                <span class="sa-form-label">Peso Specifico</span><br />
                <b-form-input v-model="data.pesoSpecifico" no-resize placeholder="Peso Specifico" type="number"></b-form-input>
            </b-col>
            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                <span class="sa-form-label">Reazione</span><br />
                <b-form-textarea v-model="data.reazione" no-resize placeholder="Reazione"></b-form-textarea>
            </b-col>
        </b-row>
        <b-row>
            <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                <span class="sa-form-label">Referto</span><br />
                <b-form-textarea v-model="data.referto" no-resize placeholder="Referto"></b-form-textarea>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                        <strong>Stato </strong><br />
                        {{ data.firma }}
                    </b-col>
                </b-row>
                <hr />
            </b-col>
        </b-row>
    </div>
</template>

<script>
export default {
    props: {
        data: {
            Type: Object,
            default: function() {
                return {
                    aspetto: "",
                    colore: "",
                    pesoSpecifico: null,
                    reazione: "",
                    referto: "",
                    firma: "",
                };
            },
        },
    },
};
</script>
