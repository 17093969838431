<template>
    <sa-page-layout :btnSaveVisible="true" :btnAnnullaVisible="true" :linkback="linkback" :pathResource="pathResource" :data="data" :showModalLoading="showModalLoading">
        <template slot="table-body">
            <div class="sa-tab-scroll">
                <div class="sa-form-section">
                    <h5 class="sa-text-left">{{ this.$i18n.t("patients.lblPatientRegistry") }}</h5>
                    <b-row>
                        <b-col cols="12" xs="12" sm="6" md="6" lg="4" xl="3">
                            <span class="sa-form-label">{{ this.$i18n.t("patients.lblLastname") }}</span>
                            <b-form-input v-model="data.cognome"></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="6" md="6" lg="4" xl="3">
                            <span class="sa-form-label">{{ this.$i18n.t("patients.lblName") }}</span>
                            <b-form-input v-model="data.nome"></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="6" md="6" lg="4" xl="3">
                            <span class="sa-form-label">{{ this.$i18n.t("patients.lblFiscalCode") }}</span>
                            <b-input-group class="mb-3">
                                <!-- <b-form-input maxlength="16" class="text-uppercase" v-model="data.codiceFiscale" @keypress="onKeyPress"></b-form-input> -->
                                <b-form-input maxlength="16" class="text-uppercase" v-model="data.identificativo"></b-form-input>
                                <!-- <b-button size="sm" variant="" @click="onClick"> <b-icon icon="search"></b-icon> </b-button> -->
                            </b-input-group>
                        </b-col>
                        <b-col cols="12" xs="12" sm="6" md="6" lg="4" xl="3">
                            <span class="sa-form-label">{{ this.$i18n.t("patients.lblBirthDate") }}</span>
                            <date-picker format="DD/MM/YYYY" value-type="timestamp" v-model="data.dataNascita" type="date"></date-picker>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="12" xs="12" sm="6" md="6" lg="4" xl="3">
                            <span class="sa-form-label">{{ this.$i18n.t("patients.lblProvince") }}</span>
                            <b-form-select id="inline-form-custom-select-pref" v-model="data.provinciaNascita" class="mb-2 mr-sm-2 mb-sm-0" :options="provinciaNascitaOption" :value="null" @change="loadComuneNascita(data.provinciaNascita)"></b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="6" md="6" lg="4" xl="3">
                            <span class="sa-form-label">{{ this.$i18n.t("patients.lblCity") }}</span>
                            <b-form-select id="inline-form-custom-select-pref" v-model="data.comuneNascitaCodiceIstat" class="mb-2 mr-sm-2 mb-sm-0" :options="comuneNascitaOption" :value="null"></b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="6" md="6" lg="4" xl="3">
                            <span class="sa-form-label">{{ this.$i18n.t("patients.lblGender") }}</span>
                            <b-form-select id="inline-form-custom-select-pref" v-model="data.sesso" class="mb-2 mr-sm-2 mb-sm-0" :options="sessoOption" :value="null"></b-form-select>
                        </b-col>
                    </b-row>
                </div>
                <div class="sa-form-section">
                    <h5 class="sa-text-left">{{ this.$i18n.t("patients.lblResidence") }}</h5>
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <span class="sa-form-label">{{ this.$i18n.t("patients.lblAddress") }}</span>
                            <b-form-input v-model="data.indirizzoResidenza"></b-form-input>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="12" xs="12" sm="6" md="6" lg="4" xl="4">
                            <span class="sa-form-label">{{ this.$i18n.t("patients.lblProvince") }}</span>
                            <b-form-select id="inline-form-custom-select-pref" v-model="data.provinciaResidenza" class="mb-2 mr-sm-2 mb-sm-0" :options="provinciaResidenzaOption" :value="null" @change="loadComuneResidenza(data.provinciaResidenza)"></b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="6" md="6" lg="4" xl="4">
                            <span class="sa-form-label">{{ this.$i18n.t("patients.lblCity") }}</span>
                            <b-form-select id="inline-form-custom-select-pref" v-model="data.comuneResidenzaCodiceIstat" class="mb-2 mr-sm-2 mb-sm-0" :options="comuneResidenzaOption" :value="null" value-field="codiceIstat" text-field="comune" @change="setCapResidenza"></b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="6" md="6" lg="4" xl="4">
                            <span class="sa-form-label">{{ this.$i18n.t("patients.lblPostalCode") }}</span>
                            <b-form-input v-model="data.capResidenza"></b-form-input>
                        </b-col>
                    </b-row>
                </div>
                <div class="sa-form-section">
                    <h6 class="sa-text-left">{{ this.$i18n.t("patients.lblDomicile") }}</h6>
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <span class="sa-form-label">{{ this.$i18n.t("patients.lblAddress") }}</span>
                            <b-form-input v-model="data.indirizzoDomicilio"></b-form-input>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="12" xs="12" sm="6" md="6" lg="4" xl="4">
                            <span class="sa-form-label">{{ this.$i18n.t("patients.lblProvince") }}</span>
                            <b-form-select id="inline-form-custom-select-pref" v-model="data.provinciaDomicilio" class="mb-2 mr-sm-2 mb-sm-0" :options="provinciaDomicilioOption" :value="null" @change="loadComuneDomicilio(data.provinciaDomicilio)"></b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="6" md="6" lg="4" xl="4">
                            <span class="sa-form-label">{{ this.$i18n.t("patients.lblCity") }}</span>
                            <b-form-select id="inline-form-custom-select-pref" v-model="data.comuneDomicilioCodiceIstat" class="mb-2 mr-sm-2 mb-sm-0" :options="comuneDomicilioOption" :value="null" value-field="codiceIstat" text-field="comune" @change="setCapDomicilio"></b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="6" md="6" lg="4" xl="4">
                            <span class="sa-form-label">{{ this.$i18n.t("patients.lblPostalCode") }}</span>
                            <b-form-input v-model="data.capDomicilio"></b-form-input>
                        </b-col>
                    </b-row>
                </div>
                <div class="sa-form-section">
                    <h5 class="sa-text-left">{{ this.$i18n.t("patients.lblContacts") }}</h5>
                    <b-row>
                        <b-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                            <span class="sa-form-label">{{ this.$i18n.t("patients.lblTelephoneNumber") }}</span>
                            <b-form-input v-model="data.telefono"></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                            <span class="sa-form-label">{{ this.$i18n.t("patients.lblEmail") }}</span>
                            <b-form-input v-model="data.email"></b-form-input>
                        </b-col>
                    </b-row>
                </div>
                <!-- <div class="sa-form-section">
                    <h5 class="sa-text-left">{{ this.$i18n.t("patients.lblDoctor") }}</h5>
                    <b-row>
                        <b-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                            <span class="sa-form-label">{{ this.$i18n.t("patients.lblDoctor") }}</span>
                            <b-form-input v-model="data.medicoCurante"></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                            <span class="sa-form-label">{{ this.$i18n.t("patients.lblFiscalCode") }}</span>
                            <b-form-input v-model="data.codiceFiscaleMedicoCurante" class="text-uppercase"></b-form-input>
                        </b-col>
                    </b-row>
                </div> -->
                <div class="sa-form-section">
                    <h5 class="sa-text-left">{{ this.$i18n.t("patients.lblNote") }}</h5>
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <!-- <span class="sa-form-label">{{ this.$i18n.t("patients.lblNote") }}</span> -->
                            <b-form-textarea v-model="data.note" no-resize></b-form-textarea>
                        </b-col>
                    </b-row>
                </div>
            </div>
        </template>
    </sa-page-layout>
</template>
<script>
import axios from "axios";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import moment from "moment";
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
export default {
    components: { DatePicker, SaPageLayout },
    data() {
        return {
            pathResource: "/pazienti",
            linkback: null,
            linkProvince: "/province",
            linkComune: "/comuni/provincia",
            linkComuneByIstat: "/comuni/istat",
            showModalLoading: false,
            id: "-1",
            profili: [],
            sessoOption: [
                { value: null, text: "Sesso" },
                { value: "M", text: "Maschio" },
                { value: "F", text: "Femmina" },
            ],
            provinciaNascitaOption: [{ value: null, text: "- Seleziona Provincia -" }],
            provinciaDomicilioOption: [{ value: null, text: "- Seleziona Provincia -" }],
            provinciaResidenzaOption: [{ value: null, text: "- Seleziona Provincia -" }],
            comuneNascitaOption: [{ value: null, text: "- Seleziona Comune -" }],
            comuneDomicilioOption: [{ codiceIstat: null, comune: "- Seleziona Comune -" }],
            comuneResidenzaOption: [{ codiceIstat: null, comune: "- Seleziona Comune -" }],
            data: { cognome: "", nome: "", identificativo: "", indirizzoResidenza: "", indirizzoDomicilio: "", note: "", sesso: null, comuneDomicilioCodiceIstat: null, provinciaDomicilio: null, comuneNascitaCodiceIstat: null, provinciaNascita: null, comuneResidenzaCodiceIstat: null, provinciaResidenza: null, capResidenza: null, capDomicilio: null },
        };
    },
    computed: {
        dataScadenzaUtenza: {
            get: function() {
                return new Date(this.data.scadenzaUtenza);
            },
            set: function(value) {
                this.data.scadenzaUtenza = moment(String(value)).valueOf();
            },
        },
    },
    mounted: function() {
        let me = this;
        me.id = this.$route.params.id;
        if (me.id === "-1") {
            me.linkback = "/pazienti/";
        } else {
            me.linkback = "/paziente/view/" + me.id;
        }

        me.loadDefaultData();
    },
    methods: {
        loadDefaultData() {
            let me = this;
            me.loadProvinceNascita();
            me.loadProvinceDomicilio();
            me.loadProvinceResidenza();
            me.loadData();
        },
        loadData() {
            let me = this;

            //console.log(me.id);
            if (me.id === "-1") {
                me.showModalLoading = false;
            } else {
                me.showModalLoading = true;
                let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
                axios.get(link + me.id).then((response) => {
                    me.data = response.data.data;
                    me.loadComuneNascita(me.data.provinciaNascita);
                    me.loadComuneResidenza(me.data.provinciaResidenza);
                    me.loadComuneDomicilio(me.data.provinciaDomicilio);
                    me.showModalLoading = false;
                });
            }
        },
        loadProvinceNascita() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkProvince;
            axios.get(link).then((response) => {
                response.data.data.forEach((element) => {
                    me.provinciaNascitaOption.push({ value: element.sigla, text: element.provincia });
                });
            });
        },
        loadProvinceDomicilio() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkProvince;
            axios.get(link).then((response) => {
                response.data.data.forEach((element) => {
                    me.provinciaDomicilioOption.push({ value: element.sigla, text: element.provincia });
                });
            });
        },
        loadProvinceResidenza() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkProvince;
            axios.get(link).then((response) => {
                response.data.data.forEach((element) => {
                    me.provinciaResidenzaOption.push({ value: element.sigla, text: element.provincia });
                });
            });
        },
        loadComuneNascita(sigla) {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkComune + "/" + sigla;
            axios.get(link).then((response) => {
                me.comuneNascitaOption = [];
                response.data.data.forEach((element) => {
                    me.comuneNascitaOption.push({ value: element.codiceIstat, text: element.comune });
                });
            });
        },
        loadComuneDomicilio(sigla) {
            // let me = this;
            // let link = process.env.VUE_APP_PATH_API + me.linkComune + "/" + sigla;
            // axios.get(link).then((response) => {
            //     me.comuneDomicilioOption = response.data.data;
            //     me.comuneDomicilioOption.unshift({ codiceIstat: null, comune: "- Seleziona Comune -" });

            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkComune + "/" + sigla;
            axios.get(link).then((response) => {
                me.comuneDomicilioOption = response.data.data;
                me.comuneDomicilioOption.unshift({ codiceIstat: null, comune: "- Seleziona Comune -" });
            });
        },
        setCapDomicilio(item) {
            let me = this;
            me.comuneDomicilioOption.forEach((comuneDomicilio) => {
                if (comuneDomicilio.codiceIstat === item) {
                    me.data.capDomicilio = comuneDomicilio.cap;
                }
            });
        },
        loadComuneResidenza(sigla) {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkComune + "/" + sigla;
            axios.get(link).then((response) => {
                me.comuneResidenzaOption = response.data.data;
                me.comuneResidenzaOption.unshift({ codiceIstat: null, comune: "- Seleziona Comune -" });
            });
        },
        setCapResidenza(item) {
            let me = this;
            me.comuneResidenzaOption.forEach((comuneResidenza) => {
                if (comuneResidenza.codiceIstat === item) {
                    me.data.capResidenza = comuneResidenza.cap;
                }
            });
        },
        // onKeyPress(e) {
        //     let me = this;
        //     if (e.keyCode === 13) {
        //         me.onClick();
        //     }
        // },

        // onClick() {
        //     let me = this;
        //     me.showModalLoading = true;
        //     if (me.data.codiceFiscale.trim().length === 0) {
        //         me.showModalLoading = false;
        //     } else {
        //         me.data.codiceFiscale = me.data.codiceFiscale.toUpperCase();
        //         let link = process.env.VUE_APP_PATH_API + "/utility";
        //         axios
        //             .get(link, { params: { codiceFiscale: me.data.codiceFiscale } })
        //             .then((response) => {
        //                 me.data.nome = response.data.data.nome;
        //                 me.data.cognome = response.data.data.cognome;
        //                 me.data.dataNascita = moment(response.data.data.dataNascita, "DD/MM/YYYY")
        //                     .toDate()
        //                     .getTime();
        //                 me.data.provinciaNascita = response.data.data.provinciaNascita;
        //                 me.data.comuneNascitaCodiceIstat = response.data.data.codiceIstatComuneNascita;
        //                 me.data.sesso = response.data.data.sesso;
        //                 me.data.indirizzoResidenza = response.data.data.indirizzoResidenza;
        //                 me.data.provinciaResidenza = response.data.data.provinciaResidenza;
        //                 me.data.comuneResidenzaCodiceIstat = response.data.data.codiceIstatComuneResidenza;
        //                 me.setCapResidenza(response.data.data.codiceIstatComuneResidenza);
        //                 me.data.indirizzoDomicilio = response.data.data.indirizzoResidenza;
        //                 me.data.provinciaDomicilio = response.data.data.provinciaResidenza;
        //                 me.data.comuneDomicilioCodiceIstat = response.data.data.codiceIstatComuneResidenza;
        //                 me.setCapDomicilio(response.data.data.codiceIstatComuneResidenza);
        //                 me.data.codiceFiscaleMedicoCurante = response.data.data.codiceFiscaleMedico;
        //                 me.data.medicoCurante = response.data.data.medico;
        //                 me.showModalLoading = false;
        //             })
        //             .catch(() => {
        //                 me.showModalLoading = false;
        //             });
        //     }
        // },
    },
};
</script>
