<template>
  <b-card class="mb-4">
    <h4 class="header-title mt-0 mb-3"><i class="mdi mdi-notification-clear-all mr-1"></i> {{ this.$i18n.t("patients.lblDiary") }}</h4>
    <ul class="timeline2" v-for="item in diario" :key="item.id">
      <li class="event" data-date="25 Maggio 2021 | 10:30">
        <span class="mdi mdi-circle pal"></span>
        <span class="sa-timeline-date">{{ formatDateTime(item.dataEvento) }}</span>
        <h3>
          <a href="#" onclick="return false;" @click="onTipoVisitaClick(item)">{{ getTipoVisita(item.tipoEvento) }}</a>
        </h3>
        <span>{{ item.lastnameUpdater }} {{ item.firstnameUpdater }}</span>
      </li>
    </ul>
    <b-modal ref="mdlEvento" size="xl" :title="mdlTitle">
      <div style="padding: 15px;">
        <component ref="itemRefs" :is="currentTabComponent" v-bind="currentProperties"></component>
      </div>
    </b-modal>
  </b-card>
</template>

<script>
import axios from "axios";
import moment from "moment";
export default {
  data() {
    return {
      mdlTitle: "",
      currentProperties: { idEvento: "gg" },
      currentTabComponent: null,
      diario: [],
    };
  },
  mounted() {
    let me = this;
    me.id = this.$route.params.id;
    me.loadDiario();
  },
  methods: {
    getTipoVisita(value) {
      let key = "patients.events." + value;
      let returnValue = this.$i18n.t(key);
      return returnValue;
    },
    loadDiario() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + "/pazienteeventi" + "/";
      axios
        .get(link, { params: { idPaziente: me.id } })
        .then((response) => {
          me.diario = response.data.data;
        })
        .catch((e) => {
          console.log("Error: " + e.response.status + " " + link);
        });
    },
    formatDateTime(value) {
      if (value) {
        return moment(new Date(value)).format("DD-MM-YYYY HH:mm");
      } else {
        return "---";
      }
    },
    onTipoVisitaClick(item) {
      let me = this;
      me.currentTabComponent = null;
      me.currentProperties = { idEvento: item.id };
      switch (item.tipoEvento) {
        case "medicinadellosportafa":
          me.mdlTitle = me.getTipoVisita(item.tipoEvento);
          me.currentTabComponent = () => import("../eventi/medicinaDelloSport/components/AfaViewComponent.vue");
          break;
        case "diabetologiaprimavisita":
          me.mdlTitle = me.getTipoVisita(item.tipoEvento);
          me.currentTabComponent = () => import("../eventi/diabetologia/components/PrimaVisitaViewComponent.vue");
          break;
        case "diabetologiaanamnesifamiliare":
          me.mdlTitle = me.getTipoVisita(item.tipoEvento);
          me.currentTabComponent = () => import("../eventi/diabetologia/components/AnamnesiFamiliareViewComponent.vue");
          break;
        case "hyperhealthvisitahh":
          me.mdlTitle = me.getTipoVisita(item.tipoEvento);
          me.currentTabComponent = () => import("../eventi/hyperhealth/components/VisitaHhViewComponent.vue");
          break;
      }

      // me.currentTabComponent = () => import("../eventi/diabetologia/components/PrimaVisitaView.vue");
      me.$refs["mdlEvento"].show();
    },
  },
};
</script>
