<template>
    <sa-page-layout :showModalLoading="showModalLoading" :btnBackVisible="true" :btnEditVisible="true" :linkedit="linkedit" :linkback="linkback" :pathResource="pathResource" :data="jsonData">
        <template slot="toolbar-title">
            <paziente-anagrafica :linkPatient="linkPatient" :idPaziente="idPaziente"></paziente-anagrafica>
        </template>
        <template slot="table-body">
            <div class="sa-tab-scroll">
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                        <b-row>
                            <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                                <strong>{{ this.$i18n.t("patients.lblRemotePathologicalAnamnesis") }}</strong>
                                <br />
                                <span>{{ jsonData.anamnesiPatologicaRemota }}</span>
                            </b-col>
                        </b-row>
                        <hr />
                        <b-row>
                            <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                                <strong>{{ this.$i18n.t("patients.lblNextPathologicalAnamnesis") }}</strong>
                                <br />
                                <span>{{ jsonData.anamnesiPatologicaProssima }}</span>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </div>
        </template>
    </sa-page-layout>
</template>
<script>
import axios from "axios";
import SaPageLayout from "../../../../../template/sa/components/layout/contents/SaPageLayout.vue";
import PazienteAnagrafica from "../../../components/PazienteAnagrafica.vue";
export default {
    components: { SaPageLayout, PazienteAnagrafica },
    props: {
        utente: {
            type: Object,
            default: function() {
                return null;
            },
        },
    },
    computed: {
        idPaziente() {
            return this.$route.query.idPaziente;
        },
        linkPatient: {
            get: function() {
                let returnValue = "";
                if (this.utente.gruppo) {
                    if (this.utente.gruppo.menuGruppo) {
                        if (this.utente.gruppo.menuGruppo.length > 1) {
                            return "/paziente/view/" + this.idPaziente;
                        } else {
                            if (this.utente.gruppo.menuGruppo.length == 1) {
                                if (this.utente.gruppo.menuGruppo[0].primaryKey === "pazienti") {
                                    return "/paziente/view/" + this.idPaziente;
                                } else {
                                    return "/paziente/homepaziente";
                                }
                            }
                        }
                    }
                }
                return returnValue;
            },
        },
    },
    mounted() {
        let me = this;
        me.jsonData.idPaziente = this.$route.query.idPaziente;
        me.jsonData.tipoEvento = "Gastroenterologia - Anamnesi Patologica";
        me.id = this.$route.params.id;
        me.linkedit = "/paziente/gastroenterologia/anamnesipatologica/edit/" + me.id + "?idPaziente=" + me.idPaziente;
        me.linkback = "/paziente/gastroenterologia/anamnesipatologica?idPaziente=" + me.idPaziente;
        me.loadData();
    },

    data() {
        return {
            linkback: null,
            pathResource: "/anamnesipatologica",
            linkedit: null,
            jsonData: {},
            showModalLoading: false,
        };
    },

    methods: {
        loadData() {
            let me = this;
            if (me.id === "-1") {
                me.showModalLoading = false;
            } else {
                let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.id;
                axios.get(link).then((response) => {
                    me.jsonData = response.data.data;
                    me.showModalLoading = false;
                });
            }
        },
    },
};
</script>
