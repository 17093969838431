import EsameObiettivoView from "./components/EsameObiettivoView.vue";
import AnamnesiView from "./components/AnamnesiView.vue";
import AtletiList from "./components/AtletiList.vue";
import WorkList from "./components/WorkList.vue";
import AtletaEdit from "./components/AtletaEdit.vue";
import PendingList from "./components/PendingList.vue";
import VisitaMedicoSportivaView from "./components/VisitaMedicoSportivaView.vue";
import VisitaMedicoSportivaEdit from "./components/VisitaMedicoSportivaEdit.vue";
import VisiteList from "./components/VisiteList.vue";

export default {
  EsameObiettivoView,
  AnamnesiView,
  AtletiList,
  WorkList,
  AtletaEdit,
  PendingList,
  VisitaMedicoSportivaView,
  VisitaMedicoSportivaEdit,
  VisiteList,
};
