<template>
  <sa-page-layout :showmolalloading="showmolalloading">
    <template slot="toolbar-title">
      <paziente-anagrafica :linkPatient="linkPatient" :idPaziente="idPaziente"></paziente-anagrafica>
    </template>
    <template slot="toolbar-row">
      <paziente-dashboard-component :idPaziente="idPaziente"></paziente-dashboard-component>
    </template>
    <template slot="table-header">
      <h3>{{ this.$i18n.t("patient.lifeStyle.lblData") }}</h3>
    </template>
    <template slot="table-body">
      <b-card class="scroll">
        <b-row
          ><b-col>
            <h4>{{ this.$i18n.t("patient.lifeStyle.lblHabits") }}</h4>
            <b-form-group v-if="rispostaQuestionarioAbitudini.length !== 0"
              ><p>
                <b>{{ this.$i18n.t("patient.lifeStyle.lblHabitsLastAnswer") }}:</b>
              </p>
              <b-row class="text-left">
                <b-col v-for="item in rispostaQuestionarioAbitudini" :key="item.id"
                  ><span v-if="item.measurename === 'Caffe'"><b>Caffé</b>: {{ item.value }}</span>
                  <span v-if="item.measurename === 'Sigarette'"><b>Sigarette</b>: {{ item.value }}</span>
                  <span v-if="item.measurename === 'Alcool'"><b>Alcool</b>: {{ item.value }}</span></b-col
                >
              </b-row>
            </b-form-group>
            <p v-if="rispostaQuestionarioAbitudini.length === 0">Nessuna risposta riscontrata</p>
            <h4>{{ this.$i18n.t("patient.lifeStyle.lblActivities") }}</h4>
            <b-form-group v-if="rispostaQuestionarioAbitudini.length !== 0"
              ><p>
                <b>{{ this.$i18n.t("patient.lifeStyle.lblActivitiesLastAnswer") }}:</b>
              </p>
              <b-row class="text-left">
                <b-col v-for="item in rispostaQuestionarioAttivita" :key="item.id"
                  ><span v-if="item.measurename === 'number_of_activity'"><b>Numero Attività</b>: {{ item.value }}</span>
                  <span v-if="item.measurename === 'minute_of_activity'"><b>Minuti Attività</b>: {{ item.value }}</span>
                </b-col>
              </b-row>
            </b-form-group>
            <p v-if="rispostaQuestionarioAbitudini.length === 0">Nessuna risposta riscontrata</p> </b-col
          ><b-col
            ><b-card
              ><b-card-body
                ><h4>
                  {{ this.$i18n.t("patient.lifeStyle.lblActivityTrend") }}
                </h4>
                <b-form-group
                  ><b-row
                    ><b-col><b-form-select :key="componentKey" v-model="meseSelezionePassi" :options="opzioniSelezione"></b-form-select></b-col
                    ><b-col> <b-form-input type="number" min="1900" max="2100" v-model="annoSelezione"></b-form-input></b-col><b-col></b-col
                    ><b-col>
                      <b-button class="secondary" @click="misureGraficoPassi(meseSelezionePassi, annoSelezione, idPaziente)">{{ this.$i18n.t("patient.lifeStyle.lblLoad") }}</b-button>
                    </b-col></b-row
                  >
                  <line-chart :chart-data="chartAndamentoPassi" :height="75" :width="250"></line-chart></b-form-group></b-card-body
            ></b-card> </b-col
        ></b-row>
        <b-card-body
          ><h4>{{ this.$i18n.t("patient.lifeStyle.lblSleep") }}</h4>
          <b-form-group
            ><b-row
              ><b-col cols="12"
                ><b-row>
                  <b-col cols="4"><b-form-select :key="componentKey" v-model="meseSelezioneSonno" :options="opzioniSelezione"></b-form-select></b-col
                  ><b-col> <b-form-input type="number" min="1900" max="2100" v-model="annoSelezione"></b-form-input></b-col><b-col></b-col
                  ><b-col cols="3">
                    <b-button class="secondary" @click="misureGraficoSleep(meseSelezioneSonno, annoSelezione, idPaziente)">{{ this.$i18n.t("patient.lifeStyle.lblLoad") }}</b-button>
                  </b-col></b-row
                ><bar-chart :chart-data="chartAndamentoSleepScore" :height="50" :width="250" :options="options"></bar-chart>
                <p style="text-align: center">
                  {{ this.$i18n.t("patient.lifeStyle.lblNB") }}
                </p>
              </b-col></b-row
            ><b-row
              ><b-col cols="6">
                <bar-chart style="height: 20rem;" :chart-data="chartAndamentoSleepTotalTime" :options="optionsOreSonno"></bar-chart>
              </b-col>
              <b-col cols="6">
                <bar-chart style="height: 20rem;" :chart-data="chartAndamentoSleepOutOfBed" :options="optionsTotalSleep"></bar-chart>
              </b-col>
            </b-row> </b-form-group
        ></b-card-body>
      </b-card>
    </template>
  </sa-page-layout>
</template>
<script>
import axios from "axios";
import moment from "moment";
import PazienteAnagrafica from "./PazienteAnagrafica.vue";
import LineChart from "./LineChart.js";
import BarChart from "./BarChart.js";
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
import PazienteDashboardComponent from "./PazienteDashboardComponent.vue";

export default {
  props: {
    utente: {
      type: Object,
      default: function() {
        return null;
      },
    },
  },
  components: { PazienteAnagrafica, LineChart, BarChart, SaPageLayout, PazienteDashboardComponent },
  computed: {
    idPaziente() {
      return this.$route.query.idPaziente;
    },
    rows() {
      return this.items.length;
    },
    linkPatient: {
      get: function() {
        let returnValue = "";
        if (this.utente.gruppo) {
          if (this.utente.gruppo.menuGruppo) {
            if (this.utente.gruppo.menuGruppo.length > 1) {
              return "/paziente/view/" + this.idPaziente;
            } else {
              if (this.utente.gruppo.menuGruppo.length == 1) {
                if (this.utente.gruppo.menuGruppo[0].primaryKey === "pazienti") {
                  return "/paziente/view/" + this.idPaziente;
                } else {
                  return "/paziente/homepaziente";
                }
              }
            }
          }
        }
        return returnValue;
      },
    },
  },
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      filter: null,
      id: "-1",
      pathRest: "/lifestyle",
      pagelink: "/paziente/lifestyle",
      filtro: {},
      filterOn: [],
      showmolalloading: false,
      chartAndamentoPassi: {},
      chartAndamentoSleepScore: {},
      chartAndamentoSleepTotalTime: {},
      chartAndamentoSleepEfficiency: {},
      chartAndamentoSleepOutOfBed: {},
      meseSelezionePassi: null,
      meseSelezioneSonno: null,
      rispostaQuestionarioAttivita: [],
      rispostaQuestionarioAbitudini: [],
      opzioniSelezione: [
        { text: "GENNAIO", value: 1 },
        { text: "FEBBRAIO", value: 2 },
        { text: "MARZO", value: 3 },
        { text: "APRILE", value: 4 },
        { text: "MAGGIO", value: 5 },
        { text: "GIUGNO", value: 6 },
        { text: "LUGLIO", value: 7 },
        { text: "AGOSTO", value: 8 },
        { text: "SETTEMBRE", value: 9 },
        { text: "OTTOBRE", value: 10 },
        { text: "NOVEMBRE", value: 11 },
        { text: "DICEMBRE", value: 12 },
      ],
      annoSelezione: null,
      options: {
        scales: {
          yAxes: [
            {
              display: true,
              ticks: {
                beginAtZero: true,
                steps: 10,
                stepValue: 10,
                max: 100,
              },
            },
          ],
        },
      },
      optionsOreSonno: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              ticks: {
                userCallback: function(v) {
                  return new Date(v * 1000).toISOString().substr(12, 4);
                },
                stepSize: 3600,
              },
            },
          ],
        },
        tooltips: {
          callbacks: {
            label: function(tooltipItem, data) {
              return data.datasets[tooltipItem.datasetIndex].label + ": " + new Date(tooltipItem.yLabel * 1000).toISOString().substr(12, 7);
            },
          },
        },
      },
      optionsTotalSleep: {
        scales: {
          yAxes: [
            {
              display: true,
              ticks: {
                beginAtZero: true,
                steps: 1,
                stepValue: 1,
                max: 10,
              },
            },
          ],
        },
        responsive: true,
        maintainAspectRatio: false,
      },
      componentKey: 0,
    };
  },
  created: function() {},
  async mounted() {
    let me = this;
    me.meseSelezionePassi = new Date(Date.now()).getMonth() + 1;
    me.meseSelezioneSonno = me.meseSelezionePassi;
    me.annoSelezione = new Date(Date.now()).getFullYear();
    await me.loadData();
  },
  methods: {
    loadData() {
      let me = this;
      me.opzioniSelezione.forEach((mese) => {
        let value = mese.value;
        switch (value) {
          case 1:
            mese.text = this.$i18n.t("patient.lifeStyle.lblJanuary");
            break;
          case 2:
            mese.text = this.$i18n.t("patient.lifeStyle.lblFebruary");
            break;
          case 3:
            mese.text = this.$i18n.t("patient.lifeStyle.lblMarch");
            break;
          case 4:
            mese.text = this.$i18n.t("patient.lifeStyle.lblApril");
            break;
          case 5:
            mese.text = this.$i18n.t("patient.lifeStyle.lblMay");
            break;
          case 6:
            mese.text = this.$i18n.t("patient.lifeStyle.lblJune");
            break;
          case 7:
            mese.text = this.$i18n.t("patient.lifeStyle.lblJuly");
            break;
          case 8:
            mese.text = this.$i18n.t("patient.lifeStyle.lblAugust");
            break;
          case 9:
            mese.text = this.$i18n.t("patient.lifeStyle.lblSeptember");
            break;
          case 10:
            mese.text = this.$i18n.t("patient.lifeStyle.lblOctober");
            break;
          case 11:
            mese.text = this.$i18n.t("patient.lifeStyle.lblNovember");
            break;
          case 12:
            mese.text = this.$i18n.t("patient.lifeStyle.lblDecember");
            break;
          default:
            break;
        }
      });
      me.misureGraficoPassi(me.meseSelezionePassi, me.annoSelezione, me.idPaziente);
      me.misureGraficoSleepScore(me.meseSelezioneSonno, me.annoSelezione, me.idPaziente);
      me.misureGraficoSleepTotal(me.meseSelezioneSonno, me.annoSelezione, me.idPaziente);
      me.misureGraficoSleepEfficiency(me.meseSelezioneSonno, me.annoSelezione, me.idPaziente);
      me.misureGraficoSleepOutOfBed(me.meseSelezioneSonno, me.annoSelezione, me.idPaziente);
      me.risposteAbitudini(me.idPaziente);
    },
    misureGraficoPassi(mese, anno, idPaziente) {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + "/hyperhealth/?idpaziente=" + idPaziente + "&dal=" + anno + "-" + mese + "-01 00:00:00&al=" + anno + "-" + mese + "-31 23:59:59&idMisura=steps";
      axios.get(link).then((res) => {
        let datePassi = [];
        let misurePassi = [];
        let dati = res.data.data.reverse();

        for (let i = dati.length - 1; i >= 0; i--) {
          datePassi.push(moment(dati[i].istant).format("DD/MM"));
          misurePassi.push(dati[i].value);
        }

        me.chartAndamentoPassi = {
          labels: datePassi,
          datasets: [
            {
              label: this.$i18n.t("patient.lifeStyle.lblSteps"),
              data: misurePassi,
              lineTension: 0,
              backgroundColor: "rgba(255, 100, 100, 0.2)",
              pointBorderColor: "rgba(255, 100, 100, 1)",
              bgColor: "'rgba(100, 0, 0, 0)'",
              dotColor: "'rgba(100, 10, 255, 1.8)'",
              borderColor: "'rgba(100, 10, 255, 1)'",
            },
          ],
        };
      });
    },
    misureGraficoSleep(m, y, id) {
      let me = this;
      me.misureGraficoSleepScore(m, y, id);
      me.misureGraficoSleepTotal(m, y, id);
      me.misureGraficoSleepEfficiency(m, y, id);
      me.misureGraficoSleepOutOfBed(m, y, id);
    },
    misureGraficoSleepScore(mese, anno, idPaziente) {
      // s>70 = yellow --- s>40 red
      let me = this;
      let link = process.env.VUE_APP_PATH_API + "/hyperhealth/?idpaziente=" + idPaziente + "&dal=" + anno + "-" + mese + "-01 00:00:00&al=" + anno + "-" + mese + "-31 23:59:59&idMisura=sleep_score";
      axios.get(link).then((res) => {
        let dateScore = [];
        let misureScore = [];
        let dati = res.data.data.reverse();
        let colors = [];
        //console.log(dati);

        //for (let i = dati.length - 1; i >= 0; i--) {
        for (let i = dati.length - 1; i >= 0; i--) {
          dateScore.push(moment(dati[i].istant).format("DD/MM"));
          let misure = dati[i].value;
          let color;

          if (misure >= 60 && misure < 70) {
            color = "rgba(252, 244, 8, 0.6)";
            //color = "rgba(0,0,0)";
          } else if (misure >= 0 && misure < 60) {
            color = "rgba(255, 0, 0, 0.6)";
          } else {
            color = "rgba(0, 181, 33, 0.6)";
          }
          colors[dati.length - i - 1] = color;
          misureScore.push(misure);
        }

        me.chartAndamentoSleepScore = {
          labels: dateScore,
          datasets: [
            {
              label: this.$i18n.t("patient.lifeStyle.lblSleepScore"),
              data: misureScore,
              lineTension: 0,
              backgroundColor: colors,
            },
          ],
        };
      });
    },
    misureGraficoSleepTotal(mese, anno, idPaziente) {
      // s>7 = yellow --- s>4 = red
      let me = this;
      let link = process.env.VUE_APP_PATH_API + "/hyperhealth/?idpaziente=" + idPaziente + "&dal=" + anno + "-" + mese + "-01 00:00:00&al=" + anno + "-" + mese + "-31 23:59:59&idMisura=total_sleep_time";
      axios.get(link).then((res) => {
        let dateSleepTime = [];
        let misureSleepTime = [];
        let dati = res.data.data.reverse();
        let colors = [];

        for (let i = dati.length - 1; i >= 0; i--) {
          dateSleepTime.push(moment(dati[i].istant).format("DD/MM"));
          let misure = dati[i].value;
          let color;
          if (misure >= 21600 && misure < 25200) {
            color = "rgba(252, 244, 8, 0.6)";
          } else if (misure >= 0 && misure < 21600) {
            color = "rgba(255, 0, 0, 0.6)";
          } else {
            color = "rgba(0, 181, 33, 0.6)";
          }
          colors[dati.length - i - 1] = color;

          misureSleepTime.push(misure);
        }

        me.chartAndamentoSleepTotalTime = {
          labels: dateSleepTime,
          datasets: [
            {
              label: this.$i18n.t("patient.lifeStyle.lbltotalSleepTime"),
              data: misureSleepTime,
              lineTension: 0,
              backgroundColor: colors,
            },
          ],
        };
      });
    },
    misureGraficoSleepEfficiency(mese, anno, idPaziente) {
      // s>70 = yellow ---- s>40 = red
      let me = this;
      let link = process.env.VUE_APP_PATH_API + "/hyperhealth/?idpaziente=" + idPaziente + "&dal=" + anno + "-" + mese + "-01 00:00:00&al=" + anno + "-" + mese + "-31 23:59:59&idMisura=sleep_efficiency";
      axios.get(link).then((res) => {
        let dateSleepEfficency = [];
        let misureSleepEfficency = [];
        let dati = res.data.data.reverse();
        let colors = [];

        for (let i = dati.length - 1; i >= 0; i--) {
          dateSleepEfficency.push(moment(dati[i].istant).format("DD/MM"));
          let misure = dati[i].value * 100;
          let color;
          if (misure >= 60 && misure < 70) {
            color = "rgba(252, 244, 8, 0.6)";
          } else if (misure >= 0 && misure < 60) {
            color = "rgba(255, 0, 0, 0.6)";
          } else {
            color = "rgba(0, 181, 33, 0.6)";
          }
          colors[dati.length - i - 1] = color;
          misureSleepEfficency.push(misure);
        }

        me.chartAndamentoSleepEfficiency = {
          labels: dateSleepEfficency,
          datasets: [
            {
              label: this.$i18n.t("patient.lifeStyle.lblSleepEfficiency"),
              data: misureSleepEfficency,
              lineTension: 0,
              backgroundColor: colors,
            },
          ],
        };
      });
    },
    misureGraficoSleepOutOfBed(mese, anno, idPaziente) {
      let me = this;
      //no alert
      let link = process.env.VUE_APP_PATH_API + "/hyperhealth/?idpaziente=" + idPaziente + "&dal=" + anno + "-" + mese + "-01 00:00:00&al=" + anno + "-" + mese + "-31 23:59:59&idMisura=wakeupcount";
      axios.get(link).then((res) => {
        let dateBedCount = [];
        let misureBedCount = [];
        let dati = res.data.data.reverse();
        let colors = [];

        for (let i = dati.length - 1; i >= 0; i--) {
          dateBedCount.push(moment(dati[i].istant).format("DD/MM"));
          let misure = dati[i].value;
          let color;
          if (misure >= 2 && misure < 4) {
            color = "rgba(252, 244, 8, 0.6)";
          } else if (misure >= 4) {
            color = "rgba(255, 0, 0, 0.6)";
          } else {
            color = "rgba(0, 181, 33, 0.6)";
          }
          colors[dati.length - i - 1] = color;
          misureBedCount.push(misure);
        }

        me.chartAndamentoSleepOutOfBed = {
          labels: dateBedCount,
          datasets: [
            {
              label: this.$i18n.t("patient.lifeStyle.lblOutOfBedTime"),
              data: misureBedCount,
              lineTension: 0,
              backgroundColor: colors,
            },
          ],
        };
      });
    },
    risposteAbitudini(idPaziente) {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + "/hyperhealth/lastmeasure?idPaziente=" + idPaziente;
      axios
        .get(link)
        .then((res) => {
          let misure = res.data.data;
          misure.forEach((element) => {
            let abitudini = ["Caffe", "Alcool", "Sigarette"];
            let attivita = ["number_of_activity", "minute_of_activity"];
            if (abitudini.includes(element.measurename)) {
              me.rispostaQuestionarioAbitudini.push(element);
            }
            if (attivita.includes(element.measurename)) {
              me.rispostaQuestionarioAttivita.push(element);
            }
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onSubmit() {
      let me = this;

      me.loadData();
    },
    onReset() {
      let me = this;
      me.filtro = {};
      let sessionStorage = window.sessionStorage;
      sessionStorage["filtro"] = JSON.stringify(me.filtro);
      me.loadData();
    },
    onAdd() {
      let me = this;
      me.$router.replace(me.pagelink + "/edit/-1?idPaziente=" + me.idPaziente).catch((err) => {
        err;
      });
    },
    onRefresh() {
      let me = this;
      me.loadData();
    },
    onAvviaTeleconsulto(item) {
      let me = this;
      me.$router.replace("/paziente/lifestyle/" + item.id + "?idPaziente=" + this.idPaziente).catch((err) => {
        err;
      });
    },
  },
};
</script>

<style scoped>
.overflowAuto {
  overflow-x: hidden;
  overflow-y: auto;
  height: calc(100vh - 163px);
}
</style>
