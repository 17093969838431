<template>
    <div>
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <b-row>
                    <b-col xs="6" sm="6" md="6" lg="6" xl="6">
                        <strong>Aspetto</strong><br />
                        {{ data.aspetto }}
                    </b-col>
                    <b-col xs="6" sm="6" md="6" lg="6" xl="6">
                        <strong>Colore</strong><br />
                        {{ data.colore }}
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <hr />
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <b-row>
                    <b-col xs="6" sm="6" md="6" lg="6" xl="6">
                        <strong>Peso Specifico </strong><br />
                        {{ data.pesoSpecifico }}
                    </b-col>
                    <b-col xs="6" sm="6" md="6" lg="6" xl="6">
                        <strong> Reazione </strong><br />
                        {{ data.reazione }}
                    </b-col>
                </b-row>
                <hr />
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                        <strong>Referto </strong><br />
                        {{ data.referto }}
                    </b-col>
                </b-row>
                <hr />
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                        <b-row>
                            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                <strong>Stato </strong><br />
                                {{ data.firma }}
                            </b-col>
                        </b-row>
                        <hr />
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
    </div>
</template>
<script>
export default {
    props: {
        data: {
            Type: Object,
            default: function() {
                return {
                    aspetto: "ddd",
                    colore: "",
                    pesoSpecifico: null,
                    firma: "",
                    reazione: "",
                    referto: "",
                };
            },
        },
    },
    data() {
        return {};
    },
};
</script>
