<template>
  <div>
    <b-row>
      <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
        <b-row>
          <b-col xs="12" sm="6" md="6" lg="6" xl="6">
            <span class="sa-form-label">{{ this.$i18n.t("patient.idoneity.lblCV") }}</span>
            <b-form-input type="number" v-model="data.cv" step="0.01"></b-form-input><br />
            <span class="sa-form-label">{{ this.$i18n.t("patient.idoneity.lblCVF") }}</span>
            <b-form-input type="number" v-model="data.cvf" step="0.01"></b-form-input><br />
            <span class="sa-form-label">{{ this.$i18n.t("patient.idoneity.lblVEMS") }}</span>
            <b-form-input type="number" v-model="data.vems" step="0.01"></b-form-input><br />
            <span class="sa-form-label">{{ this.$i18n.t("patient.idoneity.lblVemsCvf") }}</span>
            <b-form-input type="number" v-model="data.vemsCvf" step="0.01"></b-form-input><br />
            <span class="sa-form-label">{{ this.$i18n.t("patient.idoneity.lblMVV") }}</span>
            <b-form-input type="number" v-model="data.mvv" step="0.01"></b-form-input><br />
          </b-col>
          <b-col xs="12" sm="6" md="6" lg="6" xl="6">
            <span class="sa-form-label">{{ this.$i18n.t("patient.idoneity.lblCVNormal") }}</span>
            <b-form-input type="number" v-model="data.cvNormale" step="0.01"></b-form-input><br />
            <span class="sa-form-label">{{ this.$i18n.t("patient.idoneity.lblCVFNormal") }}</span>
            <b-form-input type="number" v-model="data.cvfNormale" step="0.01"></b-form-input><br />
            <span class="sa-form-label">{{ this.$i18n.t("patient.idoneity.lblVEMSNormal") }}</span>
            <b-form-input type="number" v-model="data.vemsNormale" step="0.01"></b-form-input><br />
            <span class="sa-form-label">{{ this.$i18n.t("patient.idoneity.lblVemsCvfNormal") }}</span>
            <b-form-input type="number" v-model="data.vemsCvfNormale" step="0.01"></b-form-input><br />

            <span class="sa-form-label">{{ this.$i18n.t("patient.idoneity.lblMVVNormal") }}</span>
            <b-form-input type="number" v-model="data.mvvNormale" step="0.01"></b-form-input><br />
          </b-col>
        </b-row>
        <hr />
      </b-col>
    </b-row>
    <br />
    <h4>Conclusioni:</h4>
    <b-row>
      <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
        <b-form-textarea v-model="data.conclusioni" type="text" no-resize placeholder="Conclusioni"></b-form-textarea>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
        <b-row>
          <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
            <strong>Stato </strong><br />
            {{ data.firma }}
          </b-col>
        </b-row>
        <hr />
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  props: ["data"],
};
</script>
