<template>
    <sa-page-view-edit ref="pgaView" :data="data" :pathResource="pathResource" :backVisible="true" :saveVisible="true" :linkback="linkback" :linkedit="linkedit" :showmolalloading="showmolalloading">
        <template slot="body">
            <div class="b-table-sticky-header">
                <b-table sticky-header ref="table" id="tbl" responsive stacked="lg" striped hover :items="items" :fields="fields" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table" @row-clicked="onRowClickHandler">
                    <template v-slot:cell(nominativo)="{ item }"> {{ item.nome }} {{ item.cognome }} </template>
                </b-table>
            </div>
            <b-modal id="mdlModalUtente" ref="mdlModalUtente" title="Utente" size="lg" @ok="onOkModal">
                <div>
                    <b-row>
                        <b-col cols="12" xs="12" sm="3" md="3" lg="3" xl="3">
                            <label for="">Nome</label>
                            <b-form-input v-model="users.firstname" readonly></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="3" md="3" lg="3" xl="3">
                            <label for="">Cognome</label>
                            <b-form-input v-model="users.lastname" readonly></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="3" md="3" lg="3" xl="3">
                            <label for="">Email</label>
                            <b-form-input v-model="users.email"></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="3" md="3" lg="3" xl="3">
                            <label for="">Username</label>
                            <b-form-input v-model="users.username" trim></b-form-input>
                        </b-col>
                    </b-row>
                </div>
            </b-modal>
        </template>
    </sa-page-view-edit>
</template>
<script>
import axios from "axios";
import { SaPageViewEdit } from "../../../template/sa/components/layout/contents";
export default {
    components: { SaPageViewEdit },
    data() {
        return {
            pathResource: "/pazienteaccesso/patientuser",
            linkback: "/patientaccess",
            linkedit: null,
            showmolalloading: false,
            currentPage: 1,
            perPage: 20,
            fields: [
                {
                    label: "Nome",
                    key: "nome",
                    sortable: true,
                    thStyle: "width: 50rem",
                },
                {
                    label: "Cognome",
                    key: "cognome",
                    sortable: true,
                    thStyle: "width: 50rem",
                },
                {
                    label: "Codice Fiscale",
                    key: "codiceFiscale",
                    sortable: true,
                    thStyle: "width: 50rem",
                },
                {
                    label: "Email",
                    key: "email",
                    sortable: true,
                    thStyle: "width: 50rem",
                },
            ],
            items: [{ nome: "", cognome: "", codiceFiscale: "", username: "" }],
            users: { firstname: "", lastname: "", email: "", username: "", id: "" },
            data: {},
        };
    },
    computed: {
        rows() {
            return this.items.length;
        },
    },
    mounted: function() {
        let me = this;
        me.id = this.$route.params.id;
        me.loadDefaultData();
    },
    methods: {
        loadDefaultData() {
            let me = this;
            me.loadData();
        },
        loadData() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource;
            me.showmolalloading = true;
            axios
                .get(link)
                .then((response) => {
                    me.items = [];
                    me.items = response.data.data;
                    me.showmolalloading = false;
                })
                .catch(function(error) {
                    me.showmolalloading = false;
                    me.$bvToast.toast(error.response.data.messaggio, {
                        title: "Sorveglianza Covid Admin",
                        variant: "danger",
                        solid: true,
                    });
                });
        },
        onRowClickHandler(item) {
            let me = this;
            me.users.firstname = item.nome;
            me.users.lastname = item.cognome;
            me.users.username = item.nome.toLowerCase().replace(" ", "") + "." + item.cognome.toLowerCase().replace(" ", "");
            me.users.email = item.email;
            me.users.id = item.id;
            me.$refs["mdlModalUtente"].show();
        },
        onOkModal() {
            let me = this;
            console.log(me.users);
            let jsonData = JSON.stringify(me.users);
            let link = process.env.VUE_APP_PATH_API + "/patientaccess";
            me.showmolalloading = true;
            axios
                .post(link, jsonData)
                .then((response) => {
                    me.showmolalloading = false;
                    console.log(response);
                })
                .catch((error) => {
                    me.showmolalloading = false;
                    this.$bvModal
                        .msgBoxOk(error.response.data.messaggio, {
                            title: "ATTENZIONE",
                            size: "sm",
                            okVariant: "outline-danger",
                            headerClass: "sa-msg-header-danger",
                            footerClass: "p-2 border-top-0",
                            centered: true,
                        })
                        .then((value) => {
                            this.boxTwo = value;
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                });
        },
    },
};
</script>
