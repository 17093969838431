import BancaEmatica from "../";

const routes = [
    { name: "BancaEmaticaList", path: "/bancaematica", component: BancaEmatica.BancaEmaticaList, meta: { title: "bancaematica.lblTitle" } },
    { name: "BancaEmaticaView", path: "/bancaematica/view/:id", component: BancaEmatica.BancaEmaticaView, meta: { title: "bancaematica.lblTitle" } },
    { name: "BancaEmaticaEdit", path: "/bancaematica/edit/:id", component: BancaEmatica.BancaEmaticaEdit, meta: { title: "bancaematica.lblTitle" } },
    { name: "BancaEmaticaRichiestaScorteList", path: "/bancaematicarichiestascorte", component: BancaEmatica.BancaEmaticaRichiestaScorteList, meta: { title: "bancaematica.lblTitleScorte" } },
    { name: "BancaEmaticaRichiestaScorteView", path: "/bancaematicarichiestascorte/view/:id", component: BancaEmatica.BancaEmaticaRichiestaScorteView, meta: { title: "bancaematica.lblTitleScorte" } },
    { name: "BancaEmaticaRichiestaScorteEdit", path: "/bancaematicarichiestascorte/edit/:id", component: BancaEmatica.BancaEmaticaRichiestaScorteEdit, meta: { title: "bancaematica.lblTitleScorte" } },
    { name: "BancaEmaticaRichiesteRepartoList", path: "/bancaematicarichiestereparto", component: BancaEmatica.BancaEmaticaRichiesteRepartoList, meta: { title: "bancaematica.lblTitleReparto" } },
    { name: "BancaEmaticaRichiesteRepartoEdit", path: "/bancaematicarichiestereparto/edit/:id", component: BancaEmatica.BancaEmaticaRichiesteRepartoEdit, meta: { title: "bancaematica.lblTitleReparto" } },
    { name: "BancaEmaticaRichiesteRepartoView", path: "/bancaematicarichiestereparto/view/:id", component: BancaEmatica.BancaEmaticaRichiesteRepartoView, meta: { title: "bancaematica.lblTitleReparto" } },
];

export default routes;
