<template>
    <b-card-body>
        <b-row v-if="edit === true">
            <b-col cols="11">
                <b-form-file v-model="file" :state="Boolean(file)" :placeholder="this.$i18n.t('patient.afa.lblChooseFile')" drop-placeholder="Trascina qui il FIle"></b-form-file>
                <div class="mt-3">{{ this.$i18n.t("patient.afa.lblChosenFile") }}: {{ file ? file.name : "" }}</div></b-col
            ><b-col
                ><b-button @click="aggiungi()"> {{ this.$i18n.t("patient.afa.lblAdd") }} </b-button></b-col
            ></b-row
        >
        <b-row
            ><span class="sa-form-label">{{ this.$i18n.t("patient.afa.lblAttachments") }}</span></b-row
        >
        <b-row v-if="rowList !== 0"
            ><b-list-group v-for="file in fileList()" :key="file.id"
                ><b-row
                    ><b-col cols="3">
                        <b-list-group-item button @click="download(file.file)"> <strong> Nome: </strong> {{ file.nomeFile }} </b-list-group-item></b-col
                    ><b-col>{{ file.tipo }}</b-col>
                    <b-col>{{ file.tipoDocumento }}</b-col>
                    <b-col>{{ file.descrizione }}</b-col
                    ><b-col><b-button @click="eliminaFile(file.id)" variant="outline-danger" style="height: 49px"> Elimina</b-button></b-col></b-row
                >
            </b-list-group>
            <b-pagination v-model="currentPageFile" :total-rows="rowList" :per-page="perPage" aria-controls="itemList" align="center"></b-pagination
        ></b-row>
        <div v-if="rowList === 0">
            <img
                src="@/assets/images/no-image.png"
                width="100"
                style="display: block;
    margin: auto auto;"
            />
            <h4 style="text-align: center">
                {{ this.$i18n.t("patient.afa.lblNoFile") }}
            </h4>
        </div>
    </b-card-body>
</template>

<script>
export default {
    props: ["data", "edit"],
    data() {
        return {
            file: null,
            perPage: 10,
            currentPageFile: 1,
            rowList: 0,
        };
    },
    mounted() {
        let me = this;
        try {
            let pages = me.data.allegati.length;
            me.rowList = pages;
        } catch {
            me.rowList = 0;
        }
    },
    methods: {
        download() {},
        aggiungi() {
            let me = this.file;
            switch (me) {
                case me !== null:
                    console.log("ok");
                    break;
                default:
                    alert("Nessun file selezionato");
                    break;
            }
        },
        eliminaFile() {},
        fileList() {
            try {
                const items = this.data.allegati;
                // Return just page of items needed
                return items.slice((this.currentPageFile - 1) * this.perPage, this.currentPageFile * this.perPage);
            } catch {
                return [];
            }
        },
    },
};
</script>
