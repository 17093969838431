import Diabetologia from "../";

const routes = [
    { name: "AnamnesiList", path: "/paziente/diabetologia/anamnesi", component: Diabetologia.AnamnesiList, meta: { title: "Anamnesi Diabetologica" } },
    { name: "AnamnesiView", path: "/paziente/diabetologia/anamnesi/view/:id", component: Diabetologia.AnamnesiView, meta: { title: "Anamnesi Diabetologica" } },
    { name: "AnamnesiEdit", path: "/paziente/diabetologia/anamnesi/edit/:id", component: Diabetologia.AnamnesiEdit, meta: { title: "Anamnesi Diabetologica" } },

    { name: "AnamnesiFamiliareList", path: "/paziente/diabetologia/anamnesifamiliare", component: Diabetologia.AnamnesiFamiliareList, meta: { title: "Anamnesi Familiare Diabetologica" } },
    { name: "AnamnesiFamiliareView", path: "/paziente/diabetologia/anamnesifamiliare/view/:id", component: Diabetologia.AnamnesiFamiliareView, meta: { title: "Anamnesi Familiare Diabetologica" } },
    { name: "AnamnesiFamiliareEdit", path: "/paziente/diabetologia/anamnesifamiliare/edit/:id", component: Diabetologia.AnamnesiFamiliareEdit, meta: { title: "Anamnesi Familiare Diabetologica" } },

    { name: "AnamnesiDiabetologicaFollowUpList", path: "/paziente/diabetologia/followup", component: Diabetologia.AnamnesiDiabetologicaFollowUpList, meta: { title: "Follow Up  Diabetologica" } },
    { name: "AnamnesiDiabetologicaFollowUpView", path: "/paziente/diabetologia/followup/view/:id", component: Diabetologia.AnamnesiDiabetologicaFollowUpView, meta: { title: "Follow Up  Diabetologica" } },
    { name: "AnamnesiDiabetologicaFollowUpEdit", path: "/paziente/diabetologia/followup/edit/:id", component: Diabetologia.AnamnesiDiabetologicaFollowUpEdit, meta: { title: "Follow Up  Diabetologica" } },

    { name: "ControlliSpecialisticiList", path: "/paziente/diabetologia/controllispecialistici", component: Diabetologia.ControlliSpecialisticiList, meta: { title: "Controlli Specialistici Diabetologica" } },
    { name: "ControlliSpecialisticiView", path: "/paziente/diabetologia/controllispecialistici/view/:id", component: Diabetologia.ControlliSpecialisticiView, meta: { title: "Controlli Specialistici Diabetologica" } },
    { name: "ControlliSpecialisticiEdit", path: "/paziente/diabetologia/controllispecialistici/edit/:id", component: Diabetologia.ControlliSpecialisticiEdit, meta: { title: "Controlli Specialistici Diabetologica" } },
];
export default routes;
