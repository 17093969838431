<template>
    <sa-page-view-edit ref="pgaView" :data="gruppo" :pathResource="pathResource" :backVisible="true" :saveVisible="true" :titolo="gruppo.nome" :linkback="linkback" :linkedit="linkedit" :showmolalloading="showmolalloading">
        <template slot="filter">
            <b-row>
                <b-col cols="12" sm="6">
                    <b-form-input v-model="gruppo.nome" placeholder="Nome"></b-form-input>
                </b-col>
                <b-col cols="12" sm="6">
                    <b-form-input v-model="gruppo.descrizione" placeholder="Descrizione"></b-form-input>
                </b-col>
            </b-row>
        </template>
        <template slot="body">
            <b-tabs card class="sa-card-tabs">
                <b-tab title="Menu" active>
                    <b-card-text>
                        <b-table responsive striped hover :items="gruppo.menu" :fields="fieldsMenu" head-variant="light" sort-icon-left>
                            <template v-slot:cell(iconCls)="{ item }">
                                <i :class="'fas fa-' + item.iconCls"></i>
                                <!-- You can also use the font-awesome-icon component here -->
                            </template>
                            <template v-slot:cell(text)="{ item }">
                                <div>
                                    <span class="sa-text-bold">{{ item.text }} ({{ item.routeId }})</span>
                                </div>
                                <div>
                                    <span>{{ item.view }}</span>
                                </div>
                            </template>
                            <template v-slot:cell(selected)="{ item }">
                                <b-form-checkbox v-model="item.selezionato" name="check-button" switch size="lg"></b-form-checkbox>
                            </template>
                        </b-table>
                    </b-card-text>
                </b-tab>
                <b-tab title="Permessi">
                    <b-card-text>
                        <b-row>
                            <b-col lg="12" class="my-1">
                                <b-form-group label="" label-cols-sm="0" label-align-sm="right" label-size="sm" label-for="filterInput" class="mb-0">
                                    <b-input-group size="sm">
                                        <b-form-input v-model="filter" type="search" id="filterInput" placeholder="Nome"></b-form-input>
                                        <b-input-group-append>
                                            <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                                        </b-input-group-append>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-table responsive striped hover :filter="filter" :filterIncludedFields="filterOn" :items="gruppo.sezioni" :fields="fieldsSezioni" head-variant="light" sort-icon-left>
                            <template v-slot:cell(permessiSezione)="{ item }">
                                <div></div>
                                <!--<b-form-checkbox v-for="permesso in verificaPermessoSezione(item.permessiSezione, permessi)" :key="permesso.valore" switch size="lg" class="sa-normal-label">-->
                                <div v-for="(permesso, index) in item.permessi" :key="index">
                                    <b-form-checkbox v-model="permesso.attivo" size="lg" switch class="sa-normal-label">{{ permesso.descrizione }}</b-form-checkbox>
                                </div>
                            </template>
                        </b-table>
                    </b-card-text>
                </b-tab>
            </b-tabs>
        </template>
    </sa-page-view-edit>
</template>
<script>
import axios from "axios";
import { SaPageViewEdit } from "../../../template/sa/components/layout/contents";
//import { SaModalLoading } from "../../../components/modal";
export default {
    components: { SaPageViewEdit },
    label: "Dettagllio Gruppo s",
    data() {
        return {
            linkback: "/gruppi",
            pathResource: "/gruppi",
            linkedit: "",
            showmolalloading: false,
            idGruppo: String,
            gruppo: { nome: "", descrizioneGruppo: "", menu: [], sezioni: [] },
            label: "Dettagllio Gruppo",
            filter: null,
            filterOn: [],
            fieldsMenu: [
                {
                    label: "",
                    key: "iconCls",
                    sortable: false,
                    class: "sa-col-icon",
                },
                {
                    label: "Menu",
                    key: "text",
                    sortable: false,
                },
                {
                    label: "Padre",
                    key: "idPadre",
                    sortable: false,
                },
                {
                    key: "selected",
                    label: "",
                    sortable: false,
                    class: "sa-width-2 sa-td-vertical-middle",
                },
            ],
            fieldsSezioni: [
                {
                    label: "Sezione",
                    key: "descrizione",
                    sortable: true,
                },
                {
                    label: "Permessi",
                    key: "permessiSezione",
                    sortable: false,
                    class: "sa-width-12",
                },
            ],
        };
    },
    mounted: function() {
        let me = this;
        me.id = this.$route.params.id;
        if (me.id !== "-1") {
            me.linkback = me.linkback + "/view/" + me.id;
        }
        me.loadData();
    },
    methods: {
        verificaPermessoSezione(permesso) {
            return permesso ? "checked" : "unchecked";
        },
        loadData() {
            let me = this;
            me.showmolalloading = true;
            let link = process.env.VUE_APP_PATH_API + "/vocimenu";
            axios.get(link).then((response) => {
                me.menu = response.data.data;
                link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
                axios.get(link + me.id).then((response) => {
                    me.gruppo = response.data.data;
                    me.showmolalloading = false;
                });
            });
        },
        onBack() {
            let me = this;
            if (me.gruppo.id) {
                me.$router.replace("/gruppi/view/" + me.gruppo.id).catch((err) => {
                    err;
                });
            } else {
                me.$router.replace("/gruppi").catch((err) => {
                    err;
                });
            }
        },
    },
};
</script>
<style>
.sa-text-right {
    text-align: right;
}
@media only screen and (max-width: 767px) {
    .sa-text-right {
        text-align: left;
    }
}
.card {
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.125), 0 1px 3px rgba(0, 0, 0, 0.2);
    margin-bottom: 1rem;
}
</style>
