// import Paziente from "../../components/Paziente.vue";
import PazienteSic from "../";

const routes = [
    { name: "PazienteSicList", path: "/pazientisic", component: PazienteSic.PazienteSicList, meta: { title: "pazienti.lblTitle" } },
    { name: "PazienteSic", path: "/paziente/sic/view/:id", component: PazienteSic.PazienteSic, meta: { title: "pazienti.lblTitle" } },
    { name: "PazienteSicAllergieList", path: "/paziente/sic/allergie", component: PazienteSic.PazienteSicAllergieList, meta: { title: "pazienti.lblTitle" } },
    { name: "PazienteSicAllergieView", path: "/paziente/sic/allergie/view/:id", component: PazienteSic.PazienteSicAllergieView, meta: { title: "pazienti.lblTitle" } },
    { name: "PazienteSicAllergieEdit", path: "/paziente/sic/allergie/edit/:id", component: PazienteSic.PazienteSicAllergieEdit, meta: { title: "pazienti.lblTitle" } },
    { name: "PazienteSicIntolleranzeList", path: "/paziente/sic/intolleranze", component: PazienteSic.PazienteSicIntolleranzeList, meta: { title: "pazienti.lblTitle" } },
    { name: "PazienteSicIntolleranzeView", path: "/paziente/sic/intolleranze/view/:id", component: PazienteSic.PazienteSicIntolleranzeView, meta: { title: "pazienti.lblTitle" } },
    { name: "PazienteSicIntolleranzeEdit", path: "/paziente/sic/intolleranze/edit/:id", component: PazienteSic.PazienteSicIntolleranzeEdit, meta: { title: "pazienti.lblTitle" } },
];

export default routes;
