<template>
    <div>
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="6" xl="6">
                        <strong>Data Rilevazione</strong><br />
                        {{ formatDate(jsonData.data) }}
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="6" xl="6">
                        <strong>Sostanza</strong><br />
                        {{ jsonData.sostanza }}
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <hr />
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="6" xl="6">
                        <strong>Tipo Rilevazione</strong><br />
                        {{ jsonData.tipoReazione }}
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="6" xl="6">
                        <strong>Reazione</strong><br />
                        {{ jsonData.reazione }}
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <hr />
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="6" xl="6">
                        <strong>Gravità Reazione</strong><br />
                        {{ jsonData.gravitaReazione }}
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="6" xl="6">
                        <strong>Stato Osservazione</strong><br />
                        {{ jsonData.statoOsservazione }}
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <hr />
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                        <strong>Note</strong><br />
                        {{ jsonData.note }}
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <hr />
    </div>
</template>
<script>
import UtilityMixin from "../../../utility/UtilityMixin";
export default {
    mixins: [UtilityMixin],
    props: {
        jsonData: {
            Type: Object,
            default: function() {
                return {
                    dataRilevazione: null,
                    sostanza: null,
                    tipoReazione: null,
                    reazione: null,
                    gravitaReazione: null,
                    statoOsservazione: null,
                    note: "",
                };
            },
        },
        id: {
            Type: String,
            default: function() {
                return "-1";
            },
        },
    },
};
</script>
