<template>
  <div>
    <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
      <b-col lg="3">
        <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
      </b-col>
      <b-col lg="9">
        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
      </b-col>
    </b-row>
    <div class="b-table-sticky-header">
      <b-table sticky-header ref="table" :filter="null" stacked="xl" striped hover :items="items" :fields="fields" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
        <template v-slot:cell(hrMin)="{ item }">
          <span :class="'badge badge-' + returnBadgeRischio('hrMin', item.hrMin) + ' badge-pill'" style="font-size:16px;">{{ item.hrMin }}</span>
        </template>
        <template v-slot:cell(hrMax)="{ item }">
          <span :class="'badge badge-' + returnBadgeRischio('hrMax', item.hrMax) + ' badge-pill'" style="font-size:16px;">{{ item.hrMax }}</span>
        </template>
        <template v-slot:cell(hrAverage)="{ item }">
          <span :class="'badge badge-' + returnBadgeRischio('hrAverage', item.hrAverage) + ' badge-pill'" style="font-size:16px;">{{ item.hrAverage }}</span>
        </template>
        <template v-slot:cell(calories)="{ item }">
          <span :class="'badge badge-' + returnBadgeRischio('calories', item.calories) + ' badge-pill'" style="font-size:16px;">{{ item.calories }}</span>
        </template>
        <template v-slot:cell(totalcalories)="{ item }">
          <span :class="'badge badge-' + returnBadgeRischio('totalcalories', item.totalcalories) + ' badge-pill'" style="font-size:16px;">{{ item.totalcalories }}</span>
        </template>
        <template v-slot:cell(steps)="{ item }">
          <span :class="'badge badge-' + returnBadgeRischio('steps', item.steps) + ' badge-pill'" style="font-size:16px;">{{ item.steps }}</span>
        </template>
        <template v-slot:cell(active)="{ item }">
          <span :class="'badge badge-' + returnBadgeRischio('active', item.active) + ' badge-pill'" style="font-size:16px;">{{ item.active }}</span>
        </template>
        <template v-slot:cell(distance)="{ item }">
          <span :class="'badge badge-' + returnBadgeRischio('distance', item.distance) + ' badge-pill'" style="font-size:16px;">{{ item.distance }}</span>
        </template>
        <template v-slot:cell(sleep)="{ item }">
          <span :class="'badge badge-' + returnBadgeRischio('sleep', item.sleep) + ' badge-pill'" style="font-size:16px;">{{ convertHour(item.sleep) }}</span>
        </template>
        <template v-slot:cell(diastolicBloodPressure)="{ item }">
          <span :class="'badge badge-' + returnBadgeRischio('diastolicBloodPressure', item.diastolicBloodPressure) + ' badge-pill'" style="font-size:16px;">{{ item.diastolicBloodPressure }}</span>
        </template>
        <template v-slot:cell(systolicBloodPressure)="{ item }">
          <span :class="'badge badge-' + returnBadgeRischio('systolicBloodPressure', item.systolicBloodPressure) + ' badge-pill'" style="font-size:16px;">{{ item.systolicBloodPressure }}</span>
        </template>
        <template v-slot:cell(heartPulse)="{ item }">
          <span :class="'badge badge-' + returnBadgeRischio('heartPulse', item.heartPulse) + ' badge-pill'" style="font-size:16px;">{{ item.heartPulse }}</span>
        </template>
        <template v-slot:cell(spo2)="{ item }">
          <span :class="'badge badge-' + returnBadgeRischio('spo2', item.spo2) + ' badge-pill'" style="font-size:16px;">{{ item.spo2 }}</span>
        </template>
      </b-table>
    </div>
    <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
      <b-col lg="3">
        <p>{{ this.$i18n.t("ambulatori.lblTotalRecords") }}: {{ rows }}</p>
      </b-col>
      <b-col lg="9">
        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
      </b-col>
    </b-row>
    <!-- <b-row><b-button @click="stampa()">stampa</b-button> <b-button @click="stampaRischio()">stampa rischio</b-button></b-row> -->
  </div>
</template>
<script>
import moment from "moment";
import axios from "axios";
export default {
  props: ["chart", "idPaziente"],
  data() {
    return {
      pathRangeRischio: "/pazientemisurerange",
      filtro: {},
      perPage: 10,
      currentPage: 1,
      fields: [],
      items: [],
      rows: 0,
      itemsRischio: [],
    };
  },
  mounted() {
    this.formatData();
    this.loadRischio();
  },
  methods: {
    loadRischio() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathRangeRischio + "?idPaziente=" + me.idPaziente;
      axios
        .get(link, { params: me.filtro })
        .then((response) => {
          me.itemsRischio = [];
          me.itemsRischio = response.data.data;
        })
        .catch(() => {});
    },
    convertHour(time) {
      return new Date(time * 1000).toISOString().substr(12, 4);
    },
    stampa() {
      let me = this;
      console.log(me.chart);
    },
    stampaRischio() {
      let me = this;
      console.log(me.itemsRischio);
    },
    formatData() {
      let me = this;
      if (me.chart.labels !== null && me.chart.labels !== undefined) {
        let field = {
          label: this.$i18n.t("patient.events.lblEventDate"),
          key: "date",
          thStyle: "width: 3rem",
          sortable: true,
        };
        me.fields.push(field);
        let i = 0;
        console.log(me.chart);
        let dateEvento = me.chart.datasets[0].dateList;

        dateEvento.forEach((data) => {
          let item = { date: moment(data).format("DD/MM/YYYY HH:mm:ss"), id: i };
          me.items.push(item);
          i++;
          me.rows++;
        });
        let eventi = me.chart.datasets;
        // console.log(me.chart);
        eventi.forEach((item) => {
          let key = me.returnVariableName(item.label);
          let field = {
            label: item.label,
            key: key,
            thStyle: "width: 3rem",
            sortable: true,
          };

          let fieldOrigin = {
            label: this.$i18n.t("patient.events.lblOrigin"),
            key: "origin",
            thStyle: "width: 3rem",
            sortable: true,
          };
          me.fields.push(fieldOrigin);
          me.fields.push(field);

          let dati = item.data;
          let j = 0;

          dati.forEach((dato) => {
            try {
              me.items[j][key] = dato;
            } catch {
              console.log("errore nel rapporto labels-datasets");
            }
            j++;
          });
          let origini = item.origin;
          if (origini !== null && origini !== undefined) {
            let k = 0;
            origini.forEach((dato) => {
              try {
                me.items[k].origin = dato;
              } catch {
                console.log("errore nel rapporto labels-datasets");
              }

              k++;
            });
          }
        });
      }
    },
    returnVariableName(item) {
      switch (item) {
        case "Hr Min":
          return "hrMin";
        case "Hr Max":
          return "hrMax";
        case "Hr Average":
          return "hrAverage";
        case "Number Of Steps":
          return "steps";
        case "Calories":
          return "calories";
        case "Total Calories":
          return "totalcalories";
        case "Active":
          return "active";
        case "Distance":
          return "distance";
        case "Sleep":
          return "sleep";
        case "Diastolic Blood Pressure (mmHg)":
          return "diastolicBloodPressure";
        case "Systolic Blood Pressure (mmHg)":
          return "systolicBloodPressure";
        case "Heart Pulse (bpm) - only for BPM devices":
          return "heartPulse";
        case "SP02 (%)":
          return "spo2";
        default:
          break;
      }
    },
    returnBadgeRischio(key, valore) {
      let me = this;
      let label = "";

      me.itemsRischio.forEach((item) => {
        // if (me.checkName(item.misureNome) === key) {
        //   let value = Number(valore);
        //   switch (value) {
        //     case value <= item.minRangeAllert:
        //       console.log("danger: " + value);
        //       label = "danger";
        //       break;
        //     case item.minRangeAllert < value && value <= item.minRangeWarning:
        //       console.log("warning: " + value);
        //       label = "warning";
        //       break;
        //     case item.minRangeWarning < value && value <= item.maxRangeWarning:
        //       console.log("normal: " + value);
        //       label = "success";
        //       break;
        //     case item.maxRangeWarning < value && value <= item.maxRangeAllert:
        //       console.log("warning: " + value);
        //       label = "warning";
        //       break;
        //     case value > item.maxRangeAllert:
        //       console.log("danger: " + value);
        //       label = "danger";
        //       break;
        //     default:
        //       label = "secondary";
        //       console.log(item);
        //       break;
        //   }
        // }
        let nome = me.checkName(item.misureNome);
        let value = Number(valore);

        let check = nome === key ? true : false;
        switch (check) {
          case true:
            if (value <= item.minRangeAllert) {
              // console.log("danger: " + value);
              label = "danger";
            } else if (item.minRangeAllert < value && value <= item.minRangeWarning) {
              // console.log("warning: " + value);
              label = "warning";
            } else if (item.minRangeWarning < value && value <= item.maxRangeWarning) {
              // console.log("normal: " + value);
              label = "success";
            } else if (item.maxRangeWarning < value && value <= item.maxRangeAllert) {
              // console.log("warning: " + value);
              label = "warning";
            } else if (value > item.maxRangeAllert) {
              // console.log("danger: " + value);
              label = "danger";
            } else {
              label = "secondary";
              console.log(item);
            }
            break;
          case false:
            break;
          default:
            label = "primary";
            break;
        }
      });
      return label;
    },
    checkName(name) {
      let label = "";
      switch (name) {
        case "hr_average":
          label = "hrAverage";
          break;
        case "totalcalories":
          label = "totalcalories";
          break;
        case "calories":
          label = "calories";
          break;
        case "active":
          label = "active";
          break;
        case "total_sleep_time":
          label = "sleep";
          break;
        case "sleep_efficiency":
          label = "sleepEfficiency";
          break;
        case "hr_min":
          label = "hrMin";
          break;
        case "hr_max":
          label = "hrMax";
          break;
        // case "elevation":
        //   label = this.$i18n.t("patient.card.lblElevation");
        //   break;
        // case "out_of_bed_count":
        //   label = this.$i18n.t("patient.card.lblOutOfBedTime");
        //   break;
        // case "Skin Temperature (celsius)":
        //   label = this.$i18n.t("patient.card.lblSkinTemperature");
        //   break;
        // case "Bone Mass (kg)":
        //   label = this.$i18n.t("patient.card.lblBoneMass");
        //   break;
        // case "Body Temperature (celsius)":
        //   label = this.$i18n.t("patient.card.lblBodyTemperature");
        //   break;
        // case "Temperature (celsius)":
        //   label = this.$i18n.t("patient.card.lblTemperature");
        //   break;
        // case "Pulse Wave Velocity (m/s)":
        //   label = this.$i18n.t("patient.card.lblPulseWaveVelocity");
        // break;
        case "SP02 (%)":
          label = "spo2";
          break;
        case "Heart Pulse (bpm) - only for BPM devices":
          label = "heartPulse";
          break;
        case "Systolic Blood Pressure (mmHg)":
          label = "systolicBloodPressure";
          break;
        case "Diastolic Blood Pressure (mmHg)":
          label = "diastolicBloodPressure";
          break;
        case "steps":
          label = "steps";
          break;
        // case "delete-unlink-update":
        //   label = this.$i18n.t("patient.card.lblDeleted");
        //   break;
        // case "sleep_score":
        //   label = this.$i18n.t("patient.card.lblSleepScore");
        //   break;
        // case "Weight (kg)":
        //   label = this.$i18n.t("patient.card.lblWeight");
        //   break;
        // case "Fat Free Mass (kg)":
        //   label = this.$i18n.t("patient.card.lblFatFreeMass");
        //   break;
        // case "Height (meter)":
        //   label = this.$i18n.t("patient.card.lblHeight");
        //   break;
        // case "Fat Mass Weight (kg)":
        //   label = this.$i18n.t("patient.card.lblFatMassWeight");
        // break;
        case "distance":
          label = "distance";
          break;
        // case "Fat Ratio (%)":
        //   label = this.$i18n.t("patient.card.lblFatRatio");
        //   break;
        // case "steps":
        //   label = "steps";
        //   break;
        // case "Hydration (kg)":
        //   label = this.$i18n.t("patient.card.lblHydration");
        //   break;
        // case "Muscle Mass (kg)":
        //   label = this.$i18n.t("patient.card.lblMuscleMass");
        //   break;
        default:
          label = "unknown";
          break;
      }
      return label;
    },
  },
};
</script>
