<template>
  <sa-page-layout
    :showModalLoading="showModalLoading"
    :btnSaveVisible="false"
    :btnAnnullaVisible="false"
    :btnBackVisible="true"
    :btnEditVisible="true"
    :linkedit="linkedit"
    :linkback="linkback"
    :pathResource="pathResource"
    :data="jsonData"
  >
    <template slot="toolbar-title">
      <paziente-anagrafica :linkPatient="linkPatient" :idPaziente="idPaziente"></paziente-anagrafica>
    </template>
    <template slot="table-body">
      <div class="sa-tab-scroll">
        <b-row>
          <b-col>
            <br />
            <div class="sa-form-section">
              <b-row>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                  <strong>{{ this.$i18n.t("patient.ecoendoscopia.lblReasonExam") }}</strong>
                  <br />
                  {{ jsonData.motivoEsame }}
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                  <strong>{{ this.$i18n.t("patient.ecografia.lblLiver") }}</strong>
                  <br />
                  {{ jsonData.fegato }}
                </b-col>
              </b-row>
              <hr />
              <b-row>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                  <strong>{{ this.$i18n.t("patient.ecografia.lblGallbladder") }}</strong>
                  <br />
                  {{ jsonData.colecisti }}
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                  <strong>{{ this.$i18n.t("patient.ecografia.lblBiliaryTractExt") }}</strong>
                  <br />
                  {{ jsonData.vieBiliariExtraepatiche }}
                </b-col>
              </b-row>
              <hr />
              <b-row>
                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                  <strong>{{ this.$i18n.t("patient.ecografia.lblPancreas") }}</strong>
                  <br />
                  {{ jsonData.pancreas }}
                </b-col>
              </b-row>
              <hr />
              <b-row>
                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                  <strong>{{ this.$i18n.t("patient.ecografia.lblSpleen") }}</strong>
                  <br />
                  {{ jsonData.milza }}
                </b-col>
              </b-row>
              <hr />
              <b-row>
                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                  <strong>{{ this.$i18n.t("patient.ecografia.lblAxisSplenoPortal") }}</strong>
                  <br />
                  {{ jsonData.asseSplenoPortale }}
                </b-col>
              </b-row>
              <hr />
              <b-row>
                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                  <strong>{{ this.$i18n.t("patient.ecografia.lblRightKidney") }}</strong>
                  <br />
                  {{ jsonData.reneDx }}
                </b-col>
              </b-row>
              <hr />
              <b-row>
                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                  <strong>{{ this.$i18n.t("patient.ecografia.lblLeftKidney") }}</strong>
                  <br />
                  {{ jsonData.reneSx }}
                </b-col>
              </b-row>
              <hr />
              <b-row>
                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                  <strong>{{ this.$i18n.t("patient.ecografia.lblVarious") }}</strong>
                  <br />
                  {{ jsonData.varie }}
                </b-col>
              </b-row>
              <hr />
              <b-row>
                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                  <strong>{{ this.$i18n.t("patient.ecografia.lblDateUltrasoundCheckup") }}</strong>
                  <br />
                  {{ formatDate(jsonData.dataControlloEcografico) }}
                </b-col>
              </b-row>
            </div>
          </b-col>
        </b-row>
        <br />
      </div>
    </template>
  </sa-page-layout>
</template>
<script>
import axios from "axios";
import SaPageLayout from "../../../../../template/sa/components/layout/contents/SaPageLayout.vue";
import PazienteAnagrafica from "../../../components/PazienteAnagrafica.vue";
import UtilityMixin from "../../../../utility/UtilityMixin";
export default {
  components: { SaPageLayout, PazienteAnagrafica },
  mixins: [UtilityMixin],
  props: {
    utente: {
      type: Object,
      default: function() {
        return { gruppo: { menuGruppo: [] } };
      },
    },
  },
  data() {
    return {
      linkback: null,
      linkedit: null,
      pathResource: "/ecografiaaddominale",
      jsonData: {},
      showModalLoading: false,
    };
  },
  computed: {
    idPaziente() {
      return this.$route.query.idPaziente;
    },
    linkPatient: {
      get: function() {
        let returnValue = "";
        if (this.utente.gruppo) {
          if (this.utente.gruppo.menuGruppo) {
            if (this.utente.gruppo.menuGruppo.length > 1) {
              return "/paziente/view/" + this.idPaziente;
            } else {
              if (this.utente.gruppo.menuGruppo.length == 1) {
                if (this.utente.gruppo.menuGruppo[0].primaryKey === "pazienti") {
                  return "/paziente/view/" + this.idPaziente;
                } else {
                  return "/paziente/homepaziente";
                }
              }
            }
          }
        }
        return returnValue;
      },
    },
  },
  mounted() {
    let me = this;
    me.jsonData.idPaziente = this.$route.query.idPaziente;
    me.jsonData.tipoEvento = "Gastroenterologia - Ecografia Addominale";
    me.id = this.$route.params.id;
    me.linkedit = "/paziente/gastroenterologia/ecografiaaddominale/edit/" + me.id + "?idPaziente=" + me.idPaziente;
    me.loadData();
    me.linkback = "/paziente/gastroenterologia/ecografiaaddominale?idPaziente=" + me.idPaziente;
  },
  methods: {
    loadData() {
      let me = this;
      if (me.id === "-1") {
        me.showModalLoading = false;
      } else {
        let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.id;
        axios.get(link).then((response) => {
          me.jsonData = response.data.data;
          me.showModalLoading = false;
        });
      }
    },
  },
};
</script>
