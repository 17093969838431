<template>
  <div>
    <b-row>
      <b-col cols="6" xs="12" sm="12" md="12" lg="6">
        <strong>Data Rilevazione</strong>
        <br />
        <span class="sa-text-data">{{ formatDate(jsonData.data) }}</span>
      </b-col>
      <b-col cols="6" xs="12" sm="12" md="12" lg="6">
        <strong>Farmaco</strong>
        <br />
        <span class="sa-text-data">{{ jsonData.farmaco }}</span>
      </b-col>
    </b-row>
    <hr />
    <b-row>
      <b-col cols="6" xs="12" sm="12" md="12" lg="6">
        <strong>Tipo Rilevazione</strong>
        <br />
        <span class="sa-text-data">{{ jsonData.tipoReazione }}</span>
      </b-col>
      <b-col cols="6" xs="12" sm="12" md="12" lg="6">
        <strong>Reazione</strong>
        <br />
        <span class="sa-text-data">{{ jsonData.reazione }}</span>
      </b-col>
    </b-row>
    <hr />
    <b-row>
      <b-col cols="6" xs="12" sm="12" md="12" lg="6">
        <strong>Gravità Reazione</strong>
        <br />
        <span class="sa-text-data">{{ jsonData.gravitaReazione }}</span>
      </b-col>
      <b-col cols="6" xs="12" sm="12" md="12" lg="6">
        <strong>Stato Osservazione</strong>
        <br />
        <span class="sa-text-data">{{ jsonData.statoOsservazione }}</span>
      </b-col>
    </b-row>
    <hr />
    <b-row>
      <b-col cols="12">
        <strong>Note</strong>
        <br />
        <span class="sa-text-data">{{ jsonData.note }}</span>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import UtilityMixin from "../../../utility/UtilityMixin";
export default {
  mixins: [UtilityMixin],
  props: {
    jsonData: {
      Type: Object,
      default: function() {
        return {
          dataRilevazione: null,
          farmaco: null,
          tipoReazione: null,
          reazione: null,
          gravitaReazione: null,
          statoOsservazione: null,
          note: "",
        };
      },
    },
    id: {
      Type: String,
      default: function() {
        return "-1";
      },
    },
  },
};
</script>
