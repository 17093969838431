<template>
    <sa-page-layout :showmolalloading="showmolalloading" :btnNewVisible="btnNewVisible" @refresh="onRefresh" :linkedit="linkedit" :btnRefreshVisible="true">
        <template slot="toolbar-title">
            <paziente-anagrafica :linkPatient="linkPatient" :idPaziente="idPaziente"></paziente-anagrafica>
        </template>
        <template slot="toolbar-row">
            <paziente-dashboard-component :idPaziente="idPaziente"></paziente-dashboard-component>
        </template>
        <!-- <template slot="toolbar">
      <b-button class="float-sm-right " variant="success btn-toolbar sa-margin-right" size="sm" @click="onAdd"><b-icon icon="plus" variant="secondary"></b-icon>{{ this.$i18n.t("global.lblBtnNew") }}</b-button>
      <b-button class="float-sm-right " variant="info btn-toolbar" size="sm" @click="onRefresh" v-b-modal.modal-center><b-icon icon="arrow-clockwise" variant="secondary" />Refresh</b-button>
    </template> -->
        <template slot="table-header">
            <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col lg="3">
                    <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
                </b-col>
                <b-col lg="9">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
            </b-row>
        </template>
        <template slot="table-body">
            <div class="b-table-sticky-header">
                <b-table sticky-header ref="table" :filter="filter" responsive stacked="lg" striped hover :items="items" :fields="fields" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                    <template v-slot:cell(actions)="row">
                        <b-button size="sm" variant="btn btn-info waves-effect  waves-light width-sm no-text" style="margin-right:3px" @click="onClick(row.item, row.index, $event.target)" v-b-modal.visualizzaAllegato>
                            <b-icon icon="search"></b-icon>
                        </b-button>
                    </template>
                </b-table>
            </div>
        </template>
        <template slot="table-footer">
            <b-modal size="xl" id="visualizzaAllegato" title="Visualizza Allegato" @click="onClick">
                <template>
                    <b-embed type="iframe" :src="pdf" allowfullscreen></b-embed>
                </template>
            </b-modal>
        </template>
    </sa-page-layout>
</template>
<script>
import axios from "axios";
import moment from "moment";
import { SaPageLayout } from "../../../template/sa/components/layout/contents";
import PazienteAnagrafica from "./PazienteAnagrafica.vue";
import UtilityMixin from "../../utility/UtilityMixin.js";
import PazienteDashboardComponent from "./PazienteDashboardComponent.vue";
export default {
    props: {
        utente: {
            type: Object,
            default: function() {
                return null;
            },
        },
    },
    components: { SaPageLayout, PazienteAnagrafica, PazienteDashboardComponent },
    computed: {
        idPaziente() {
            return this.$route.query.idPaziente;
        },
        rows() {
            return this.items.length;
        },
        btnNewVisible: {
            get: function() {
                let me = this;
                return UtilityMixin.methods.verificaPermessi(me.utente, "questionari", 2);
            },
        },
        linkPatient: {
            get: function() {
                let returnValue = "";
                if (this.utente.gruppo) {
                    if (this.utente.gruppo.menuGruppo) {
                        if (this.utente.gruppo.menuGruppo.length > 1) {
                            return "/paziente/view/" + this.idPaziente;
                        } else {
                            if (this.utente.gruppo.menuGruppo.length == 1) {
                                if (this.utente.gruppo.menuGruppo[0].primaryKey === "pazienti") {
                                    return "/paziente/view/" + this.idPaziente;
                                } else {
                                    return "/paziente/homepaziente";
                                }
                            }
                        }
                    }
                }
                return returnValue;
            },
        },
    },
    data() {
        return {
            pathResource: "/pazientequestionari",
            // pathRESTRequest: "/paziente/questionari",
            linkedit: "/paziente/questionari",
            pagelink: "",
            perPage: 10,
            currentPage: 1,
            note: "",
            pdf: null,
            filter: null,
            showmolalloading: false,
            id: "-1",
            filtro: {},
            fields: [
                {
                    label: this.$i18n.t("patient.survey.lblSurveyName"),
                    key: "nomeQuestionario",
                    sortable: true,
                    thStyle: "width: 3rem",
                },
                {
                    label: this.$i18n.t("patient.survey.lblSendDate"),
                    key: "dataOraInvio",
                    formatter: (value) => {
                        if (!value) {
                            return "";
                        } else {
                            return moment(new Date(value)).format("DD-MM-YYYY HH:mm:ss");
                        }
                    },
                    thStyle: "width: 3rem",
                    sortable: true,
                },
                {
                    label: this.$i18n.t("patient.survey.lblAnswerDate"),
                    key: "dataOraRisposta",
                    formatter: (value) => {
                        if (!value) {
                            return "";
                        } else {
                            return moment(new Date(value)).format("DD-MM-YYYY HH:mm:ss");
                        }
                    },
                    thStyle: "width: 3rem",
                    sortable: true,
                },
                {
                    label: "",
                    key: "actions",
                    thStyle: "width: 3rem",
                },
                //{ class: "sa-table-column-action-single", thStyle: "width: 10rem" ,key: "actions", label: "" },
            ],
            items: [],
            data: {},
        };
    },
    created: function() {},
    mounted: function() {
        let me = this;
        me.id = this.$route.params.id;
        me.data.idPaziente = this.$route.query.idPaziente;
        me.loadData();
    },
    methods: {
        loadData() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "?idAnagrafica=" + me.data.idPaziente;
            me.showmolalloading = true;
            axios
                .get(link, { params: me.filtro })
                .then((response) => {
                    me.items = [];
                    me.items = response.data.data;
                    me.showmolalloading = false;
                })
                .catch(() => {
                    me.showmolalloading = false;
                });
        },
        onRefresh() {
            let me = this;
            me.loadData();
        },
        onClick(item) {
            let me = this;
            me.pdf = "data:application/pdf;base64," + item.pdf;
        },
        // onAdd() {
        //   let me = this;
        //   me.$router.replace(me.pathRESTRequest + "/edit/-1?idPaziente=" + me.idPaziente).catch((err) => {
        //     err;
        //   });
        // },
    },
};
</script>
