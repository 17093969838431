<template>
    <sa-page-layout :data="jsonData" :btnBackVisible="true" :linkback="linkback" :pathResource="pathResource" :showModalLoading="showModalLoading" :btnSaveVisible="true">
        <template slot="table-body">
            <b-card header="Dettagli Paziente" header-tag="header" title>
                <b-card-text>
                    <b-container>
                        <b-row>
                            <b-col cols="12" md="2" class="sa-text-right">Cognome:</b-col>
                            <b-col cols="12" md="4">
                                <b-form-input readonly v-model="jsonData.cognome" placeholder="Cognome"></b-form-input>
                            </b-col>
                            <b-col cols="12" md="2" class="sa-text-right">Nome:</b-col>
                            <b-col cols="12" md="4">
                                <b-form-input readonly v-model="jsonData.nome" placeholder="Nome"></b-form-input>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="12" md="2" class="sa-text-right">Codice Fiscale:</b-col>
                            <b-col cols="12" md="4">
                                <b-form-input readonly v-model="jsonData.codiceFiscale" placeholder="Codice Fiscale"></b-form-input>
                            </b-col>
                            <b-col cols="12" md="2" class="sa-text-right">Proprietario:</b-col>
                            <b-col cols="12" md="4">
                                <b-form-input v-model="jsonData.proprietario" placeholder="Proprietario"></b-form-input>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="12" md="2" class="sa-text-right">Tipo:</b-col>
                            <b-col cols="12" md="4">
                                <b-form-input v-model="jsonData.tipo" placeholder="Codice Fiscale"></b-form-input>
                            </b-col>
                        </b-row>
                    </b-container>
                </b-card-text>
            </b-card>
        </template>
    </sa-page-layout>
</template>
<script>
import axios from "axios";
import SaPageLayout from "../../../../template/sa/components/layout/contents/SaPageLayout.vue";
export default {
    components: { SaPageLayout },
    data() {
        return {
            jsonData: {},
            linkback: "/pazientisic",
            pathResource: "/pazienti",
            showModalLoading: false,
        };
    },
    mounted: function() {
        let me = this;
        me.id = this.$route.params.id;
        me.loadData();
    },
    methods: {
        loadData() {
            let me = this;
            if (me.id === "-1") {
                me.showModalLoading = false;
            } else {
                me.showModalLoading = true;
                let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
                axios.get(link + me.id).then((response) => {
                    me.jsonData = response.data.data;
                    me.showModalLoading = false;
                });
            }
        },
    },
};
</script>
