<template>
  <sa-page-view-edit titolo="AAA" :data="data" :pathResource="pathResource" :backVisible="true" :saveVisible="true" :linkback="linkback" :linkedit="linkedit" :showmolalloading="showmolalloading">
    <template slot="body">
      <b-tabs ref="tbDettaglio" class="sa-tabs" content-class="mt-3" @activate-tab="onActiveTabs" fill>
        <b-tab title="Anagrafica">
          <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
              <accettazione-anagrafica-component :data="data" :link="indirizzoLink" @update="onUpdateData"></accettazione-anagrafica-component>
            </b-col>
          </b-row>
        </b-tab>
        <b-tab title="Prestazioni">
          <template>
            <b-form @submit.prevent="onSubmit">
              <b-row>
                <b-col md="3">
                  <span class="sa-form-label">Codice Nomenclatore</span>
                  <b-form-input v-model="filtro.codicePrestazione" placeholder="Codice Nomenclatore"></b-form-input>
                </b-col>
                <b-col md="3">
                  <span class="sa-form-label">Codice Catalogo</span>
                  <b-form-input v-model="filtro.codicePrestazioneAgg" placeholder="Codice Catalogo"></b-form-input>
                </b-col>
                <b-col md="3">
                  <span class="sa-form-label">Descrizione Nomenclatore</span>
                  <b-form-input v-model="filtro.descrizione" placeholder="Descrizione Nomenclatore"></b-form-input>
                </b-col>
                <b-col md="3">
                  <span class="sa-form-label">Descrizione Catalogo</span>
                  <b-form-input v-model="filtro.descrizioneAgg" placeholder="Descrizione Catalogo"></b-form-input>
                </b-col>
              </b-row>
              <b-row>
                <b-col class="sa-padding-right text-right">
                  <b-button type="submit" variant="info">{{ this.$i18n.t("global.lblFind") }}</b-button>
                  <b-button type="reset" v-on:click="onReset" variant="danger">{{ this.$i18n.t("global.lblResetFilter") }}</b-button>
                </b-col>
              </b-row>
              <br />
            </b-form>
            <b-row>
              <b-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                  <b-col lg="3">
                    <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
                  </b-col>
                  <b-col lg="9">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                  </b-col>
                </b-row>
                <!--<b-table striped hover :items="items"></b-table> -->
                <b-table sticky-header ref="tblNomenclatore" :filter="filter" responsive stacked="lg" striped hover :items="nomenclatore" :fields="fieldsNomenclatore" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                  <template v-slot:cell(descrizione)="{ item }">
                    <a>
                      <i>{{ item.descrizioneAgg }}</i> <br />
                      <hr />
                      {{ item.descrizione }} <b-link tabindex="0" v-b-popover.click.blur="onClickPopover(item)"> ({{ item.codiceBranca }}) </b-link> <br />
                      <hr />
                      <strong>Cod. </strong>{{ item.codicePrestazione }} <strong>Cod. Regionale </strong> {{ item.codicePrestazioneAgg }}
                    </a>
                  </template>
                  <template v-slot:cell(aggiungi)="row">
                    <b-button size="sm" class="mr-1 no-text" variant="outline-info" @click="onAggiungi(row.item, row.index, $event.target)">
                      <b-icon icon="plus-circle" variant="outline-primary" />
                    </b-button>
                  </template>
                </b-table>
              </b-col>
              <b-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                <b-table sticky-header ref="tblPrestazioni" :filter="filter" responsive stacked="lg" striped hover :items="data.accettazionePrestazioni" :fields="fieldsPrestazioni" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                  <template #cell(descrizione)="{ item }">
                    <a>
                      <b-form-select id="inline-form-custom-select-pref" v-model="item.idAmbulatorio" class="mb-2 mr-sm-2 mb-sm-0" :options="ambulatoriOptions" :value="null" value-field="value" text-field="text"></b-form-select>
                      <hr />
                      <i>{{ item.descrizioneAgg }}</i> <br />
                      <hr />
                      {{ item.descrizione }} ({{ item.codiceBranca }}) <br />
                      <hr />
                      <strong>Cod. </strong>{{ item.codicePrestazione }} <strong>Cod. Regionale </strong> {{ item.codicePrestazioneAgg }}
                    </a>
                  </template>
                  <template #cell(quantita)="{ item }">
                    {{ item.quantita }}
                  </template>
                  <template v-slot:cell(rimuovi)="row">
                    <b-button size="sm" class="mr-1 no-text" variant="btn btn-danger waves-effect waves-light width-sm no-text" @click="onDelete(row.item)">
                      <b-icon icon="trash" />
                    </b-button>
                  </template>
                </b-table>
              </b-col>
            </b-row>
          </template>

          <div class="b-table-sticky-header"></div>
        </b-tab>
      </b-tabs>
    </template>
  </sa-page-view-edit>
</template>
<script>
import axios from "axios";
import moment from "moment";
// import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import { SaPageViewEdit } from "../../../template/sa/components/layout/contents";
import AccettazioneAnagraficaComponent from "./AccettazioneAnagraficaComponent.vue";
export default {
  components: { SaPageViewEdit, AccettazioneAnagraficaComponent },
  data() {
    return {
      currentPage: 1,
      perPage: 100,
      tabIndex: 1,
      pathNomenclatore: "/nomenclatore",
      pathResource: "/accettazioni",
      linkback: null,
      linkedit: null,
      showmolalloading: false,
      filter: null,
      filtro: { descrizione: "", codicePrestazione: null, codicePrestazioneAgg: null, descrizioneAgg: "" },
      id: "-1",
      profili: [],
      ambulatoriOptions: [{ value: null, text: "-Selezionare una Struttura-" }],
      data: {
        accettazioneStruttura: { codiceStruttura: null },
        accettazioneAnagrafica: { codiceFiscale: "", provinciaNascita: null, comuneNascitaCodiceIstat: null, provinciaResidenza: null, comuneResidenzaCodiceIstat: null, provinciaDomicilio: null, comuneDomicilioCodiceIstat: null, capDomicilio: null, capResidenza: null, dataNascita: null, sesso: null, tipoIdentificativo: null },
        accettazionePrestazioni: [],
      },
      sessoOption: [
        { value: null, text: "-Sel. Sesso-" },
        { value: "M", text: "Maschio" },
        { value: "F", text: "Femmina" },
      ],
      nomenclatore: [],
      fieldsNomenclatore: [
        {
          label: "Descrizione Catalogo",
          key: "descrizione",
          sortable: true,
        },
        {
          label: "Note",
          key: "nota",
          sortable: true,
        },
        {
          label: "",
          key: "aggiungi",
        },
      ],
      fieldsPrestazioni: [
        {
          label: "Prestazione",
          key: "descrizione",
          sortable: true,
        },
        {
          label: "Q.tà",
          key: "quantita",
          type: "float",
          sortable: true,
        },
        {
          label: "Importo",
          key: "importoPrestazione",
          sortable: true,
        },
        {
          label: "",
          key: "rimuovi",
        },
      ],
    };
  },
  computed: {
    rows() {
      return this.nomenclatore.length;
    },
    indirizzoLink() {
      return process.env.VUE_APP_PATH_API + this.pathResource + "/";
    },
  },
  mounted: function() {
    let me = this;
    me.id = this.$route.params.id;
    if (me.id === "-1") {
      me.linkback = "/pazienteaccettazione";
    } else {
      me.linkback = "/pazienteaccettazione/view/" + me.id;
    }
  },
  methods: {
    onUpdateData(newValue, ambulatoriOptions) {
      this.data = newValue;
      this.ambulatoriOptions = ambulatoriOptions;
    },

    loadNomenclatore() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathNomenclatore;
      me.showmolalloading = true;
      axios
        .get(link, { params: me.filtro })
        .then((response) => {
          me.nomenclatore = [];
          me.nomenclatore = response.data.data;
          me.showmolalloading = false;
        })
        .catch(() => {
          me.showmolalloading = false;
        });
    },
    formatDate(value) {
      if (value) {
        return moment(new Date(value)).format("DD-MM-YYYY");
      } else {
        return "---";
      }
    },

    onActiveTabs() {
      // let me = this;
      // if (newTabIndex === 1) {
      //     me.loadNomenclatore();
      // }
    },
    onAggiungi(row) {
      let me = this;
      let exist = false;
      me.data.accettazionePrestazioni.forEach((element, index) => {
        if (element.codicePrestazioneAgg === row.codicePrestazioneAgg) {
          me.data.accettazionePrestazioni[index].quantita++;
          exist = true;
        }
      });
      if (!exist) {
        row.quantita = 1;
        me.data.accettazionePrestazioni.push(row);
      }
      this.$refs.tblPrestazioni.$forceUpdate();
    },
    onDelete(row) {
      let me = this;
      me.data.accettazionePrestazioni.forEach((element, index) => {
        if (element.codicePrestazioneAgg === row.codicePrestazioneAgg) {
          if (element.quantita > 1) {
            me.data.accettazionePrestazioni[index].quantita--;
          } else {
            me.data.accettazionePrestazioni.splice(index, 1);
          }
        }
      });

      this.$refs.tblPrestazioni.$forceUpdate();
    },
    onClickPopover(item) {
      return { title: "Branca Specialistica", content: "BRANCA: " + item.branca };
    },
    onReset() {
      let me = this;
      me.filtro = {};
      let sessionStorage = window.sessionStorage;
      sessionStorage["filtro"] = JSON.stringify(me.filtro.codiceSacca);
      me.loadNomenclatore();
    },
    onSubmit() {
      let me = this;
      let sessionStorage = window.sessionStorage;
      sessionStorage["filtro"] = JSON.stringify(me.filtro);
      me.loadNomenclatore();
    },
  },
};
</script>
