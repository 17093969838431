<template>
    <sa-page-list :titolo="titolo" :showmolalloading="showmolalloading">
        <template slot="toolbar">
            <b-button pill class="float-sm-right sa-btn-header sa-btn-icon-text" variant="light sa-margin-right" size="sm" @click="onAdd"><b-icon icon="plus" variant="secondary"></b-icon>Nuovo</b-button>
            <b-button pill class="float-sm-right sa-btn-header sa-btn-icon-text" variant="light sa-margin-right" size="sm" @click="onRefresh" v-b-modal.modal-center><b-icon icon="arrow-clockwise" variant="secondary" />Refresh</b-button>
        </template>
        <template slot="table-filter">
            <b-form @submit.prevent="onSubmit">
                <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                    <b-col lg="6">
                        <b-form-input v-model="filtro.nome" type="search" placeholder="Nome"></b-form-input>
                    </b-col>
                    <b-col lg="6">
                        <b-form-input v-model="filtro.descrizione" type="search" placeholder="Descrizione"></b-form-input>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="sa-padding-right text-right">
                        <b-button type="submit" variant="info">Cerca</b-button>
                    </b-col>
                </b-row>
            </b-form>
        </template>
        <template slot="table-header">
            <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col cols="6">
                    <p>Totale Record: {{ rows }}</p>
                </b-col>
                <b-col cols="6">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
            </b-row>
        </template>
        <template slot="table-body">
            <div class="b-table-sticky-header">
                <b-table sticky-header ref="table" id="tbl" :filter="filter" responsive stacked="lg" striped hover :items="items" :fields="fields" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                    <template v-slot:cell(nome)="{ item }">
                        <router-link class="sa-edit-link" :to="'/gruppi/view/' + item.id">{{ item.nome }}</router-link>
                    </template>
                    <template v-slot:cell(actions)="row">
                        <b-button size="sm" variant="outline-danger" @click="onDelete(row.item, row.index, $event.target)" class="mr-1 no-text">
                            <b-icon icon="trash" variant="danger"></b-icon>
                        </b-button>
                    </template>
                </b-table>
            </div>
        </template>
        <template slot="table-footer">
            <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col cols="6">
                    <p>Totale Record: {{ rows }}</p>
                </b-col>
                <b-col cols="6">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
            </b-row>
        </template>
    </sa-page-list>
</template>
<script>
import axios from "axios";
import { SaPageList } from "../../../template/sa/components/layout/contents";
export default {
    components: { SaPageList },
    computed: {
        rows() {
            return this.items.length;
        },
    },
    data() {
        return {
            titolo: "Gruppi Utenti",
            showmolalloading: false,
            perPage: 50,
            pathRESTRequest: "/gruppi",
            currentPage: 1,
            filter: null,
            filtro: {},
            filterOn: [],
            fields: [
                {
                    label: "Nome",
                    key: "nome",
                    sortable: true,
                },
                {
                    label: "Descrizione",
                    key: "descrizione",
                    sortable: true,
                },
                { key: "actions", label: "", thStyle: "width: 7rem", tdClass: "text-center" },
            ],
            items: [],
        };
    },
    created: function() {
        this.loadData();
    },
    methods: {
        loadData() {
            //console.log("OK");

            let me = this;
            me.showmolalloading = true;
            let link = process.env.VUE_APP_PATH_API + me.pathRESTRequest;
            axios.get(link).then((response) => {
                //console.log(response);
                me.items = response.data.data;
                me.showmolalloading = false;
            });
        },
        onAdd() {
            let me = this;
            me.$router.replace(me.pathRESTRequest + "/edit/-1").catch((err) => {
                err;
            });
        },
        onRefresh() {
            let me = this;
            me.loadData();
        },
        onDelete(item) {
            let me = this;
            this.$bvModal
                .msgBoxConfirm("Sicuro di voler Eliminare il gruppo " + item.nome)
                .then((value) => {
                    if (value) {
                        let link = process.env.VUE_APP_PATH_API + me.pathRESTRequest + "/";
                        axios.delete(link + item.id).then(() => {
                            me.loadData();
                        });
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        onSubmit() {
            console.log("OK Filtro");
            let me = this;
            me.filter = me.filtro.nome;
        },
    },
};
</script>
