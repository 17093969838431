import Gastro from "../";

const routes = [
  { name: "AnamnesiList", path: "/paziente/gastroenterologia/anamnesipatologica", component: Gastro.GastroAnamnesiPatologicaList, meta: { title: "Anamnesi Patologica Gastroenterologia" } },
  { name: "AnamnesiView", path: "/paziente/gastroenterologia/anamnesipatologica/view/:id", component: Gastro.GastroAnamnesiPatologicaView, meta: { title: "Anamnesi Patologica Gastroenterologia" } },
  { name: "AnamnesiEdit", path: "/paziente/gastroenterologia/anamnesipatologica/edit/:id", component: Gastro.GastroAnamnesiPatologicaEdit, meta: { title: "Anamnesi Patologica Gastroenterologia" } },

  { name: "AnamnesiList", path: "/paziente/gastroenterologia/anamnesifisiologica", component: Gastro.GastroAnamnesiFisiologicaList, meta: { title: "Anamnesi Fisiologica Gastroenterologia" } },
  { name: "AnamnesiView", path: "/paziente/gastroenterologia/anamnesifisiologica/view/:id", component: Gastro.GastroAnamnesiFisiologicaView, meta: { title: "Anamnesi Fisiologica Gastroenterologia" } },
  { name: "AnamnesiEdit", path: "/paziente/gastroenterologia/anamnesifisiologica/edit/:id", component: Gastro.GastroAnamnesiFisiologicaEdit, meta: { title: "Anamnesi Fisiologica Gastroenterologia" } },

  { name: "AnamnesiList", path: "/paziente/gastroenterologia/anamnesifamiliare", component: Gastro.GastroAnamnesiFamiliareList, meta: { title: "Anamnesi Familiare Gastroenterologia" } },
  { name: "AnamnesiView", path: "/paziente/gastroenterologia/anamnesifamiliare/view/:id", component: Gastro.GastroAnamnesiFamiliareView, meta: { title: "Anamnesi Familiare Gastroenterologia" } },
  { name: "AnamnesiEdit", path: "/paziente/gastroenterologia/anamnesifamiliare/edit/:id", component: Gastro.GastroAnamnesiFamiliareEdit, meta: { title: "Anamnesi Familiare Gastroenterologia" } },

  { name: "AnamnesiList", path: "/paziente/gastroenterologia/anorettocolonscopia", component: Gastro.GastroAnoRettoColonscopiaList, meta: { title: "Ano-Retto Colonscopia Gastroenterologia" } },
  { name: "AnamnesiView", path: "/paziente/gastroenterologia/anorettocolonscopia/view/:id", component: Gastro.GastroAnoRettoColonscopiaView, meta: { title: "Ano-Retto Colonscopia Gastroenterologia" } },
  { name: "AnamnesiEdit", path: "/paziente/gastroenterologia/anorettocolonscopia/edit/:id", component: Gastro.GastroAnoRettoColonscopiaEdit, meta: { title: "Ano-Retto Colonscopia Gastroenterologia" } },

  { name: "EgdsEdit", path: "/paziente/gastroenterologia/egds/edit/:id", component: Gastro.EgdsEdit, meta: { title: "Visita Egds" } },
  { name: "EgdsView", path: "/paziente/gastroenterologia/egds/view/:id", component: Gastro.EgdsView, meta: { title: "Visita Egds " } },
  { name: "EgdsList", path: "/paziente/gastroenterologia/egds", component: Gastro.EgdsList, meta: { title: "Visita Egds " } },

  { name: "ConfigEgdsStrumentoList", path: "/paziente/gastroenterologia/configurazioneegds/strumento", component: Gastro.ConfigSelectEgdsStrumentoList, meta: { title: "Configurazione Valori Strumento Egds " } },
  { name: "ConfigEgdsStrumentoEdit", path: "/paziente/gastroenterologia/configurazioneegds/strumento/edit/:id", component: Gastro.ConfigSelectEgdsStrumentoEdit, meta: { title: "Configurazione Strumento Egds" } },
  { name: "ConfigEgdsStrumentoView", path: "/paziente/gastroenterologia/configurazioneegds/strumento/view/:id", component: Gastro.ConfigSelectEgdsStrumentoView, meta: { title: "Configurazione Strumento Egds" } },

  {
    name: "ConfigEgdsLavaggioDisinfezioneEdit",
    path: "/paziente/gastroenterologia/configurazioneegds/lavaggiodisinfezione/edit/:id",
    component: Gastro.ConfigSelectEgdsLavaggioDisinfezioneEdit,
    meta: { title: "Configurazione Lavaggio/Disinfezione Egds" },
  },
  {
    name: "ConfigEgdsLavaggioDisinfezioneView",
    path: "/paziente/gastroenterologia/configurazioneegds/lavaggiodisinfezione/view/:id",
    component: Gastro.ConfigSelectEgdsLavaggioDisinfezioneView,
    meta: { title: "Configurazione Lavaggio/Disinfezione Egds" },
  },
  {
    name: "ConfigEgdsLavaggioDisinfezioneList",
    path: "/paziente/gastroenterologia/configurazioneegds/lavaggiodisinfezione",
    component: Gastro.ConfigSelectEgdsLavaggioDisinfezioneList,
    meta: { title: "Configurazione Valori Lavaggio/Disinfezione Egds " },
  },

  { name: "ConfigEgdsIndicazioneEdit", path: "/paziente/gastroenterologia/configurazioneegds/indicazione/edit/:id", component: Gastro.ConfigSelectEgdsIndicazioneEdit, meta: { title: "Configurazione Indicazione Egds" } },
  { name: "ConfigEgdsIndicazioneView", path: "/paziente/gastroenterologia/configurazioneegds/indicazione/view/:id", component: Gastro.ConfigSelectEgdsIndicazioneView, meta: { title: "Configurazione Indicazione Egds" } },
  { name: "ConfigEgdsIndicazioneList", path: "/paziente/gastroenterologia/configurazioneegds/indicazione", component: Gastro.ConfigSelectEgdsIndicazioneList, meta: { title: "Configurazione Valori Indicazione Egds " } },

  { name: "VisitaGastroenterologicaEdit", path: "/paziente/gastroenterologia/visitagastroenterologica/edit/:id", component: Gastro.VisitaGastroenterologicaEdit, meta: { title: "Visita Gastroenterologica " } },
  { name: "VisitaGastroenterologicaView", path: "/paziente/gastroenterologia/visitagastroenterologica/view/:id", component: Gastro.VisitaGastroenterologicaView, meta: { title: "Visita Gastroenterologica " } },
  { name: "VisitaGastroenterologicaList", path: "/paziente/gastroenterologia/visitagastroenterologica", component: Gastro.VisitaGastroenterologicaList, meta: { title: "Visita Gastroenterologica " } },

  { name: "EcografiaAddominaleEdit", path: "/paziente/gastroenterologia/ecografiaaddominale/edit/:id", component: Gastro.EcografiaAddominaleEdit, meta: { title: "Visita Gastroenterologica " } },
  { name: "EcografiaAddominaleView", path: "/paziente/gastroenterologia/ecografiaaddominale/view/:id", component: Gastro.EcografiaAddominaleView, meta: { title: "Visita Gastroenterologica " } },
  { name: "EcografiaAddominaleList", path: "/paziente/gastroenterologia/ecografiaaddominale", component: Gastro.EcografiaAddominaleList, meta: { title: "Visita Gastroenterologica " } },

  { name: "EcoendoscopiaEdit", path: "/paziente/gastroenterologia/ecoendoscopia/edit/:id", component: Gastro.EcoendoscopiaEdit, meta: { title: "Econendoscopia " } },
  { name: "EcoendoscopiaView", path: "/paziente/gastroenterologia/ecoendoscopia/view/:id", component: Gastro.EcoendoscopiaView, meta: { title: "Econendoscopia " } },
  { name: "EcoendoscopiaList", path: "/paziente/gastroenterologia/ecoendoscopia", component: Gastro.EcoendoscopiaList, meta: { title: "Econendoscopia " } },
];
export default routes;
