import "./assets/css/adminlte.css?v=1.2";
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import axios from "axios";
import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import VueSaTemplate from "./app/template/";
import i18n from "./i18n";
import CountryFlag from "vue-country-flag";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

axios.defaults.headers.common["Authorization"] = "Sisto";
axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
//axios.defaults.params = { language: i18n.locale };

Vue.use(VueSaTemplate);
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.component("country-flag", CountryFlag);
Vue.config.productionTip = false;

import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/js/all.js";
import "@/assets/css/theme.css";
//import "@/assets/css/custom.css?v=1.2";

Vue.prototype.$user = { nome: "Sisto Andolfi" };
Vue.filter("toCurrency", function(value) {
    if (typeof value !== "number") {
        return value;
    }
    var formatter = new Intl.NumberFormat("it-IT", {
        style: "currency",
        currency: "EUR",
        minimumFractionDigits: 0,
    });
    return formatter.format(value);
});
// Vue.filter("toCurrency", function(value) {
//     if (typeof value !== "number") {
//         return value;
//     }
//     var formatter = new Intl.NumberFormat("en-US", {
//         style: "currency",
//         currency: "USD",
//         minimumFractionDigits: 0,
//     });
//     return formatter.format(value);
// });
new Vue({
    i18n,
    router,
    mounted: function() {
        let messages = {
            paziente: { titolo: "Titolo di Prova" },
            teleconsulto: { lblTitle: "Questa è una prova di caricamento dinamico" },
        };
        let language = "it";
        let link = process.env.VUE_APP_PATH_API + "/traduora";
        axios.get(link).then((response) => {
            messages = response.data;
            //console.log(axios);
            this.$i18n.setLocaleMessage(language, messages);
        });
    },
    render: (h) => h(App),
}).$mount("#app");
