import { Line, mixins } from "vue-chartjs";
const { reactiveProp } = mixins;

export default {
  extends: Line,
  mixins: [reactiveProp],
  //props: ["options"],
  props: {
    options: { type: Object },
    backgroundColor: { type: Object },
    bgColor: { type: String },
    dotColor: { type: String },
    borderColor: { type: String },
    responsive: true,
    mantainAspectRatio: false,
    warnings: { type: Array },
    alerts: { type: Array },
  },
  mounted() {
    // this.chartData is created in the mixin.
    // If you want to pass options please create a local options object
    var horizonalLinePlugin = {
      id: "horizontalLine",
      afterDraw: function(chartInstance) {
        var yValue;
        var xValue;
        var yScale = chartInstance.scales["y-axis-0"];
        var xScale = chartInstance.scales["x-axis-0"];
        var canvas = chartInstance.chart;
        var ctx = canvas.ctx;
        var index;
        var line;
        var style;

        if (chartInstance.options.horizontalLine) {
          for (index = 0; index < chartInstance.options.horizontalLine.length; index++) {
            line = chartInstance.options.horizontalLine[index];

            if (line.y) {
              yValue = yScale.getPixelForValue(line.y);
            } else {
              yValue = 0;
            }
            if (line.x) {
              xValue = xScale.getPixelForValue(0);
            } else {
              xValue = 0;
            }
            ctx.lineWidth = 3;
            var width = xScale.width;

            if (yValue) {
              if (!line.style) {
                style = "#28a745";
              } else {
                style = line.style;
              }
              ctx.beginPath();
              ctx.strokeStyle = style;
              ctx.moveTo(xValue, yValue);
              ctx.lineTo(canvas.width, yValue);
              ctx.strokeRect(xScale.getPixelForValue(0), yValue, width, 0);
              // ctx.stroke();
            }

            if (line.text) {
              ctx.fillStyle = style;
              ctx.fillText(line.text, xScale.getPixelForValue(0), yValue - 10);
            }
          }
          return;
        }
      },
    };
    this.options.animation = {
      onProgress: function(animation) {
        this.progress = animation.animationObject.currentStep / animation.animationObject.numSteps;
      },
    };
    this.addPlugin(horizonalLinePlugin);
    this.renderChart(this.chartData, this.options);
  },
};
