<template>
    <div>
        <b-row><h4>Valori</h4></b-row>
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <b-row>
                    <b-col xs="4" sm="4" md="4" lg="4" xl="4">
                        <span class="sa-form-label">FVC (L/s)</span>
                        <b-form-input type="number" v-model="data.fvc" placeholder="FVC"></b-form-input>
                    </b-col>
                    <b-col xs="4" sm="4" md="4" lg="4" xl="4">
                        <span class="sa-form-label">FEV1 (L)</span>
                        <b-form-input type="number" v-model="data.fev1" placeholder="FEV1"></b-form-input>
                    </b-col>
                    <b-col xs="4" sm="4" md="4" lg="4" xl="4">
                        <span class="sa-form-label">FEV1 (L)</span>
                        <strong>FEV1/FVC% (%) </strong><br />
                        <b-form-input type="number" v-model="data.fev1Fvc" placeholder="FEV/FVC"></b-form-input>
                    </b-col>
                </b-row>
                <hr />
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <b-row>
                    <b-col xs="4" sm="4" md="4" lg="4" xl="4">
                        <span class="sa-form-label">PEF(L/s)</span>
                        <b-form-input type="number" v-model="data.pef" placeholder="PEF"></b-form-input>
                    </b-col>
                    <b-col xs="4" sm="4" md="4" lg="4" xl="4">
                        <span class="sa-form-label">FEF-25-75% (L/s) </span>
                        <b-form-input type="number" v-model="data.fef2575" placeholder="FEF-25-75%"></b-form-input>
                    </b-col>
                    <b-col xs="4" sm="4" md="4" lg="4" xl="4">
                        <span class="sa-form-label">MEF25% (L/s)</span>
                        <b-form-input type="number" v-model="data.mef25" placeholder="MEF25%"></b-form-input>
                    </b-col>
                </b-row>
                <hr />
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <b-row>
                    <b-col xs="4" sm="4" md="4" lg="4" xl="4">
                        <span class="sa-form-label">MEF50% (L/s)</span><br />
                        <b-form-input type="number" v-model="data.mef50" placeholder="MEF50%"></b-form-input>
                    </b-col>
                    <b-col xs="4" sm="4" md="4" lg="4" xl="4">
                        <span class="sa-form-label">MEF75% (L/s)</span><br />
                        <b-form-input type="number" v-model="data.mef75" placeholder="MEF75"></b-form-input>
                    </b-col>
                    <b-col xs="4" sm="4" md="4" lg="4" xl="4">
                        <span class="sa-form-label">MVV(L/m)</span><br />
                        <b-form-input type="number" v-model="data.mvv" placeholder="MVV"></b-form-input>
                    </b-col>
                </b-row>
                <hr />
            </b-col>
        </b-row>
        <br />
        <b-row><h4>Valori Normali</h4></b-row>
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <b-row>
                    <b-col xs="4" sm="4" md="4" lg="4" xl="4">
                        <span class="sa-form-label">FVC(L/m)</span><br />
                        <b-form-input type="number" v-model="data.fvcNormale" placeholder="FVC"></b-form-input>
                    </b-col>
                    <b-col xs="4" sm="4" md="4" lg="4" xl="4">
                        <span class="sa-form-label">FEV1(L)</span>
                        <b-form-input type="number" v-model="data.fev1Normale" placeholder="FEV1"></b-form-input>
                    </b-col>
                    <b-col xs="4" sm="4" md="4" lg="4" xl="4">
                        <span class="sa-form-label">FEV1/FVC% (%)</span>
                        <b-form-input type="number" v-model="data.fev1FvcNormale" placeholder="FVC"></b-form-input>
                    </b-col>
                </b-row>
                <hr />
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <b-row>
                    <b-col xs="4" sm="4" md="4" lg="4" xl="4">
                        <span class="sa-form-label">PEF(L/s)</span>
                        <b-form-input type="number" v-model="data.pefNormale" placeholder="PEF"></b-form-input>
                    </b-col>
                    <b-col xs="4" sm="4" md="4" lg="4" xl="4">
                        <span class="sa-form-label">FEF-25-75% (L/s) </span>
                        <b-form-input type="number" v-model="data.fef2575Normale" placeholder="FEF-25-75%"></b-form-input>
                    </b-col>
                    <b-col xs="4" sm="4" md="4" lg="4" xl="4">
                        <span class="sa-form-label">MEF25% (L/s)</span>
                        <b-form-input type="number" v-model="data.mef25Normale" placeholder="MEF25%"></b-form-input>
                    </b-col>
                </b-row>
                <hr />
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <b-row>
                    <b-col xs="4" sm="4" md="4" lg="4" xl="4">
                        <span class="sa-form-label">MEF50% (L/s) </span>
                        <b-form-input type="number" v-model="data.mef50Normale" placeholder="MEF50% "></b-form-input>
                    </b-col>
                    <b-col xs="4" sm="4" md="4" lg="4" xl="4">
                        <span class="sa-form-label">MEF75% (L/s) </span>
                        <b-form-input type="number" v-model="data.mef75Normale" placeholder="MEF75%"></b-form-input>
                    </b-col>
                    <b-col xs="4" sm="4" md="4" lg="4" xl="4">
                        <span class="sa-form-label">MVV(L/m)</span>
                        <b-form-input type="number" v-model="data.mvvNormale" placeholder="MVV"></b-form-input>
                    </b-col>
                </b-row>
                <hr />
            </b-col>
        </b-row>
        <br />
        <h4>Conclusioni:</h4>
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <b-form-textarea v-model="data.conclusioni" type="text" no-resize placeholder="Conclusioni"></b-form-textarea>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                        <strong>Stato </strong><br />
                        {{ data.firma }}
                    </b-col>
                </b-row>
                <hr />
            </b-col>
        </b-row>
    </div>
</template>

<script>
export default {
    props: {
        data: {
            Type: Object,
            default: function() {
                return {
                    fvc: null,
                    fev1: null,
                    fev1Fvc: null,
                    pef: null,
                    fef2575: null,
                    mef25: null,
                    mef50: null,
                    mef75: null,
                    mvv: null,
                    conclusioni: "",
                    firma: "",
                    /*Valori Nomali */

                    fvcNormale: null,
                    fev1Normale: null,
                    fev1FvcNormale: null,
                    pefNormale: null,
                    fef2575Normale: null,
                    mef25Normale: null,
                    mef50Normale: null,
                    mef75Normale: null,
                    mvvNormale: null,
                };
            },
        },
    },
};
</script>
