<template>
    <div>
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="6" xl="6">
                <h4>E.C.G. A RIPOSO</h4>
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
                        <strong>Freq. media </strong><br />
                        {{ data.frequenzaMediaRiposo }}
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
                        <strong>PR </strong><br />
                        {{ data.prRiposo }}
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
                        <strong>QT </strong><br />
                        {{ data.qtRiposo }}
                    </b-col>
                </b-row>
                <hr />
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                        <strong>Referto </strong><br />
                        {{ data.refertoRiposo }}
                    </b-col>
                </b-row>
                <hr />
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="12" lg="6" xl="6">
                <h4>E.C.G. DOPO SFORZO</h4>
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
                        <strong>Freq. media </strong><br />
                        {{ data.frequenzaMediaDopoSforzo }}
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
                        <strong>PR </strong><br />
                        {{ data.prDopoSforzo }}
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
                        <strong>QT </strong><br />
                        {{ data.qtDopoSforzo }}
                    </b-col>
                </b-row>
                <hr />
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                        <strong>Referto </strong><br />
                        {{ data.refertoDopoSforzo }}
                    </b-col>
                </b-row>
                <hr />
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="6" xl="6">
                <h4>INDICE RAPIDO DI IDONIETA'</h4>
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
                        <strong>Durata eser.(Sec.) </strong><br />
                        {{ data.durataEsercizio }}
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
                        <strong>Num. battiti da 60" a 90" </strong><br />
                        {{ data.numeroBattiti }}
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
                        <strong>I.R.I. </strong><br />
                        {{ data.iri }}
                    </b-col>
                </b-row>
                <hr />
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                        <strong>Stato </strong><br />
                        {{ data.firma }}
                    </b-col>
                </b-row>
                <hr />
            </b-col>
        </b-row>
    </div>
</template>
<script>
export default {
    props: {
        data: {
            Type: Object,
            default: function() {
                return {
                    frequenzaMediaRiposo: null,
                    prRiposo: null,
                    qtRiposo: null,
                    refertoRiposo: "",
                    frequenzaMediaDopoSforzo: null,
                    prDopoSforzo: null,
                    qtDopoSforzo: null,
                    refertoDopoSforzo: "",
                    durataEsercizio: null,
                    numeroBattiti: null,
                    iri: null,
                };
            },
        },
    },
};
</script>
