<template>
  <sa-page-layout :showModalLoading="showModalLoading" :linkedit="linkedit" :linkback="linkback" :btnBackVisible="true">
    <template slot="toolbar-title">
      <paziente-anagrafica :linkPatient="linkPatient" :idPaziente="idPaziente"></paziente-anagrafica>
    </template>
    <template slot="table-body">
      <paziente-sic-allergie-view-component :jsonData="jsonData"></paziente-sic-allergie-view-component>
    </template>
  </sa-page-layout>
</template>
<script>
import axios from "axios";
import SaPageLayout from "../../../../template/sa/components/layout/contents/SaPageLayout.vue";
import PazienteAnagrafica from "../../components/PazienteAnagrafica.vue";
import PazienteSicAllergieViewComponent from "./PazienteSicAllergieViewComponent.vue";
export default {
  components: { SaPageLayout, PazienteAnagrafica, PazienteSicAllergieViewComponent },
  props: {
    utente: {
      type: Object,
      default: function() {
        return null;
      },
    },
  },
  data() {
    return {
      pathResource: "/allergie",
      pagelink: "/paziente/sic/allergie",
      linkedit: "/paziente/sic/allergie",
      showModalLoading: false,
      jsonData: {},
      linkback: "",
    };
  },
  mounted: function() {
    let me = this;
    me.id = this.$route.params.id;
    me.jsonData.idPaziente = this.$route.query.idPaziente;
    me.linkedit = "/paziente/sic/allergie/edit/" + me.id + "?idPaziente=" + me.idPaziente;
    me.linkback = "/paziente/sic/allergie" + "?idPaziente=" + me.idPaziente;
    me.loadData();
  },
  computed: {
    idPaziente() {
      return this.$route.query.idPaziente;
    },
    linkPatient: {
      get: function() {
        let returnValue = "";
        if (this.utente.gruppo) {
          if (this.utente.gruppo.menuGruppo) {
            if (this.utente.gruppo.menuGruppo.length > 1) {
              return "/paziente/view/" + this.idPaziente;
            } else {
              if (this.utente.gruppo.menuGruppo.length == 1) {
                if (this.utente.gruppo.menuGruppo[0].primaryKey === "pazienti") {
                  return "/paziente/view/" + this.idPaziente;
                } else {
                  return "/paziente/homepaziente";
                }
              }
            }
          }
        }
        return returnValue;
      },
    },
  },
  methods: {
    loadData() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.id;
      me.showModalLoading = true;
      axios
        .get(link)
        .then((response) => {
          me.jsonData = response.data.data;
          me.showModalLoading = false;
        })
        .catch(() => {
          me.showModalLoading = false;
        });
    },
  },
};
</script>
