import HassistoIntegrator from "../";

const routes = [
    { name: "HassitoKitList", path: "/hassistokit", component: HassistoIntegrator.HassistoKitList, meta: { title: "Lista Kit" } },
    { name: "HassistoKitEdit", path: "/hassistokit/edit/:id", component: HassistoIntegrator.HassistoKitEdit, meta: { title: "Kits" } },
    { name: "HassistoHubList", path: "/hassistohub", component: HassistoIntegrator.HassistoHubList, meta: { title: "Lista Hub" } },
    { name: "HassitoDeviceList", path: "/hassistodevice", component: HassistoIntegrator.HassistoDeviceList, meta: { title: "Lista Device" } },
    { name: "HassitoDeviceEdit", path: "/hassistodevice/edit/:id", component: HassistoIntegrator.HassistoDeviceEdit, meta: { title: "Device" } },
];

export default routes;
