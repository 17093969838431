import moment from "moment";
export default {
    /*data() {
        return {
            showModalLoading: Boolean,
            show: true,
        };
    },*/
    methods: {
        formatDateTime(value) {
            if (value) {
                return moment(new Date(value)).format("DD-MM-YYYY HH:mm:ss");
            } else {
                return "---";
            }
        },
        formatDate(value) {
            if (value) {
                return moment(new Date(value)).format("DD/MM/YYYY");
            } else {
                return "---";
            }
        },
        formatTime(value) {
            if (value) {
                return moment(new Date(value)).format("HH:mm");
            } else {
                return "---";
            }
        },
        parseFormatDateTime(value, format) {
            if (value) {
                return moment(value, format).toDate();
            } else {
                return "---";
            }
        },
        verificaPermessi(utente, idSezione, permessoDaVerificare) {
            let returnValue = false;
            if (utente !== null) {
                let gruppo = utente.gruppo;
                if (gruppo) {
                    if (gruppo !== null) {
                        if (gruppo.permessi) {
                            gruppo.permessi.forEach((sezione) => {
                                if (sezione.id === idSezione) {
                                    sezione.permessi.forEach((permesso) => {
                                        if (permesso.permesso === permessoDaVerificare) {
                                            returnValue = permesso.attivo;
                                        }
                                    });
                                }
                            });
                        }
                    }
                }
            }
            return returnValue;
        },
    },
};
