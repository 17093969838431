<template>
  <sa-page-layout ref="pgaView" :data="data" :pathResource="pathResource" :btnAnnullaVisible="true" :btnSaveVisible="true" :linkback="linkback" :showModalLoading="showModalLoading">
    <template slot="toolbar-title">
      <paziente-anagrafica :linkPatient="linkPatient" :idPaziente="idPaziente"></paziente-anagrafica>
    </template>
    <template slot="toolbar-row">
      <paziente-dashboard-component :idPaziente="idPaziente"></paziente-dashboard-component>
    </template>
    <template slot="table-body">
      <div class="sa-tab-scroll">
        <b-row>
          <b-col cols="12" md="2" class="sa-text-right">{{ this.$i18n.t("therapy.lblDrug") }}:</b-col>
          <b-col cols="12" md="4">
            <b-input-group class="mb-3">
              <b-form-input maxlength="16" class="text-uppercase" v-model="data.farmaco" :placeholder="this.$i18n.t('therapy.lblDrug')" disabled></b-form-input>
              <b-button size="sm" variant="" v-b-modal.ricercaFarmaco>
                <b-icon icon="search"></b-icon>
              </b-button>
            </b-input-group>
            <span v-if="showCodici"><b>ATC: </b>{{ data.atcGmp }}</span>
          </b-col>
          <b-col cols="12" md="2" class="sa-text-right">{{ this.$i18n.t("therapy.lblQuantity") }}:</b-col>
          <b-col cols="12" md="4">
            <template>
              <b-input-group class="mb-3"> <b-form-spinbutton id="sb-step" v-model="data.quantita" min="0" max="1000000000" step="0.50"></b-form-spinbutton> </b-input-group>
            </template>
            <span v-if="showCodici"
              ><b>{{ this.$i18n.t("therapy.lblProductCode") }}:</b> {{ data.codiceProdotto }}</span
            >
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="2" class="sa-text-right">{{ this.$i18n.t("therapy.lblFrequency") }}:</b-col>
          <b-col cols="12" md="4">
            <b-form-select id="inline-form-custom-select-pref" v-model="data.frequenza" class="mb-2 mr-sm-2 mb-sm-0" value-field="value" text-field="text" :options="frequenzaItems" :value="null"></b-form-select>
          </b-col>

          <b-col cols="12" md="2" class="sa-text-right">{{ this.$i18n.t("therapy.lblPosology") }}:</b-col>
          <b-col cols="12" md="4">
            <b-form-select id="inline-form-custom-select-pref" v-model="data.posologia" class="mb-2 mr-sm-2 mb-sm-0" :options="posologiaItems" value-field="value" text-field="text" :value="null"></b-form-select>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="2" class="sa-text-right">{{ this.$i18n.t("therapy.lblStartDate") }}:</b-col>
          <b-col cols="12" md="4">
            <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="data.dataInizio" type="datetime"></date-picker>
          </b-col>
          <b-col cols="12" md="2" class="sa-text-right">{{ this.$i18n.t("therapy.lblEndDate") }}:</b-col>
          <b-col cols="12" md="4">
            <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="data.dataFine" type="datetime"></date-picker>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="2" class="sa-text-right">{{ this.$i18n.t("therapy.lblStatus") }}:</b-col>
          <b-col cols="12" md="">
            <!-- <b-form-input placeholder="Inserisci testo" v-model="data.stato"></b-form-input> -->
            <b-form-select id="inline-form-custom-select-pref" v-model="data.stato" class="mb-2 mr-sm-2 mb-sm-0" :options="statoOption" value-field="value" text-field="text" :value="null"></b-form-select>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="2" class="sa-text-right">{{ this.$i18n.t("therapy.lblNote") }}:</b-col>
          <b-col cols="12" md="10">
            <b-form-textarea id="textarea" v-model="data.note"></b-form-textarea>
          </b-col>
        </b-row>
        <hr v-if="showCodici" />
        <interazione-farmaci v-if="showCodici" :interazioneFarmaci="data" :key="componentKey"></interazione-farmaci>
      </div>
    </template>

    <template slot="table-footer">
      <b-modal id="ricercaFarmaco" scrollable title="Ricerca Farmaco" @show="onShow" @ok="onOk">
        <b-form @submit.prevent="onClick">
          <b-input-group class="mb-3">
            <b-form-input maxlength="16" class="text-uppercase" v-model="descrizioneFarmaco" :placeholder="this.$i18n.t('therapy.lblDrug')"></b-form-input>
            <b-button size="sm" variant="" v-b-modal.ricercaFarmaco @click="onClick">
              <b-icon icon="search"></b-icon>
            </b-button>
          </b-input-group>
          <!-- Ricerca per ATC -->
          <b-input-group class="mb-3">
            <b-form-input maxlength="16" class="text-uppercase" v-model="atcGmpFarmaco" placeholder="ATC"></b-form-input>
            <b-button size="sm" variant="" v-b-modal.ricercaFarmaco @click="onClick">
              <b-icon icon="search"></b-icon>
            </b-button>
          </b-input-group>
        </b-form>
        <b-table id="tabellaFarmaci" :items="tabellaFarmaci" :fields="farmaciFields" selectable select-mode="single" @row-selected="onRowSelected"> </b-table>
      </b-modal>
      <b-modal id="interazioneTrovata" title="ALERT" centered
        ><b-row class="text-center"
          ><b-col
            ><span>{{ this.$i18n.t("therapy.lblInteractionsTrue") }}</span></b-col
          ></b-row
        ></b-modal
      >
    </template>
  </sa-page-layout>
</template>
<script>
import axios from "axios";
import DatePicker from "vue2-datepicker";
import PazienteAnagrafica from "../../components/PazienteAnagrafica.vue";
import interazioneFarmaci from "../../components/farmaci/InterazioneFarmaciView.vue";
import PazienteDashboardComponent from "../../components/PazienteDashboardComponent.vue";
import SaPageLayout from "../../../../template/sa/components/layout/contents/SaPageLayout.vue";
export default {
  components: { PazienteAnagrafica, DatePicker, interazioneFarmaci, PazienteDashboardComponent, SaPageLayout },
  props: {
    utente: {
      type: Object,
      default: function() {
        return null;
      },
    },
  },
  data() {
    return {
      tabellaFarmaci: [],
      rigaSelezionata: null,
      descrizioneFarmaco: "",
      atcGmpFarmaco: "",
      id: { type: String },
      pathRestFarmaci: "/farmaci",
      pathResource: "/pazientiterapia",
      linkback: "/terapia",
      linkedit: null,
      showModalLoading: false,
      showCodici: false,
      componentKey: 0,
      farmaciFields: [
        {
          label: "Descrizione",
          key: "descrizione",
          sortable: true,
          thStyle: "width: 7rem",
        },
        {
          label: "ATC",
          key: "atcGmp",
          sortable: true,
          thStyle: "width: 7rem",
        },
      ],
      data: { farmaco: "", atcGmp: "", codiceProdotto: "", iterazioneFarmaco: {} },
      frequenzaItems: [],
      posologiaItems: [],
      statoOption: [
        { value: null, text: "--Seleziona Stato Terapia--" },
        { value: "ATTIVA", text: "ATTIVA" },
        { value: "SOSPESA", text: "SOSPESA" },
        { value: "TERMINATA", text: "TERMINATA" },
        { value: "ANNULLATA", text: "ANNULLATA" },
      ],
    };
  },
  computed: {
    idPaziente() {
      return this.$route.query.idPaziente;
    },
    linkPatient: {
      get: function() {
        let returnValue = "";
        if (this.utente.gruppo) {
          if (this.utente.gruppo.menuGruppo) {
            if (this.utente.gruppo.menuGruppo.length > 1) {
              return "/paziente/view/" + this.idPaziente;
            } else {
              if (this.utente.gruppo.menuGruppo.length == 1) {
                if (this.utente.gruppo.menuGruppo[0].primaryKey === "pazienti") {
                  return "/paziente/view/" + this.idPaziente;
                } else {
                  return "/paziente/homepaziente";
                }
              }
            }
          }
        }
        return returnValue;
      },
    },
  },
  mounted: function() {
    let me = this;
    me.id = this.$route.params.id;
    me.data.idPaziente = me.idPaziente;
    if (me.id === "-1") {
      me.linkback = "/paziente/terapia?idPaziente=" + me.idPaziente;
    } else {
      me.linkback = "/paziente/terapia/view/" + me.id + "?idPaziente=" + me.idPaziente;
    }
    this.loadPosologiaData();
    this.loadFrequenzaData();
    me.updateParent();
  },
  methods: {
    updateParent() {
      let me = this;
      document.addEventListener("interazioniTrue", function(e) {
        let check = e.detail;
        // console.log(check);
        if (check === true) {
          me.$bvModal.show("interazioneTrovata");
        }
      });
      document.addEventListener("takeInterazioni", function(e) {
        // console.log(e); // Prints "Example of an event"
        let interactions = e.detail;
        me.data.iterazioneFarmaco = JSON.stringify(interactions);
        me.showModalLoading = false;
      });
    },
    loadFrequenzaData() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + "/pazientiterapiefrequenza";
      me.showModalLoading = true;
      axios
        .get(link)
        .then((response) => {
          me.frequenzaItems = [];
          response.data.data.forEach((element) => {
            let option = { text: this.$i18n.t(element.frequenza), value: this.$i18n.t(element.frequenza) };
            me.frequenzaItems.push(option);
          });
          if (me.id !== "-1") {
            me.loadData();
          }
        })
        .catch(() => {});
    },
    loadPosologiaData() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + "/pazientiterapiaposologia";
      me.showModalLoading = true;
      axios
        .get(link)
        .then((response) => {
          me.posologiaItems = [];
          response.data.data.forEach((element) => {
            let option = { text: this.$i18n.t(element.posologia), value: this.$i18n.t(element.posologia) };
            me.posologiaItems.push(option);
          });
          me.showModalLoading = false;
        })
        .catch(() => {
          me.showModalLoading = false;
        });
    },
    loadData() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
      me.isBusy = true;
      me.showCodici = false;
      axios
        .get(link + me.id)
        .then((response) => {
          me.data = response.data.data;
          //me.$refs["SaPageList"].close();
        })
        .catch(() => {
          me.showModalLoading = false;
        });
    },
    onShow() {
      let me = this;
      me.tabellaFarmaci = [];
    },
    onOk() {
      let me = this;
      me.showCodici = false;
      me.showModalLoading = true;
      me.data.farmaco = me.rigaSelezionata.descrizione;
      me.data.atcGmp = me.rigaSelezionata.atcGmp;
      me.data.codiceProdotto = me.rigaSelezionata.codiceProdotto;
      me.componentKey += 1;
      me.showCodici = true;
    },
    onRowSelected(items) {
      if (items.length > 0) {
        this.rigaSelezionata = items[0];
      }
    },
    checkAttiva(id) {
      if (id === "-1") {
        return false;
      } else {
        return true;
      }
    },

    onClick() {
      let me = this;
      me.showModalLoading = true;
      let link = process.env.VUE_APP_PATH_API + me.pathRestFarmaci + "/";
      if (me.descrizioneFarmaco !== "") {
        axios
          .get(link, { params: { descrizione: me.descrizioneFarmaco, atcGmp: me.atcGmpFarmaco } })
          .then((response) => {
            me.tabellaFarmaci = response.data.data;
            me.showModalLoading = false;
          })
          .catch((error) => {
            me.showModalLoading = false;
            me.$bvModal
              .msgBoxOk(error.response.data.messaggio, {
                title: "ATTENZIONE",
                size: "sm",
                okVariant: "outline-danger",
                headerClass: "sa-msg-header-danger",
                footerClass: "p-2 border-top-0",
                centered: true,
              })
              .then((value) => {
                this.boxTwo = value;
              })
              .catch((err) => {
                console.log(err);
              });
          });
      } else {
        // console.log(me.atcGmpFarmaco);
        axios
          .get(link, { params: { atcGmp: me.atcGmpFarmaco } })
          .then((response) => {
            me.tabellaFarmaci = response.data.data;
            me.showModalLoading = false;
          })
          .catch((error) => {
            me.showModalLoading = false;
            me.$bvModal
              .msgBoxOk(error.response.data.messaggio, {
                title: "ATTENZIONE",
                size: "sm",
                okVariant: "outline-danger",
                headerClass: "sa-msg-header-danger",
                footerClass: "p-2 border-top-0",
                centered: true,
              })
              .then((value) => {
                this.boxTwo = value;
              })
              .catch((error) => {
                console.log(error);
              });
          });
      }
    },
  },
};
</script>
