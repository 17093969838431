<template>
    <div class="sa-page">
        <div class="content-header">
            <p></p>
        </div>
        <div class="content">
            <div class="card">
                <div class="card-header">
                    <h3 class="card-title">Lista Tamponi</h3>
                </div>
                <div class="card-body">
                    <div class="sa-table">
                        <div class="sa-table-filter">
                            <b-row>
                                <b-col sm="6" lg="6" class="my-1">
                                    <b-form-group label="" label-cols-sm="0" label-align-sm="right" label-size="sm" label-for="filterInput" class="mb-0">
                                        <b-input-group size="sm">
                                            <b-form-datepicker locale="it" @context="onDataRiferimentoContext" placeholder="Data Riferimento"></b-form-datepicker>
                                            <b-button @click="onScaricaExcell">Scarica Excel</b-button>
                                        </b-input-group>
                                    </b-form-group>
                                </b-col>
                                <b-col sm="6" lg="6" class="my-1 text-right"> </b-col>
                            </b-row>
                        </div>
                        <div class="sa-table-header">
                            <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                                <b-col lg="3">
                                    <p>Totale Record: {{ rows }}</p>
                                </b-col>
                                <b-col lg="9">
                                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" aria-controls="tblTamponi" align="right" size="sm"></b-pagination>
                                </b-col>
                            </b-row>
                        </div>
                        <div class="sa-table-body">
                            <b-table
                                id="tblTamponi"
                                :filter="filter"
                                :filterIncludedFields="filterOn"
                                responsive
                                stacked="lg"
                                striped
                                hover
                                :items="items"
                                :fields="fields"
                                :current-page="currentPage"
                                :per-page="perPage"
                                sort-icon-left
                            >
                            </b-table>
                        </div>
                        <div class="sa-table-footer">
                            <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                                <b-col lg="3">
                                    <p>Totale Record: {{ rows }}</p>
                                </b-col>
                                <b-col lg="9">
                                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" aria-controls="tblTamponi" align="right" size="sm"></b-pagination>
                                </b-col>
                            </b-row>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="content-footer">
            <p></p>
        </div>
    </div>
</template>
<script>
import axios from "axios";
export default {
    computed: {
        rows() {
            return this.items.length;
        },
    },
    data() {
        return {
            dataPrestazione: null,
            perPage: 10,
            pathRESTRequest: "/tamponi/protezionecivile",
            currentPage: 1,
            filter: null,
            filterOn: [],
            fields: [
                {
                    label: "Codice Fiscale",
                    key: "codiceFiscale",
                    sortable: true,
                },
                {
                    label: "Data",
                    key: "dataPrestazione",
                    sortable: true,
                },
                {
                    label: "Stato",
                    key: "statoAttuale",
                    sortable: true,
                },
            ],
            items: [],
        };
    },
    created: function() {
        this.loadData();
    },
    methods: {
        loadData() {},
        onDataRiferimentoContext(oEvent) {
            let dataRiferimento = oEvent.selectedYMD;
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathRESTRequest + "?dataPrestazione=" + dataRiferimento;
            me.dataPrestazione = dataRiferimento;
            axios.get(link).then((response) => {
                me.items = response.data.data;
            });
        },
        onScaricaExcell() {
            let me = this;
            if (me.dataPrestazione) {
                console.log(me.dataPrestazione);
                window.open(process.env.VUE_APP_PATH_API + "/tamponi/potezioneciviledownload" + "?dataPrestazione=" + me.dataPrestazione);
            }
            //windows.open(process.env.VUE_APP_PATH_API + me.pathRESTRequest + "?dataPrestazione=" + dataRiferimento);
        },
    },
};
</script>
