<template>
  <sa-page-layout :data="jsonData" :pathResource="pathResource" :btnAnnullaVisible="true" :btnSaveVisible="true" :linkback="linkback" :linkedit="linkedit" :showModalLoading="showModalLoading">
    <template slot="toolbar-title">
      <paziente-anagrafica :linkPatient="linkPatient" :idPaziente="idPaziente"></paziente-anagrafica>
    </template>
    <template slot="table-body">
      <div class="sa-tab-scroll">
        <b-row>
          <b-col cols="6">
            <strong>Data Rilevazione</strong>
            <date-picker class="datepicker" format="DD-MM-YYYY" value-type="timestamp" :clearable="false" v-model="jsonData.data" type="timestamp"></date-picker>
          </b-col>
          <b-col cols="6">
            <strong>Farmaco</strong>
            <b-input-group class="mb-3">
              <b-form-input v-model="jsonData.farmaco" disabled></b-form-input>
              <b-input-group-append>
                <b-button size="sm" variant="secondary" v-b-modal.modalFarmaco><b-icon icon="search"></b-icon></b-button>
              </b-input-group-append>
            </b-input-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6">
            <strong>Tipo Rilevazione</strong>
            <b-form-select v-model="jsonData.tipoReazione" :options="tipoRilevazioneOptions" :value="null" value-field="value" text-field="descrizione"></b-form-select>
          </b-col>
          <b-col cols="6">
            <strong>Reazione</strong>
            <b-form-select v-model="jsonData.reazione" :options="reazioneOptions" :value="null" value-field="value" text-field="descrizione"></b-form-select>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6">
            <strong>Gravità Reazione</strong>
            <b-form-select v-model="jsonData.gravitaReazione" :options="gravitaReazioneOptions" :value="null" text-field="descrizioneGravitaReazione" value-field="value"></b-form-select>
          </b-col>
          <b-col cols="6">
            <strong>Stato Osservazione</strong>
            <b-form-select v-model="jsonData.statoOsservazione" :value="null" value-field="value" text-field="descrizione" :options="statoOsservazioneOptions"></b-form-select>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <strong>Note</strong>
            <b-form-textarea class="form-control" v-model="jsonData.note" rows="5" no-resize></b-form-textarea>
          </b-col>
        </b-row>
      </div>
    </template>
    <template slot="table-footer">
      <b-modal id="modalFarmaco" title="Farmaco" size="md" hide-footer v-model="showmodalFarmaco">
        <b-form @submit.prevent="onClick">
          <b-input-group class="mb-3">
            <b-form-input maxlength="16" class="text-uppercase" v-model="descrizioneFarmaco" placeholder="FARMACO"></b-form-input>
            <b-input-group-append>
              <b-button size="sm" variant="" v-b-modal.modalFarmaco @click="onClick">
                <b-icon icon="search"></b-icon>
              </b-button>
            </b-input-group-append>
          </b-input-group>
          <!-- Ricerca per ATC -->
          <b-input-group class="mb-3">
            <b-form-input maxlength="16" class="text-uppercase" v-model="atcGmpFarmaco" placeholder="ATC"></b-form-input>
            <b-input-group-append>
              <b-button size="sm" variant="" v-b-modal.modalFarmaco @click="onClick">
                <b-icon icon="search"></b-icon>
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form>
        <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
          <b-col lg="3">
            <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
          </b-col>
          <b-col lg="9">
            <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
          </b-col>
        </b-row>
        <b-table
          selectable
          select-mode="single"
          @row-selected="onRowSelected"
          sticky-header
          ref="table"
          stacked="l"
          striped
          hover
          :items="listaFarmaco"
          :fields="fieldsFarmaco"
          :current-page="currentPage"
          :per-page="perPage"
          sort-icon-left
          head-variant="light"
          class="sa-b-table"
        >
        </b-table>
        <template #cell(selected)="{ rowSelected }">
          <template v-if="rowSelected">
            <span aria-hidden="true">&check;</span>
            <span class="sr-only">Selected</span>
          </template>
          <template v-else>
            <span aria-hidden="true">&nbsp;</span>
            <span class="sr-only">Not selected</span>
          </template>
        </template>
        <div class="sa-padding-right text-right col">
          <b-button size="sm" variant="danger" @click="showmodalFarmaco = false">
            Cancel
          </b-button>
          <b-button size="sm" variant="secondary" @click="onAggiungiFarmaco">
            Aggiungi
          </b-button>
        </div>
      </b-modal>
    </template>
  </sa-page-layout>
</template>
<script>
import axios from "axios";
import DatePicker from "vue2-datepicker";
import { SaPageLayout } from "../../../../template/sa/components/layout/contents";
import PazienteAnagrafica from "../../../paziente/components/PazienteAnagrafica.vue";
export default {
  components: { PazienteAnagrafica, SaPageLayout, DatePicker },
  props: {
    utente: {
      type: Object,
      default: function() {
        return null;
      },
    },
  },
  data() {
    return {
      id: -1,
      pathResource: "/allergie",
      linkback: "/paziente/sic/allergie",
      pathResourceTipoReazione: "/tiporeazioni",
      pathResourceGravitaReazione: "/gravitareazioni",
      pathRestFarmaci: "/farmaci",
      pathResourceReazione: "/reazioni",
      descrizioneFarmaco: "",
      atcGmpFarmaco: "",
      linkedit: null,
      jsonData: { tipoReazione: null, reazione: null, gravitaReazione: null, statoOsservazione: null, codiceAtc: "" },
      perPage: 9,
      currentPage: 1,
      listaFarmaco: [],
      showmodalFarmaco: false,
      showModalLoading: false,
      selected: {},
      statoOsservazioneOptions: [
        { value: null, descrizione: "-Seleziona Valore-" },
        { value: "NON RILEVATO", descrizione: "NON RILEVATO" },
        { value: "ATTIVA", descrizione: "ATTIVA" },
        { value: "CHIUSA", descrizione: "CHIUSA" },
        { value: "NUOVA", descrizione: "NUOVA" },
      ],
      gravitaReazioneOptions: [{ value: null, descrizioneGravitaReazione: "-Seleziona Valore-" }],
      tipoRilevazioneOptions: [{ value: null, descrizione: "-Seleziona Valore-" }],
      reazioneOptions: [{ value: null, descrizione: "-Seleziona Reazione-" }],
      fieldsFarmaco: [
        {
          label: "",
          key: "selected",
          selection: true,
          thStyle: "width: 2rem",
        },
        {
          label: "Descrizione",
          key: "descrizione",
          sortable: true,
          thStyle: "width: 7rem",
        },
        {
          label: "ATC",
          key: "atcGmp",
          sortable: true,
          thStyle: "width: 7rem",
        },
      ],
    };
  },
  computed: {
    idPaziente() {
      return this.$route.query.idPaziente;
    },
    linkPatient: {
      get: function() {
        let returnValue = "";
        if (this.utente.gruppo) {
          if (this.utente.gruppo.menuGruppo) {
            if (this.utente.gruppo.menuGruppo.length > 1) {
              return "/paziente/view/" + this.idPaziente;
            } else {
              if (this.utente.gruppo.menuGruppo.length == 1) {
                if (this.utente.gruppo.menuGruppo[0].primaryKey === "pazienti") {
                  return "/paziente/view/" + this.idPaziente;
                } else {
                  return "/paziente/homepaziente";
                }
              }
            }
          }
        }
        return returnValue;
      },
    },
    rows() {
      return this.listaFarmaco.length;
    },
  },
  mounted: function() {
    let me = this;
    me.id = this.$route.params.id;
    me.jsonData.idAnagrafica = me.idPaziente;
    me.jsonData.data = Date.now();
    me.linkback = "/paziente/sic/allergie?idPaziente=" + me.idPaziente;
    console.log(me.jsonData);
    me.loadTipiRilevazioni();
    me.loadReazioni();
    me.loadGravitaReazioni();
  },
  methods: {
    loadTipiRilevazioni() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResourceTipoReazione;
      axios.get(link).then((response) => {
        response.data.data.forEach((element) => {
          me.tipoRilevazioneOptions.push({ value: element.descrizione, descrizione: element.descrizione });
        });
      });
    },
    loadReazioni() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResourceReazione;
      axios.get(link).then((response) => {
        response.data.data.forEach((element) => {
          me.reazioneOptions.push({ value: element.descrizione, descrizione: element.descrizione });
        });
      });
    },
    loadGravitaReazioni() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResourceGravitaReazione;
      axios.get(link).then((response) => {
        response.data.data.forEach((element) => {
          me.gravitaReazioneOptions.push({ value: element.descrizioneGravitaReazione, descrizioneGravitaReazione: element.descrizioneGravitaReazione });
        });
      });
    },
    onRowSelected(items) {
      let me = this;
      me.selected = items;
      console.log(items);
    },
    onAggiungiFarmaco() {
      let me = this;
      me.showmodalFarmaco = false;
      me.jsonData.farmaco = me.selected[0].descrizione;
    },
    onClick() {
      let me = this;
      me.showModalLoading = true;
      let link = process.env.VUE_APP_PATH_API + me.pathRestFarmaci + "/";
      if (me.descrizioneFarmaco !== "") {
        axios
          .get(link, { params: { descrizione: me.descrizioneFarmaco, atcGmp: me.atcGmpFarmaco } })
          .then((response) => {
            me.listaFarmaco = response.data.data;
            me.showModalLoading = false;
          })
          .catch((error) => {
            me.showModalLoading = false;
            me.$bvModal
              .msgBoxOk(error.response.data.messaggio, {
                title: "ATTENZIONE",
                size: "sm",
                okVariant: "outline-danger",
                headerClass: "sa-msg-header-danger",
                footerClass: "p-2 border-top-0",
                centered: true,
              })
              .then((value) => {
                this.boxTwo = value;
              })
              .catch((err) => {
                console.log(err);
              });
          });
      } else {
        axios
          .get(link, { params: { atcGmp: me.atcGmpFarmaco } })
          .then((response) => {
            me.listaFarmaco = response.data.data;
            me.showModalLoading = false;
          })
          .catch((error) => {
            me.showModalLoading = false;
            me.$bvModal
              .msgBoxOk(error.response.data.messaggio, {
                title: "ATTENZIONE",
                size: "sm",
                okVariant: "outline-danger",
                headerClass: "sa-msg-header-danger",
                footerClass: "p-2 border-top-0",
                centered: true,
              })
              .then((value) => {
                this.boxTwo = value;
              })
              .catch((error) => {
                console.log(error);
              });
          });
      }
    },
  },
};
</script>
<style></style>
