<template>
  <sa-page-view-edit-paziente ref="pgaView" :data="data" :pathResource="pathResource" :backVisible="true" :saveVisible="true" :linkback="linkback" :linkedit="linkedit" :showmolalloading="showmolalloading">
    <template slot="toolbar-title">
      <paziente-anagrafica :linkPatient="linkPatient" :idPaziente="idPaziente"></paziente-anagrafica>
    </template>
    <template slot="body">
      <div>
        <div class="sa-form-section">
          <h5 class="sa-text-left">{{ this.$i18n.t("patient.events.anamnesis.lblAnamnesis") }}</h5>
          <b-row>
            <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
              <span class="sa-form-label">{{ this.$i18n.t("patient.events.lblFrom") }}</span>
              <b-form-select id="inline-form-custom-select-pref" v-model="data.provenienza" class="mb-2 mr-sm-2 mb-sm-0" :options="provenienzaOption" :value="null"></b-form-select>
            </b-col>
            <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
              <span class="sa-form-label">{{ this.$i18n.t("patient.events.lblEventDate") }}</span>
              <date-picker format="DD-MM-YYYY HH:mm" value-type="timestamp" v-model="data.dataEvento" type="datetime" :placeholder="dataEventoPlaceholder"></date-picker>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
              <span class="sa-form-label">{{ this.$i18n.t("patient.events.anamnesis.lblRemotePathologicalAnamnesis") }}</span>
              <b-form-textarea v-model="data.anamnesiPatologicaRemota"></b-form-textarea>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
              <span class="sa-form-label">{{ this.$i18n.t("patient.events.anamnesis.lblNextPathologicalAnamnesis") }}</span>
              <b-form-textarea v-model="data.anamnesiPatologicaProssima"></b-form-textarea>
            </b-col>

            <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4"> </b-col>
          </b-row>
        </div>
      </div>
    </template>
  </sa-page-view-edit-paziente>
</template>
<script>
import axios from "axios";
import DatePicker from "vue2-datepicker";
import moment from "moment";
import "vue2-datepicker/index.css";
import PazienteAnagrafica from "./PazienteAnagrafica.vue";
import SaPageViewEditPaziente from "../../../template/sa/components/layout/contents/SaPageViewEditPaziente.vue";
export default {
  props: {
    utente: {
      type: Object,
      default: function() {
        return null;
      },
    },
  },
  components: { SaPageViewEditPaziente, DatePicker, PazienteAnagrafica },
  data() {
    return {
      pathResource: "/pazienteeventoanamnesipatologica",
      pathResourceProvenienza: "/pazienteeventiprovenienza",
      linkback: "/paziente/anamnesi?sisto=andolfi",
      linkedit: null,
      showmolalloading: false,
      file1: null,
      id: "-1",
      profili: [],
      data: {},
      provenienzaOption: [{ value: null, text: this.$i18n.t("patient.events.lblSelectFrom") }],
    };
  },
  computed: {
    idPaziente() {
      return this.$route.query.idPaziente;
    },
    dataEventoPlaceholder() {
      return this.$i18n.t("patient.events.lblEventDate");
    },
    linkPatient: {
      get: function() {
        if (this.utente.gruppo.menuGruppo) {
          if (this.utente.gruppo.menuGruppo.length > 1) {
            return "view/" + this.idPaziente;
          } else {
            if (this.utente.gruppo.menuGruppo.length == 1) {
              if (this.utente.gruppo.menuGruppo[0].primaryKey === "pazienti") {
                return "view/" + this.idPaziente;
              } else {
                return "homepaziente";
              }
            } else {
              return "";
            }
          }
        } else {
          return "";
        }
      },
    },
  },
  mounted: function() {
    let me = this;
    me.id = this.$route.params.id;
    me.data.idPaziente = this.$route.query.idPaziente;
    me.linkback = "/paziente/anamnesi?idPaziente=" + me.data.idPaziente;
    if (me.id === "-1") {
      me.linkback = "/paziente/anamnesi?idPaziente=" + me.idPaziente;
    } else {
      me.linkback = "/paziente/anamnesi/view/" + me.id + "?idPaziente=" + me.idPaziente;
    }
    me.loadDefaultData();
  },
  methods: {
    formatDate(value) {
      if (value) {
        return moment(new Date(value)).format("DD-MM-YYYY HH:mm:ss");
      } else {
        return "---";
      }
    },
    loadDefaultData() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResourceProvenienza;
      axios.get(link).then((response) => {
        response.data.data.forEach((element) => {
          me.provenienzaOption.push({ value: element.provenienza, text: element.provenienza });
        });
      });
      me.loadData();
    },
    loadData() {
      let me = this;
      if (me.id === "-1") {
        me.showmolalloading = false;
      } else {
        me.showmolalloading = true;
        let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
        axios.get(link + me.id).then((response) => {
          me.data = response.data.data;
          me.showmolalloading = false;
        });
      }
    },
    onBack() {
      console.log("Indietro");
    },
    onSave() {
      console.log("SALVA");
    },
  },
};
</script>
