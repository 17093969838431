<template :showmolalloading="showmolalloading">
    <sa-page-list>
        <template slot="toolbar">
            <b-button class="float-sm-right " variant="success btn-toolbar sa-margin-right" size="sm" @click="onAdd"><b-icon icon="plus" variant="secondary"></b-icon>{{ this.$i18n.t("global.lblBtnNew") }}</b-button>
            <b-button class="float-sm-right " variant="info btn-toolbar" size="sm" @click="onRefresh" v-b-modal.modal-center><b-icon icon="arrow-clockwise" variant="secondary" />Refresh</b-button>
        </template>
        <template slot="table-filter">
            <b-form @submit.prevent="onSubmit">
                <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                    <b-col sm="4">
                        <b-form-input v-model="filtro.numero" type="search" id="filterInput" placeholder="Numero Accettazione"></b-form-input>
                    </b-col>
                    <b-col sm="4">
                        <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="filtro.dataDal" type="datetime" placeholder="Data Dal"></date-picker>
                    </b-col>
                    <b-col sm="4">
                        <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="filtro.dataAl" type="datetime" placeholder="Data Al"></date-picker>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="sa-padding-right text-right">
                        <b-button type="submit" variant="info">{{ this.$i18n.t("global.lblFind") }}</b-button>
                        <b-button type="reset" v-on:click="onReset" variant="danger">{{ this.$i18n.t("global.lblResetFilter") }}</b-button>
                    </b-col>
                </b-row>
            </b-form>
        </template>
        <template slot="table-header">
            <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col cols="6">
                    <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
                </b-col>
                <b-col cols="6">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
            </b-row>
        </template>
        <template slot="table-body">
            <div class="b-table-sticky-header">
                <!--<b-table striped hover :items="items"></b-table> -->
                <b-table sticky-header ref="table" id="tbl" :filter="filter" responsive stacked="lg" striped hover :items="items" :fields="fields" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                    <template v-slot:cell(numero)="{ item }">
                        <router-link class="sa-edit-link" :to="'/pazienteaccettazione/view/' + item.id">{{ item.numero }}</router-link>
                    </template>
                    <template v-slot:cell(statoAttuale)="{ item }">
                        <a>{{item.statoAttuale}} ({{formatDate(item.statoAttualeDataOra)}})</a>
                    </template>
                </b-table>
            </div>
        </template>
        <template slot="table-footer">
            <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col cols="6">
                    <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
                </b-col>
                <b-col cols="6">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
            </b-row>
        </template>
    </sa-page-list>
</template>
<script>
import { SaPageList } from "../../../template/sa/components/layout/contents";
import moment from "moment";
import axios from "axios";
import DatePicker from "vue2-datepicker";
export default {
    components: { SaPageList, DatePicker },
    computed: {
        rows() {
            return this.items.length;
        },
    },
    data() {
        return {
            showmolalloading: false,
            pagelink: "/pazienteaccettazione",
            pathResource: "/accettazioni",
            currentPage: 1,
            perPage: 20,
            filter: null,
            filterOn: [],
            fields: [
                {
                    label: "Numero", //this.$i18n.t("agende.lblDiary"),
                    key: "numero",
                    sortable: true,
                    thStyle: "width: 50rem",
                },
                {
                    label: "Data", // this.$i18n.t("agende.lblDescription"),
                    key: "createDate",
                    formatter: (value) => {
                        return moment(new Date(value)).format("DD-MM-YYYY");
                    },
                    thStyle: "width: 50rem",
                    sortable: true,
                },
                {
                    label: "Stato Attuale",
                    key: "statoAttuale",
                    sortable: true,
                    thStyle: "width: 50rem",
                },
                { class: "sa-table-column-action-single", key: "actions", label: "" },
            ],
            
            filtro: {numero: "", dataDal: null, dataAl: null},
            items: [],
            data: [],
        };
    },
    created: function() {
        this.loadData();
    },
    methods: {
        loadData() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource;
            me.showmolalloading = true;
            axios
                .get(link, { params: me.filtro })
                .then((response) => {
                    me.items = [];
                    me.items = response.data.data;
                    me.showmolalloading = false;
                })
                .catch(function(error) {
                    me.showmolalloading = false;
                    me.$bvToast.toast(error.response.data.messaggio, {
                        title: "Sorveglianza Covid Admin",
                        variant: "danger",
                        solid: true,
                    });
                });
        },
        onEdit(item) {
            let me = this;
            me.$router.replace(me.pagelink + "/edit/" + item.id).catch((err) => {
                err;
            });
        },
                formatDate(value) {
            if (value) {
                return moment(new Date(value)).format("DD-MM-YYYY HH:mm");
            } else {
                return "---";
            }
        },
        onView(item) {
            let me = this;
            me.$router.replace(me.pagelink + "/view/" + item.id).catch((err) => {
                err;
            });
        },
        onReset() {
            let me = this;
            me.filtro = {};
            let sessionStorage = window.sessionStorage;
            sessionStorage["filtro"] = JSON.stringify(me.filtro);
            me.loadData();
        },
        onAdd() {
            let me = this;
            me.$router.replace(me.pagelink + "/edit/-1").catch((err) => {
                err;
            });
        },
        onRefresh() {
            let me = this;
            me.loadData();
        },
        onDelete(item) {
            console.log(item);
        },
        onSubmit: function() {
            let me = this;
            let sessionStorage = window.sessionStorage;
            sessionStorage["filtro"] = JSON.stringify(me.filtro);
            me.loadData();
        },
    },
};
</script>
