<template>
    <sa-page-view-edit ref="pgaView" :backVisible="true" :editVisible="false" :titolo="data.nomeCsv" :linkback="pagelink" :linkedit="linkedit" :showmolalloading="showmolalloading">
        <template slot="filter">
            <div style="max-height: 18rem; overflow: auto;">
                <b-table responsive striped hover :items="tamponeStato" :fields="fieldsStatoTampone" head-variant="light" sort-icon-left>
                    <!--<template v-slot:cell(createDate)="{ item }">
                        <div>
                            <span>{{ item.createDate | moment("YYYY-MM-DD HH:mm:ss") }}</span>
                        </div>
                    </template>-->
                    <template v-slot:cell(stato)="{ item }">
                        <div>
                            <span class="sa-text-bold">{{ item.stato }}</span>
                        </div>
                    </template>
                    <template v-slot:cell(log)="{ item }">
                        <div>
                            <span>{{ item.log }}</span>
                        </div>
                    </template>
                    <template v-slot:cell(jsonTampone)="{ item }">
                        <div>
                            <span>{{ item.jsonTampone }}</span>
                        </div>
                    </template>
                </b-table>
            </div>
        </template>

        <template slot="body">
            <div style="display: flex; height: 100%;flex-direction: column;">
                <div style="max-height: 10rem"></div>
                <div style="height: 100%">
                    <div class="b-table-sticky-header">
                        <b-table responsive striped hover :items="datiDettaglioTampone" :fields="fieldsTamponi" head-variant="light" sort-icon-left>
                            <template v-slot:cell(key)="{ item }">
                                <div>
                                    <span class="sa-text-bold">{{ item.key }}</span>
                                </div>
                            </template>
                            <template v-slot:cell(value)="{ item }">
                                <div>
                                    <span>{{ item.value }}</span>
                                </div>
                            </template>
                        </b-table>
                    </div>
                </div>
            </div>
        </template>
        <template slot="footer"> </template>
    </sa-page-view-edit>
</template>
<script>
import axios from "axios";
import moment from "moment";
import { SaPageViewEdit } from "../../../template/sa/components/layout/contents";
export default {
    components: { SaPageViewEdit },
    label: "Dettagllio Tampone",
    data() {
        return {
            showmolalloading: false,
            pagelink: "/tamponi",
            linkedit: "",
            pathResource: "/elaborazioniames",
            datiDettaglioTampone: [],
            tamponeStato: [],
            tampone: { nome: "", menu: [], sezioni: [] },
            label: "Dettagllio Tamponi",
            pathRESTRequest: "/tamponi",
            filter: null,
            filterOn: [],
            data: {},
            fieldsStatoTampone: [
                {
                    label: "Data",
                    key: "createDate",
                    sortable: false,
                    formatter: (value) => {
                        if (!value) {
                            return "";
                        } else {
                            return moment(new Date(value)).format("DD-MM-YYYY HH:mm:ss");
                        }
                    },
                },
                {
                    label: "Stato",
                    key: "stato",
                    sortable: false,
                },
                {
                    label: "Log",
                    key: "log",
                    sortable: false,
                },
                {
                    label: "JSON",
                    key: "jsonTampone",
                    sortable: false,
                },
            ],
            fieldsTamponi: [
                {
                    label: "Chiave",
                    key: "key",
                    sortable: false,
                },
                {
                    label: "Valore",
                    key: "value",
                    sortable: false,
                },
                {
                    key: "selected",
                    label: "",
                    sortable: false,
                    class: "sa-width-2 sa-td-vertical-middle",
                },
            ],
        };
    },
    mounted: function() {
        let me = this;
        me.loadData(this.$route.params.id);
    },
    methods: {
        verificaPermessoSezione(permessoSezione, permessi) {
            let returnValue = [];
            permessi.forEach((permesso) => {
                if (permesso.valore & permessoSezione) {
                    returnValue.push(permesso);
                }
            });

            return returnValue;
        },
        loadData(id) {
            //console.log("K");
            let me = this;
            me.showmolalloading = true;
            let link = process.env.VUE_APP_PATH_API + me.pathRESTRequest + "/";
            axios.get(link + id).then((response) => {
                me.tampone = response.data.data;
                for (let element in me.tampone) {
                    me.datiDettaglioTampone.push({
                        key: element,
                        value: me.tampone[element],
                    });
                }
                me.showmolalloading = false;
            });
            this.loadTamponeStato(id);
        },
        loadTamponeStato(id) {
            //console.log("tampstato");
            let me = this;
            me.showmolalloading = true;
            let link = process.env.VUE_APP_PATH_API + me.pathRESTRequest + "stato/?idTampone=";
            axios.get(link + id).then((response) => {
                me.tamponeStato = response.data.data;
                me.showmolalloading = false;
                //console.log(me.tamponeStato);
            });
        },
        onSubmit() {
            return 0;
        },
        onReset() {
            return 0;
        },
        onEdit() {
            let me = this;
            console.log(me.pathRESTRequest);
            let link = me.pathRESTRequest + "/edit/" + me.tampone.primaryKey;
            console.log(link);
            me.$router.replace(link).catch((err) => {
                console.log(link);
                err;
            });
        },
        onBack() {
            let me = this;
            me.$router.replace(me.pathRESTRequest).catch((err) => {
                err;
            });
        },
    },
};
</script>
<style>
.sa-text-right {
    text-align: right;
}
@media only screen and (max-width: 767px) {
    .sa-text-right {
        text-align: left;
    }
}
.card {
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.125), 0 1px 3px rgba(0, 0, 0, 0.2);
    margin-bottom: 1rem;
}
</style>
