import { render, staticRenderFns } from "./AgendaModalSpostaAppuntamento.vue?vue&type=template&id=050efe4a&"
import script from "./AgendaModalSpostaAppuntamento.vue?vue&type=script&lang=js&"
export * from "./AgendaModalSpostaAppuntamento.vue?vue&type=script&lang=js&"
import style0 from "./AgendaModalSpostaAppuntamento.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports