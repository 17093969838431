<template>
  <sa-page-layout :showModalLoading="showModalLoading" :btnNewVisible="true" @refresh="onRefresh" :linkedit="linkedit" :btnRefreshVisible="true">
    <template slot="toolbar-title">
      <paziente-anagrafica :linkPatient="linkPatient" :idPaziente="idPaziente"></paziente-anagrafica>
    </template>
    <template slot="table-body">
      <div class="b-table-sticky-header">
        <div class="sa-tab-scroll">
          <b-tabs content-class="mt-3" class="sa-tab" style="height: 100%;" fill>
            <b-tab active>
              <template #title>
                <span>Ultima Allergia Rilevata</span>
              </template>
              <paziente-sic-allergie-view-component :jsonData="lastRecord"></paziente-sic-allergie-view-component>
            </b-tab>
            <b-tab>
              <template #title>
                <span>Storico Allergie</span>
              </template>
              <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col lg="3">
                  <p>{{ this.$i18n.t("ambulatori.lblTotalRecords") }}: {{ rows }}</p>
                </b-col>
                <b-col lg="9">
                  <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
              </b-row>
              <div>
                <div class="b-table-sticky-header" style="padding-top: 10px;">
                  <b-table
                    sticky-header
                    ref="table"
                    :filter="filter"
                    responsive
                    stacked="xl"
                    striped
                    hover
                    :items="items"
                    :fields="fields"
                    :current-page="currentPage"
                    :per-page="perPage"
                    sort-icon-left
                    head-variant="light"
                    class="sa-b-table"
                  >
                    <template v-slot:cell(createDate)="{ item }">
                      <router-link class="sa-edit-link" :to="'/paziente/sic/allergie/view/' + item.id + '?idPaziente=' + idPaziente">{{ new Date(item.createDate).toLocaleString() }}</router-link>
                    </template>
                  </b-table>
                </div>
                <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                  <b-col lg="3">
                    <p>{{ this.$i18n.t("ambulatori.lblTotalRecords") }}: {{ rows }}</p>
                  </b-col>
                  <b-col lg="9">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                  </b-col>
                </b-row>
              </div>
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </template>
  </sa-page-layout>
</template>
<script>
import axios from "axios";
import moment from "moment";
import { SaPageLayout } from "../../../../template/sa/components/layout/contents";
import PazienteAnagrafica from "../../../paziente/components/PazienteAnagrafica.vue";
import PazienteSicAllergieViewComponent from "./PazienteSicAllergieViewComponent.vue";
import UtilityMixin from "../../../utility/UtilityMixin.js";
export default {
  props: {
    utente: {
      type: Object,
      default: function() {
        return null;
      },
    },
  },
  components: { SaPageLayout, PazienteAnagrafica, PazienteSicAllergieViewComponent },
  computed: {
    idPaziente() {
      return this.$route.query.idPaziente;
    },
    rows() {
      return this.items.length;
    },
    btnNewVisible: {
      get: function() {
        let me = this;
        return UtilityMixin.methods.verificaPermessi(me.utente, "analisirischi", 2);
      },
    },
    linkPatient: {
      get: function() {
        let returnValue = "";
        if (this.utente.gruppo) {
          if (this.utente.gruppo.menuGruppo) {
            if (this.utente.gruppo.menuGruppo.length > 1) {
              return "/paziente/view/" + this.idPaziente;
            } else {
              if (this.utente.gruppo.menuGruppo.length == 1) {
                if (this.utente.gruppo.menuGruppo[0].primaryKey === "pazienti") {
                  return "/paziente/view/" + this.idPaziente;
                } else {
                  return "/paziente/homepaziente";
                }
              }
            }
          }
        }
        return returnValue;
      },
    },
  },
  data() {
    return {
      pathResource: "/allergie",
      pagelink: "/paziente/sic/allergie",
      linkedit: "/paziente/sic/allergie",
      perPage: 10,
      currentPage: 1,
      note: "",
      filter: null,
      showModalLoading: false,
      id: "-1",
      filtro: {},
      lastRecord: {},
      filterOn: [],
      fields: [
        {
          label: "Data Inizio",
          key: "createDate",
          formatter: (value) => {
            if (!value) {
              return "";
            } else {
              return moment(new Date(value)).format("DD-MM-YYYY HH:mm:ss");
            }
          },
          thStyle: "width: 3rem",
          sortable: true,
        },
        {
          label: "Farmaco",
          key: "farmaco",
          sortable: true,
          thStyle: "width: 3rem",
        },
        {
          label: "Stato",
          key: "stato",
          sortable: true,
          thStyle: "width: 3rem",
        },
      ],
      items: [],
      data: {},
    };
  },
  created: function() {},
  mounted: function() {
    let me = this;
    me.id = this.$route.params.id;
    me.data.idPaziente = this.$route.query.idPaziente;
    me.loadData();
  },
  methods: {
    loadData() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResource + "?idPaziente=" + me.data.idPaziente;
      me.showModalLoading = true;
      axios
        .get(link, { params: me.filtro })
        .then((response) => {
          me.items = response.data.data;
          console.log(me.items);
          me.lastRecord = me.items.at(-1);
          me.showModalLoading = false;
        })
        .catch(() => {
          me.showModalLoading = false;
        });
    },
    onRefresh() {
      let me = this;
      me.loadData();
    },
  },
};
</script>
<style scoped>
.col-lg-12 {
  height: 35px;
}
</style>
