<template>
    <sa-page-view-edit ref="pgaView" :data="item" :backVisible="true" :editVisible="true" :linkback="pagelink" :linkedit="linkedit">
        <template slot="body">
            <div class="bg-picture card-box">
                <div class="profile-info-name">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col">
                                <template>
                                    <h5 class="m-0">{{ this.$i18n.t("structure.lblClinicRegistry") }}</h5>
                                </template>
                            </div>
                            <div class="col"></div>
                            <div class="col">
                                <img :src="item.logo" style="width: 10rem" />
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col">
                                <strong>{{ this.$i18n.t("structure.lblName") }}</strong
                                ><br />
                                <span class="sa-form-label">{{ item.denominazione }}</span>
                            </div>
                            <div class="col">
                                <strong>{{ this.$i18n.t("structure.lblRegionCode") }}</strong
                                ><br />
                                <span class="sa-form-label">{{ item.codiceRegione }}</span>
                            </div>
                            <div class="col">
                                <strong>{{ this.$i18n.t("structure.lblCompanyCode") }}</strong
                                ><br />
                                <span class="sa-form-label">{{ item.codiceStruttura }}</span>
                            </div>
                        </div>
                        <hr />
                        <div class="row">
                            <div class="col">
                                <strong>{{ this.$i18n.t("structure.lblVatNumber") }}</strong
                                ><br />
                                <span class="sa-form-label">{{ item.partitaIva }}</span>
                            </div>
                            <div class="col">
                                <strong>{{ this.$i18n.t("structure.lblFiscalCode") }}</strong
                                ><br />
                                <span class="sa-form-label">{{ item.codiceFiscale }}</span>
                            </div>
                            <div class="col">
                                <strong>{{ this.$i18n.t("structure.lblIban") }}</strong
                                ><br />
                                <span class="sa-form-label">{{ item.iban }}</span>
                            </div>
                            <div class="col">
                                <strong>{{ this.$i18n.t("structure.lblTaxRegime") }}</strong
                                ><br />
                                <span class="sa-form-label">{{ item.codiceRegimeFiscale }}</span>
                            </div>
                        </div>
                        <hr />
                        <div class="row">
                            <div class="col">
                                <strong>{{ this.$i18n.t("structure.lblAddress") }} </strong><br />
                                <span class="sa-form-label">{{ item.indirizzo }}</span>
                            </div>
                        </div>
                        <hr />
                        <div class="row">
                            <div class="col">
                                <strong> {{ this.$i18n.t("structure.lblProvince") }} </strong><br />
                                <span class="sa-form-label">{{ item.provincia }}</span>
                            </div>
                            <div class="col">
                                <strong> {{ this.$i18n.t("structure.lblCity") }} </strong><br />
                                <span class="sa-form-label">{{ item.localita }}</span>
                            </div>
                            <div class="col">
                                <strong>{{ this.$i18n.t("structure.lblPostalCode") }}</strong
                                ><br />
                                <span class="sa-form-label">{{ item.cap }}</span>
                            </div>
                        </div>
                        <hr />
                        <div class="row">
                            <div class="col">
                                <strong> {{ this.$i18n.t("structure.lblTelephoneNumber") }} </strong><br />
                                <span class="sa-form-label">{{ item.telefono }}</span>
                            </div>
                            <div class="col">
                                <strong> {{ this.$i18n.t("structure.lblEmail") }} </strong><br />
                                <span class="sa-form-label">{{ item.email }}</span>
                            </div>
                            <div class="col"></div>
                        </div>
                        <hr />
                        <div class="row">
                            <div class="col">
                                <strong> {{ this.$i18n.t("structure.lblFax") }} </strong><br />
                                <span class="sa-form-label">{{ item.fax }}</span>
                            </div>
                            <div class="col">
                                <strong> {{ this.$i18n.t("structure.lblUrl") }} </strong><br />
                                <span class="sa-form-label">{{ item.url }}</span>
                            </div>
                            <div class="col"></div>
                        </div>
                        <hr />
                        <div class="row">
                            <div class="col">
                                <strong>{{ this.$i18n.t("structure.lblNote") }} </strong><br />
                                <span class="sa-form-label">{{ item.note }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </sa-page-view-edit>
</template>
<script>
import axios from "axios";
//import moment from "moment";
import { SaPageViewEdit } from "../../../template/sa/components/layout/contents";
export default {
    components: { SaPageViewEdit },
    computed: {
        rows() {
            return this.items.length;
        },
    },
    data() {
        return {
            perPage: 10,
            currentPage: 1,
            filter: null,
            pagelink: "/strutture",
            pathRest: "/strutture",
            linkedit: null,
            pathRESTRequest: "/struttura",
            filtro: {},
            filterOn: [],
            fields: [],
            items: [],
            item: {},
        };
    },
    created: function() {},
    mounted: function() {
        let me = this;
        me.id = this.$route.params.id;
        me.linkedit = "/struttura/edit/" + me.id;
        me.loadData();
    },
    methods: {
        loadData() {
            let me = this;
            me.showmolalloading = true;
            let link = process.env.VUE_APP_PATH_API + me.pathRest + "/";
            axios.get(link + me.id).then((response) => {
                me.item = response.data.data;
                me.showmolalloading = false;
            });
        },
        onAdd() {
            let me = this;
            me.$router.replace(me.pathRESTRequest + "/edit/-1").catch((err) => {
                err;
            });
        },
        onRefresh() {
            let me = this;
            me.loadData();
        },
    },
};
</script>
