<template>
    <sa-page-layout :btnBackVisible="true" :btnSaveVisible="true" :linkback="linkback" :pathResource="pathResource" :showModalLoading="showModalLoading">
        <template slot="table-body">
            <b-tabs content-class="mt-3" class="sa-tab" style="height: 100%;" fill>
                <b-tab>
                    <template #title>
                        <span class="text-success">Anamnesi</span>
                    </template>

                    <anamnesi-edit :data="data.certificatoAnamnesi"></anamnesi-edit>
                </b-tab>
                <b-tab>
                    <template #title>
                        <span class="text-success">Esame Obiettivo</span>
                    </template>
                    <div class="sa-tab-scroll">
                        <esame-obiettivo-edit :data="data.certificatoEsameObbiettivo"></esame-obiettivo-edit>
                    </div>
                </b-tab>
                <b-tab>
                    <template #title>
                        <span class="text-success">Urine</span>
                    </template>
                    <div>
                        <urine-edit-component></urine-edit-component>
                    </div>
                </b-tab>
                <b-tab>
                    <template #title>
                        <span class="text-success">Spirografia</span>
                    </template>
                    <div>
                        <spirografia-edit></spirografia-edit>
                    </div>
                    <!-- <div class="sa-tab-scroll">
            <spirografia-view-component
              :data="data.visitaSpirografia"
            ></spirografia-view-component>
          </div>-->
                </b-tab>
                <b-tab>
                    <template #title>
                        <span class="text-success">E.C.G.</span>
                    </template>
                    <ecg-edit :data="data.visitaEcg"></ecg-edit>
                </b-tab>
                <b-tab>
                    <template #title>
                        <span class="text-success">Es. Spec. Int.</span>
                    </template>
                    <p class="p-3">Tab contents 1</p>
                </b-tab>
            </b-tabs>
        </template>
    </sa-page-layout>
</template>
<script>
import axios from "axios";
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
import UrineEditComponent from "./UrineEditComponent.vue";
import AnamnesiEdit from "./AnamnesiEdit.vue";
import EsameObiettivoEdit from "./EsameObiettivoEdit.vue";
import EcgEdit from "./EcgEdit.vue";
import SpirografiaEdit from "./SpirografiaEditComponent.vue";
export default {
    components: {
        SaPageLayout,
        UrineEditComponent,
        AnamnesiEdit,
        EsameObiettivoEdit,
        EcgEdit,
        SpirografiaEdit,
    },
    data() {
        return {
            pathResource: "/visite",
            linkback: null,
            showModalLoading: false,
            data: {
                certificatoAnamnesi: {},
                certificatoEsameObbiettivo: {},
                visitaSpirografia: {},
                visitaEcg: {},
            },
        };
    },
    mounted: function() {
        let me = this;
        me.id = this.$route.params.id;
        if (me.id !== -1) {
            me.linkback = "/worklist/visitamedicosportiva/view/" + me.id;
        }
        me.loadData();
    },
    methods: {
        loadData() {
            let me = this;
            me.showModalLoading = true;
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
            axios.get(link + me.id).then((response) => {
                me.data = response.data.data;
                me.showModalLoading = false;
            });
        },
    },
};
</script>
