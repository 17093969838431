<template>
    <sa-page-layout :data="jsonData" :btnBackVisible="true" :linkback="linkback" :pathResource="pathResource" :showModalLoading="showModalLoading" :btnSaveVisible="true">
        <template slot="table-body">
            <b-tabs content-class="mt-3" class="sa-tab" style="height: 100%;" fill>
                <b-tab active title="Anagrafica">
                    <div class="sa-tab-scroll">
                        <anagrafica-component :data="jsonData.atleta" :edit="true"></anagrafica-component>
                        <dati-generali-edit-component :edit="true" :data="jsonData"></dati-generali-edit-component>
                    </div>
                </b-tab>
            </b-tabs>
        </template>
    </sa-page-layout>
</template>
<script>
import axios from "axios";
import AnagraficaComponent from "../../anagrafica/components/AnagraficaComponent.vue";
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
import DatiGeneraliEditComponent from "./DatiGeneraliEditComponent.vue";
export default {
    components: {
        AnagraficaComponent,
        SaPageLayout,
        DatiGeneraliEditComponent,
    },
    data() {
        return {
            showModalLoading: false,
            linkback: "/atleti",
            pathResource: "/visite",
            jsonData: {
                provenienza: null,
                sport: null,
                statoAttuale: null,
                ecgNonEseguito: false,
                spirografiaNonEseguita: false,
                urineMancanti: true,
                giudizio: null,
                giudizioConclusivo: null,
                atleta: {
                    tipo: "PATIENT",
                    identificativo: null,
                    tipoIdentificativo: null,
                    cognome: null,
                    nome: null,
                    dataNascita: null,
                    comuneNascitaCodiceIstat: null,
                    comuneResidenzaCodiceIstat: null,
                    comuneDomicilioCodiceIstat: null,
                    indirizzoResidenza: null,
                    indirizzoDomicilio: null,
                    note: null,
                    medicoCurante: null,
                    codiceFiscaleMedicoCurante: null,
                    capDomicilio: null,
                    capResidenza: null,
                    telefono: null,
                    email: null,
                    codiceFiscale: null,
                    sesso: null,
                    codiceStruttura: null,
                    comuneResidenza: null,
                    comuneNascita: null,
                    provinciaResidenza: null,
                    provinciaNascita: null,
                },
            },
        };
    },
    methods: {
        loadData() {
            let me = this;
            if (me.id === "-1") {
                me.showModalLoading = false;
            } else {
                let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.id;
                me.showModalLoading = true;
                axios
                    .get(link)
                    .then((response) => {
                        me.jsonData = response.data.data;
                        me.showModalLoading = false;
                    })
                    .catch(() => {
                        me.showModalLoading = false;
                    });
            }
        },
    },
};
</script>
