<template>
    <sa-page-view-edit ref="pgaView" :data="data" :pathResource="pathResource" :backVisible="true" :saveVisible="true" :titolo="data.nome" :linkback="linkback" :linkedit="linkedit" :showmolalloading="showmolalloading">
        <template slot="body">
            <b-row>
                <b-col cols="12" lg="2" md="2" class="sa-text-right">Nome:</b-col>
                <b-col cols="12" lg="2" md="4" class="sa-text-left">
                    <b-form-input v-model="data.nome" size="sm" placeholder="Nome"></b-form-input>
                </b-col>
                <b-col cols="12" lg="2" md="2" class="sa-text-right">Stato:</b-col>
                <b-col cols="12" lg="2" md="4" class="sa-text-left">
                    <span class="sa-text-data">{{ data.stato }}</span>
                </b-col>
                <b-col cols="12" lg="2" md="2" class="sa-text-right">Tipo Avvio:</b-col>
                <b-col cols="12" lg="2" md="4" class="sa-text-left">
                    <b-form-select v-model="data.tipoAvvio" :options="options"></b-form-select>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" lg="2" md="2" class="sa-text-right">Descrizione:</b-col>
                <b-col cols="12" lg="10" md="10" class="sa-text-left">
                    <b-form-textarea id="textarea" v-model="data.descrizione" placeholder="Descrizione del task..." rows="7"></b-form-textarea>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" lg="2" md="2" class="sa-text-right">Nome Classe:</b-col>
                <b-col cols="12" lg="10" md="10" class="sa-text-left">
                    <b-form-input v-model="data.classeJava" size="sm" placeholder="Nome"></b-form-input>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" lg="2" md="2" class="sa-text-right">Ultimo Avvio:</b-col>
                <b-col cols="12" lg="4" md="4" class="sa-text-left">
                    <span class="sa-text-data">{{ formatDate(data.dataOraUltimoStart) }}</span>
                </b-col>
                <b-col cols="12" lg="2" md="2" class="sa-text-right">Ultimo Stop:</b-col>
                <b-col cols="12" lg="4" md="4" class="sa-text-left">
                    <span class="sa-text-data">{{ formatDate(data.dataOraUltimoStop) }}</span>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" lg="2" md="2" class="sa-text-right">Data Avvio:</b-col>
                <b-col cols="12" lg="4" md="4" class="sa-text-left">
                    <date-picker format="DD-MM-YYYY HH:mm:sss" value-type="timestamp" v-model="data.avvioData" type="datetime"></date-picker>
                    <!--<b-form-datepicker locale="it" v-model="dataAavvioData" class="mb-2"></b-form-datepicker>-->
                </b-col>
                <b-col cols="12" lg="2" md="2" class="sa-text-right">Prossima Exec.:</b-col>
                <b-col cols="12" lg="4" md="4" class="sa-text-left">
                    <span class="sa-text-data">{{ formatDate(data.dataOraProssimaEsecuzione) }}</span>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" lg="2" md="2" class="sa-text-right">Rip. Mese:</b-col>
                <b-col cols="12" lg="1" md="4" class="sa-text-left">
                    <span class="sa-text-data">{{ data.ripetizioneMese }}</span>
                </b-col>
                <b-col cols="12" lg="2" md="2" class="sa-text-right"
                    >Rip. Giorno (<span class="sa-text-data">{{ data.ripetizioneGiorno }}</span
                    >):</b-col
                >
                <b-col cols="12" lg="1" md="4" class="sa-text-left">
                    <b-form-input v-model="data.ripetizioneGiorno" type="range" min="0" max="30"></b-form-input>
                </b-col>
                <b-col cols="12" lg="2" md="2" class="sa-text-right"
                    >Rip. Ore (<span class="sa-text-data">{{ data.ripetizioneOre }}</span
                    >):</b-col
                >
                <b-col cols="12" lg="1" md="4" class="sa-text-left">
                    <b-form-input v-model="data.ripetizioneOre" type="range" min="0" max="24"></b-form-input>
                </b-col>
                <b-col cols="12" lg="2" md="2" class="sa-text-right"
                    >Rip. Minuti (<span class="sa-text-data">{{ data.ripetizioneMinuti }}</span
                    >):</b-col
                >
                <b-col cols="12" lg="1" md="4" class="sa-text-left">
                    <b-form-input v-model="data.ripetizioneMinuti" type="range" min="0" max="59"></b-form-input>
                </b-col>
            </b-row>
        </template>
    </sa-page-view-edit>
</template>
<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import { SaPageViewEdit } from "../../../template/sa/components/layout/contents";
import moment from "moment";
import axios from "axios";
export default {
    components: { SaPageViewEdit, DatePicker },
    data() {
        return {
            pathResource: "/gesantask",
            linkback: "/taskmanager",
            linkedit: null,
            showmolalloading: false,
            id: "-1",
            options: [
                { value: null, text: "Selezionare Tipo Avvio" },
                { value: "Automatico", text: "Automatico" },
                { value: "Manuale", text: "Manuale" },
            ],
            data: {},
        };
    },
    computed: {
        dataAavvioData: {
            get: function() {
                return new Date(this.data.avvioData);
            },
            set: function(value) {
                this.data.avvioData = moment(String(value)).valueOf();
            },
        },
    },
    mounted: function() {
        let me = this;
        me.id = this.$route.params.id;
        me.loadData();
    },
    methods: {
        formatDate(value) {
            if (value) {
                return moment(new Date(value)).format("DD-MM-YYYY HH:mm:ss");
            } else {
                return "---";
            }
        },
        loadData() {
            let me = this;
            if (me.id === "-1") {
                me.showmolalloading = false;
            } else {
                me.showmolalloading = true;
                me.linkback = "/taskmanager/view/" + me.id;
                let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
                axios.get(link + me.id).then((response) => {
                    me.data = response.data.data;
                    me.showmolalloading = false;
                });
            }
        },
        onSave() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathRESTRequest;
            let jsonData = JSON.stringify(me.data);

            me.$refs["sa-modal-loading"].show();
            if (me.id == "-1") {
                axios
                    .post(link, jsonData)
                    .then((response) => {
                        me.data = response.data.data;
                        me.$refs["sa-modal-loading"].close();
                        me.$router.replace(me.pathRESTRequest).catch((err) => {
                            err;
                        });
                    })
                    .catch((error) => {
                        me.$refs["sa-modal-loading"].close();
                        this.$bvModal
                            .msgBoxOk(error.response.data.messaggio, {
                                title: "ATTENZIONE",
                                size: "sm",
                                okVariant: "outline-danger",
                                headerClass: "sa-msg-header-danger",
                                footerClass: "p-2 border-top-0",
                                centered: true,
                            })
                            .then((value) => {
                                this.boxTwo = value;
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                    });
            } else {
                axios
                    .put(link + "/" + me.id, jsonData)
                    .then((response) => {
                        me.gruppo = response.data.data;
                        me.$refs["sa-modal-loading"].close();
                        me.$router.replace("/taskmanager").catch((err) => {
                            err;
                        });
                    })
                    .catch((error) => {
                        me.$refs["sa-modal-loading"].close();
                        this.$bvModal
                            .msgBoxOk(error.response.data.messaggio, {
                                title: "ATTENZIONE",
                                size: "sm",
                                okVariant: "outline-danger",
                                headerClass: "sa-msg-header-danger",
                                footerClass: "p-2 border-top-0",
                                centered: true,
                            })
                            .then((value) => {
                                this.boxTwo = value;
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                    });
            }
        },
        onBack() {
            let me = this;
            me.$router.replace("/taskmanager").catch((err) => {
                err;
            });
        },
    },
};
</script>
