<template>
  <b-row>
    <b-col>
      <span class="sa-form-label">{{ this.$i18n.t("patient.idoneity.lblFamilyAnamnesis") }}</span>
      <b-form-textarea rows="4" max-rows="9" v-model="data.anamnesiFamiliare" :disabled="!edit"></b-form-textarea>
      <span class="sa-form-label">{{ this.$i18n.t("patient.idoneity.lblPathologicalAnamnesis") }}</span>
      <b-form-textarea rows="4" max-rows="9" v-model="data.anamnesiPatologica" :disabled="!edit"></b-form-textarea>
      <span class="sa-form-label">{{ this.$i18n.t("patient.idoneity.lblInjuries") }}</span>
      <b-form-textarea rows="4" max-rows="9" v-model="data.infortuni" :disabled="!edit"></b-form-textarea>
    </b-col>
    <b-col cols="2"></b-col>
    <b-col>
      <span class="sa-form-label">{{ this.$i18n.t("patient.idoneity.lblPhysiologicalAnamnesis") }}</span>
      <b-form-textarea rows="4" max-rows="9" v-model="data.anamnesiFisiologica" :disabled="!edit"></b-form-textarea>
      <span class="sa-form-label">{{ this.$i18n.t("patient.idoneity.lblSurgicalInterventions") }}</span>
      <b-form-textarea rows="4" max-rows="9" v-model="data.interventiChirurgici" :disabled="!edit"></b-form-textarea>
      <span class="sa-form-label">{{ this.$i18n.t("patient.idoneity.lblSportAnamnesis") }}</span>
      <b-form-textarea rows="4" max-rows="9" v-model="data.anamnesiSportiva" :disabled="!edit"></b-form-textarea>
    </b-col>
  </b-row>
</template>

<script>
export default {
  props: ["data", "edit"],
  data() {
    return {};
  },
};
</script>
