<template>
  <div>
    <div class="sa-form-section">
      <h5 class="sa-text-left">Dati</h5>
      <b-row>
        <b-col xs="12" sm="4" md="4" lg="4" xl="4">
          <strong>{{ this.$i18n.t("patient.diabetology.lblHeight") }}</strong>
          <br />
          {{ jsonData.altezza }}
        </b-col>
        <b-col xs="12" sm="4" md="4" lg="4" xl="4">
          <strong>{{ this.$i18n.t("patient.diabetology.lblWeight") }}</strong>
          <br />
          {{ jsonData.peso }}
        </b-col>
        <b-col xs="12" sm="4" md="4" lg="4" xl="4">
          <strong>B.M.I</strong>
          <br />
          {{ jsonData.bmi }}
        </b-col>
      </b-row>
      <hr />
    </div>
    <div class="sa-form-section">
      <b-row>
        <b-col xs="12" sm="3" md="3" lg="3" xl="3">
          <strong>{{ this.$i18n.t("patient.diabetology.lblIdealWeight") }} </strong>
          <br />
          {{ jsonData.pesoIdeale }}
        </b-col>
        <b-col xs="12" sm="3" md="3" lg="3" xl="3">
          <strong>{{ this.$i18n.t("patient.diabetology.lblIdealWeightAbove") }}</strong>
          <br />
          {{ jsonData.pesoPesoIdealeSopra }}
        </b-col>
        <b-col xs="12" sm="3" md="3" lg="3" xl="3">
          <strong>{{ this.$i18n.t("patient.diabetology.lblIdealWeightBelow") }} </strong>
          <br />
          {{ jsonData.pesoPesoIdealeSotto }}
        </b-col>
        <b-col xs="12" sm="3" md="3" lg="3" xl="3">
          <strong> Spo2 </strong>
          <br />
          {{ jsonData.spo2 }}
        </b-col>
      </b-row>
      <hr />
    </div>
    <div class="sa-form-section">
      <b-row>
        <b-col xs="4" sm="4" md="4" lg="4" xl="4">
          <strong> {{ this.$i18n.t("patient.diabetology.lblSystolicPressure") }}</strong>
          <br />
          {{ jsonData.pressioneSistolica }}
        </b-col>
        <b-col xs="4" sm="4" md="4" lg="4" xl="4">
          <strong>{{ this.$i18n.t("patient.diabetology.lblDiastolicBloodPressure") }} </strong>
          <br />
          {{ jsonData.pressioneDiastolica }}
        </b-col>
        <b-col xs="4" sm="4" md="4" lg="4" xl="4">
          <strong> {{ this.$i18n.t("patient.diabetology.lblHeartRate") }}</strong>
          <br />
          {{ jsonData.frequenzaCardiaca }}
        </b-col>
      </b-row>
      <hr />
    </div>

    <div class="sa-form-section">
      <b-row>
        <b-col xs="12" sm="4" md="4" lg="4" xl="4">
          <strong> {{ this.$i18n.t("patient.diabetology.lblSkinAndAnnexes") }}</strong>
          <br />
          {{ jsonData.cuteEdAnnessi }}
        </b-col>
        <b-col xs="12" sm="4" md="4" lg="4" xl="4">
          <strong>{{ this.$i18n.t("patient.diabetology.lblRespiratorySystem") }}</strong>
          <br />
          {{ jsonData.apparatoRespiratorio }}
        </b-col>
        <b-col xs="12" sm="4" md="4" lg="4" xl="4">
          <strong> {{ this.$i18n.t("patient.lblDigestiveSystem") }}</strong>
          <br />
          {{ jsonData.apparatoDigerente }}
        </b-col>
      </b-row>
      <hr />
    </div>
    <div class="sa-form-section">
      <b-row>
        <b-col xs="12" sm="4" md="4" lg="4" xl="4">
          <strong>{{ this.$i18n.t("patients.lblUrogenitalApparatus") }}</strong>
          <br />
          {{ jsonData.apparatoUrogenitale }}
        </b-col>

        <b-col xs="12" sm="4" md="4" lg="4" xl="4">
          <strong> {{ this.$i18n.t("patient.diabetology.lblArticularMuscleSystem") }}</strong>
          <br />
          {{ jsonData.apparatoMuscoloArticolare }}
        </b-col>
        <b-col xs="12" sm="4" md="4" lg="4" xl="4">
          <strong>{{ this.$i18n.t("patient.diabetology.lblCardiovascularSystem") }}</strong>
          <br />
          {{ jsonData.sistemaCardiovascolare }}
        </b-col>
      </b-row>
      <hr />
    </div>
    <div class="sa-form-section">
      <b-row>
        <b-col xs="12" sm="3" md="3" lg="3" xl="3">
          <strong> {{ this.$i18n.t("patients.lblNervousSystem") }}</strong>
          <br />
          {{ jsonData.sistemaNervoso }}
        </b-col>
        <b-col xs="12" sm="3" md="3" lg="3" xl="3">
          <strong>{{ this.$i18n.t("patients.lblFoot") }}</strong>
          <br />
          {{ jsonData.piede }}
        </b-col>
        <b-col xs="12" sm="3" md="3" lg="3" xl="3">
          <strong> {{ this.$i18n.t("patients.lblSubcutaneous") }}</strong>
          <br />
          {{ jsonData.sottocutaneo }}
        </b-col>
        <b-col xs="12" sm="3" md="3" lg="3" xl="3">
          <strong> {{ this.$i18n.t("patients.lblMouthTeeth") }}</strong>
          <br />
          {{ jsonData.boccaDenti }}
        </b-col>
      </b-row>
      <hr />
    </div>
    <b-row>
      <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
        <div class="sa-view-form-data"><user-information :data="jsonData"></user-information></div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import axios from "axios";
import moment from "moment";
import "vue2-datepicker/index.css";
import UtilityMixin from "../../../../utility/UtilityMixin";
import UserInformation from "../../../../utenti/components/UserInformation.vue";
export default {
  mixins: [UtilityMixin],
  components: { UserInformation },
  props: {
    idEvento: { type: String, require: true },
  },
  data() {
    return {
      pathResource: "/diabetologiaprimavisita",
      linkback: null,
      linkedit: null,
      showmolalloading: false,
      id: "-1",
      profili: [],
      jsonData: {},
      provenienzaOption: [{ value: null, text: null }],
    };
  },
  computed: {
    idPaziente() {
      return this.$route.query.idPaziente;
    },
    dataEventoPlaceholder() {
      return this.$i18n.t("patient.events.lblEventDate");
    },
  },
  mounted: function() {
    let me = this;
    me.id = this.$route.params.id;

    me.linkback = "" + me.idPaziente;
    me.loadData();
  },
  methods: {
    formatDate(value) {
      if (value) {
        return moment(new Date(value)).format("DD-MM-YYYY HH:mm:ss");
      } else {
        return "---";
      }
    },
    loadData() {
      let me = this;
      if (me.id === "-1") {
        me.showmolalloading = false;
      } else {
        me.showmolalloading = true;
        let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";

        axios.get(link + me.idEvento).then((response) => {
          me.jsonData = response.data.data;
        });
      }
    },
  },
};
</script>
