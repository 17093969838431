<template>
    <!-- Main Sidebar Container -->
    <sa-aside-paziente mini>
        <sa-aside-brand-paziente :logo="logo" :logosmall="logosmall" header="Gesan srl" />
        <sa-aside-container-paziente>
            <sa-aside-user-paziente :image="avatar" :username="nominativoUtente" :ruolo="ruolo" />
            <sa-aside-nav-paziente class="sisto-andolfi">
                <template v-for="item in externalmenu">
                    <sa-aside-nav-header-paziente v-if="!item.children && !item.viewId" :title="item.text" :key="item.text" />
                    <sa-aside-nav-item-paziente v-if="!item.children && item.viewId" :title="item.text" :key="item.text" :icon="item.iconCls" :badge="item.badge" :to="getLinkMenu(item.viewId)" />
                    <sa-aside-nav-item-dropdown-paziente v-if="item.children" :key="item.text" :title="item.text" :icon="item.iconCls">
                        <sa-aside-nav-item-paziente v-for="submenu in item.children" :key="submenu.text" :title="submenu.text" :icon="submenu.iconCls" :to="getLinkMenu(submenu.viewId)" />
                    </sa-aside-nav-item-dropdown-paziente>
                </template>
            </sa-aside-nav-paziente>
        </sa-aside-container-paziente>
    </sa-aside-paziente>
</template>

<script>
import avatar from "../../../assets/images/no-avatar.png";
import logo from "../../../assets/images/logo-base.png";
import logosmall from "../../../assets/images/logo.png";
import SaAsidePaziente from "./SaAsidePaziente.vue";
import SaAsideBrandPaziente from "./SaAsideBrandPaziente.vue";
import SaAsideContainerPaziente from "./SaAsideContainerPaziente.vue";
import SaAsideUserPaziente from "./SaAsideUserPaziente.vue";
import SaAsideNavPaziente from "./SaAsideNavPaziente.vue";
import SaAsideNavHeaderPaziente from "./SaAsideNavHeaderPaziente.vue";
import SaAsideNavItemDropdownPaziente from "./SaAsideNavItemDropdownPaziente.vue";
import SaAsideNavItemPaziente from "./SaAsideNavItemPaziente.vue";

export default {
    components: { SaAsidePaziente, SaAsideBrandPaziente, SaAsideContainerPaziente, SaAsideUserPaziente, SaAsideNavPaziente, SaAsideNavHeaderPaziente, SaAsideNavItemDropdownPaziente, SaAsideNavItemPaziente },
    props: {
        externalmenu: Array,
        nominativoUtente: String,
        idPaziente: {
            type: String,
            default: "-1",
        },
        ruolo: String,
    },
    methods: {
        getLinkMenu(value) {
            return value + "?idPaziente=" + this.idPaziente;
        },
    },
    created() {},
    data() {
        return {
            logo,
            logosmall,
            avatar,
            username: "",
        };
    },
};
</script>

<style>
.img-circle {
    border-radius: 0%;
}
/* .sidebar-collapse .main-sidebar,
.sidebar-collapse .main-sidebar::before {
    margin-left: -250px;
} */
.nav-sidebar .menu-open svg.right {
    transform: rotate(-90deg);
}
</style>

<style scoped></style>
