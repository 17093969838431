<template functional>
    <li class="nav-header">{{ this.$i18n.t(title) }}</li>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            required: true,
        },
    },
};
</script>
