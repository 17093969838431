import PrimaVisitaList from "./components/PrimaVisitaList.vue";
import PrimaVisitaView from "./components/PrimaVisitaView.vue";
import PrimaVisitaEdit from "./components/PrimaVisitaEdit.vue";
import AnamnesiList from "./components/AnamnesiList.vue";
import AnamnesiEdit from "./components/AnamnesiEdit.vue";
import AnamnesiView from "./components/AnamnesiView.vue";
import AnamnesiFisiologicaList from "./components/AnamnesiFisiologicaList.vue";
import AnamnesiFisiologicaView from "./components/AnamnesiFisiologicaList.vue";
import AnamnesiFisiologicaEdit from "./components/AnamnesiFisiologicaList.vue";
import AnamnesiFamiliareList from "./components/AnamnesiFamiliareList.vue";
import AnamnesiFamiliareView from "./components/AnamnesiFamiliareView.vue";
import AnamnesiFamiliareEdit from "./components/AnamnesiFamiliareEdit.vue";
import AnamnesiDiabetologicaFollowUpList from "./components/AnamnesiDiabetologicaFollowUpList.vue";
import AnamnesiDiabetologicaFollowUpEdit from "./components/AnamnesiDiabetologicaFollowUpEdit.vue";
import AnamnesiDiabetologicaFollowUpView from "./components/AnamnesiDiabetologicaFollowUpView.vue";
import ControlliSpecialisticiList from "./components/ControlliSpecialisticiList.vue";
import ControlliSpecialisticiView from "./components/ControlliSpecialisticiView.vue";
import ControlliSpecialisticiEdit from "./components/ControlliSpecialisticiEdit.vue";

export default {
  PrimaVisitaList,
  PrimaVisitaView,
  PrimaVisitaEdit,
  AnamnesiList,
  AnamnesiEdit,
  AnamnesiView,
  AnamnesiFisiologicaList,
  AnamnesiFisiologicaView,
  AnamnesiFisiologicaEdit,
  AnamnesiFamiliareList,
  AnamnesiFamiliareView,
  AnamnesiFamiliareEdit,
  AnamnesiDiabetologicaFollowUpList,
  AnamnesiDiabetologicaFollowUpEdit,
  AnamnesiDiabetologicaFollowUpView,
  ControlliSpecialisticiEdit,
  ControlliSpecialisticiView,
  ControlliSpecialisticiList,
};
