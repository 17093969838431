import GastroAnamnesiPatologicaList from "./components/GastroAnamnesiPatologicaList.vue";
import GastroAnamnesiPatologicaEdit from "./components/GastroAnamnesiPatologicaEdit.vue";
import GastroAnamnesiPatologicaView from "./components/GastroAnamnesiPatologicaView.vue";
import GastroAnamnesiFisiologicaList from "./components/GastroAnamnesiFisiologicaList.vue";
import GastroAnamnesiFisiologicaEdit from "./components/GastroAnamnesiFisiologicaEdit.vue";
import GastroAnamnesiFisiologicaView from "./components/GastroAnamnesiFisiologicaView.vue";
import GastroAnamnesiFamiliareList from "./components/GastroAnamnesiFamiliareList.vue";
import GastroAnamnesiFamiliareEdit from "./components/GastroAnamnesiFamiliareEdit.vue";
import GastroAnamnesiFamiliareView from "./components/GastroAnamnesiFamiliareView.vue";
import GastroAnoRettoColonscopiaList from "./components/GastroAnoRettoColonscopiaList.vue";
import GastroAnoRettoColonscopiaEdit from "./components/GastroAnoRettoColonscopiaEdit.vue";
import GastroAnoRettoColonscopiaView from "./components/GastroAnoRettoColonscopiaView.vue";
import EgdsEdit from "./components/EgdsEdit.vue";
import EgdsView from "./components/EgdsView.vue";
import EgdsList from "./components/EgdsList.vue";
import ConfigSelectEgdsStrumentoList from "./components/ConfigSelectEgdsStrumentoList.vue";
import ConfigSelectEgdsStrumentoEdit from "./components/ConfigSelectEgdsStrumentoEdit.vue";
import ConfigSelectEgdsStrumentoView from "./components/ConfigSelectEgdsStrumentoView.vue";
import ConfigSelectEgdsLavaggioDisinfezioneList from "./components/ConfigSelectEgdsLavaggioDisinfezioneList.vue";
import ConfigSelectEgdsLavaggioDisinfezioneEdit from "./components/ConfigSelectEgdsLavaggioDisinfezioneEdit.vue";
import ConfigSelectEgdsLavaggioDisinfezioneView from "./components/ConfigSelectEgdsLavaggioDisinfezioneView.vue";
import ConfigSelectEgdsIndicazioneList from "./components/ConfigSelectEgdsIndicazioneList.vue";
import ConfigSelectEgdsIndicazioneEdit from "./components/ConfigSelectEgdsIndicazioneEdit.vue";
import ConfigSelectEgdsIndicazioneView from "./components/ConfigSelectEgdsIndicazioneView.vue";
import VisitaGastroenterologicaEdit from "./components/VisitaGastroenterologicaEdit.vue";
import VisitaGastroenterologicaView from "./components/VisitaGastroenterologicaView.vue";
import VisitaGastroenterologicaList from "./components/VisitaGastroenterologicaList.vue";
import EcografiaAddominaleEdit from "./components/EcografiaAddominaleEdit.vue";
import EcografiaAddominaleView from "./components/EcografiaAddominaleView.vue";
import EcografiaAddominaleList from "./components/EcografiaAddominaleList.vue";
import EcoendoscopiaEdit from "./components/EcoendoscopiaEdit.vue";
import EcoendoscopiaView from "./components/EcoendoscopiaView.vue";
import EcoendoscopiaList from "./components/EcoendoscopiaList.vue";

export default {
  GastroAnamnesiPatologicaList,
  GastroAnamnesiPatologicaEdit,
  GastroAnamnesiPatologicaView,
  GastroAnamnesiFisiologicaList,
  GastroAnamnesiFisiologicaEdit,
  GastroAnamnesiFisiologicaView,
  GastroAnamnesiFamiliareList,
  GastroAnamnesiFamiliareEdit,
  GastroAnamnesiFamiliareView,
  GastroAnoRettoColonscopiaList,
  GastroAnoRettoColonscopiaEdit,
  GastroAnoRettoColonscopiaView,
  EgdsEdit,
  EgdsView,
  EgdsList,
  VisitaGastroenterologicaEdit,
  VisitaGastroenterologicaView,
  VisitaGastroenterologicaList,
  EcografiaAddominaleEdit,
  EcografiaAddominaleView,
  EcografiaAddominaleList,
  EcoendoscopiaEdit,
  EcoendoscopiaList,
  EcoendoscopiaView,
  ConfigSelectEgdsStrumentoList,
  ConfigSelectEgdsStrumentoEdit,
  ConfigSelectEgdsStrumentoView,
  ConfigSelectEgdsLavaggioDisinfezioneList,
  ConfigSelectEgdsLavaggioDisinfezioneEdit,
  ConfigSelectEgdsLavaggioDisinfezioneView,
  ConfigSelectEgdsIndicazioneList,
  ConfigSelectEgdsIndicazioneEdit,
  ConfigSelectEgdsIndicazioneView,
};
