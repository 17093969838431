import Paziente from "./components/Paziente.vue";
import PazienteView from "./components/PazienteView.vue";
import PazienteTeleconsultiList from "./components/PazienteTeleconsultiList.vue";
import PazienteTeleconsultoEdit from "./components/PazienteTeleconsultoEdit.vue";
import PazienteTeleconsultoView from "./components/PazienteTeleconsultoView.vue";
import PazienteDietaList from "./components/PazienteDietaList.vue";
import PazienteDietaEdit from "./components/PazienteDietaEdit.vue";
import PazienteDietaView from "./components/PazienteDietaView.vue";
import PazienteTeleconsulto from "./components/PazienteTeleconsulto.vue";
import PazienteTeleconsultoHassisto from "./components/PazienteTeleconsultoHasssito.vue";
import PazienteDashboard from "./components/PazienteDashboard.vue";
import PazienteAnamnesiList from "./components/PazienteAnamnesiList.vue";
import PazienteAnamnesiEdit from "./components/PazienteAnamnesiEdit.vue";
import PazienteAnamnesiView from "./components/PazienteAnamnesiView.vue";
import PazienteQuestionariList from "./components/PazienteQuestionariList.vue";
import PazienteQuestionariEdit from "./components/PazienteQuestionariEdit.vue";
import PazienteLinkUtili from "./components/PazienteLinkUtili.vue";
import PazienteHome from "./components/PazienteHome.vue";
import PazienteLifeStyle from "./components/PazienteLifeStyle.vue";
import RangeConfiguration from "./components/RangeConfiguration.vue";
import RangeConfigurationEdit from "./components/RangeConfigurationEdit.vue";
import AnalisiRischiList from "./components/analisi-rischi/AnalisiRischiList.vue";
import AnalisiRischiView from "./components/analisi-rischi/AnalisiRischiView.vue";
import AnalisiRischiEdit from "./components/analisi-rischi/AnalisiRischiEdit.vue";
import PazienteDeviceList from "./components/PazienteDeviceList.vue";
import PazienteDeviceEdit from "./components/PazienteDeviceEdit.vue";
export default {
  Paziente,
  PazienteView,
  PazienteTeleconsultiList,
  PazienteTeleconsultoEdit,
  PazienteTeleconsultoView,
  PazienteTeleconsulto,
  PazienteTeleconsultoHassisto,
  PazienteDashboard,
  PazienteAnamnesiList,
  PazienteAnamnesiEdit,
  PazienteAnamnesiView,
  PazienteDietaList,
  PazienteDietaView,
  PazienteDietaEdit,
  PazienteQuestionariList,
  PazienteQuestionariEdit,
  PazienteLinkUtili,
  PazienteHome,
  PazienteLifeStyle,
  RangeConfiguration,
  RangeConfigurationEdit,
  AnalisiRischiList,
  AnalisiRischiEdit,
  AnalisiRischiView,
  PazienteDeviceList,
  PazienteDeviceEdit,
};
