<template>
  <div>
    <b-row>
      <b-col cols="12" xs="5" sm="5" md="5" lg="5" xl="12"
        ><span class="sa-form-label">{{ this.$i18n.t("patient.idoneity.lblDateVisit") }}</span>
        <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="data.dataAppuntamento" :disabled="!edit" type="datetime"></date-picker>
        <span class="sa-form-label">{{ this.$i18n.t("patient.idoneity.lblSportPracticed") }}</span>
        <b-form-input v-model="data.sportPraticato" :disabled="!edit"></b-form-input>
        <span class="sa-form-label">{{ this.$i18n.t("patient.idoneity.lblFollowingVisit") }}</span>
        <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="data.visitaSuccessiva" :disabled="!edit" type="datetime"></date-picker>
        <span class="sa-form-label">{{ this.$i18n.t("patient.idoneity.lblWeeklyWorkouts") }}</span>
        <b-form-input v-model="data.allenamentiSettimana" :disabled="!edit"></b-form-input>
        <span class="sa-form-label">{{ this.$i18n.t("patient.idoneity.lblSpeciality") }}</span>
        <b-form-input v-model="data.specialita" :disabled="!edit"></b-form-input>
        <span class="sa-form-label">{{ this.$i18n.t("patient.idoneity.lblFederation") }}</span>
        <b-form-input v-model="data.federazione" :disabled="!edit"></b-form-input>
        <span class="sa-form-label">{{ this.$i18n.t("patient.idoneity.lblOtherSports") }}</span>
        <b-form-input v-model="data.altriSport" :disabled="!edit"></b-form-input>
        <span class="sa-form-label">{{ this.$i18n.t("patient.idoneity.lblAntitetanus") }}</span>
        <b-form-input v-model="data.antitetanica" :disabled="!edit"></b-form-input>
        <span class="sa-form-label">{{ this.$i18n.t("patient.idoneity.lblAlcohol") }}</span>
        <b-form-input v-model="data.alcool" :disabled="!edit"></b-form-input>
        <span class="sa-form-label">{{ this.$i18n.t("patient.idoneity.lblNarcoticSubstances") }}</span>
        <b-form-input v-model="data.sostenzeStupefacenti" :disabled="!edit"></b-form-input> </b-col
      ><b-col cols="12" xs="2" sm="2" md="2" lg="2" xl="12"></b-col
      ><b-col cols="12" xs="5" sm="5" md="5" lg="5" xl="12"
        ><span class="sa-form-label">{{ this.$i18n.t("patient.idoneity.lblFirstVisit") }}</span>
        <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="data.primaVisita" :disabled="!edit" type="datetime"></date-picker>
        <span class="sa-form-label">{{ this.$i18n.t("patient.idoneity.lblHourVisit") }}</span>
        <b-form-timepicker v-model="data.oraAppuntamento" :disabled="!edit"></b-form-timepicker>
        <span class="sa-form-label">{{ this.$i18n.t("patient.idoneity.lblYearsNumber") }}</span>
        <b-form-input v-model="data.numeroAnni" :disabled="!edit"></b-form-input>
        <span class="sa-form-label">{{ this.$i18n.t("patient.idoneity.lblHoursWorkout") }}</span>
        <b-form-input v-model="data.oreAllenamento" :disabled="!edit"></b-form-input>
        <span class="sa-form-label">{{ this.$i18n.t("patient.idoneity.lblCorporation") }}</span>
        <b-form-input v-model="data.societa" :disabled="!edit"></b-form-input>
        <span class="sa-form-label">{{ this.$i18n.t("patient.idoneity.lblLHB") }}</span>
        <b-form-input v-model="data.aslNew" :disabled="!edit"></b-form-input>
        <span class="sa-form-label">{{ this.$i18n.t("patient.idoneity.lblMenarche") }}</span>
        <b-form-input v-model="data.menarca" :disabled="!edit"></b-form-input>
        <span class="sa-form-label">{{ this.$i18n.t("patient.idoneity.lblSmoking") }}</span>
        <b-form-input v-model="data.fumo" :disabled="!edit"></b-form-input>
        <span class="sa-form-label">{{ this.$i18n.t("patient.idoneity.lblCoffee") }}</span>
        <b-form-input v-model="data.caffe" :disabled="!edit"></b-form-input>
        <span class="sa-form-label">{{ this.$i18n.t("patient.idoneity.lblMilitary") }}</span>
        <b-form-input v-model="data.militare" :disabled="!edit"></b-form-input>
        <span class="sa-form-label">{{ this.$i18n.t("patient.idoneity.lblMilitaryMotif") }}</span>
        <b-form-input v-model="data.militareMotivo" :disabled="!edit"></b-form-input>
      </b-col>
    </b-row>
    <div style="height: 30px;"></div>
    <b-row>
      <b-col
        ><span class="sa-form-label">{{ this.$i18n.t("patient.idoneity.lblIdentityDocument") }}</span> <b-form-input v-model="data.documentoIdentitaTipo" :disabled="!edit"></b-form-input>
      </b-col>
      <b-col>
        <span class="sa-form-label">{{ this.$i18n.t("patient.idoneity.lblIdentityDocumentNumber") }}</span> <b-form-input v-model="data.documentoIdentitaNumero" :disabled="!edit"></b-form-input>
      </b-col>
      <b-col>
        <span class="sa-form-label">{{ this.$i18n.t("patient.idoneity.lblIdentityDocumentReleaser") }}</span
        ><b-form-input v-model="data.documentoIdentitaRilasciatoDa" :disabled="!edit"></b-form-input>
        <span class="sa-form-label">{{ this.$i18n.t("patient.idoneity.lblIdentityDocumentDateRelease") }}</span>
        <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="data.documentoIdentitaRilasciatoIl" :disabled="!edit" type="datetime"></date-picker>
      </b-col>
    </b-row>
    <b-form-group> </b-form-group>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";

export default {
  props: ["data", "edit"],
  components: { DatePicker },
};
</script>
