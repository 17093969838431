<template>
    <sa-page-view-edit ref="pgaView" :data="data" :pathResource="pathResource" :backVisible="true" :saveVisible="true" :titolo="data.titolo" :linkback="linkback" :linkedit="linkedit" :showmolalloading="showmolalloading">
        <template slot="body">

            <b-row>
                <b-col cols="12" lg="2" md="2" class="sa-text-right"><b>{{ this.$i18n.t("pazienti.lblLaboratoryData")}}</b></b-col>
            </b-row>


            <b-row>
                <b-col cols="12" lg="2" md="2" class="sa-text-right">{{ this.$i18n.t("pazienti.lblFastingGlycemia")}}:</b-col>
                <b-col cols="12" lg="4" md="4" class="sa-text-left">
                    <b-form-input v-model="data.glicemiaDigiuno" size="sm" placeholder="Glicemia digiuno(mg/ml)" required></b-form-input>
                </b-col>


                <b-col cols="12" lg="2" md="2" class="sa-text-right">{{ this.$i18n.t("pazienti.lblGlycemiaAfterMeal")}}:</b-col>
                <b-col cols="12" lg="4" md="4" class="sa-text-left">
                    <b-form-input v-model="data.glicemiaDopoPasto" size="sm" placeholder="Glicemia dopo pasto" required></b-form-input>
                </b-col>

                <b-col cols="12" lg="2" md="2" class="sa-text-right">{{ this.$i18n.t("pazienti.lblFastingPeptideC")}}:</b-col>
                <b-col cols="12" lg="4" md="4" class="sa-text-left">
                    <b-form-input v-model="data.peptideCDigiuno" size="sm" placeholder="Peptide C digiuno" required></b-form-input>
                </b-col>


                <b-col cols="12" lg="2" md="2" class="sa-text-right">{{ this.$i18n.t("pazienti.lblPeptideCAfterMeal")}}:</b-col>
                <b-col cols="12" lg="4" md="4" class="sa-text-left">
                    <b-form-input v-model="data.peptideCDopoPasto" size="sm" placeholder="Peptide C dopo pasto" required></b-form-input>
                </b-col>
            
            </b-row>

            <b-row>
                <b-col cols="12" lg="2" md="2" class="sa-text-right">{{ this.$i18n.t("pazienti.lblFractionalGlycurialI")}}:</b-col>
                <b-col cols="12" lg="10" md="10" class="sa-text-left">
                    <b-form-input v-model="data.glicuriaFrazionataI" size="sm" placeholder="Glicuria Frazionata I" required></b-form-input>
                </b-col>
            </b-row>

            <b-row>
                <b-col cols="12" lg="2" md="2" class="sa-text-right">{{ this.$i18n.t("pazienti.lblFractionalGlycurialII")}}:</b-col>
                <b-col cols="12" lg="10" md="10" class="sa-text-left">
                    <b-form-input v-model="data.glicuriaFrazionataII" size="sm" placeholder="Glicuria Frazionata II" required></b-form-input>
                </b-col>
            </b-row>



            <b-row>
                <b-col cols="12" lg="2" md="2" class="sa-text-right">{{ this.$i18n.t("pazienti.lblPancreasAntibodies")}}:</b-col>
                <b-col cols="12" lg="4" md="4" class="sa-text-left">
                    <b-form-input v-model="data.anticorpiPancreas" size="sm" placeholder="Anticorpi Pancreas" required></b-form-input>
                </b-col>


                <b-col cols="12" lg="2" md="2" class="sa-text-right">{{ this.$i18n.t("pazienti.lblInsulinAntibodies")}}:</b-col>
                <b-col cols="12" lg="4" md="4" class="sa-text-left">
                    <b-form-input v-model="data.anticorpiInsulina" size="sm" placeholder="Anticorpi Insulina" required></b-form-input>
                </b-col>
            </b-row>


            <b-row>
                <b-col cols="12" lg="2" md="2" class="sa-text-right">{{ this.$i18n.t("pazienti.lblOggtGlycemia")}}:</b-col>
                <b-dropdown text="NON RILEVATO" block variant="secondary" class="m-1">
                    <b-dropdown-item ></b-dropdown-item>
                    <b-dropdown-item >{{ this.$i18n.t("pazienti.lblYES")}}</b-dropdown-item>
                    <b-dropdown-item >{{ this.$i18n.t("pazienti.lblNO")}}</b-dropdown-item>
                    
                </b-dropdown>

                <b-col cols="12" lg="2" md="2" class="sa-text-right">{{ this.$i18n.t("pazienti.lblOggtInsulin")}}:</b-col>
                <b-dropdown text="NON RILEVATO" block variant="secondary" class="m-1">
                    <b-dropdown-item ></b-dropdown-item>
                    <b-dropdown-item >{{ this.$i18n.t("pazienti.lblYES")}}</b-dropdown-item>
                    <b-dropdown-item >{{ this.$i18n.t("pazienti.lblNO")}}</b-dropdown-item>
                </b-dropdown>
            </b-row>

            <b-row>
                <b-col cols="12" lg="2" md="2" class="sa-text-right">{{ this.$i18n.t("pazienti.lblTotalCholesterol")}}:</b-col>
                <b-col cols="12" lg="10" md="10" class="sa-text-left">
                    <b-form-input v-model="data.colesteroloTot" size="sm" placeholder="Colesterolo Totale" required></b-form-input>
                </b-col>
            </b-row>


            <b-row>
                <b-col cols="12" lg="2" md="2" class="sa-text-right">Hdt:</b-col>
                <b-col cols="12" lg="4" md="4" class="sa-text-left">
                    <b-form-input v-model="data.hdt" size="sm" placeholder="Hdt" required></b-form-input>
                </b-col>

                <b-col cols="12" lg="2" md="2" class="sa-text-right">Ldt:</b-col>
                <b-col cols="12" lg="4" md="4" class="sa-text-left">
                    <b-form-input v-model="data.ldt" size="sm" placeholder="Ldt" required></b-form-input>
                </b-col>

                <b-col cols="12" lg="2" md="2" class="sa-text-right">{{ this.$i18n.t("pazienti.lblTriglycerides")}}:</b-col>
                <b-col cols="12" lg="4" md="4" class="sa-text-left">
                    <b-form-input v-model="data.trigliceridi" size="sm" placeholder="Trigliceridi" required></b-form-input>
                </b-col>

                <b-col cols="12" lg="2" md="2" class="sa-text-right">{{ this.$i18n.t("pazienti.lblLipidogram")}}:</b-col>
                <b-col cols="12" lg="4" md="4" class="sa-text-left">
                    <b-form-input v-model="data.lipidogramma" size="sm" placeholder="Lipidogramma" required></b-form-input>
                </b-col>

            </b-row>

            <b-row>
                <b-col cols="12" lg="2" md="2" class="sa-text-right">Ast:</b-col>
                <b-col cols="12" lg="2" md="2" class="sa-text-left">
                    <b-form-input v-model="data.ast" size="sm" placeholder="Ast" required></b-form-input>
                </b-col>

                <b-col cols="12" lg="2" md="2" class="sa-text-right">Alt:</b-col>
                <b-col cols="12" lg="2" md="2" class="sa-text-left">
                    <b-form-input v-model="data.alt" size="sm" placeholder="Alt" required></b-form-input>
                </b-col>

                <b-col cols="12" lg="2" md="2" class="sa-text-right">{{ this.$i18n.t("pazienti.lblAlkalinePhosphatase")}}:</b-col>
                <b-col cols="12" lg="2" md="2" class="sa-text-left">
                    <b-form-input v-model="data.fosfatasialcalina" size="sm" placeholder="Fosfatasi Alcalina" required></b-form-input>
                </b-col>
            </b-row>


            <b-row>
                <b-col cols="12" lg="2" md="2" class="sa-text-right">{{ this.$i18n.t("pazienti.lblTotalBilirubin")}}:</b-col>
                <b-col cols="12" lg="2" md="2" class="sa-text-left">
                    <b-form-input v-model="data.bilirubinaTot" size="sm" placeholder="Bilirubina Totale" required></b-form-input>
                </b-col>

                <b-col cols="12" lg="2" md="2" class="sa-text-right">{{ this.$i18n.t("pazienti.lblBilirubinI")}}:</b-col>
                <b-col cols="12" lg="2" md="2" class="sa-text-left">
                    <b-form-input v-model="data.bilirubinaI" size="sm" placeholder="Bilirubina I" required></b-form-input>
                </b-col>

                <b-col cols="12" lg="2" md="2" class="sa-text-right">Tp:</b-col>
                <b-col cols="12" lg="2" md="2" class="sa-text-left">
                    <b-form-input v-model="data.tp" size="sm" placeholder="Tp" required></b-form-input>
                </b-col>
            </b-row>

            <b-row>
                <b-col cols="12" lg="2" md="2" class="sa-text-right">{{ this.$i18n.t("pazienti.lblTotalProteins")}}:</b-col>
                <b-col cols="12" lg="2" md="2" class="sa-text-left">
                    <b-form-input v-model="data.proteineTot" size="sm" placeholder="Proteine Totali" required></b-form-input>
                </b-col>

                <b-col cols="12" lg="2" md="2" class="sa-text-right">AG:</b-col>
                <b-col cols="12" lg="2" md="2" class="sa-text-left">
                    <b-form-input v-model="data.ag" size="sm" placeholder="AG" required></b-form-input>
                </b-col>

                <b-col cols="12" lg="2" md="2" class="sa-text-right">{{ this.$i18n.t("pazienti.lblElectrophoresis")}}:</b-col>
                <b-col cols="12" lg="2" md="2" class="sa-text-left">
                    <b-form-input v-model="data.elettroforesi" size="sm" placeholder="Elettroforesi" required></b-form-input>
                </b-col>
            </b-row>

            <b-row>
                <b-col cols="12" lg="2" md="2" class="sa-text-right">Azotemia:</b-col>
                <b-col cols="12" lg="2" md="2" class="sa-text-left">
                    <b-form-input v-model="data.azotemia" size="sm" placeholder="Azotemia" required></b-form-input>
                </b-col>

                <b-col cols="12" lg="2" md="2" class="sa-text-right">Uricemia:</b-col>
                <b-col cols="12" lg="2" md="2" class="sa-text-left">
                    <b-form-input v-model="data.uricemia" size="sm" placeholder="Uricemia" required></b-form-input>
                </b-col>

                <b-col cols="12" lg="2" md="2" class="sa-text-right">{{ this.$i18n.t("pazienti.lblCreatinine")}}:</b-col>
                <b-col cols="12" lg="2" md="2" class="sa-text-left">
                    <b-form-input v-model="data.creatinina" size="sm" placeholder="Creatinina" required></b-form-input>
                </b-col>
            </b-row>

            <b-row>
                <b-col cols="12" lg="2" md="2" class="sa-text-right">Clearance:</b-col>
                <b-col cols="12" lg="2" md="2" class="sa-text-left">
                    <b-form-input v-model="data.clearance" size="sm" placeholder="Clearance" required></b-form-input>
                </b-col>

                <b-col cols="12" lg="2" md="2" class="sa-text-right">Gr (/mmc):</b-col>
                <b-col cols="12" lg="2" md="2" class="sa-text-left">
                    <b-form-input v-model="data.gr" size="sm" placeholder="Gr (/mcc)" required></b-form-input>
                </b-col>

                <b-col cols="12" lg="2" md="2" class="sa-text-right">Gb (/mmc):</b-col>
                <b-col cols="12" lg="2" md="2" class="sa-text-left">
                    <b-form-input v-model="data.gb" size="sm" placeholder="Gb (/mcc)" required></b-form-input>
                </b-col>
            </b-row>


            <b-row>
                <b-col cols="12" lg="2" md="2" class="sa-text-right">Hb (g/dt):</b-col>
                <b-col cols="12" lg="2" md="2" class="sa-text-left">
                    <b-form-input v-model="data.hb" size="sm" placeholder="Hb (g/dt)" required></b-form-input>
                </b-col>

                <b-col cols="12" lg="2" md="2" class="sa-text-right">Ht (%):</b-col>
                <b-col cols="12" lg="2" md="2" class="sa-text-left">
                    <b-form-input v-model="data.ht" size="sm" placeholder="Ht (%)" required></b-form-input>
                </b-col>

                <b-col cols="12" lg="2" md="2" class="sa-text-right">N (%):</b-col>
                <b-col cols="12" lg="2" md="2" class="sa-text-left">
                    <b-form-input v-model="data.n" size="sm" placeholder="N (%)" required></b-form-input>
                </b-col>
            </b-row>

            <b-row>

                <b-col cols="12" lg="2" md="2" class="sa-text-right">L (%):</b-col>
                <b-col cols="12" lg="2" md="2" class="sa-text-left">
                    <b-form-input v-model="data.l" size="sm" placeholder="L (%)" required></b-form-input>
                </b-col>

                <b-col cols="12" lg="2" md="2" class="sa-text-right">E (%):</b-col>
                <b-col cols="12" lg="2" md="2" class="sa-text-left">
                    <b-form-input v-model="data.e" size="sm" placeholder="E (%)" required></b-form-input>
                </b-col>

                <b-col cols="12" lg="2" md="2" class="sa-text-right">M (%):</b-col>
                <b-col cols="12" lg="2" md="2" class="sa-text-left">
                    <b-form-input v-model="data.m" size="sm" placeholder="M (%)" required></b-form-input>
                </b-col>

            </b-row>
            <b-row>
                <b-col cols="12" lg="2" md="2" class="sa-text-right">Hbaic:</b-col>
                <b-col cols="12" lg="4" md="4" class="sa-text-left">
                    <b-form-input v-model="data.hbaic" size="sm" placeholder="Hbaic" required></b-form-input>
                </b-col>

                <b-col cols="12" lg="2" md="2" class="sa-text-right">{{ this.$i18n.t("pazienti.lblFructosamine")}}:</b-col>
                <b-col cols="12" lg="4" md="4" class="sa-text-left">
                    <b-form-input v-model="data.fruttosamina" size="sm" placeholder="Fruttosamina" required></b-form-input>
                </b-col>
            </b-row>


            <b-row>
                <b-col cols="12" lg="2" md="2" class="sa-text-right">{{ this.$i18n.t("pazienti.lblExperimentalTests")}}:</b-col>
                <b-col cols="12" lg="10" md="10" class="sa-text-left">
                    <b-form-input v-model="data.esamisperimentali" size="sm" placeholder="Esami Sperimentali" required></b-form-input>
                </b-col>
            </b-row>


            <b-row>
                <b-col cols="12" lg="2" md="2" class="sa-text-right">{{ this.$i18n.t("pazienti.lblProgressiveEvent") }}:</b-col>
                <b-col cols="6" lg="4" md="4" class="sa-text-left">
                    <b-form-input v-model="data.progressivoEvento" size="sm" placeholder="Progressivo Evento" required></b-form-input>
                </b-col>
                <b-col cols="12" lg="2" md="2" class="sa-text-right">{{ this.$i18n.t("pazienti.lblDateEvent") }}:</b-col>
                <b-col cols="12" lg="4" md="4" class="sa-text-right">
                    <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="data.dataNascita" type="date" placeholder="Data Nascita"></date-picker>
                </b-col>
            </b-row>

            <b-row>
                <b-col cols="12" lg="2" md="2" class="sa-text-right">{{ this.$i18n.t("pazienti.lblPersonInCharge")}}:</b-col>
                <b-col cols="12" lg="10" md="10" class="sa-text-left">
                    <b-form-input v-model="data.responsabile" size="sm" placeholder="Responsabile" required></b-form-input>
                </b-col>
            </b-row>

        </template>
    </sa-page-view-edit>
</template>
<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import { SaPageViewEdit } from "../../../template/sa/components/layout/contents";
import moment from "moment";
import axios from "axios";
export default {
    components: { SaPageViewEdit, DatePicker },
    //components: { SaPageViewEdit},
    data() {
        return {
            pathRest: "/eventidiabetologiadatidilaboratorio",
            pathResource: "/eventidiabetologiadatidilaboratorio",
            //linkback: "/pazientidiabetologia",
            linkback: "/paziente",

            linkedit: null,
            showmolalloading: false,
            id: "-1",
            data: {},
        };
    },
    computed: {
        dataAavvioData: {
            get: function() {
                return new Date(this.data.avvioData);
            },
            set: function(value) {
                this.data.avvioData = moment(String(value)).valueOf();
            },
        },
    },
    mounted: function() {
        let me = this;
        me.id = this.$route.params.id;
        if (me.id !== "-1") {
            me.linkback = me.linkback + "/view/" + me.id;
        }
        me.loadData();
    },
    methods: {
        formatDate(value) {
            if (value) {
                return moment(new Date(value)).format("DD-MM-YYYY HH:mm:ss");
            } else {
                return "---";
            }
        },
        loadData() {
            let me = this;
            me.showmolalloading = false;
            //:if (me.id === "-1") {
            //    me.linkback = "/pazientidiabetologia";
            //    me.showmolalloading = false;
            //} else {
            //    me.linkback = "/pazientidiabetologia/view/" + me.id;
            //    me.showmolalloading = true;
            //    let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
            //    axios.get(link + me.id).then((response) => {
            //        me.data = response.data.data;
            //        me.showmolalloading = false;
            //    });
            //}
        },
        onSave() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource;
            let jsonData = JSON.stringify(me.data);

            me.showmolalloading = true;
            if (me.id == "-1") {
                axios
                    .post(link, jsonData)
                    .then((response) => {
                        me.data = response.data.data;
                        me.showmolalloading = false;
                        me.$router.replace(me.routeLink).catch((err) => {
                            err;
                        });
                    })
                    .catch((error) => {
                        me.showmolalloading = false;
                        this.$bvModal
                            .msgBoxOk(error.response.data.messaggio, {
                                title: "ATTENZIONE",
                                size: "sm",
                                okVariant: "outline-danger",
                                headerClass: "sa-msg-header-danger",
                                footerClass: "p-2 border-top-0",
                                centered: true,
                            })
                            .then((value) => {
                                this.boxTwo = value;
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                    });
            } else {
                axios
                    .put(link + "/" + me.id, jsonData)
                    .then((response) => {
                        me.gruppo = response.data.data;
                        me.$refs["sa-modal-loading"].close();
                        me.$router.replace(me.routeLink).catch((err) => {
                            err;
                        });
                    })
                    .catch((error) => {
                        me.$refs["sa-modal-loading"].close();
                        this.$bvModal
                            .msgBoxOk(error.response.data.messaggio, {
                                title: "ATTENZIONE",
                                size: "sm",
                                okVariant: "outline-danger",
                                headerClass: "sa-msg-header-danger",
                                footerClass: "p-2 border-top-0",
                                centered: true,
                            })
                            .then((value) => {
                                this.boxTwo = value;
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                    });
            }
        },
        onBack() {
            let me = this;
            me.$router.replace(me.routeLink).catch((err) => {
                err;
            });
        },
    },
};
</script>