<template>
    <sa-page-layout :btnAnnullaVisible="true" :btnSaveVisible="true" :data="data" :linkback="linkback" :linkedit="linkedit" :pathResource="pathResource" :showModalLoading="showModalLoading">
        <template slot="toolbar-title">
            <paziente-anagrafica :linkPatient="linkPatient" :idPaziente="idPaziente"></paziente-anagrafica>
        </template>
        <template slot="toolbar-row">
            <paziente-dashboard-component :idPaziente="idPaziente"></paziente-dashboard-component>
        </template>
        <template slot="table-body">
            <div class="sa-tab-scroll">
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                        <b-card class="mb-4">
                            <b-card-body class="text-center">
                                <b-row>
                                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                        <h4 class="text-left">
                                            {{ this.$i18n.t("patient.diet.lblDietType") }}
                                            <a data-toggle="modal" data-target="#collapse-info" href="#collapse-info" @click="showModal()" onclick="return false"><b-icon icon="info-circle" font-scale="1"></b-icon></a>
                                        </h4>
                                        <div>
                                            <b-form-select v-model="data.tipo" value-field="value" text-field="text" :options="tipiDieta" @change="setDescription(data.tipo)"></b-form-select>
                                        </div>
                                        <b-modal id="collapse-info" centered :title="nomeDieta" size="lg" v-if="data.descrizione !== null" hide-footer>
                                            <b-row class="text-justify" style="padding-left:10px; padding-right:10px;"
                                                ><b-col cols="12"
                                                    ><span>{{ data.descrizione }}</span></b-col
                                                >
                                            </b-row>
                                            <hr />
                                            <div style="height: 30px;"></div>
                                        </b-modal>
                                        <b-modal id="collapse-edit" centered :title="nomeDieta" size="lg" v-if="data.descrizione !== null">
                                            <b-form-textarea v-model="data.descrizione" rows="9"></b-form-textarea>
                                        </b-modal>
                                    </b-col>
                                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                        <h4 class="text-left">{{ this.$i18n.t("patient.diet.lblStartDate") }}</h4>
                                        <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="data.data" type="datetime" placeholder="Data Dal"></date-picker>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                        <h4 class="text-left">{{ this.$i18n.t("patient.diet.lblNote") }}</h4>
                                        <b-form-textarea v-model="data.note" placeholder="Note" no-resize></b-form-textarea>
                                    </b-col>
                                </b-row>
                            </b-card-body>
                        </b-card>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                        <b-card class="mb-4">
                            <span><b>Risposte Questionario Dieta</b> </span>
                        </b-card>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="4">
                        <b-card>
                            <b-card-title style="width: 100%">
                                <b-row>
                                    <b-col cols="4">
                                        <label for="">
                                            <img src="@/assets/images/users/breakfast.svg" alt="" width="85px /" />
                                        </label>
                                    </b-col>
                                    <b-col cols="8">
                                        <label for="">
                                            <h4 class="sa-card-title-position">{{ this.$i18n.t("patient.diet.lblBreakfast") }}</h4>
                                        </label>
                                    </b-col>
                                </b-row>
                            </b-card-title>
                            <b-card-body>
                                <b-row v-for="(item, index) in breakfastData" :key="index">
                                    <b-col cols="4">
                                        <label>
                                            <p class="text-muted">{{ item.pietanza }}</p>
                                        </label>
                                    </b-col>
                                    <b-col cols="3">
                                        <strong>{{ getTranslater("patient.diet.lblQuantity") }}</strong> {{ item.quantita }} ({{ item.unitaMisura }})
                                    </b-col>
                                    <b-col cols="3">
                                        <strong>{{ getTranslater("patient.diet.lblKcal") }}:</strong> {{ item.kiloCal }}
                                    </b-col>
                                    <b-col cols="2">
                                        <b-button id="btnDelete" size="sm" variant="btn btn-outline-danger no-text" @click="onDelete('BREAKFAST', item)"><b-icon icon="trash"></b-icon></b-button>
                                    </b-col>
                                </b-row>
                            </b-card-body>
                            <b-button id="btnBreakfast" class="btn btn-block btn--md btn-light waves-effect waves-light btn-card-bottom" @click="onAddNewDish('BREAKFAST')"><b-icon icon="plus" variant="primary"></b-icon>{{ this.$i18n.t("global.lblAdd") }}</b-button>
                        </b-card>
                    </b-col>

                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="4">
                        <b-card>
                            <b-card-title style="width: 100%">
                                <b-row>
                                    <b-col cols="4">
                                        <label for="">
                                            <img src="@/assets/images/users/apple.svg" alt="" width="85px /" style="float:right" />
                                        </label>
                                    </b-col>
                                    <b-col cols="8">
                                        <label for="">
                                            <h4 class="sa-card-title-position">{{ this.$i18n.t("patient.diet.lblMorningSnack") }}</h4>
                                        </label>
                                    </b-col>
                                </b-row>
                            </b-card-title>
                            <b-card-body>
                                <b-row v-for="(item, index) in morningSnackData" :key="index">
                                    <b-col cols="4">
                                        <label>
                                            <p class="text-muted">{{ item.pietanza }}</p>
                                        </label>
                                    </b-col>
                                    <b-col cols="3">
                                        <strong>{{ getTranslater("patient.diet.lblQuantity") }}:</strong> {{ item.quantita }} ({{ item.unitaMisura }})</b-col
                                    >
                                    <b-col cols="3">
                                        <strong>{{ getTranslater("patient.diet.lblKcal") }}:</strong> {{ item.kiloCal }}
                                    </b-col>
                                    <b-col cols="2">
                                        <b-button id="btnDelete" size="sm" variant="btn btn-outline-danger no-text" @click="onDelete('MORNINGSNACK', item)"><b-icon icon="trash"></b-icon></b-button>
                                    </b-col>
                                </b-row>
                            </b-card-body>
                            <b-button id="btnMorningSnack" class="btn btn-block btn--md btn-light waves-effect waves-light btn-card-bottom" @click="onAddNewDish('MORNINGSNACK')"><b-icon icon="plus" variant="primary"></b-icon>{{ this.$i18n.t("global.lblAdd") }}</b-button>
                        </b-card>
                    </b-col>

                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="4">
                        <b-card>
                            <b-card-title style="width: 100%">
                                <b-row>
                                    <b-col cols="4">
                                        <label for="">
                                            <img src="@/assets/images/users/lunch-bag.svg" alt="" width="85px /" />
                                        </label>
                                    </b-col>
                                    <b-col cols="8">
                                        <label for="">
                                            <h4 class="sa-card-title-position">{{ this.$i18n.t("patient.diet.lblLunch") }}</h4>
                                        </label>
                                    </b-col>
                                </b-row>
                            </b-card-title>
                            <b-card-body>
                                <b-row v-for="(item, index) in lunchData" :key="index">
                                    <b-col cols="4">
                                        <label>
                                            <p class="text-muted">{{ item.pietanza }}</p>
                                        </label>
                                    </b-col>
                                    <b-col cols="3">
                                        <strong>{{ getTranslater("patient.diet.lblQuantity") }}:</strong> {{ item.quantita }} ({{ item.unitaMisura }})</b-col
                                    >
                                    <b-col cols="3">
                                        <strong>{{ getTranslater("patient.diet.lblKcal") }}:</strong> {{ item.kiloCal }}
                                    </b-col>
                                    <b-col cols="2">
                                        <b-button id="btnDelete" size="sm" variant="btn btn-outline-danger no-text" @click="onDelete('LUNCH', item)"><b-icon icon="trash"></b-icon></b-button>
                                    </b-col>
                                </b-row>
                            </b-card-body>
                            <b-button id="btnLunch" class="btn btn-block btn--md btn-light waves-effect waves-light btn-card-bottom" @click="onAddNewDish('LUNCH')"><b-icon icon="plus" variant="primary"></b-icon>{{ this.$i18n.t("global.lblAdd") }}</b-button>
                        </b-card>
                    </b-col>

                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="4">
                        <b-card>
                            <b-card-title style="width: 100%">
                                <b-row>
                                    <b-col cols="4">
                                        <label for="">
                                            <img src="@/assets/images/users/energy-food.svg" alt="" width="85px /" />
                                        </label>
                                    </b-col>
                                    <b-col cols="8">
                                        <label for="">
                                            <h4 class="sa-card-title-position">{{ this.$i18n.t("patient.diet.lblAfternoonSnack") }}</h4>
                                        </label>
                                    </b-col>
                                </b-row>
                            </b-card-title>
                            <b-card-body>
                                <b-row v-for="(item, index) in afternoonSnackData" :key="index">
                                    <b-col cols="4">
                                        <label>
                                            <p class="text-muted">{{ item.pietanza }}</p>
                                        </label>
                                    </b-col>
                                    <b-col cols="3">
                                        <strong>{{ getTranslater("patient.diet.lblQuantity") }}:</strong> {{ item.quantita }} ({{ item.unitaMisura }})</b-col
                                    >
                                    <b-col cols="3">
                                        <strong>{{ getTranslater("patient.diet.lblKcal") }}:</strong> {{ item.kiloCal }}
                                    </b-col>
                                    <b-col cols="2">
                                        <b-button id="btnDelete" size="sm" variant="btn btn-outline-danger no-text" @click="onDelete('AFTERNOONSNACK', item)"><b-icon icon="trash"></b-icon></b-button>
                                    </b-col>
                                </b-row>
                            </b-card-body>
                            <b-button id="btnAfternoonSnack" class="btn btn-block btn--md btn-light waves-effect waves-light btn-card-bottom" @click="onAddNewDish('AFTERNOONSNACK')"><b-icon icon="plus" variant="primary"></b-icon>{{ this.$i18n.t("global.lblAdd") }}</b-button>
                        </b-card>
                    </b-col>

                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="4">
                        <b-card>
                            <b-card-title style="width: 100%">
                                <b-row>
                                    <b-col cols="4">
                                        <label for="">
                                            <img src="@/assets/images/users/dinner.svg" alt="" width="85px /" />
                                        </label>
                                    </b-col>
                                    <b-col cols="8">
                                        <label for="">
                                            <h4 class="sa-card-title-position">{{ this.$i18n.t("patient.diet.lblDinner") }}</h4>
                                        </label>
                                    </b-col>
                                </b-row>
                            </b-card-title>
                            <b-card-body>
                                <b-row v-for="(item, index) in dinnerData" :key="index">
                                    <b-col cols="4">
                                        <label>
                                            <p class="text-muted">{{ item.pietanza }}</p>
                                        </label>
                                    </b-col>
                                    <b-col cols="3">
                                        <strong>{{ getTranslater("patient.diet.lblQuantity") }}:</strong> {{ item.quantita }} ({{ item.unitaMisura }})</b-col
                                    >
                                    <b-col cols="3">
                                        <strong>{{ getTranslater("patient.diet.lblKcal") }}:</strong> {{ item.kiloCal }}
                                    </b-col>
                                    <b-col cols="2">
                                        <b-button id="btnDelete" size="sm" variant="btn btn-outline-danger no-text" @click="onDelete('DINNER', item)"><b-icon icon="trash"></b-icon></b-button>
                                    </b-col>
                                </b-row>
                            </b-card-body>
                            <b-button id="btnDinner" class="btn btn-block btn--md btn-light waves-effect waves-light btn-card-bottom" @click="onAddNewDish('DINNER')"><b-icon icon="plus" variant="primary"></b-icon>{{ this.$i18n.t("global.lblAdd") }}</b-button>
                        </b-card>
                    </b-col>

                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="4">
                        <b-card>
                            <b-card-title style="width: 100%">
                                <b-row>
                                    <b-col cols="4">
                                        <label for="">
                                            <img src="@/assets/images/users/fruits.svg" alt="" width="85px /" />
                                        </label>
                                    </b-col>
                                    <b-col cols="8">
                                        <label for="">
                                            <h4 class="sa-card-title-position">{{ this.$i18n.t("patient.diet.lblNightSnack") }}</h4>
                                        </label>
                                    </b-col>
                                </b-row>
                            </b-card-title>
                            <b-card-body>
                                <b-row v-for="(item, index) in nightSnackData" :key="index">
                                    <b-col cols="4">
                                        <label>
                                            <p class="text-muted">{{ item.pietanza }}</p>
                                        </label>
                                    </b-col>
                                    <b-col cols="3">
                                        <strong>{{ getTranslater("patient.diet.lblQuantity") }}:</strong> {{ item.quantita }} ({{ item.unitaMisura }})</b-col
                                    >
                                    <b-col cols="3">
                                        <strong>{{ getTranslater("patient.diet.lblKcal") }}:</strong> {{ item.kiloCal }}
                                    </b-col>
                                    <b-col cols="2">
                                        <b-button id="btnDelete" size="sm" variant="btn btn-outline-danger no-text" @click="onDelete('NIGHTSNACK', item)"><b-icon icon="trash"></b-icon></b-button>
                                    </b-col>
                                </b-row>
                            </b-card-body>
                            <b-button id="btnNightSnack" class="btn btn-block btn--md btn-light waves-effect waves-light btn-card-bottom" @click="onAddNewDish('NIGHTSNACK')"><b-icon icon="plus" variant="primary"></b-icon>{{ this.$i18n.t("global.lblAdd") }}</b-button>
                        </b-card>
                    </b-col>

                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="4">
                        <b-card>
                            <b-card-title style="width: 100%">
                                <b-row>
                                    <b-col cols="4">
                                        <label for="">
                                            <img src="@/assets/images/users/salt.svg" alt="" width="85px /" />
                                        </label>
                                    </b-col>
                                    <b-col cols="8">
                                        <label for="">
                                            <h4 class="sa-card-title-position">{{ this.$i18n.t("patient.diet.lblSalt") }}</h4>
                                        </label>
                                    </b-col>
                                </b-row>
                            </b-card-title>
                            <b-card-body>
                                <b-row v-for="(item, index) in saltData" :key="index">
                                    <b-col cols="4">
                                        <label>
                                            <p class="text-muted">{{ item.pietanza }}</p>
                                        </label>
                                    </b-col>
                                    <b-col cols="3">
                                        <strong>{{ getTranslater("patient.diet.lblQuantity") }}:</strong> {{ item.quantita }} ({{ item.unitaMisura }})</b-col
                                    >
                                    <b-col cols="3">
                                        <strong>{{ getTranslater("patient.diet.lblKcal") }}:</strong> {{ item.kiloCal }}
                                    </b-col>
                                    <b-col cols="2">
                                        <b-button id="btnDelete" size="sm" variant="btn btn-outline-danger no-text" @click="onDelete('SALT', item)"><b-icon icon="trash"></b-icon></b-button>
                                    </b-col>
                                </b-row>
                            </b-card-body>
                            <b-button id="btnSalt" class="btn btn-block btn--md btn-light waves-effect waves-light btn-card-bottom" @click="onAddNewDish('SALT')"><b-icon icon="plus" variant="primary"></b-icon>{{ this.$i18n.t("global.lblAdd") }}</b-button>
                        </b-card>
                    </b-col>
                </b-row>
            </div>
            <div>
                <b-modal id="mdlAggiungiPietanza" ref="mdlAggiungiPietanza" @ok="onOk">
                    <b-row>
                        <b-col>
                            <h5 class="text-center">{{ getPasto(nuovaPietanza.pasto) }}</h5>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <span class="sa-form-label text-center">{{ this.$i18n.t("patient.diet.lblDish") }}</span>
                            <b-form-input :value="nuovaPietanza.pietanza" v-model="nuovaPietanza.pietanza" placeholder="Pietanza"></b-form-input>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                            <span class="sa-form-label">{{ this.$i18n.t("patient.diet.lblQuantity") }}</span>
                            <b-form-input v-model="nuovaPietanza.quantita" placeholder="Quantità"></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <span class="sa-form-label">{{ this.$i18n.t("patient.diet.lblUnitOfMeasure") }}</span>
                            <b-form-input v-model="nuovaPietanza.unitaMisura" placeholder="Unità di Misura"></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                            <span class="sa-form-label">{{ this.$i18n.t("patient.diet.lblKcal") }}</span>
                            <b-form-input v-model="nuovaPietanza.kiloCal" placeholder="Kcal"></b-form-input>
                        </b-col>
                    </b-row>
                </b-modal>
            </div>
        </template>
    </sa-page-layout>
</template>
<script>
import axios from "axios";
import PazienteAnagrafica from "./PazienteAnagrafica.vue";
import DatePicker from "vue2-datepicker";
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
import PazienteDashboardComponent from "./PazienteDashboardComponent.vue";
export default {
    components: { PazienteAnagrafica, DatePicker, SaPageLayout, PazienteDashboardComponent },
    data() {
        return {
            showModalLoading: false,
            linkback: "/paziente/diete",
            linkedit: "",
            pathResource: "/dieta",
            nuovaPietanza: { pasto: "", pietanza: "", quantita: "", unitaMisura: "", kiloCal: { type: Number } },
            breakfastData: [],
            morningSnackData: [],
            lunchData: [],
            descrizioneCustom: null,
            afternoonSnackData: [],
            dinnerData: [],
            nightSnackData: [],
            saltData: [],
            arraySelezionato: [],
            data: { pazienteDietaDettaglio: [] },
            nomeDieta: null,
            lang: {
                formatLocale: {
                    firstDayOfWeek: 1,
                },
                monthBeforeYear: true,
            },
            tipiDieta: [
                { text: this.$i18n.t("patient.diet.tipologies.lblSelect"), value: null, description: null },
                { text: this.$i18n.t("patient.diet.tipologies.lblItalianDiet"), value: "D-IT", description: this.$i18n.t("patient.diet.tipologies.DescItalianDiet") },
                { text: this.$i18n.t("patient.diet.tipologies.lblPritikinDiet"), value: "D-PRT", description: this.$i18n.t("patient.diet.tipologies.DescPritikinDiet") },
                { text: this.$i18n.t("patient.diet.tipologies.lblZoneDiet"), value: "D-ZN", description: this.$i18n.t("patient.diet.tipologies.DescZoneDiet") },
                { text: this.$i18n.t("patient.diet.tipologies.lblScarsdaleDiet"), value: "D-SD", description: this.$i18n.t("patient.diet.tipologies.DescScarsdaleDiet") },
                { text: this.$i18n.t("patient.diet.tipologies.lblWWDiet"), value: "D-WW", description: this.$i18n.t("patient.diet.tipologies.DescWWDiet") },
                { text: this.$i18n.t("patient.diet.tipologies.lblMacrobioticDiet"), value: "D-MAC", description: this.$i18n.t("patient.diet.tipologies.DescMacrobioticDiet") },
                { text: this.$i18n.t("patient.diet.tipologies.lblKetogenicDiet"), value: "D-CHT", description: this.$i18n.t("patient.diet.tipologies.DescKetogenicDiet") },
                { text: this.$i18n.t("patient.diet.tipologies.lblDASHDiet"), value: "D-DSH", description: this.$i18n.t("patient.diet.tipologies.DescDASHDiet") },
                { text: this.$i18n.t("patient.diet.tipologies.lblPaleolithicDiet"), value: "D-PLL", description: this.$i18n.t("patient.diet.tipologies.DescPaleolithicDiet") },
                { text: this.$i18n.t("patient.diet.tipologies.lblFasting"), value: "FST", description: this.$i18n.t("patient.diet.tipologies.DescFasting") },
                { text: this.$i18n.t("patient.diet.tipologies.lblWaterDiet"), value: "D-WTR", description: this.$i18n.t("patient.diet.tipologies.DescWaterDiet") },
                { text: this.$i18n.t("patient.diet.tipologies.lblSemiLiquidDiet"), value: "D-WTR", description: this.$i18n.t("patient.diet.tipologies.DescSemiLiquidDiet") },
                { text: this.$i18n.t("patient.diet.tipologies.lblLowResidueDiet"), value: "D-LRS", description: this.$i18n.t("patient.diet.tipologies.DescLowResidueDiet") },
                { text: this.$i18n.t("patient.diet.tipologies.lblLowSodiumDiet"), value: "D-LNa", description: this.$i18n.t("patient.diet.tipologies.DescLowSodiumDiet") },
                { text: this.$i18n.t("patient.diet.tipologies.lblDysphagiaDiet"), value: "D-DPH", description: this.$i18n.t("patient.diet.tipologies.DescDysphagiaDiet") },
                { text: this.$i18n.t("patient.diet.tipologies.lblAllergyDiet"), value: "D-DPH", description: this.$i18n.t("patient.diet.tipologies.DescAllergyDiet") },
                { text: this.$i18n.t("patient.diet.tipologies.lblOtherDiet"), value: "OTHER" },
            ],
        };
    },
    props: {
        utente: {
            type: Object,
            default: function() {
                return null;
            },
        },
    },
    computed: {
        idPaziente() {
            return this.$route.query.idPaziente;
        },
        linkPatient: {
            get: function() {
                let returnValue = "";
                if (this.utente.gruppo) {
                    if (this.utente.gruppo.menuGruppo) {
                        if (this.utente.gruppo.menuGruppo.length > 1) {
                            return "/paziente/view/" + this.idPaziente;
                        } else {
                            if (this.utente.gruppo.menuGruppo.length == 1) {
                                if (this.utente.gruppo.menuGruppo[0].primaryKey === "pazienti") {
                                    return "/paziente/view/" + this.idPaziente;
                                } else {
                                    return "/paziente/homepaziente";
                                }
                            }
                        }
                    }
                }
                return returnValue;
            },
        },
    },
    mounted: function() {
        let me = this;
        me.id = this.$route.params.id;
        me.linkback = "/paziente/diete?idPaziente=" + me.idPaziente;
        me.data.idPaziente = this.$route.query.idPaziente;
        if (!me.data.tipo) {
            me.data.tipo = null;
        }
        me.loadData();
    },
    methods: {
        showModal() {
            let me = this;
            this.getTipoDieta(me.data.tipo);
            if (me.data.tipo === "OTHER") {
                me.$bvModal.show("collapse-edit");
            } else {
                me.$bvModal.show("collapse-info");
            }
        },
        getTranslater(value) {
            return this.$i18n.t(value);
        },
        setDescription(tipo) {
            let me = this;
            if (me.descrizioneCustom === null) {
                me.descrizioneCustom = me.data.descrizione;
                console.log(me.descrizioneCustom);
            }
            try {
                me.tipiDieta.forEach((item) => {
                    if (tipo !== "OTHER") {
                        if (tipo === item.value) {
                            me.data.descrizione = item.description;
                        }
                    } else {
                        me.data.descrizione = me.descrizioneCustom;
                    }
                });
            } catch (err) {
                console.log(err);
            }
        },
        getPasto(value) {
            let returnValue = "";
            switch (value) {
                case "BREAKFAST":
                    returnValue = this.$i18n.t("patient.diet.lblBreakfast");
                    break;
                case "MORNINGSNACK":
                    returnValue = this.$i18n.t("patient.diet.lblMorningSnack");
                    break;
                case "LUNCH":
                    returnValue = this.$i18n.t("patient.diet.lblLunch");
                    break;
                case "AFTERNOONSNACK":
                    returnValue = this.$i18n.t("patient.diet.lblAfternoonSnack");
                    break;
                case "DINNER":
                    returnValue = this.$i18n.t("patient.diet.lblDinner");
                    break;
                case "NIGHTSNACK":
                    returnValue = this.$i18n.t("patient.diet.lblNightSnack");
                    break;
                case "SALT":
                    returnValue = this.$i18n.t("patient.diet.lblSalt");
                    break;
            }
            return returnValue;
        },
        getTipoDieta(tipo) {
            let me = this;
            me.tipiDieta.forEach((element) => {
                if (element.value === tipo) {
                    me.nomeDieta = null;
                    me.nomeDieta = element.text;
                }
            });
        },
        loadData() {
            let me = this;
            if (me.id === "-1") {
                me.showModalLoading = false;
            } else {
                me.showModalLoading = true;
                let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
                axios.get(link + me.id).then((response) => {
                    me.data = response.data.data;
                    me.setDescription(me.data.tipo);
                    me.getTipoDieta(me.data.tipo);
                    me.data.pazienteDietaDettaglio.forEach((element) => {
                        switch (element.pasto) {
                            case "BREAKFAST":
                                me.breakfastData.push(element);
                                break;
                            case "MORNINGSNACK":
                                me.morningSnackData.push(element);
                                break;
                            case "LUNCH":
                                me.lunchData.push(element);
                                break;
                            case "AFTERNOONSNACK":
                                me.afternoonSnackData.push(element);
                                break;
                            case "DINNER":
                                me.dinnerData.push(element);
                                break;
                            case "NIGHTSNACK":
                                me.nightSnackData.push(element);
                                break;
                            case "SALT":
                                me.saltData.push(element);
                                break;
                        }
                    });
                    me.showModalLoading = false;
                });
            }
        },
        onOk() {
            let me = this;
            let flag = true;
            me.arraySelezionato.forEach((element) => {
                if (element.pietanza === me.nuovaPietanza.pietanza) {
                    flag = false;
                    element.quantita = parseInt(element.quantita) + parseInt(me.nuovaPietanza.quantita);
                    element.kiloCal = parseInt(element.kiloCal) + parseInt(me.nuovaPietanza.kiloCal);
                    return;
                }
            });
            if (flag) {
                me.arraySelezionato.push(me.nuovaPietanza);
            }
            me.updateData();
        },
        onDelete(pasto, dettaglio) {
            let me = this;
            me.setSelectedArray(pasto);
            me.setSelectedArray(dettaglio);
            let deleteIndex = -1;
            me.arraySelezionato.forEach((element, index) => {
                if (element.pietanza === dettaglio.pietanza) {
                    deleteIndex = index;
                }
            });
            me.arraySelezionato.splice(deleteIndex, 1);
        },
        onAddNewDish(value) {
            let me = this;
            me.nuovaPietanza = {};
            me.setSelectedArray(value);
            me.nuovaPietanza.pasto = value;
            me.$refs.mdlAggiungiPietanza.show();
        },

        setSelectedArray(key) {
            let me = this;
            switch (key) {
                case "BREAKFAST":
                    me.arraySelezionato = me.breakfastData;
                    break;
                case "MORNINGSNACK":
                    me.arraySelezionato = me.morningSnackData;
                    break;
                case "LUNCH":
                    me.arraySelezionato = me.lunchData;
                    break;
                case "AFTERNOONSNACK":
                    me.arraySelezionato = me.afternoonSnackData;
                    break;
                case "DINNER":
                    me.arraySelezionato = me.dinnerData;
                    break;
                case "NIGHTSNACK":
                    me.arraySelezionato = me.nightSnackData;
                    break;
                case "SALT":
                    me.arraySelezionato = me.saltData;
                    break;
            }
        },
        updateData() {
            let me = this;
            me.data.pazienteDietaDettaglio = [];
            me.breakfastData.forEach((element) => {
                me.data.pazienteDietaDettaglio.push(element);
            });
            me.morningSnackData.forEach((element) => {
                me.data.pazienteDietaDettaglio.push(element);
            });
            me.lunchData.forEach((element) => {
                me.data.pazienteDietaDettaglio.push(element);
            });
            me.afternoonSnackData.forEach((element) => {
                me.data.pazienteDietaDettaglio.push(element);
            });
            me.dinnerData.forEach((element) => {
                me.data.pazienteDietaDettaglio.push(element);
            });
            me.nightSnackData.forEach((element) => {
                me.data.pazienteDietaDettaglio.push(element);
            });
            me.saltData.forEach((element) => {
                me.data.pazienteDietaDettaglio.push(element);
            });
            console.log(me.data.pazienteDietaDettaglio);
        },
    },
};
</script>
<style lang="css" scoped>
.btn-card-bottom {
    position: relative;
    top: 30px;
}
.sa-card-title-position {
    margin-top: 40px;
    margin-left: 5px;
}
</style>
