<template>
    <div style="margin-top: 10px">
        <div class="b-table-sticky-header">
            <b-table sticky-header ref="table" responsive stacked="xl" striped hover :items="items" :fields="fields" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                <template v-slot:cell(nominativoMedicoRichiedente)="{ item }"> {{ item.nomeMedicoRichiedente }} {{ item.cognomeMedicoRichiedente }} </template>
            </b-table>
        </div>
    </div>
</template>
<script>
import moment from "moment";
import UtilityMixin from "../../utility/UtilityMixin";
export default {
    mixins: [UtilityMixin],
    components: {},
    props: {
        data: {
            Type: Object,
            default: function() {
                return {};
            },
        },
    },
    data() {
        return {
            currentPage: 1,
            perPage: 10,
            items: [
                { nomeMedicoRichiedente: "Medico", cognomeMedicoRichiedente: "Prova", tipoVisita: "ECOCARDIOGRAFIA COLOR-DOPPLER", assegnatoAttualeDataOra: 1636707990000, motivazione: "EXTRASISTOLIA VENTRICOLARE" },
                { nomeMedicoRichiedente: "Medico", cognomeMedicoRichiedente: "Prova", tipoVisita: "ECG DA SFORZO AL CICLOERGOMETRO", assegnatoAttualeDataOra: 1636707990000, motivazione: "EXTRASISTOLIA VENTRICOLARE" },
            ],
            fields: [
                {
                    label: "Esame",
                    key: "tipoVisita",
                    sortable: true,
                },
                {
                    label: "Motivazione",
                    key: "motivazione",
                    sortable: true,
                },
                {
                    label: "Medico Richiedente",
                    key: "nominativoMedicoRichiedente",
                    thStyle: "width: 5rem",
                    sortable: true,
                },
                {
                    label: "Data/Ora richiesta",
                    key: "assegnatoAttualeDataOra",
                    formatter: (value) => {
                        return moment(new Date(value)).format("DD/MM/YYYY HH:mm");
                    },
                    thStyle: "width: 5rem",
                    sortable: true,
                },
            ],
        };
    },
};
</script>
