<template>
  <div>
    <b-row>
      <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
        <b-row>
          <b-col xs="12" sm="3" md="3" lg="3" xl="3">
            <strong>{{ this.$i18n.t("patient.idoneity.lblSG") }}</strong
            ><br />
            {{ jsonData.sg }} <br /><br />
            <strong>{{ this.$i18n.t("patient.idoneity.lblPh") }}</strong> <br />
            {{ jsonData.ph }} <br /><br />
            <strong>{{ this.$i18n.t("patient.idoneity.lblUBJValue") }}</strong
            ><br />
            {{ jsonData.valoreUbj }} <br /><br />
            <strong>{{ this.$i18n.t("patient.idoneity.lblGLU") }}</strong
            ><br />
            {{ jsonData.glu }}
          </b-col>
          <b-col xs="12" sm="3" md="3" lg="3" xl="3">
            <strong>{{ this.$i18n.t("patient.idoneity.lblNIT") }}</strong
            ><br />
            {{ jsonData.nit }} <br /><br />
            <strong>{{ this.$i18n.t("patient.idoneity.lblLeu") }}</strong> <br />
            {{ jsonData.leu }} <br /><br />
            <strong>{{ this.$i18n.t("patient.idoneity.lblPRO") }}</strong
            ><br />
            {{ jsonData.pro }} <br /><br />
            <strong>{{ this.$i18n.t("patient.idoneity.lblEry") }}</strong
            ><br />
            {{ jsonData.ery }}
          </b-col>
          <b-col xs="12" sm="3" md="3" lg="3" xl="3">
            <strong>{{ this.$i18n.t("patient.idoneity.lblKET") }}</strong
            ><br />
            {{ jsonData.ket }} <br /><br />
            <strong>{{ this.$i18n.t("patient.idoneity.lblLeuValue") }}</strong> <br />
            {{ jsonData.valoreLeu }} <br /><br />
            <strong>{{ this.$i18n.t("patient.idoneity.lblPROValue") }}</strong
            ><br />
            {{ jsonData.valorePro }}
          </b-col>
          <b-col xs="12" sm="3" md="3" lg="3" xl="3">
            <strong>{{ this.$i18n.t("patient.idoneity.lblKETValue") }}</strong
            ><br />
            {{ jsonData.valoreKet }} <br /><br />
            <strong>{{ this.$i18n.t("patient.idoneity.lblUBJ") }}</strong> <br />
            {{ jsonData.ubj }} <br /><br />
            <strong>{{ this.$i18n.t("patient.idoneity.lblBIL") }}</strong
            ><br />
            {{ jsonData.bil }}
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <hr />
    <b-row>
      <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
        <b-row>
          <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
            <strong>{{ this.$i18n.t("patient.idoneity.lblReport") }} </strong><br />
            {{ jsonData.refertoUrine }}
          </b-col>
        </b-row>
        <hr />
        <b-row>
          <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
            <b-row>
              <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <strong>Stato </strong><br />
                {{ jsonData.firma }}
              </b-col>
            </b-row>
            <hr />
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>
<script>
export default {
  props: {
    jsonData: {
      Type: Object,
      require: true,
    },
  },
};
</script>
